import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import AnimateHeight from "react-animate-height"

interface ListTextInputProps {
  maxHeight?: string
  onChange?: (updatedValues: string[]) => void
  placeholder?: string
  value: string[]
}

interface ListItem {
  height: "auto" | 0
  value: string
}

const ANIMATION_DURATION = 250

function ListTextInput({ onChange, placeholder, value, maxHeight = "150px" }: ListTextInputProps) {
  const [inputValue, setInputValue] = useState<string>("")
  const [selectedItems, setSelectedItems] = useState<ListItem[]>([])

  useEffect(() => {
    const values: ListItem[] = value.map(
      (val: string): ListItem => ({
        height: "auto",
        value: val,
      }),
    )
    setSelectedItems(values)
  }, [value])

  const handleRemoveValueClick = (e: React.MouseEvent<HTMLElement>) => {
    const removeValue: string = e.currentTarget.getAttribute("data-value") || ""

    const updatedItems: ListItem[] = selectedItems.map((item: ListItem) => {
      if (item.value !== removeValue) return item
      return {
        ...item,
        height: 0,
      }
    })
    setSelectedItems(updatedItems)
    setTimeout(() => {
      // This is where the actual delete is happening
      const updatedValues: string[] = selectedItems
        .filter((item: ListItem) => item.value !== removeValue)
        .map((item: ListItem) => item.value)
      if (onChange) onChange(updatedValues)
    }, ANIMATION_DURATION)
  }

  const handleAddValueClick = (e: React.MouseEvent<HTMLElement>) => {
    const element = e.currentTarget.previousSibling as HTMLInputElement
    maybeAddListValue(element.value || "", addValue)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      maybeAddListValue(event.currentTarget.value || "", addValue)
    }
  }

  const addValue = (value: string): void => {
    if (selectedItems.filter((i) => i.value === value).length > 0) return

    const updatedItems: ListItem[] = [
      ...selectedItems,
      // When adding an item, set the inital height to 0, then animate to auto
      {
        height: 0,
        value,
      },
    ]

    setSelectedItems(updatedItems)
    setInputValue("")
    if (onChange) onChange(updatedItems.map((item: ListItem) => item.value))
  }

  return (
    <div className="ListTextInput">
      <div className="relative">
        <input
          className="input suffix-pad"
          name="company[sso_azure_tenant_id_restriction]"
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setInputValue(e.currentTarget.value)
          }}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          type="text"
          value={inputValue}
        />
        <button className="suffix" onClick={handleAddValueClick} type="button">
          <FontAwesomeIcon icon={["far", "plus"]} />
        </button>
      </div>
      <div className="mt-2">
        <div className="ListTextInput__list-items" style={{ maxHeight }}>
          {selectedItems.map((item) => (
            <ListTextInputItem
              item={item}
              // Assumed to be unique (we skip duplicates in the logic)
              key={item.value}
              onItemRemoved={handleRemoveValueClick}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const maybeAddListValue = (value: string, addFn: (arg: string) => void) => {
  if ((value || "").trim() === "") return
  addFn(value)
}

interface ListTextInputItemProps {
  onItemRemoved: (event: React.MouseEvent<HTMLElement>) => void
  item: ListItem
}

const ListTextInputItem = ({ item, onItemRemoved }: ListTextInputItemProps) => (
  // For list item animations, an adaptation with credit to:
  // https://muffinman.io/react-animate-height/
  // https://github.com/Stanko/react-animate-height#demo
  <div className="ListTextInput__list-items__list-item">
    <AnimateHeight duration={ANIMATION_DURATION} height={item.height}>
      <div className="ListTextInput__list-items__list-item-content">
        <span>{item.value}</span>
        <span>
          <button onClick={onItemRemoved} data-value={item.value} type="button">
            <FontAwesomeIcon icon={["far", "xmark"]} />
          </button>
        </span>
      </div>
    </AnimateHeight>
  </div>
)

export { ListTextInput }
