import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { Maybe } from "graphql/jsutils/Maybe"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Candidate, UpdateSuccessionPlanCandidateInput } from "types/graphql"
import { MotionLayout } from "v2/react/components/succession/SuccessionPlanPanel/shared/MotionWrappers"
import { TruncatedText } from "v2/react/components/succession/SuccessionPlanPanel/shared/TruncatedText"
import {
  attributeColorMapper,
  attributeColorMapperInverted,
} from "v2/react/components/succession/SuccessionPlanPanel/utils/successionUtils"
import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import {
  useGetSuccessionPlanAttributeLevelsQuery,
  useUpdateSuccessionPlanCandidateMutation,
} from "v2/redux/GraphqlApi/SuccessionApi"

interface CandidateAttributeListProps {
  candidate: Candidate
  positionId: string
}

function CandidateAttributeList({ candidate, positionId }: CandidateAttributeListProps) {
  const { data } = useGetSuccessionPlanAttributeLevelsQuery({})
  const attributeLevels = data?.currentCompany?.settings.succession.attributeLevels

  const [mutate] = useUpdateSuccessionPlanCandidateMutation()

  const handleAttributeChange = (
    property: keyof UpdateSuccessionPlanCandidateInput,
    value: string,
  ) => {
    const input: UpdateSuccessionPlanCandidateInput = {
      candidateId: candidate.id,
      positionId,
      flight_risk: candidate.flightRiskLevel,
      performance: candidate.performanceLevel,
      potential: candidate.potentialLevel,
      readiness: candidate.readinessLevel,
    }
    input[property] = value

    mutate(input)
  }

  if (!attributeLevels) return null
  const canEditFields = candidate.editableFields ? candidate.editableFields : false

  return (
    <MotionLayout layout>
      <div className="succession-plan-attributes grid-cols-4 items-center justify-between gap-2 grid max-480:grid-cols-2 max-480:grid">
        <AttributeDropdown
          attribute="readiness"
          currentLabel={candidate.readiness}
          currentLevel={candidate.readinessLevel}
          canEditField
          expandedLabel
          onChange={handleAttributeChange}
          options={attributeLevels.readiness}
        />
        <AttributeDropdown
          attribute="flight_risk"
          currentLabel={candidate.flightRisk}
          currentLevel={candidate.flightRiskLevel}
          canEditField={canEditFields}
          invertedColors
          onChange={handleAttributeChange}
          options={attributeLevels.flightRisk}
        />
        <AttributeDropdown
          attribute="performance"
          currentLabel={candidate.performance}
          currentLevel={candidate.performanceLevel}
          canEditField={canEditFields}
          onChange={handleAttributeChange}
          options={attributeLevels.performance}
        />
        <AttributeDropdown
          attribute="potential"
          currentLabel={candidate.potential}
          currentLevel={candidate.potentialLevel}
          canEditField={canEditFields}
          onChange={handleAttributeChange}
          options={attributeLevels.potential}
        />
      </div>
    </MotionLayout>
  )
}

export { CandidateAttributeList }

interface AttributeDropdownProps {
  attribute: keyof UpdateSuccessionPlanCandidateInput
  currentLabel: Maybe<string>
  currentLevel: Maybe<string>
  canEditField: boolean
  expandedLabel?: boolean
  invertedColors?: boolean
  onChange: (property: keyof UpdateSuccessionPlanCandidateInput, value: string) => void
  options: OptionType[]
}

interface OptionType {
  label: string
  value: string
}

const AttributeDropdown = ({
  attribute,
  currentLabel,
  currentLevel,
  canEditField,
  expandedLabel,
  invertedColors,
  onChange,
  options,
}: AttributeDropdownProps) => {
  const { t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)
  const [attributeLevel, setAttributeLevel] = useState(currentLevel || "none")
  const [attributeLabel, setAttributeLabel] = useState(
    currentLabel || t("v2.succession_plan_panel.candidate.none"),
  )

  const { context, floatingStyles, getFloatingProps, refs } = useSelectList({
    showList: showMenu,
    setShowList: setShowMenu,
    floatOverrides: { placement: "bottom-start" },
    offset: 2,
  })

  const handleSelection = (option: string) => {
    const label =
      options.find((opt) => opt.value === option)?.label ||
      t("v2.succession_plan_panel.candidate.none")
    setAttributeLevel(option)
    setAttributeLabel(label)
    onChange(attribute, option)
    setShowMenu(false)
  }

  const expandedLabelDisplay = (option: string) =>
    option !== "none"
      ? t(`v2.succession_plan_panel.candidate.expanded_label.${attribute}.${option}`)
      : ""

  return (
    <div className="dropdown--react nav-overflow-menu">
      <span className="text-sm text-neutral-64">
        {t(`v2.succession_plan_panel.candidate.${attribute}`)}
      </span>
      <button
        className={cn(
          `badge--${
            (invertedColors ? attributeColorMapperInverted : attributeColorMapper)[attributeLevel]
          } !w-full !justify-between`,
          attribute,
          { disabled: !canEditField },
        )}
        disabled={!canEditField}
        onClick={() => setShowMenu((curr) => !curr)}
        ref={refs.setReference}
        type="button"
      >
        <TruncatedText
          displayClasses="text-sm-bold max-w-[4.25rem]"
          displayContent={
            <>
              {attributeLabel}{" "}
              {expandedLabel &&
                labelUnmodified(attributeLabel) &&
                expandedLabelDisplay(attributeLevel)}
            </>
          }
          tooltipContent={
            <>
              {attributeLabel}{" "}
              {expandedLabel &&
                labelUnmodified(attributeLabel) &&
                expandedLabelDisplay(attributeLevel)}
            </>
          }
        />
        <FontAwesomeIcon icon={["fas", "caret-down"]} className="icon-14" />
      </button>

      <DropdownMenu
        context={context}
        showList={showMenu}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="dropdown-menu !block !w-fit right-0 p-2 z-5 mt-2"
      >
        <button
          className="dropdown-menu-link items-center gap-1.5 flex"
          onClick={() => handleSelection("none")}
          type="button"
        >
          <FontAwesomeIcon icon={["far", "minus"]} />
          <p>{t("v2.succession_plan_panel.candidate.none")}</p>
          {attributeLevel === "none" && (
            <FontAwesomeIcon icon={["fas", "circle-check"]} className="ml-auto h-3.5 w-3.5" />
          )}
        </button>
        {options.map((option) => (
          <button
            key={option.value}
            className="dropdown-menu-link items-center gap-1.5 flex"
            onClick={() => handleSelection(option.value)}
            type="button"
          >
            <FontAwesomeIcon
              icon={["fad", "circle-exclamation"]}
              className={cn(
                `icon--${
                  (invertedColors ? attributeColorMapperInverted : attributeColorMapper)[
                    option.value
                  ]
                }`,
              )}
            />
            <p>
              {option.label}{" "}
              {expandedLabel && labelUnmodified(option.label) && expandedLabelDisplay(option.value)}
            </p>
            {attributeLevel === option.value && (
              <FontAwesomeIcon icon={["fas", "circle-check"]} className="ml-auto h-3.5 w-3.5" />
            )}
          </button>
        ))}
      </DropdownMenu>
    </div>
  )
}

const labelUnmodified = (label: string) => ["Low", "Medium", "High"].includes(label)
