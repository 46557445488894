import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { AnimatePresence, LayoutGroup } from "framer-motion"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { Candidate, Position } from "types/graphql"
import { MotionOpaqueScale } from "v2/react/components/succession/SuccessionPlanPanel/shared/MotionWrappers"
import { CandidateCard } from "v2/react/components/succession/SuccessionPlanPanel/Summary/CandidateCard"
import { CandidateForm } from "v2/react/components/succession/SuccessionPlanPanel/Summary/CandidateForm"
import { DraggableList } from "v2/react/shared/collection/DraggableList/DraggableList"
import { Toggle, ToggleButton, ToggleLabel, ToggleSwitch } from "v2/react/shared/forms/Toggle"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"
import { prepareIconClass } from "v2/react/utils/misc"
import {
  useGetSuccessionPanelSettingsQuery,
  useUpdatePersonSuccessionPanelSettingsMutation,
  useUpdateSuccessionPlanCandidateOrderMutation,
} from "v2/redux/GraphqlApi/SuccessionApi"

interface CandidateListProps {
  canEditSuccessionPlan: boolean
  position: Position
}

function CandidateList({ canEditSuccessionPlan, position }: CandidateListProps) {
  const { t } = useTranslation()
  const dragContainerRef = useRef(null)
  const [candidateList, setCandidateList] = useState<Candidate[]>([])
  const [showCandidateForm, setShowCandidateForm] = useState(false)
  const [mutate] = useUpdateSuccessionPlanCandidateOrderMutation()
  const { data: settings } = useGetSuccessionPanelSettingsQuery({})
  const [updateShowNotes] = useUpdatePersonSuccessionPanelSettingsMutation()
  const showCandidateNotes =
    settings?.currentPerson?.settings?.successionPanelSettings?.showCandidateNotes

  useEffect(() => {
    setCandidateList(position?.successionPlan?.candidates || [])
  }, [position])

  const handleCandidateReorder = (updatedCandidates: Candidate[]) => {
    setCandidateList(updatedCandidates)

    const updated = updatedCandidates.map((candidate, index) => ({
      candidateId: candidate.id,
      sortOrder: index + 1,
    }))

    mutate({
      positionId: position.id,
      candidates: updated,
    })
  }

  const handleNotesToggle = (value: boolean) => {
    updateShowNotes({ showCandidateNotes: value })
  }

  if (candidateList.length > 0) {
    return (
      <div className="module-card succession-plan-candidates mb-0">
        <div className="module-card__header rounded-t-xl bg-primary-3">
          <div className="w-full grid-cols-2 gap-2 grid">
            <p className="text-base-bold">{t("v2.succession_plan_panel.summary.candidates")}</p>
            {settings && (
              <div className="ml-auto">
                <Toggle
                  id="show_notes"
                  initialValue={showCandidateNotes || false}
                  onToggle={handleNotesToggle}
                >
                  <ToggleButton classes="flex items-center gap-2">
                    <ToggleSwitch />{" "}
                    <ToggleLabel
                      classes="text-base-bold"
                      disabledLabel={t("v2.succession_plan_panel.summary.show_notes")}
                      enabledLabel={t("v2.succession_plan_panel.summary.show_notes")}
                    />
                  </ToggleButton>
                </Toggle>
              </div>
            )}
          </div>
        </div>
        <div className={cn("module-card__body gap-0", { "p-0": candidateList.length })}>
          <DraggableList<Candidate>
            items={candidateList}
            handleReorder={handleCandidateReorder}
            dragContainerRef={dragContainerRef}
            draggableListClasses="p-0 shadow-none border-l-neutral-8 border-r-neutral-8"
          >
            <LayoutGroup>
              {candidateList.map((candidate) => (
                <DraggableList.Item<Candidate>
                  key={candidate.id}
                  item={candidate}
                  itemClasses="p-0 !rounded-none candidate"
                  dragContainerRef={dragContainerRef}
                  useLayoutProperty
                >
                  <CandidateCard
                    candidate={candidate}
                    canEditSuccessionPlan={canEditSuccessionPlan}
                    positionId={position.id}
                    showCandidateNotes={showCandidateNotes || false}
                    key={candidate.id}
                  />
                </DraggableList.Item>
              ))}
            </LayoutGroup>
          </DraggableList>
          <AnimatePresence>
            <MotionOpaqueScale classNames="p-4" key="content" show={!showCandidateForm}>
              <button
                className="btn btn--primary ml-auto"
                type="button"
                onClick={() => setShowCandidateForm(true)}
              >
                <FontAwesomeIcon icon={["far", "plus"]} />
                {t("v2.succession_plan_panel.summary.add_a_candidate")}
              </button>
            </MotionOpaqueScale>
            <MotionOpaqueScale key="form" show={showCandidateForm}>
              <CandidateForm
                candidates={candidateList}
                filledBy={position.filledBy || []}
                onClose={() => setShowCandidateForm(false)}
                positionId={position.id}
              />
            </MotionOpaqueScale>
          </AnimatePresence>
        </div>
      </div>
    )
  }

  return (
    <div className="module-card succession-plan-candidates mb-0">
      <div className="module-card__header rounded-t-xl bg-primary-3">
        <div className="w-full grid-cols-2 gap-2 grid">
          <p className="text-base-bold">{t("v2.succession_plan_panel.summary.candidates")}</p>
        </div>
      </div>
      <div className={cn("module-card__body gap-0", { "p-0": showCandidateForm })}>
        {!showCandidateForm && (
          <div className="flex-col items-center gap-4 flex">
            <BlueIcon icon={prepareIconClass("fas users-custom")} size="med-lg" />
            <div className="w-[95%] text-center">
              <p className="text-base-bold">
                {t("v2.succession_plan_panel.summary.no_candidates_yet")}
              </p>
              <p>{t("v2.succession_plan_panel.summary.add_a_candidate_from")}</p>
            </div>
            <button
              className="btn--large btn--primary"
              type="button"
              onClick={() => setShowCandidateForm(true)}
            >
              <FontAwesomeIcon icon={["far", "plus"]} />
              {t("v2.succession_plan_panel.summary.add_a_candidate")}
            </button>
          </div>
        )}
        <AnimatePresence>
          <MotionOpaqueScale show={showCandidateForm}>
            <CandidateForm
              candidates={candidateList}
              filledBy={position.filledBy || []}
              onClose={() => setShowCandidateForm(false)}
              positionId={position.id}
            />
          </MotionOpaqueScale>
        </AnimatePresence>
      </div>
    </div>
  )
}

export { CandidateList }
