import { createEntityAdapter, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import type { List } from "types/graphql"
import type {
  ChartSection,
  CollapseExpandChartSectionAction,
  ContainerState,
  Field,
} from "v2/redux/slices/ContainerSlice/types"
import type { RootState } from "v2/redux/store"

// Entity adapters
const chartSectionsAdapter = createEntityAdapter<ChartSection>()
const chartSectionSelectors = chartSectionsAdapter.getSelectors(
  (state: RootState) => state.container.chartSections,
)

const InitialState: ContainerState = {
  chartSectionTreeIndex: {},
  chartSections: chartSectionsAdapter.getInitialState(),
  chartSectionsCollapsedIndex: {},
  containerKey: null,
  fields: [],
  lists: [],
}

const ContainerSlice = createSlice({
  name: "container",
  initialState: InitialState,
  reducers: {
    collapseOrExpandChartSection: (state, { payload }: CollapseExpandChartSectionAction) => {
      const normalizedId = payload.id.toString()
      const current = !!state.chartSectionsCollapsedIndex[normalizedId]
      const asCollapsedOrExpanded = payload.collapsed ?? !current

      return fp.set(["chartSectionsCollapsedIndex", normalizedId], asCollapsedOrExpanded, state)
    },

    setChartSectionTreeIndex: (
      state,
      { payload }: PayloadAction<ContainerState["chartSectionTreeIndex"]>,
    ) => fp.set("chartSectionTreeIndex", payload, state),

    setChartSections: (state, { payload: chartSections }: PayloadAction<ChartSection[]>) => {
      // Opts to use Immer given the entity adapter uses it naturally. To
      // support tracking what chart sections were updated after re-fetching
      // the collection, this manually generates updates. The alternative of
      // upserting all data is that each record will have a new reference.
      const incomingIds = fp.map("id", chartSections)
      const idsToDestroy = fp.without(incomingIds, state.chartSections.ids)
      const [toMaybeUpdate, toCreate] = fp.partition(
        (section) => state.chartSections.entities[section.id],
        chartSections,
      )

      const upserts = fp.reject(
        (section) => fp.equals(section, state.chartSections.entities[section.id]),
        toMaybeUpdate,
      )

      chartSectionsAdapter.removeMany(state.chartSections, idsToDestroy)
      chartSectionsAdapter.setMany(state.chartSections, toCreate)
      chartSectionsAdapter.upsertMany(state.chartSections, upserts)
    },

    setCollapsedChartSectionByIds: (state, { payload }: PayloadAction<EntityId[]>) => ({
      ...state,
      chartSectionsCollapsedIndex: fp.reduce(
        (memo, key) => ({ ...memo, [key]: true }),
        {},
        payload,
      ),
    }),

    setFields: (state, { payload }: PayloadAction<Field[]>) => fp.set("fields", payload)(state),

    setLists: (state, { payload }: PayloadAction<List[]>) => fp.set("lists", payload)(state),

    setContainerKey: (state, { payload }: PayloadAction<string>) =>
      fp.set("containerKey", payload, state),

    setChartHistoryCutoffDate: (state, { payload }: PayloadAction<string>) =>
      fp.set("chartHistoryCutoffDate", payload, state),
  },
})

export const {
  collapseOrExpandChartSection,
  setChartSectionTreeIndex,
  setChartSections,
  setCollapsedChartSectionByIds,
  setContainerKey,
  setChartHistoryCutoffDate,
  setFields,
  setLists,
} = ContainerSlice.actions
export { chartSectionSelectors, ContainerSlice, ContainerState, Field }
