import type { TFunction } from "i18next"
import { useCallback, useEffect, useState } from "react"
import { FieldErrors, UseFormReturn } from "react-hook-form"

import { SmartRecruitersFormType } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/types"
import { FormKey } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/utils"

type BannerError = {
  key?: FormKey | "generic" | "error_creating_positions"
  message: string
  type?: "initial"
}

interface UseBannerErrorsProps {
  initialErrors?: BannerError[]
}

const useBannerErrors = ({ initialErrors }: UseBannerErrorsProps) => {
  const [bannerErrors, setBannerErrors] = useState<BannerError[]>(initialErrors ?? [])

  /**
   * Ensures we don't add duplicate errors to the banner, prioritizing the most
   * recent error of a given key.
   */
  const updateBannerErrors = useCallback(
    (errors: BannerError[]) => {
      setBannerErrors((prev) => {
        const filteredPrev = prev.filter(
          (error) => !errors.some((newError) => newError.key === error.key),
        )
        return [...filteredPrev, ...errors]
      })
    },
    [setBannerErrors],
  )

  /**
   * Clears banner errors that are not of type "initial".
   */
  const clearBannerErrors = useCallback(() => {
    setBannerErrors((prev) => prev.filter((error) => error.type === "initial"))
  }, [setBannerErrors])

  // Sets initial banner errors.
  useEffect(() => {
    setBannerErrors(initialErrors || [])
  }, [initialErrors])

  return { bannerErrors, setBannerErrors, updateBannerErrors, clearBannerErrors }
}

interface UseErrorsForPresetFormFieldsProps {
  errors: FieldErrors<SmartRecruitersFormType>
  defaultValues?: UseFormReturn<SmartRecruitersFormType>["formState"]["defaultValues"]
  updateBannerErrors: (errors: BannerError[]) => void
  t: TFunction
}

/**
 * When there are errors on form fields that are preset, we show the errors in a
 * banner because there is no 'input' to attach the error to.
 */
const useErrorsForPresetFormFields = ({
  errors,
  defaultValues,
  updateBannerErrors,
  t,
}: UseErrorsForPresetFormFieldsProps) => {
  useEffect(() => {
    if (errors) {
      // Job description is never editable.
      if (errors.job?.jobDescription) {
        updateBannerErrors([
          {
            key: "jobDescription",
            message: t(
              `v2.job_requisitions.modals.send_to_ats.smart_recruiters.errors.${errors.job?.jobDescription?.message}`,
            ),
          },
        ])
      }

      if (errors.job?.refNumber && !!defaultValues?.job?.refNumber) {
        updateBannerErrors([
          {
            key: "refNumber",
            message: t(
              `v2.job_requisitions.modals.send_to_ats.smart_recruiters.errors.${errors.job?.refNumber?.message}`,
            ),
          },
        ])
      }
    }
  }, [errors, defaultValues, updateBannerErrors, t])
}

export type { BannerError }
export { useBannerErrors, useErrorsForPresetFormFields }
