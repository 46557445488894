import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { HistoricalChartSection } from "types/graphql"
import {
  ChartSectionRow,
  HistoricalChartSectionTreeNode,
} from "v2/react/components/orgChart/SuperPanel/ChartSectionsTab/ChartSectionRow"
import { ChartSectionsTabHeader } from "v2/react/components/orgChart/SuperPanel/ChartSectionsTab/ChartSectionsTabHeader"
import { useChartNodeHistoryChartSectionsQuery } from "v2/redux/GraphqlApi/ChartNodeHistoriesApi"
import { collapseOrExpandChartSection } from "v2/redux/slices/ContainerSlice"
import { selectIsChartSectionCollapsed } from "v2/redux/slices/ContainerSlice/containerSelectors"
import { asyncToggleChartSectionFilter } from "v2/redux/slices/GridSlice/gridThunks"
import { selectChartSettings } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

const asChartSectionTreeNode = (
  chartSection: HistoricalChartSection,
): HistoricalChartSectionTreeNode => ({
  ...chartSection,
  children: [],
  color: "",
  level: 0,
})

const withChildren = (
  chartSection: HistoricalChartSectionTreeNode,
  chartSections: HistoricalChartSectionTreeNode[],
  level: number,
): HistoricalChartSectionTreeNode => {
  const children = chartSections
    .filter((node) => node.parentId === chartSection.id)
    .map((chartSection) => withChildren(chartSection, chartSections, level + 1))

  return { ...chartSection, children, level: level + 1 }
}

const hierarchicalChartSectionsAsTreeNodes = (
  chartSections: HistoricalChartSection[],
): HistoricalChartSectionTreeNode[] => {
  const level = 1 // The level starts at 1 to accomodate the company
  const asTreeNodes = chartSections.map(asChartSectionTreeNode)
  const roots = asTreeNodes
    .filter((chartSection) => chartSection.parentId === null)
    .map((chartSection) => withChildren(chartSection, asTreeNodes, level))

  return roots
}

function HistoricalChartSectionsTab() {
  const orgChartId = useAppSelector((state) => state.container.containerKey)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const chartSettings = useAppSelector(selectChartSettings)
  const isChartSectionCompanyRootCollapsed = useAppSelector((state) =>
    selectIsChartSectionCollapsed(state, "0"),
  )
  const { data } = useChartNodeHistoryChartSectionsQuery(
    {
      uniqueKey: orgChartId || "",
      asOf: chartSettings.historyModeSelectedDate,
    },
    { skip: !orgChartId || !chartSettings.historyMode },
  )

  const handleCompanyClick = () => {
    dispatch(asyncToggleChartSectionFilter("", ""))
  }

  const collapseOrExpandRoot = () => dispatch(collapseOrExpandChartSection({ id: "0" }))

  if (!data?.chart?.historicalChartSections) return null
  const chartSections = data.chart.historicalChartSections

  const rootChartSections = hierarchicalChartSectionsAsTreeNodes(chartSections)

  return (
    <div id="tab-chart-sections" className="drawer-contents panel grid-rows-[auto_1fr] grid">
      <ChartSectionsTabHeader />

      <div className="drawer-section-content chart-section-section p-3">
        <div className="mb-1 items-center justify-between flex">
          <p className="text-base-bold">{t("v2.orgchart.super_panel.sections")}</p>
        </div>

        {chartSections.length > 0 && (
          <ul>
            <li className={cn({ collapsed: isChartSectionCompanyRootCollapsed })}>
              <div className="company-wrapper mb-2 h-8 w-full rounded rounded-r-lg bg-neutral-3">
                <div className="expand-collapse">
                  <div className="section-content">
                    <button
                      type="button"
                      className="expand h-3 w-3 rounded-lg p-0"
                      onClick={collapseOrExpandRoot}
                    >
                      <FontAwesomeIcon icon="caret-right" className="icon-regular" />
                    </button>
                    <button
                      type="button"
                      className="collapse h-3 w-3 rounded-lg p-0"
                      onClick={collapseOrExpandRoot}
                    >
                      <FontAwesomeIcon icon="caret-down" className="icon-regular" />
                    </button>
                  </div>
                </div>

                <button
                  className="company-name w-full bg-transparent text-start leading-8"
                  onClick={handleCompanyClick}
                  type="button"
                >
                  {window.gon.company_name}
                </button>
              </div>
              <ul
                data-container="chart_sections"
                className={cn({ hidden: isChartSectionCompanyRootCollapsed })}
              >
                {rootChartSections.map((chartSection: HistoricalChartSectionTreeNode) => (
                  <ChartSectionRow
                    key={`chart-section-row-${chartSection.id}`}
                    chartSection={chartSection}
                    editingDisabled
                    hasAccessToEdit={false}
                  />
                ))}
              </ul>
            </li>
          </ul>
        )}
      </div>
    </div>
  )
}

export { HistoricalChartSectionsTab }
