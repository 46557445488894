import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { CareerLadder } from "types/graphql"
import { EditCareerLadderModal } from "v2/react/components/careerLadders/Show/EditCareerLadderModal"
import { EmptyState } from "v2/react/components/careerLadders/Show/EmptyState"
import { SmallEditButton } from "v2/react/components/positionTypes/Show/SmallEditButton"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

interface DescriptionProps {
  careerLadder: CareerLadder
}

const Description = ({ careerLadder }: DescriptionProps) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const handleEdit = () => setModalOpen(true)

  const onClose = () => setModalOpen(false)

  const emptyDescription = {
    buttonText: t("v2.career_ladders.show.add_description_button_text"),
    note: t("v2.career_ladders.show.add_description_note"),
    icon: "fas short-text",
    onClick: handleEdit,
  }

  return (
    <>
      <div className="module-card">
        <div className="module-card__header">
          <div className="module-title">{t("v2.career_ladders.show.description")}</div>
          {careerLadder.description?.length ? <SmallEditButton onClick={handleEdit} /> : null}
        </div>
        <div className="module-card__body">
          {careerLadder.description?.length ? (
            <div>{sanitizeParse(careerLadder.description)}</div>
          ) : (
            <EmptyState
              buttonText={emptyDescription.buttonText}
              note={emptyDescription.note}
              icon={emptyDescription.icon}
              onClick={emptyDescription.onClick}
            />
          )}
        </div>
      </div>
      <EditCareerLadderModal careerLadder={careerLadder} isOpen={modalOpen} onClose={onClose} />
    </>
  )
}

export { Description }
