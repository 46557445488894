import React from "react"
import { useTranslation } from "react-i18next"

import { Sidebar } from "v2/react/shared/layout/Sidebar"
import { BasicTableSettingsPanelContent } from "v2/react/shared/tables/TableUtilities/shared/BasicTableSettingsPanelContent"
import type { PanelProps as UtilityPanelProps } from "v2/react/shared/tables/TableUtilities/UtilityPanel"
import { pjaxReload } from "v2/react/utils/pjax"
import {
  useGetJobRequisitionsTableSettingsQuery,
  useUpdatePersonJobRequisitionsTableSettingsMutation,
} from "v2/redux/GraphqlApi/JobRequisitionsApi"

interface PanelProps {
  onUtilityToggle: UtilityPanelProps["onUtilityToggle"]
}

const Panel = ({ onUtilityToggle }: PanelProps) => {
  const { t } = useTranslation()
  const { data, isFetching, isError } = useGetJobRequisitionsTableSettingsQuery({})
  const [mutate, mutationState] = useUpdatePersonJobRequisitionsTableSettingsMutation()

  const allColumns = data?.currentPerson?.settings?.jobRequisitionsTableSettings?.allColumns
  const allColumnsSet = new Set(allColumns?.map((column) => column.id))
  const selectedColumns =
    data?.currentPerson?.settings?.jobRequisitionsTableSettings?.tableSettings?.selectedColumns
  const filteredSelectedColumns = selectedColumns?.filter((key) => allColumnsSet.has(key))

  const dataLoaded =
    !isFetching && !!filteredSelectedColumns && !!allColumns && !mutationState.isLoading
  const anyErrors = isError || mutationState.isError

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const selectedColumns = Array.from(formData.keys())

    mutate({ input: { selectedColumns } })
      .unwrap()
      .then(() => {
        const container = "[data-pjax-container='job-requisitions-list']"

        pjaxReload({
          container,
          fragment: container,
        })
      })
  }

  return (
    <Sidebar.Content
      title={t("v2.positions.index.edit_settings")}
      isError={anyErrors}
      isLoading={!dataLoaded}
      onClose={() => onUtilityToggle(null)}
    >
      {dataLoaded && (
        <BasicTableSettingsPanelContent
          fieldsetId="edit-job-requisitions-table-settings"
          allColumns={allColumns}
          selectedColumns={filteredSelectedColumns}
          onSubmit={handleSubmit}
        />
      )}
    </Sidebar.Content>
  )
}

export { Panel }
