import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"

import { EffectiveDate } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/effectiveDates/helpers"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface EffectiveDateCellTooltipProps {
  asHelperText?: boolean
  children: ReactNode
  disableTooltip?: boolean
  effectiveDates: EffectiveDate[]
}

function EffectiveDateCellTooltip({
  asHelperText,
  children,
  disableTooltip,
  effectiveDates,
}: EffectiveDateCellTooltipProps) {
  const { t } = useTranslation()

  if (disableTooltip) {
    return children
  }

  return (
    <Tooltip>
      <TooltipTrigger className="w-full">{children}</TooltipTrigger>
      <TooltipContent className="react-tooltip-content max-w-fit">
        {asHelperText ? (
          <p>{t("v2.headcount_plan.datasheet.set_effective_dates")}</p>
        ) : (
          <div>
            {effectiveDates.map((ed: EffectiveDate) =>
              ed.value ? (
                <p key={ed.id} className="items-center justify-between gap-x-2 flex">
                  <span className="text-nowrap text-white/80">{ed.label}</span>{" "}
                  <span className="text-nowrap">{ed.value}</span>
                </p>
              ) : (
                <span key={ed.id} />
              ),
            )}
          </div>
        )}
      </TooltipContent>
    </Tooltip>
  )
}

export { EffectiveDateCellTooltip }
