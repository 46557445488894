import dayjs from "dayjs"
import React, { useEffect, useState } from "react"

import { Collections, HeadcountPlan, HeadcountPlanParticipant } from "types/graphql"
import { HeadcountPlanActiveStatus } from "types/graphql.enums"
import { HeadcountPlanDatasheet } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet"
import { useHeadcountPlanDatasheet } from "v2/react/hooks/useHeadcountPlanDatasheet"
import { getCookie, setCookie } from "v2/react/utils/cookies"

import { ParticipantBanner } from "./ParticipantBanner"

interface Props {
  headcountPlanId: string
  participantId: string
  displayState: "approved" | "original"
  setDisplayState: (value: "approved" | "original") => void
  headcountPlan: HeadcountPlan | undefined
  participant: HeadcountPlanParticipant | undefined
  dropdownCellCollections: Collections | undefined
  csvDownloadRef: React.RefObject<HTMLButtonElement> | string
  showGroupByDropzone?: boolean
}

export function ParticipantDatasheet({
  headcountPlanId,
  participantId,
  displayState,
  setDisplayState,
  headcountPlan,
  participant,
  dropdownCellCollections,
  csvDownloadRef,
  showGroupByDropzone,
}: Props) {
  const position = participant?.position
  const person = participant?.person
  const isFinalized = !!headcountPlan?.lockedAt
  const hasSubmitted = participant?.status === "complete"
  const csvDownloadName = `built-${headcountPlan?.name}-${person?.name}-${dayjs().format(
    "YYYY-MM-DD",
  )}`.replace(/\s/g, "")
  const [remainingBudget, setRemainingBudget] = useState(participant?.remainingBudget)
  const cookieName = `built__dismissed-budget-over-for-hcp-participant-${participantId}`
  const [showBudgetOverBanner, setShowBudgetOverBanner] = useState(getCookie(cookieName) !== "true")

  const onCloseBudgetOverBanner = () => {
    setShowBudgetOverBanner(false)
    setCookie(cookieName, "true", 365 * 5) // 5 years since we'll have another solution by then
  }

  useEffect(() => {
    if (
      remainingBudget !== participant?.remainingBudget &&
      participant?.remainingBudget?.includes("-")
    ) {
      setShowBudgetOverBanner(true)
      setCookie(cookieName, "false", 365 * 5)
    }

    setRemainingBudget(participant?.remainingBudget || "")
  }, [participant, remainingBudget, cookieName])

  const { rows, columns } = useHeadcountPlanDatasheet({
    attributes: headcountPlan?.allowedAttributes ?? [],
    positions:
      (isFinalized && displayState === "approved"
        ? participant?.approvedPositions
        : participant?.allPositionsInPlan) ?? [],
    dropdownCellCollections: dropdownCellCollections ?? {},
    hideOptions: hasSubmitted,
    showStatus: isFinalized && displayState === "original",
    newPositionIdPrefix: headcountPlan?.newPositionIdPrefix,
    showFinalizedAndActiveOptions:
      headcountPlan?.activeStatus === HeadcountPlanActiveStatus.Active &&
      displayState === "approved",
  })

  return (
    <>
      <ParticipantBanner
        displayState={displayState}
        setDisplayState={setDisplayState}
        headcountPlan={headcountPlan}
        participant={participant}
        showBudgetOverBanner={showBudgetOverBanner}
        onCloseBudgetOverBanner={onCloseBudgetOverBanner}
      />
      <HeadcountPlanDatasheet
        key={displayState}
        csvDownloadRef={csvDownloadRef}
        csvDownloadName={csvDownloadName}
        headcountPlanId={headcountPlanId}
        headcountPlan={headcountPlan}
        participantId={participantId}
        participantPositionId={position?.id}
        rows={rows}
        columns={columns}
        readOnly={hasSubmitted}
        showGroupByDropzone={showGroupByDropzone}
      />
    </>
  )
}
