import { skipToken } from "@reduxjs/toolkit/dist/query"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { GenericError } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/GenericError"
import { SendToAtsForm } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/SendToAtsForm"
import {
  BannerError,
  useBannerErrors,
} from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/useBannerErrors"
import { processData } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/utils"
import { useNProgressBar } from "v2/react/hooks/useNProgressBar"
import { Modal, useModalOverlayRef } from "v2/react/shared/overlay/Modal"
import { useGetSmartRecruitersJobRequisitionQuery } from "v2/redux/GraphqlApi/JobRequisitionsApi"

interface SendToAtsProps {
  closeModal: () => void
  isOpen: boolean
  jobRequisitionId?: string
  positionId?: string
  loadOnMount?: boolean
}

const SendToAtsModal = ({
  closeModal,
  isOpen,
  jobRequisitionId,
  positionId,
  loadOnMount = true,
}: SendToAtsProps) => {
  const { t } = useTranslation()
  const { modalRef } = useModalOverlayRef()
  const { data, isLoading, isFetching, isError } = useGetSmartRecruitersJobRequisitionQuery(
    loadOnMount
      ? { jobRequisitionId: jobRequisitionId ?? "", positionId: positionId ?? "" }
      : skipToken,
  )

  const processedData = useMemo(
    () =>
      data
        ? processData({
            jobRequisitionId,
            positionId,
            data,
            t,
          })
        : null,
    [data, jobRequisitionId, positionId, t],
  )

  const { bannerErrors, updateBannerErrors, clearBannerErrors } = useBannerErrors({
    initialErrors: processedData?.auxiliary.bannerErrors,
  })

  useNProgressBar({ finishCondition: !isLoading && !isFetching, startCondition: isOpen })
  if (isLoading || isFetching) return null

  const handleCloseModal = () => {
    closeModal()
    clearBannerErrors()
  }

  const errorCreatingPositions = bannerErrors.find(
    (error) => error.key === "error_creating_positions",
  )
  const showStoppingErrors = isError || !!errorCreatingPositions

  return (
    <Modal
      title={t("v2.job_requisitions.modals.send_to_ats.title")}
      isOpen={isOpen}
      onClose={handleCloseModal}
      overlayRef={modalRef}
      size="md"
    >
      {processedData && !showStoppingErrors ? (
        <SendToAtsForm
          initialData={processedData}
          closeModal={handleCloseModal}
          bannerErrors={bannerErrors}
          updateBannerErrors={updateBannerErrors}
        />
      ) : (
        <GenericError message={errorCreatingPositions?.message} />
      )}
    </Modal>
  )
}

export type { BannerError }
export { SendToAtsModal }
