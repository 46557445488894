import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface OpenLinkInNewTabProps {
  url: string
}

function OpenLinkInNewTab({ url }: OpenLinkInNewTabProps) {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="svg-inline-fa text-inherit">
      <FontAwesomeIcon className="cursor-pointer" icon={["far", "arrow-up-right-from-square"]} />
    </a>
  )
}

export { OpenLinkInNewTab }
