import cn from "classnames"
import React, { useState } from "react"

import { PositionAutocompleteConnectionQuery } from "types/graphql"
import { ForcedAutocompleteCell } from "v2/react/components/headcountPlanning/TableDatasheet/ForcedAutocompleteCell"
import { prepareStyle } from "v2/react/components/orgChart/Datasheet/Cell/utils"
import { Column, CursorConnection, NodeRow } from "v2/react/components/orgChart/Datasheet/types"
import { usePositionSearch } from "v2/react/hooks/usePositionSearch"
import PersonSearchResult from "v2/react/shared/forms/PersonSearchResult"
import { useAppSelector } from "v2/redux/store"

export type Position = NonNullable<
  NonNullable<NonNullable<PositionAutocompleteConnectionQuery["positions"]>["nodes"]>[0]
>

type ForcedAutocompleteProps<TNode, CType> = {
  cursorConnection: CursorConnection
  row: NodeRow<TNode>
  column: Column<CType>
  isFirst: boolean
  isLast: boolean
  style: React.CSSProperties
  currentValue?: string
}

export function prepareValue(arg: Position | null | undefined) {
  if (!arg || !arg.uniqueKey) {
    return ""
  }
  return arg.uniqueKey
}

export function getPositionLabel(position: Position | null | undefined) {
  if (!position) {
    return ""
  }

  const people = position.people
  const peopleNames = people?.map((person) => person.name).join(", ")
  const positionTitle = position.title
  const positionSystemID = position.systemIdentifier
  const value = peopleNames || positionTitle || positionSystemID || ""
  return value
}

export function PositionAutocomplete<TNode, CType>({
  column,
  currentValue,
  cursorConnection,
  isFirst,
  isLast,
  row,
  style,
}: ForcedAutocompleteProps<TNode, CType>) {
  const [inputValue, setInputValue] = useState(
    cursorConnection.initialWriteValue ?? currentValue ?? "",
  )

  const chartKey = useAppSelector((state) => state.container.containerKey)
  const isLimitedAdminUsingOfficialChart = useAppSelector(
    (state) => state.ability.isLimitedAdminUsingOfficialChart,
  )
  const { positions } = usePositionSearch({
    filter: inputValue.trim(),
    chartKey,
    exclude: (row.data as { position_id: string }).position_id, // TODO MY: Harden This
    excludeWithReqs: false,
    subordinateOnly: isLimitedAdminUsingOfficialChart,
    includeSelf: isLimitedAdminUsingOfficialChart,
  })

  const defaultPosition = {
    id: "",
    uniqueKey: "",
    title: "",
    people: [{ id: "", name: currentValue ?? "", avatarThumbUrl: "" }],
  }

  const currentPosition =
    positions.find((position) => getPositionLabel(position) === currentValue) || defaultPosition

  // NOTE: some logic must be duplicated in the `useCellState` call in `OrgChartDatasheetCell`
  // This is because this cell will get replaced with `DisplayValueCell` when the cursor moves
  // away, so the save would never complete.

  return (
    <ForcedAutocompleteCell
      editable
      options={positions}
      columnId={String(column.fieldKey)}
      cellInputRef={cursorConnection.cellInputRef}
      saveFn={(option) => cursorConnection.saveFn(option?.uniqueKey)}
      className={cn("GridBody-cell Cell__select-field bg-transparent", { last: isLast })}
      currentValue={currentPosition}
      searchTerm={inputValue}
      setSearchTerm={setInputValue}
      rowId={row.id}
      style={{ ...prepareStyle(style, isFirst, row.color), padding: 0 }}
      renderOption={(option) => {
        const people = option.people
        const peopleNames = people?.map((person) => person.name).join(", ")

        return (
          <PersonSearchResult
            avatarUrl={people?.[0]?.avatarThumbUrl || ""}
            personName={peopleNames}
            positionTitle={option.title ?? ""}
            includeListItemClass={false}
          />
        )
      }}
      getLabel={getPositionLabel}
    />
  )
}
