import cn from "classnames"
import OrgChart from "org_chart/chart/orgChart"
import React from "react"

import { FeatureFlags, Position } from "types/graphql"
import { ChartBuilderTab } from "v2/react/components/orgChart/SuperPanel/ChartBuilderTab/ChartBuilderTab"
import { ChartSectionsTab } from "v2/react/components/orgChart/SuperPanel/ChartSectionsTab/ChartSectionsTab"
import { ColorCodingTab } from "v2/react/components/orgChart/SuperPanel/ColorCodingTab/ColorCodingTab"
import { DataOptionsTab } from "v2/react/components/orgChart/SuperPanel/DataOptionsTab/DataOptionsTab"
import { DisplayOptionsTab } from "v2/react/components/orgChart/SuperPanel/DisplayOptionsTab/DisplayOptionsTab"
import {
  selectCanManageChart,
  selectCanTimeTravelChart,
  selectIsLimitedAdminUsingOfficialChart,
} from "v2/redux/slices/AbilitySlice/abilitySelectors"
import { chartSectionSelectors } from "v2/redux/slices/ContainerSlice"
import { selectChartSettings } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppSelector } from "v2/redux/store"

import { HistoricalChartSectionsTab } from "./ChartSectionsTab/HistoricalChartSectionsTab"
import { TimeTravelTab } from "./TimeTravelTab/TimeTravelTab"

interface Props {
  ff: FeatureFlags
  offsetHeight: number
  chart?: OrgChart | null
  addAsChild?: (position: Position) => void
  hcpIsLocked?: boolean
}

function SuperPanel({ ff, offsetHeight, chart, addAsChild, hcpIsLocked }: Props) {
  const canManageChart = useAppSelector(selectCanManageChart)
  const canTimeTravelChart = useAppSelector(selectCanTimeTravelChart)
  const isLimitedAdminUsingOfficialChart = useAppSelector(selectIsLimitedAdminUsingOfficialChart)

  const chartSectionsTotal = useAppSelector((state) => chartSectionSelectors.selectTotal(state))
  const { historyMode, displayMode, selectedTab, superPanelOpen } =
    useAppSelector(selectChartSettings)

  const showChartSectionTab = canManageChart || chartSectionsTotal > 0
  const showVisualizationTab =
    ff.orgchartVisualization && (canManageChart || isLimitedAdminUsingOfficialChart)
  const showChartBuilderTab =
    displayMode !== "grid" && !historyMode && selectedTab === "tab-chart-builder" && !hcpIsLocked
  const showTimeTravelTab = canTimeTravelChart

  return (
    <div
      className={cn({
        drawer: true,
        "is-open": superPanelOpen,
      })}
      style={{ height: `calc(100vh - ${offsetHeight}px)` }}
    >
      <div className="super-panel drawer-tab-container h-full bg-white">
        {showTimeTravelTab && selectedTab === "tab-time-travel" && <TimeTravelTab />}
        {showVisualizationTab && selectedTab === "tab-color-coding" && <ColorCodingTab />}
        {showChartSectionTab && selectedTab === "tab-chart-sections" && (
          <>
            {historyMode && <HistoricalChartSectionsTab />}
            {!historyMode && <ChartSectionsTab />}
          </>
        )}
        {selectedTab === "tab-data-options" && <DataOptionsTab />}
        {selectedTab === "tab-display-options" && <DisplayOptionsTab />}
        {showChartBuilderTab && <ChartBuilderTab chart={chart} addAsChild={addAsChild} />}
      </div>
    </div>
  )
}

export { SuperPanel }
