import classNames from "classnames"
import React, { forwardRef } from "react"

export const COMPARE_VALUES_PADDING_X = 32

type Props = {
  id?: string
  compareValue?: string | null
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}
export const CompareValues = forwardRef<HTMLDivElement, Props>(
  ({ id, compareValue, children, className, style }, ref) => (
    <div id={id} className={classNames("compare-values", className)} ref={ref} style={style}>
      {compareValue ? <s>{compareValue}</s> : null}
      {children}
    </div>
  ),
)
