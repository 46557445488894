import React from "react"
import { useTranslation } from "react-i18next"

import { StatusBadge } from "v2/react/shared/status/StatusBadge"

export function PlanTypeIndicator({ type }: { type: "original" | "approved" }) {
  const { t } = useTranslation()

  return (
    <div className="flex-col justify-center flex">
      {type === "original" ? (
        <div className="badge--yellow">{t("v2.headcount_plan.header.original_proposal")}</div>
      ) : (
        <StatusBadge status="plan_approved" displayType="button" checkmarkOnApproved />
      )}
    </div>
  )
}
