import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { PositionNestedJobRequisition } from "types/graphql"
import { ExternalReqModal } from "v2/react/components/orgChart/ProfilePanel/PositionModules/ExternalReqModal"
import { SendToAts } from "v2/react/pages/org_chart"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"
import { UrlHelper } from "v2/react/utils/urls"

type InternalReqInfoProps = {
  requisitions: PositionNestedJobRequisition[]
}

function InternalReqInfo({ requisitions }: InternalReqInfoProps) {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const approvalStateLast = (req: PositionNestedJobRequisition) =>
    !(req.approvalState && !req.clientIdentifier && req.approvalState === "approved") &&
    !(req.status && req.clientIdentifier)

  const internalReq = (req: PositionNestedJobRequisition) => {
    if (req.hasInternalReq) {
      return <a href={UrlHelper.jobRequisitionOverviewPath(req.id)}>{req.systemUid}</a>
    }
    return req.systemUid
  }

  return (
    <div className="w-full">
      {requisitions.map((req, i) => (
        <div key={req.id}>
          <div className="-mt-4 border-0 border-b border-solid border-b-neutral-8-solid">
            <div className="items-center justify-between p-4 flex">
              <div>{t("v2.job_requisitions.fields.req_id")}</div>
              <div>{internalReq(req)}</div>
            </div>
          </div>
          <div
            className={
              approvalStateLast(req) && i + 1 === requisitions.length
                ? "-mb-4"
                : "border-0 border-b border-solid border-b-neutral-8-solid"
            }
          >
            <div className="items-center justify-between p-4 flex">
              <div>{t("v2.job_requisitions.fields.req_status")}</div>
              <div>
                {req.approvalState ? (
                  <StatusBadge status={req.approvalState} displayType="button" />
                ) : (
                  t("v2.defaults.dash")
                )}
              </div>
            </div>
          </div>
          {req.approvalState && !req.clientIdentifier && req.approvalState === "approved" && (
            <div
              className={
                (req.status && req.clientIdentifier) || i + 1 < requisitions.length
                  ? "border-0 border-b border-solid border-b-neutral-8-solid"
                  : "-mb-4"
              }
            >
              <div className="items-center justify-between p-4 flex">
                <div className="whitespace-nowrap">
                  {t("v2.job_requisitions.fields.external_req_id")}
                </div>
                <SendToAts
                  asLink={false}
                  asDropdownMenuLink={false}
                  asButton
                  jobRequisitionId={req.id}
                  systemUid={req.systemUid ?? undefined}
                  title={req.jobTitle}
                  replacementFor={req.replacementFor ?? ""}
                  rmInfoOnly
                  positionId=""
                  orgUnitNames={[]}
                  locationName=""
                  jobDescription={req.jobDescription ?? ""}
                  numPositions={Number(req.numPositions)}
                />
              </div>
            </div>
          )}
          {req.status && req.clientIdentifier && (
            <div className="border-0 border-b border-solid border-b-neutral-8-solid">
              <div className="items-center justify-between p-4 flex">
                <div>{t("v2.job_requisitions.fields.external_req_id")}</div>
                <div>
                  <button className="link" type="button" onClick={handleOpenModal}>
                    {req.clientIdentifier}
                  </button>
                </div>
              </div>
            </div>
          )}
          {req.status && req.clientIdentifier && (
            <div
              className={
                i + 1 < requisitions.length
                  ? "border-0 border-b border-solid border-b-neutral-8-solid"
                  : "-mb-4"
              }
            >
              <div className="items-center justify-between p-4 flex">
                <div>{t("v2.job_requisitions.fields.external_req_status")}</div>
                <div>
                  {req.status ? (
                    <StatusBadge
                      status={req.status}
                      statusLabel={req.statusLabel}
                      displayType="button"
                    />
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          )}
          {req.clientIdentifier && (
            <ExternalReqModal reqId={req.id} isOpen={modalOpen} handleClose={handleCloseModal} />
          )}
        </div>
      ))}
    </div>
  )
}

export { InternalReqInfo }
