/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createApi } from "@reduxjs/toolkit/query/react"

import {
  AddOrgUnitInput,
  AddOrgUnitMutation,
  Collection,
  ColorCodesQuery,
  ColorCodesQueryVariables,
  CurrentCompanyCurrencyCodeQuery,
  CurrentCompanyCurrencyCodeQueryVariables,
  CurrentCompanyCustomFieldsQuery,
  CurrentCompanyCustomFieldsQueryVariables,
  CurrentCompanyFeatureFlagsQuery,
  CurrentCompanyIntegrationFlagsQuery,
  CurrentCompanySuccessionMatrixSettingsQuery,
  CurrentPersonQuery,
  CurrentPersonQueryVariables,
  GetImportTriggerStateQuery,
  GetPersonQuery,
  GetPersonQueryVariables,
  GetPositionDetailsQuery,
  GetPositionDetailsQueryVariables,
  GetPositionQuery,
  GetPositionQueryVariables,
  ImportTriggerState,
  JsonFormQuery,
  JsonFormQueryVariables,
  PeopleConnectionQuery,
  PeopleConnectionQueryVariables,
  PositionAutocompleteConnectionQuery,
  PositionAutocompleteConnectionQueryVariables,
  PositionForListQuery,
  PositionForListQueryVariables,
  ProfilePanelPermissionsQuery,
  ProfilePanelPermissionsQueryVariables,
  SaveRoleInput,
  SaveRoleMutation,
  SearchExistingSubordinatePositionsQuery,
  SearchExistingSubordinatePositionsQueryVariables,
  UpdateColorCodeInput,
  UpdateCompanyCurrencyCodeInput,
  UpdateCompanySuccessionMatrixSettingsInput,
  UpdatePersonOrgChartSettingsInput,
  UsersDestroyInput,
  UsersDestroyMutation,
  VariablePayTypesCreateInput,
  VariablePayTypesCreateMutation,
} from "types/graphql.d"
import { baseGqlQueryFn } from "v2/graphqlClient/baseGqlQueryFn"
import OperationStore from "v2/operation_store"
import { flatMutationOperation, mutationOperation, queryOperation } from "v2/redux/utils/endpoints"

import { CollectionsQueryResponse } from "./types"

export const GraphqlApi = createApi({
  reducerPath: "GraphqlApi",
  baseQuery: baseGqlQueryFn,
  tagTypes: [
    "Approvers",
    "CareerLadder",
    "Chart",
    "ColorCode",
    "CompanyCurrencyCode",
    "HeadcountPlan",
    "HeadcountPlanChanges",
    "JobRequisition",
    "Matrix",
    "Node",
    "PositionType",
    "PositionTypeDetails",
    "PositionTypes",
    "ProfilePanelSettings",
    "RecruitingSettings",
    "Position",
    "User",
  ] as string[],
  endpoints: (builder) => ({
    addOrgUnit: builder.mutation<AddOrgUnitMutation, AddOrgUnitInput>({
      query: (input) => ({
        operationId: OperationStore.getOperationId("AddOrgUnit"),
        variables: { input },
      }),
    }),
    fetchCollections: builder.query({
      query: (query: string) => ({ query, operationName: "CurrentCompanyCollections" }),
      transformResponse: (response: CollectionsQueryResponse): Collection[] => {
        const { collections } = response.currentCompany || {}
        return Object.values(collections || {}).flat()
      },
    }),
    getColorCodes: builder.query({
      query: queryOperation<ColorCodesQueryVariables>("ColorCodes"),
      transformResponse: (response: ColorCodesQuery) => ({
        colorCodeAttributes: response.nodeContainer?.color_code_attributes || [],
        colorCodes: response.nodeContainer?.color_codes || [],
      }),
      providesTags: ["ColorCode"],
    }),
    getCurrencyCode: builder.query<
      CurrentCompanyCurrencyCodeQuery,
      CurrentCompanyCurrencyCodeQueryVariables
    >({
      query: queryOperation("CurrentCompanyCurrencyCode"),
      providesTags: ["CompanyCurrencyCode"],
    }),
    getCurrentPerson: builder.query<CurrentPersonQuery, CurrentPersonQueryVariables>({
      query: queryOperation("CurrentPerson"),
    }),
    getCustomFields: builder.query<
      CurrentCompanyCustomFieldsQuery,
      CurrentCompanyCustomFieldsQueryVariables
    >({
      query: queryOperation("CurrentCompanyCustomFields"),
    }),
    getFeatureFlags: builder.query<CurrentCompanyFeatureFlagsQuery, void>({
      query: queryOperation("CurrentCompanyFeatureFlags"),
    }),
    getImportTriggerState: builder.query<ImportTriggerState | null, void>({
      query: queryOperation("GetImportTriggerState"),
      transformResponse: (res: GetImportTriggerStateQuery) =>
        res?.currentCompany?.importTriggerState ?? null,
    }),
    getIntegrationFlags: builder.query<CurrentCompanyIntegrationFlagsQuery, void>({
      query: queryOperation("CurrentCompanyIntegrationFlags"),
    }),
    getJsonForm: builder.query<JsonFormQuery, JsonFormQueryVariables>({
      query: queryOperation<JsonFormQueryVariables>("JsonForm"),
    }),
    getPerson: builder.query<GetPersonQuery, GetPersonQueryVariables>({
      query: queryOperation<GetPersonQueryVariables>("GetPerson"),
    }),
    getPosition: builder.query<GetPositionQuery, GetPositionQueryVariables>({
      query: queryOperation<GetPositionQueryVariables>("GetPosition"),
      providesTags: (result, error, id) => [
        { type: "Position", positionId: id },
        "PositionForPanel",
      ],
    }),
    getPositionDetails: builder.query<GetPositionDetailsQuery, GetPositionDetailsQueryVariables>({
      query: queryOperation<GetPositionDetailsQueryVariables>("GetPositionDetails"),
      providesTags: (response) =>
        response?.position?.id
          ? ["PositionDetails", `position_${response.position.id}`]
          : ["PositionDetails"],
    }),
    getPositionAutocomplete: builder.query<
      PositionAutocompleteConnectionQuery,
      PositionAutocompleteConnectionQueryVariables
    >({
      query: queryOperation<PositionAutocompleteConnectionQueryVariables>(
        "PositionAutocompleteConnection",
      ),
    }),
    getPositionForList: builder.query<PositionForListQuery, PositionForListQueryVariables>({
      query: queryOperation<PositionForListQueryVariables>("PositionForList"),
    }),
    getProfilePanelPermissions: builder.query<
      ProfilePanelPermissionsQuery,
      ProfilePanelPermissionsQueryVariables
    >({
      query: queryOperation<ProfilePanelPermissionsQueryVariables>("ProfilePanelPermissions"),
    }),
    getSuccessionMatrixSettings: builder.query({
      query: queryOperation("CurrentCompanySuccessionMatrixSettings"),
      providesTags: ["Matrix"],
      transformResponse: (response: CurrentCompanySuccessionMatrixSettingsQuery) =>
        response.currentCompany?.settings.succession.matrix,
    }),
    saveRole: builder.mutation<SaveRoleMutation, { input: SaveRoleInput }>({
      query: mutationOperation<SaveRoleInput>("SaveRole"),
    }),
    saveSuccessionMatrixSettings: builder.mutation({
      query: mutationOperation<UpdateCompanySuccessionMatrixSettingsInput>(
        "UpdateCompanySuccessionMatrixSettings",
      ),
      invalidatesTags: ["Matrix"],
    }),
    searchPeople: builder.query<PeopleConnectionQuery, PeopleConnectionQueryVariables>({
      query: queryOperation<PeopleConnectionQueryVariables>("PeopleConnection"),
    }),
    searchSubordinatePositions: builder.query({
      query: queryOperation<SearchExistingSubordinatePositionsQueryVariables>(
        "SearchExistingSubordinatePositions",
      ),
      transformResponse: (response: SearchExistingSubordinatePositionsQuery) =>
        response.headcountPlan?.allPositionsInScope?.map((projection) => ({
          id: projection.position?.id ?? projection.rootEvent?.uniqueKey,
          title: projection.positionAttributesWithEdits?.title?.label,
          name: projection.position?.person?.name,
          avatar: projection.position?.person?.avatarThumbUrl,
          label:
            projection?.position?.person?.name ||
            projection.positionAttributesWithEdits?.title?.label ||
            "",
          systemIdentifier: projection.systemIdentifier,
          person: projection.position?.person,
          positionEndDate:
            projection.positionAttributesWithEdits?.effective_dates?.position_end_date?.value,
        })),
    }),
    updateColorCode: builder.mutation({
      query: mutationOperation<UpdateColorCodeInput>("UpdateColorCode"),
      invalidatesTags: ["ColorCode"],
    }),
    updateCurrencyCode: builder.mutation({
      query: mutationOperation<UpdateCompanyCurrencyCodeInput>("UpdateCompanyCurrencyCode"),
      invalidatesTags: ["CompanyCurrencyCode"],
    }),
    updatePersonOrgChartSettings: builder.mutation({
      query: mutationOperation<UpdatePersonOrgChartSettingsInput>("UpdatePersonOrgChartSettings"),
      invalidatesTags: ["ColorCode"],
    }),
    usersDestroy: builder.mutation<UsersDestroyMutation, UsersDestroyInput>({
      query: flatMutationOperation<UsersDestroyInput>("UsersDestroy"),
      invalidatesTags: ["User"],
    }),
    variablePayTypesCreate: builder.mutation<
      VariablePayTypesCreateMutation,
      VariablePayTypesCreateInput
    >({
      query: flatMutationOperation<VariablePayTypesCreateInput>("VariablePayTypesCreate"),
    }),
  }),
})

export const {
  useAddOrgUnitMutation,
  useFetchCollectionsQuery,
  useGetColorCodesQuery,
  useGetCurrencyCodeQuery,
  useGetCurrentPersonQuery,
  useGetCustomFieldsQuery,
  useGetFeatureFlagsQuery,
  useGetImportTriggerStateQuery,
  useGetIntegrationFlagsQuery,
  useGetJsonFormQuery,
  useGetPersonQuery,
  useGetProfilePanelPermissionsQuery,
  useLazyGetPersonQuery,
  useGetPositionAutocompleteQuery,
  useGetPositionForListQuery,
  useGetPositionQuery,
  useGetPositionDetailsQuery,
  useGetSuccessionMatrixSettingsQuery,
  useLazyGetPositionQuery,
  useLazySearchPeopleQuery,
  useSaveRoleMutation,
  useSaveSuccessionMatrixSettingsMutation,
  useSearchPeopleQuery,
  useSearchSubordinatePositionsQuery,
  useUpdateColorCodeMutation,
  useUpdateCurrencyCodeMutation,
  useUpdatePersonOrgChartSettingsMutation,
  useUsersDestroyMutation,
  useVariablePayTypesCreateMutation,
} = GraphqlApi
