import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import type { DisplayState } from "v2/react/components/headcountPlanning/types"

interface HeadcountPlanState {
  displayState: DisplayState | null
}

const initialState: HeadcountPlanState = {
  displayState: null,
}

const HeadcountPlanSlice = createSlice({
  name: "headcountPlan",
  initialState,
  reducers: {
    setDisplayState: (state, { payload }: PayloadAction<HeadcountPlanState["displayState"]>) => ({
      ...state,
      displayState: payload,
    }),
  },
})

export const { setDisplayState } = HeadcountPlanSlice.actions
export { HeadcountPlanSlice }
