import React from "react"

import { CompareValues } from "v2/react/components/headcountPlanning/TableDatasheet/CompareValues"
import { StandardEditableCell as StandardEditableCellPrimitive } from "v2/react/components/headcountPlanning/TableDatasheet/StandardEditableCell"
import { StrikethroughCell } from "v2/react/components/headcountPlanning/TableDatasheet/StrikethroughCell"
import { Column } from "v2/react/components/headcountPlanning/TableDatasheet/types"

import { useSaveCell } from "../hooks/useSaveCell"
import { formatBasePay, formatVariablePay, isVariablePayKey } from "./helpers"
import { HeadcountPlanDatasheetRow } from "./types"

type Props = {
  row: HeadcountPlanDatasheetRow
  column: Column<HeadcountPlanDatasheetRow>
  headcountPlanId: string
  participantId?: string
  readOnly: boolean
  editable?: boolean
}
export function StandardEditableCell({
  row,
  column,
  participantId,
  headcountPlanId,
  readOnly,
  editable,
}: Props) {
  const saveFn = useSaveCell(headcountPlanId, column.id, row, participantId)

  const currentValue = getCurrentValue(row, column)
  const compareValue = getCompareValue(row, column)

  if (row.excluded) {
    return <StrikethroughCell value={currentValue} />
  }

  if (readOnly) {
    return (
      <CompareValues
        compareValue={compareValue}
        className="non-editable-cell"
        id={`${column.id}-${row.id}`}
      >
        <span>{currentValue}</span>
      </CompareValues>
    )
  }

  return (
    <StandardEditableCellPrimitive
      columnId={column.id}
      rowId={row.id}
      saveFn={saveFn}
      currentValue={currentValue}
      compareValue={compareValue}
      inputType={columnToInputType(column)}
      inputPlaceholder={columnToPlaceholder(column)}
      editable={editable}
    />
  )
}

function getCurrentValue(
  row: HeadcountPlanDatasheetRow,
  column: Column<HeadcountPlanDatasheetRow>,
) {
  if (isVariablePayKey(column.id)) {
    return formatVariablePay(
      row.position?.positionBasePayAnnualized ?? 0,
      row.positionAttributesWithEdits,
      column.id,
    )
  }

  if (column.id === "budgeted_base_pay_rate") {
    return formatBasePay(row.positionAttributesWithEdits)
  }

  return row.positionAttributesWithEdits[column.id] ?? ""
}

function getCompareValue(
  row: HeadcountPlanDatasheetRow,
  column: Column<HeadcountPlanDatasheetRow>,
) {
  if (row.type !== "modified" || !(column.id in row.payload)) {
    return null
  }

  if (isVariablePayKey(column.id)) {
    return formatVariablePay(
      row.position?.positionBasePayAnnualized ?? 0,
      row.positionAttributes,
      column.id,
    )
  }

  if (column.id === "budgeted_base_pay_rate") {
    return formatBasePay(row.positionAttributes)
  }

  return row.positionAttributes[column.id]
}

function columnToPlaceholder(column: Column<HeadcountPlanDatasheetRow>) {
  if (column.type === "date") {
    return "YYYY-MM-DD"
  }

  return ""
}

function columnToInputType(column: Column<HeadcountPlanDatasheetRow>) {
  if (column.type === "numeric") {
    return "number"
  }

  return "text"
}
