import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { ChangeEvent, KeyboardEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { Person } from "types/graphql"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { usePersonSearch } from "v2/react/hooks/usePersonSearch"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { useNavController } from "v2/react/shared/navigation/useNavController"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { getCookie } from "v2/react/utils/cookies"
import { UrlHelper } from "v2/react/utils/urls"

interface SearchProps {
  placeholder?: string
  tooltipContent?: string
}

function NavSearch({ placeholder, tooltipContent }: SearchProps) {
  const [showTooltip, setShowTooltip] = useState(false)
  const { t } = useTranslation()
  const { toggleNav } = useNavController()

  const handleTooltip = () => {
    if (getCookie("nav_open_state") === "true" || !tooltipContent) return
    setShowTooltip(true)
  }

  const {
    showResultList,
    setShowResultList,
    inputValue,
    setInputValue,
    peopleResult,
    handleInputChange,
  } = usePersonSearch({
    omitValues: [],
    fieldValue: "",
    includeTerminated: true,
  })

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({ showList: showResultList, setShowList: setShowResultList })

  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    handleInputChange(event)
  }

  const expandInput = () => {
    const nav = document.querySelector(".nav")

    const navShrunk = nav?.classList.contains("shrink-nav")
    if (navShrunk) {
      setShowTooltip(false)
      toggleNav()

      // Hack: When the input immediately focuses, it causes a slight layout
      // shift. This timeout allows the nav to expand before focusing the input.
      setTimeout(() => {
        refs.domReference.current?.focus()
      }, 250)
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeIndex != null && peopleResult[activeIndex]) {
      handleResultClick(peopleResult[activeIndex])
    }
  }

  const handleResultClick = (person: Person) => {
    setInputValue("")
    setActiveIndex(null)
    setShowResultList(false)
    refs.domReference.current?.blur()
    document.location.href = UrlHelper.personProfilePath(person.id)
  }

  return (
    <div>
      <Tooltip open={showTooltip} placement="right">
        <TooltipTrigger onMouseEnter={handleTooltip} onMouseLeave={() => setShowTooltip(false)}>
          <div
            role="button"
            tabIndex={0}
            className="search nav-search w-auto"
            onClick={expandInput}
            onKeyDown={expandInput}
          >
            <div className="search-field relative">
              <div className="prefix">
                <FontAwesomeIcon icon={["far", "search"]} />
              </div>
              <input
                className="input prefix-pad"
                type="search"
                placeholder={placeholder}
                ref={refs.setReference}
                value={inputValue}
                /* eslint-disable react/jsx-props-no-spreading */
                {...getReferenceProps({
                  onKeyDown: handleKeyDown,
                  onChange: handleChangeEvent,
                })}
              />
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent className="react-tooltip-content--sm">
          <span className="break-all">{tooltipContent}</span>
        </TooltipContent>
      </Tooltip>
      <DropdownMenu
        showList={showResultList && !!inputValue.trim().length}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="autocomplete-container"
        context={context}
      >
        <div className="list-group autocomplete-list">
          {peopleResult?.map((person, index) => (
            <div
              role="option"
              data-id={person.id}
              data-name={person.name}
              aria-selected={activeIndex === index}
              key={person.id}
              className={personClassName(activeIndex, index)}
              ref={(node) => {
                listRef.current[index] = node
              }}
              /* eslint-disable react/jsx-props-no-spreading */
              {...getItemProps({
                onClick: () => handleResultClick(person),
              })}
            >
              <div className="list-group-item-thumb circle circle-med shrink-0">
                <img alt={person.name || ""} src={person.avatarThumbUrl || ""} />
              </div>
              <div className="list-group-item-text">
                <div className="list-group-item-title break-all text-base-bold">{person.name}</div>
                <div className="text-neutral-64">{person.primaryPosition?.title || " "}</div>
              </div>
            </div>
          ))}
          {peopleResult?.length === 0 && inputValue.trim().length > 0 && (
            <div className="list-group-item disabled text-neutral-64 opacity-100">
              <p>{t("v2.people.search.no_people_found")}</p>
            </div>
          )}
        </div>
      </DropdownMenu>
    </div>
  )
}

export type { SearchProps }
export { NavSearch }

const personClassName = (activeIndex: number | null, index: number) =>
  classNames("list-group-item", { highlight: activeIndex === index })
