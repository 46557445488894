import { defaultMemoize } from "reselect"

import { Maybe } from "types/graphql.d"

export const prepareStyle = defaultMemoize((style, isFirst, color?: Maybe<string>) => {
  const base = color && isFirst ? { ...(style || {}), borderLeft: `5px solid ${color}` } : style
  return {
    ...base,
    borderRightWidth: 0,
    borderTopWidth: 0,
    zIndex: 21,
  }
})
