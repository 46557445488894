import { useEffect, useState } from "react"
import { useWindowSize } from "usehooks-ts"

export const TOOLBAR_LARGE_SC_HEIGHT = 112
export const TOOLBAR_SMALL_SC_HEIGHT = 169

const useOffsetHeight = () => {
  const { width = 0 } = useWindowSize()
  const [offsetHeight, setOffsetHeight] = useState(
    width < 768 ? TOOLBAR_SMALL_SC_HEIGHT : TOOLBAR_LARGE_SC_HEIGHT,
  )
  useEffect(() => {
    const alertTop = document.querySelector(".alert-top")?.getBoundingClientRect().height || 0
    const toolbarHeight =
      (width < 768 ? TOOLBAR_SMALL_SC_HEIGHT : TOOLBAR_LARGE_SC_HEIGHT) + alertTop
    setOffsetHeight(toolbarHeight)
  }, [width])
  return { offsetHeight }
}

export { useOffsetHeight }
