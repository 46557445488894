import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { uniqueId } from "lodash/fp"
import React, { ChangeEvent, KeyboardEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { TagListInteractive } from "v2/react/components/positionTypes/modals/TagListInteractive"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { Pulser } from "v2/react/shared/loaders/Pulser"
import { toTagFormat } from "v2/react/utils/strings"

const EMPTY_LIST: Option[] = []
const ENTER_KEY_CODE = "Enter"
const ESCAPE_KEY_CODE = "Escape"

type Option = { id: string; label: string }

interface Props {
  content: Option[]
  disabled: boolean
  handleGenerate: () => void
  isAiAvailable?: boolean
  labelText: string
  loadingContent: boolean
  options: Option[]
  searchTerm?: string
  setData: (data: React.SetStateAction<Option[]>) => void
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
}

function AITagTextArea({
  content,
  disabled,
  handleGenerate,
  isAiAvailable = true,
  labelText,
  loadingContent,
  options,
  searchTerm,
  setData,
  setSearchTerm,
}: Props) {
  const { t } = useTranslation()
  const id = labelText
  const [value, setValue] = useState(searchTerm || "")
  const [showList, setShowList] = React.useState(false)

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({
    offsetOptions: 8,
    showList,
    setShowList,
    listWidth: 190,
    floatOverrides: { placement: "bottom-start" },
  })

  const focusInput = () => {
    if (!refs.domReference.current) return
    refs.domReference.current.focus()
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    if (newValue.trim().length > 0) {
      setShowList(true)
    } else {
      setShowList(false)
    }
    setSearchTerm(newValue)
    setValue(newValue)
  }

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Add input value as a tag
    if (e.key === ENTER_KEY_CODE && activeIndex === null) {
      handleOptionSelect({ label: value, id: uniqueId("") })
    }
    // Add menu option as a tag
    if (e.key === ENTER_KEY_CODE && activeIndex !== null) {
      const selected = options.find((_, index) => index === activeIndex)
      if (!selected) return
      handleOptionSelect(selected)
    }
    // Clear active index if closing menu
    if (e.key === ESCAPE_KEY_CODE) {
      setActiveIndex(null)
    }
  }

  const handleInputClick = () => {
    setShowList(false)
    setActiveIndex(null)
  }

  const handleOptionSelect = (option: Option) => {
    if (option.label === "") {
      return
    }
    setData((prev) => [...prev, { ...option, label: toTagFormat(option.label) }])
    setValue("")
    setSearchTerm("")
    setShowList(false)
    setActiveIndex(null)
  }

  const handleTagClick = (option: Option) => {
    const filtered = content.filter((tag) => tag.id !== option.id)
    setData(filtered)
  }

  const filterForSelected = (option: Option) => !content.includes(option)
  const menuOptions = options.filter(filterForSelected)

  return (
    <div className="ai-textarea">
      <div className="mb-1 items-center justify-between flex">
        <div className="gap-2 flex">
          <label htmlFor={id}>{labelText}</label>
          {disabled && (
            <div className="tooltip tooltip-top-align-left">
              <FontAwesomeIcon className="!text-neutral-64" icon={["far", "circle-info"]} />
              <div className="tooltip-content max-w-[224px]">
                {t("v2.positions.show.edit_disabled")}
              </div>
            </div>
          )}
        </div>
        {!disabled && isAiAvailable && (
          <button className="btn--sm btn--secondary" onClick={handleGenerate} type="button">
            <FontAwesomeIcon icon={["far", "sparkles"]} />
            {t("v2.position_types.show.generate_with_ai")}
          </button>
        )}
      </div>
      <div className="relative">
        <div
          onClick={focusInput}
          onKeyDown={focusInput}
          tabIndex={0}
          role="textbox"
          className={cn(
            "min-h-[100px] cursor-pointer flex-wrap content-start gap-2 rounded-lg border-none px-3 py-2.5 flex",
            {
              "border--main bg-neutral-3": disabled,
              "border--main hover:border--main-hover bg-white": !disabled,
            },
          )}
        >
          <TagListInteractive
            tagList={loadingContent ? EMPTY_LIST : content}
            onRemove={handleTagClick}
            disabled={disabled}
          />

          <input
            className="h-6 w-fit min-w-4 border-none bg-transparent flex"
            id={id}
            size={value.length || 16}
            type="text"
            value={loadingContent ? "" : value}
            ref={refs.setReference}
            /* eslint-disable react/jsx-props-no-spreading */
            {...getReferenceProps({
              onKeyDown: handleInputKeyDown,
              onChange: handleInputChange,
              onClick: handleInputClick,
            })}
            disabled={disabled || loadingContent}
          />
          <DropdownMenu
            context={context}
            floatingRef={refs.setFloating}
            floatingStyles={{
              ...floatingStyles,
              maxHeight: "206px",
              overflow: "auto",
              width: "190px",
              zIndex: 11,
            }}
            floatingProps={getFloatingProps}
            showList={showList && menuOptions.length > 0}
            wrapperClasses="basic-select-menu"
          >
            {menuOptions.map((option, index) => (
              <li
                className={cn("basic-select-menu__item", {
                  "bg-neutral-3": activeIndex === index,
                })}
                key={option.id}
                ref={(node) => {
                  listRef.current[index] = node
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getItemProps({
                  onClick: () => handleOptionSelect(option),
                })}
              >
                <span>{option.label}</span>
              </li>
            ))}
          </DropdownMenu>
        </div>
        {loadingContent && (
          <div className="absolute left-4 top-4 z-[2]">
            <Pulser />
          </div>
        )}
      </div>
    </div>
  )
}

export { AITagTextArea }
