import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlanningNavTitleWithSubtitle } from "v2/react/components/headcountPlanning/HeadcountPlanningNav"
import {
  useDisplayStateSync,
  useHCPOwnerHeaderData,
  type HCPOwnerHeaderDataProps,
} from "v2/react/components/headcountPlanning/hooks/useHCPHeaderData"
import {
  FinalizePlanButton,
  FinalizePlanModal,
} from "v2/react/components/headcountPlanning/Overview/FinalizePlanButton"
import { NavActiveIcon } from "v2/react/components/headcountPlanning/shared/NavActiveIcon"
import { getNavLinks } from "v2/react/components/headcountPlanning/shared/navigation"
import { PlanTopbarMenu } from "v2/react/components/headcountPlanning/shared/PlanTopbarMenu"
import { PlanTypeIndicator } from "v2/react/components/headcountPlanning/ShowParticipant/PlanTypeIndicator"
import { DisplayState, type ActiveView } from "v2/react/components/headcountPlanning/types"
import { withRootProvider } from "v2/react/components/RootProvider"
import { useActiveView } from "v2/react/hooks/useActiveView"
import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import { ClientOnly } from "v2/react/shared/layout/ClientOnly"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlockSmall } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { UrlHelper } from "v2/react/utils/urls"
import { selectAnyErrors } from "v2/redux/slices/DatasheetSlice/datasheetSelectors"
import { useAppSelector } from "v2/redux/store"

const CSV_DOWNLOAD_REF = "export-hcp"

type HeaderProps = {
  title: string
  headcountPlanName: string
  initialDisplayState: DisplayState
  activeView: ActiveView
} & HCPOwnerHeaderDataProps

const Header = ({
  title,
  headcountPlanName,
  headcountPlanId,
  setBudget,
  hasActiveStatus,
  lockedAt,
  canBeFinalized,
  initialDisplayState,
  activeView,
}: HeaderProps) => {
  const { t } = useTranslation()
  const { featureFlags: ff } = useCurrentActiveSession()
  const [finalizePlanModalIsOpen, setFinalizePlanModalIsOpen] = useState(false)
  const datasheetHasErrors = useAppSelector(selectAnyErrors)
  const { activeView: currentView, handleLinkClick } = useActiveView<ActiveView>({
    initialView: activeView,
  })
  const { displayState, updateDisplayState } = useDisplayStateSync(initialDisplayState, currentView)
  const isOrgChart = currentView === "Org Chart"
  const isTimeline = currentView === "Timeline"
  const isInsights = currentView === "Insights"
  const canExport = !isOrgChart && !isInsights
  const { currentIsFinalized, currentCanBeFinalized, currentSetBudget, currentHasActiveStatus } =
    useHCPOwnerHeaderData({
      headcountPlanId,
      setBudget,
      hasActiveStatus,
      lockedAt,
      canBeFinalized,
    })
  const canToggleDisplayState = !isTimeline && !isInsights && currentIsFinalized

  return (
    <PageNav>
      <TitleBlockLarge>
        <HeadcountPlanningNavTitleWithSubtitle
          title={title}
          subtitle={headcountPlanName}
          subtitleLinkTo={UrlHelper.headcountPlanPath(headcountPlanId)}
        />
      </TitleBlockLarge>
      <LinkBlockSmall>
        <ClientOnly>
          <LinkGroup
            links={getNavLinks({ isOwner: true, active: currentView, headcountPlanId, ff, t })}
            usePjax
            onClick={handleLinkClick}
          />
        </ClientOnly>
      </LinkBlockSmall>
      <ActionBlock>
        <div className="items-center gap-2 flex">
          {currentHasActiveStatus ? <NavActiveIcon /> : null}
          {currentIsFinalized ? (
            // For now, the timeline/insights data is always the approved plan.
            <PlanTypeIndicator type={isTimeline || isInsights ? "approved" : displayState} />
          ) : null}
          {currentIsFinalized ? null : (
            <>
              <ClientOnly>
                <FinalizePlanModal
                  headcountPlanId={headcountPlanId}
                  headcountPlanName={headcountPlanName}
                  isOpen={finalizePlanModalIsOpen}
                  setIsOpen={setFinalizePlanModalIsOpen}
                />
              </ClientOnly>
              <FinalizePlanButton
                className="hidden sm:flex"
                canBeFinalized={currentCanBeFinalized && !datasheetHasErrors}
                setIsOpen={setFinalizePlanModalIsOpen}
              />
            </>
          )}
          {canExport && (
            <ExportButton csvDownloadRef={CSV_DOWNLOAD_REF} className="hidden sm:block" />
          )}
          <PlanTopbarMenu
            isFinalized={currentIsFinalized}
            displayState={displayState}
            setDisplayState={updateDisplayState}
            headcountPlanId={headcountPlanId}
            setBudget={!!currentSetBudget}
            showExportButton={canExport}
            showFinalizeButton={currentCanBeFinalized && !currentIsFinalized && !datasheetHasErrors}
            csvDownloadRef={CSV_DOWNLOAD_REF}
            canToggleDisplayState={canToggleDisplayState}
          />
        </div>
      </ActionBlock>
    </PageNav>
  )
}

const OwnerHeader = withRootProvider(Header, "OwnerHeader")

export { OwnerHeader, CSV_DOWNLOAD_REF }
