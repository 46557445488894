import { createSlice } from "@reduxjs/toolkit"

interface ExportState {
  /**
   * This piece of state is used to trigger an export of data remotely, meaning
   * through a redux action + a useEffect listening for changes to the state.
   *
   * Generally this shouldn't be necessary unless the export button is outside
   * of the same component tree as the data for the export.
   */
  triggerExport: number
}

const initialState: ExportState = {
  triggerExport: 0,
}

const ExportSlice = createSlice({
  name: "export",
  initialState,
  reducers: {
    incrementTriggerExport: (state) => ({
      ...state,
      triggerExport: state.triggerExport + 1,
    }),
    resetTriggerExport: (state) => ({
      ...state,
      triggerExport: 0,
    }),
  },
})

export const { incrementTriggerExport, resetTriggerExport } = ExportSlice.actions
export { ExportSlice }
