import cn from "classnames"
import React from "react"

interface BasicModuleProps {
  title: string
  children: React.ReactNode
  dark?: boolean
  bodyClassNames?: string
}

const BasicModule = ({ title, children, dark, bodyClassNames }: BasicModuleProps) => (
  <div className={cn("module-card mb-0 text-neutral-100", { "bg-transparent": dark })}>
    <div
      className={cn("module-card__header", {
        "bg-primary-3": dark,
      })}
    >
      <div className="text-base-bold">{title}</div>
    </div>
    <div className={cn("module-card__body", bodyClassNames)}>{children}</div>
  </div>
)

export { BasicModule }
