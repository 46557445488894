import React from "react"
import { ChangeHandler, RefCallBack } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { TextInput } from "v2/react/shared/forms/TextInput"
import { Info } from "v2/react/shared/status/Info"

function SystemUidField({
  ats,
  defaultValue,
  name,
  onChange,
  show = true,
  textInputRef,
}: {
  ats: "adp" | "greenhouse" | "lever"
  defaultValue: string | undefined
  name: string
  onChange: ChangeHandler
  show: boolean
  textInputRef: RefCallBack
}) {
  const { t } = useTranslation()
  if (!show) return null

  const label = (
    <div className="cursor-default items-center flex">
      <span>{t(`v2.job_requisitions.modals.send_to_ats.${ats}.field_requisition_id`)}</span>
      <Info
        description={t(
          `v2.job_requisitions.modals.send_to_ats.${ats}.field_requisition_id_tooltip`,
        )}
        tooltipSize="sm"
      />
    </div>
  )

  return (
    <div className="mb-4 cursor-default">
      <TextInput
        id="system_uid"
        name={name}
        inputRef={textInputRef}
        label={label}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </div>
  )
}

export { SystemUidField }
