import cn from "classnames"
import React, { ReactNode } from "react"

interface Props {
  children: ReactNode
}

function PageNav({ children }: Props) {
  return <div className={cn("page-nav zPageNav no-print")}>{children}</div>
}

export default PageNav
