import React from "react"

import { transitionTableCursor } from "v2/redux/slices/DatasheetSlice"
import { selectCursor } from "v2/redux/slices/DatasheetSlice/cursor/cursorSelectors"
import {
  inEitherReadOnEditable,
  inEitherWriteState,
  writingOnEditableCell,
  writingOnEditableCellWithInitial,
} from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import { CellCursor, CursorState } from "v2/redux/slices/DatasheetSlice/cursor/types"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { useDatasheetContext } from "../context"
import { TransitionKeys } from "./cursorKeyMovements"
import { createMovedCursor } from "./moveCursor"
import { Cell } from "./useCellState"

type KeyDownEvent = React.KeyboardEvent<HTMLDivElement>
type InputRef = React.RefObject<HTMLInputElement>

type Opts<TValue> = {
  moveDown?: () => void
  moveUp?: () => void
  getSaveValue: () => TValue | undefined
}

export function useCellHandlers<TValue>(
  cell: Cell,
  inputRef: InputRef | null,
  { getSaveValue }: Opts<TValue>,
) {
  const datasheetContext = useDatasheetContext()
  const appDispatch = useAppDispatch()
  const cursor = useAppSelector(selectCursor)
  const cellStateOnFirstClick = React.useRef<string | null>(null)

  const handleCellClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
    (event) => {
      if (event.detail === 1) {
        cellStateOnFirstClick.current = cell.state
      }

      if (event.detail > 1) {
        const input = inputRef?.current
        if (input) {
          // For cells that weren't selected when the double or triple click was initiated,
          // we want to put the cursor at the end of the input.
          if (cellStateOnFirstClick.current === "idle" || cellStateOnFirstClick.current === null) {
            input.setSelectionRange(input.value.length, input.value.length)
          }
          // For cells that were selected when a triple click was initiated,
          // we want to select the whole input.
          else if (["selected", "erroredSelected"].includes(cellStateOnFirstClick.current)) {
            if (event.detail === 3) {
              input.setSelectionRange(0, input.value.length)
            }
          }
        }
      }

      if (cursor.state === CursorState.WritingOnEditableNeedsFollowUp) return
      if (cell.isEditing || cell.isErroredEditing) return

      if (
        inEitherReadOnEditable(cursor) &&
        cursor.rowId === cell.rowId &&
        cursor.columnId === cell.columnId
      ) {
        appDispatch(
          transitionTableCursor({
            rowId: cell.rowId,
            columnId: cell.columnId,
            editable: true,
            state: CursorState.WritingOnEditable,
            fieldType: cell.fieldType,
            enteredBy: "transition",
            currentValue: null,
          }),
        )
      } else {
        appDispatch(
          transitionTableCursor(
            cell.editable
              ? {
                  rowId: cell.rowId,
                  columnId: cell.columnId,
                  editable: true,
                  state: CursorState.OnEditable,
                  fieldType: cell.fieldType,
                  enteredBy: "placement",
                  currentValue: null,
                }
              : {
                  rowId: cell.rowId,
                  columnId: cell.columnId,
                  editable: false,
                  state: CursorState.OnNonEditable,
                  fieldType: undefined,
                  enteredBy: "placement",
                  currentValue: null,
                },
          ),
        )
      }
    },
    [cell, appDispatch, cursor, inputRef],
  )

  const handleCellKeyUp = React.useCallback(
    (event: KeyDownEvent) => {
      const isWriting = writingOnEditableCell(cursor) || writingOnEditableCellWithInitial(cursor)
      const cursorIfInWrite =
        isWriting && cursor.columnId === cell.columnId && cursor.rowId === cell.rowId
          ? cursor
          : undefined
      const nativeEvent = "nativeEvent" in event ? event.nativeEvent : event
      if (!cursorIfInWrite) return { handled: false, event }

      const options = extractMoveAfterTo(cursorIfInWrite, nativeEvent)
      if (!options) return { handled: false, event }

      event.preventDefault()
      event.stopPropagation()
      inputRef?.current?.blur?.()
      const nextCursor = createMovedCursor(cursor, datasheetContext.table, options.moveAfterTo)
      cell.dispatch({ type: "save", value: getSaveValue(), nextCursor })
      return { handled: true, event }
    },
    [cursor, cell, inputRef, getSaveValue, datasheetContext],
  )

  return { handleCellClick, handleCellKeyUp }
}

export const extractMoveAfterTo = (cursor: CellCursor, event: KeyboardEvent) => {
  if (!inEitherWriteState(cursor)) return undefined

  const transitionMovement = TransitionKeys.findMovement(event)
  if (transitionMovement) return { moveAfterTo: transitionMovement.direction }

  return undefined
}
