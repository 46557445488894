/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { createCalendar } from "@internationalized/date"
import cn from "classnames"
import React, { useEffect, useRef, useState, type DOMAttributes, type KeyboardEvent } from "react"
import {
  mergeProps,
  useDateField,
  useDateSegment,
  useFocus,
  useFocusWithin,
  useKeyboard,
  useLocale,
  usePress,
  type AriaDatePickerProps,
  type DateValue,
} from "react-aria"
import { useDateFieldState, type DateFieldState, type DateSegment } from "react-stately"

const desiredIndexes = [
  { type: "year" },
  { type: "literal" },
  { type: "month" },
  { type: "literal" },
  { type: "day" },
]

interface DateFieldProps extends AriaDatePickerProps<DateValue> {
  /**
   * Ref to be used for the field.
   */
  fieldRef: React.RefObject<HTMLDivElement>
  inputClassName?: string
  /**
   * Runs whenever a segment is focused.
   */
  onSegmentFocus?: (
    e: React.FocusEvent<HTMLDivElement, Element>,
    date?: DateValue,
    ref?: React.RefObject<HTMLDivElement>,
  ) => void
  /**
   * Runs whenever the field value changes by user interaction.
   */
  onDateFieldChange?: (date?: DateValue) => void
  /**
   * Runs when a user hits enter on the field.
   */
  onDateFieldSelection?: (e: KeyboardEvent<HTMLDivElement>) => void
  /**
   * Shows the placeholder value only when focused or missing a date segment
   */
  showPlaceholderOnFocus?: boolean
  /**
   * Whether or not the field is rendered as part of a group.
   * @default false
   */
  isSingleField?: boolean
  /**
   * Whether or not to render the date field with an icon.
   * @default false
   */
  withIcon?: boolean
}

function DateField({
  fieldRef,
  inputClassName,
  onSegmentFocus,
  onDateFieldChange,
  onDateFieldSelection,
  showPlaceholderOnFocus,
  isSingleField = false,
  withIcon = false,
  ...props
}: DateFieldProps) {
  const { locale } = useLocale()
  const [isFieldFocused, setIsFieldFocused] = useState(false)
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
    shouldForceLeadingZeros: true,
  })
  const { labelProps, fieldProps } = useDateField(
    { ...props, "aria-label": "date field" },
    state,
    fieldRef,
  )
  const firstSegmentRef = useRef<HTMLDivElement>(null)

  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocused) => setIsFieldFocused(isFocused),
  })
  const { keyboardProps } = useKeyboard({
    onKeyDown: (e) => {
      if (e.key === "Enter") {
        onDateFieldSelection?.(e)
      }
    },
  })

  const { pressProps: iconPressProps } = usePress({
    onPress: () => {
      // Focus on the first segment when the icon is pressed.
      firstSegmentRef?.current?.focus()
    },
  })

  useEffect(() => {
    if (isFieldFocused) {
      onDateFieldChange?.(state.value)
    }
  }, [state.value, onDateFieldChange, isFieldFocused])

  const sortedSegments: DateSegment[] = []
  desiredIndexes.forEach((value) => {
    const matched = state.segments.find((segment) => segment.type === value.type)
    if (matched) sortedSegments.push(matched)
  })

  const numericSegments = sortedSegments.filter((d) => !Number.isNaN(Number(d.text))).length

  return (
    <div
      className={cn(
        "Date-Field border--main grow rounded-lg focus-within:z-2 hover:z-1",
        inputClassName,
        {
          "hover:border--main-hover bg-white": !props.isDisabled,
          "bg-neutral-3": props.isDisabled,
          "focused-placeholder": showPlaceholderOnFocus,
          "unfocused-placeholder":
            showPlaceholderOnFocus && numericSegments < 3 && numericSegments > 0,
        },
      )}
    >
      <span {...labelProps}>{props.label}</span>
      <div
        {...mergeProps(fieldProps, keyboardProps, focusWithinProps)}
        ref={fieldRef}
        className={cn(
          "Field h-6 items-center justify-start border-0 border-solid border-neutral-8 px-2.5 text-sm flex sm:h-10 sm:text-base",
          {
            "cursor-pointer": !props.isDisabled,
            "border-r": !isSingleField,
            "prefix-pad": withIcon,
          },
        )}
      >
        {withIcon && (
          <div {...iconPressProps} className="prefix">
            <FontAwesomeIcon icon={["far", "calendar"]} />
          </div>
        )}
        {sortedSegments.map((segment, i) => (
          // We wrap this in a span to avoid triggering focus when clicking
          // below the segment.
          // see: https://github.com/adobe/react-spectrum/issues/3164
          //
          // Also (and inexplicably), if we don't use `i` as the key here, the
          // segment functionality breaks.
          /* eslint-disable react/no-array-index-key */
          <span key={i}>
            <DateSegmentPart
              segment={segment}
              state={state}
              isDisabled={props.isDisabled ?? false}
              onSegmentFocus={onSegmentFocus}
              externalRef={i === 0 ? firstSegmentRef : undefined}
            />
          </span>
        ))}
      </div>
    </div>
  )
}

interface DateSegmentProps extends DOMAttributes<DateSegment> {
  onSegmentFocus: DateFieldProps["onSegmentFocus"]
  segment: DateSegment
  state: DateFieldState
  isDisabled: boolean
  externalRef?: React.RefObject<HTMLDivElement>
}

function DateSegmentPart({
  onSegmentFocus,
  segment,
  state,
  externalRef,
  isDisabled,
}: DateSegmentProps) {
  const ref = useRef<HTMLDivElement>(null)
  const segmentRef = externalRef ?? ref

  const { segmentProps } = useDateSegment(segment, state, segmentRef)
  const { focusProps } = useFocus<HTMLDivElement>({
    onFocus: (e) => {
      onSegmentFocus?.(e, state.value, segmentRef)
    },
    isDisabled,
  })

  return (
    <div
      {...mergeProps(segmentProps, focusProps)}
      ref={segmentRef}
      className={cn("segment rounded-sm bg-transparent uppercase focus:bg-primary-100", {
        "placeholder text-neutral-64": segment.isPlaceholder,
      })}
    >
      {segment.text === "/" ? "-" : segment.text}
    </div>
  )
}

export { DateField }
export type { DateFieldProps }
