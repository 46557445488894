import { get, has, map } from "lodash"

let instance

const ServerKeys = Object.freeze({ NodeKey: "node_key" })

class RelationalNodeDataStore {
  static get instance() {
    if (!instance) throw new Error("Invalid access - load data before use")
    return instance
  }

  static load(initial) {
    // TODO: Handle when loading more data into the existing instance.
    if (instance) throw new Error("Loading additional data is not implemented")
    instance = new RelationalNodeDataStore(initial)
  }

  constructor(initial = {}) {
    this.index = { ...(initial.index || {}) }
    this.keys = { ...(initial.keys || {}) }
  }

  allOf = (namespace, callback = undefined) => {
    const into = callback || this.getFieldDefinition
    return map(this.keys[namespace] || [], into)
  }

  hasAnyOf = (namespace) => (this.keys[namespace] || []).length > 0

  hasFieldDefinition = (uniqueKey) => has(this.index, uniqueKey)

  getFieldDefinition = (uniqueKey, fallback = null) => get(this.index, uniqueKey, fallback)

  getShortFieldLabel = (uniqueKey, fallback = "") => {
    const definition = this.getFieldDefinition(uniqueKey, {})
    if (uniqueKey.endsWith("_type")) {
      return definition.short_display_pay_type
    }
    return definition.short_display_name || this.getFieldLabel(uniqueKey, fallback)
  }

  getFieldLabel = (uniqueKey, fallback = "") => {
    const definition = this.getFieldDefinition(uniqueKey, {})
    if (uniqueKey.endsWith("_type")) {
      return definition.display_pay_type
    }
    return definition.label || definition.name || fallback
  }

  fieldLabelOrTranslatedValue = (field, fallback = "") => {
    if (this.hasFieldDefinition(field)) return this.getFieldLabel(field, fallback)

    return `field_${field}`.t("org_chart")
  }

  getNodeKey = (uniqueKey, fallback = null) => {
    const definition = this.getFieldDefinition(uniqueKey, {})
    return get(definition, ServerKeys.NodeKey, fallback)
  }

  valueOf = (uniqueKey, nodeData, fallback = null) => {
    const keyPath = [this.getNodeKey(uniqueKey), uniqueKey]
    return get(nodeData, keyPath, fallback)
  }
}

export default RelationalNodeDataStore
