import fp from "lodash/fp"

import { Maybe, StringFieldSuggestion, TagsFieldSuggestion } from "types/graphql"
import { FieldSuggestionPersistedState, FieldSuggestionType } from "types/graphql.enums"
import { FieldSuggestionState } from "v2/redux/slices/FieldSuggestionSlice"

import { FieldSuggestion, SuggestionPredicate, SuggestionTypePredicate } from "./types"

type StatePredicate = (input: string | null | undefined) => boolean

/** Type Predicates */

/* eslint-disable no-underscore-dangle */
export const isStringSuggestion = (
  suggestion: FieldSuggestion,
): suggestion is StringFieldSuggestion => suggestion.__typename === "StringFieldSuggestion"
export const isTagsSuggestion = (suggestion: FieldSuggestion): suggestion is TagsFieldSuggestion =>
  suggestion.__typename === "TagsFieldSuggestion"
/* eslint-enable no-underscore-dangle */

// State predicates
// TODO: Drop "re" from "regener..."
export const isAccepted: StatePredicate = fp.eq(FieldSuggestionState.Accepted)
export const isBackfilled: StatePredicate = fp.eq(FieldSuggestionState.Backfilled)
export const isDeclined: StatePredicate = fp.eq(FieldSuggestionState.Declined)
export const isInitialized: StatePredicate = fp.eq(FieldSuggestionState.Initialized)
export const isInitializeFailed: StatePredicate = fp.eq(FieldSuggestionState.InitializeFailed)
export const isInitializing: StatePredicate = fp.eq(FieldSuggestionState.Initializing)
export const isRegenerated: StatePredicate = fp.eq(FieldSuggestionState.Generated)
export const isRegenerateFailed: StatePredicate = fp.eq(FieldSuggestionState.GenerateFailed)
export const isRegenerating: StatePredicate = fp.eq(FieldSuggestionState.Generating)

export const isAcceptedOrDeclined: StatePredicate = fp.anyPass([isAccepted, isDeclined])
export const isBusy: StatePredicate = fp.anyPass([isInitializing, isRegenerating])
export const isEphemeralState: StatePredicate = fp.anyPass([
  isRegenerated,
  isRegenerateFailed,
  isRegenerating,
])

// Suggestion predicates

const checksIfState =
  (statePredicate: StatePredicate): SuggestionPredicate =>
  (suggestion) =>
    !fp.isNil(suggestion) && statePredicate(suggestion.state)

export const isSuggestionAccepted: SuggestionPredicate = checksIfState(isAccepted)
export const isSuggestionBackfilled: SuggestionPredicate = checksIfState(isBackfilled)
export const isSuggestionAwaitingAction: SuggestionPredicate = (suggestion) =>
  !!suggestion?.isAwaitingAction
export const isSuggestionBusy: SuggestionPredicate = checksIfState(isBusy)
export const isSuggestionDeclined: SuggestionPredicate = checksIfState(isDeclined)
export const isSuggestionInInitialState: SuggestionPredicate = (suggestion) =>
  Boolean(suggestion && suggestion.hasInitialized)
export const isSuggestionInitialized: SuggestionPredicate = checksIfState(isInitialized)
export const isSuggestionInitializeFailed: SuggestionPredicate = checksIfState(isInitializeFailed)
export const isSuggestionInitializing: SuggestionPredicate = checksIfState(isInitializing)
export const isSuggestionRegenerated: SuggestionPredicate = checksIfState(isRegenerated)
export const isSuggestionRegenerateFailed: SuggestionPredicate = checksIfState(isRegenerateFailed)
export const isSuggestionRegenerating: SuggestionPredicate = checksIfState(isRegenerating)

export const mapToStateFlags = (suggestion: Maybe<FieldSuggestion>) => ({
  isAccepted: isSuggestionAccepted(suggestion),
  isAwaitingAction: isSuggestionAwaitingAction(suggestion),
  isBackfilled: isSuggestionBackfilled(suggestion),
  isBusy: isSuggestionBusy(suggestion),
  isDeclined: isSuggestionDeclined(suggestion),
  isInInitialState: isSuggestionInInitialState(suggestion),
  isInitialized: isSuggestionInitialized(suggestion),
  isInitializing: isSuggestionInitializing(suggestion),
  isInitializeFailed: isSuggestionInitializeFailed(suggestion),
  isRegenerated: isSuggestionRegenerated(suggestion),
  isRegenerateFailed: isSuggestionRegenerateFailed(suggestion),
  isRegenerating: isSuggestionRegenerating(suggestion),
})

// Misc utils

export function findBestMatchingSuggestion<T extends FieldSuggestion>(
  suggestions: FieldSuggestion[],
  field: string,
  predicate: SuggestionTypePredicate<T>,
) {
  const suggestion = suggestions.find((suggestion) => suggestion.field === field)
  return suggestion && predicate(suggestion) ? suggestion : undefined
}

export const deriveValue = <ValueType>(
  actual: Maybe<ValueType>,
  initiallySuggested: Maybe<ValueType>,
  fallback: ValueType,
  fieldSuggestion: Maybe<FieldSuggestion>,
  hasAiFeatureFlag: boolean,
) => {
  if (!fp.isNil(actual)) return actual
  if (!hasAiFeatureFlag) return fallback
  if (isSuggestionAwaitingAction(fieldSuggestion) && initiallySuggested) return initiallySuggested
  return fallback
}

/**
 * Fallbacks exist to support scenarios where a model is missing one or more
 * field suggestions.
 *
 * This is most likely to occur for models that predate the field
 * suggestion(s). Furthermore, when they don't exist, it's likely the server is
 * not "initializing" them. For these reasons, the fallback uses a "declined"
 * state. This prevents funky UI state while supporting our AI features e.g.
 * generating a suggestion.
 */
export const Fallbacks = {
  stringFieldSuggestion: fp.memoize(
    (field: string): StringFieldSuggestion => ({
      __typename: "StringFieldSuggestion",
      field,
      hasInitialized: false,
      initializedStringValue: "",
      isAwaitingAction: false,
      state: FieldSuggestionPersistedState.Backfilled,
      stringValue: "",
      type: FieldSuggestionType.String,
    }),
  ),

  tagsFieldSuggestion: fp.memoize(
    (field: string): TagsFieldSuggestion => ({
      __typename: "TagsFieldSuggestion",
      domain: field,
      field,
      hasInitialized: false,
      initializedTags: [],
      isAwaitingAction: false,
      state: FieldSuggestionPersistedState.Backfilled,
      tags: [],
      type: FieldSuggestionType.Tags,
    }),
  ),
}
