import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { memo, useState } from "react"
import { useTranslation } from "react-i18next"

import type { SunburstNode } from "v2/dashboards/charts/sunburst"
import type { CareerLadderPositionTypes } from "v2/react/components/opportunities/Index/LadderSection"
import { PaginatedPositionTypeCards } from "v2/react/components/opportunities/Index/PaginatedPositionTypeCards"
import { PositionTypesPlatter } from "v2/react/components/opportunities/Index/PositionTypesPlatter"
import { OpportunitiesSunburst } from "v2/react/components/opportunities/OpportunitiesSunburst"
import RootProvider from "v2/react/components/RootProvider"
import { PositionTypeSearchInput } from "v2/react/shared/forms/PositionTypeSearchInput"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { UrlHelper } from "v2/react/utils/urls"
import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"

interface OpportunitiesIndexProps {
  sunburstData: SunburstNode | null
  companyName: string
  /**
   * The current person's position type within their career ladder, plus the
   * next two levels of position types within the ladder.
   */
  careerLadderPositionTypes: CareerLadderPositionTypes | null
  /**
   * We show an empty state if there are no position types to display and if
   * there is no current career ladder position type.
   */
  showEmptyState?: boolean
}

const MemoizedSunburst = memo(OpportunitiesSunburst)

function WithProvider({
  sunburstData,
  companyName,
  careerLadderPositionTypes,
  showEmptyState,
}: OpportunitiesIndexProps) {
  const [selectedJobFamily, setSelectedJobFamily] = useState<SunburstNode | null>(null)
  const { t } = useTranslation()

  return (
    <div>
      <div
        className={cn(
          "bg-gradient-blue-to-b page-pad-sans-large-bottom flex-col items-center gap-4 flex",
          {
            "min-h-[50vh]": sunburstData,
          },
        )}
      >
        <div className="flex-col items-center gap-1 text-center flex">
          <h1 className="text-neutral-100">
            {t("v2.opportunities.index.header", { company_name: companyName })}
          </h1>
          <p className="text-base text-neutral-64">{t("v2.opportunities.index.sub_header")}</p>
        </div>
        <div className="w-full 480:w-72">
          <div className="search">
            <PositionTypeSearchInput
              onSelect={(positionType: PositionTypesConnectionNode) => {
                const url = UrlHelper.opportunityDetailsPath(
                  idFromUniqueKey(positionType.uniqueKey || ""),
                )
                window.location.href = url
              }}
              placeholder={t("v2.opportunities.index.search_placeholder")}
              disabled={showEmptyState}
              htmlForId="opportunities-exploration-position-type-search"
              excludeEmpty
            />
          </div>
        </div>

        {sunburstData && (
          <MemoizedSunburst data={sunburstData} setSelectedJobFamily={setSelectedJobFamily} />
        )}
      </div>
      <div className="page-pad flex-col gap-8 flex">
        {showEmptyState && (
          <div className="flex-col items-center gap-4 py-60 flex">
            <div className="h-12 w-12 shrink-0 grow-0 items-center justify-center rounded-full bg-primary-8-solid text-primary-100 flex">
              <FontAwesomeIcon icon={["far", "compass"]} className="fa-lg" />
            </div>
            <div className="max-w-[15rem] text-center text-base text-neutral-64">
              {t("v2.opportunities.index.no_set_job_defs")}
            </div>
          </div>
        )}
        {selectedJobFamily && !showEmptyState && (
          <PaginatedPositionTypeCards
            jobFamily={selectedJobFamily}
            title={t("v2.opportunities.index.job_family_jobs_header", {
              name: selectedJobFamily.name,
              count: selectedJobFamily.totalNestedNodes,
              interpolation: { escapeValue: false },
            })}
          />
        )}
        {!selectedJobFamily && !showEmptyState && (
          <PositionTypesPlatter careerLadderPositionTypes={careerLadderPositionTypes} />
        )}
      </div>
    </div>
  )
}

function Index(props: OpportunitiesIndexProps) {
  return (
    <RootProvider>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WithProvider {...props} />
    </RootProvider>
  )
}

export { Index }
