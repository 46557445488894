import * as d3 from "d3"

/*
 * Helper: Insert line breaks into tick labels.
 */
function insertLineBreaks(d) {
  const el = d3.select(this)
  const words = d.split(" ")
  el.text("")
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; i++) {
    const tspan = el.append("tspan").text(words[i])
    if (i > 0) tspan.attr("x", 0).attr("dy", "12")
  }
}

/*
 * Helper: Create a rounded rectangle path.
 * For positive bars, round the top corners.
 * For negative bars, round the bottom corners.
 */
function roundedRectPath(xPos, yPos, w, h, r, roundTop) {
  // eslint-disable-next-line no-param-reassign
  r = Math.min(r, h / 2, w / 2)
  if (roundTop) {
    // Round top corners.
    return `
        M${xPos},${yPos + r}
        A${r},${r} 0 0 1 ${xPos + r},${yPos}
        H${xPos + w - r}
        A${r},${r} 0 0 1 ${xPos + w},${yPos + r}
        V${yPos + h}
        H${xPos}
        Z
      `
  }
  // Round bottom corners.
  return `
        M${xPos},${yPos}
        H${xPos + w}
        V${yPos + h - r}
        A${r},${r} 0 0 1 ${xPos + w - r},${yPos + h}
        H${xPos + r}
        A${r},${r} 0 0 1 ${xPos},${yPos + h - r}
        Z
      `
}

/**
 * Aids in positioning a tooltip to the top right of an element in an svg chart.
 * If the tooltip overflows the right edge of the SVG, it will be positioned to
 * the top left.
 */
function moveTooltip(
  event,
  tooltip,
  svg,
  additionalSelectorClass = null,
  contentSelector = "#content",
) {
  // Get the SVG's bounding box
  const svgBounds = svg.node().getBoundingClientRect()

  // Get the content container
  const contentContainer = d3.select(contentSelector).node()
  const contentBounds = contentContainer.getBoundingClientRect()

  // Get scroll position of the content container
  const scrollTop = contentContainer.scrollTop
  const scrollLeft = contentContainer.scrollLeft

  // Get the current element's class
  let selectorClass = `${event.currentTarget.classList[0]}`
  if (additionalSelectorClass) {
    selectorClass = `.${selectorClass}.${additionalSelectorClass}`
  }

  // We want to render the tooltip at the topmost part of the element
  const target = d3.selectAll(selectorClass).node()
  const targetBounds = target.getBoundingClientRect()

  const tooltipWidth = tooltip.node().offsetWidth
  const offsetX = 8 // horizontal gap between element and tooltip

  let left = targetBounds.right + offsetX
  // If the tooltip would exceed the right edge of the SVG, position it to the left
  if (left + tooltipWidth > svgBounds.right) {
    left = targetBounds.left - tooltipWidth - offsetX
  }

  // Position the tooltip aligned with the top of the element
  const top = targetBounds.top

  // Calculate position relative to the content container, accounting for scroll
  const relativeLeft = left - contentBounds.left + scrollLeft
  const relativeTop = top - contentBounds.top + scrollTop

  tooltip.style("left", `${relativeLeft}px`).style("top", `${relativeTop}px`)
}

const generateYAxis = (
  svg,
  y,
  margin,
  height,
  width,
  axisColor,
  fontFamily,
  yAxisLabel,
  axisGridLineColor,
  useGridLines,
  minTickSpacing = 40,
  numTicks = height / minTickSpacing,
  tickFormat = null,
) => {
  const yAxisGenerator = d3.axisLeft(y).ticks(numTicks).tickFormat(tickFormat).tickSizeOuter(0)
  const yAxisGroup = svg
    .append("g")
    .attr("id", "yAxis")
    .attr("transform", `translate(${margin.left},0)`)
    .call(yAxisGenerator)

  // If a y‑axis label is provided, add it.
  if (yAxisLabel) {
    yAxisGroup
      .append("text")
      .attr("x", -margin.left)
      .attr("y", 10)
      .attr("fill", axisColor)
      .attr("text-anchor", "start")
      .style("font-family", fontFamily)
      .text(yAxisLabel)
  }

  yAxisGroup.selectAll("text").style("fill", axisColor).style("font-family", fontFamily)
  yAxisGroup.select(".domain").remove()
  yAxisGroup.selectAll("line").attr("stroke", axisColor)

  if (useGridLines) {
    yAxisGroup.call((g) => {
      g.selectAll(".grid-line").remove()
      g.selectAll(".tick line")
        .clone()
        .attr("class", "grid-line")
        .attr("x2", width - margin.left - margin.right)
        .attr("stroke", axisGridLineColor)
    })
  }
}

const generateXAxis = (
  svg,
  x,
  width,
  height,
  margin,
  axisColor,
  fontFamily,
  minTickSpacing,
  xAxisLabel,
  y,
  shouldInsertLineBreaks = false,
) => {
  const domain = x.domain()
  const maxTicks = Math.floor(width / minTickSpacing)
  const tickInterval = domain.length > maxTicks ? Math.ceil(domain.length / maxTicks) : 1

  const xAxisGenerator = d3
    .axisBottom(x)
    .tickSizeOuter(0)
    .tickValues(domain.filter((_d, i) => i % tickInterval === 0))
  const xAxisGroup = svg
    .append("g")
    .attr("id", "xAxis")
    .attr("transform", `translate(0,${height - margin.bottom})`)
    .call(xAxisGenerator)

  xAxisGroup
    .selectAll("text")
    .style("fill", axisColor)
    .style("font-family", fontFamily)
    .each(function (d) {
      if (shouldInsertLineBreaks) {
        insertLineBreaks.call(this, d)
      }
    })
  // Tick lines are styled by the axis generator.
  xAxisGroup.selectAll("line").attr("stroke", axisColor)

  // Move the x-axis domain to align with 0 on the y-axis.
  const offset = y(0) - (height - margin.bottom)
  xAxisGroup
    .select(".domain")
    .attr("transform", `translate(0, ${offset})`)
    .attr("stroke", axisColor)

  // If an x‑axis label is provided, add it.
  if (xAxisLabel) {
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - 5) // positioned a little below the axis
      .attr("fill", axisColor)
      .attr("text-anchor", "middle")
      .style("font-family", fontFamily)
      .text(xAxisLabel)
  }
}

export { generateYAxis, generateXAxis, roundedRectPath, insertLineBreaks, moveTooltip }
