import { EntityId } from "@reduxjs/toolkit"
import { z } from "zod"

import { StringFieldSuggestion, TagsFieldSuggestion } from "types/graphql"

export type Suggestion = TagsFieldSuggestion | StringFieldSuggestion

export type BaseSuggestionEntry = {
  id: string
  entityId: string
  isAwaitingAction?: boolean
  hasInitialized?: boolean
  field: string
  state: string
}

export interface StringSuggestionEntry extends BaseSuggestionEntry {
  suggestion: StringFieldSuggestion
  type: "string"
  value: string | null
  initializedValue?: string | null
  errorReason?: string | null
}

export interface TagsSuggestionEntry extends BaseSuggestionEntry {
  suggestion: TagsFieldSuggestion
  type: "tags"
  value: string[] | null
  initializedValue?: string[] | null
  errorReason?: string | null
}

export type FieldSuggestionEntry = StringSuggestionEntry | TagsSuggestionEntry

export enum FieldSuggestionState {
  Generating = "generating",
  Generated = "generated",
  GenerateFailed = "generate_failed",
  Initializing = "initializing",
  Initialized = "initialized",
  InitializeFailed = "initialize_failed",
  Accepted = "accepted",
  Backfilled = "backfilled",
  Declined = "declined",
}

export const StringFieldEventPayloadSchema = z.object({
  errorReason: z.string().nullable(),
  eventType: z.nativeEnum(FieldSuggestionState),
  field: z.string(),
  hasInitialized: z.boolean(),
  initializedValue: z.string().nullable(),
  isAwaitingAction: z.boolean(),
  type: z.literal("string"),
  value: z.string().nullable(),
})
export type StringFieldEventPayload = z.infer<typeof StringFieldEventPayloadSchema>

export const TagsFieldEventPayloadSchema = z.object({
  errorReason: z.string().nullable(),
  eventType: z.nativeEnum(FieldSuggestionState),
  field: z.string(),
  hasInitialized: z.boolean(),
  initializedValue: z.array(z.string()).nullable(),
  isAwaitingAction: z.boolean(),
  type: z.literal("tags"),
  value: z.array(z.string()).nullable(),
})
export type TagsFieldEventPayload = z.infer<typeof TagsFieldEventPayloadSchema>

export const EventPayloadSchema = z.discriminatedUnion("type", [
  StringFieldEventPayloadSchema,
  TagsFieldEventPayloadSchema,
])
export type EventPayload = z.infer<typeof EventPayloadSchema>

export const SocketMessageSchema = z.object({
  subjectId: z.string(),
  data: EventPayloadSchema,
})
export type SocketMessage = z.infer<typeof SocketMessageSchema>
export type MessageData = SocketMessage["data"]

export type MatchFieldSuggestionEventArg = {
  into: FieldSuggestionState[]
  field: string
  entityId: EntityId
}

export type BeginGeneratingFieldSuggestionArg = {
  entityId: string
  field: string
  timeoutMs?: number
}

export type CancelGeneratingFieldSuggestionArg = {
  entityId: string
  field: string
}

export type MatchGenerateFieldSuggestionCancelationArg = {
  field: string
  entityId: EntityId
}
