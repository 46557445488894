import { appContainer } from "v2/appContainer"
import i18nManifest from "v2/i18n-manifest.json"
import { loadedI18n } from "v2/redux/slices/SessionSlice"

interface I18nManifest {
  [localeFile: string]: string
}

interface LocaleValues {
  [key: string]: string | LocaleValues
}

interface LocaleData {
  [key: string]: LocaleValues
}

class I18nDataLoader {
  /**
   * Only fetches the locale data if it hasn't been loaded yet.
   */
  public static maybeLoadLocaleAndUpdateAppContainer(currentLanguage: string): Promise<void> {
    if (appContainer.reduxStore.getState().session.loadedI18n) {
      return Promise.resolve()
    }

    return this.loadLocaleAndUpdateAppContainer(currentLanguage)
  }

  public static loadLocaleAndUpdateAppContainer(currentLanguage: string): Promise<void> {
    return this.loadLocaleData(currentLanguage).then((localeData) => {
      if (localeData && localeData[currentLanguage]) {
        Object.keys(localeData[currentLanguage]).forEach((key) => {
          appContainer.i18nInstance.addResourceBundle(
            currentLanguage,
            key,
            localeData[currentLanguage][key],
            true,
            true,
          )
        })
        appContainer.maybeChangeI18nLocale({ locale: currentLanguage })
        appContainer.reduxStore.dispatch(loadedI18n(true))
      }
    })
  }

  private static loadLocaleData(currentLanguage: string): Promise<LocaleData | null> {
    const manifest: I18nManifest = i18nManifest
    const localeFileOriginalFilename = `app-${currentLanguage}.json`
    const localeFileWithDigest = manifest[localeFileOriginalFilename]

    return fetch(`/javascripts/i18n/${localeFileWithDigest}`)
      .then((response) => response.json())
      .catch(() => null)
  }
}

export default I18nDataLoader
