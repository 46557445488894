import { useEffect, useState, type ReactNode } from "react"

interface ClientOnlyProps {
  children: ReactNode
}

/**
 * Helper function to protect again SSR hydration issues. Wrap this around parts
 * of a component that should only be rendered on the client.
 */
const ClientOnly = ({ children }: ClientOnlyProps) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return hasMounted ? children : null
}

export { ClientOnly }
