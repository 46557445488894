import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import dayjs, { Dayjs } from "dayjs"
import React from "react"

import { useDateFormatter } from "v2/react/hooks/useDateFormatter"

interface ShortcutButtonProps {
  date: string
  isSelected: boolean
  onClick: () => void
  label: string
  cutoffDate: Dayjs
}

function DateShortcutButton({ cutoffDate, date, isSelected, onClick, label }: ShortcutButtonProps) {
  const { parseAndFormat } = useDateFormatter()
  const localizedDate = parseAndFormat(date)
  const isDisabled = dayjs(date).isBefore(cutoffDate)

  return (
    <button
      className={cn("focus:elevation--overlay w-full rounded py-3", {
        "bg-transparent": !isSelected,
        "bg-primary-3 font-bold": isSelected,
        "opacity-50": isDisabled,
      })}
      type="button"
      disabled={isDisabled}
      onClick={onClick}
    >
      <div className="justify-between flex">
        <span className="flex-grow text-left">{label}</span>
        <span className="flex-grow text-right">{localizedDate}</span>
        <span className="ml-2 w-[14px]">
          {isSelected && <FontAwesomeIcon icon={["fas", "check-circle"]} />}
        </span>
      </div>
    </button>
  )
}

export { DateShortcutButton }
