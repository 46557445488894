import { useEffect, useMemo } from "react"

import { GraphqlApi } from "v2/redux/GraphqlApi"
import { useAppDispatch } from "v2/redux/store"

/**
 * Hook to invalidate GraphQL cache tags when a modal event occurs. Useful when
 * we need the submission of a pjax modal to trigger refetching of data through
 * RTK Query.
 */
const useModalTagInvalidation = (
  modalSelector: string,
  tagsToInvalidate: string[],
  eventName = "modal:save",
) => {
  const dispatch = useAppDispatch()
  const memoizedTagsToInvalidate = useMemo(() => tagsToInvalidate, [tagsToInvalidate])

  useEffect(() => {
    const $ = window?.$
    const onmount = $?.onmount
    if (!onmount || !$) return

    // Create a handler function that we can reference for cleanup
    const handleModalEvent = () => {
      dispatch(GraphqlApi.util.invalidateTags(memoizedTagsToInvalidate))
    }

    // Track whether we've mounted our handler
    let handlerMounted = false

    // Use a function for the onmount callback that we can clean up
    const setupModalHandler = () => {
      if (!handlerMounted) {
        const $modal = $(modalSelector)
        $modal.on(eventName, handleModalEvent)
        handlerMounted = true
      }
    }

    onmount(modalSelector, setupModalHandler)

    // Clean up only our specific handler when the component unmounts
    // eslint-disable-next-line consistent-return
    return () => {
      const $modal = $(modalSelector)
      if ($modal.length) {
        $modal.off(eventName, handleModalEvent)
      }
    }
  }, [dispatch, modalSelector, eventName, memoizedTagsToInvalidate])
}

export { useModalTagInvalidation }
