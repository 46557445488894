import dayjs from "dayjs"
import type { TFunction } from "i18next"

import { TimelineRow, TimelineTotalRow } from "types/graphql"
import { measureText } from "v2/react/utils/strings"

function matchArraysUpToIndex(array1: unknown[], array2: unknown[], index: number) {
  return array1.slice(0, index + 1).every((value, i) => value === array2[i])
}

export function inSameGroupUpToIndex(
  row1: TimelineRow,
  row2: TimelineRow | undefined,
  index: number,
) {
  return row2 !== undefined && matchArraysUpToIndex(row1.groupByCells, row2.groupByCells, index)
}

export function countRowsInSameGroupUpToIndex(
  rows: TimelineRow[],
  row: TimelineRow,
  index: number,
) {
  return rows.filter((r) => matchArraysUpToIndex(r.groupByCells, row.groupByCells, index)).length
}

export function countRowsInSameMetricUpToIndex(
  rows: TimelineRow[],
  row: TimelineRow,
  index: number,
) {
  return rows.filter((r) =>
    // Makes sure we are matching metrics based on the group and metric pieces of the id.
    matchArraysUpToIndex(
      [
        r.id
          .split(".")
          .filter((part) => part !== r.kind)
          .join("."),
      ],
      [
        row.id
          .split(".")
          .filter((part) => part !== row.kind)
          .join("."),
      ],
      index,
    ),
  ).length
}

export function countRowsInSameMetricUpToTotalIndex(
  rows: TimelineTotalRow[],
  row: TimelineTotalRow,
  index: number,
) {
  return rows.filter((r) => matchArraysUpToIndex([r.metric], [row.metric], index)).length
}

type GetTooltipMessageArgs = {
  t: TFunction
  translationPath?: string
  isFirstColumn: boolean
  isLastColumn: boolean
  timelineStartDate: string
  intervalStartDate: string
  timelineEndDate: string
  intervalEndDate: string
}
export function getTooltipMessage({
  t,
  translationPath,
  timelineStartDate,
  intervalStartDate,
  timelineEndDate,
  intervalEndDate,
  isFirstColumn,
  isLastColumn,
}: GetTooltipMessageArgs) {
  if (!translationPath) return null
  const startMismatch = timelineStartDate !== intervalStartDate
  const endMismatch = timelineEndDate !== intervalEndDate

  if (isFirstColumn && startMismatch && isLastColumn && endMismatch) {
    return t(`${translationPath}.timeline_start_and_end_tooltip`, {
      start_date: formatTimelineDate(timelineStartDate),
      end_date: formatTimelineDate(timelineEndDate),
      interpolation: { escapeValue: false },
    })
  }

  if (isFirstColumn && startMismatch) {
    return t(`${translationPath}.timeline_start_tooltip`, {
      date: formatTimelineDate(timelineStartDate),
      interpolation: { escapeValue: false },
    })
  }

  if (isLastColumn && endMismatch) {
    return t(`${translationPath}.timeline_end_tooltip`, {
      date: formatTimelineDate(timelineEndDate),
      interpolation: { escapeValue: false },
    })
  }
  return null
}

function formatTimelineDate(date: string) {
  return dayjs(date).format("MM/DD/YYYY")
}

export function getHeaderMinWidth(text: string) {
  const measure = measureText(text)
  if (!measure) return "120px"
  return measure.width >= 320 ? "320px" : "120px"
}
