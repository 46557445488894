import cn from "classnames"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { TimelineIntervalTypeEnum } from "types/graphql.enums"
import divergingBarChart from "v2/dashboards/charts/diverging_bar_chart"
import { ChartLegend } from "v2/react/components/headcountPlanning/Insights/ChartLegend"
import { SmallSelect } from "v2/react/shared/collection/menus/SmallSelect"
import { LoadingWrapper } from "v2/react/shared/loaders/LoadingWrapper"
import { Info } from "v2/react/shared/status/Info"
import { useGetPeopleChangesOverTimeQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

interface PeopleChangesOverTimeProps {
  headcountPlanId: string
  participantId?: string
}

const PeopleChangesOverTime = ({ headcountPlanId, participantId }: PeopleChangesOverTimeProps) => {
  const { t } = useTranslation()
  const chartRef = useRef<HTMLDivElement>(null)
  const [intervalType, setIntervalType] = useState<TimelineIntervalTypeEnum>(
    TimelineIntervalTypeEnum.Month,
  )

  const { data, isLoading, isFetching, isError } = useGetPeopleChangesOverTimeQuery({
    headcountPlanId,
    participantId,
    intervalType,
  })

  const fetchingData = isFetching || isLoading

  const changesOverTimeData = useMemo(
    () =>
      data?.peopleChangesOverTime?.result?.map((item) => ({
        positive: item.hires,
        negative: item.fires,
        label: item.label,
        id: item.id,
        fullLabel: item.fullLabel,
      })),
    [data?.peopleChangesOverTime?.result],
  )
  const createChart = useCallback(() => {
    if (!changesOverTimeData) return

    // Clear any existing chart first
    if (chartRef.current) {
      chartRef.current.innerHTML = ""
    }

    const chart = divergingBarChart({
      data: changesOverTimeData,
      options: {
        elementId: "people-changes-over-time-chart",
        positiveLabel: t("v2.headcount_plan.insights.people_changes_over_time.new_hires"),
        negativeLabel: t("v2.headcount_plan.insights.people_changes_over_time.terminations"),
        netLabel: t("v2.headcount_plan.insights.people_changes_over_time.net"),
      },
    })

    chartRef.current?.append(chart)
  }, [changesOverTimeData, t])

  useEffect(() => {
    createChart()

    const resizeObserver = new ResizeObserver(() => {
      createChart()
    })
    if (chartRef.current) {
      resizeObserver.observe(chartRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [createChart])

  const legendItems = [
    {
      label: t("v2.headcount_plan.insights.people_changes_over_time.new_hires"),
      backgroundColor: "bg-primary-50",
    },
    {
      label: t("v2.headcount_plan.insights.people_changes_over_time.terminations"),
      backgroundColor: "bg-accent-flamingo-50",
    },
  ]

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="items-center flex">
          <span className="text-base-bold text-neutral-100">
            {t("v2.headcount_plan.insights.people_changes_over_time.title")}
          </span>
          <Info
            description={t("v2.headcount_plan.insights.people_changes_over_time.header_tooltip")}
          />
        </div>

        <SmallSelect<TimelineIntervalTypeEnum>
          options={[
            {
              id: TimelineIntervalTypeEnum.Month,
              label: t("v2.headcount_plan.insights.people_changes_over_time.month"),
            },
            {
              id: TimelineIntervalTypeEnum.Quarter,
              label: t("v2.headcount_plan.insights.people_changes_over_time.quarter"),
            },
            {
              id: TimelineIntervalTypeEnum.Year,
              label: t("v2.headcount_plan.insights.people_changes_over_time.year"),
            },
          ]}
          selectedId={intervalType}
          onClick={(id) => setIntervalType(id)}
        />
      </div>
      <LoadingWrapper
        isLoading={fetchingData}
        isError={isError}
        loaderClass={cn({ "module-card__body h-[328px]": fetchingData || isError })}
        wrapperClass="module-card__body p-6"
      >
        <div ref={chartRef} id="people-changes-over-time-chart" className="h-[244px] w-full" />
        <ChartLegend legendItems={legendItems} />
      </LoadingWrapper>
    </div>
  )
}

export { PeopleChangesOverTime }
