import React from "react"

import { NodeInterface, Option } from "types/graphql.d"
import { CompareValues } from "v2/react/components/headcountPlanning/TableDatasheet/CompareValues"
import { ForcedAutocompleteCell as ForcedAutocompleteCellPrimitive } from "v2/react/components/headcountPlanning/TableDatasheet/ForcedAutocompleteCell"
import { StrikethroughCell } from "v2/react/components/headcountPlanning/TableDatasheet/StrikethroughCell"
import { Column } from "v2/react/components/headcountPlanning/TableDatasheet/types"
import { useCollectionSearch } from "v2/react/hooks/useCollectionSearch"

import { useSaveCell } from "../hooks/useSaveCell"
import { isOrgUnitTypeKey } from "./helpers"
import { HeadcountPlanDatasheetRow } from "./types"

type Props = {
  row: HeadcountPlanDatasheetRow
  column: Column<HeadcountPlanDatasheetRow>
  headcountPlanId: string
  participantId?: string
  readOnly: boolean
}
export function ForcedAutocompleteCell({
  row,
  column,
  headcountPlanId,
  participantId,
  readOnly,
}: Props) {
  const currentValue = row.positionAttributesWithEdits[column.id]
  const compareValue =
    row.type === "modified" && column.id in row.payload ? row.positionAttributes[column.id] : null
  const saveFn = useSaveCell<Option>(headcountPlanId, column.id, row, participantId)
  const [skipQuery, setSkipQuery] = React.useState<boolean>(true)
  const [searchTerm, setSearchTerm] = React.useState<string>(currentValue?.label ?? "")

  const { collectionResult } = useCollectionSearch({
    fieldKey: column.id as keyof NodeInterface,
    filter: searchTerm,
    skipQuery,
  })

  if (row.excluded) {
    return <StrikethroughCell value={currentValue?.label ?? ""} />
  }

  if (readOnly) {
    return (
      <CompareValues compareValue={compareValue?.label} className="non-editable-cell">
        <span>{currentValue?.label ?? ""}</span>
      </CompareValues>
    )
  }

  return (
    <ForcedAutocompleteCellPrimitive
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      options={collectionResult}
      renderOption={(option) => <div>{option.label}</div>}
      saveFn={saveFn}
      currentValue={currentValue}
      compareValue={compareValue}
      columnId={column.id}
      rowId={row.id}
      onEditing={() => setSkipQuery(false)}
      editable={column.editableFn?.(row)}
      clearable={isOrgUnitTypeKey(column.id)}
    />
  )
}
