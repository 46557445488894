import { bindActionCreators } from "redux"

import { transitionTableCursor as transitionCursor } from "v2/redux/slices/DatasheetSlice"
import { AppDispatch, RootState } from "v2/redux/store"

import { inEitherReadOnEditable } from "./cursorStates"
import { BeginWriteWithCursorPayload, CellCursor, CursorState, CursorUnit } from "./types"

type ThunkCursorApi = {
  cursor: CellCursor
  dispatch: AppDispatch
  getState: () => RootState
  transitionCursor: typeof transitionCursor
}

export const cursorUnit: CursorUnit = Object.freeze({ state: CursorState.Unit })

/**
 * Transitions the cell cursor into "write" mode, provided the cell is
 * editable.
 */
export const beginWriteWithCursor = ({ clobber }: BeginWriteWithCursorPayload) =>
  thunkWithCursorApi(({ transitionCursor, cursor }) => {
    if (!inEitherReadOnEditable(cursor)) return undefined
    const next = { ...cursor, enteredBy: "transition" as const }
    return typeof clobber === "object"
      ? transitionCursor({
          ...next,
          state: CursorState.WritingOnEditableWithInitial,
          initial: clobber.withValue ?? "",
        })
      : transitionCursor({ ...next, state: CursorState.WritingOnEditable })
  })

/**
 * Utility creating a thunk which only invokes the wrapped function.
 * The wrapped function is invoked with extra values that are useful
 * for managing the cursor.
 *
 * @private
 */
const thunkWithCursorApi =
  (func: (api: ThunkCursorApi) => void) => (dispatch: AppDispatch, getState: GetRootState) => {
    const cursor = getState().datasheet.cursor
    const actions = bindActionCreators({ transitionCursor }, dispatch)

    func({ dispatch, getState, cursor, transitionCursor: actions.transitionCursor })
  }

type GetRootState = () => RootState
