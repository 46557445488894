/* eslint-disable react/jsx-props-no-spreading */
import cn from "classnames"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { AriaDatePickerProps, DateValue, useDatePicker } from "react-aria"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import { useDatePickerState } from "react-stately"

import { useTriggerVisibility } from "v2/react/hooks/useTriggerVisibility"
import { PickerCalendar } from "v2/react/shared/forms/DateInputs/DatePicker/PickerCalendar"
import { DateField, type DateFieldProps } from "v2/react/shared/forms/DateInputs/shared/DateField"
import { Dialog } from "v2/react/shared/forms/DateInputs/shared/Dialog"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"

interface DatePickerProps extends AriaDatePickerProps<DateValue> {
  autoFocus?: boolean
  dialogClassName?: string
  /**
   * Optional boolean will display the dialog dropdown in a portal. The benefits
   * of this is that the dialog can display outside of an overflow-hidden
   * parent. The drawback is that the focus when tabbing will not move from the
   * date input segments to the calendar month/year dropdowns
   */
  dialogInPortal?: boolean
  errorMessage?: string
  id: string
  /**
   * Includes a footer in the bottom of the calendar dropdown with a
   * button to clear the date value
   */
  includeClearFooter?: boolean
  isDisabled?: boolean
  /**
   * Shows the placeholder value only when focused or missing a date segment
   */
  showPlaceholderOnFocus?: boolean
  size?: "sm"
  testId?: string
  /**
   * Whether or not to render the date picker with an icon.
   * @default true
   */
  withIcon?: boolean
  wrapperClassName?: string
}

const DatePicker = ({
  autoFocus,
  dialogClassName,
  dialogInPortal,
  errorMessage,
  id,
  includeClearFooter,
  isDisabled,
  showPlaceholderOnFocus = false,
  size,
  testId,
  withIcon = true,
  wrapperClassName,
  ...props
}: DatePickerProps) => {
  const { t } = useTranslation()
  const [clickedSegmentRef, setClickedSegmentRef] = useState<
    React.RefObject<HTMLDivElement> | undefined
  >(undefined)
  const state = useDatePickerState(props)
  const ref = useRef<HTMLDivElement>(null)
  const dateFieldRef = useRef(null)
  const { groupProps, labelProps, fieldProps, dialogProps, calendarProps } = useDatePicker(
    { "aria-label": "date field", ...props, isDisabled, autoFocus },
    state,
    ref,
  )
  const { isOpen } = state

  useTriggerVisibility({ isOpen, hideElement: () => state.close(), triggerRef: ref })

  const handleSegmentFocus: DateFieldProps["onSegmentFocus"] = (_e, _date, ref) => {
    state.open()
    setClickedSegmentRef(ref)
  }

  const handleClickOutside = useCallback(() => state.close(), [state])

  const handleDateFieldChange: NonNullable<DateFieldProps["onDateFieldChange"]> = (date) =>
    date && state.setValue(date)

  const handleClearDate = useCallback(() => {
    state.setValue(null)
    state.close()
  }, [state])

  const handleDateFieldSelection: DateFieldProps["onDateFieldSelection"] = (e) => {
    const target = e.target
    if (!(target instanceof HTMLElement)) return

    target.blur()
    state.close()
  }

  const dialog = useMemo(
    () =>
      ref.current && (
        <Dialog
          dialogInPortal={dialogInPortal}
          fieldId={id}
          fieldRef={dateFieldRef}
          size={size}
          focusRefOnMount={clickedSegmentRef}
          className={cn("Date-Field-Dialog box-border w-[280px]", dialogClassName)}
          onClickOutside={handleClickOutside}
          triggerCoordinates={ref.current.getBoundingClientRect()}
          {...dialogProps}
        >
          <PickerCalendar {...calendarProps} />
          {includeClearFooter && (
            <>
              <hr className="-mx-2 my-0" />
              <div className="px-3 pt-2">
                <button
                  className="btn--sm btn--secondary"
                  disabled={!calendarProps.value}
                  onClick={handleClearDate}
                  type="button"
                >
                  {t("v2.defaults.clear")}
                </button>
              </div>
            </>
          )}
        </Dialog>
      ),
    [
      calendarProps,
      clickedSegmentRef,
      dialogClassName,
      dialogInPortal,
      dialogProps,
      handleClearDate,
      handleClickOutside,
      id,
      includeClearFooter,
      size,
      t,
    ],
  )

  return (
    <div id={id} className={cn("Date-Picker relative", wrapperClassName)} data-testid={testId}>
      <div {...labelProps} className={cn(props.label && "mb-1 items-center gap-x-1 flex")}>
        <span className="select-none font-bold text-neutral-100">{props.label}</span>
      </div>
      <div
        {...groupProps}
        ref={ref}
        className={cn(
          "date-field-wrapper w-full rounded-lg bg-white flex",
          errorMessage ? "border--error" : "",
        )}
      >
        <DateField
          fieldRef={dateFieldRef}
          onSegmentFocus={handleSegmentFocus}
          inputClassName={cn("relative", {
            "range-error focus-within:!border--main-hover": errorMessage,
            "focus-within:border--focus": !errorMessage,
            disabled: isDisabled,
          })}
          onDateFieldChange={handleDateFieldChange}
          onDateFieldSelection={handleDateFieldSelection}
          showPlaceholderOnFocus={showPlaceholderOnFocus}
          isSingleField
          isDisabled={isDisabled}
          withIcon={withIcon}
          {...fieldProps}
        />
      </div>
      {errorMessage && <InputErrorText message={errorMessage} />}
      {isOpen && dialogInPortal && createPortal(dialog, document.body)}
      {isOpen && !dialogInPortal && dialog}
    </div>
  )
}

export { DatePicker }
