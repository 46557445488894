import _ from "underscore"

import exportStyles from "../constants/exportStyles"
import { globals } from "../constants/options"
import truncate from "../utils/truncator"

// TODO: Double-check links, make sure they do not overlap department label.
const {
  nodeWidth,
  nodeRadius,
  linkStrokeWidth,
  chainOfCommand: { height },
} = globals

let chartInstance

function handleClick(d) {
  const options = chartInstance.getOptions()
  if (options.displayTopFn) {
    return options.displayTopFn.call(this, d)
  }
  if (d.klass === "Position") {
    chartInstance.trigger("action.display-chart-top", d)
  }
  if (d.klass === "Company") {
    chartInstance.trigger("action.display-full-chart")
  }
}

function renderRectangle() {
  window.d3
    .select(this)
    .attr("width", nodeWidth)
    .attr("height", height)
    .attr("rx", nodeRadius)
    .attr("ry", nodeRadius)
    .style("fill", globals.cardColor)
    .attr("filter", "url(#node-shadow-elevation)")
    .on("click", handleClick)
}

function renderText() {
  window.d3
    .select(this)
    .attr("text-anchor", "middle")
    .attr("class", "chain-of-command-text")
    .text((d) => {
      if (d.name) {
        return d.name
      }

      if (d.klass === "Position") {
        return "Open Position".t("org_chart")
      }

      return ""
    })
    .style(exportStyles.chain_of_command)
    .call(truncate, "chain_of_command")
    .on("click", handleClick)
}

function insertChainOfCommandNode(d) {
  const element = window.d3.select(this)

  if (d.depth === 0) {
    return
  }

  element
    .append("rect")
    .attr("y", (d) => d.y)
    .attr("x", (d) => d.x)
    .each(renderRectangle)

  element
    .append("text")
    .attr("y", d.y + height / 2 + 3)
    .each(renderText)
}

function updateChainOfCommandNode(d) {
  const element = window.d3.select(this)

  if (d.depth === 0) {
    return
  }

  element
    .select("rect")
    .attr("y", (d) => d.y)
    .attr("x", (d) => d.x)
    .each(renderRectangle)

  element
    .select("text")
    .attr("y", d.y + height / 2 + 3)
    .each(renderText)
}

// Assigns a depth based on the chain of command's index. The depth is used to
// position each node in a chain of command. This also sets the x and y as data
// on the chain of command so those values can be used to position the links.
const assignDepths = (chainOfCommand, positioningHelper, extraChartSectionSpacing) => {
  const height = globals.chainOfCommand.height
  const verticalSpacing = globals.chainOfCommand.verticalSpacing
  const x = -(nodeWidth / 2)

  return chainOfCommand.map((d, i) => {
    const depth = -i
    const y =
      positioningHelper.nodeYPosition() +
      depth * (height + verticalSpacing + extraChartSectionSpacing)

    return { ...d, depth, x, y }
  })
}

// Returns an array of data that is usable to generate links. Each member of
// the array will contain an object with a source and a target.
const generateLinkData = (chainOfCommand) => {
  const links = []
  _.each(chainOfCommand, (d, i) => {
    const next = chainOfCommand[i + 1]

    if (next) {
      links.push({
        source: d,
        target: next,
      })
    }
  })

  return links
}

function setLinkPositioning(d) {
  window.d3
    .select(this)
    .attr("x1", 0)
    .attr("x2", 0)
    .attr("y1", d.source.y)
    .attr("y2", d.target.y + height)
}

function renderLinks(data) {
  const strokeColor = globals.chainOfCommand.linkColor
  const linkData = generateLinkData(data)
  const links = window.d3.select(this).selectAll("link").data(linkData)
  links.exit().remove()
  links
    .enter()
    .insert("line")
    .each(function () {
      this.parentNode.insertBefore(this, this.parentNode.firstChild)
    })
    .attr("class", "link")
    .attr("stroke", strokeColor)
    .attr("stroke-width", linkStrokeWidth)
    .each(setLinkPositioning)
}

function addTo(d, positioningHelper, chart, extraChartSectionSpacing) {
  if (!d.chain_of_command) {
    return
  }
  chartInstance = chart
  const element = window.d3.select(this)
  const chainOfCommand = assignDepths(
    d.chain_of_command,
    positioningHelper,
    extraChartSectionSpacing,
  )
  // eslint-disable-next-line no-param-reassign
  d.chain_of_command = chainOfCommand
  const chain = element.selectAll("g.chain-of-command").data(chainOfCommand)

  const enter = chain.enter().append("g").attr("class", "chain-of-command")

  chain.exit().remove()
  enter.each(insertChainOfCommandNode)

  renderLinks.call(this, chainOfCommand)
}

function update(element, positioningHelper, extraChartSectionSpacing) {
  if (element.data().length < 1) {
    return
  }
  const d = element.datum()
  if (!d.chain_of_command) {
    return
  }
  const chainOfCommand = assignDepths(
    d.chain_of_command,
    positioningHelper,
    extraChartSectionSpacing,
  )
  element.selectAll("g.chain-of-command").data(chainOfCommand).each(updateChainOfCommandNode)

  const links = element.selectAll("line.link").data(generateLinkData(chainOfCommand))

  links.each(setLinkPositioning)
  links.exit().remove()
}

const findChartSectionHead = (d) => {
  const chainOfCommand = d.chain_of_command || []

  return _.find(chainOfCommand, (chain) => chain.is_chart_section_head === true)
}

// This equals the extra space between the focused node and the chart section
// head within a chain of command, if it exists.
const chartSectionHeadVerticalOffset = (d, verticalSpacing, extraChartSectionSpacing) => {
  if (!d.is_chart_section_head && d.chain_of_command) {
    const chartSectionHeadInChainOfCommand = findChartSectionHead(d)
    if (!chartSectionHeadInChainOfCommand) {
      return 0
    }
    if (Number.isNaN(chartSectionHeadInChainOfCommand.depth)) {
      return 0
    }

    return Math.abs(
      chartSectionHeadInChainOfCommand.depth *
        (height + verticalSpacing + extraChartSectionSpacing),
    )
  }

  return 0
}

const chainOfCommand = Object.freeze({
  addTo,
  update,
  findChartSectionHead,
  chartSectionHeadVerticalOffset,
})

export default chainOfCommand
