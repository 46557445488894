import React from "react"

import { PositionReportsWithSettings } from "types/graphql.enums"
import { Sidebar } from "v2/react/shared/layout/Sidebar"
import type { Table, Utility } from "v2/react/shared/tables/TableUtilities"
import { EditAssetsTableSettings } from "v2/react/shared/tables/TableUtilities/EditAssetsTableSettings"
import { EditExportsTableSettings } from "v2/react/shared/tables/TableUtilities/EditExportsTableSettings"
import { EditJobRequisitionsTableSettings } from "v2/react/shared/tables/TableUtilities/EditJobRequisitionsTableSettings"
import { EditPositionReportSettings } from "v2/react/shared/tables/TableUtilities/EditPositionReportSettings"
import { EditPositionTypesTableSettings } from "v2/react/shared/tables/TableUtilities/EditPositionTypesTableSettings"
import { EditSuccessionTableSettings } from "v2/react/shared/tables/TableUtilities/EditSuccessionTableSettings"
import { FilterTable } from "v2/react/shared/tables/TableUtilities/FilterTable"
import { isFilterableTable } from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"

interface UtilityPanelProps {
  table: Table
  open: boolean
  selectedUtility: Utility
  onUtilityToggle: (utility: Utility) => void
  onAfterClose?: () => void
}

type PanelProps = Pick<UtilityPanelProps, "table" | "onUtilityToggle">

const UtilityPanel = ({
  table,
  open,
  selectedUtility,
  onUtilityToggle,
  onAfterClose,
}: UtilityPanelProps) => (
  <Sidebar open={open} onAfterClose={onAfterClose}>
    {selectedUtility === "edit-position-report-table-settings" && isPositionTable(table) && (
      <EditPositionReportSettings.Panel table={table} onUtilityToggle={onUtilityToggle} />
    )}
    {selectedUtility === "filter-table" && isFilterableTable(table) && (
      <FilterTable.Panel table={table} onUtilityToggle={onUtilityToggle} />
    )}
    {selectedUtility === "edit-succession-table-settings" && table === "succession-plans" && (
      <EditSuccessionTableSettings.Panel onUtilityToggle={onUtilityToggle} />
    )}
    {selectedUtility === "edit-assets-table-settings" && table === "assets" && (
      <EditAssetsTableSettings.Panel onUtilityToggle={onUtilityToggle} />
    )}
    {selectedUtility === "edit-exports-table-settings" && table === "exports-page" && (
      <EditExportsTableSettings.Panel onUtilityToggle={onUtilityToggle} />
    )}
    {selectedUtility === "edit-job-requisitions-table-settings" && table === "job-requisitions" && (
      <EditJobRequisitionsTableSettings.Panel onUtilityToggle={onUtilityToggle} />
    )}
    {selectedUtility === "edit-position-types-table-settings" && table === "position-types" && (
      <EditPositionTypesTableSettings.Panel onUtilityToggle={onUtilityToggle} />
    )}
  </Sidebar>
)

const getPjaxContainerForTable = (table: Table) => {
  if (table === PositionReportsWithSettings.PositionsPage) {
    return "[data-pjax-container='positions-list']"
  }
  if (isPositionTable(table)) {
    return "[data-pjax-container='position_reports_table']"
  }

  return null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isPositionTable = (table: any): table is PositionReportsWithSettings =>
  Object.values(PositionReportsWithSettings).includes(table)

export { UtilityPanel, getPjaxContainerForTable, isPositionTable }
export type { PanelProps }
