import { isEmpty, isEqual } from "lodash"
import { useState } from "react"
import { useDebounce } from "usehooks-ts"

import { AUTOCOMPLETE_LIMIT } from "v2/react/constants"
import { useChartNodeHistorySearchQuery } from "v2/redux/GraphqlApi/ChartNodeHistoriesApi"

interface Props {
  filter: string
  chartKey: string
  asOf: string
}

const useChartHistorySearch = ({ filter, chartKey, asOf }: Props) => {
  const [returnEmpty, setReturnEmpty] = useState(false)

  const searchTerm = useDebounce(filter || "", 350)

  const { data, isFetching } = useChartNodeHistorySearchQuery(
    {
      searchTerm,
      uniqueKey: chartKey,
      asOf,
      first: AUTOCOMPLETE_LIMIT,
    },
    {
      skip: !searchTerm,
    },
  )

  const chartNodeHistories = data?.chart?.chartHistoryNodesConnection?.nodes || []

  // Handles the case where the input changes from empty to non-empty, and we
  // need to return an empty list of options until the fresh data returns.
  if (isEmpty(filter) && !returnEmpty) {
    setReturnEmpty(true)
  } else if (!isEmpty(filter) && isEqual(filter, searchTerm) && !isFetching && returnEmpty) {
    setReturnEmpty(false)
  }

  return {
    chartNodeHistories: returnEmpty ? [] : chartNodeHistories,
    returnEmpty,
    searchTerm,
  }
}

export { useChartHistorySearch }
