import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { pjaxModalFor } from "v2/react/utils/pjax"

interface UserMenuLink {
  text: string
  icon: IconProp
  url: string
  show: boolean
  addDividerAbove?: boolean
  usePjaxModal?: boolean
}

interface NavUserMenuProps {
  links: UserMenuLink[]
  currentPerson: { name: string; avatarThumbUrl: string }
}

function NavUserMenu({ links, currentPerson }: NavUserMenuProps) {
  if (!currentPerson) return null

  return (
    <Dropdown className="person-menu w-full items-center justify-center flex">
      <Dropdown.Trigger triggerClassName="dropdown-link person-dropdown-link w-full items-center rounded bg-transparent p-1 text-left hover:bg-neutral-3">
        {currentPerson.avatarThumbUrl && (
          <img className="circle circle-sm" src={currentPerson.avatarThumbUrl} alt="user avatar" />
        )}
        <div className="hide w-full max-w-[165px] items-center justify-between gap-1 flex">
          <p className="mt ml-2 truncate">{currentPerson.name}</p>
          <FontAwesomeIcon icon="caret-down" />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Menu className="person-dropdown-menu min-w-[208px]">
        {links.map((link) => {
          if (!link.show) return null

          return (
            <React.Fragment key={link.url}>
              {link.addDividerAbove && <hr className="-mx-2 my-2" />}
              <Dropdown.Item
                className="items-center gap-2 whitespace-nowrap flex"
                useActiveStyles
                href={link.url}
                as={link.usePjaxModal ? "button" : "a"}
                onClick={
                  link.usePjaxModal
                    ? (event) => {
                        event?.preventDefault()
                        pjaxModalFor(link.url)
                      }
                    : undefined
                }
              >
                <FontAwesomeIcon icon={link.icon} />
                <span>{link.text}</span>
              </Dropdown.Item>
            </React.Fragment>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export type { NavUserMenuProps }
export { NavUserMenu }
