/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { ProfilePanelMode } from "types/graphql.enums"
import { appContainer } from "v2/appContainer"
import { withRootProvider } from "v2/react/components/RootProvider"
import { prepareIconClass } from "v2/react/utils/misc"
import { useAppSelector } from "v2/redux/store"

import { IconTextLink } from "../orgChart/ProfilePanelContainer/IconTextLink"

const mockLinkData = new Array(500).fill("").map((_, index) => ({
  dataKey: `mock-entry-${index}`,
  linkPath: `/positions/${index}`,
  linkText: `UBO${index}`,
  mode: ProfilePanelMode.Position,
  personId: `${index}`,
  positionId: `${index}`,
}))

type BasicProps = {
  currencyIconClass?: string
}

function Basic({ currencyIconClass = appContainer.gon.currency_icon_class }: BasicProps) {
  const { t } = useTranslation()
  const session = useAppSelector((state) => state.session)

  return (
    <div className="flex-col gap-4 flex">
      <div>
        <h3>
          Using <code>t</code>
        </h3>
        {t("v2.defaults.archive")}
      </div>
      <div>
        <h3>Icon</h3>
        <FontAwesomeIcon icon={prepareIconClass("fas square-euro-sign")} />
      </div>
      <div>
        <h3>From Gon</h3>
        <dl className="divide-gray-100 my-0 divide-y divide-solid">
          <DescriptionListEntry term="gon.currencyIconClass" definition={currencyIconClass} />
        </dl>
      </div>
      <div>
        <h3>From Redux</h3>
        <dl className="divide-gray-100 my-0 divide-y divide-solid">
          <DescriptionListEntry term="session.status" definition={session.status} />

          <DescriptionListEntry term="session.currentUserId" definition={session.currentUserId} />

          <DescriptionListEntry
            term="session.currentPersonId"
            definition={session.currentPersonId}
          />

          <DescriptionListEntry
            term="session.currentPersonName"
            definition={session.currentPersonName}
          />
        </dl>
      </div>
      <div>
        <h3>Lots of Components</h3>
        {mockLinkData.map(({ dataKey, ...iconTextProps }) => (
          <div key={dataKey}>
            <IconTextLink {...iconTextProps} />
          </div>
        ))}
      </div>
    </div>
  )
}

function DescriptionListEntry({ term, definition }: { term: string; definition: unknown }) {
  return (
    <div className="border-x-0 px-4 py-2 sm:grid-cols-3 sm:gap-4 sm:px-0 sm:grid">
      <dt>
        <code>{term}</code>
      </dt>
      <dd className="mt-1 sm:col-span-2 sm:mt-0">{`${definition}`}</dd>
    </div>
  )
}

const BasicSSR = withRootProvider(Basic)

export { BasicSSR }
