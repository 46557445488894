const getPageTopOffsets = () => {
  const alertTop: HTMLDivElement | null = document.querySelector(".alert-top")
  const pageNav: HTMLDivElement | null = document.querySelector(".page-nav")
  const utilityNav: HTMLDivElement | null = document.querySelector(".utility-nav")

  let totalHeaderHeight = 0.0
  let alertHeight = 0.0
  let utilityNavHeight = 0.0
  let pageNavHeight = 0.0

  if (alertTop) {
    totalHeaderHeight += alertTop.getBoundingClientRect().height
    alertHeight = alertTop.getBoundingClientRect().height
  }
  if (pageNav) {
    totalHeaderHeight += pageNav.getBoundingClientRect().height
    pageNavHeight = pageNav.getBoundingClientRect().height
  }
  if (utilityNav) {
    totalHeaderHeight += utilityNav.getBoundingClientRect().height
    utilityNavHeight = utilityNav.getBoundingClientRect().height
  }

  return {
    alertHeight,
    utilityNavHeight,
    totalHeaderHeight,
    pageNavHeight,
  }
}

const getVisualHeight = () => {
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight || 0
  const { pageNavHeight: headerHeight, alertHeight } = getPageTopOffsets()
  const height = viewportHeight - headerHeight - alertHeight

  if (height < 600) return 600
  return height
}

export { getPageTopOffsets, getVisualHeight }
