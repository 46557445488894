/* eslint-disable jsx-a11y/control-has-associated-label */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Table, Row as TanRow } from "@tanstack/react-table"
import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useWindowSize } from "usehooks-ts"

import { ActiveCursor } from "v2/react/components/headcountPlanning/TableDatasheet/ActiveCursor"
import { DatasheetContextProvider } from "v2/react/components/headcountPlanning/TableDatasheet/context"
import { CursorBeacon } from "v2/react/components/headcountPlanning/TableDatasheet/CursorBeacon"
import { useCsvDownloadListener } from "v2/react/components/headcountPlanning/TableDatasheet/hooks/useCsvDownloadListener"
import { useDatasheetCellCursor } from "v2/react/components/headcountPlanning/TableDatasheet/hooks/useDatasheetCellCursor"
import { useTableDatasheet } from "v2/react/components/headcountPlanning/TableDatasheet/hooks/useTableDatasheet"
import { TableHeader } from "v2/react/components/headcountPlanning/TableDatasheet/TableHeader"
import { Column } from "v2/react/components/headcountPlanning/TableDatasheet/types"
import { TableDropZone } from "v2/react/shared/tables/Dropzone/TableDropZone"

import { useVirtualizers } from "./TableDatasheet/hooks/useVirtualizers"
import { HEADER_HEIGHT_PX, ROW_HEIGHT_PX } from "./TableDatasheet/utils/constants"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function showGrayBar(row: TanRow<any>, rowIndex: number) {
  return row.depth === 0 && rowIndex > 0
}

type DatasheetProps<TRow> = {
  columns: Column<TRow>[]
  csvDownloadName?: string
  csvDownloadRef?: React.RefObject<HTMLButtonElement> | string
  renderCell: (row: TRow, column: Column<TRow>) => React.ReactNode
  onDownloadCsvPress?: (table: Table<TRow>, csvDownloadName?: string) => void
  rows: TRow[]
  showGroupByDropzone?: boolean
}
export function TableDatasheet<TRow extends { id: string }>({
  columns,
  csvDownloadName,
  csvDownloadRef,
  onDownloadCsvPress,
  renderCell,
  rows,
  showGroupByDropzone,
}: DatasheetProps<TRow>) {
  const table = useTableDatasheet(rows, columns)
  const exportType = "headcount_plan"
  useCsvDownloadListener(csvDownloadRef, table, csvDownloadName, onDownloadCsvPress, exportType)
  const beaconRef = React.useRef<HTMLButtonElement>(null)
  const cursorRef = React.useRef<HTMLDivElement>(null)
  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const [height, setHeight] = useState(0)
  const datasheetRef = React.useRef<HTMLDivElement>(null)
  const { height: windowHeight } = useWindowSize()

  useEffect(() => {
    if (!datasheetRef.current) return
    const newHeight = windowHeight - datasheetRef.current.getBoundingClientRect().top - 55
    setHeight(newHeight)
  }, [datasheetRef, windowHeight])

  const tableColumns = table.getVisibleLeafColumns()
  const { rows: tableRows } = table.getRowModel()
  const { columnVirtualizer, rowVirtualizer } = useVirtualizers<TRow>({
    getColumnWidth: (column) => column.getSize(),
    getRowHeight: (row, index) => {
      if (row.getIsGrouped()) {
        return showGrayBar(row, index) ? 52 : 36
      }

      return ROW_HEIGHT_PX
    },
    headerHeight: HEADER_HEIGHT_PX,
    tableContainerRef,
    tableRows,
    tableColumns,
  })

  useDatasheetCellCursor({
    table,
    beaconRef,
    cursorRef,
    tableContainerRef,
    rowVirtualizer,
    columnVirtualizer,
  })

  const items = rowVirtualizer.getVirtualItems()

  return (
    <div
      ref={datasheetRef}
      style={{ height }}
      className="grid-rows-[auto_1fr] overflow-hidden grid"
    >
      <DatasheetContextProvider table={table}>
        {showGroupByDropzone === false ? null : <TableDropZone table={table} />}

        <div className="table">
          <div
            className="relative h-full w-full overflow-auto"
            id="table-container"
            ref={tableContainerRef}
          >
            <table
              className="table-datasheet grid"
              ref={tableRef}
              style={{ width: columnVirtualizer.getTotalSize() }}
            >
              <TableHeader columnVirtualizer={columnVirtualizer} table={table} />
              <tbody
                className="grid"
                style={{ transformStyle: "preserve-3d", height: rowVirtualizer.getTotalSize() }}
              >
                {items.map((virtualRow, rowIndex) => {
                  const row = tableRows[virtualRow.index]
                  const rowStart = virtualRow.start - rowVirtualizer.options.scrollMargin
                  const visibleCells = row.getVisibleCells()
                  const nextRow = table.getRowModel().rows[virtualRow.index + 1]
                  const prevRow = table.getRowModel().rows[virtualRow.index - 1]
                  return (
                    <tr
                      id={`tr-${row.id}`}
                      key={row.id}
                      className="w-full border-b-0 flex"
                      data-virtual-row-index={virtualRow.index}
                      data-virtual-row-start={rowStart}
                      style={{
                        height: `${virtualRow.size}px`,
                        position: "absolute",
                        transform: `translateY(${rowStart}px) translateZ(${100 - 1 * rowIndex}px)`,
                      }}
                    >
                      {row.getIsGrouped() ? (
                        <GroupCell
                          row={row}
                          showGrayBar={showGrayBar(row, rowIndex)}
                          mergeWithNextRow={nextRow?.getIsGrouped() && nextRow.depth > row.depth}
                          mergeWithPrevRow={prevRow?.getIsGrouped() && prevRow.depth < row.depth}
                        />
                      ) : (
                        columnVirtualizer.getVirtualItems().map((virtualColumn) => {
                          const cell = visibleCells[virtualColumn.index]
                          return (
                            <td
                              key={cell.id}
                              className={cn(
                                "first:border-l-1 absolute left-0 top-0 border-l-0 border-t-0 p-0 flex",
                                cell.column.columnDef.meta?.original?.cellClassName,
                              )}
                              data-virtual-col-start={virtualColumn.start}
                              style={{
                                transform: `translateX(${virtualColumn.start}px)`,
                                height: `${virtualRow.size}px`,
                                width: cell.column.getSize(),
                              }}
                            >
                              {renderCell(
                                cell.row.original as TRow,
                                cell.column.columnDef.meta?.original as Column<TRow>,
                              )}
                            </td>
                          )
                        })
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <CursorBeacon beaconRef={beaconRef} />
            <ActiveCursor cursorRef={cursorRef} />
          </div>
        </div>
      </DatasheetContextProvider>
    </div>
  )
}

type GroupCellProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: TanRow<any>
  showGrayBar: boolean
  mergeWithNextRow: boolean
  mergeWithPrevRow: boolean
}
function GroupCell({ row, showGrayBar, mergeWithNextRow, mergeWithPrevRow }: GroupCellProps) {
  const cell = row.getVisibleCells().find((cell) => cell.getIsGrouped())

  if (!cell) {
    return null
  }

  const getButtonHeight = () => {
    if (showGrayBar) {
      return "calc(52px - 1rem)"
    }
    if (mergeWithNextRow) {
      return "36px"
    }
    return "35px"
  }

  return (
    <td
      colSpan={row.getVisibleCells().length}
      className={cn("datasheet-grouped-cell w-full block", {
        "h-[52px]": showGrayBar,
        "h-[36px]": !showGrayBar,
      })}
    >
      {showGrayBar ? (
        <div
          className="h-4 border-b border-b-neutral-8-solid bg-neutral-3"
          style={{ borderBottomStyle: "solid" }}
        />
      ) : null}
      <button
        type="button"
        onClick={row.getToggleExpandedHandler()}
        className={cn(
          "grid-cols-[min-content_min-content] items-center gap-2 bg-transparent grid",
          {
            "border-b border-solid border-neutral-8": !mergeWithNextRow,
          },
        )}
        style={{
          paddingLeft: `${row.depth * 1 + 1}rem`, // eslint-disable-line no-mixed-operators
          paddingTop: mergeWithPrevRow ? 0 : undefined,
          paddingBottom: mergeWithNextRow ? 0 : undefined,
          height: getButtonHeight(),
        }}
      >
        <div className="flex-col justify-center flex">
          <FontAwesomeIcon icon={["fas", row.getIsExpanded() ? "caret-down" : "caret-right"]} />
        </div>
        <span className="font-bold">
          {`${cell.column.columnDef.header}: ${cell.getValue() as string} (${
            row.getLeafRows().filter((row) => !row.getIsGrouped()).length
          })`}
        </span>
      </button>
    </td>
  )
}
