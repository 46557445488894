import * as d3 from "d3"

import { generateYAxis } from "v2/dashboards/charts/utils"
import { formatCurrency, parseCurrency } from "v2/react/utils/currency"

function planVsActual({ data, options = {} }) {
  const xAccessor = (d) => d.label
  const planAccessor = (d) => (options.dataType.includes("cost") ? parseCurrency(d.plan) : d.plan)
  const actualAccessor = (d) =>
    options.dataType.includes("cost") ? parseCurrency(d.actual) : d.actual

  const {
    margin = { top: 8, right: 4, bottom: 20, left: 25 },
    chartWidth = null,
    chartHeight = null,
    axisColor = "#63698C", // $color-neutral-64-solid
    axisGridLineColor = "rgba(237, 237, 242, 1)", // $color-neutral-8-solid
    fontFamily = "Satoshi, sans-serif",
    useGridLines = true,
    elementId = "diverging-stacked-bar-chart",
    colors = {
      plan: "rgba(139, 231, 255, 1)", // accent breeze
      actual: "rgba(027, 098, 255, 1)", // primary 100
      line: "rgba(255, 79, 125, 1)", // accent flamingo
    },
    lineStyles = {
      plan: "12, 4",
      actual: "0",
    },
  } = options

  const element = document.getElementById(elementId)

  // Compute width and height from the element's client dimensions.
  const width = chartWidth || element?.clientWidth || 800
  const height = chartHeight || element?.clientHeight || 400

  // Add dimensions
  const svg = d3
    .create("svg")
    .attr("height", height)
    .attr("width", width)
    .attr("viewBox", [0, 0, width, height])
    .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
    .style("font", fontFamily)

  // Set y-domain to span more than 0 if there is no data
  let max = d3.max(data, (d) => Math.max(parseCurrency(d.plan), parseCurrency(d.actual) || 0))
  if (max === 0) {
    max = 8
  }

  const maybeFormatCurrency = (value) =>
    options.dataType.includes("cost") ? formatCurrency({ value, notation: "compact" }) : value

  // Make the left margin as wide as the max. + 10 is for the - after
  const left = maybeFormatCurrency(max).toString().length * 6 + 10
  if (left > margin.left) margin.left = left

  // Set up yScale
  const yScale = d3
    .scaleLinear()
    .domain([0, max])
    .nice()
    .range([height - margin.bottom, margin.top])

  // Set up xScale
  const xScale = d3
    .scalePoint()
    .domain(data.map(xAccessor))
    .range([margin.left, width - margin.right])
    .padding(0.2)

  generateYAxis(
    svg,
    yScale,
    margin,
    height,
    width,
    axisColor,
    fontFamily,
    "",
    axisGridLineColor,
    useGridLines,
    null,
    null,
    maybeFormatCurrency,
  )

  // Draw vertical crosshair underneath lines
  const hoverGroup = svg.append("g").attr("class", "hover-elements").style("display", "none")

  const verticalLine = hoverGroup
    .append("line")
    .attr("class", "hover-line")
    .attr("y1", margin.top)
    .attr("y2", height - margin.bottom)
    .attr("stroke", colors.line)
    .attr("stroke-width", 1)

  // Set up lines
  const createLineGenerator = (accessor) =>
    d3
      .line()
      .x((d) => xScale(xAccessor(d)))
      .y((d) => yScale(accessor(d)))

  const planData = data.filter((d) => d.plan !== null)
  svg
    .append("path")
    .datum(planData)
    .attr("d", createLineGenerator(planAccessor))
    .attr("fill", "none")
    .attr("stroke", colors.plan)
    .attr("stroke-width", 2)
    .attr("stroke-dasharray", lineStyles.plan)

  svg
    .selectAll("plan-circles")
    .data(planData)
    .enter()
    .append("circle")
    .attr("id", (_, i) => `data-point-${i}`)
    .attr("fill", colors.plan)
    .attr("r", 4)
    .attr("cx", (d) => xScale(xAccessor(d)))
    .attr("cy", (d) => yScale(planAccessor(d)))

  const actualData = data.filter((d) => d.actual !== null)
  svg
    .append("path")
    .datum(actualData)
    .attr("d", createLineGenerator(actualAccessor))
    .attr("fill", "none")
    .attr("stroke", colors.actual)
    .attr("stroke-width", 2)
    .attr("stroke-dasharray", lineStyles.actual)

  svg
    .selectAll("plan-circles")
    .data(actualData)
    .enter()
    .append("circle")
    .attr("id", (_, i) => `data-point-${i}`)
    .attr("fill", colors.actual)
    .attr("r", 4)
    .attr("cx", (d) => xScale(xAccessor(d)))
    .attr("cy", (d) => yScale(actualAccessor(d)))

  const xAxis = d3.axisBottom(xScale).tickSizeOuter(0)

  svg
    .append("g")
    .attr("class", "x-axis")
    .attr("transform", `translate(0,${height - margin.bottom})`)
    .style("font-family", fontFamily)
    .style("color", axisColor)
    .call(xAxis)

  let tooltip = d3.select("#content").select("#plan-vs-actual-tooltip")
  if (tooltip.empty()) {
    tooltip = d3
      .select("#content")
      .append("div")
      .attr(
        "class",
        "line-tooltip elevation--overlay text-base bg-white rounded-lg p-3 min-w-[11.5rem]",
      )
      .attr("id", "plan-vs-actual-tooltip")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("pointer-events", "none")
  }

  const mouseTrackingArea = svg
    .append("rect")
    .attr("class", "mouse-tracking-area")
    .attr("width", width - margin.left - margin.right)
    .attr("height", height - margin.top - margin.bottom)
    .attr("transform", `translate(${margin.left}, ${margin.top})`)
    .attr("fill", "none")
    .attr("pointer-events", "all")

  mouseTrackingArea
    .on("mouseenter", () => {
      hoverGroup.style("display", null)
      tooltip.style("visibility", "visible")
    })
    .on("mouseleave", () => {
      hoverGroup.style("display", "none")
      tooltip.style("visibility", "hidden")
    })
    .on("mousemove", (event) => {
      // Get the mouse position relative to the SVG.
      const [mouseX] = d3.pointer(event)
      // Get the domain (array of labels) from the xScale.
      const domain = xScale.domain()
      // Compute the center for each band.
      const centers = domain.map((label) => xScale(label) + xScale.bandwidth() / 2)

      // Find the index of the center closest to the mouse x coordinate.
      let closestIndex = 0
      let minDistance = Infinity
      centers.forEach((center, i) => {
        const dist = Math.abs(mouseX - center)
        if (dist < minDistance) {
          minDistance = dist
          closestIndex = i
        }
      })

      // Use that index to get the corresponding data point.
      const dataPoint = data[closestIndex]

      if (dataPoint) {
        // Compute the x position of the chosen data point.
        const xPos = xScale(dataPoint.label) + xScale.bandwidth() / 2
        verticalLine.transition().duration(50).attr("transform", `translate(${xPos}, 0)`)

        const planY = yScale(dataPoint.plan) || 0
        const actualY = yScale(dataPoint.actual) || 0
        const midpointY = (planY + actualY) / 2

        const tooltipContent = formatTooltip(dataPoint)
        tooltip.html(tooltipContent)

        // Get the SVG's bounding box
        const chartRect = svg.node().getBoundingClientRect()

        // Get the content container
        const contentContainer = d3.select("#content").node()
        const contentBounds = contentContainer.getBoundingClientRect()

        // Get scroll position of the content container
        const scrollTop = contentContainer.scrollTop
        const scrollLeft = contentContainer.scrollLeft

        // Calculate absolute position
        let tooltipX = chartRect.left + xPos + 10
        let tooltipY = chartRect.top + midpointY

        // Calculate position relative to the content container, accounting for scroll
        tooltipX = tooltipX - contentBounds.left + scrollLeft
        tooltipY = tooltipY - contentBounds.top + scrollTop

        // Adjust for viewport boundaries
        const tooltipRect = tooltip.node().getBoundingClientRect()
        const viewportWidth = window.innerWidth
        const viewportHeight = window.innerHeight

        if (tooltipX + tooltipRect.width + 10 > viewportWidth) {
          tooltipX = tooltipX - tooltipRect.width - 20
        }

        if (tooltipY + tooltipRect.height > viewportHeight) {
          tooltipY -= tooltipRect.height
        }

        tooltip
          .style("left", `${tooltipX}px`)
          .style("top", `${tooltipY}px`)
          .style("visibility", "visible")
      }
    })

  return svg.node()
}

export default planVsActual

const formatTooltip = (dataPoint) => `
    <div class="text-base-bold">${dataPoint.fullLabel}</div>
    <hr class="my-3 -mx-3" />
    <div class="flex items-center justify-between">
        <span class="text-base-bold">Planned</span>
        <span>${dataPoint.plan}</span>
    </div>
    ${
      dataPoint.actual
        ? `<div class="flex items-center justify-between">
        <span class="text-base-bold">Actual</span>
        <span>${dataPoint.actual}</span>
    </div>`
        : ""
    }
  `
