import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlanningNavTitleWithSubtitle } from "v2/react/components/headcountPlanning/HeadcountPlanningNav"
import RootProvider, { withRootProvider } from "v2/react/components/RootProvider"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { AlertBanner } from "v2/react/shared/status/AlertBanner"
import { parseCurrency } from "v2/react/utils/currency"
import { UrlHelper } from "v2/react/utils/urls"
import {
  useGetHeadcountPlanWithTreeQuery,
  useSaveHeadcountPlanBudgetsMutation,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { HCPStepper } from "../shared/HCPStepper"
import { StickyFooterBar } from "../StickyFooterBar"
import { FormElements } from "../types"
import { BudgetTable } from "./BudgetTable"

interface Props {
  headcountPlanId: string
  maxChartDepth: number
}

function WithProvider({ headcountPlanId, maxChartDepth }: Props) {
  const { t } = useTranslation()
  const { data } = useGetHeadcountPlanWithTreeQuery({
    id: headcountPlanId,
    rootPositionId: undefined,
    participantIds: [],
    maxChartDepth,
  })
  const headcountPlan = data?.headcountPlan
  const [erroredRows, setErroredRows] = useState<string[]>([])
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const [mutate, mutation] = useSaveHeadcountPlanBudgetsMutation()

  if (!headcountPlan) return <Spinner />

  const setBudget = headcountPlan.setBudget

  if (!setBudget) window.location.href = UrlHelper.headcountPlanPath(headcountPlanId)

  const onSubmit = async (event: FormEvent<Element>) => {
    event.preventDefault()
    const target = event.target as HTMLFormElement
    const elements = target.elements as FormElements
    const budgets: Record<string, number> = {}
    Array.from(elements).forEach((el) => {
      const elem = el as HTMLInputElement
      const name = elem.name
      if (name && elem.value !== "") budgets[name] = parseCurrency(elem.value)
    })

    const result = await mutate({ headcountPlanId, budgets }).unwrap()

    if (result.saveHeadcountPlanBudgets?.success) {
      window.location.href = UrlHelper.headcountPlanPath(headcountPlanId)
    } else {
      setErrorMessages(result.saveHeadcountPlanBudgets?.errors?.map((e) => e.message) || [])
      setErroredRows(
        result.saveHeadcountPlanBudgets?.errors
          ?.map((e) => e.path)
          ?.flat()
          ?.filter((p): p is string => !!p) || [],
      )
    }
  }

  const searchParams = new URLSearchParams(window.location.search)
  const cancelTo = searchParams.get("cancelTo")
  const mode = searchParams.get("mode")

  return (
    <form id="edit-budgets-form" onSubmit={onSubmit}>
      <div className="flex-col items-center justify-center gap-4 px-12 py-8 flex">
        {mode === "initialize" && <HCPStepper setBudget={setBudget} currentStep={3} />}
        <div className="mb-16 w-full md:w-[800px]">
          <div className="mb-4">
            <AlertBanner
              messages={[
                {
                  text: t("v2.headcount_plan.set_budgets.note"),
                  icon: <FontAwesomeIcon icon={["far", "circle-info"]} />,
                },
              ]}
              type="info"
            />
            {errorMessages && errorMessages.length > 0 && (
              <AlertBanner
                className="mt-4"
                messages={[
                  {
                    text: errorMessages.join("<br/>"),
                    icon: <FontAwesomeIcon icon={["far", "circle-exclamation"]} />,
                  },
                ]}
                type="critical"
              />
            )}
          </div>
          <BudgetTable
            headcountPlan={headcountPlan}
            logoThumbUrl={headcountPlan.company.logoThumbUrl || ""}
            companyName={headcountPlan.company.name || ""}
            participants={headcountPlan.participantsTree}
            erroredRows={erroredRows}
          />
        </div>
      </div>
      <StickyFooterBar innerClassName="w-full sm:w-[800px]">
        {mode !== "initialize" && (
          <a
            href={cancelTo || UrlHelper.headcountPlanPath(headcountPlanId)}
            className="btn--large btn--secondary"
            type="submit"
          >
            {t("v2.defaults.cancel")}
          </a>
        )}
        <button className="btn--large btn--primary" type="submit">
          {mode === "initialize" && t("v2.headcount_plan.setup.start_planning")}
          {!mutation.isLoading && mode !== "initialize" && t("v2.defaults.save")}
          {mutation.isLoading && mode !== "initialize" && `${t("v2.defaults.saving")}...`}
        </button>
      </StickyFooterBar>
    </form>
  )
}

const SetBudgets = ({ headcountPlanId, maxChartDepth }: Props) => (
  <RootProvider>
    <WithProvider headcountPlanId={headcountPlanId} maxChartDepth={maxChartDepth} />
  </RootProvider>
)

const Header = ({ mode, name, id }: { mode: string; name: string; id: string | number }) => {
  const { t } = useTranslation()

  return (
    <PageNav>
      <TitleBlockLarge>
        <HeadcountPlanningNavTitleWithSubtitle
          title={
            mode === "initialize"
              ? t("v2.headcount_plan.setup.header")
              : t("v2.headcount_plan.set_budgets.header")
          }
          subtitle={name}
          subtitleLinkTo={UrlHelper.headcountPlanPath(id)}
        />
      </TitleBlockLarge>
    </PageNav>
  )
}

const SetBudgetsHeader = withRootProvider(Header, "SetBudgetsHeader")

export { SetBudgets, SetBudgetsHeader }
