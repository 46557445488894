/* eslint-disable react/jsx-props-no-spreading */
import i18n from "i18next"
import React, { PropsWithChildren, useEffect, useState } from "react"
import { I18nProvider } from "react-aria"
import { I18nextProvider } from "react-i18next"

import { appContainer } from "v2/appContainer"
import I18nDataLoader from "v2/i18nDataLoader"
import { AppStore, useAppSelector } from "v2/redux/store"
import { StoreProvider } from "v2/redux/StoreProvider"

// Done to ensure FontAwesome icons are loaded and registered during SSR.
import "v2/components/fontawesome"

type RootProviderProps = PropsWithChildren<{
  i18n?: typeof i18n
  store?: AppStore
  canLoadI18nResources?: boolean
}>

const currentLanguage = globalThis.gon?.current_user_locale ?? "en"

const I18nDataGuard = ({
  children,
  canLoadI18nResources = true,
}: PropsWithChildren<Pick<RootProviderProps, "canLoadI18nResources">>) => {
  const isI18nLoaded = useAppSelector((state) => state.session.loadedI18n)
  const [loading, setLoading] = useState(canLoadI18nResources && !isI18nLoaded)

  useEffect(() => {
    if (isI18nLoaded || !canLoadI18nResources) return

    I18nDataLoader.loadLocaleAndUpdateAppContainer(currentLanguage).finally(() => {
      setLoading(false)
    })
  }, [isI18nLoaded, canLoadI18nResources])

  return loading ? null : children
}

function RootProvider({
  children,
  i18n: i18nFromCaller,
  store,
  canLoadI18nResources,
}: RootProviderProps) {
  return (
    <I18nextProvider i18n={i18nFromCaller ?? appContainer.i18nInstance}>
      <I18nProvider locale={currentLanguage}>
        <StoreProvider store={store}>
          <I18nDataGuard canLoadI18nResources={canLoadI18nResources}>{children}</I18nDataGuard>
        </StoreProvider>
      </I18nProvider>
    </I18nextProvider>
  )
}

function withRootProvider<Props extends object>(Component: React.FC<Props>, name?: string) {
  const componentName = name ?? `${Component.name}WithRootProvider`
  const builder = {
    // This quirky hack ensures that the generated function is named
    // `componentName` and shows as such in React Dev Tools. Assigning
    // `function.name = ...` resulted in type errors.
    [componentName]: (props: Props & RootProviderProps) => (
      <RootProvider {...props}>
        <Component {...props} />
      </RootProvider>
    ),
  }

  return builder[componentName]
}

export default RootProvider
export { withRootProvider }
