import cn from "classnames"
import React, { createContext, useMemo, useRef, useState } from "react"

import { DropdownItem } from "v2/react/shared/collection/menus/Dropdown/DropdownItem"
import { DropdownMenu } from "v2/react/shared/collection/menus/Dropdown/DropdownMenu"
import { DropdownTrigger } from "v2/react/shared/collection/menus/Dropdown/DropdownTrigger"
import {
  useDropdownSelect,
  type OnOpenChange,
} from "v2/react/shared/collection/menus/Dropdown/hooks/useDropdownSelect"

interface DropdownContext {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  floatingInfo: ReturnType<typeof useDropdownSelect>
}

const DropdownContext = createContext<DropdownContext>({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsOpen: () => {},
  floatingInfo: {} as ReturnType<typeof useDropdownSelect>,
})

interface DropdownProps {
  children: React.ReactNode
  align?: "left" | "right"
  className?: string
  onOpenChange?: OnOpenChange
}

const Dropdown = ({ children, align, className, onOpenChange }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const setShowDropdown: OnOpenChange = (open, event, reason) => {
    setIsOpen(open)
    onOpenChange?.(open, event, reason)
  }

  const floatingInfo = useDropdownSelect({
    showDropdown: isOpen,
    setShowDropdown,
    align,
  })

  const contextValue = useMemo(
    () => ({ isOpen, setIsOpen, floatingInfo }),
    [isOpen, setIsOpen, floatingInfo],
  )

  return (
    <DropdownContext.Provider value={contextValue}>
      <div className={cn("dropdown--react", className)} role="presentation" ref={dropdownRef}>
        {children}
      </div>
    </DropdownContext.Provider>
  )
}

Dropdown.Trigger = DropdownTrigger
Dropdown.Menu = DropdownMenu
Dropdown.Item = DropdownItem

export { Dropdown, DropdownContext }
export type { DropdownProps }
