import OrgChart from "org_chart/chart/orgChart"
import RelationalNodeDataStore from "org_chart/chart/utils/relationalNodeDataStore"
import React, { useMemo, useRef, useState } from "react"

import { Position } from "types/graphql"
import { useOrgChart } from "v2/react/components/headcountPlanning/hooks/useOrgChart"
import { OrgChartSearchInput } from "v2/react/components/headcountPlanning/OrgChart/OrgChartSearchInput"
import { InProgressAlert } from "v2/react/components/headcountPlanning/shared/InProgressAlert"
import { DisplayState } from "v2/react/components/headcountPlanning/types"
import { OrgChartViewOptions } from "v2/react/components/orgChart/Navigation/OrgChartViewOptions"
import { SuperPanel } from "v2/react/components/orgChart/SuperPanel/SuperPanel"
import { TabSwitch } from "v2/react/components/orgChart/SuperPanel/TabSwitch"
import RootProvider from "v2/react/components/RootProvider"
import { useOffsetHeight } from "v2/react/hooks/useOffsetHeight"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { UtilityNav } from "v2/react/shared/navigation/UtilityNav"
import { getCookie } from "v2/react/utils/cookies"
import { getVisualHeight } from "v2/react/utils/layout"
import { UrlHelper } from "v2/react/utils/urls"
import { bootstrapActiveChartContainer } from "v2/redux/containers/activeChartContainer"
import { BootstrapChartArg, ChartContainer } from "v2/redux/containers/types"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"
import { useGetOwnerOrgChartQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { useAppSelector } from "v2/redux/store"

RelationalNodeDataStore.load({ keys: {}, index: {} })

interface InnerProps {
  headcountPlanId: string
  activeChart: ChartContainer
}

function OwnerOrgChartInner({ headcountPlanId, activeChart }: InnerProps) {
  const featureFlags = useGetFeatureFlagsQuery()
  const ff = featureFlags?.data?.currentCompany?.featureFlags
  const displayStateCookie = getCookie(
    `built__display-state-for-hcp-${headcountPlanId}`,
  ) as DisplayState
  const displayState =
    useAppSelector((state) => state.headcountPlan.displayState) ?? displayStateCookie ?? "approved"
  const { data, isFetching } = useGetOwnerOrgChartQuery({ headcountPlanId })
  const currentCompany = data?.currentCompany
  const currentPerson = data?.currentPerson
  const headcountPlan = data?.headcountPlan
  const isFinalized = !!headcountPlan?.lockedAt
  const hcpPositions =
    isFinalized && displayState === "approved"
      ? headcountPlan?.approvedPositionsForOrgchart
      : headcountPlan?.allPositionsForOrgchart

  const { offsetHeight } = useOffsetHeight()
  const [chart, setChart] = useState<OrgChart | null>(null)
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const positionsEndpoint = UrlHelper.headcountPlanOrganizePath(
    headcountPlanId,
    `?display_state=${displayState}`,
  )
  const isParticipantPage = false
  const topNode = useMemo(
    () => ({
      name: currentCompany?.name,
      avatar: currentCompany?.logoThumbUrl,
      klass: "Company",
      id: "company",
      position_id: "company",
      parent_id: null,
      payload: {},
    }),
    [currentCompany],
  )

  const { addAsChild } = useOrgChart(
    activeChart,
    chart,
    chartContainerRef,
    hcpPositions,
    headcountPlanId,
    isFetching,
    isParticipantPage,
    positionsEndpoint,
    setChart,
    headcountPlan?.startDate,
    topNode,
    undefined,
    currentPerson?.primaryPosition?.id,
  )

  const onSelect = (item: Position) => {
    if (item.uniqueKey) chart?.focus(item.uniqueKey)
  }

  return (
    <div className="bg-white">
      <UtilityNav>
        <div className="items-center gap-2 flex">
          {ff && <TabSwitch ff={ff} hcpIsLocked={isFinalized} />}
        </div>
        {!isFetching && !headcountPlan?.canBeFinalized && <InProgressAlert />}
        <div className="items-center justify-end gap-2 flex md:w-full">
          <OrgChartSearchInput headcountPlanId={headcountPlanId} onSelect={onSelect} />
        </div>
        <OrgChartViewOptions chart={chart} />
      </UtilityNav>

      <div className="relative bg-white">
        {ff && (
          <SuperPanel
            ff={ff}
            offsetHeight={offsetHeight}
            chart={chart}
            addAsChild={addAsChild}
            hcpIsLocked={isFinalized}
          />
        )}
      </div>
      {isFetching && <Spinner />}
      <div
        ref={chartContainerRef}
        className="organize-container bg-primary-3-solid"
        id="organize-container"
        style={{ height: getVisualHeight() }}
      />
    </div>
  )
}

interface Props {
  headcountPlanId: string
  settings: BootstrapChartArg
}
const OwnerOrgChart = ({ headcountPlanId, settings }: Props) => {
  const activeChart = bootstrapActiveChartContainer(settings)

  return (
    <RootProvider>
      <OwnerOrgChartInner headcountPlanId={headcountPlanId} activeChart={activeChart} />
    </RootProvider>
  )
}

export { OwnerOrgChart }
