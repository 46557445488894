import dayjs from "dayjs"

const totalTenureInYears = (createdAt?: string): number => {
  if (!createdAt) return 0
  const current = dayjs()
  const days: number = current.diff(createdAt, "days")
  const tenureInYears = days / 365
  return Math.ceil(tenureInYears * 100) / 100
}

export { totalTenureInYears }
