import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { MouseEvent } from "react"

import { Tooltip, TooltipContent, TooltipTrigger } from "../../../shared/overlay/Tooltip"

interface ColumnSortProps {
  canSort: boolean
  classes?: string
  columnLabel: string
  disabled?: boolean
  onSort?: (event: MouseEvent<HTMLButtonElement>) => void
  sorted: false | "asc" | "desc"
  info?: string
}

function ColumnSort({
  canSort,
  classes,
  columnLabel,
  disabled,
  onSort,
  sorted,
  info,
}: ColumnSortProps) {
  const icon: Record<string, IconName> = {
    asc: "sort-up",
    desc: "sort-down",
    false: "sort",
  }

  return (
    <button
      type="button"
      className={cn("group w-full gap-2 border-none bg-transparent p-0 flex", classes)}
      disabled={disabled}
      onClick={onSort}
    >
      <h2 className="text-base-bold">{columnLabel}</h2>
      {info && (
        <Tooltip>
          <TooltipTrigger>
            <FontAwesomeIcon className="mt-0.5" icon={["far", "circle-info"]} />
          </TooltipTrigger>
          <TooltipContent className="react-tooltip-content--sm">{info}</TooltipContent>
        </Tooltip>
      )}
      {canSort && (
        <FontAwesomeIcon
          className={cn(
            "mt-0.5 opacity-0 transition-all duration-150 ease-out group-hover:opacity-100",
            {
              "opacity-100": sorted,
            },
          )}
          icon={["fas", icon[sorted.toString()]]}
        />
      )}
    </button>
  )
}

export { ColumnSort }
