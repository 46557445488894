import { NodeData } from "org_chart/chart/node/types"
import OrgChart, { OverrideOptions } from "org_chart/chart/orgChart"
import { Peripherals } from "packs/org_chart"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useTranslation } from "react-i18next"

import { ChartNodeHistory, Company } from "types/graphql"
import { ChartError } from "v2/react/components/orgChart/HistoricalOrgChart/ChartError"
import { useChartViewId, useOrgChart } from "v2/react/components/orgChart/HistoricalOrgChart/hooks"
import {
  buildDataLoaderFn,
  buildHierarchyWithCounts,
  buildOrgchartActions,
  reshapeDataForHierarchy,
} from "v2/react/components/orgChart/HistoricalOrgChart/utils"
import { OrgChartViewOptions } from "v2/react/components/orgChart/Navigation/OrgChartViewOptions"
import { PeripheralsProps } from "v2/react/components/orgChart/Peripherals"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { getVisualHeight } from "v2/react/utils/layout"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { UrlHelper } from "v2/react/utils/urls"
import { useChartNodeHistoryBaseDataQuery } from "v2/redux/GraphqlApi/ChartNodeHistoriesApi"
import { selectChartSettings } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface Props {
  peripheralsProps?: PeripheralsProps
  forExport?: boolean
  orgChartId: string
}

function HistoricalOrgChart({ peripheralsProps, forExport = false, orgChartId }: Props) {
  const { t } = useTranslation()
  const [hasError, setHasError] = useState(false)
  const [hasNoData, setHasNoData] = useState(false)
  const dispatch = useAppDispatch()
  const chartSettings = useAppSelector(selectChartSettings)
  const visualHeight = useMemo(() => getVisualHeight(), [])
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const handleError = useCallback((error: unknown) => {
    window?.Sentry?.captureException(error)
    setHasError(true)
  }, [])
  const { chartViewId, setChartViewId } = useChartViewId()

  const viewEntireChart = useCallback(() => {
    window.history.pushState({}, "", UrlHelper.orgchartRootPath())
    setChartViewId(null)
  }, [setChartViewId])

  const displayNodeAtTop = useCallback(
    (node: NodeData) => {
      if (node.klass === "Company") {
        viewEntireChart()
      } else if (node.position_id && !node.is_assistant) {
        const positionId = idFromUniqueKey(node.position_id)
        window.history.pushState({}, "", UrlHelper.displayChartTopPath(positionId.toString()))
        setChartViewId(`position_${positionId}`)
      }

      return null
    },
    [setChartViewId, viewEntireChart],
  )
  const { data, isFetching } = useChartNodeHistoryBaseDataQuery(
    {
      uniqueKey: orgChartId || "",
      asOf: chartSettings.historyModeSelectedDate,
      chartViewId,
    },
    { skip: !orgChartId || !chartSettings.historyMode },
  )

  const chartData = useMemo(() => {
    if (isFetching || !data?.chart?.chartHistoryNodes?.length || !data.currentCompany) return null

    const roots = data.chart.chartHistoryNodes.filter((node) => !node.parent_id)
    const addCompanyNode =
      (chartSettings.displayMode !== "three_level" || roots.length > 1) && !chartViewId
    const transformedFlatData = reshapeDataForHierarchy(
      data.chart.chartHistoryNodes as ChartNodeHistory[],
      data.currentCompany as Company,
      addCompanyNode,
    )

    return transformedFlatData
  }, [data, chartViewId, chartSettings.displayMode, isFetching])

  const fullChartOptions = useMemo(() => {
    const dataLoaderFn = buildDataLoaderFn(orgChartId || "", dispatch)
    const chartDataClone = structuredClone(chartData)

    // Note: d3 stratify mutates data, so it can mess with rendering if
    // directly stratify the chart data. Always stratify with copies of the
    // chartData to prevent dependency changes.
    let jsonData
    try {
      jsonData = chartDataClone
        ? buildHierarchyWithCounts(
            chartDataClone,
            chartSettings.countOpenPositions,
            chartSettings.countDottedRelationships,
            chartSettings.childrenCount,
            chartViewId,
          )
        : {}
    } catch (error) {
      handleError(error)
      return {} as OverrideOptions
    }

    const overrideChartOptions = {
      actions: buildOrgchartActions(t, displayNodeAtTop, setChartViewId),
      dataLoaderFn,
      displayTopFn: displayNodeAtTop,
      dragAndDropEnabled: false,
      historyMode: true,
      jsonData,
      loadAsync: true,
      orgchartLite: true,
    }

    return {
      ...chartSettings,
      ...overrideChartOptions,
    }
  }, [
    chartData,
    chartSettings,
    orgChartId,
    dispatch,
    t,
    displayNodeAtTop,
    handleError,
    setChartViewId,
    chartViewId,
  ])

  useEffect(() => {
    setHasNoData(data?.chart?.chartHistoryNodes?.length === 0)
  }, [data])

  const { chart } = useOrgChart(
    chartData,
    fullChartOptions,
    handleError,
    chartContainerRef,
    chartSettings,
  )

  const highlightOrNavigateToNode = (node: NodeData, chart: OrgChart | null) => {
    if (!chart) return null
    const nodeOnChart = chart.find(node.id)

    if (nodeOnChart) {
      return chart.focus(nodeOnChart.id)
    }

    return displayNodeAtTop(node)
  }

  return (
    <>
      {!forExport && peripheralsProps && (
        <Peripherals
          abilities={peripheralsProps.abilities}
          approvalProps={peripheralsProps.approvalProps}
          importDropdownProps={peripheralsProps.importDropdownProps}
          isOfficialChart={peripheralsProps.isOfficialChart}
          sharePath={peripheralsProps.sharePath}
          chart={chart}
          onSelectSearch={(node: NodeData) => highlightOrNavigateToNode(node, chart || null)}
        />
      )}
      {hasError && <ChartError />}
      {hasNoData && (
        <ChartError message={t("v2.historical_orgchart.errors.no_data_found")}>
          <button type="button" className="bg-transparent underline" onClick={viewEntireChart}>
            {t("v2.historical_orgchart.view_entire_chart")}
          </button>
        </ChartError>
      )}
      {isFetching && <Spinner />}
      {!hasError && (
        <ErrorBoundary fallback={<ChartError />}>
          {!forExport && <OrgChartViewOptions chart={chart} />}
          <div
            ref={chartContainerRef}
            className="organize-container bg-primary-3-solid"
            id="organize-container"
            style={{ height: visualHeight }}
          />
        </ErrorBoundary>
      )}
    </>
  )
}

export { HistoricalOrgChart }
