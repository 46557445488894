/* eslint-disable no-nested-ternary */
import { FloatingPortal, useFloating } from "@floating-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { Dayjs } from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { useOnClickOutside } from "usehooks-ts"

import { useDisplayStateMethods } from "v2/react/components/headcountPlanning/hooks/useDisplayStateMethods"
import { ClientOnly } from "v2/react/shared/layout/ClientOnly"
import { incrementTriggerExport } from "v2/redux/slices/ExportSlice"
import { useAppDispatch } from "v2/redux/store"

import { SubmitProposalButton, SubmitProposalModal } from "../ShowParticipant/SubmitProposalButton"

type Props = {
  headcountPlanId: string
  participantId: string
  displayState?: "original" | "approved"
  setDisplayState?: (value: "original" | "approved") => void
  csvDownloadRef?: React.RefObject<HTMLButtonElement> | string
  setRef?: (element: HTMLButtonElement) => void
  /**
   * Shows the button if we're below the `sm` breakpoint.
   */
  showExportButton?: boolean
  /**
   * Shows the button if we're below the `sm` breakpoint.
   */
  showSubmitProposalButton?: boolean
  startDate: Dayjs
  canToggleDisplayState?: boolean
}
export function ParticipantTopbarMenu({
  headcountPlanId,
  participantId,
  csvDownloadRef,
  displayState,
  setDisplayState,
  setRef,
  showExportButton,
  showSubmitProposalButton,
  canToggleDisplayState,
  startDate,
}: Props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = React.useState(false)
  const [submitProposalModalIsOpen, setSubmitProposalModalIsOpen] = React.useState(false)
  const { refs, floatingStyles } = useFloating({ placement: "bottom-end" })

  useOnClickOutside(refs.floating, (event) => {
    if (refs.domReference?.current?.contains(event.target as Node)) return
    setIsOpen(false)
  })

  const { showOriginalView, showApprovedView } = useDisplayStateMethods({
    setDisplayState,
    headcountPlanId,
    setIsOpen,
  })

  const handleExportButtonClick = () => {
    if (typeof csvDownloadRef === "string") {
      dispatch(incrementTriggerExport())
    }
  }

  const showViewApprovedPlan = canToggleDisplayState && displayState === "original"
  const showViewOriginalProposal = canToggleDisplayState && displayState === "approved"

  const showMenu =
    showExportButton || showSubmitProposalButton || showViewApprovedPlan || showViewOriginalProposal

  if (!showMenu) return null

  const exportButtonId =
    typeof csvDownloadRef === "string" ? csvDownloadRef : csvDownloadRef?.current?.id
  const exportButtonRef = typeof csvDownloadRef === "string" ? undefined : csvDownloadRef ?? setRef

  return (
    <div className={cn({ "block sm:hidden": !(showViewApprovedPlan || showViewOriginalProposal) })}>
      <button
        ref={refs.setReference}
        type="button"
        id="plan-topbar-menu-button"
        className="btn--large btn--secondary dropdown-link w-11"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={["far", "ellipsis"]} />
      </button>
      {isOpen ? (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="dropdown-menu open z-20 mt-1"
          >
            {showViewApprovedPlan ? (
              <button
                className="dropdown-menu-link grid-cols-[1.25rem_auto] gap-2 grid"
                type="button"
                onClick={() => showApprovedView()}
              >
                <div className="justify-self-center">
                  <FontAwesomeIcon icon={["far", "circle-check"]} />
                </div>
                <span>{t("v2.headcount_plan.header.view_approved_plan")}</span>
              </button>
            ) : null}
            {showViewOriginalProposal ? (
              <button
                className="dropdown-menu-link grid-cols-[1.25rem_auto] gap-2 grid"
                type="button"
                onClick={() => showOriginalView()}
              >
                <div className="justify-self-center">
                  <FontAwesomeIcon icon={["far", "undo"]} />
                </div>
                {t("v2.headcount_plan.header.view_original_proposal")}
              </button>
            ) : null}
            {showExportButton ? (
              <button
                id={exportButtonId}
                ref={exportButtonRef}
                className="dropdown-menu-link grid-cols-[1.25rem_auto] gap-2 grid sm:hidden"
                type="button"
                onClick={handleExportButtonClick}
              >
                <div className="justify-self-center">
                  <FontAwesomeIcon icon={["fal", "download"]} />
                </div>
                {t("v2.defaults.export")}
              </button>
            ) : null}
            {showSubmitProposalButton ? (
              <SubmitProposalButton
                className="flex sm:hidden"
                disabled={false}
                display="menuItem"
                onClick={() => setIsOpen(false)}
                setIsOpen={setSubmitProposalModalIsOpen}
              />
            ) : null}
          </div>
        </FloatingPortal>
      ) : null}
      <ClientOnly>
        <SubmitProposalModal
          headcountPlanId={headcountPlanId}
          isOpen={submitProposalModalIsOpen}
          participantId={participantId}
          setIsOpen={setSubmitProposalModalIsOpen}
          startDate={startDate}
        />
      </ClientOnly>
    </div>
  )
}
