import { createSelector } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import {
  groupDynamicFieldKeysByCollectionName,
  isDynamicField,
} from "org_chart/chart/utils/relationalNodeDataStore/dynamicFields"

import {
  isVariablePayAmount,
  isVariablePayType,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/helpers"
import { OMIT_FROM_SPREADSHEET } from "v2/redux/slices/ContainerSlice/containerSelectors"
import { selectFieldKeysWithActiveFilters } from "v2/redux/slices/GridSlice/gridSelectors"
import {
  selectActiveDynamicFields,
  selectActiveStaticFields,
  selectChartSettings,
} from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { ReduxState } from "v2/redux/types"

const QueryForNodesOperationName = Object.freeze("NodeContainerPeopleAndPositionsConnection")
const PersonPositionNodeChangedSubscriptionName = "PersonPositionNodeChangedSubscription"

/** @private */
const selectPageInfo = (state: ReduxState) => state.node.pageInfo

/**
 * @public
 * @returns {string|null} the end cursor of the current page (if present).
 */
const selectEndCursor = createSelector(selectPageInfo, (pageInfo) =>
  pageInfo !== null ? pageInfo.endCursor : null,
)

/**
 * @public
 * @returns {object} an object with three properties: a corresponding type for
 *   each dynamic field (as dynamicFields), each dynamic field key indexed by
 *   type (in dynamicFieldsByCollectionName), and all static field keys.
 */
const selectFieldsForQuery = createSelector(
  selectActiveStaticFields,
  selectActiveDynamicFields,
  selectFieldKeysWithActiveFilters,
  (staticFieldKeys, dynamicFieldKeys, filterKeys) => {
    // While not common, there are cases where a filter may reference a field that isn't being
    // shown.
    //
    // Additionally, some fields require follow-up actions to edit that in turn may require
    // extra fields.
    //
    // In these cases, we need to include these fields in the query so the logic works
    // correctly. Thus, union filter field keys here and the static fields, and then consolidate and
    // refine these arrays as necessary.
    const dynamicFieldsByCollectionName = fp.pipe(
      fp.union(filterKeys),
      groupDynamicFieldKeysByCollectionName,
    )(dynamicFieldKeys)

    const dynamicFields = fp.keys(dynamicFieldsByCollectionName)

    const metaDataFieldsKeys = fp.pipe(
      fp.map(metaDataFields),
      fp.flatten,
      fp.uniq,
    )(staticFieldKeys.concat(dynamicFieldKeys))

    const allStaticFieldKeys = fp.pipe(
      fp.union(metaDataFieldsKeys),
      fp.union(filterKeys),
      fp.union(["lists", "chart_section"]),
      fp.reject(isDynamicField),
      fp.reject((field) => OMIT_FROM_SPREADSHEET.includes(field)),
    )(staticFieldKeys)

    return { dynamicFieldsByCollectionName, dynamicFields, staticFieldKeys: allStaticFieldKeys }
  },
)

/**
 * @private
 * @returns {[string]} an array of field keys needed as dependencies for the
 * other fields present in the query. An example is title and system_identifier
 * for budgeted pay types, b/c these fields are needed to populate items in
 * the follow up modal when editing.
 */
const metaDataFields = (fieldKey: string) => {
  const fields = [fieldKey]
  if (fieldKey === "title") return ["title", "job_code_title_label"]
  if (fieldKey === "job_code_title_label") return ["job_code_title_label", "title"]
  if (["position_base_pay_type", "position_base_pay", "position_hours_per_week"].includes(fieldKey))
    return fields.concat([
      "title",
      "system_identifier",
      "position_base_pay",
      "position_base_pay_reporting",
    ])
  if (["base_pay", "base_pay_type", "hours_per_week"].includes(fieldKey)) {
    return fields.concat(["name", "base_pay_reporting"])
  }
  if (["total_annual_compensation"].includes(fieldKey)) {
    return fields.concat(["total_annual_compensation_reporting"])
  }
  if (isVariablePayType(fieldKey) || isVariablePayAmount(fieldKey)) {
    return fields.concat(["name", "system_identifier", "title"])
  }
  if (fieldKey === "employee_status") return fields.concat(["name", "reports_to", "title"])
  return fields
}

/** @private */
const dynamicFieldsQueryFragment = (name: string) => {
  if (name === "variable_pays") {
    return `${name}(field_ids: $${name}) { id field_id formatted_value can_edit amount pay_type model_type reporting_amount }`
  }
  if (name === "org_units") {
    return `${name}(field_ids: $${name}) { id field_id formatted_value can_edit }`
  }
  return `${name}(field_ids: $${name}) { id field_id formatted_value can_edit reporting_amount }`
}

/** @private */
const nodeQueryFragment = (staticFieldKeys: string[], dynamicFields: string[]) => `
  ... on NodeInterface {
    id
    klass
    lock_version
    position_id
    person_id
    editable_fields
    ${staticFieldKeys.join("\n")}
    ${dynamicFields.map(dynamicFieldsQueryFragment).join("\n")}
  }
`

const selectGqlQueryStringForPageOfNodes = createSelector(
  selectFieldsForQuery,
  ({ staticFieldKeys, dynamicFields }) => `
    query NodeContainerPeopleAndPositionsConnection(
      $key: String!
      $endCursor: String
      $asOf: ISO8601Date
      ${dynamicFields.map((name) => `$${name}: [String!]!`).join("\n")}
    ) {
      nodeContainer(key: $key) {
        nodePage(after: $endCursor asOf: $asOf) {
          nodes {
            ${nodeQueryFragment(staticFieldKeys, dynamicFields)}
          }

          pageInfo { hasNextPage hasPreviousPage startCursor endCursor }
        }
      }
    }
  `,
)

const selectGqlQueryStringForNodeChangedSubscription = createSelector(
  selectFieldsForQuery,
  ({ staticFieldKeys, dynamicFields }) => `
    subscription PersonPositionNodeChangedSubscription(
      $nodeContainerId: ID!
      ${dynamicFields.map((name) => `$${name}: [String!]!`).join("\n")}
    ) {
      personPositionNodeChanged(nodeContainerId: $nodeContainerId) {
        changeType
        changedAttributes
        node { ${nodeQueryFragment(staticFieldKeys, dynamicFields)} }
        nodeId
        triggeredBy {
          id: uniqueKey
          name
          avatarThumbUrl
        }
        triggeredByType
      }
    }
  `,
)

const selectContainerKey = (state: ReduxState) => state.container.containerKey

const selectGqlQueryVariablesForNodes = createSelector(
  selectContainerKey,
  selectEndCursor,
  selectFieldsForQuery,
  selectChartSettings,
  (containerKey, endCursor, fieldsForQuery, chartSettings) => ({
    ...fieldsForQuery.dynamicFieldsByCollectionName,
    endCursor,
    key: containerKey,
    asOf:
      chartSettings.historyMode && chartSettings.historyModeSelectedDate
        ? chartSettings.historyModeSelectedDate
        : null,
  }),
)

const selectGqlQueryArgForPageOfNodes = createSelector(
  selectGqlQueryStringForPageOfNodes,
  selectGqlQueryVariablesForNodes,
  (query, variables) => ({
    operationName: QueryForNodesOperationName,
    query,
    variables,
  }),
)

const selectGqlQueryArgForNodeChangedSubscription = createSelector(
  selectGqlQueryStringForNodeChangedSubscription,
  selectContainerKey,
  selectFieldsForQuery,
  (query, nodeContainerId, fieldsForQuery) => ({
    operationName: PersonPositionNodeChangedSubscriptionName,
    query,
    variables: {
      ...fieldsForQuery.dynamicFieldsByCollectionName,
      nodeContainerId,
    },
  }),
)

export {
  selectContainerKey,
  selectFieldsForQuery,
  selectGqlQueryStringForPageOfNodes,
  selectGqlQueryArgForPageOfNodes,
  selectGqlQueryStringForNodeChangedSubscription,
  selectGqlQueryArgForNodeChangedSubscription,
}
