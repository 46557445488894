import React from "react"

import { CSV_DOWNLOAD_REF } from "v2/react/components/headcountPlanning/Owner/OwnerHeader"
import { withRootProvider } from "v2/react/components/RootProvider"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { UtilityNav } from "v2/react/shared/navigation/UtilityNav"
import { getCookie } from "v2/react/utils/cookies"
import { useGetOwnerDatasheetPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { useAppSelector } from "v2/redux/store"

import { AddNewPositionButton } from "./AddNewPositionButton"
import { GroupByToggleButton } from "./GroupByToggleButton"
import { useShowGroupByDropzone } from "./hooks/useShowGroupByDropzone"
import { OwnerDatasheet } from "./Owner/OwnerDatasheet"
import { DisplayState } from "./types"

function Content({ headcountPlanId, positionId }: { headcountPlanId: string; positionId: string }) {
  const ownerPage = useGetOwnerDatasheetPageQuery({ headcountPlanId })
  const currentPerson = ownerPage.data?.currentPerson
  const headcountPlan = ownerPage.data?.headcountPlan
  const isFinalized = !!headcountPlan?.lockedAt
  const dropdownCellCollections = ownerPage.data?.currentCompany?.dropdownCellCollections

  const displayStateCookie = getCookie(
    `built__display-state-for-hcp-${headcountPlanId}`,
  ) as DisplayState
  const displayState =
    useAppSelector((state) => state.headcountPlan.displayState) ?? displayStateCookie ?? "approved"
  const { showGroupByDropzone, setShowGroupByDropzone } = useShowGroupByDropzone(currentPerson)

  const alertTop = document.querySelector(".alert-top")?.getBoundingClientRect().height || 0
  const height = alertTop ? "calc(100vh - 50px)" : "100vh"
  const reportsTo = {
    id: currentPerson?.primaryPosition?.id,
    name: currentPerson?.name,
    title: currentPerson?.primaryPosition?.title,
    label:
      currentPerson?.name ||
      currentPerson?.primaryPosition?.title ||
      currentPerson?.primaryPosition?.id,
  }

  if (ownerPage.isLoading) return <Spinner />

  return (
    <div className={`h-[${height}]`}>
      <UtilityNav>
        <div className="w-full justify-between flex">
          <GroupByToggleButton on={showGroupByDropzone} setOn={setShowGroupByDropzone} />
          {isFinalized ? null : (
            <AddNewPositionButton
              primary
              reportsTo={reportsTo}
              headcountPlanId={headcountPlanId}
              positionId={positionId}
              startDate={headcountPlan?.startDate}
            />
          )}
        </div>
      </UtilityNav>

      <div className="page-pad-t page-pad-x gap-8">
        <OwnerDatasheet
          displayState={displayState}
          headcountPlanId={headcountPlanId}
          csvDownloadRef={CSV_DOWNLOAD_REF}
          headcountPlan={headcountPlan}
          dropdownCellCollections={dropdownCellCollections}
          showGroupByDropzone={showGroupByDropzone}
        />
      </div>
    </div>
  )
}

const Owner = withRootProvider(Content, "Owner")

export { Owner }
