import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { RadioGroup } from "v2/react/shared/forms/RadioGroup"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"
import { mergeCurrentSearchParamsWithUrl } from "v2/react/utils/urls"

interface Props {
  exportUrl: string
  onSubmit: () => void
}

function ExportForm({ onSubmit, exportUrl }: Props) {
  const { t } = useTranslation()
  const [exportOption, setExportOption] = useState("current-view")
  const [isSaving, setIsSaving] = useState(false)
  const downloadUrl = () => {
    if (exportOption === "current-view") {
      return mergeCurrentSearchParamsWithUrl(exportUrl)
    }

    return `${exportUrl}?full_data=1`
  }

  const handleExport = () => {
    setIsSaving(true)

    const anchor = document.createElement("a")
    anchor.href = downloadUrl()
    if (!globalThis.gon?.enable_async_exports) {
      anchor.download = ""
    }
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)

    setTimeout(() => {
      setIsSaving(false)
      onSubmit()
    }, 500)
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="react-modal__body">
        <legend className="mb-1 text-base-bold">
          {t("v2.succession.modals.export.what_data_should_be_included")}
        </legend>
        <RadioGroup
          groupName="succession-export-options"
          options={[
            {
              id: "current-view",
              value: "current-view",
              label: t("v2.succession.modals.export.current_view"),
            },
            {
              id: "full-data",
              value: "full-data",
              label: t("v2.succession.modals.export.full_data"),
            },
          ]}
          defaultChecked={exportOption}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setExportOption(event.target.value)
          }
        />
      </div>
      <SaveFooter
        isSaving={isSaving}
        enabledText={t("v2.defaults.export")}
        disabledText={t("v2.defaults.exporting")}
        onCancel={onSubmit}
        onSave={handleExport}
      />
    </form>
  )
}

export { ExportForm }
