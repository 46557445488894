import fp from "lodash/fp"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import {
  ListDiffEntries,
  PositionFieldValuesDiffProvider,
  RenderWhenDiffEntriesNonEmpty,
  WatchFieldName,
} from "v2/react/components/positions/positionFieldValuesDiff"
import {
  FieldValues,
  usePositionFormContext,
  usePositionWatchField,
} from "v2/react/components/positions/positionForm"
import { Notice } from "v2/react/shared/status/Notice"
import { safeNumber } from "v2/react/utils/safeNumber"

import { HeadcountPlanDatasheetRow } from "./HeadcountPlanDatasheet/types"

type DiffOfPositionAndHeadcountPlanDatasheetRowProps = {
  row: HeadcountPlanDatasheetRow
  watchKeys: WatchFieldName[]
}

function DiffOfPositionAndHeadcountPlanDatasheetRow({
  row,
  watchKeys,
}: DiffOfPositionAndHeadcountPlanDatasheetRowProps) {
  const { t } = useTranslation()
  const { initialFieldValues, positionFormCollections } = usePositionFormContext()
  const initialFieldValuesWithoutDefaults = useMemo(
    () => getInitialFieldValuesWithoutDefaults(row, initialFieldValues),
    [initialFieldValues, row],
  )
  const currentUnwrapped = usePositionWatchField({ name: "position" })
  const current = useMemo(() => ({ position: currentUnwrapped }), [currentUnwrapped])

  return (
    <PositionFieldValuesDiffProvider
      basis={initialFieldValuesWithoutDefaults}
      current={current}
      positionFormCollections={positionFormCollections}
      watchFieldNames={watchKeys}
    >
      <RenderWhenDiffEntriesNonEmpty>
        <Notice type="caution" icon={["far", "triangle-exclamation"]}>
          <div className="flex-col flex">
            <div>
              <strong className="text-neutral-100">
                {t("v2.headcount_plan.add_position_from_row_modal.form_diff_alert_into")}
              </strong>
            </div>
            <ListDiffEntries />
          </div>
        </Notice>
      </RenderWhenDiffEntriesNonEmpty>
    </PositionFieldValuesDiffProvider>
  )
}

/**
 * Utility which drops default values that were assigned by the position form
 * but are not set in the HCP row.
 */
function getInitialFieldValuesWithoutDefaults(
  { positionAttributesWithEdits: rowAttributes }: HeadcountPlanDatasheetRow,
  initialFieldValues: FieldValues | undefined,
) {
  const fteInRow = safeNumber(rowAttributes.full_time_equivalent, { fallback: undefined })
  const locationIdInRow = rowAttributes.location?.id

  let normalizedInitialFieldValues = initialFieldValues
  if (fteInRow === undefined) {
    normalizedInitialFieldValues = fp.unset(["position", "fte"], normalizedInitialFieldValues)
  }

  if (fp.isNil(locationIdInRow)) {
    normalizedInitialFieldValues = fp.pipe(
      fp.unset(["position", "locationId"]),
      fp.unset(["position", "locationName"]),
    )(normalizedInitialFieldValues)
  }

  return normalizedInitialFieldValues
}

export {
  DiffOfPositionAndHeadcountPlanDatasheetRow,
  DiffOfPositionAndHeadcountPlanDatasheetRowProps,
}
