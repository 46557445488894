import classNames from "classnames"
import React from "react"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface Props {
  icon?: React.ReactNode
  iconSrc?: string
  iconAlt?: string
  onClick?: () => void
  text: string
  className?: string
  disabled?: boolean
  tooltipContent?: React.HTMLProps<HTMLDivElement>["children"]
}

export function PositionMenuButton({
  icon,
  iconSrc,
  iconAlt,
  onClick,
  text,
  className,
  tooltipContent,
  disabled = false,
}: Props) {
  const handleButtonClick = () => {
    if (onClick) onClick()
  }

  const button = (
    <button
      disabled={disabled}
      className={classNames(
        `button-icon-grid justify-start bg-white py-1 text-start ${disabled && "disabled"}`,
        className,
      )}
      onClick={handleButtonClick}
      type="button"
    >
      {icon ? (
        <div className="justify-self-center">{icon}</div>
      ) : (
        <img
          src={iconSrc}
          alt={iconAlt}
          style={{ width: "1rem" }}
          className="justify-self-center"
        />
      )}
      <p>{text}</p>
    </button>
  )

  if (tooltipContent) {
    return (
      <Tooltip placement="right" gapSize={16}>
        <TooltipTrigger>{button}</TooltipTrigger>
        <TooltipContent className="react-tooltip-content--sm">{tooltipContent}</TooltipContent>
      </Tooltip>
    )
  }

  return button
}
