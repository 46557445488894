import fp from "lodash/fp"
import { createSelector } from "reselect"

import type { NodeInterface } from "types/graphql.d"
import { selectFields } from "v2/redux/slices/ContainerSlice/containerSelectors"
import {
  selectCursorColId,
  selectCursorRowId,
} from "v2/redux/slices/DatasheetSlice/cursor/cursorSelectors"
import type { CursorIndices } from "v2/redux/slices/DatasheetSlice/cursor/types"
import { makePredicate } from "v2/redux/slices/GridSlice/gridHelpers/makePredicate"
import { selectDatasheetRows } from "v2/redux/slices/GridSlice/gridSelectors/selectDatasheetRows"
import { RootState } from "v2/redux/store"
import type { ReduxState } from "v2/redux/types"

/**
 * @public
 * @returns {array} an array of field keys used for grouping in sorted order.
 */
const selectGroupFieldKeys = (state: ReduxState) => state.grid.groupFieldKeys

const selectShowColumnStats = (state: ReduxState) => state.grid.showColumnStats

/**
 * @public
 * @returns {array} an array of filter hashes where each hash holds a field key and term.
 */
const selectFilters = (state: ReduxState) => state.grid.filters

const withFieldKey = (_1: unknown, fieldKey: keyof NodeInterface) => fieldKey
const withTerm = (_1: unknown, _2: unknown, term: string) => term

const selectIsFilterActive = createSelector(
  [selectFilters, withFieldKey, withTerm],
  (filters, fieldKey, term) =>
    !!fp.find((filter) => filter.fieldKey === fieldKey && filter.term === term, filters),
)

/**
 * @public
 * @returns {"all" | "open" | "filled"} a value indicating which positions to show by status.
 */
const selectPositionStatusFilterOption = (state: ReduxState) =>
  state.visualization.gridPositionFilter

/**
 * @public
 * @returns {array} an array of field keys actively filtering data in the spreadsheet.
 */
const selectFieldKeysWithActiveFilters = createSelector(selectFilters, (filters) =>
  fp.map(fp.prop("fieldKey"), filters),
)

/**
 * @public
 * @returns {function} a function accepting a node and returning its position status.
 */
const selectPositionStatusFilter = createSelector(
  selectPositionStatusFilterOption,
  (option) =>
    ({ name }: NodeInterface) => {
      switch (option) {
        case "filled":
          return !!name
        case "open":
          return !name
        default:
          return true
      }
    },
)

/**
 * @public
 * @returns {function} a function accepting a node array and returning those matching all filters.
 */
const selectNodeFilterFunc = createSelector(
  selectFilters,
  selectPositionStatusFilter,
  (filters, positionStatusFilter) => {
    const predicates = fp.map(makePredicate, filters)
    const whereAllPredicatesPass = fp.allPass([...predicates, positionStatusFilter])

    return (nodes: NodeInterface[]) => fp.filter(whereAllPredicatesPass, nodes)
  },
)

// Selectors that involve parts from the grid slice and the cursor, which
// is stored in the datasheet slice.

const selectCursorIndices: (s: RootState) => CursorIndices = createSelector(
  selectCursorRowId,
  selectCursorColId,
  selectDatasheetRows,
  selectFields,
  (rowId, colId, rows, fields) => {
    if (fp.isUndefined(rowId) || fp.isUndefined(colId)) return [undefined, undefined]
    const rowIndex = fp.findIndex(fp.propEq("id", rowId), rows)
    let colIndex = fp.findIndex(fp.propEq("fieldKey", colId), fields)

    const mergeAvatarAndName =
      fields.find((f) => f.fieldKey === "avatar") && fields.find((f) => f.fieldKey === "name")

    colIndex -= mergeAvatarAndName ? 1 : 0
    return [rowIndex, colIndex]
  },
)

export {
  selectCursorIndices,
  selectFieldKeysWithActiveFilters,
  selectFilters,
  selectGroupFieldKeys,
  selectShowColumnStats,
  selectIsFilterActive,
  selectNodeFilterFunc,
  selectPositionStatusFilter,
}
