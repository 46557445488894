import cn from "classnames"
import { motion } from "framer-motion"
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { useDialog, type AriaDialogProps } from "react-aria"

interface DialogProps extends AriaDialogProps {
  children: ReactNode
  className?: string
  /**
   * Optional boolean will display the dialog dropdown in a portal. The benefits
   * of this is that the dialog can display outside of an overflow-hidden
   * parent. The drawback is that the focus when tabbing will not move from the
   * date input segments to the calendar month/year dropdowns
   */
  dialogInPortal?: boolean
  onClickOutside: () => void
  /**
   * The ID of the date input wrapper.  It's important to get this
   * set correctly so the component closes when clicking outside of it.
   */
  fieldId: string
  /**
   * The ref of the date input wrapper. This is used to determine if the click
   * event is outside of the date input field.
   */
  fieldRef: React.RefObject<HTMLDivElement>
  /**
   * By default, focus transitions to the dialog on mount. In cases where we
   * want to force the focus elsewhere on mount, provide the ref here.
   */
  focusRefOnMount?: React.RefObject<HTMLElement>
  size?: "sm"
  triggerCoordinates: DOMRect
}

/* eslint-disable react/jsx-props-no-spreading */
function Dialog({
  children,
  className,
  dialogInPortal,
  onClickOutside,
  fieldId,
  fieldRef,
  size,
  triggerCoordinates,
  ...props
}: DialogProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [isAnimationComplete, setIsAnimationComplete] = useState(false)
  const { dialogProps } = useDialog(props, ref)

  useEffect(() => {
    if (props.focusRefOnMount && isAnimationComplete) {
      props.focusRefOnMount.current?.focus()
    }
  }, [props.focusRefOnMount, isAnimationComplete])

  const determinePosition = () => {
    if (!dialogInPortal) return {}

    const lowerPositioning = triggerCoordinates.top + triggerCoordinates.height

    // If we know the size beforehand, we can determine if the dropdown extends
    // beyond the bottom of the viewport and position it above the trigger
    // instead.
    if (size === "sm") {
      const dropdownHeight = 290
      const upperPositioning = triggerCoordinates.top - dropdownHeight - 16

      if (lowerPositioning + dropdownHeight > window.innerHeight) {
        return {
          top: `${upperPositioning}px`,
          left: `${triggerCoordinates.left}px`,
        }
      }
    }

    return {
      top: `${lowerPositioning}px`,
      left: `${triggerCoordinates.left}px`,
    }
  }

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!event.isTrusted) {
        return // Ignore non-user-triggered events
      }
      if (
        !fieldRef.current ||
        (event?.target as HTMLElement).matches(
          `#${fieldId}, #${fieldId} *, #${fieldId}-Dialog, #${fieldId}-Dialog *`,
        )
      ) {
        return
      }

      onClickOutside()
    },
    [fieldId, fieldRef, onClickOutside],
  )

  // Currently, when a date picker calendar is open and you click on a different
  // calendar trigger, the initial one does not close. This is a hacky
  // workaround to ensure that the initial dialog closes when a new one is
  // opened.
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    document.addEventListener("mousedown", handleClickOutside, true)
    document.addEventListener("touchstart", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
      document.removeEventListener("mousedown", handleClickOutside, true)
      document.removeEventListener("touchstart", handleClickOutside, true)
    }
  }, [handleClickOutside])

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.97 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.97 }}
      transition={{ ease: [0.17, 0.67, 0.83, 0.67], duration: 0.084 }}
      onAnimationComplete={() => setIsAnimationComplete(true)}
      className={cn({ "absolute z-10": !dialogInPortal })}
    >
      {/* eslint-disable react/jsx-props-no-spreading */}
      <div
        {...dialogProps}
        id={`${fieldId}-Dialog`}
        ref={ref}
        className={cn(
          "Date-Range-Dialog elevation absolute left-0 my-2 min-w-[20rem] rounded-lg bg-white p-2",
          className,
          { "max-h-[290px] overflow-y-auto": size === "sm", "z-10": dialogInPortal },
        )}
        style={determinePosition()}
      >
        {children}
      </div>
    </motion.div>
  )
}

export { Dialog }
