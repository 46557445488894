import {
  editingCellState,
  erroredEditingCellState,
  erroredSelectedCellState,
  IdObject,
  initialCellState,
  selectedCellState,
  separateId,
} from "./cellStateMachines"
import { inEitherRead, inEitherWriteState, onNothing } from "./cursor/cursorStates"
import { CellCursor, CursorState } from "./cursor/types"
import {
  clearFieldErrors,
  isEmptyErrorSet,
  makeNextDatasheetErrorSet,
} from "./datasheetHelpers/datasheetErrorSets"
import { CellState, DatasheetErrorSet } from "./types"

export function translateReduxToCellState(
  storedCellState: CellState | undefined,
  obj: IdObject,
  cursor: CellCursor,
) {
  const isErrored = storedCellState
    ? ["errored", "erroredEditing", "erroredSelected"].includes(storedCellState.state)
    : false

  if (
    isErrored &&
    inEitherRead(cursor) &&
    cursor.rowId === obj.rowId &&
    cursor.columnId === obj.columnId
  ) {
    return erroredSelectedCellState(cursor, storedCellState?.errorMessage)
  }

  if (
    isErrored &&
    inEitherWriteState(cursor) &&
    cursor.rowId === obj.rowId &&
    cursor.columnId === obj.columnId
  ) {
    return erroredEditingCellState(cursor, storedCellState?.errorMessage)
  }

  if (storedCellState) return storedCellState

  if (inEitherRead(cursor) && cursor.rowId === obj.rowId && cursor.columnId === obj.columnId) {
    return selectedCellState(cursor)
  }
  if (
    inEitherWriteState(cursor) &&
    cursor.rowId === obj.rowId &&
    cursor.columnId === obj.columnId
  ) {
    return editingCellState(cursor)
  }

  return initialCellState(obj)
}

export function translateCellStateToRedux(
  cellState: CellState,
  cursor: CellCursor,
  existingErrorSet: DatasheetErrorSet | undefined,
) {
  return {
    id: cellState.id,
    storedCellState: translateCellStateToStoredCellState(cellState),
    errorSet: translateCellStateToErrorSet(cellState, existingErrorSet),
    cursor: translateCellStateToCursor(cellState, cursor),
  }
}

function translateCellStateToStoredCellState(cellState: CellState) {
  // Remove the cell from redux if idle, selected or editing so that we're not storing a bunch of useless
  // state in redux. So:
  //   - cell not in store and cursor not on cell => "idle"
  //   - cell not in store and cursor on cell => "selected"
  //   - cell not in store and cursor in write state on cell => "editing"
  if (
    cellState.state === "idle" ||
    cellState.state === "selected" ||
    cellState.state === "editing"
  ) {
    return undefined
  }
  return cellState
}

function translateCellStateToCursor(cellState: CellState, cursor: CellCursor): CellCursor {
  const { rowId, columnId } = separateId(cellState.id)
  // There will be a 'nextCursor' in the state if the save was initiated by a hotkey.
  // In this scenario, we don't want to move the cursor yet if we're in the 'saving' or 'followUp' states,
  // because those states indicate that we're still mid-save. We only want to move the cursor after the save
  // is completed, whether that's an actual successful save or a noop.
  if (cellState.nextCursor && !["saving", "followUp"].includes(cellState.state)) {
    return cellState.nextCursor
  }
  // If the cursor has moved off the cell, leave it alone.
  if (onNothing(cursor) || cursor.rowId !== rowId || cursor.columnId !== columnId) {
    return cursor
  }

  if (
    ["errored", "erroredEditing", "selected"].includes(cellState.state) &&
    inEitherWriteState(cursor)
  ) {
    return {
      rowId: cursor.rowId,
      columnId: cursor.columnId,
      editable: true,
      state: CursorState.OnEditable,
      fieldType: cursor.fieldType,
      enteredBy: "placement",
      currentValue: null,
    }
  }

  return cursor
}

function translateCellStateToErrorSet(
  cellState: CellState,
  existingErrorSet: DatasheetErrorSet | undefined,
) {
  const { rowId, columnId } = separateId(cellState.id)

  if (["errored", "erroredEditing", "erroredSelected"].includes(cellState.state)) {
    const nextDatasheetErrorSet = makeNextDatasheetErrorSet(
      [
        {
          path: ["attributes", columnId],
          message: cellState.errorMessage ?? "Unknown Error",
        },
      ],
      rowId,
      existingErrorSet,
    )
    return nextDatasheetErrorSet
  }

  if (!existingErrorSet) {
    return undefined
  }

  const newSet = clearFieldErrors([columnId], existingErrorSet)

  if (isEmptyErrorSet(newSet)) {
    return undefined
  }

  return newSet
}
