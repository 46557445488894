import {
  ChartNodeHistoryBaseDataQuery,
  ChartNodeHistoryBaseDataQueryVariables,
  ChartNodeHistoryByIdsFullDataQuery,
  ChartNodeHistoryByIdsFullDataQueryVariables,
  ChartNodeHistoryChartSectionsQuery,
  ChartNodeHistoryChartSectionsQueryVariables,
  ChartNodeHistoryConnectionQuery,
  ChartNodeHistoryConnectionQueryVariables,
} from "types/graphql"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { queryOperation } from "v2/redux/utils/endpoints"

const ChartNodeHistoriesApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    chartNodeHistoryBaseData: builder.query<
      ChartNodeHistoryBaseDataQuery,
      ChartNodeHistoryBaseDataQueryVariables
    >({
      query: queryOperation<ChartNodeHistoryBaseDataQueryVariables>("ChartNodeHistoryBaseData"),
    }),
    chartNodeHistoryChartSections: builder.query<
      ChartNodeHistoryChartSectionsQuery,
      ChartNodeHistoryChartSectionsQueryVariables
    >({
      query: queryOperation<ChartNodeHistoryChartSectionsQueryVariables>(
        "ChartNodeHistoryChartSections",
      ),
    }),
    chartNodeHistoryByIdsFullData: builder.query<
      ChartNodeHistoryByIdsFullDataQuery,
      ChartNodeHistoryByIdsFullDataQueryVariables
    >({
      query: queryOperation<ChartNodeHistoryByIdsFullDataQueryVariables>(
        "ChartNodeHistoryByIdsFullData",
      ),
    }),
    chartNodeHistorySearch: builder.query<
      ChartNodeHistoryConnectionQuery,
      ChartNodeHistoryConnectionQueryVariables
    >({
      query: queryOperation<ChartNodeHistoryConnectionQueryVariables>("ChartNodeHistoryConnection"),
    }),
  }),
})

export { ChartNodeHistoriesApi }

export const {
  useChartNodeHistoryBaseDataQuery,
  useChartNodeHistoryChartSectionsQuery,
  useChartNodeHistoryByIdsFullDataQuery,
  useChartNodeHistorySearchQuery,
} = ChartNodeHistoriesApi
