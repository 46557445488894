import React from "react"

import { CSV_DOWNLOAD_REF } from "v2/react/components/headcountPlanning/Participant/ParticipantHeader"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { UtilityNav } from "v2/react/shared/navigation/UtilityNav"
import { getCookie } from "v2/react/utils/cookies"
import { useGetParticipantDatasheetPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { setDisplayState } from "v2/redux/slices/HeadcountPlanSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { withRootProvider } from "../RootProvider"
import { AddNewPositionButton } from "./AddNewPositionButton"
import { GroupByToggleButton } from "./GroupByToggleButton"
import { useShowGroupByDropzone } from "./hooks/useShowGroupByDropzone"
import { ParticipantDatasheet } from "./ShowParticipant/ParticipantDatasheet"
import { DisplayState } from "./types"

interface Props {
  headcountPlanId: string
  participantId: string
}

function Content({ headcountPlanId, participantId }: Props) {
  const dispatch = useAppDispatch()
  const participantPage = useGetParticipantDatasheetPageQuery({ headcountPlanId, participantId })
  const currentPerson = participantPage.data?.currentPerson
  const headcountPlan = participantPage.data?.headcountPlan
  const participant = headcountPlan?.participant
  const person = participant?.person
  const position = participant?.position
  const hasSubmitted = participant?.status === "complete"
  const dropdownCellCollections = participantPage.data?.currentCompany?.dropdownCellCollections
  const displayStateCookie = getCookie(
    `built__display-state-for-hcp-${headcountPlanId}`,
  ) as DisplayState
  const displayState =
    useAppSelector((state) => state.headcountPlan.displayState) ?? displayStateCookie ?? "approved"

  const { showGroupByDropzone, setShowGroupByDropzone } = useShowGroupByDropzone(currentPerson)

  const alertTop = document.querySelector(".alert-top")?.getBoundingClientRect().height || 0
  const height = alertTop ? "calc(100vh - 100px)" : "100vh"

  const reportsTo = {
    id: position?.id ?? "",
    name: person?.name ?? "",
    title: position?.title ?? "",
    label: (person?.name || position?.title || position?.id) ?? "",
  }

  if (participantPage.isLoading) return <Spinner />

  const updateDisplayState = (newState: DisplayState) => {
    dispatch(setDisplayState(newState))
  }

  return (
    <div className={`h-[${height}]`}>
      <UtilityNav>
        <div className="w-full justify-between flex">
          <GroupByToggleButton on={showGroupByDropzone} setOn={setShowGroupByDropzone} />
          {hasSubmitted ? null : (
            <AddNewPositionButton
              primary
              reportsTo={reportsTo}
              positionId={position?.id}
              headcountPlanId={headcountPlanId}
              participantId={participantId}
              startDate={headcountPlan?.startDate ?? ""}
            />
          )}
        </div>
      </UtilityNav>

      <div className="page-pad-t page-pad-x gap-8">
        <ParticipantDatasheet
          displayState={displayState}
          setDisplayState={updateDisplayState}
          headcountPlanId={headcountPlanId}
          participantId={participantId}
          headcountPlan={headcountPlan}
          participant={participant}
          dropdownCellCollections={dropdownCellCollections}
          csvDownloadRef={CSV_DOWNLOAD_REF}
          showGroupByDropzone={showGroupByDropzone}
        />
      </div>
    </div>
  )
}

const ShowParticipant = withRootProvider(Content, "ShowParticipant")

export { ShowParticipant }
