import { RootState } from "v2/redux/store"

const selectCanEditChartNodes = (state: RootState) => state.ability.canEditChartNodes
const selectCanManageChart = (state: RootState) => state.ability.canManageChart
const selectCanTimeTravelChart = (state: RootState) => state.ability.canTimeTravelChart
const selectCanManageOfficialChart = (state: RootState) => state.ability.canManageOfficialChart
const selectIsLimitedAdminUsingOfficialChart = (state: RootState) =>
  state.ability.isLimitedAdminUsingOfficialChart

export {
  selectCanEditChartNodes,
  selectCanManageChart,
  selectCanTimeTravelChart,
  selectIsLimitedAdminUsingOfficialChart,
}
