import { Collections } from "types/graphql.d"
import { formatCurrency } from "v2/react/utils/currency"

import { HeadcountPlanDatasheetRow } from "./types"

export function formatVariablePay(
  currentBasePay: number,
  payload: HeadcountPlanDatasheetRow["payload"],
  attributeId: string,
) {
  const pay = payload[attributeId]
  const payType = payload[`${attributeId}_type`]
  if (payType?.includes("percent")) {
    if (pay === "") return ""
    let basePay = currentBasePay
    if ("budgeted_base_pay_rate" in payload) {
      basePay = annualizedBasePay(payload)
    }
    const value = (pay * basePay) / 100
    if (Number.isNaN(value)) return `- (${pay}%)`
    return `${formatCurrency({ value })} (${pay}%)`
  }
  return formatCurrency({ value: pay })
}

function annualizedBasePay(payload: HeadcountPlanDatasheetRow["payload"]) {
  const basePay = parseFloat(payload.budgeted_base_pay_rate)
  const basePayType = payload.budgeted_base_pay_type
  const hoursPerWeek = parseFloat(payload.position_hours_per_week)

  if (basePayType === "position_base_pay_type_hourly") {
    return basePay * hoursPerWeek * 52
  }

  return basePay
}

export function formatBasePay(payload: HeadcountPlanDatasheetRow["payload"]) {
  const basePay = payload.budgeted_base_pay_rate
  return formatCurrency({ value: basePay })
}

export function isDropdownCellKey(
  fieldKey: string,
): fieldKey is keyof typeof dropdownCellKeyToCollectionMap {
  return fieldKey in dropdownCellKeyToCollectionMap
}

export function isVariablePayTypeKey(fieldKey: string) {
  return /^variable_pay_type_\d+_type$/.test(fieldKey)
}

export function isVariablePayKey(fieldKey: string) {
  return /^variable_pay_type_\d+$/.test(fieldKey)
}

export function isOrgUnitTypeKey(fieldKey: string) {
  return /^org_unit_type_\d+$/.test(fieldKey)
}

type DropdownCellName =
  | "employee_type"
  | "position_importance"
  | "position_status"
  | "budgeted_base_pay_type"
  | "budgeted_variable_pay_type"
  | "eeoc_classification"
  | "flsa_classification"
  | "hiring_priority"
  | "position_type"

type DropdownCellCollections = Pick<
  Collections,
  | "employeeTypes"
  | "positionImportances"
  | "positionStatuses"
  | "positionBasePayTypes"
  | "variablePayPayTypes"
  | "eeocClassifications"
  | "flsaClassifications"
  | "hiringPriorities"
  | "positionPlanTypes"
>

export const dropdownCellKeyToCollectionMap: {
  [key in DropdownCellName]: keyof DropdownCellCollections
} = {
  employee_type: "employeeTypes",
  position_importance: "positionImportances",
  position_status: "positionStatuses",
  budgeted_base_pay_type: "positionBasePayTypes",
  budgeted_variable_pay_type: "variablePayPayTypes",
  eeoc_classification: "eeocClassifications",
  flsa_classification: "flsaClassifications",
  hiring_priority: "hiringPriorities",
  position_type: "positionPlanTypes",
}
