import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TFunction } from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

import { Person, Position, ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { StickyHeader } from "v2/react/components/orgChart/ProfilePanel/StickyHeader"
import { joinStrings } from "v2/react/utils/strings"
import { UrlHelper } from "v2/react/utils/urls"

import { OpenLinkInNewTab } from "./Icons/OpenLinkInNewTab"
import { PanelToggle } from "./PanelToggle"
import { IS_V2 } from "./utils/constants"

interface ProfilePanelHeaderProps {
  abilities?: ProfilePanelPermissions
  handleOpenSettings: () => void
  handleV2Close: () => void
  isOfficialChart: boolean
  panelMode?: ProfilePanelMode
  person?: Person | null
  position?: Position | null
}

const ProfilePanelHeader: React.FC<ProfilePanelHeaderProps> = ({
  abilities,
  handleOpenSettings,
  handleV2Close,
  isOfficialChart,
  panelMode,
  person,
  position,
}) => {
  const { t } = useTranslation()

  const conditionsForLoading = (!person && !position) || !abilities || !panelMode
  if (conditionsForLoading) return null

  return (
    <>
      <div className="react-profile-panel__actions">
        <div />
        <PanelToggle panelMode={panelMode} />
        <div className="col-start-3 items-center justify-end gap-2 flex">
          <SettingsButton handleOpenSettings={handleOpenSettings} t={t} />
          {panelMode === ProfilePanelMode.Person && <PersonProfileButton person={person} t={t} />}
          {panelMode === ProfilePanelMode.Position &&
            abilities?.canShowPositionPage &&
            isOfficialChart && <PositionProfileButton position={position} t={t} />}
          <ClosePanelButton handleV2Close={handleV2Close} />
        </div>
      </div>
      {panelMode === ProfilePanelMode.Position && (
        <StickyHeader>
          <div className="w-full items-center justify-between flex">
            <div>
              <h1 className="title m-0 text-large">
                {position?.title || t("v2.profile_panel.untitled_position")}
              </h1>
              <p className="text-sm text-neutral-64">
                {position?.people && position?.people.length > 0
                  ? `${t("v2.profile_panel.filled_by")} ${joinStrings(
                      position.people.map((p) => p.name),
                      t,
                    )}`
                  : t("v2.defaults.open_position")}
              </p>
            </div>
            <ClosePanelButton handleV2Close={handleV2Close} />
          </div>
        </StickyHeader>
      )}
      {panelMode === ProfilePanelMode.Person && (
        <StickyHeader>
          <div className="w-full items-center justify-between flex">
            <div className="person-header items-center gap-2 flex">
              <div className="items-center flex">
                <div>
                  <h1
                    data-pjax-container="profile-header-info"
                    className="title no-margin profile-header-info flex-none text-large"
                  >
                    {person?.fullName}
                  </h1>
                  <p className="text-sm text-neutral-64">
                    {position?.title || t("v2.profile_panel.untitled_position")}
                  </p>
                </div>
              </div>
            </div>
            <ClosePanelButton handleV2Close={handleV2Close} />
          </div>
        </StickyHeader>
      )}
    </>
  )
}

export { ProfilePanelHeader }

interface SettingsButtonProps {
  handleOpenSettings: () => void
  t: TFunction
}

const SettingsButton = ({ handleOpenSettings, t }: SettingsButtonProps) => (
  <div
    className="tooltip tooltip-right btn--large btn--secondary w-10"
    data-testid="settings-icon-button"
  >
    <FontAwesomeIcon
      className="cursor-pointer"
      icon={["far", "gear"]}
      onClick={handleOpenSettings}
    />
    <span className="tooltip-content tooltip-content--sm">
      {t("v2.profile_panel.configure_panel")}
    </span>
  </div>
)

interface PersonProfileButtonProps {
  person: Person | null | undefined
  t: TFunction
}

const PersonProfileButton = ({ person, t }: PersonProfileButtonProps) => (
  <div className="tooltip tooltip-right btn--large btn--secondary w-10">
    <OpenLinkInNewTab url={UrlHelper.personProfilePath(person?.id || "")} />
    <span className="tooltip-content tooltip-content--sm">
      {t("v2.profile_panel.view_profile")}
    </span>
  </div>
)

interface PositionProfileButtonProps {
  position: Position | null | undefined
  t: TFunction
}

const PositionProfileButton = ({ position, t }: PositionProfileButtonProps) => (
  <div className="tooltip tooltip-right btn--large btn--secondary w-10">
    <OpenLinkInNewTab url={UrlHelper.positionShowPath(position?.id || "")} />
    <span className="tooltip-content tooltip-content--sm">
      {t("v2.profile_panel.view_position")}
    </span>
  </div>
)

interface ClosePanelButtonProps {
  handleV2Close: () => void
}

const ClosePanelButton = ({ handleV2Close }: ClosePanelButtonProps) => (
  <button
    className="btn--icon btn--ghost"
    data-action={IS_V2 ? undefined : "hide-panel"}
    onClick={handleV2Close}
    type="button"
  >
    <FontAwesomeIcon className="cursor-pointer" icon={["far", "xmark"]} />
  </button>
)
