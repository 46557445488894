import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import parse from "html-react-parser"
import React from "react"
import { useTranslation } from "react-i18next"
import sanitizeHtml from "sanitize-html"

import { Modal, SaveFooter } from "v2/react/shared/overlay/Modal"
import { useFinalizeHeadcountPlanMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

type Props = {
  canBeFinalized: boolean
  onClick?: () => void
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  display?: "menuItem" | "button"
  className?: string
}
export function FinalizePlanButton({
  canBeFinalized,
  setIsOpen,
  onClick,
  display = "button",
  className,
}: Props) {
  const { t } = useTranslation()

  return (
    <button
      className={cn(
        "whitespace-nowrap",
        {
          "btn--large btn--secondary": display === "button",
          "dropdown-menu-link gap-2 flex": display === "menuItem",
          "btn-disabled": !canBeFinalized,
        },
        className,
      )}
      type="button"
      onClick={() => {
        setIsOpen(true)
        onClick?.()
      }}
      disabled={!canBeFinalized}
    >
      <div className="justify-self-center">
        <FontAwesomeIcon className="icon--success" icon={["fad", "circle-check"]} />
      </div>
      {t("v2.headcount_plan.finalize_plan.button_text")}
    </button>
  )
}

type ModalProps = {
  headcountPlanId: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  headcountPlanName: string
}
export function FinalizePlanModal({
  isOpen,
  setIsOpen,
  headcountPlanId,
  headcountPlanName,
}: ModalProps) {
  const { t } = useTranslation()
  const [mutate, mutation] = useFinalizeHeadcountPlanMutation()

  const handleSave = () => {
    mutate({
      id: headcountPlanId,
    })
      .unwrap()
      .then((response) => {
        if (response.finalizeHeadcountPlan.success) {
          setIsOpen(false)
        }
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={t("v2.headcount_plan.finalize_plan.modal_title")}
    >
      <div className="react-modal__body flex-col gap-2 flex">
        <p>
          {parse(
            sanitizeHtml(
              t("v2.headcount_plan.finalize_plan.modal_body_p1_html", { name: headcountPlanName }),
            ),
          )}
        </p>
        <p>{t("v2.headcount_plan.finalize_plan.modal_body_p2")}</p>
      </div>
      <SaveFooter
        isSaving={mutation.isLoading}
        enabledText={t("v2.headcount_plan.finalize_plan.button_text")}
        disabledText={t("v2.headcount_plan.finalize_plan.button_text_pending")}
        onCancel={() => setIsOpen(false)}
        onSave={handleSave}
      />
    </Modal>
  )
}
