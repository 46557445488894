import { useMemo } from "react"

import { FieldSuggestionsPerformActionsInput } from "types/graphql"
import { FieldSuggestionAction } from "types/graphql.enums"
import { useFieldSuggestionsPerformActionsMutation } from "v2/redux/GraphqlApi/FieldSuggestionsApi"
import {
  beginInitializingFieldSuggestion,
  cancelGeneratingFieldSuggestion,
  fieldsSelectors,
  FieldSuggestionState,
  updatedField,
} from "v2/redux/slices/FieldSuggestionSlice"
import { useAppDispatch } from "v2/redux/store"

import { isBackfilled } from "./useTypedFieldWithSuggestion/fieldSuggestionHelpers"

type PerformSuggestionActionMutation = ReturnType<typeof useFieldSuggestionsPerformActionsMutation>
type PerformSuggestionActionState = PerformSuggestionActionMutation[1]
type PerformSuggestionActionInput = Omit<FieldSuggestionsPerformActionsInput, "id">

// Ensures `valueOrValues` is an array.
const arrayWrap = <Kind>(valueOrValues: Kind | Kind[]): Kind[] =>
  Array.isArray(valueOrValues) ? valueOrValues : [valueOrValues]

// Normalizes caller input to be properly structured for the mutation.
const mapToSuggestionActions = (action: FieldSuggestionAction, fields: string | string[]) =>
  arrayWrap(fields).map((field) => ({ field, action }))

/**
 * Builds functions to accept, decline, initialize, or (re-)generate field
 * suggestions.
 *
 * These functions help with guiding/managing socket communication as well as
 * reducing boiler plate in components. Each function accepts a single field
 * or an array of fields.
 *
 * @example
 *     declineFieldSuggestions("summary")
 * @example
 *     acceptFieldSuggestions(["tasks_and_responsibilities", "something_else"])
 * @example
 *     generateFieldSuggestions(["skills", "worker_characteristics"])
 * @public
 */
const useFieldSuggestionsActions = (entityId: string) => {
  const dispatch = useAppDispatch()
  const [performAction, performActionState] = useFieldSuggestionsPerformActionsMutation()

  const preparedActions = useMemo(() => {
    const performAll = async (input: PerformSuggestionActionInput["fieldSuggestionActions"]) => {
      try {
        const result = await performAction({
          fieldSuggestionActions: input,
          id: entityId,
        }).unwrap()
        const errors = result.fieldSuggestionsPerformActions?.errors
        return !errors || errors.length === 0
      } catch (_error) {
        // Don't need any handling for the error since RTK Query puts it in the
        // backing hook's errors. It is only thrown since we unwrap the
        // mutation. Return false to indicate failure.
        return false
      }
    }

    return {
      acceptFieldSuggestions: (fields: string | string[]) =>
        performAll(mapToSuggestionActions(FieldSuggestionAction.Accept, fields)),
      /**
       * Manually cancels a pending initialize/generate call.
       *
       * @see generateFieldSuggestions
       */
      cancelGeneratingFieldSuggestions: (fieldOrFields: string | string[]) =>
        arrayWrap(fieldOrFields).forEach((field) =>
          dispatch(cancelGeneratingFieldSuggestion({ entityId, field })),
        ),
      declineFieldSuggestions: (fields: string | string[]) =>
        performAll(mapToSuggestionActions(FieldSuggestionAction.Decline, fields)),
      /**
       * Important - flags that updates over the socket can be applied. The
       * updates must be received within ~10s before we treat the request as
       * a timeout. A different timeout (in milliseconds) can optionally be
       * given.
       *
       * `cancelGeneratingFieldSuggestion` can be used to manually exit this
       * state.
       */
      generateFieldSuggestions: (fields: string | string[], timeoutMs?: number) => {
        arrayWrap(fields).forEach((field) => {
          dispatch(async (_, getState) => {
            const id = `${entityId}-${field}`
            const entry = fieldsSelectors.selectById(getState(), id)
            if (!entry) return

            if (isBackfilled(entry.state)) {
              dispatch(beginInitializingFieldSuggestion({ entityId, field, timeoutMs }))
              performAll(mapToSuggestionActions(FieldSuggestionAction.Initialize, [field]))
              return
            }

            dispatch(updatedField({ id, changes: { state: FieldSuggestionState.Generating } }))

            const payload: Parameters<typeof updatedField>[0] = {
              id,
              changes: {},
            }

            if (typeof entry.initializedValue === "string") {
              payload.changes = {
                state: FieldSuggestionState.Generated,
                initializedValue: entry.initializedValue,
                value: entry.initializedValue,
              }
            } else {
              payload.changes = {
                state: FieldSuggestionState.Generated,
                initializedValue: entry.initializedValue,
                value: entry.initializedValue,
              }
            }

            // Small delay to avoid any batching (so effects capture state transitions)
            setTimeout(() => {
              dispatch(updatedField(payload))
            }, 4)
          })
        })

        return Promise.resolve(true)
      },
      initializeFieldSuggestions: (fields: string | string[], timeoutMs?: number) => {
        arrayWrap(fields).forEach((field) =>
          dispatch(beginInitializingFieldSuggestion({ entityId, field, timeoutMs })),
        )

        return performAll(mapToSuggestionActions(FieldSuggestionAction.Initialize, fields))
      },
    }
  }, [dispatch, performAction, entityId])

  return { ...preparedActions, state: performActionState }
}

export { useFieldSuggestionsActions }
export type {
  PerformSuggestionActionInput,
  PerformSuggestionActionMutation,
  PerformSuggestionActionState,
}
