import { EntityId } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import { Maybe, Person, PositionSeatChangeIntent } from "types/graphql.d"

export const withNameModal = (value: string) => `modal:name<SEP>${value}`
export const withSeatChangeModal = (value: string) => `modal:seat<SEP>${value}`

export const toEncodedInitialData = (value: string) => `initializeWith:${value}`
export const toEncodedIdIntentPair = (id: EntityId, intent: PositionSeatChangeIntent) =>
  `id:${id}<SEP>intent:${intent}`

export const getIdAndIntentFromEncoded = (value: string) => {
  const [id, intent] = fp.pipe(fp.split("<SEP>intent:"), fp.map(fp.replace(/^id:/, "")))(value)
  return { id, intent }
}

export const isNameModal = (value: Maybe<string> | undefined) =>
  fp.startsWith("modal:name<SEP>", value ?? "")
export const isSeatChangeModal = (value: Maybe<string> | undefined) =>
  fp.startsWith("modal:seat<SEP>", value ?? "")

const removeModalPrefix = fp.replace(/^modal:.+<SEP>/, "")
const removeInitialDataPrefix = fp.replace(/^initializeWith:/, "")
export const getInitialNameDataFromEncoded = (value: Maybe<string> | undefined) =>
  fp.pipe(removeModalPrefix, removeInitialDataPrefix)(value ?? "")

export const getInitialPersonDataFromEncoded = (value: Maybe<string> | undefined) => {
  const personData = fp.pipe(removeModalPrefix, removeInitialDataPrefix, JSON.parse)(value ?? "{}")
  return personData as Person
}
