import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"

interface RemovePositionOptionCardProps {
  imageUrl: string
  isSelected: boolean
  handleSelection: () => void
  message: string
}

function RemovePositionOptionCard({
  imageUrl,
  isSelected,
  message,
  handleSelection,
}: RemovePositionOptionCardProps) {
  return (
    <div className="flex-1">
      <button
        type="button"
        className={cn("module selector-module mb-0 bg-transparent p-0 focus:border-primary-100", {
          selected: isSelected,
        })}
        onClick={handleSelection}
      >
        <div className="module-body place-content-center bg-transparent grid">
          <p
            className={cn("selector-text mb-6 max-w-[21ch] text-center", { selected: isSelected })}
          >
            {message}
          </p>
          <img src={imageUrl} alt={message} className="mx-auto max-h-[6.75rem]" />
        </div>
        <div className="check">
          <FontAwesomeIcon icon={["far", "check"]} />
        </div>
      </button>
    </div>
  )
}

export { RemovePositionOptionCard }
