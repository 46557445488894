import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "usehooks-ts"

import { Candidate, Position } from "types/graphql"
import RootProvider from "v2/react/components/RootProvider"
import { CandidateBadge } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/CandidateBadge"
import { GridItemCard } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/GridItemCard"
import { Matrix } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/Matrix"
import { CandidateFormDropdown } from "v2/react/components/succession/SuccessionPlanPanel/Summary/CandidateFormDropdown"
import { generateMatrixLevels } from "v2/react/components/succession/SuccessionPlanPanel/utils/successionUtils"
import { Toggle, ToggleButton, ToggleLabel, ToggleSwitch } from "v2/react/shared/forms/Toggle"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"
import { prepareIconClass } from "v2/react/utils/misc"
import { useGetSuccessionMatrixSettingsQuery } from "v2/redux/GraphqlApi"
import {
  AxisModeSchema,
  CardTypeSchema,
  GridItemSchema,
  PaletteSchema,
  setAxisMode,
  setCardType,
  setGridItems,
  setPalette,
} from "v2/redux/slices/MatrixSlice"
import { useAppDispatch } from "v2/redux/store"

interface MatrixTabProps {
  candidates: Candidate[]
  position: Position
}

function WithProvider({ candidates, position }: MatrixTabProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { data: matrix } = useGetSuccessionMatrixSettingsQuery({})
  const is1016orAbove = useMediaQuery("(min-width: 1016px)")
  const [hideEmptyGridItems, setHideEmptyGridItems] = useState(false)

  useEffect(() => {
    if (matrix) {
      dispatch(setCardType(CardTypeSchema.parse(matrix.cardType)))
      dispatch(setPalette(PaletteSchema.parse(matrix.paletteType)))
      dispatch(setGridItems(GridItemSchema.array().parse(matrix.gridItems)))
      dispatch(setAxisMode(AxisModeSchema.parse(matrix.axisMode)))
    }
  }, [matrix, dispatch])

  if (!matrix) return null

  const gridItems = GridItemSchema.array().parse(matrix.gridItems)

  const unpositionedCandidates = candidates.filter(
    (c) => !c.person || !c.performanceLevel || !c.potentialLevel,
  )

  return (
    <div>
      {!is1016orAbove && (
        <Toggle
          id="hide-empty-grid-items"
          initialValue={hideEmptyGridItems}
          onToggle={() => setHideEmptyGridItems(!hideEmptyGridItems)}
        >
          <ToggleButton classes="flex items-center gap-2 mb-4">
            <ToggleSwitch />
            <ToggleLabel
              classes="text-base-bold"
              disabledLabel={t("v2.succession_plan_panel.matrix.hide_empty_sections")}
              enabledLabel={t("v2.succession_plan_panel.matrix.hide_empty_sections")}
            />
          </ToggleButton>
        </Toggle>
      )}
      {unpositionedCandidates.length > 0 && (
        <div className="unpositioned group mb-6 flex-wrap gap-x-2.5 gap-y-2 flex max-[1016px]:gap-1">
          <p className="w-full">
            {t("v2.succession_plan_panel.matrix.no_performance_or_potential")}
          </p>
          {unpositionedCandidates.map((candidate) => (
            <CandidateBadge candidate={candidate} key={candidate.id} />
          ))}
        </div>
      )}
      {(!position.successionPlan ||
        !position.successionPlan?.candidates ||
        position.successionPlan.candidates.length === 0) && <EmptyState positionId={position.id} />}
      <Matrix
        axisMode={matrix.axisMode}
        matrixClassNames="max-[1016px]:max-h-fit max-h-[48rem] min-h-[42rem] no-axes max-[1016px]:flex max-[1016px]:flex-col"
      >
        {gridItems.map((gridItem, index) => (
          <GridItemCard
            key={gridItem.label}
            candidates={candidates}
            cardType={CardTypeSchema.parse(matrix.cardType)}
            gridItem={gridItem}
            hideIfEmpty={hideEmptyGridItems}
            itemIndex={index}
            performance={generateMatrixLevels(matrix.axisMode)[index].performance}
            potential={generateMatrixLevels(matrix.axisMode)[index].potential}
          />
        ))}
      </Matrix>
    </div>
  )
}

const MatrixTab = ({ candidates, position }: MatrixTabProps) => (
  <RootProvider>
    <WithProvider candidates={candidates} position={position} />
  </RootProvider>
)

export { MatrixTab }

interface EmptyStateProps {
  positionId: string
}

const EmptyState = ({ positionId }: EmptyStateProps) => {
  const { t } = useTranslation()
  return (
    <div className="p-10">
      <div className="flex-col items-center gap-4 flex">
        <BlueIcon icon={prepareIconClass("fas users-custom")} size="med-lg" />
        <div className="max-w-[30rem] text-center">
          <p className="text-base-bold">
            {t("v2.succession_plan_panel.summary.no_candidates_yet")}
          </p>
          <p>{t("v2.succession_plan_panel.summary.add_a_candidate_from")}</p>
        </div>
        <CandidateFormDropdown buttonType="primary" positionId={positionId} />
      </div>
    </div>
  )
}
