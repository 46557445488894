import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"

import { CSV_DOWNLOAD_REF } from "v2/react/components/headcountPlanning/Participant/ParticipantHeader"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { useGetParticipantDatasheetPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { withRootProvider } from "../RootProvider"
import { TimelineView } from "./shared/TimelineView"

interface Props {
  headcountPlanId: string
  participantId: string
}

function Content({ headcountPlanId, participantId }: Props) {
  const participantPage = useGetParticipantDatasheetPageQuery({ headcountPlanId, participantId })

  const headcountPlan = participantPage.data?.headcountPlan
  const participant = headcountPlan?.participant
  const person = participant?.person
  const { t } = useTranslation()
  const csvDownloadName = [
    "built",
    headcountPlan?.name?.replace(" ", ""),
    person?.name?.replace(" ", ""),
    t("v2.defaults.timeline"),
    dayjs(new Date()).format("YYYY-MM-DD"),
  ].join("-")

  if (participantPage.isLoading) return <Spinner />

  return (
    <TimelineView
      csvDownloadRef={CSV_DOWNLOAD_REF}
      csvDownloadName={csvDownloadName}
      headcountPlanId={headcountPlanId}
      participantId={participantId}
    />
  )
}

const ParticipantTimeline = withRootProvider(Content, "ParticipantTimeline")

export { ParticipantTimeline }
