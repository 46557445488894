import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"

interface EditDeleteMenuProps {
  buttonClasses: string
  deleteButtonText: string
  editButtonText: string
  onDelete: () => void
  onEdit: () => void
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>
  showMenu: boolean
}

function EditDeleteMenu({
  buttonClasses,
  deleteButtonText,
  editButtonText,
  onDelete,
  onEdit,
  setShowMenu,
  showMenu,
}: EditDeleteMenuProps) {
  const { context, floatingStyles, getFloatingProps, refs } = useSelectList({
    showList: showMenu,
    setShowList: setShowMenu,
    floatOverrides: { placement: "bottom-start" },
    offset: 2,
  })

  return (
    <div className="dropdown--react nav-overflow-menu">
      <button
        className={buttonClasses}
        type="button"
        onClick={() => setShowMenu((curr) => !curr)}
        ref={refs.setReference}
      >
        <FontAwesomeIcon icon={["far", "ellipsis"]} />
      </button>
      <DropdownMenu
        context={context}
        showList={showMenu}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="dropdown-menu !block !w-fit right-0 p-2 z-5 mt-2"
      >
        <button
          className="dropdown-menu-link items-center gap-1.5 flex"
          onClick={onEdit}
          type="button"
        >
          <FontAwesomeIcon icon={["far", "pen"]} />
          <p>{editButtonText}</p>
        </button>
        <hr className="my-2" />
        <button
          className="dropdown-menu-link items-center gap-1.5 text-status-critical flex"
          onClick={onDelete}
          type="button"
        >
          <FontAwesomeIcon icon={["far", "trash-alt"]} />
          <p>{deleteButtonText}</p>
        </button>
      </DropdownMenu>
    </div>
  )
}

export { EditDeleteMenu }
