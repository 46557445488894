import React from "react"
import { useTranslation } from "react-i18next"

import { Notice } from "v2/react/shared/status/Notice"

const GenericError = ({ message }: { message?: string | undefined }) => {
  const { t } = useTranslation()

  return (
    <div className="react-modal__body">
      <Notice type="critical" icon={["far", "circle-exclamation"]}>
        {message ||
          t("v2.job_requisitions.modals.send_to_ats.smart_recruiters.errors.generic_fallback")}
      </Notice>
    </div>
  )
}

export { GenericError }
