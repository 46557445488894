import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"

interface SmallSelectProps<T> {
  options: { id: T; label: string }[]
  selectedId: T
  onClick: (id: T) => void
}

const SmallSelect = <T extends React.Key>({
  options,
  selectedId,
  onClick,
}: SmallSelectProps<T>) => {
  const selectedOption = options.find((option) => option.id === selectedId)

  return (
    <Dropdown>
      <div className="select--xs">
        <Dropdown.Trigger>
          <span>{selectedOption?.label}</span>
        </Dropdown.Trigger>
      </div>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item
            className="justify-between flex"
            key={option.id}
            onClick={() => onClick(option.id)}
            useActiveStyles
          >
            {option.label}
            {selectedId === option.id && (
              <FontAwesomeIcon icon={["fas", "circle-check"]} className="text-neutral-100" />
            )}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { SmallSelect }
