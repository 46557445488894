import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { PositionsDestroyPositionInput } from "types/graphql"
import { DestroyPositionMode } from "types/graphql.enums"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import { ErrorNotice } from "v2/react/shared/status/ErrorNotice"
import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"
import { pjaxReload } from "v2/react/utils/pjax"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { UrlHelper } from "v2/react/utils/urls"
import { useDestroyPositionMutation } from "v2/redux/GraphqlApi/PositionsApi"
import { closeProfilePlanSidebar } from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { Confirm } from "./Confirm"
import { Subordinates } from "./Subordinates"

interface RemovePositionModalProps {
  editingDisabled: boolean
  hasChildren: boolean
  isHeadcountPlanner: boolean
  isOfficialChart: boolean
  isOpen: boolean
  onClose: () => void
  pjaxReloadContainer?: string
  pjaxReloadUrl?: string
  positionId: string
}

const RemovePositionModal = ({
  hasChildren,
  isOpen,
  onClose,
  pjaxReloadContainer,
  pjaxReloadUrl,
  positionId,
  editingDisabled = false,
  isHeadcountPlanner = false,
  isOfficialChart = false,
}: RemovePositionModalProps) => {
  const [selectedRemoveOption, setSelectedRemoveOption] = useState<DestroyPositionMode | null>(
    hasChildren ? DestroyPositionMode.MoveChildrenUp : null,
  )
  const [destroyPosition] = useDestroyPositionMutation()
  const [permanentlyDelete, setPermanentlyDelete] = useState(false)
  const [showChildrenOptions, setShowChildrenOptions] = useState(hasChildren)
  const [isError, setIsError] = useState(false)
  const hasPositionManagement = useAppSelector(
    (state) => state.session.featureFlags?.positionManagement,
  )
  const hasSuccessionPlanning = useAppSelector(
    (state) => state.session.featureFlags?.successionPlanning,
  )
  const currentPersonId = useAppSelector((state) => state.session.currentPersonId)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const hasAccessToJobDefOrPositionsPages = hasPositionManagement || hasSuccessionPlanning

  const togglePermanentlyDelete = () => {
    setPermanentlyDelete(!permanentlyDelete)
  }

  const handleSubmit = async () => {
    if (hasChildren && showChildrenOptions) {
      setShowChildrenOptions(false)
      return
    }

    const input: PositionsDestroyPositionInput = {
      mode: selectedRemoveOption || DestroyPositionMode.NoChildren,
      positionId,
      permanent: permanentlyDelete,
    }
    await destroyPosition(input)
      .unwrap()
      .then((result) => {
        if (result.destroyPosition.success) {
          dispatch(closeProfilePlanSidebar())

          if (pjaxReloadUrl && pjaxReloadContainer) {
            pjaxReload({
              url: pjaxReloadUrl,
              container: pjaxReloadContainer,
            })
          } else if (hasAccessToJobDefOrPositionsPages && result.destroyPosition.positionTypeId) {
            window.location.href = UrlHelper.positionTypePath(result.destroyPosition.positionTypeId)
          } else if (hasAccessToJobDefOrPositionsPages) {
            window.location.href = UrlHelper.positionsPath()
          } else if (currentPersonId) {
            window.location.href = UrlHelper.personProfilePath(
              idFromUniqueKey(currentPersonId.toString()),
            )
          }
        } else {
          setIsError(true)
        }
      })
      .catch(() => setIsError(true))
  }

  const shouldPreventSubmit = (): boolean => {
    if (hasChildren && showChildrenOptions) {
      return selectedRemoveOption === null
    }

    return isHeadcountPlanner || editingDisabled
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("v2.profile_panel.remove_position")}
      size="md"
      footer={
        <ModalFooter
          disabled={shouldPreventSubmit()}
          onClose={onClose}
          onSave={handleSubmit}
          saveButtonText={
            permanentlyDelete
              ? t("v2.profile_panel.permanently_delete")
              : t("v2.profile_panel.remove_position")
          }
          useCriticalButton
        />
      }
    >
      {isError && <ErrorNotice wrapperClassName="pt-6 px-6" />}
      <AnimatePresence>
        {showChildrenOptions ? (
          <motion.div variants={opaqueBasic} initial="hidden" animate="shown" exit="hidden">
            <Subordinates
              onSelect={setSelectedRemoveOption}
              selectedRemoveOption={selectedRemoveOption}
            />
          </motion.div>
        ) : (
          <motion.div variants={opaqueBasic} initial="shown" animate="shown" exit="hidden">
            <Confirm
              isApprovalPending={editingDisabled}
              isHeadcountPlanner={isHeadcountPlanner}
              isOfficialChart={isOfficialChart}
              togglePermanentlyDelete={togglePermanentlyDelete}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Modal>
  )
}

export { RemovePositionModal }
