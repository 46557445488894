import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { MouseEvent, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { prepareIconClass } from "v2/react/utils/misc"

interface PanelIconTooltipProps {
  handleOpenProfile: (event: React.MouseEvent) => void
}

const PanelIconTooltip = ({ handleOpenProfile }: PanelIconTooltipProps) => {
  const { t } = useTranslation()
  const [showTooltip, setShowTooltip] = useState(false)
  const triggerRef = useRef<HTMLDivElement>(null)

  const iconClass = prepareIconClass("fas sidebar-flip-custom")

  const handleOpen = (event: MouseEvent) => {
    setShowTooltip(false)
    handleOpenProfile(event)
  }

  useEffect(() => {
    if (!triggerRef.current) {
      setShowTooltip(false)
    }
  }, [triggerRef])

  return (
    <Tooltip placement="bottom-start" open={showTooltip}>
      <TooltipTrigger
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div className="no-print invisible ml-1 mt-1 group-hover:visible" ref={triggerRef}>
          <button className="bg-transparent text-neutral-80" type="button" onClick={handleOpen}>
            <FontAwesomeIcon icon={iconClass} />
          </button>
        </div>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm">
        <span>{t("v2.succession.info_panel")}</span>
      </TooltipContent>
    </Tooltip>
  )
}

export { PanelIconTooltip }
