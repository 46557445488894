import React from "react"

import { RequisitionImportLink } from "v2/react/components/jobRequisitions/RequisitionImportLink"
import RootProvider from "v2/react/components/RootProvider"
import { useActiveView } from "v2/react/hooks/useActiveView"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlock } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { Link, LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlock } from "v2/react/shared/navigation/PageNav/TitleBlock"

type View = "reqs" | "jobs" | "settings"

interface Props {
  links?: Link<View>[]
  title: string
  importSource: string
  importPath: string
  isImporting: boolean
}

function RecruitingPageNav({ title, links = [], importSource, importPath, isImporting }: Props) {
  const initialView = links.find((link) => link.active)?.id || "reqs"
  const { activeView: currentView, handleLinkClick } = useActiveView<View>({
    initialView,
  })
  const isReqs = currentView === "reqs"
  const canImport = isReqs && importSource && importPath

  return (
    <RootProvider>
      <PageNav>
        <TitleBlock>
          <h1>{title}</h1>
        </TitleBlock>
        <LinkBlock>
          <LinkGroup links={links} usePjax onClick={handleLinkClick} />
        </LinkBlock>
        {canImport && (
          <ActionBlock>
            <RequisitionImportLink importPath={importPath} isImporting={isImporting} />
          </ActionBlock>
        )}
      </PageNav>
    </RootProvider>
  )
}

export { RecruitingPageNav }
