import fp from "lodash/fp"
import React, { RefObject, useCallback, useMemo } from "react"
import { bindActionCreators } from "redux"
import { useEventListener } from "usehooks-ts"

import {
  ArrowKeys,
  makeMovementMatchHandler,
  TransitionKeys,
} from "v2/react/components/orgChart/OrgChartDatasheet/hooks/cursorKeyMovements"
import { useCustomEventListener } from "v2/react/hooks/useCustomEventListener"
import { selectCursor } from "v2/redux/slices/DatasheetSlice/cursor/cursorSelectors"
import {
  inEitherReadOnEditable,
  inEitherWriteState,
  onEditableCell,
  onEditableCellTransitionNext,
  onNonEditableCell,
  onNothing,
} from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import type { CellCursor } from "v2/redux/slices/DatasheetSlice/cursor/types"
import { beginWriteWithCursor, moveCursor } from "v2/redux/slices/GridSlice/cursor/cursorActions"
import { cursorEventTarget } from "v2/redux/slices/GridSlice/cursor/cursorEvents"
import type { CursorEventDetail } from "v2/redux/slices/GridSlice/cursor/types"
import { selectCursorIndices } from "v2/redux/slices/GridSlice/gridSelectors"
import { useAppDispatch, useAppSelector, type AppDispatch } from "v2/redux/store"

export type HookArg = {
  /**
   * UI element which should always be in the viewport to handle cursor
   * keyboard events.
   */
  beaconRef: RefObject<HTMLButtonElement>
  /**
   * Helps keep the cursor in view and limit the effects of keyboard listeners
   * by helping them identify keyboard events they should ignore.
   */
  sheetRef?: RefObject<{
    contains: (element: Node | null) => boolean
    scrollToCell: (c: { rowIndex: number; columnIndex: number }) => void
  }>
}

interface HookState extends HookArg {
  cursor: CellCursor
  inFollowUp: boolean
  inEditMode: boolean

  columnIndex?: number
  rowIndex?: number
}

/**
 * Listens to a variety of events in order to control the cell cursor. Manages
 * both moving the cursor, and transitioning the cursor into a "write" state.
 *
 * This hook is meant to be used at the top-level of the datasheet. For
 * individual cells see `useCellCursorConnection`.
 */
export function useDatasheetCellCursor(arg: HookArg) {
  const [rowIndex, columnIndex] = useAppSelector(selectCursorIndices)
  const hookState = {
    ...arg,
    columnIndex,
    cursor: useAppSelector(selectCursor),
    inEditMode: useAppSelector((state) => state.visualization.editMode),
    inFollowUp: useAppSelector((state) => state.grid.followUpModal.isOpen),
    rowIndex,
  }

  useCellCursorStateChangeToManageFocusAndRestoreScroll(hookState)
  useCellCursorTargetChangeListenerToRestoreFocus(hookState)
  useCellCursorTargetChangeListenerToScrollIntoView(hookState)
  useKeyDownListenerToPreventUnwantedDefaultBehavior(hookState)
  useKeyUpListenerToBeginWriteWithNewValue(hookState)
  useKeyUpListenerToControlCursor(hookState)
  useKeyDownListenerWhileWritingToScrollBackToInput(hookState)

  return hookState
}

/**
 * Prevents default behaviors of certain keydown events.
 *
 * While editing an input, a keyup event will not fire for the "Tab" key by
 * default. When not editing an input, "Tab" messes with the scroll position.
 * Squelching the event during a keydown remedies both issues.
 *
 * When not editing an input, an arrow key causes the sheet to scroll. This
 * squelches that as the arrow key now controls the cell cursor.
 */
function useKeyDownListenerToPreventUnwantedDefaultBehavior({
  cursor,
  inEditMode,
  inFollowUp,
  sheetRef,
}: HookState) {
  const listener = useCallback(
    (reactOrNativeEvent: KeyboardEvent | React.KeyboardEvent) => {
      if (activeElementNotInSheet(sheetRef)) return

      const event = unwrapEvent(reactOrNativeEvent)
      if (!inEditMode || inFollowUp || matchModifierKey(event)) return

      // Ensure Enter + Tab behave appropriately.
      if (TransitionKeys.matchEvent(event)) squelchEvent(event)

      // When not writing to a cell, prevent default arrow key behavior to
      // prevent unwanted scrolling.
      if (!inEitherWriteState(cursor) && ArrowKeys.matchEvent(event)) squelchEvent(event)
    },
    [cursor, inEditMode, inFollowUp, sheetRef],
  )

  useEventListener("keydown", listener)
}

/**
 * When on an editable cell, transitions into a write state. If the cell
 * supports it, this will replace the current value with the character of the
 * key pressed. Skips the event if it fires for a non-printable character.
 */
function useKeyUpListenerToBeginWriteWithNewValue({
  beaconRef,
  cursor,
  inEditMode,
  inFollowUp,
  sheetRef,
}: HookState) {
  const { beginWriteWithCursor } = useCursorActions(useAppDispatch())
  const listener = useCallback(
    (reactOrNativeEvent: KeyboardEvent | React.KeyboardEvent) => {
      const event = unwrapEvent(reactOrNativeEvent)
      if (activeElementNotInSheet(sheetRef)) return
      if (!inEditMode || inFollowUp || matchModifierKey(event)) return
      if (!inEitherReadOnEditable(cursor)) return
      // These keys must be handled elsewhere...return if they're detected.
      if (TransitionKeys.matchEvent(event) || ArrowKeys.matchEvent(event)) return

      // Bail if given any control/modifier key. See the unicode categories
      // these map to here: https://www.compart.com/en/unicode/category
      if (!event.key.match(/^[^\p{Cc}\p{Cf}\p{Zp}\p{Zl}]{1,2}$/u)) return

      // If here, the user has started typing while focused on an editable
      // cell. Transition the cursor state using the first character as the
      // initial input.
      event.preventDefault()
      beginWriteWithCursor({ clobber: { withValue: `${event.key}` } })
    },
    [beginWriteWithCursor, cursor, inEditMode, inFollowUp, sheetRef],
  )

  useEventListener("keyup", listener, beaconRef)
}

/**
 * This helps work around an edge case where a cell's input may be unmounted.
 * The situation arises if the user scrolls down far enough that the cell
 * component is unmounted by the virtualized container. By reacting to a
 * keydown, this helps to get the cell mounted again *and* puts it back into
 * the view.
 */
function useKeyDownListenerWhileWritingToScrollBackToInput({
  cursor,
  columnIndex,
  rowIndex,
  sheetRef,
}: HookState) {
  const listener = useCallback(
    (reactOrNativeEvent: React.KeyboardEvent | KeyboardEvent) => {
      // Avoid hooking into an event that's not our own.
      const notBody = document.activeElement !== document.querySelector("body")
      if (activeElementNotInSheet(sheetRef) && notBody) return
      if (!inEitherWriteState(cursor)) return

      // If this was an arrow key, stop propagation to avoid awkward scroll.
      const event = unwrapEvent(reactOrNativeEvent)
      if (ArrowKeys.matchEvent(event)) event.stopPropagation()

      // If this was a transition key, prevent default to prevent focus from
      // escaping the spreadsheet.
      if (TransitionKeys.matchEvent(event)) event.preventDefault()

      performScrollToCell(sheetRef, rowIndex, columnIndex)
    },
    [columnIndex, cursor, rowIndex, sheetRef],
  )

  useEventListener("keydown", listener)
}

/**
 * Handles moving the cursor, or when on an editable cell, possibly
 * transitioning to the "writingOnEditable" state.
 */
function useKeyUpListenerToControlCursor({
  beaconRef,
  cursor,
  inEditMode,
  inFollowUp,
  sheetRef,
}: HookState) {
  const { beginWriteWithCursor, moveCursor } = useCursorActions(useAppDispatch())
  const listener = useCallback(
    (reactOrNativeEvent: KeyboardEvent | React.KeyboardEvent) => {
      const event = unwrapEvent(reactOrNativeEvent)
      if (activeElementNotInSheet(sheetRef)) return
      if (!inEditMode || inFollowUp || matchModifierKey(event)) return

      // Control is relinquished to the cell that is undergoing changes. If
      // writing, or if we're on nothing, return.
      if (onNothing(cursor) || inEitherWriteState(cursor)) return

      const applyMovementIfMatchFoundIn = makeMovementMatchHandler(event, ({ direction }) =>
        moveCursor({ direction }),
      )

      event.preventDefault()
      if (onNonEditableCell(cursor)) applyMovementIfMatchFoundIn(ArrowKeys, TransitionKeys)
      else if (onEditableCell(cursor) && TransitionKeys.matchEvent(event)) beginWriteWithCursor({})
      else if (onEditableCellTransitionNext(cursor))
        applyMovementIfMatchFoundIn(ArrowKeys, TransitionKeys)
      else if (onEditableCell(cursor)) applyMovementIfMatchFoundIn(ArrowKeys)
    },
    [beginWriteWithCursor, cursor, inEditMode, inFollowUp, moveCursor, sheetRef],
  )

  useEventListener("keyup", listener, beaconRef)
}

/**
 * Either focuses or blurs the beacon, if necessary, when the cursor
 * transitions into a new state.
 */
const useCellCursorStateChangeToManageFocusAndRestoreScroll = ({
  beaconRef,
  sheetRef,
}: HookState) => {
  const listener = useCallback(
    ({ detail: { cursor, cursorIndices } }: CustomEvent<CursorEventDetail>) => {
      const { current: beacon } = beaconRef
      if (!beacon || onNothing(cursor)) return

      if (inEitherWriteState(cursor) && beacon === document.activeElement) beacon.blur()
      else if (!inEitherWriteState(cursor) && beacon !== document.activeElement) beacon.focus()

      performScrollToCell(sheetRef, ...cursorIndices)
    },
    [beaconRef, sheetRef],
  )

  useCustomEventListener(cursorEventTarget, "cellCursorStateChange", listener)
}

/**
 * Scrolls the cursor into view if it moves on to a new cell. Assumes
 * sheetRef's `scrollToCell` function acts like a no-op if it is already in
 * view.
 */
function useCellCursorTargetChangeListenerToScrollIntoView({ sheetRef }: HookState) {
  const listener = useCallback(
    ({ detail: { cursorIndices } }: CustomEvent<CursorEventDetail>) => {
      performScrollToCell(sheetRef, ...cursorIndices)
    },
    [sheetRef],
  )

  useCustomEventListener(cursorEventTarget, "cellCursorTargetChange", listener)
}

/**
 * Focuses the beaconRef if it's not in focus, the cursor target changed, and
 * the cursor is in an editable state.
 *
 * This addresses an edge case where a user can click outside of the
 * spreadsheet and then subsequently click into a new cell. A state change is
 * unlikely, but a change in the underlying target will happen.
 */
function useCellCursorTargetChangeListenerToRestoreFocus({ beaconRef }: HookState) {
  const listener = useCallback(
    ({ detail: { cursor } }: CustomEvent<CursorEventDetail>) => {
      if (inEitherReadOnEditable(cursor) && beaconRef.current !== document.activeElement)
        beaconRef.current?.focus()
    },
    [beaconRef],
  )

  useCustomEventListener(cursorEventTarget, "cellCursorTargetChange", listener)
}

/**
 * Checks if the active element, the one with focus, is within the
 * spreadsheet's body. This simplifies determining whether a keyboard event is
 * relevant enough to take action on.
 */
const activeElementNotInSheet = (sheetRef?: HookArg["sheetRef"]) =>
  !sheetRef?.current?.contains?.(document.activeElement)

const performScrollToCell = (
  sheetRef: HookArg["sheetRef"],
  ...cursorIndices: (number | undefined)[]
) => {
  const [rowIndex, columnIndex] = cursorIndices
  if (!sheetRef || !sheetRef.current) return
  if (fp.isUndefined(rowIndex) || fp.isUndefined(columnIndex)) return

  sheetRef.current.scrollToCell({ rowIndex, columnIndex })
}

const unwrapEvent = (event: KeyboardEvent | React.KeyboardEvent) =>
  "nativeEvent" in event ? event.nativeEvent : event

const matchModifierKey = ({ metaKey, altKey, ctrlKey }: KeyboardEvent) =>
  metaKey || altKey || ctrlKey

const useCursorActions = (dispatch: AppDispatch) =>
  useMemo(() => bindActionCreators({ beginWriteWithCursor, moveCursor }, dispatch), [dispatch])

const squelchEvent = (event: Event) => {
  event.preventDefault()
  event.stopPropagation()
}
