import React, { useEffect } from "react"

interface TimerButtonProps {
  path: string
}

const TimerButton = ({ path }: TimerButtonProps) => {
  usePjaxForTimerButton(path)

  // Setting data-pjax-render here causes issues with SSR.  We set the path
  // attribute instead with data-pjax-render-path so that this button can be
  // found and adjusted when submitting timesheets.
  return <div data-pjax-container="timer-button" data-pjax-render-path={path} />
}

const usePjaxForTimerButton = (path: TimerButtonProps["path"]) => {
  useEffect(() => {
    if (!path || typeof window === "undefined") return undefined

    // Fire off pjax directly after first mount
    const pjax = window?.$?.pjax
    if (pjax) {
      // Echoes `#render` in webpack/v2/pjax.js
      const options = {
        url: path,
        container: "[data-pjax-container=timer-button]",
        push: false,
      }

      pjax(options)
      pjax.xhr = null
    }

    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export { TimerButton }
