import { isEmpty, isNil } from "lodash"

/**
 * Indicates whether a value is `null`, `undefined`, `false`, a string without
 * non-whitespace characters, an empty array, or an object without any of its
 * own enumerable properties.
 */
function isBlank(value: unknown): boolean {
  if (isNil(value)) return true
  if (typeof value === "boolean") return value === false
  if (typeof value === "string") return value.trim() === ""
  if (typeof value === "function") return false
  if (typeof value === "object" || Array.isArray(value)) return isEmpty(value)

  return false
}

export { isBlank }
