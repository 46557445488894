import React from "react"
import { useTranslation } from "react-i18next"

import { ColumnType } from "v2/react/components/headcountPlanning/Overview/ParticipantTable/ProgressColumns/Header"
import { ProgressGroup } from "v2/react/shared/data/ProgressGroup"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface CellProps {
  columnType: ColumnType
  actual: number
  planned: number
  planStarted: boolean
  showPlannedOnly?: boolean
}

const Cell = ({ columnType, actual, planned, planStarted, showPlannedOnly }: CellProps) => {
  const percentage = (actual / planned) * 100

  return (
    <td className="w-[16rem] min-w-[11rem]">
      {showPlannedOnly ? (
        <div className="px-4">{planned}</div>
      ) : (
        <Tooltip placement="bottom">
          <TooltipTrigger>
            <ProgressGroup
              actual={actual}
              planned={planned}
              percentage={percentage}
              dataAvailable={planStarted}
            />
          </TooltipTrigger>
          {planStarted && (
            <CellTooltipContent columnType={columnType} actual={actual} planned={planned} />
          )}
        </Tooltip>
      )}
    </td>
  )
}

const CellTooltipContent = ({
  actual,
  planned,
  columnType,
}: Pick<CellProps, "actual" | "planned" | "columnType">) => {
  const { t } = useTranslation()
  const variance = actual - planned

  return (
    <TooltipContent className="react-tooltip-content react-tooltip-content--light min-w-[184px] flex-col gap-0 p-0 text-sm flex">
      <div className="p-3">
        <span className="text-sm-bold">
          {t(`v2.headcount_plan.overview.columns.${columnType}`)}
        </span>
      </div>
      <hr className="my-0 w-full" />
      <div className="flex-col gap-2 p-3 flex">
        <div className="flex-row justify-between gap-2 flex">
          <span className="text-sm-bold">{t("v2.headcount_plan.overview.planned")}</span>
          <span>{planned}</span>
        </div>
        <div className="flex-row justify-between gap-2 flex">
          <span className="text-sm-bold">{t("v2.headcount_plan.overview.actual")}</span>
          <span>{actual}</span>
        </div>
      </div>
      <hr className="my-0 w-full" />
      <div className="p-3">
        <div className="flex-row justify-between gap-2 flex">
          <span className="text-sm-bold">{t("v2.headcount_plan.overview.variance")}</span>
          <span className="text-sm-bold">{variance}</span>
        </div>
      </div>
    </TooltipContent>
  )
}

export { Cell }
