import { RefObject, useEffect } from "react"

interface useTriggerProps<T> {
  isOpen: boolean
  hideElement: () => void
  triggerRef: RefObject<T>
}

/**
 * A custom hook that monitors if a trigger element is visible in the viewport.
 * When the trigger element scrolls out of view, it will automatically trigger the hideElement callback.
 * This is particularly useful for dropdowns, tooltips or any floating elements that should close
 * when their trigger is no longer visible.
 * @param isOpen boolean - Current state of the item
 * @param hideElement - Callback function to hide/close the element
 * @param triggerRef React ref object attached to the trigger element
 *
 * @example
 * ```tsx
 * const MyComponent = () => {
 *  const [isOpen, setIsOpen] = useState(false)
 *  const triggerRef = useRef<HTMLButtonElement>(null)
 *
 *  useTriggerVisibility({
 *    isOpen,
 *    hideItem: () => setIsOpen(false),
 *    triggerRef
 *  })
 *
 *  return (
 *    <button ref={triggerRef}>
 *      Trigger
 *      <Dropdown>
 *        Hide Me
 *      </Dropdown>
 *    <button>
 *  )
 * }
 * ```
 */
const useTriggerVisibility = <T extends HTMLElement>({
  isOpen,
  hideElement,
  triggerRef,
}: useTriggerProps<T>) => {
  useEffect(() => {
    if (!isOpen || !triggerRef || !triggerRef.current) return undefined

    const cachedRef = triggerRef.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) hideElement()
      },
      {
        threshold: 0,
        root: null,
      },
    )

    observer.observe(cachedRef)

    return () => {
      observer.unobserve(cachedRef)
    }
  }, [hideElement, isOpen, triggerRef])
}

export { useTriggerVisibility }
