const pjax = ({
  url,
  container,
  fragment = false,
  replace = false,
  push = false,
}: {
  url: string
  container: string
  fragment?: string | boolean
  replace?: boolean
  push?: boolean
}) => {
  window?.$?.pjax({
    url,
    container,
    fragment,
    replace,
    push,
  })
}

const pjaxModalFor = (url: string) => {
  window?.$?.pjax({
    url,
    container: "[data-pjax-container=modal]",
    push: false,
  })
}

const pjaxReload = ({
  url,
  container,
  fragment = false,
  replace = false,
}: {
  url?: string
  container: string
  fragment?: string | boolean
  replace?: boolean
}) => {
  window?.$?.pjax.reload({
    url,
    container,
    fragment,
    replace,
    push: false,
  })
}

const pjaxSubmit = ({
  event,
  url,
  container,
  fragment = false,
  replace = false,
}: {
  event: React.FormEvent<HTMLFormElement>
  url?: string
  container: string
  fragment?: string | boolean
  replace?: boolean
}) => {
  window?.$?.pjax.submit(event, {
    url,
    container,
    fragment,
    replace,
    push: false,
  })
}

export { pjaxModalFor, pjaxReload, pjaxSubmit, pjax }
