/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames"
import React, { MutableRefObject } from "react"

import { CompareValues } from "./CompareValues"
import { useDatasheetContext } from "./context"
import { DropdownMenu } from "./DropdownMenu"
import { useAutocompleteList } from "./hooks/useAutocompleteList"
import { useCellHandlers } from "./hooks/useCellHandlers"
import { useCellState } from "./hooks/useCellState"
import { EditingCellHandle } from "./hooks/useEditingCellRegistry"
import { useOptimisticValue } from "./hooks/useOptimisticValue"
import { StyleLayers } from "./StyleLayers"
import { CellInput, FieldType, SaveFn } from "./types"

type SuggestedAutocompleteOption = {
  id: string
  label?: string
}

type OptionInfo = {
  label: string
  createdBy: "fallbackClick" | "keydown"
}

function defaultGetLabel<T extends SuggestedAutocompleteOption>(option: T | null | undefined) {
  return option?.label ?? ""
}

function defaultCreateOption<T extends SuggestedAutocompleteOption>({ label }: OptionInfo): T {
  return { id: "custom", label } as T
}

export type FallbackOptionProps = {
  saveCustom: () => void
}

type Props<T> = {
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  renderOption: (option: T, isActive: boolean) => React.ReactNode
  disableOption?: (option: T) => boolean
  options: Array<T>
  currentValue: T
  compareValue?: T | null
  saveFn: SaveFn<T>
  onSaved?: () => void
  onErrored?: () => void
  onEditing?: () => void
  rowId: string
  columnId: string
  createOption?: (optionInfo: OptionInfo) => T
  editable?: boolean
  className?: string
  style?: React.CSSProperties
  hasNotChanged?: (value: T, oldValue: T | undefined) => boolean
  renderFallbackOption?: (props: FallbackOptionProps) => React.ReactNode
  getLabel?: (option: T | null | undefined) => string
  cellInputRef?: React.Ref<CellInput>
}
export function SuggestedAutocompleteCell<T extends SuggestedAutocompleteOption>({
  searchTerm,
  setSearchTerm,
  renderOption,
  disableOption,
  options,
  saveFn,
  onSaved,
  onErrored,
  onEditing,
  currentValue,
  compareValue,
  rowId,
  columnId,
  editable,
  className,
  style,
  renderFallbackOption,
  hasNotChanged,
  cellInputRef,
  createOption = defaultCreateOption,
  getLabel = defaultGetLabel,
}: Props<T>) {
  const [optimisticValue, setOptimisticValue] = useOptimisticValue(getLabel(currentValue))
  const initialValueRef = React.useRef<string | null>(null)
  const cellRef = React.useRef<HTMLDivElement>(null)
  const editingCellHandleRef = React.useRef<EditingCellHandle>(null)

  React.useImperativeHandle(
    editingCellHandleRef,
    (): EditingCellHandle => ({
      save: () => cell.dispatch({ type: "save", value: getSaveValue() }),
      contains: (target) =>
        cellRef.current?.contains(target as Node) === true ||
        autocompleteList.refs.floating.current?.contains(target as Node) === true,
    }),
  )

  React.useImperativeHandle(cellInputRef, () => ({
    getValue: () => searchTerm,
  }))

  const { registerEditingCell, unregisterEditingCell } = useDatasheetContext()

  const hasNotChangedFn =
    hasNotChanged ?? ((value, oldValue) => getLabel(value) === getLabel(oldValue))

  const cell = useCellState<T>({
    currentValue,
    fieldType: FieldType.SuggestedAutocomplete,
    rowId,
    columnId,
    editable,
    hasNotChanged: hasNotChangedFn,
    saveFn: (state) => saveFn(state.value),
    onEditing: (state) => {
      registerEditingCell(editingCellHandleRef)
      document.getElementById(`${columnId}-${rowId}-input`)?.focus()
      if (typeof state.initial === "string") {
        initialValueRef.current = state.initial
      }
      onEditing?.()
    },
    onSaving: () => {
      unregisterEditingCell()
    },
    onSaved: (state) => {
      const label = getLabel(state.value)
      setSearchTerm(label)
      if (state.value) {
        setOptimisticValue(label)
      }
      onSaved?.()
    },
    onErrored: () => onErrored?.(),
  })

  const autocompleteList = useAutocompleteList({
    showList: cell.isEditing,
    minWidthAdjustment: 34,
  })

  const inputRef = autocompleteList.refs.reference as MutableRefObject<HTMLInputElement>

  const getSaveValue = () => {
    const selectedOption = options[autocompleteList.activeIndex]
    if (selectedOption && !disableOption?.(selectedOption)) {
      return selectedOption
    }
    return createOption({ label: searchTerm, createdBy: "keydown" })
  }

  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, inputRef, {
    getSaveValue,
    moveDown: () =>
      autocompleteList.setActiveIndex((prev) =>
        prev === null ? 0 : Math.min(options.length - 1, prev + 1),
      ),
    moveUp: () =>
      autocompleteList.setActiveIndex((prev) => (prev === null ? 0 : Math.max(0, prev - 1))),
  })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      initialValueRef.current === null
        ? event.target.value
        : `${initialValueRef.current}${event.target.value.slice(-1)}`
    initialValueRef.current = null
    setSearchTerm(value)
  }

  const saveCustom = () =>
    cell.dispatch({
      type: "save",
      value: createOption({ label: searchTerm, createdBy: "fallbackClick" }),
    })

  const hideCompareValue =
    cell.isEditing ||
    cell.isSaving ||
    cell.isSaved ||
    cell.isErrored ||
    cell.isErroredEditing ||
    cell.isErroredSelected ||
    cell.isErroredSaving
  const showSearchTerm =
    cell.isEditing ||
    cell.isErroredSelected ||
    cell.isErroredEditing ||
    cell.isErrored ||
    cell.isFollowUp
  const showDropdownMenu =
    (cell.isEditing || cell.isErroredEditing) &&
    (options.length > 0 || renderFallbackOption !== undefined)
  const disableInput = !cell.isEditing && !cell.isErroredEditing
  const showStateValue = cell.isSaving || cell.isSaved || cell.isErrored || cell.isErroredSaving

  // eslint-disable-next-line no-nested-ternary
  const inputValue = showSearchTerm
    ? searchTerm
    : showStateValue
    ? getLabel(cell.value)
    : optimisticValue ?? getLabel(currentValue)

  const errored =
    cell.isErrored || cell.isErroredEditing || cell.isErroredSaving || cell.isErroredSelected

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      id={`${columnId}-${rowId}`}
      className={classNames(`editable-cell position-${columnId}-input`, className, {
        "editable-cell--errored": errored,
      })}
      onClick={handleCellClick}
      onKeyUp={handleCellKeyUp}
      style={style}
      ref={cellRef}
    >
      <CompareValues
        compareValue={hideCompareValue ? null : getLabel(compareValue)}
        style={{ maxWidth: "100%", width: "100%" }}
      >
        <input
          id={`${columnId}-${rowId}-input`}
          ref={autocompleteList.refs.setReference}
          {...autocompleteList.getReferenceProps()}
          className={classNames("-ml-0.5 h-full w-full border-none bg-white", {
            "pointer-events-none": disableInput,
          })}
          value={inputValue}
          onChange={handleInputChange}
          disabled={disableInput}
          name={columnId}
        />
      </CompareValues>
      <DropdownMenu
        showList={showDropdownMenu}
        floatingRef={autocompleteList.refs.setFloating}
        floatingStyles={{
          ...autocompleteList.floatingStyles,
          zIndex: 5,
          marginLeft: "-0.95rem",
        }}
        floatingProps={autocompleteList.getFloatingProps}
        wrapperClasses="autocomplete-container"
        context={autocompleteList.context}
      >
        <div className="list-group autocomplete-list">
          {options.map((option, index) => {
            const disabled = disableOption?.(option) ?? false

            return (
              <li
                role="option"
                className={textClassName(autocompleteList.activeIndex, index, disabled)}
                aria-selected={autocompleteList.activeIndex === index}
                key={option.id}
                ref={(node) => {
                  autocompleteList.listRef.current[index] = node
                }}
                {...autocompleteList.getItemProps({
                  onClick: () => {
                    if (!disabled) {
                      cell.dispatch({ type: "save", value: option })
                    }
                  },
                })}
              >
                {renderOption(option, autocompleteList.activeIndex === index)}
              </li>
            )
          })}
          {renderFallbackOption?.({ saveCustom })}
        </div>
      </DropdownMenu>
      <StyleLayers cell={cell} fieldType={FieldType.SuggestedAutocomplete} />
    </div>
  )
}

const textClassName = (activeIndex: number | null, index: number, disabled: boolean) =>
  classNames("list-group-item mb-0", { highlight: activeIndex === index, disabled })
