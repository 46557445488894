import { isEmpty, isEqual } from "lodash"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDebounce } from "usehooks-ts"

import { AUTOCOMPLETE_LIMIT } from "v2/react/constants"
import { useOrgChartSearchQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

interface Props {
  filter: string
  headcountPlanId: string
  participantId?: string
}

const useOrgChartSearch = ({ filter, headcountPlanId, participantId }: Props) => {
  const { t } = useTranslation()
  const [returnEmpty, setReturnEmpty] = useState(false)

  const searchTerm = useDebounce(filter || "", 350)

  const { data, isFetching } = useOrgChartSearchQuery(
    {
      searchTerm,
      headcountPlanId,
      participantId,
      first: AUTOCOMPLETE_LIMIT,
    },
    {
      skip: !searchTerm,
    },
  )

  const emptyItems = [
    {
      id: "everything",
      uniqueKey: "everything",
      title: t("v2.orgchart.search.everything"),
    },
    {
      id: "people",
      uniqueKey: "people",
      title: t("v2.orgchart.search.people"),
    },
    {
      id: "positions",
      uniqueKey: "positions",
      title: t("v2.orgchart.search.positions"),
    },
  ]

  const positions = data?.headcountPlan?.orgChartPositionNodesConnection?.nodes || emptyItems

  // Handles the case where the input changes from empty to non-empty, and we
  // need to return an empty list of options until the fresh data returns.
  if (isEmpty(filter) && !returnEmpty) {
    setReturnEmpty(true)
  } else if (!isEmpty(filter) && isEqual(filter, searchTerm) && !isFetching && returnEmpty) {
    setReturnEmpty(false)
  }
  return {
    positions: returnEmpty ? emptyItems : positions,
    returnEmpty,
    searchTerm,
  }
}

export { useOrgChartSearch }
