import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DateValue, parseDate } from "@internationalized/date"
import cn from "classnames"
import { indexBy } from "lodash/fp"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { EffectiveDateCellTooltip } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/effectiveDates/EffectiveDateCellTooltip"
import { EffectiveDateModal } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/effectiveDates/EffectiveDateModal"
import {
  EffectiveDate,
  getDistinctDates,
  getEffectiveDate,
  getEffectiveDatesHash,
  getEffectiveDatesList,
} from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/effectiveDates/helpers"
import { CompareValues } from "v2/react/components/headcountPlanning/TableDatasheet/CompareValues"
import { useCellHandlers } from "v2/react/components/headcountPlanning/TableDatasheet/hooks/useCellHandlers"
import { useCellState } from "v2/react/components/headcountPlanning/TableDatasheet/hooks/useCellState"
import { useFollowUpHandlers } from "v2/react/components/headcountPlanning/TableDatasheet/hooks/useFollowUpHandlers"
import { SaveLayer } from "v2/react/components/headcountPlanning/TableDatasheet/StyleLayers/SaveLayer"
import { FieldType } from "v2/react/components/headcountPlanning/TableDatasheet/types"
import { DatePicker } from "v2/react/shared/forms/DateInputs/DatePicker"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import {
  useAddChangeToHeadcountPlanMutation,
  useEliminateHeadcountPlanPositionMutation,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { setRowForEffectiveDateModal, updateCellState } from "v2/redux/slices/DatasheetSlice"
import { CellEvent } from "v2/redux/slices/DatasheetSlice/types"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { HeadcountPlanDatasheetRow } from "../types"

type Props = {
  endDate: string
  headcountPlanId: string
  readOnly: boolean
  row: HeadcountPlanDatasheetRow
  startDate: string
}

function EffectiveDatesCell({ endDate, headcountPlanId, readOnly, row, startDate }: Props) {
  const dispatch = useAppDispatch()
  const effectiveDates = getEffectiveDatesList(row.positionAttributesWithEdits)
  const distinctDates = getDistinctDates(effectiveDates)
  const rowForEffectiveDateModal = useAppSelector(
    (state) => state.datasheet.rowForEffectiveDateModal,
  )

  const cell = useCellState({
    currentValue: getEffectiveDatesHash(row.positionAttributesWithEdits),
    fieldType: FieldType.EffectiveDate,
    rowId: row.id,
    columnId: "effective_dates",
    editable: !readOnly,
  })
  const cellRef = React.createRef<HTMLDivElement>()

  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, null, {
    getSaveValue: () => getEffectiveDatesHash(row.positionAttributesWithEdits),
  })

  const errored =
    cell.isErrored || cell.isErroredEditing || cell.isErroredSaving || cell.isErroredSelected

  const followUpHandlersArg = {
    cell,
    shouldFollowUpBeforeCellExit: !!rowForEffectiveDateModal,
  }

  const { beginFollowUp, cancelFollowUp, completeFollowUp } =
    useFollowUpHandlers(followUpHandlersArg)

  const handleOpenModal = () => {
    beginFollowUp()
    dispatch(setRowForEffectiveDateModal(row))
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div
        role="button"
        className={cn("editable-cell group/cell", { "editable-cell--errored": errored })}
        ref={cellRef}
        id={`effective_dates-${row.id}`}
        onClick={handleCellClick}
        onKeyUp={handleCellKeyUp}
      >
        <Tooltip placement="top" closeDelay={150}>
          <TooltipTrigger
            className={cn({ "Cell--error": cell.errorMessage })}
            disabled={!cell.errorMessage}
          >
            <CompareValues>
              <div className="w-full items-center justify-between flex">
                {distinctDates.length === 0 && (
                  <NoEffectiveDates
                    effectiveDates={effectiveDates}
                    handleOpenModal={handleOpenModal}
                    readOnly={readOnly}
                  />
                )}
                {distinctDates.length === 1 && (
                  <SingularDate
                    distinctDates={distinctDates}
                    effectiveDates={effectiveDates}
                    handleOpenModal={handleOpenModal}
                    hasError={errored}
                    headcountPlanId={headcountPlanId}
                    id={row.id}
                    readOnly={readOnly}
                    row={row}
                  />
                )}
                {distinctDates.length > 1 && (
                  <MultipleDates
                    currentEffectiveDates={effectiveDates}
                    handleOpenModal={handleOpenModal}
                    readOnly={readOnly}
                  />
                )}
              </div>
            </CompareValues>
          </TooltipTrigger>
          {cell.errorMessage && (
            <TooltipContent className="react-tooltip-content react-tooltip-content-error">
              <FontAwesomeIcon icon={["far", "exclamation-circle"]} />
              {cell.errorMessage}
            </TooltipContent>
          )}
        </Tooltip>
        {cell.isSaved && <SaveLayer showStyle />}
      </div>
      {rowForEffectiveDateModal && rowForEffectiveDateModal.id === row.id && (
        <EffectiveDateModal
          endDate={endDate}
          headcountPlanId={headcountPlanId}
          onModalCancel={cancelFollowUp}
          onModalSave={completeFollowUp}
          row={rowForEffectiveDateModal}
          startDate={startDate}
        />
      )}
    </>
  )
}

export { EffectiveDatesCell }

interface NoEffectiveDatesProps {
  effectiveDates: EffectiveDate[]
  handleOpenModal: () => void
  readOnly: boolean
}

const NoEffectiveDates = ({ effectiveDates, handleOpenModal, readOnly }: NoEffectiveDatesProps) => (
  <EffectiveDateCellTooltip
    effectiveDates={effectiveDates}
    asHelperText={!getDistinctDates(effectiveDates).length}
    disableTooltip={readOnly}
  >
    <button
      type="button"
      className="h-10 w-full border-none bg-transparent p-0"
      onClick={handleOpenModal}
      disabled={readOnly}
    >
      <div className="group/badge-box ml-auto h-10 w-10 place-content-center border-none bg-transparent !p-0 grid">
        <div
          className={cn("badge--gray badge--sm !w-6 !px-2 opacity-0", {
            "group-hover/badge-box:!elevation--secondary group-hover/cell:!no-underline group-hover/cell:opacity-100":
              !readOnly,
          })}
        >
          <FontAwesomeIcon icon={["far", "plus"]} />
        </div>
      </div>
    </button>
  </EffectiveDateCellTooltip>
)

interface SingularDateProps {
  distinctDates: string[]
  effectiveDates: EffectiveDate[]
  handleOpenModal: () => void
  hasError: boolean
  headcountPlanId: string
  id: string
  readOnly: boolean
  row: HeadcountPlanDatasheetRow
}

const SingularDate = ({
  distinctDates,
  effectiveDates,
  handleOpenModal,
  hasError,
  headcountPlanId,
  id,
  readOnly,
  row,
}: SingularDateProps) => {
  const [currentValue, setCurrentValue] = useState("")
  const dispatch = useAppDispatch()
  const cellDispatch = React.useCallback(
    (event: Omit<CellEvent, "rowId" | "columnId">) => {
      if (!row) return
      dispatch(updateCellState({ rowId: row.id, columnId: "effective_dates", ...event }))
    },
    [dispatch, row],
  )
  const [saveEffectiveDates] = useAddChangeToHeadcountPlanMutation()
  const [saveEffectiveDatesWithEliminateInfo] = useEliminateHeadcountPlanPositionMutation()
  const currentEffectiveDate = distinctDates[0]

  useEffect(() => {
    // This helps make sure that currentValue stays up-to-date with the currentEffectiveDate value.
    setCurrentValue(currentEffectiveDate)
  }, [currentEffectiveDate])

  const handleChange = async (value: DateValue) => {
    setCurrentValue(value.toString())

    // When updating dates from the cell, we need to update both required and optional
    // dates that match the displayed value even if the only displayed value is an optional field
    const updatedDates = effectiveDates.map((ed: EffectiveDate) =>
      (ed.optional && distinctDates.length > 1) ||
      (ed.optional && distinctDates.length === 1 && ed.value !== distinctDates[0])
        ? ed
        : { ...ed, value: value.toString() },
    )

    cellDispatch({ type: "reset" })
    cellDispatch({ type: "save", value: updatedDates })

    if (positionEndDateEdited(updatedDates)) {
      const result = await saveEffectiveDatesWithEliminateInfo({
        eliminateDescendingPositions: false,
        headcountPlanId,
        headcountPlanParticipantId: row.participantId,
        payload: { ...row.payload, effective_dates: indexBy("id", updatedDates) },
        positionId: row.action === null ? row.position?.id : null,
        revisionNumber: row.action === null ? 0 : row.revisionNumber + 1,
        rootEventId: row.rootEventId,
      }).unwrap()

      if (result.eliminateHeadcountPlanPosition?.success) {
        cellDispatch({ type: "saveSuccess" })
        setTimeout(() => cellDispatch({ type: "reset" }), 800)
      } else {
        cellDispatch({
          type: "saveError",
          message: result.eliminateHeadcountPlanPosition.errors[0].message,
          value: updatedDates,
        })
      }

      return
    }

    const result = await saveEffectiveDates({
      action: "update_existing",
      headcountPlanId,
      headcountPlanParticipantId: row.participantId,
      payload: { ...row.payload, effective_dates: indexBy("id", updatedDates) },
      positionId: row.rootEventId ? null : row.position?.id,
      revisionNumber: row.revisionNumber + 1,
      rootEventId: row.rootEventId,
    }).unwrap()

    if (result.addChangeToHeadcountPlan.headcountPlanChangeCreated) {
      cellDispatch({ type: "saveSuccess" })
      setTimeout(() => cellDispatch({ type: "reset" }), 800)
    } else if (
      result.addChangeToHeadcountPlan.errors &&
      result.addChangeToHeadcountPlan.errors.length > 0
    ) {
      cellDispatch({
        type: "saveError",
        message: result.addChangeToHeadcountPlan.errors[0].message,
        value: updatedDates,
      })
    }
  }

  const positionEndDateEdited = (updatedDates: EffectiveDate[]) => {
    const previousEndDate = getEffectiveDate(row.positionAttributesWithEdits, "position_end_date")
    const currentEndDate = updatedDates.find((ed) => ed.id === "position_end_date")?.value

    return currentEndDate !== previousEndDate
  }

  // If the only dates with a value come from the actual backing record we need
  // to disable the calendar.
  //
  // When this is `true` we want to let the calendar dialog show, but with
  // everything effectively disabled/readonly. We also still want the selected
  // date to show appropriately. This achieves that by marking all dates
  // unavailable and flagging everything as readonly. Without marking the dates
  // as unavailable, the styling looks off.
  const isCalendarDisabled = effectiveDates
    .filter(({ value }) => value)
    .every(({ isValueFromActual }) => isValueFromActual)
  const isDateUnavailable = useCallback(() => isCalendarDisabled, [isCalendarDisabled])

  return (
    <>
      <EffectiveDateCellTooltip effectiveDates={effectiveDates} disableTooltip={hasError}>
        <DatePicker
          dialogClassName="-ml-4 mt-3"
          dialogInPortal
          id={`effective_date_${id}`}
          isDateUnavailable={isDateUnavailable}
          isDisabled={readOnly}
          isReadOnly={isCalendarDisabled}
          name="effective_date"
          onChange={handleChange}
          withIcon={false}
          wrapperClassName="h-10 border-none bg-transparent p-0 Date-Picker--Unstyled focused-placeholder cursor-pointer"
          value={currentValue.length > 0 ? parseDate(currentValue) : null}
        />
      </EffectiveDateCellTooltip>
      <EffectiveDateCellTooltip
        effectiveDates={effectiveDates}
        asHelperText
        disableTooltip={readOnly || hasError}
      >
        <button
          type="button"
          className="group/badge-box h-10 w-10 cursor-pointer place-content-center border-none bg-transparent !p-0 grid"
          onClick={handleOpenModal}
          disabled={readOnly}
        >
          <div
            className={cn("badge--gray badge--sm !w-6 !px-2 opacity-0", {
              "group-hover/badge-box:!elevation--secondary group-hover/cell:!no-underline group-hover/cell:opacity-100":
                !readOnly,
            })}
          >
            <FontAwesomeIcon icon={["far", "plus"]} />
          </div>
        </button>
      </EffectiveDateCellTooltip>
    </>
  )
}

interface MultipleDateProps {
  currentEffectiveDates: EffectiveDate[]
  handleOpenModal: () => void
  readOnly: boolean
}

const MultipleDates = ({ currentEffectiveDates, handleOpenModal, readOnly }: MultipleDateProps) => {
  const { t } = useTranslation()

  return (
    <EffectiveDateCellTooltip effectiveDates={currentEffectiveDates}>
      <button
        type="button"
        className="w-full items-center justify-between rounded-lg border-none bg-transparent p-0 flex"
        onClick={handleOpenModal}
        disabled={readOnly}
      >
        <div className="px-1">{t("v2.headcount_plan.datasheet.multiple")}</div>
        <div className="group/badge-box h-10 w-10 place-content-center grid">
          <div
            className={cn("badge--gray badge--sm !w-6 !px-2", {
              "group-hover/badge-box:!elevation--secondary": !readOnly,
            })}
          >
            {getDistinctDates(currentEffectiveDates).length}
          </div>
        </div>
      </button>
    </EffectiveDateCellTooltip>
  )
}
