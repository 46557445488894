/* eslint-disable react/no-danger */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { AlertBanner } from "v2/react/shared/status/AlertBanner"

type Props = {
  displayState: "original" | "approved"
  setDisplayState: (value: "original" | "approved") => void
  positionsApproved: number
}

export function ParticipantFinalizedBanner({
  displayState,
  setDisplayState,
  positionsApproved,
}: Props) {
  return displayState === "original" ? (
    <OriginalProposalBanner setDisplayState={setDisplayState} />
  ) : (
    <ApprovedPlanBanner positionsApproved={positionsApproved} setDisplayState={setDisplayState} />
  )
}

function ApprovedPlanBanner({
  positionsApproved,
  setDisplayState,
}: {
  positionsApproved: number
  setDisplayState: (value: "original" | "approved") => void
}) {
  return (
    <AlertBanner
      type="success"
      className="mb-4"
      messages={[
        {
          icon: (
            <FontAwesomeIcon
              icon={["far", "circle-check"]}
              className="h-4 text-status-success"
              style={{ width: "1rem" }}
            />
          ),
          text: "plan_notification_finalized".t("headcount_plan", null, "1", positionsApproved, [
            positionsApproved,
          ]),
          subText: (
            <div>
              <span>{"find_your_original_proposal".t("headcount_plan")}</span>
              <button
                type="button"
                className="bg-transparent p-0 underline"
                onClick={() => setDisplayState("original")}
              >
                {"here".t("headcount_plan")}
              </button>
              .
            </div>
          ),
        },
      ]}
    />
  )
}

function OriginalProposalBanner({
  setDisplayState,
}: {
  setDisplayState: (value: "original" | "approved") => void
}) {
  return (
    <AlertBanner
      type="caution"
      className="mb-4"
      messages={[
        {
          icon: (
            <FontAwesomeIcon
              icon={["far", "triangle-exclamation"]}
              className="h-4"
              style={{ width: "1rem" }}
            />
          ),
          text: "plan_notification_original".t("headcount_plan"),
          subText: (
            <div>
              <span>{"find_your_approved_plan".t("headcount_plan")}</span>
              <button
                type="button"
                className="bg-transparent p-0 underline"
                onClick={() => setDisplayState("approved")}
              >
                {"here".t("headcount_plan")}
              </button>
              .
            </div>
          ),
        },
      ]}
    />
  )
}
