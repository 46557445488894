import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"

import type { TimelineColumns, TimelineRow, TimelineTotalRow } from "types/graphql"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { useCsvDownloadListener } from "v2/react/shared/tables/TimelineTable/hooks/useCsvDownloadListener"
import { TimelineHeaderContent } from "v2/react/shared/tables/TimelineTable/TimelineHeaderContent"
import { TimelineTableTd } from "v2/react/shared/tables/TimelineTable/TimelineTableTd"
import { TimelineTableTh } from "v2/react/shared/tables/TimelineTable/TimelineTableTh"
import type { OnCellClick } from "v2/react/shared/tables/TimelineTable/types"
import {
  countRowsInSameGroupUpToIndex,
  countRowsInSameMetricUpToIndex,
  countRowsInSameMetricUpToTotalIndex,
  getHeaderMinWidth,
  getTooltipMessage,
  inSameGroupUpToIndex,
} from "v2/react/shared/tables/TimelineTable/utils"

type TimelineTableProps = {
  columns: TimelineColumns
  rows: TimelineRow[]
  totals?: TimelineTotalRow[]
  startDate: string
  endDate: string
  onCellClick?: OnCellClick
  csvDownloadRef?: React.RefObject<HTMLButtonElement> | string
  csvDownloadName?: string
  csvExportType?: string
  /**
   * Translation path for the tooltip message that appears when the timeline
   * interval does not match the timeline start/end dates. The translation path
   * provided assumed the following nested keys:
   * - timeline_start_and_end_tooltip
   * - timeline_start_tooltip
   * - timeline_end_tooltip
   */
  boundaryMismatchTooltipTranslationPath?: string
  showActuals?: boolean
}

/**
 * Renders a table of timeline data.
 *
 * Note about row ordering: Rows are expected to be ordered by their `groupByCells`. For example, if a row has a
 * `groupByCells` value of ["A", "a"], all other rows with the ["A", "a"] grouping must come immediately before or after,
 * and any rows starting with the "A" group must come immediately before or after those.
 *
 */
export function TimelineTable({
  columns,
  rows,
  totals,
  startDate,
  endDate,
  onCellClick,
  csvDownloadRef,
  csvDownloadName,
  csvExportType,
  boundaryMismatchTooltipTranslationPath,
  showActuals,
}: TimelineTableProps) {
  const { t } = useTranslation()

  useCsvDownloadListener(csvDownloadRef, columns, rows, totals, csvDownloadName, csvExportType)

  const handleCellClick = useCallback(
    ({
      index,
      rowId,
      metricKey,
      metricLabel,
      groupByCells,
    }: Omit<Parameters<OnCellClick>[0], "intervalStart" | "intervalEnd"> & { index: number }) => {
      if (!onCellClick) return undefined

      const isFirst = index === 0
      const isLast = index === columns.timeline.length - 1
      const currentCol = columns.timeline[index]
      const intervalStart = isFirst ? startDate : currentCol.startDate
      const intervalEnd = isLast ? endDate : currentCol.endDate

      return onCellClick({
        rowId,
        metricKey,
        metricLabel,
        intervalStart,
        intervalEnd,
        groupByCells,
      })
    },
    [columns, endDate, onCellClick, startDate],
  )

  return (
    <div className="timeline-table">
      <table className="h-full bg-white">
        <thead>
          <tr>
            {columns.groupBy.map((cell, index) => (
              <TimelineTableTh
                key={cell.id}
                hideBorderLeft={index === 0}
                className="whitespace-normal"
                style={{
                  minWidth: getHeaderMinWidth(cell.label),
                }}
              >
                {cell.label}
              </TimelineTableTh>
            ))}
            <TimelineTableTh
              hideBorderLeft={columns.groupBy.length === 0}
              colSpan={showActuals ? 2 : 1}
            >
              {t("v2.defaults.metric")}
            </TimelineTableTh>
            {columns.timeline.map((cell, index) => (
              <TimelineTableTh
                key={cell.id}
                className="text-right"
                style={{ width: `${99 / columns.timeline.length}%` }}
                hideBorderRight={index === columns.timeline.length - 1}
              >
                <TimelineHeaderContent
                  tooltipMessage={getTooltipMessage({
                    t,
                    translationPath: boundaryMismatchTooltipTranslationPath,
                    timelineStartDate: startDate,
                    intervalStartDate: cell.startDate,
                    timelineEndDate: endDate,
                    intervalEndDate: cell.endDate,
                    isFirstColumn: index === 0,
                    isLastColumn: index === columns.timeline.length - 1,
                  })}
                  label={cell.label}
                />
              </TimelineTableTh>
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={row.id}>
              {row.groupByCells.map((value, groupIndex) =>
                !inSameGroupUpToIndex(row, rows[rowIndex - 1], groupIndex) ? (
                  <TimelineTableTd
                    key={columns.groupBy[groupIndex].id}
                    rowSpan={countRowsInSameGroupUpToIndex(rows, row, groupIndex)}
                    className="font-bold"
                    hideBorderLeft={groupIndex === 0}
                    hideBorderBottom={rowIndex === rows.length - 1}
                  >
                    {value}
                  </TimelineTableTd>
                ) : null,
              )}
              {showMetricTd(rows, row, rowIndex) && (
                <TimelineTableTd
                  className={cn("font-bold", tigerStripe(rows, row))}
                  rowSpan={countRowsInSameMetricUpToIndex(rows, row, rowIndex)}
                  hideBorderLeft={row.groupByCells.length === 0}
                  hideBorderBottom={rowIndex === rows.length - 1}
                >
                  {row.metric}
                  <MetricTooltip metricKey={row.metricKey} />
                </TimelineTableTd>
              )}
              {showActuals && (
                <TimelineTableTd
                  className={cn("font-bold", tigerStripe(rows, row))}
                  hideBorderBottom={rowIndex === rows.length - 1}
                >
                  {t(`v2.headcount_plan_timeline.kind.${row.kind}`)}
                </TimelineTableTd>
              )}
              {row.timelineCells.map((value, index) => (
                <TimelineTableTd
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={columns.timeline[index].id + index}
                  id={`${row.id}-${columns.timeline[index]?.id}`}
                  className={cn(
                    "text-right font-normal",
                    {
                      "hover:cursor-pointer hover:bg-neutral-3": !!onCellClick,
                    },
                    tigerStripe(rows, row),
                  )}
                  hideBorderRight={index === row.timelineCells.length - 1}
                  hideBorderBottom={rowIndex === rows.length - 1}
                  onClick={() =>
                    handleCellClick({
                      index,
                      rowId: row.id,
                      metricKey: row.metricKey,
                      metricLabel: row.metric,
                      groupByCells: row.groupByCells,
                    })
                  }
                >
                  {value}
                </TimelineTableTd>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {totals?.map((row, rowIndex) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <tr key={row.metric + rowIndex}>
              {rowIndex === 0 ? (
                <TimelineTableTd
                  className="border-l-0 font-bold uppercase"
                  colSpan={columns.groupBy.length}
                  rowSpan={totals.length}
                >
                  {t("v2.defaults.total")}
                </TimelineTableTd>
              ) : null}
              {showMetricTotalTd(totals, row, rowIndex) && (
                <TimelineTableTd
                  className={cn("font-bold", tigerStripeTotal(totals, row, rows))}
                  rowSpan={countRowsInSameMetricUpToTotalIndex(totals, row, rowIndex)}
                >
                  {row.metric}
                </TimelineTableTd>
              )}
              {showActuals && (
                <TimelineTableTd
                  className={cn("font-bold", tigerStripeTotal(totals, row, rows))}
                  hideBorderBottom={rowIndex === rows.length - 1}
                >
                  {t(`v2.headcount_plan_timeline.kind.${row.kind}`)}
                </TimelineTableTd>
              )}
              {row.timelineCells.map((value, index) => (
                <TimelineTableTd
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={columns.timeline[index].id + index}
                  className={cn(
                    "text-right font-normal",
                    {
                      "hover:cursor-pointer hover:bg-neutral-3": !!onCellClick,
                    },
                    tigerStripeTotal(totals, row, rows),
                  )}
                  hideBorderRight={index === row.timelineCells.length - 1}
                  onClick={() =>
                    handleCellClick({
                      index,
                      rowId: row.id,
                      metricKey: row.metricKey,
                      metricLabel: row.metric,
                      groupByCells: [],
                    })
                  }
                >
                  {value}
                </TimelineTableTd>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  )
}

const tigerStripe = (rows: TimelineRow[], row: TimelineRow) => {
  if (rows.length <= 1 || rows.every((r) => r.metric === row.metric)) return ""

  const firstOfKind = rows.findIndex(
    (r) =>
      r.metric === row.metric && r.groupByCells.every((group) => row.groupByCells.includes(group)),
  )
  return firstOfKind % 2 === 0 ? "bg-primary-3" : ""
}

const tigerStripeTotal = (
  rows: TimelineTotalRow[],
  row: TimelineTotalRow,
  tableRows: TimelineRow[],
) => {
  const lastTableRowStriped =
    tigerStripe(tableRows, tableRows[tableRows.length - 1]) === "bg-primary-3"

  if (rows.length <= 1 || rows.every((r) => r.metric === row.metric)) return ""

  const firstOfKind = rows.findIndex((r) => r.metric === row.metric)
  return firstOfKind % 2 === (lastTableRowStriped ? 1 : 0) ? "bg-primary-3" : ""
}

const showMetricTd = (rows: TimelineRow[], row: TimelineRow, rowIndex: number) => {
  // If there is only 1 row for the metric, return true
  if (countRowsInSameMetricUpToIndex(rows, row, rowIndex) === 1) return true

  // If there is more than 1 row for a metric, we only show the first metric TD
  // and have it span the corresponding rows.
  return (
    countRowsInSameMetricUpToIndex(rows, row, rowIndex) > 0 &&
    rows[rowIndex - 1]?.metric !== rows[rowIndex]?.metric
  )
}

const showMetricTotalTd = (rows: TimelineTotalRow[], row: TimelineTotalRow, rowIndex: number) => {
  // If there is only 1 row for the metric, return true
  if (countRowsInSameMetricUpToTotalIndex(rows, row, rowIndex) === 1) return true

  // If there is more than 1 row for a metric, we only show the first metric TD
  // and have it span the corresponding rows.
  return (
    countRowsInSameMetricUpToTotalIndex(rows, row, rowIndex) > 0 &&
    rows[rowIndex - 1]?.metric !== rows[rowIndex]?.metric
  )
}

function MetricTooltip({ metricKey }: { metricKey: string }) {
  const {
    i18n: { exists },
    t,
  } = useTranslation()
  const translationKey = `v2.headcount_plan_timeline.metrics_tooltips.${metricKey}`

  if (!exists(translationKey)) {
    return null
  }

  return (
    <Tooltip>
      <TooltipTrigger className="ml-1">
        <FontAwesomeIcon icon={["far", "info-circle"]} />
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content">{t(translationKey)}</TooltipContent>
    </Tooltip>
  )
}
