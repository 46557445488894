import React from "react"

import type { TableUtilitiesProps, Utility } from "v2/react/shared/tables/TableUtilities"
import { EditAssetsTableSettings } from "v2/react/shared/tables/TableUtilities/EditAssetsTableSettings"
import { EditJobRequisitionsTableSettings } from "v2/react/shared/tables/TableUtilities/EditJobRequisitionsTableSettings"
import { EditPositionReportSettings } from "v2/react/shared/tables/TableUtilities/EditPositionReportSettings"
import { EditPositionTypesTableSettings } from "v2/react/shared/tables/TableUtilities/EditPositionTypesTableSettings"
import { EditSuccessionTableSettings } from "v2/react/shared/tables/TableUtilities/EditSuccessionTableSettings"
import { FilterTable } from "v2/react/shared/tables/TableUtilities/FilterTable"
import { isPositionTable } from "v2/react/shared/tables/TableUtilities/UtilityPanel"

interface UtilityButtonsProps extends Omit<TableUtilitiesProps, "timeZone"> {
  selectedUtility: Utility
  onUtilityToggle: (utility: Utility) => void
}

interface UtilityButtonProps extends Pick<UtilityButtonsProps, "onUtilityToggle"> {
  active: boolean
}

const UtilityButtons = ({ table, selectedUtility, onUtilityToggle }: UtilityButtonsProps) => (
  <div className="items-center gap-2 flex">
    {renderButtonsFor(table, onUtilityToggle, selectedUtility)}
  </div>
)

const renderButtonsFor = (
  table: UtilityButtonsProps["table"],
  onUtilityToggle: UtilityButtonsProps["onUtilityToggle"],
  selectedUtility: UtilityButtonsProps["selectedUtility"],
) => {
  if (isPositionTable(table)) {
    return (
      <>
        <FilterTable.Button
          active={selectedUtility === "filter-table"}
          onUtilityToggle={onUtilityToggle}
        />
        <EditPositionReportSettings.Button
          active={selectedUtility === "edit-position-report-table-settings"}
          onUtilityToggle={onUtilityToggle}
        />
      </>
    )
  }
  if (table === "succession-plans") {
    return (
      <EditSuccessionTableSettings.Button
        active={selectedUtility === "edit-succession-table-settings"}
        onUtilityToggle={onUtilityToggle}
      />
    )
  }
  if (table === "assets") {
    return (
      <EditAssetsTableSettings.Button
        active={selectedUtility === "edit-assets-table-settings"}
        onUtilityToggle={onUtilityToggle}
      />
    )
  }
  if (table === "exports-page") {
    return (
      <FilterTable.Button
        active={selectedUtility === "filter-table"}
        onUtilityToggle={onUtilityToggle}
      />
    )
  }
  if (table === "position-types") {
    return (
      <EditPositionTypesTableSettings.Button
        active={selectedUtility === "edit-assets-table-settings"}
        onUtilityToggle={onUtilityToggle}
      />
    )
  }

  if (table === "job-requisitions") {
    return (
      <EditJobRequisitionsTableSettings.Button
        active={selectedUtility === "edit-job-requisitions-table-settings"}
        onUtilityToggle={onUtilityToggle}
      />
    )
  }

  return null
}

export { UtilityButtons }
export type { UtilityButtonProps }
