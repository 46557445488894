import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { ChartNodeHistory } from "types/graphql"
import { useChartHistorySearch } from "v2/react/hooks/useChartHistorySearch"

import { ChartNodeHistoryAutocompleteResult } from "./ChartNodeHistoryAutocompleteResult"
import { RenderItemFn, Search, SearchInput, SearchList } from "./Search"

interface ChartNodeHistorySearchInputProps {
  chartKey: string
  asOf: string
  onSelect?: (selectedNode: ChartNodeHistory) => void
}

function ChartNodeHistorySearchInput({
  onSelect,
  asOf,
  chartKey,
}: ChartNodeHistorySearchInputProps) {
  const [inputValue, setInputValue] = useState("")
  const { chartNodeHistories, returnEmpty, searchTerm } = useChartHistorySearch({
    filter: inputValue.trim(),
    asOf,
    chartKey,
  })

  const { t } = useTranslation()

  const renderItem: RenderItemFn<(typeof chartNodeHistories)[0]> = ({
    item,
    index,
    activeIndex,
    listRef,
    getItemProps,
    handleResultClick,
  }) => (
    <ChartNodeHistoryAutocompleteResult
      className={classNames("list-group-item", { highlight: activeIndex === index })}
      getItemProps={getItemProps}
      handleResultClick={handleResultClick}
      isSelected={activeIndex === index}
      searchTerm={searchTerm}
      key={item.id}
      chartNodeHistory={item as ChartNodeHistory}
      refFn={(node: HTMLDivElement | null) => {
        // eslint-disable-next-line no-param-reassign
        listRef.current[index] = node
      }}
    />
  )

  return (
    <Search
      floatOverrides={{ placement: "bottom-start" }}
      items={chartNodeHistories as ChartNodeHistory[]}
      inputValue={inputValue}
      setInputValue={setInputValue}
      onSelect={onSelect}
      getId={(item) => item.position_id}
      getLabel={(item) => item.title || ""}
    >
      <div className="search-field relative">
        <div className="prefix pl-1 pr-1">
          <FontAwesomeIcon icon={["far", "search"]} />
        </div>
        <SearchInput
          className="h-6 pl-[1.9rem] text-sm sm:h-auto sm:text-base"
          placeholder={t("v2.historical_orgchart.search.placeholder")}
        />
      </div>
      <SearchList
        renderItem={renderItem}
        showEmptyMessage={!returnEmpty}
        emptyMessage={t("v2.positions.search.no_positions_found")}
        outerStyle={{ minWidth: "min(280px, 90vw)", right: 0 }}
        outerClassName="autocomplete-container"
        innerClassName="list-group autocomplete-list"
      />
    </Search>
  )
}

export { ChartNodeHistorySearchInput }
