import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"

import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { NavLinkProps } from "v2/react/shared/navigation/MainNav/NavLink"

interface CompanyMenuProps {
  companyName: string
  logoUrl: string
  menuLinks: NavLinkProps[]
  switcherLinks: Omit<NavLinkProps, "icon">[] | null
  switcherLabel: string
}

const CompanyMenu = ({
  companyName,
  logoUrl,
  menuLinks,
  switcherLinks,
  switcherLabel,
}: CompanyMenuProps) => {
  const companyNameClass = companyName.length >= 15 ? "text-sm" : "text-base-bold"

  return (
    <Dropdown className="company-menu mr-2 flex-1">
      <Dropdown.Trigger triggerClassName="dropdown-link items-center gap-1 rounded p-1 transition-all hover:bg-neutral-3 bg-transparent w-full">
        <div className="border--main h-6 w-6 shrink-0 rounded bg-white">
          <img
            src={logoUrl}
            alt={companyName}
            className="company-logo h-6 w-6 rounded object-cover"
          />
        </div>
        <div className="company-menu-inner hide w-full max-w-[123px] items-center justify-between gap-1 flex">
          <p
            className={cn(
              "company-name ml-2 w-[115px] text-left text-neutral-100",
              companyNameClass,
            )}
          >
            {companyName}
          </p>
          <FontAwesomeIcon icon="caret-down" />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Menu className="w-[208px]">
        {menuLinks.map(({ id, url, label, icon }) => (
          <Dropdown.Item key={id} as="a" href={url} useActiveStyles>
            {icon && (
              <span className="mr-2">
                <FontAwesomeIcon icon={icon} />
              </span>
            )}
            {label}
          </Dropdown.Item>
        ))}
        <CompanySwitcher switcherLinks={switcherLinks} switcherLabel={switcherLabel} />
      </Dropdown.Menu>
    </Dropdown>
  )
}

const CompanySwitcher = ({
  switcherLinks,
  switcherLabel,
}: Pick<CompanyMenuProps, "switcherLinks" | "switcherLabel">) => {
  const hasMultipleCompanies = switcherLinks && switcherLinks.length > 0
  if (!hasMultipleCompanies) return null

  return (
    <>
      <hr className="-mx-2 my-2" />
      <label className="py-2 text-sm text-neutral-64">{switcherLabel}</label>
      {switcherLinks.map(({ id, url, label, active }) => (
        <Dropdown.Item
          key={id}
          as="a"
          href={url}
          useActiveStyles
          className="items-center justify-between flex"
        >
          <p className="max-w-[160px] break-words">{label}</p>
          {active && (
            <FontAwesomeIcon icon={["fas", "circle-check"]} className="selected-icon ml-auto" />
          )}
        </Dropdown.Item>
      ))}
    </>
  )
}

export type { CompanyMenuProps }
export { CompanyMenu }
