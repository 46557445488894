import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { ContentType } from "v2/react/components/positionTypes/modals/types"

interface Props {
  infoBannerType: ContentType
  isAiAvailable: boolean
}

function InfoBanner({ infoBannerType, isAiAvailable }: Props) {
  const { t } = useTranslation()

  if (infoBannerType === "position" && !isAiAvailable) return null

  if (infoBannerType === "definition") {
    return (
      <div className="alert-neutral">
        <div className={cn("items-center gap-1.5 flex", { "mb-4": isAiAvailable })}>
          <FontAwesomeIcon icon={["far", "circle-info"]} />
          {t("v2.position_types.show.edit_job_definition_alert_title")}
        </div>
        {isAiAvailable && (
          <div className="5 gap-1 flex">
            <div className="w-3.5" />
            <span>{t("v2.position_types.show.edit_job_definition_alert_body")}</span>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="alert-neutral">
      <div className="gap-1.5 flex">
        <FontAwesomeIcon icon={["far", "circle-info"]} className="mt-1" />
        {t("v2.position_types.show.edit_job_definition_alert_body")}
      </div>
    </div>
  )
}

export { InfoBanner }
