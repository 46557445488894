import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

export function CreatePlanButton() {
  const { t } = useTranslation()

  return (
    <a href="/headcount_plans/setup" className="btn--large btn--primary whitespace-nowrap">
      <FontAwesomeIcon icon={["far", "plus"]} />
      <span>{t("v2.headcount_plan.lists.create_plan")}</span>
    </a>
  )
}
