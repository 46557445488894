const buildCsrfHeader = () => {
  const headers: { "x-csrf-token"?: string } = {}
  const selector = "[name='csrf-token']"
  const maybeCsrfToken = document.querySelector<HTMLMetaElement>(selector)

  if (maybeCsrfToken && maybeCsrfToken.content) {
    headers["x-csrf-token"] = maybeCsrfToken.content
  }
  return headers
}

export { buildCsrfHeader }
