import { TextColors } from "v2/color_coding/color_coder"

import { globals } from "../constants/options"
import { dimensions as ellipsisIconDimensions } from "../svg/defs/defineEllipsisIcon"
import { dimensions as panelSidebarDimensions } from "../svg/defs/definePanelSidebarIcon"
import { NodeData, PositioningHelper } from "./types"

const buttonWidth = 24
const buttonEdgeOffset = 8

const buttonMouseover = function (this: SVGGElement, d: NodeData) {
  if (d.textColorDomain) {
    const fillColor = TextColors[d.textColorDomain].extraLight

    window.d3.select(this).selectAll("rect.hover-color-overlay").attr("fill", fillColor)
  } else {
    window.d3
      .select(this)
      .selectAll("rect.hover-color-overlay")
      .attr("fill", TextColors.darkText.extraLight20)
  }

  window.d3
    .select(this)
    .selectAll("rect.card-color-overlay")
    .attr("fill", (d) => (d.card_color ? d.card_color : globals.cardColor))
}

const hoverOverlayColor = (d: NodeData) => {
  if (d.textColorDomain) {
    return TextColors[d.textColorDomain].transparent
  }
  return TextColors.darkText.extraLight
}

const buttonMouseout = function (this: SVGGElement) {
  window.d3.select(this).selectAll("rect.hover-color-overlay").attr("fill", hoverOverlayColor)
}

const appendButtons = (
  selection: d3.Selection<NodeData>,
  buttonIdentifier: string,
  x: number,
  y: number,
): d3.Selection<NodeData> => {
  selection
    .append("rect")
    .attr("class", `node-action-button ${buttonIdentifier} card-color-overlay`)
    .attr("fill", (d) => (d.card_color ? d.card_color : globals.cardColor))
    .attr("width", buttonWidth)
    .attr("height", buttonWidth)
    .attr("x", x)
    .attr("y", y)
    .attr("rx", 3)
    .attr("ry", 3)
  selection
    .append("rect")
    .attr("class", `node-action-button hover-color-overlay ${buttonIdentifier}`)
    .attr("fill", hoverOverlayColor)
    .attr("width", buttonWidth)
    .attr("height", buttonWidth)
    .attr("x", x)
    .attr("y", y)
    .attr("rx", 3)
    .attr("ry", 3)

  return selection
}

/**
 * Appends a <g> element to handle the actions menu to the given SVGGElement.
 */
function appendMenuButton(
  this: SVGGElement,
  positioningHelper: PositioningHelper,
  clickHandler: () => void,
): SVGGElement {
  const x = globals.nodeWidth / 2 - buttonWidth - buttonEdgeOffset
  const y = globals.nodePadding
  const buttonGroup = window.d3
    .select(this)
    .append("g")
    .attr("class", "node-action-button-group actions")
    .on("click", clickHandler)
    .on("mouseover", buttonMouseover)
    .on("mouseout", buttonMouseout)

  appendButtons(buttonGroup, "actions", x, y)

  buttonGroup
    .append("use")
    .attr("href", (d) => {
      if (d.textColorDomain && d.textColorDomain === "lightText") {
        return "#ellipsis-icon-light"
      }

      return "#ellipsis-icon"
    })
    .style({ "pointer-events": "none" })
    .attr("width", ellipsisIconDimensions.width)
    .attr("height", ellipsisIconDimensions.height)
    .attr("x", x - ellipsisIconDimensions.width / 2 + buttonWidth / 2)
    .attr("y", y - ellipsisIconDimensions.height / 2 + buttonWidth / 2)

  return this
}

function appendProfileButton(
  this: SVGGElement,
  positioningHelper: PositioningHelper,
  clickHandler: () => void,
  actionsButttonVisible: boolean,
): SVGGElement {
  let x = globals.nodeWidth / 2 - buttonWidth - buttonEdgeOffset
  const spaceBetweenButtons = 2
  x -= actionsButttonVisible ? buttonWidth + spaceBetweenButtons : 0
  const y = globals.nodePadding

  const buttonGroup = window.d3
    .select(this)
    .append("g")
    .attr("class", "node-action-button-group profile")
    .on("click", clickHandler)
    .on("mouseover", buttonMouseover)
    .on("mouseout", buttonMouseout)

  appendButtons(buttonGroup, "profile", x, y)

  buttonGroup
    .append("use")
    .attr("class", "node-action-icon profile")
    .attr("href", (d) => {
      if (d.textColorDomain && d.textColorDomain === "lightText") {
        return "#circle-info-icon-light"
      }

      return "#circle-info-icon"
    })
    .style({ "pointer-events": "none" })
    .attr("width", panelSidebarDimensions.width)
    .attr("height", panelSidebarDimensions.height)
    .attr("x", x - panelSidebarDimensions.width / 2 + buttonWidth / 2)
    .attr("y", y - panelSidebarDimensions.height / 2 + buttonWidth / 2)
    .on("click", clickHandler)

  return this
}

export default { appendMenuButton, appendProfileButton, buttonEdgeOffset, buttonWidth }
