import { today as dateToday, DateValue, getLocalTimeZone, parseDate } from "@internationalized/date"
import dayjs, { Dayjs } from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import { isEqual } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import ClosePanelButton from "v2/react/components/orgChart/SuperPanel/ClosePanelButton"
import { DrawerFooter } from "v2/react/components/orgChart/SuperPanel/DrawerFooter"
import { DateShortcutButton } from "v2/react/components/orgChart/SuperPanel/TimeTravelTab/DateShortcutButton"
import { DatePicker } from "v2/react/shared/forms/DateInputs/DatePicker"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { toggleSuperPanelFooter } from "v2/redux/slices/VisualizationSlice"
import { selectChartSettings } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

dayjs.extend(quarterOfYear)
dayjs.extend(localizedFormat)

function TimeTravelTab() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    historyModeSelectedDate: initialHistoryModeSelectedDate,
    historyMode: initialHistoryMode,
    displayMode,
  } = useAppSelector(selectChartSettings)
  const [historyMode, setHistoryMode] = useState(initialHistoryMode)
  const [selectedShortcutDate, setSelectedShortcutDate] = useState("")
  const [selectedDate, setSelectedDate] = useState(initialHistoryModeSelectedDate)
  const [error, setError] = useState("")
  const chartHistoryCutoffDate = useAppSelector((state) => state.container.chartHistoryCutoffDate)

  useEffect(() => {
    const historyModeChanged = !isEqual(initialHistoryModeSelectedDate, selectedDate)
    dispatch(toggleSuperPanelFooter(historyModeChanged))
  }, [dispatch, initialHistoryModeSelectedDate, selectedDate])

  const editMode = useAppSelector((state) => state.visualization.editMode)
  const cutoffDate = dayjs(chartHistoryCutoffDate).startOf("day")
  const today = dayjs().startOf("day")
  const formattedToday = today.format("YYYY-MM-DD")
  const shortcutDates = useMemo(
    () => [
      {
        date: today.format("YYYY-MM-DD"),
        label: t("v2.orgchart.super_panel.time_travel_tab.shortcuts.today"),
      },
      {
        date: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
        label: t("v2.orgchart.super_panel.time_travel_tab.shortcuts.end_of_last_month"),
      },
      {
        date: dayjs().subtract(1, "quarter").endOf("quarter").format("YYYY-MM-DD"),
        label: t("v2.orgchart.super_panel.time_travel_tab.shortcuts.end_of_last_quarter"),
      },
      {
        date: dayjs().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
        label: t("v2.orgchart.super_panel.time_travel_tab.shortcuts.end_of_last_year"),
      },
      {
        date: dayjs().subtract(12, "months").format("YYYY-MM-DD"),
        label: t("v2.orgchart.super_panel.time_travel_tab.shortcuts.12_months_ago"),
      },
    ],
    [today, t],
  )

  if (!chartHistoryCutoffDate) {
    return ""
  }

  const saveOptions = async () => {
    const historyModeSwitched = historyMode !== initialHistoryMode

    const preferencesPayload = {
      history_mode_selected_date: selectedDate,
      history_mode: historyMode,
      edit_mode: historyMode === true ? false : editMode,
    }

    const preferences = asyncPatchPreferences(preferencesPayload)
    await dispatch(preferences)

    if (historyModeSwitched || displayMode === "grid") {
      window.location.reload()
    }
  }

  const setDate = (date: string) => {
    setError("")
    setHistoryMode(date !== dayjs().startOf("day").format("YYYY-MM-DD"))
    const dateMatchesShortcut = shortcutDates.find((shortcutDate) => shortcutDate.date === date)
    if (!dateMatchesShortcut) setSelectedShortcutDate("")
    setSelectedDate(date)
  }

  const setShortcutDate = (label: string, date: string) => {
    setSelectedShortcutDate(label)
    setDate(date)
  }

  const cancelChanges = () => {
    setSelectedDate(initialHistoryModeSelectedDate)
    setSelectedShortcutDate("")
    setHistoryMode(initialHistoryMode)
  }

  const validateDate = (date: Dayjs) => {
    if (date.isBefore(cutoffDate)) {
      return t("v2.orgchart.super_panel.time_travel_tab.errors.data_unavailable_before", {
        date: cutoffDate.format("ll"),
      })
    }
    if (date.isAfter(today)) {
      return t("v2.orgchart.super_panel.time_travel_tab.errors.data_unavailable_after_today")
    }

    return ""
  }

  const handleDateInputChange = (value: DateValue) => {
    const selectedDateValue = value.toString()
    const selectedDate = dayjs(selectedDateValue)

    setSelectedDate(selectedDateValue)
    setHistoryMode(selectedDateValue !== dayjs().startOf("day").format("YYYY-MM-DD"))

    if (!selectedDate) return

    const error = validateDate(selectedDate)
    setError(error)

    if (!error) setDate(selectedDateValue)
  }

  const dateValue = parseDate(selectedDate || formattedToday)
  return (
    <div id="tab-time-travel" className="drawer-contents panel grid-rows-[auto_1fr] grid">
      <div className="drawer-header">
        <div className="drawer-title">{t("v2.orgchart.super_panel.time_travel")}</div>
        <ClosePanelButton />
      </div>

      <form className="h-full">
        <div className="drawer-section-content h-full !overflow-visible p-3">
          <DatePicker
            id="time-travel-date"
            value={dateValue}
            aria-label={t("v2.orgchart.super_panel.time_travel_tab.datepicker_aria_label")}
            onChange={handleDateInputChange}
            minValue={parseDate(cutoffDate.format("YYYY-MM-DD"))}
            maxValue={dateToday(getLocalTimeZone())}
            errorMessage={error}
          />

          <div className="mt-4" role="group" aria-labelledby="shortcut-buttons-label">
            <div id="shortcut-buttons-label" className="sr-only">
              {t("v2.orgchart.super_panel.time_travel_tab.shortcuts_label")}
            </div>
            {shortcutDates.map(({ date, label }) => (
              <DateShortcutButton
                key={label}
                isSelected={selectedShortcutDate === label}
                label={label}
                onClick={() => setShortcutDate(label, date)}
                date={date}
                cutoffDate={cutoffDate}
              />
            ))}
          </div>
        </div>
        <DrawerFooter
          onCancel={cancelChanges}
          onSave={saveOptions}
          cancelText={t("v2.defaults.cancel")}
          disableSubmit={error !== ""}
          doFormSubmit={false}
        />
      </form>
    </div>
  )
}

export { TimeTravelTab }
