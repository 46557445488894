/* eslint-disable no-nested-ternary */
import React from "react"

import { HeadcountPlan } from "types/graphql.d"
import { getEffectiveDate } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/effectiveDates/helpers"
import { DisplayValueCell } from "v2/react/components/headcountPlanning/TableDatasheet/DisplayValueCell"
import { StrikethroughCell } from "v2/react/components/headcountPlanning/TableDatasheet/StrikethroughCell"
import { Column, FieldType } from "v2/react/components/headcountPlanning/TableDatasheet/types"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

import { DatePickerCell } from "./DatePickerCell"
import { DropdownCell } from "./DropdownCell"
import { EffectiveDatesCell } from "./effectiveDates/EffectiveDatesCell"
import { FinalizedPositionMenuCell } from "./FinalizedPositionMenuCell"
import { ForcedAutocompleteCell } from "./ForcedAutocompleteCell"
import { JustificationCell } from "./JustificationCell"
import { LocationCell } from "./LocationCell"
import { NonEditableCell } from "./NonEditableCell"
import { PositionMenuCell } from "./PositionMenuCell"
import { PositionTypeCell } from "./PositionTypeCell"
import { ReportsToCell } from "./ReportsToCell"
import { StandardEditableCell } from "./StandardEditableCell"
import { StatusCell } from "./StatusCell"
import { SubmittedByCell } from "./SubmittedByCell"
import { SuggestedAutocompleteCell } from "./SuggestedAutocompleteCell"
import { TypeCell } from "./TypeCell"
import { HeadcountPlanDatasheetRow } from "./types"

type Props = {
  forEntireOrg: boolean
  row: HeadcountPlanDatasheetRow
  column: Column<HeadcountPlanDatasheetRow>
  headcountPlanId: string
  headcountPlan: HeadcountPlan | undefined
  participantId?: string
  participantPositionId?: string
  readOnly: boolean
}
export function HeadcountPlanDatasheetCell({
  forEntireOrg,
  row,
  column,
  headcountPlanId,
  headcountPlan,
  participantId,
  participantPositionId,
  readOnly,
}: Props) {
  const positionEndDate = getEffectiveDate(row.positionAttributesWithEdits, "position_end_date")

  if (column.id === "status") {
    return <StatusCell rowId={row.id} status={row.status} />
  }
  if (column.id === "type") {
    return (
      <TypeCell
        endDate={positionEndDate}
        rowId={row.id}
        type={row.type}
        excluded={row.excluded}
        allowedAttributes={headcountPlan?.allowedAttributes}
      />
    )
  }
  if (column.id === "finalized_options") {
    return (
      <FinalizedPositionMenuCell
        allowedAttributes={headcountPlan?.allowedAttributes}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        participantPositionId={participantPositionId}
        row={row}
      />
    )
  }
  if (column.id === "options") {
    return (
      <PositionMenuCell
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        participantPositionId={participantPositionId}
        row={row}
      />
    )
  }
  if (column.id === "effective_dates") {
    return (
      <EffectiveDatesCell
        endDate={headcountPlan?.endDate}
        headcountPlanId={headcountPlanId}
        readOnly={readOnly}
        row={row}
        startDate={headcountPlan?.startDate}
      />
    )
  }
  if (column.id === "position_id") {
    return (
      <DisplayValueCell
        id={`${column.id}-${row.id}`}
        className="non-editable-cell h-full flex-col justify-center flex"
      >
        {row.createdPosition?.systemIdentifier ? (
          <Tooltip>
            <TooltipTrigger>{row.createdPosition.systemIdentifier}</TooltipTrigger>
            <TooltipContent className="react-tooltip-content">{row.systemId}</TooltipContent>
          </Tooltip>
        ) : (
          row.systemId
        )}
      </DisplayValueCell>
    )
  }
  if (column.id === "title") {
    return (
      <PositionTypeCell
        row={row}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        column={column}
        readOnly={readOnly}
      />
    )
  }
  if (column.id === "location") {
    return (
      <LocationCell
        row={row}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }
  if (column.id === "reports_to") {
    return (
      <ReportsToCell
        forEntireOrg={forEntireOrg}
        row={row}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }
  if (column.id === "justification") {
    return (
      <JustificationCell
        row={row}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }
  if (column.id === "submitted_by") {
    return <SubmittedByCell rowId={row.id} content={row.submittedBy} excluded={row.excluded} />
  }
  if (column.fieldType === FieldType.ForcedAutocomplete) {
    return (
      <ForcedAutocompleteCell
        row={row}
        column={column}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }
  if (column.fieldType === FieldType.SuggestedAutocomplete) {
    return (
      <SuggestedAutocompleteCell
        row={row}
        column={column}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }

  if (column.fieldType === FieldType.Standard) {
    return (
      <StandardEditableCell
        row={row}
        column={column}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
        editable={column.editableFn?.(row)}
      />
    )
  }
  if (column.fieldType === FieldType.SelectDropdown) {
    let fieldsToClearOnChange = /^variable_pay_type_(\d+)_type$/.test(column.id)
      ? [column.id.slice(0, -5)]
      : []
    fieldsToClearOnChange =
      column.id === "budgeted_base_pay_type" ? ["position_hours_per_week"] : []
    return (
      <DropdownCell
        row={row}
        column={column}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
        fieldsToClearOnChange={fieldsToClearOnChange}
      />
    )
  }
  if (column.fieldType === FieldType.DatePicker) {
    return (
      <DatePickerCell
        row={row}
        column={column}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
        editable={column.editableFn?.(row)}
      />
    )
  }
  if (column.fieldType === FieldType.NonEditable) {
    return (
      <NonEditableCell
        row={row}
        column={column}
        readOnly={readOnly}
        currentValueOverride={nonEditableFieldValueOverride(column, row)}
      />
    )
  }

  const content = row.positionAttributesWithEdits[column.id] ?? ""

  if (row.excluded) {
    return <StrikethroughCell value={content} />
  }

  return <DisplayValueCell id={`${column.id}-${row.id}`}>{content}</DisplayValueCell>
}

function nonEditableFieldValueOverride(
  column: Column<HeadcountPlanDatasheetRow>,
  row: HeadcountPlanDatasheetRow,
) {
  if (column.id === "filled") {
    return `position_${row.filled ? "filled" : "open"}`.t("headcount_plan")
  }
  if (column.id === "proposed_budget") {
    return row.proposedBudget
  }
  return undefined
}
