import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import { ChangeBatch } from "types/graphql"
import {
  interactiveChangeBatches,
  selectOpenAndActiveBatches,
  transitionPausedBatch,
} from "v2/redux/GraphqlApi/ChangeRequestsApi"
import { RootState } from "v2/redux/store"

import { toFlattenedBatch } from "./ChangeRequestSlice/toFlattenedBatch"
import { ChangeRequestSliceState, ChangeScreen } from "./ChangeRequestSlice/types"

type PushScreensPayloadAction = PayloadAction<{
  scrollTop?: number
  screens: ChangeScreen[]
}>

const initialState: ChangeRequestSliceState = {
  activeChangeRequest: null,
  screenStack: [],
}

/* eslint-disable no-param-reassign */
const tryToReassignBatchInScreen = (state: ChangeRequestSliceState, batch?: ChangeBatch | null) => {
  if (!batch) return

  // If drilled into a screen representing the active batch, update the
  // active batch with the latest and greatest.
  state.screenStack.forEach((screen) => {
    if (screen.screenKey !== "Batch" || screen.batch.id !== batch.id) return
    screen.batch = toFlattenedBatch(batch)
  })
}

export const ChangeRequestSlice = createSlice({
  name: "changeRequest",
  initialState,
  reducers: {
    closedStackModal(state) {
      state.screenStack = []
    },
    pushedStackModalScreens(state, { payload }: PushScreensPayloadAction) {
      const priorIndex = state.screenStack.length - 1
      if (priorIndex >= 0) {
        state.screenStack[priorIndex].scrollTop = payload.scrollTop
      }
      state.screenStack.push(...payload.screens)
    },
    poppedStackModalScreen(state) {
      state.screenStack.pop()
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(interactiveChangeBatches.matchFulfilled, (state, { payload }) => {
        // Try to unwrap the latest batch that we may be opening straight up. We
        // generally assume that only interactive batches will undergo observable
        // changes.
        tryToReassignBatchInScreen(state, payload?.activeChangeBatch ?? payload?.openChangeBatch)
      })
      .addMatcher(transitionPausedBatch.matchFulfilled, (state, { payload }) => {
        // If a paused batch has been sorted out, try to update it in case it's
        // being shown to the end user.
        tryToReassignBatchInScreen(
          state,
          payload.changeRequestsTransitionPausedChangeBatch?.changeBatch,
        )
      }),
})
/* eslint-enable no-param-reassign */
export const selectHaveAnyActiveBatch = createSelector(
  selectOpenAndActiveBatches,
  ({ active }) => !!active,
)
export const selectScreenStack = (state: RootState) => state.changeRequest.screenStack
export const selectStackModalIsOpen = createSelector(selectScreenStack, (stack) => stack.length > 0)
export const selectChangeBatchesOverview = createSelector(
  selectOpenAndActiveBatches,
  ({ open, active }) => ({
    open,
    active,
    done: [],
  }),
)
export const selectMaterializedScreenStack = createSelector(
  selectScreenStack,
  selectOpenAndActiveBatches,
  (screenStack, { open, active }) =>
    fp.map((screen) => {
      if (screen.screenKey === "Batch" && screen.batch.id === open?.id)
        return { ...screen, batch: open }
      if (screen.screenKey === "Batch" && screen.batch.id === active?.id)
        return { ...screen, batch: active }

      return screen
    }, screenStack),
)
export const selectStackModalScreen = createSelector(selectMaterializedScreenStack, fp.last)

export const { closedStackModal, poppedStackModalScreen, pushedStackModalScreens } =
  ChangeRequestSlice.actions
export * from "./ChangeRequestSlice/hooks"
export * from "./ChangeRequestSlice/types"
