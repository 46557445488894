import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { ConfigureCollaboratorsModal } from "v2/react/components/headcountPlanning/ConfigureCollaboratorsModal"
import { HeadcountPlanningNavTitleWithSubtitle } from "v2/react/components/headcountPlanning/HeadcountPlanningNav"
import {
  useDisplayStateSync,
  useHCPParticipantHeaderData,
  type HCPParticipantHeaderDataProps,
  type MinimalHCPMember,
} from "v2/react/components/headcountPlanning/hooks/useHCPHeaderData"
import { ParticipantTopbarMenu } from "v2/react/components/headcountPlanning/Participant/ParticipantTopbarMenu"
import { NavActiveIcon } from "v2/react/components/headcountPlanning/shared/NavActiveIcon"
import { getNavLinks } from "v2/react/components/headcountPlanning/shared/navigation"
import { PlanTypeIndicator } from "v2/react/components/headcountPlanning/ShowParticipant/PlanTypeIndicator"
import {
  SubmitProposalButton,
  SubmitProposalModal,
} from "v2/react/components/headcountPlanning/ShowParticipant/SubmitProposalButton"
import type { ActiveView, DisplayState } from "v2/react/components/headcountPlanning/types"
import { withRootProvider } from "v2/react/components/RootProvider"
import { useActiveView } from "v2/react/hooks/useActiveView"
import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import { AvatarCircles } from "v2/react/shared/collection/AvatarCircles"
import { ClientOnly } from "v2/react/shared/layout/ClientOnly"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlockSmall } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { UrlHelper } from "v2/react/utils/urls"
import { selectAnyErrors } from "v2/redux/slices/DatasheetSlice/datasheetSelectors"
import { useAppSelector } from "v2/redux/store"

const CSV_DOWNLOAD_REF = "export-hcp"

type HeaderProps = {
  title: string
  headcountPlanName: string
  initialDisplayState: DisplayState
  activeView: ActiveView
} & HCPParticipantHeaderDataProps

const Header = ({
  title,
  headcountPlanName,
  headcountPlanId,
  participantId,
  setBudget,
  hasActiveStatus,
  lockedAt,
  canBeFinalized,
  initialDisplayState,
  activeView,
  participant,
  collaborators,
  hasSubmitted,
  canSubmit,
}: HeaderProps) => {
  const { t } = useTranslation()
  const { featureFlags: ff } = useCurrentActiveSession()
  const [submitProposalModalIsOpen, setSubmitProposalModalIsOpen] = useState(false)
  const [collaboratorsModalIsOpen, setCollaboratorsModalIsOpen] = useState(false)
  const datasheetHasErrors = useAppSelector(selectAnyErrors)
  const { activeView: currentView, handleLinkClick } = useActiveView<ActiveView>({
    initialView: activeView,
  })
  const { displayState, updateDisplayState } = useDisplayStateSync(initialDisplayState, currentView)
  const isOrgChart = currentView === "Org Chart"
  const isTimeline = currentView === "Timeline"
  const isInsights = currentView === "Insights"
  const canExport = !isOrgChart && !isInsights
  const {
    currentIsFinalized,
    currentHasActiveStatus,
    currentParticipant,
    currentCollaborators,
    currentHasSubmitted,
    currentCanSubmit,
    currentStartDate: startDate,
  } = useHCPParticipantHeaderData({
    headcountPlanId,
    participantId,
    setBudget,
    hasActiveStatus,
    lockedAt,
    canBeFinalized,
    participant,
    collaborators,
    hasSubmitted,
    canSubmit,
  })
  const canToggleDisplayState = !isTimeline && !isInsights && currentIsFinalized

  return (
    <PageNav>
      <TitleBlockLarge>
        <HeadcountPlanningNavTitleWithSubtitle
          title={title}
          subtitle={headcountPlanName}
          subtitleLinkTo={UrlHelper.headcountPlanPath(headcountPlanId)}
        />
      </TitleBlockLarge>
      <LinkBlockSmall>
        <LinkGroup
          links={getNavLinks({
            isOwner: false,
            active: currentView,
            headcountPlanId,
            participantId,
            ff,
            t,
          })}
          usePjax
          onClick={handleLinkClick}
        />
      </LinkBlockSmall>
      <ActionBlock>
        <div className="items-center gap-2 flex">
          <button
            className="flex-col justify-center bg-transparent flex"
            type="button"
            onClick={() => setCollaboratorsModalIsOpen(true)}
            aria-label="Manage Collaborators"
          >
            <AvatarCircles items={[currentParticipant, ...currentCollaborators]} />
          </button>
          <ClientOnly>
            <ConfigureCollaboratorsModal
              isOpen={collaboratorsModalIsOpen}
              setIsOpen={setCollaboratorsModalIsOpen}
              planner={{
                id: currentParticipant.uniqueKey,
                name: currentParticipant.person.name,
                personId: currentParticipant.person.id,
                avatarThumbUrl: currentParticipant.person.avatarThumbUrl,
              }}
              existingCollaborators={currentCollaborators}
              headcountPlanId={headcountPlanId}
              participantId={participantId}
            />
          </ClientOnly>

          {currentHasActiveStatus ? <NavActiveIcon /> : null}
          {currentIsFinalized ? (
            // For now, the timeline/insights data is always the approved plan.
            <PlanTypeIndicator type={isTimeline || isInsights ? "approved" : displayState} />
          ) : null}

          {currentHasSubmitted ? null : (
            <>
              <ClientOnly>
                <SubmitProposalModal
                  headcountPlanId={headcountPlanId}
                  isOpen={submitProposalModalIsOpen}
                  participantId={participantId}
                  setIsOpen={setSubmitProposalModalIsOpen}
                  startDate={startDate}
                />
              </ClientOnly>
              <SubmitProposalButton
                className="hidden sm:flex"
                disabled={!currentCanSubmit || datasheetHasErrors}
                setIsOpen={setSubmitProposalModalIsOpen}
              />
            </>
          )}

          {canExport && (
            <ExportButton csvDownloadRef={CSV_DOWNLOAD_REF} className="hidden sm:block" />
          )}

          <ParticipantTopbarMenu
            headcountPlanId={headcountPlanId}
            participantId={participantId}
            csvDownloadRef={CSV_DOWNLOAD_REF}
            showExportButton={canExport}
            showSubmitProposalButton={
              !currentHasSubmitted && !(!currentCanSubmit || datasheetHasErrors)
            }
            displayState={displayState}
            setDisplayState={updateDisplayState}
            canToggleDisplayState={canToggleDisplayState}
            startDate={startDate}
          />
        </div>
      </ActionBlock>
    </PageNav>
  )
}

const ParticipantHeader = withRootProvider(Header, "ParticipantHeader")

export type { MinimalHCPMember }
export { ParticipantHeader, CSV_DOWNLOAD_REF }
