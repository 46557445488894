import React from "react"
import { useTranslation } from "react-i18next"

import { ChangeRequest } from "types/graphql"
import { useScreen } from "v2/redux/slices/ChangeRequestSlice"

type ChangeRequestDetailsProps = {
  changeRequest?: ChangeRequest | null
}

export function ChangeRequestDetails({ changeRequest }: ChangeRequestDetailsProps) {
  const { t } = useTranslation()
  useScreen("ChangeRequest")

  if (!changeRequest) return null

  return (
    <div className="elevation flex-col gap-4 rounded-xl bg-white p-4 flex">
      <div className="flex-col gap-2 flex">
        <h3>{t("v2.adp.change_requests.details.change_source")}</h3>
        <pre className="m-0">
          {JSON.stringify(
            changeRequest.changeSource ?? t("v2.adp.change_requests.details.no_changes"),
            null,
            2,
          )}
        </pre>
      </div>

      <div className="flex-col gap-2 flex">
        <h3>{t("v2.adp.change_requests.details.request_status")}</h3>
        <p className="m-0">
          {changeRequest.httpMethod} {changeRequest.endpoint}
        </p>
        <pre className="m-0">{JSON.stringify(changeRequest.requestBody, null, 2)}</pre>
      </div>

      {changeRequest.correlationId ? (
        <div className="flex-col gap-2 flex">
          <h3>{t("v2.adp.change_requests.details.correlation_id")}</h3>
          <pre className="m-0">{changeRequest.correlationId}</pre>
        </div>
      ) : null}

      {changeRequest.responseStatusCode ? (
        <div className="flex-col gap-2 flex">
          <h3>{t("v2.adp.change_requests.details.response_code")}</h3>
          <p>{changeRequest.responseStatusCode}</p>
        </div>
      ) : null}

      {changeRequest.responseHeaders ? (
        <div className="flex-col gap-2 flex">
          <h3>{t("v2.adp.change_requests.details.response_headers")}</h3>
          <pre className="m-0">{JSON.stringify(changeRequest.responseHeaders, null, 2)}</pre>
        </div>
      ) : null}

      {changeRequest.responseBody ? (
        <div className="flex-col gap-2 flex">
          <h3>{t("v2.adp.change_requests.details.response_body")}</h3>
          <pre className="m-0">{JSON.stringify(changeRequest.responseBody, null, 2)}</pre>
        </div>
      ) : null}
    </div>
  )
}
