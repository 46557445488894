import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import assistantPosition from "images/assistant-position-icon.svg"
import standardPosition from "images/standard-position-icon.svg"
import OrgChart from "org_chart/chart/orgChart"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import ClosePanelButton from "v2/react/components/orgChart/SuperPanel/ClosePanelButton"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface Props {
  chart?: OrgChart | null
  addAsChild?: (position: Position) => void
}

function ChartBuilderTab({ chart, addAsChild }: Props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isListView = window.location.pathname.indexOf("lists/") > 0
  const isHCPView = window.location.pathname.indexOf("headcount_plans/") > 0
  const builderPositionsMin = useAppSelector((state) => state.visualization.builderPositionsMin)

  const positionNodes = [
    {
      src: standardPosition,
      title: t("v2.orgchart.super_panel.standard"),
      dataType: "standard",
    },
  ]

  if (!isHCPView)
    positionNodes.push({
      src: assistantPosition,
      title: t("v2.orgchart.super_panel.assistant"),
      dataType: "assistant",
    })

  useEffect(() => {
    window.App.OrgChart.mountChartBuilderTab(chart, addAsChild)
  }, [chart, isHCPView, addAsChild])

  const handleMinimize = () =>
    dispatch(asyncPatchPreferences({ builder_positions_min: !builderPositionsMin }))

  return (
    <div id="tab-chart-builder" className="drawer-contents panel grid-rows-[auto_1fr] grid">
      <div className="drawer-header">
        <div className="drawer-title">
          {isListView
            ? t("v2.orgchart.utilitynav.list_builder")
            : t("v2.orgchart.utilitynav.chart_builder")}
        </div>
        <ClosePanelButton />
      </div>

      <form className="h-full overflow-auto">
        <div className="drawer-section-content p-3">
          {!isListView && (
            <>
              <div className="justify-between flex">
                <p className="font-bold">{t("v2.orgchart.super_panel.positions")}</p>
                <button
                  type="button"
                  data-action="toggle-positions"
                  className="btn--sm btn--ghost"
                  onClick={handleMinimize}
                >
                  <FontAwesomeIcon
                    icon={["far", builderPositionsMin ? "chevron-down" : "chevron-up"]}
                  />
                </button>
              </div>

              <div
                id="position-nodes"
                className={cn("list-group mb-4 shadow-none", {
                  "list-group-inline": builderPositionsMin,
                })}
              >
                {positionNodes.map((position) => (
                  <div
                    className={cn("list-group-item", { tooltip: builderPositionsMin })}
                    data-type={position.dataType}
                    key={position.title}
                  >
                    <div className="list-group-item-thumb">
                      <img src={position.src} alt={position.title} />
                    </div>
                    <div
                      className={cn("title", {
                        "tooltip-content tooltip-content--sm": builderPositionsMin,
                      })}
                    >
                      {position.title}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {isHCPView && (
            <div>
              <label>{t("v2.orgchart.super_panel.people")}</label>
              <span className="text-neutral-64">{t("v2.defaults.coming_soon")}</span>
            </div>
          )}
          {!isHCPView && (
            <>
              {/*
            This renders the remaining chart_builder_tab.jst.ejs content
          */}
              <div id="builder-container" />
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export { ChartBuilderTab }
