import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

function InProgressAlert() {
  const { t } = useTranslation()
  const [alertOpen, setAlertOpen] = useState(true)
  const closeAlert = () => setAlertOpen(false)

  if (!alertOpen) return ""

  return (
    <div className="alert-caution top-17 fixed absolute -bottom-12 left-[50%] translate-x-[-50%] flex-row items-center gap-2 whitespace-nowrap p-2 pl-3 flex">
      <FontAwesomeIcon icon={["far", "triangle-exclamation"]} />
      {t("v2.headcount_plan.orgchart.not_all_proposals")}
      <button
        className="bg-transparent"
        type="button"
        onClick={closeAlert}
        aria-label={t("v2.defaults.close")}
      >
        <FontAwesomeIcon icon={["fal", "xmark"]} />
      </button>
    </div>
  )
}

export { InProgressAlert }
