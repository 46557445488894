import { useEffect } from "react"

import { chartViewIdFromUrl } from "v2/react/components/orgChart/HistoricalOrgChart/utils"
import { maybeGetIDFromUniqueKey } from "v2/react/utils/uniqueKey"
import { setChartViewId as reduxSetChartViewId } from "v2/redux/slices/VisualizationSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

const maybeChartViewType = (input: string | null | undefined) => {
  if (!input) return null

  if (input.match(/position_/)) {
    return "top_position"
  }
  if (input.match(/chart_section_/)) {
    return "chart_section"
  }

  return null
}

const useChartViewId = () => {
  const chartViewId = useAppSelector((state) => state.visualization.chartViewId)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const handlePopState = () => {
      const chartViewId = chartViewIdFromUrl()
      dispatch(reduxSetChartViewId(chartViewId))
      const chartViewType = maybeChartViewType(chartViewId)
      const chartViewRecordId = maybeGetIDFromUniqueKey(chartViewId)

      window.App.OrgChart.setChartViewOptions(chartViewType, chartViewRecordId)
    }

    window.addEventListener("popstate", handlePopState)

    return () => {
      window.removeEventListener("popstate", handlePopState)
    }
  }, [dispatch])

  const setChartViewId = (newChartViewId: string | null) => {
    dispatch(reduxSetChartViewId(newChartViewId))

    const chartViewType = maybeChartViewType(newChartViewId)
    const chartViewId = maybeGetIDFromUniqueKey(newChartViewId)
    window.App.OrgChart.setChartViewOptions(chartViewType, chartViewId)
  }

  return { chartViewId, setChartViewId }
}

export { useChartViewId }
