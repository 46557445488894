import {
  AddPositionsToChartInput,
  AtsOptionsQuery,
  CancelRequisitionInput,
  ConfigureJobRequisitionApprovalsInput,
  JobRequisitionInfoQuery,
  JobRequisitionInfoQueryVariables,
  JobRequisitionQuery,
  JobRequisitionQueryVariables,
  JobRequisitionsTableSettingsQuery,
  JobRequisitionsTableSettingsQueryVariables,
  PositionForRequisitionQuery,
  PositionForRequisitionQueryVariables,
  SaveJobRequisitionInput,
  SaveRecruitingSettingsInput,
  SendToAtsInput,
  SendToAtsMutationVariables,
  SendToAtsPayload,
  SendToSmartRecruitersInput,
  SendToSmartRecruitersMutation,
  SmartRecruitersJobRequisitionQuery,
  SmartRecruitersJobRequisitionQueryVariables,
  ToggleJobRequisitionInput,
  UpdatePersonJobRequisitionsTableSettingsInput,
} from "types/graphql.d"
import OperationStore from "v2/operation_store"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { flatMutationOperation, mutationOperation, queryOperation } from "v2/redux/utils/endpoints"

const addPositionsToChartOperationId = OperationStore.getOperationId("AddPositionsToChart")
const cancelRequisitionOperationId = OperationStore.getOperationId("CancelRequisition")
const ToggleJobRequisitionOperationId = OperationStore.getOperationId("ToggleJobRequisition")
const configureJobRequisitionApprovalsId = OperationStore.getOperationId(
  "ConfigureJobRequisitionApprovals",
)

const getAtsOptionsQueryOperationId = OperationStore.getOperationId("AtsOptions")
const getPositionForRequisitionOperationId = OperationStore.getOperationId("PositionForRequisition")
const getJobRequisitionOperationId = OperationStore.getOperationId("JobRequisition")
const getJobRequisitionInfoOperationId = OperationStore.getOperationId("JobRequisitionInfo")
const getRecruitingSettingsOperationId = OperationStore.getOperationId("RecruitingSettings")
const saveJobRequisitionOperationId = OperationStore.getOperationId("SaveJobRequisition")
const saveRecruitingSettingsOperationId = OperationStore.getOperationId("SaveRecruitingSettings")
const sendToAtsOperationId = OperationStore.getOperationId("SendToAts")

export type JobRequisition = NonNullable<JobRequisitionQuery["jobRequisition"]>

export const JobRequisitionsApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    addPositionsToChart: builder.mutation({
      query: (params: { input: AddPositionsToChartInput }) => ({
        operationId: addPositionsToChartOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["JobRequisition"],
    }),
    cancelRequisition: builder.mutation({
      query: (params: { input: CancelRequisitionInput }) => ({
        operationId: cancelRequisitionOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["JobRequisition"],
    }),
    toggleJobRequisition: builder.mutation({
      query: (params: { input: ToggleJobRequisitionInput }) => ({
        operationId: ToggleJobRequisitionOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["JobRequisition"],
    }),
    configureJobRequisitionApprovals: builder.mutation({
      query: (params: { input: ConfigureJobRequisitionApprovalsInput }) => ({
        operationId: configureJobRequisitionApprovalsId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["JobRequisition"],
    }),
    configureJobRequisitionRecruiters: builder.mutation({
      query: mutationOperation<ConfigureJobRequisitionApprovalsInput>(
        "ConfigureJobRequisitionApprovals",
      ),
      invalidatesTags: ["JobRequisition"],
    }),
    getAtsOptions: builder.query<AtsOptionsQuery, void>({
      query: () => ({
        operationId: getAtsOptionsQueryOperationId,
      }),
    }),
    getPositionForRequisition: builder.query<
      PositionForRequisitionQuery,
      PositionForRequisitionQueryVariables
    >({
      query: (params: { positionId: string }) => ({
        operationId: getPositionForRequisitionOperationId,
        variables: { positionId: params.positionId },
      }),
    }),
    getJobRequisition: builder.query<JobRequisitionQuery, JobRequisitionQueryVariables>({
      query: (params: { id: string }) => ({
        operationId: getJobRequisitionOperationId,
        variables: { id: params.id },
      }),
      providesTags: ["JobRequisition"],
    }),
    getJobRequisitionInfo: builder.query<JobRequisitionInfoQuery, JobRequisitionInfoQueryVariables>(
      {
        query: (params: { id: string }) => ({
          operationId: getJobRequisitionInfoOperationId,
          variables: { id: params.id },
        }),
        providesTags: ["JobRequisitionInfo"],
      },
    ),
    getJobRequisitionsTableSettings: builder.query<
      JobRequisitionsTableSettingsQuery,
      JobRequisitionsTableSettingsQueryVariables
    >({
      query: queryOperation<JobRequisitionsTableSettingsQueryVariables>(
        "JobRequisitionsTableSettings",
      ),
      providesTags: ["JobRequisitionsTableSettings"],
    }),
    getRecruitingSettings: builder.query({
      query: () => ({
        operationId: getRecruitingSettingsOperationId,
      }),
      providesTags: ["RecruitingSettings"],
    }),
    saveJobRequisition: builder.mutation({
      query: (params: { input: SaveJobRequisitionInput }) => ({
        operationId: saveJobRequisitionOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["JobRequisition"],
    }),
    saveRecruitingSettings: builder.mutation({
      query: (params: { input: SaveRecruitingSettingsInput }) => ({
        operationId: saveRecruitingSettingsOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["RecruitingSettings"],
    }),
    sendToAts: builder.mutation<{ sendToAts: SendToAtsPayload }, SendToAtsMutationVariables>({
      query: (params: { input: SendToAtsInput }) => ({
        operationId: sendToAtsOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["JobRequisition"],
    }),
    updatePersonJobRequisitionsTableSettings: builder.mutation({
      query: mutationOperation<UpdatePersonJobRequisitionsTableSettingsInput>(
        "UpdatePersonJobRequisitionsTableSettings",
      ),
      invalidatesTags: ["JobRequisitionsTableSettings"],
    }),
    getSmartRecruitersJobRequisition: builder.query<
      SmartRecruitersJobRequisitionQuery,
      SmartRecruitersJobRequisitionQueryVariables
    >({
      query: queryOperation("SmartRecruitersJobRequisition"),
      providesTags: ["JobRequisition", "PositionForPanel"],
    }),
    sendToSmartRecruiters: builder.mutation<
      SendToSmartRecruitersMutation,
      SendToSmartRecruitersInput
    >({
      query: flatMutationOperation<SendToSmartRecruitersInput>("SendToSmartRecruiters"),
      invalidatesTags: (res) => {
        if (!res?.sendToSmartRecruiters.jobRequisition) return []
        return ["JobRequisition", "PositionForPanel"]
      },
    }),
  }),
})

export const {
  useAddPositionsToChartMutation,
  useCancelRequisitionMutation,
  useToggleJobRequisitionMutation,
  useConfigureJobRequisitionApprovalsMutation,
  useGetAtsOptionsQuery,
  useGetPositionForRequisitionQuery,
  useGetJobRequisitionQuery,
  useGetJobRequisitionInfoQuery,
  useGetJobRequisitionsTableSettingsQuery,
  useGetRecruitingSettingsQuery,
  useSaveJobRequisitionMutation,
  useSaveRecruitingSettingsMutation,
  useSendToAtsMutation,
  useUpdatePersonJobRequisitionsTableSettingsMutation,
  useGetSmartRecruitersJobRequisitionQuery,
  useSendToSmartRecruitersMutation,
} = JobRequisitionsApi
