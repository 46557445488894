import React from "react"

import type { HeadcountPlan } from "types/graphql"
import { BudgetingCells } from "v2/react/components/headcountPlanning/Overview/ParticipantTable/BudgetingCells"
import { Cell as ProgressCell } from "v2/react/components/headcountPlanning/Overview/ParticipantTable/ProgressColumns/Cell"
import { StatusCell } from "v2/react/components/headcountPlanning/Overview/ParticipantTable/StatusCell"

interface Props {
  headcountPlanId: string
  logoThumbUrl: string
  canBeFinalized: boolean
  companyName: string
  showBudget: boolean
  showProposal: boolean
  budget: HeadcountPlan["budget"]
  proposal: HeadcountPlan["proposedBudget"]
  remainingBudget: HeadcountPlan["remainingBudget"]
  showStatus: boolean
  showPositionCount: boolean
  planStarted: boolean
  planMetrics: Omit<HeadcountPlan["finalAggregations"], "newPositionsCount">
}

function EntireOrgRow({
  headcountPlanId,
  logoThumbUrl,
  canBeFinalized,
  companyName,
  showBudget,
  showProposal,
  budget,
  proposal,
  remainingBudget,
  showStatus,
  showPositionCount,
  planStarted,
  planMetrics,
}: Props) {
  return (
    <tr>
      <td>
        <div className="hcp-overview-table__entire-org-label">
          <div className="hcp-overview-table__node-label">
            <div className="hcp-overview-table__entire-org-node-logo">
              <img src={logoThumbUrl} alt="logoThumbUrl" />
            </div>
          </div>
          <a
            href={`/headcount_plans/${headcountPlanId}/owner`}
            className="hcp-overview-table__env-link p-3"
          >
            {companyName}
          </a>
        </div>
      </td>
      <td aria-label="empty" />
      <td aria-label="empty" />
      {showPositionCount && (
        <ProgressCell
          columnType="position_count"
          actual={planMetrics.actualPositionsCount || 0}
          planned={planMetrics.plannedPositionsCount || 0}
          planStarted={planStarted}
          showPlannedOnly={false}
        />
      )}
      <BudgetingCells
        budget={budget}
        completed={canBeFinalized}
        proposal={proposal}
        showBudget={showBudget}
        showProposal={showProposal}
        remainingBudget={remainingBudget}
      />
      {showStatus && <StatusCell />}
    </tr>
  )
}

export { EntireOrgRow }
