import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Maybe } from "types/graphql"
import {
  FallbackOptionProps,
  SuggestedAutocompleteCell,
} from "v2/react/components/headcountPlanning/TableDatasheet/SuggestedAutocompleteCell"
import { prepareStyle } from "v2/react/components/orgChart/Datasheet/Cell/utils"
import type {
  Column,
  CursorConnection,
  NodeRow,
} from "v2/react/components/orgChart/Datasheet/types"
import { useCollectionSearch } from "v2/react/hooks/useCollectionSearch"
import {
  toEncodedIdNamePair,
  toEncodedInitialData,
} from "v2/redux/slices/GridSlice/gridHelpers/orgUnits"
import { FieldKey } from "v2/redux/slices/NodeSlice/types"

type OrgUnitAutocompleteProps<TNode, CType> = {
  column: Column<CType>
  currentValue?: string
  cursorConnection: CursorConnection
  isFirst: boolean
  isLast: boolean
  row: NodeRow<TNode>
  style: React.CSSProperties
}

export function prepareValue(arg: Maybe<{ id: string; label: string }> | undefined) {
  if (!arg || arg.label === "") {
    return ""
  }
  if (arg.id === "custom") {
    return toEncodedInitialData(arg.label)
  }
  return toEncodedIdNamePair(arg.id, arg.label)
}

/**
 * Presents an autocomplete for the org unit type along with an action to add a
 * new org unit.
 *
 * @public
 */
function OrgUnitAutocomplete<TNode, CType>({
  column,
  currentValue,
  cursorConnection,
  isFirst,
  isLast,
  row,
  style,
}: OrgUnitAutocompleteProps<TNode, CType>) {
  const [inputValue, setInputValue] = useState(
    cursorConnection.initialWriteValue ?? currentValue ?? "",
  )

  const { collectionResult } = useCollectionSearch({
    fieldKey: column.fieldKey as FieldKey, // TODO MY: Harden this
    filter: inputValue.trim(),
  })

  return (
    <SuggestedAutocompleteCell
      editable
      cellInputRef={cursorConnection.cellInputRef}
      options={collectionResult}
      columnId={String(column.fieldKey)}
      saveFn={(option) => cursorConnection.saveFn(prepareValue(option))}
      renderFallbackOption={({ saveCustom }) => (
        <FallbackOption saveCustom={saveCustom} columnLabel={column.label} />
      )}
      className={classNames("GridBody-cell Cell__select-field bg-transparent", { last: isLast })}
      currentValue={{ id: "", label: currentValue ?? "" }}
      searchTerm={inputValue}
      setSearchTerm={setInputValue}
      rowId={row.id}
      style={{ ...prepareStyle(style, isFirst, row.color), padding: 0 }}
      renderOption={(option) => <div>{option.label}</div>}
    />
  )
}

function FallbackOption({
  saveCustom,
  columnLabel,
}: FallbackOptionProps & { columnLabel: string }) {
  const { t } = useTranslation()
  return (
    <div
      className={classNames(
        "AutocompleteField__footer",
        "align-center justify-center px-4 py-2 flex",
      )}
    >
      <button className="btn--sm btn--primary" onMouseDown={saveCustom} type="button">
        <FontAwesomeIcon icon={["far", "plus"]} />
        {t("v2.defaults.add_entity", { entity: columnLabel })}
      </button>
    </div>
  )
}

export { OrgUnitAutocomplete, OrgUnitAutocompleteProps }
