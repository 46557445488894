import React from "react"
import { useTranslation } from "react-i18next"

import { SmartRecruiters } from "v2/react/components/orgChart/ProfilePanel/PositionModules/ExternalReqModal/SmartRecruiters"
import { Standard } from "v2/react/components/orgChart/ProfilePanel/PositionModules/ExternalReqModal/Standard"
import { useNProgressBar } from "v2/react/hooks/useNProgressBar"
import { Modal } from "v2/react/shared/overlay/Modal"
import { useGetJobRequisitionInfoQuery } from "v2/redux/GraphqlApi/JobRequisitionsApi"

type ExternalReqProps = {
  reqId: string
  isOpen: boolean
  handleClose: (event: React.MouseEvent) => void
}

export function ExternalReqModal({ reqId, isOpen, handleClose }: ExternalReqProps) {
  const { data, isLoading } = useGetJobRequisitionInfoQuery({ id: reqId })
  const { t } = useTranslation()
  const req = data?.jobRequisition
  const isLoaded = !isLoading && !!req
  useNProgressBar({ finishCondition: isLoaded, startCondition: isOpen })
  if (isLoading || !req) return null

  const importSource = req.source
  const modalSize = importSource === "smart_recruiters" ? "lg" : "sm"

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t("v2.defaults.view_detail")}
      size={modalSize}
    >
      <div className="react-modal__body">
        {(importSource === "smart_recruiters" && <SmartRecruiters req={req} />) || (
          <Standard req={req} />
        )}
      </div>
    </Modal>
  )
}
