import { TFunction } from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

import { ChartNodeHistory } from "types/graphql"

interface ChartNodeHistoryAutocompleteResultProps {
  chartNodeHistory: ChartNodeHistory
  isSelected: boolean
  className: string
  searchTerm: string
  refFn: (node: HTMLDivElement | null) => void
  getItemProps: (userProps?: React.HTMLProps<HTMLElement> | undefined) => Record<string, unknown>
  handleResultClick: (uniqueKey: string | undefined | null, positionLabel: string) => void
}

const ChartNodeHistoryAutocompleteResult: React.FC<ChartNodeHistoryAutocompleteResultProps> = ({
  chartNodeHistory,
  isSelected,
  className,
  refFn,
  searchTerm,
  getItemProps,
  handleResultClick,
}) => {
  const title = chartNodeHistory.title || ""
  const { t } = useTranslation()
  const positionSystemID = chartNodeHistory.system_identifier
  const matchTerm = searchTerm?.toLowerCase()

  return (
    <div
      role="option"
      aria-selected={isSelected}
      key={chartNodeHistory.id}
      className={className}
      ref={refFn}
      /* eslint-disable react/jsx-props-no-spreading */
      {...getItemProps({
        onClick: () => handleResultClick(chartNodeHistory.position_id, title),
      })}
    >
      <div className="list-group-item-thumb circle circle-med shrink-0">
        <img alt={chartNodeHistory.name || ""} src={chartNodeHistory.avatar_with_default || ""} />
      </div>

      <div className="list-group-item-text">
        <div className="list-group-item-title text-base-bold">
          {chartNodeHistory.people?.map((person) => person.name).join(", ")}
        </div>
        <div className="list-group-item-content text-neutral-64">
          {chartNodeHistory.title || " "}
          {maybeShowPositionID(positionSystemID, matchTerm, t)}
        </div>
      </div>
    </div>
  )
}

const maybeShowPositionID = (
  positionSystemID: string | undefined | null,
  matchTerm: string,
  t: TFunction,
) =>
  positionSystemID?.toLowerCase().includes(matchTerm) ? (
    <div>
      <div>{`${t("v2.historical_orgchart.search.result_position_id")}: ${positionSystemID}`}</div>
    </div>
  ) : null

export { ChartNodeHistoryAutocompleteResult }
