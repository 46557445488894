import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { createPortal } from "react-dom"
import { useMediaQuery } from "usehooks-ts"

import { getPageTopOffsets } from "v2/react/utils/layout"

interface PanelSidebarProps {
  children: React.ReactNode
  id?: string
  isOpen: boolean
  panelType: PanelSidebarType
}

type PanelSidebarType = "profile" | "succession" | "successionLarge"
type BreakPoints = { is480: boolean; is528: boolean; is1016: boolean }

const PanelSidebar: React.FC<PanelSidebarProps> = ({ children, id, isOpen, panelType }) => {
  const { totalHeaderHeight } = getPageTopOffsets()
  const is480 = useMediaQuery("(min-width: 480px)")
  const is528 = useMediaQuery("(min-width: 528px)")
  const is1016 = useMediaQuery("(min-width: 1016px)")
  const breakpoints: BreakPoints = { is480, is528, is1016 }

  const sidebarVariants = {
    open: { x: 0, transition: { duration: 0.4 } },
    closed: { x: "100%", transition: { duration: 0.4 } },
  }

  // It is important that the animated div element has a width of a non-zero
  // value by using the inherited width of the parent. Also, a margin is needed
  // here to let the drop shadow show.
  //
  // Additionally, this component needs to be placed within the `sticky_content`
  // content container in order to be positioned properly.
  return (
    <div className="sticky top-0 z-above-modal-panel-sidebar">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="absolute bottom-0 right-0 top-0 overflow-hidden"
            initial={{ width: 0 }}
            animate={{ width: panelWidth(panelType, breakpoints) }}
            style={{
              height: `calc(100vh - ${totalHeaderHeight}px)`,
            }}
          >
            <motion.div
              animate="open"
              className={classNames(
                "panel-scroll-container h-inherit overflow-y-auto overscroll-none",
                elevationStyles(panelType),
              )}
              exit="closed"
              initial="closed"
              variants={sidebarVariants}
            >
              <div className="h-fit-content min-h-full bg-white" id={id}>
                <div
                  className="fixed bottom-0 right-0 h-full w-1"
                  data-testid="right-animated-complete"
                />
                {children}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

// Animations will not work unless the widths play very nicely here. It is
// important that the animated div element has a width of a non-zero value by
// using the inherited width of the parent. The fixed position plays a role in
// this. Also, using tailwind breakpoint values for widths will cause
// animations to not work. We need to know media types or css variables.
// See: https://samuelkraft.com/blog/responsive-animation-framer-motion
// See: https://www.youtube.com/watch?v=xSuxsfn13xg
// NOTE: This media query approach is not ideal for server side rendering,
// etc. in the future we may need to use some other approach.
//
// We avoid using these:
//   Nope: "max-w-screen w-screen 480:w-[480px]"
//   Nope: "max-w-screen w-screen 1016:w-[528px]"
//   Nope: "max-w-screen w-screen 528:w-[528px] 1016:w-[1016px]"
// In the absence of breakpoints, use explicit widths to appease the animations.
// In the future if we use breakpoints, dynamic values will not work:
//   const staticWidthStyles = `max-w-screen w-screen ${widthBreakpoint}:w-[${width}]`
// https://stackoverflow.com/questions/72889068/template-literal-not-working-correctly-with-tailwind-css
// https://tailwindcss.com/docs/content-configuration#dynamic-class-names
const panelWidth = (panelType: PanelSidebarType, breakpoints: BreakPoints): string => {
  if (panelType === "profile" && breakpoints.is480) return "480px"
  if (panelType === "succession" && breakpoints.is528) return "528px"
  if (panelType === "successionLarge" && breakpoints.is1016) return "1016px"
  if (panelType === "successionLarge" && breakpoints.is528) return "528px"
  return "100%"
}

// A margin is needed here to let the drop shadow show
const elevationStyles = (panelType: PanelSidebarType): string => {
  if (panelType === "profile") return "480:elevation--profound-left 480:ml-4"
  return "528:elevation--profound-left 528:ml-4"
}

// We provide a portal version of this component in order to place it within the
// `sticky_content` content container in the case that this component is
// rendered from within the react component tree rather than through rails.
const PortaledPanelSidebar = (props: PanelSidebarProps) => {
  const portalElement = document.getElementById("portal-for-sticky-content")

  if (!portalElement) {
    return null
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <>{createPortal(<PanelSidebar {...props} />, portalElement)}</>
}

export { PanelSidebar, PortaledPanelSidebar }
