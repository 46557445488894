import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { TFunction } from "i18next"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { AllowedAttribute, Maybe, Scalars } from "types/graphql"
import { getEffectiveDate } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/effectiveDates/helpers"
import { useNProgressBar } from "v2/react/hooks/useNProgressBar"
import { AvatarCircles } from "v2/react/shared/collection/AvatarCircles"
import { Modal } from "v2/react/shared/overlay/Modal"
import { AlertBanner } from "v2/react/shared/status/AlertBanner"
import { isBlank } from "v2/react/utils/values"
import {
  useGetSubmitProposalDataQuery,
  useSubmitHeadcountPlanProposalMutation,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { updateCellStates } from "v2/redux/slices/DatasheetSlice"
import { CellEvent } from "v2/redux/slices/DatasheetSlice/types"
import { useAppDispatch } from "v2/redux/store"

import { BudgetOverBanner } from "../banners/BudgetOverBanner"

interface Props {
  disabled: boolean
  display?: "menuItem" | "button"
  onClick?: () => void
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  className?: string
}

export function SubmitProposalButton({
  disabled,
  display = "button",
  onClick,
  setIsOpen,
  className,
}: Props) {
  const { t } = useTranslation()
  return (
    <button
      type="button"
      className={classNames(
        "whitespace-nowrap",
        {
          "btn--large btn--secondary": display === "button",
          "dropdown-menu-link gap-2 flex": display === "menuItem",
          disabled,
        },
        className,
      )}
      onClick={() => {
        setIsOpen(true)
        onClick?.()
      }}
      disabled={disabled}
    >
      <div className="justify-self-center">
        <FontAwesomeIcon
          icon={["far", "paper-plane"]}
          className={classNames({ "mr-1": display === "button" })}
        />
      </div>
      {t("v2.headcount_plan.submit_proposal.submit_proposal")}
    </button>
  )
}

type HeadcountPlanChangeProjection = {
  id: Scalars["ID"]
  systemIdentifier: string
  positionAttributes?: Maybe<Scalars["JSON"]>
  positionAttributesWithEdits?: Maybe<Scalars["JSON"]>
}

type ModalProps = {
  headcountPlanId: string
  isOpen: boolean
  participantId: string
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  startDate: Dayjs
}
export function SubmitProposalModal({
  headcountPlanId,
  isOpen,
  participantId,
  setIsOpen,
  startDate,
}: ModalProps) {
  const { t } = useTranslation()
  const [note, setNote] = React.useState("")
  const [mutate, mutation] = useSubmitHeadcountPlanProposalMutation()
  const { data, isLoading } = useGetSubmitProposalDataQuery(
    isOpen
      ? {
          headcountPlanId,
          participantId,
        }
      : skipToken,
  )

  React.useEffect(() => {
    if (mutation.data?.submitHeadcountPlanProposal?.success) {
      mutation.reset()
      setIsOpen(false)
    }
  }, [mutation, setIsOpen])

  const allowedAttributes = data?.headcountPlan?.allowedAttributes
  const positions = data?.headcountPlan?.participant?.allPositionsInPlan
  const budget = data?.headcountPlan?.participant?.budget
  const proposedBudget = data?.headcountPlan?.participant?.proposedBudget
  const remainingBudget = data?.headcountPlan?.participant?.remainingBudget
  const submitsTo = data?.headcountPlan?.participant?.submitsTo

  const dataLoaded = !isLoading && !!data?.headcountPlan

  const positionsMissingBudgets: string[] = []
  const cellStateUpdates: CellEvent[] = []
  const appDispatch = useAppDispatch()

  if (isOpen && budget && positions && allowedAttributes) {
    positions.forEach((position: HeadcountPlanChangeProjection) => {
      validateBudgetFields({
        cellStateUpdates,
        position,
        positionsMissingBudgets,
        allowedAttributes,
        startDate,
        t,
      })
    })
  }

  useEffect(() => {
    if (dataLoaded && isOpen && cellStateUpdates.length > 0)
      appDispatch(updateCellStates(cellStateUpdates))
  })

  useNProgressBar({ finishCondition: dataLoaded, startCondition: isOpen })
  if (!dataLoaded) return null

  if (positionsMissingBudgets && positionsMissingBudgets.length > 0)
    return (
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        title={t("v2.headcount_plan.submit_proposal.information_missing")}
        footer={
          <button
            type="button"
            className="btn--large btn--secondary"
            onClick={() => {
              setIsOpen(false)
            }}
          >
            {t("v2.headcount_plan.submit_proposal.back_to_proposal")}
          </button>
        }
      >
        <div className="p-6">
          <div className="pb-4">
            <AlertBanner
              type="critical"
              messages={[
                {
                  icon: (
                    <FontAwesomeIcon
                      icon={["far", "triangle-exclamation"]}
                      className="h-4"
                      style={{ width: "1rem" }}
                    />
                  ),
                  text: (
                    <span className="font-medium">
                      {t("v2.headcount_plan.submit_proposal.budget_missing_for_positions")}
                      <ul>
                        {positionsMissingBudgets.map((positionName) => (
                          <li key={positionName}>{positionName}</li>
                        ))}
                      </ul>
                    </span>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Modal>
    )

  if (isOpen) {
    const limit = submitsTo?.length === 5 ? 3 : 4

    return (
      <Modal
        size="md"
        isOpen={isOpen}
        onClose={() => {
          mutation.reset()
          setIsOpen(false)
        }}
        title={t("v2.headcount_plan.submit_proposal.submit_proposal")}
        footer={
          <button
            type="submit"
            className="btn--large btn--primary"
            disabled={mutation.data?.submitHeadcountPlanProposal?.success === false}
            onClick={() => {
              mutate({
                input: {
                  headcountPlanId: headcountPlanId.toString(),
                  participantId,
                  justification: note,
                },
              })
            }}
          >
            {mutation.isLoading
              ? t("v2.headcount_plan.submit_proposal.submit_proposal_pending")
              : t("v2.headcount_plan.submit_proposal.submit_proposal")}
          </button>
        }
      >
        <div className="p-6">
          {mutation.data?.submitHeadcountPlanProposal?.success === false && (
            <div className="pb-4">
              <AlertBanner
                type="critical"
                messages={[
                  {
                    icon: (
                      <FontAwesomeIcon
                        icon={["far", "circle-exclamation"]}
                        className="h-4"
                        style={{ width: "1rem" }}
                      />
                    ),
                    text: (
                      <span className="font-medium">
                        {mutation.data?.submitHeadcountPlanProposal?.errors?.[0]?.message}
                      </span>
                    ),
                  },
                ]}
              />
            </div>
          )}

          {budget && proposedBudget && remainingBudget?.includes("-") && (
            <BudgetOverBanner
              budget={budget}
              proposedBudget={proposedBudget}
              remainingBudget={remainingBudget}
            />
          )}

          {submitsTo ? (
            <>
              <label>{t("v2.headcount_plan.submit_proposal.send_to")}</label>
              <div className="items-center gap-2 rounded-lg border border-solid border-neutral-8 bg-white p-2 flex">
                <AvatarCircles items={submitsTo} keyPrefix="submit-proposal" />
                {submitsTo
                  .slice(0, limit)
                  .map((c) => c.person.name)
                  .join(", ")}
                {submitsTo.length > limit &&
                  t("v2.headcount_plan.submit_proposal.and_other_admins", {
                    count: submitsTo.length - limit,
                  })}
              </div>
              <div className="input-group mt-6">
                <label htmlFor="note">{t("v2.headcount_plan.submit_proposal.note")}</label>
                <div style={{ width: "100%" }}>
                  <textarea
                    id="note"
                    className="input"
                    name="note"
                    style={{ resize: "none" }}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
              </div>
            </>
          ) : (
            <p>{t("v2.headcount_plan.submit_proposal.confirm_submit_to_entire_org")}</p>
          )}
        </div>
      </Modal>
    )
  }
}

interface ValidateBudgetFieldsProps {
  cellStateUpdates: CellEvent[]
  position: HeadcountPlanChangeProjection
  positionsMissingBudgets: string[]
  allowedAttributes: Pick<AllowedAttribute, "id" | "name">[]
  startDate: Dayjs
  t: TFunction
}

const budgetedBasePayRateDoesNotCoverPosition = (
  position: HeadcountPlanChangeProjection,
  startDate: Dayjs,
) => {
  if (!position.id.startsWith("position")) return false
  if (!isBlank(position.positionAttributes?.budgeted_base_pay_rate)) return false

  const budgetEffectiveDate = getEffectiveDate(
    position.positionAttributesWithEdits,
    "position_budget",
  )
  return !isBlank(budgetEffectiveDate) && dayjs(budgetEffectiveDate).isAfter(startDate)
}

const validateBudgetFields = ({
  cellStateUpdates,
  position,
  positionsMissingBudgets,
  allowedAttributes,
  startDate,
  t,
}: ValidateBudgetFieldsProps) => {
  const attributes = position.positionAttributesWithEdits
  const systemId = position.systemIdentifier
  const title = attributes.title.label
  const positionLabel = `${systemId} ${title && ": "}${title}`

  if (budgetedBasePayRateDoesNotCoverPosition(position, startDate)) {
    if (!positionsMissingBudgets.includes(positionLabel))
      positionsMissingBudgets.push(positionLabel)

    cellStateUpdates.push({
      rowId: position.id,
      columnId: "effective_dates",
      type: "error",
      message: t("v2.headcount_plan.datasheet.information_missing", {
        missing: t("v2.headcount_plan.fields.effective_date_for_field", {
          field: t("v2.headcount_plan.fields.position_budget"),
        }),
      }),
    })
  }

  if (!attributes.budgeted_base_pay_rate) {
    if (!positionsMissingBudgets.includes(positionLabel))
      positionsMissingBudgets.push(positionLabel)

    const label = allowedAttributes.find((a) => a.id === "budgeted_base_pay_rate")?.name

    cellStateUpdates.push({
      rowId: position.id,
      columnId: "budgeted_base_pay_rate",
      type: "error",
      message: t("v2.headcount_plan.datasheet.information_missing", {
        missing: label,
      }),
    })
  }

  if (!attributes.budgeted_base_pay_type) {
    if (!positionsMissingBudgets.includes(positionLabel))
      positionsMissingBudgets.push(positionLabel)

    const label = allowedAttributes.find((a) => a.id === "budgeted_base_pay_type")?.name

    cellStateUpdates.push({
      rowId: position.id,
      columnId: "budgeted_base_pay_type",
      type: "error",
      message: t("v2.headcount_plan.datasheet.information_missing", {
        missing: label,
      }),
    })
  }

  if (
    attributes.budgeted_base_pay_type === "position_base_pay_type_hourly" &&
    !attributes.position_hours_per_week
  ) {
    if (!positionsMissingBudgets.includes(positionLabel))
      positionsMissingBudgets.push(positionLabel)

    const label = allowedAttributes.find((a) => a.id === "position_hours_per_week")?.name

    cellStateUpdates.push({
      rowId: position.id,
      columnId: "position_hours_per_week",
      type: "error",
      message: t("v2.headcount_plan.datasheet.information_missing", {
        missing: label,
      }),
    })
  }
}
