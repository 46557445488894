import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import pluralize from "pluralize"
import React, { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"

import { CancelRequisitionInput, ToggleJobRequisitionInput } from "types/graphql"
import { DiffFromHeadcountPlanPositionData } from "v2/react/components/jobRequisitions/Overview/DiffFromHeadcountPlanPositionData"
import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"
import { JobRequisitionAbilities } from "v2/react/components/jobRequisitions/types"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlockLarge } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { TitleBlock, TitleHeaderWithParent } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { Modal, ModalFooter, useModalOverlayRef } from "v2/react/shared/overlay/Modal"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"
import {
  JobRequisition,
  useCancelRequisitionMutation,
  useToggleJobRequisitionMutation,
} from "v2/redux/GraphqlApi/JobRequisitionsApi"
import { setReqModalOpen } from "v2/redux/slices/RequisitionSlice"
import { useAppDispatch } from "v2/redux/store"

import { HeaderButtons } from "./HeaderButtons"

interface Props {
  abilities: JobRequisitionAbilities
  data: FormShape
  jobRequisition: JobRequisition
}

function Header({ abilities, data, jobRequisition }: Props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { modalRef } = useModalOverlayRef()
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)
  const [editWarningModalIsOpen, setEditWarningModalIsOpen] = useState(false)
  const [mutate, { isLoading: isLoadingMutation }] = useCancelRequisitionMutation()
  const [toggleMutate] = useToggleJobRequisitionMutation()
  const [cancelErrors, setCancelErrors] = useState<string | JSX.Element[]>()

  const handleCancelButtonClick = () => setCancelModalIsOpen(true)

  const handleCloseButtonClick = () => {
    toggleJobRequisitionCloseStatus()
  }

  const handleEditButtonClick = () => {
    if (jobRequisition.approvalState !== "canceled") {
      setEditWarningModalIsOpen(true)
    } else {
      dispatch(setReqModalOpen(true))
    }
  }

  const handleCancelSave = async () => {
    const input: CancelRequisitionInput = {
      jobRequisitionId: jobRequisition.id,
    }

    const result = await mutate({ input }).unwrap()

    if (result.cancelRequisition && result.cancelRequisition.errors.length === 0) {
      closeModal()
    } else {
      const errors: Error[] = result.saveJobRequisition?.errors || []
      if (errors.length === 0) return
      const errorMessage: JSX.Element[] = errors.map((s: Error) => (
        <span key={s.message}>
          {s.message}
          <br />
        </span>
      ))
      setCancelErrors(errorMessage)
    }
  }

  const handleEditWarningSave = () => {
    dispatch(setReqModalOpen(true))
    setEditWarningModalIsOpen(false)
  }

  const toggleJobRequisitionCloseStatus = async () => {
    const input: ToggleJobRequisitionInput = {
      jobRequisitionId: jobRequisition.id,
    }

    const result = await toggleMutate({ input }).unwrap()
    if (result.toggleJobRequisition.errors.length === 0) return

    const errors: Error[] = result.saveJobRequison?.errors || []
    if (errors.length === 0) return

    const errorMessage: JSX.Element[] = errors.map((s: Error) => (
      <span key={s.message}>
        {s.message}
        <br />
      </span>
    ))
    setCancelErrors(errorMessage)
  }

  const closeModal = () => {
    setCancelModalIsOpen(false)
    setEditWarningModalIsOpen(false)
    dispatch(setReqModalOpen(false))
  }

  const positionDisplay = (): ReactNode => {
    const positionText = "position".t("job_requisition")
    if (data.reqType === "new_position") {
      return (
        <>
          {data.sourceOpenings}{" "}
          {"new".t("job_requisition", null, null, null, [
            data.sourceOpenings > 1 ? pluralize(positionText) : positionText,
          ])}
          : {jobRequisition.jobTitle}
        </>
      )
    }
    return (
      <>
        {"backfill_for".t("job_requisition")}: {jobRequisition.jobTitle}
      </>
    )
  }

  const approvalBanner = (): ReactNode => (
    <div className="page-pad !pb-0">
      <div className="alert-success">
        <div className="flex">
          <div className="flex-1 flex-row content-center items-center gap-1 flex">
            <div className="mt-[0.08rem] self-center">
              <FontAwesomeIcon
                icon={["far", "circle-check"]}
                className="h-4"
                style={{ width: "1rem" }}
              />
            </div>
            {"approved".t("job_requisition")}
          </div>
          <button
            className="btn--large btn--secondary"
            onClick={handleCloseButtonClick}
            type="button"
          >
            <FontAwesomeIcon icon={["far", "box-archive"]} /> Close
          </button>
        </div>
      </div>
    </div>
  )

  return (
    // We're temporarily setting the top style manually here until we can
    // properly server render this component.
    <div className="requisition-header zPageNav sticky top-0">
      <PageNav>
        <TitleBlock>
          <TitleHeaderWithParent
            parentTitle={t("v2.jobs.header.tabs.job_requisitions")}
            parentTitleUrl="/job_requisitions"
            title={positionDisplay()}
          />
        </TitleBlock>
        <ActionBlockLarge>
          <div className="items-center gap-2 flex">
            <DiffFromHeadcountPlanPositionData mode="tooltip" />
            <div className="hidden sm:block">
              <StatusBadge
                status={jobRequisition.approvalState || ""}
                displayType="button"
                checkmarkOnApproved
              />
            </div>
            <HeaderButtons
              abilities={abilities}
              data={jobRequisition.jsonFormValues.data}
              handleCancelButtonClick={handleCancelButtonClick}
              handleEditButtonClick={handleEditButtonClick}
              handleCloseButtonClick={handleCloseButtonClick}
              jobRequisition={jobRequisition}
              status={jobRequisition.approvalState || ""}
            />
          </div>
        </ActionBlockLarge>
      </PageNav>

      {jobRequisition.approvalState === "approved" && approvalBanner()}

      <Modal
        footer={
          <ModalFooter
            disabled={isLoadingMutation}
            onClose={closeModal}
            onSave={handleCancelSave}
            saveButtonText={submitButtonText(isLoadingMutation)}
          />
        }
        isOpen={cancelModalIsOpen}
        onClose={closeModal}
        overlayRef={modalRef}
        title={"are_you_sure".t("defaults")}
      >
        <div className="p-6">
          {cancelErrors && <div className="alert alert-critical mb-4">{cancelErrors}</div>}
          {message(jobRequisition.approvalState === "approved")}
        </div>
      </Modal>
      <Modal
        footer={
          <ModalFooter
            disabled={isLoadingMutation}
            onClose={closeModal}
            onSave={handleEditWarningSave}
            saveButtonText={"yes_edit".t("job_requisition")}
          />
        }
        isOpen={editWarningModalIsOpen}
        onClose={closeModal}
        overlayRef={modalRef}
        title={"edit_requisition".t("job_requisition")}
      >
        <div className="p-6">{"edit_requires_resubmit".t("job_requisition")}</div>
      </Modal>
    </div>
  )
}

export { Header }

const submitButtonText = (isLoadingMutation: boolean): string => {
  if (isLoadingMutation) {
    return "canceling_requisition".t("job_requisition")
  }

  return "yes_cancel_requisition".t("job_requisition")
}

const message = (approved?: boolean): string => {
  if (approved) {
    return "req_is_approved".t("job_requisition")
  }

  return "req_already_sent".t("job_requisition")
}
