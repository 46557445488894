import { NodeData } from "../node/types"

/* eslint-disable no-underscore-dangle */
export const hasHiddenChildren = (d: NodeData): boolean =>
  Boolean(d._children && d._children.length)

export const hasVisibleChildren = (d: NodeData): boolean => Boolean(d.children && d.children.length)

export const hasVisibleAssistants = (d: NodeData): boolean =>
  Boolean(d.assistants && d.assistants.length)

export const hasHiddenAssistants = (d: NodeData): boolean =>
  Boolean(d._assistants && d._assistants.length)

export const hasChildren = (d: NodeData): boolean =>
  hasHiddenChildren(d) || hasVisibleChildren(d) || hasVisibleAssistants(d) || hasHiddenAssistants(d)

export const hasHiddenChildrenOrAssistants = (d: NodeData): boolean =>
  hasHiddenAssistants(d) || hasHiddenChildren(d)

export const hasVisibleChildrenOrAssistants = (d: NodeData): boolean =>
  hasVisibleAssistants(d) || hasVisibleChildren(d)

export const hasStats = (d: NodeData): boolean =>
  !(d.klass === "Company") &&
  ((d.children_count && d.children_count > 0) ||
    d.in_progress ||
    d.has_children === 1 ||
    hasChildren(d))

export const numberOfGrandchildren = (d: NodeData): number => {
  const children = d.children || []

  return children.reduce(
    (count: number, child: NodeData) => count + (child.children ? child.children.length : 0),
    0,
  )
}

export const numberOfChildren = (d: NodeData): number => {
  const children = d.children || []
  return children.length
}

const statsHelpers = Object.freeze({
  hasChildren,
  hasHiddenAssistants,
  hasHiddenChildren,
  hasHiddenChildrenOrAssistants,
  hasStats,
  hasVisibleAssistants,
  hasVisibleChildren,
  hasVisibleChildrenOrAssistants,
  numberOfGrandchildren,
  numberOfChildren,
})

export default statsHelpers
