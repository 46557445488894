import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useState } from "react"

import { Modal } from "v2/react/shared/overlay/Modal"
import { ApprovalActionType } from "v2/react/shared/status/statusUtils"

interface Props {
  approvable: string
  selectedAction: ApprovalActionType | null
  submitterName: string | null | undefined
  isOpen: boolean
  onCloseNoSave: () => void
  onSave: (status: ApprovalActionType, note: string) => Promise<Error[]> | undefined
}

interface ContentDetails {
  title: string
  icon: IconName
  alertClasses: string
  infoBox: string
}

function ApprovalActionModal({
  approvable,
  selectedAction,
  submitterName,
  isOpen,
  onCloseNoSave,
  onSave,
}: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | React.ReactNode[]>()
  const [note, setNote] = useState("")

  const actionModalContent: Record<string, ContentDetails> = {
    approved: {
      title: "approve".t("status_defaults"),
      icon: "circle-check" as IconName,
      alertClasses: "alert--green",
      infoBox: "you_are_approving".t("status_defaults", null, null, null, [approvable]),
    },
    changes_requested: {
      title: "request_changes".t("status_defaults"),
      icon: "triangle-exclamation" as IconName,
      alertClasses: "alert--yellow",
      infoBox: "you_are_requesting_changes".t("status_defaults", null, null, null, [approvable]),
    },
    denied: {
      title: "deny_approval".t("status_defaults"),
      icon: "circle-exclamation" as IconName,
      alertClasses: "alert--red",
      infoBox: "you_are_denying".t("status_defaults", null, null, null, [approvable]),
    },
  }

  const handleSave = async () => {
    setIsLoading(true)
    setError(undefined)
    if (!selectedAction) return
    const response = await onSave(selectedAction, note)

    if (response?.length) {
      const message: React.ReactNode[] = response.map((s: Error) => (
        <span key={s.message}>
          {s.message}
          <br />
        </span>
      ))
      setError(message)
    }

    setNote("")
    setIsLoading(false)
  }

  const handleClose = () => {
    setNote("")
    onCloseNoSave()
  }

  if (!selectedAction || !isOpen) return null

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={actionModalContent[selectedAction].title}>
      <div className="action-modal">
        <div className="p-6">
          {error && (
            <div className="alert alert-critical my-4">
              <span className="">{error}</span>
            </div>
          )}
          <div
            className={cn(
              "action-modal__alert mb-4 items-center gap-x-[6px] !p-4 flex",
              actionModalContent[selectedAction].alertClasses,
            )}
          >
            <FontAwesomeIcon icon={["far", actionModalContent[selectedAction].icon]} />
            <p>{actionModalContent[selectedAction].infoBox}</p>
          </div>
          <label className="font-bold" htmlFor="submitter-note">
            {"note_for_submitter".t("status_defaults")}
          </label>
          <textarea
            className="input max-h-48 min-h-24 resize-y"
            id="submitter-note"
            name="note"
            onChange={(e) => setNote(e.currentTarget.value)}
            value={note}
          />
          {submitterName ? (
            <p>{"this_message".t("status_defaults", null, null, null, [submitterName])}</p>
          ) : null}
        </div>
        <div className="border-t-neutral-8 p-6">
          {isLoading ? (
            <button className="btn--large btn--primary btn-disabled" type="button">
              {"send_disabled".t("button_defaults")}
            </button>
          ) : (
            <button className="btn--large btn--primary" onClick={handleSave} type="button">
              {"send_enabled".t("button_defaults")}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export { ApprovalActionModal }
