import cn from "classnames"
import { TFunction } from "i18next"
import UpDownLoad from "images/up-down-load.svg"
import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { ImportDropdown, ImportDropdownProps } from "v2/react/components/imports/ImportDropdown"
import { selectChartSettings } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppSelector } from "v2/redux/store"

interface Props {
  canExportChart: boolean
  canManageOfficialChart: boolean
  importDropdownProps: ImportDropdownProps
  isOfficialChart: boolean
  onlyLinks?: boolean
  setExportModalIsOpen: Dispatch<SetStateAction<boolean>>
}

function ImportExportButton({
  canExportChart,
  canManageOfficialChart,
  importDropdownProps,
  isOfficialChart,
  onlyLinks,
  setExportModalIsOpen,
}: Props) {
  const { t } = useTranslation()
  const { historyMode } = useAppSelector(selectChartSettings)
  const canImportExport =
    isOfficialChart && canExportChart && canManageOfficialChart && isOfficialChart && !historyMode
  const {
    adpEventsUrl,
    adpUrl,
    initialTriggerState,
    listUrl,
    paylocityUrl,
    photosUrl,
    isolvedUrl,
    adpIhcmUrl,
  } = importDropdownProps

  const menuLinks = (showHr?: boolean) => {
    if (canImportExport) {
      return (
        <>
          {exportButton("menu-link", t, setExportModalIsOpen)}
          {showHr ? <hr className="mx-[-8px] my-2" /> : null}
          <ImportDropdown
            adpEventsUrl={adpEventsUrl}
            adpUrl={adpUrl}
            initialTriggerState={initialTriggerState}
            listUrl={listUrl}
            paylocityUrl={paylocityUrl}
            photosUrl={photosUrl}
            isolvedUrl={isolvedUrl}
            adpIhcmUrl={adpIhcmUrl}
            onlyLinks
          />
        </>
      )
    }

    if (canExportChart) {
      return exportButton("menu-link", t, setExportModalIsOpen)
    }

    return null
  }

  // This provides the menu links without the menu-trigger button
  if (onlyLinks) return menuLinks()

  if (canImportExport) {
    return (
      <div className="dropdown importexport-dropdown">
        <button
          type="button"
          className="btn--large btn--secondary tooltip tooltip-right dropdown-link w-10"
        >
          <img src={UpDownLoad} alt={t("v2.orgchart.pagenav.import_export")} />
          <span className="tooltip-content tooltip-content--sm">
            {t("v2.orgchart.pagenav.import_export")}
          </span>
        </button>

        <div className="dropdown-menu left-auto right-0 z-20">{menuLinks(true)}</div>
      </div>
    )
  }
  if (canExportChart) {
    return exportButton("button", t, setExportModalIsOpen)
  }

  return null
}

export { ImportExportButton }

const exportButton = (
  type: "menu-link" | "button",
  t: TFunction,
  setExportModalIsOpen: Dispatch<SetStateAction<boolean>>,
) => (
  <button
    className={cn("export", {
      "btn--large btn--secondary tooltip tooltip-right export w-10": type === "button",
      "dropdown-menu-link export": type === "menu-link",
    })}
    onClick={() => setExportModalIsOpen(true)}
    type="button"
  >
    <i className="far fa-download" />
    <span
      className={cn({
        "tooltip-content tooltip-content--sm": type === "button",
        "ml-2": type === "menu-link",
      })}
    >
      {t("v2.orgchart.pagenav.export")}
    </span>
  </button>
)
