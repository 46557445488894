import { EntityId } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import { createSelector, createStructuredSelector } from "reselect"

import { RootState } from "v2/redux/store"

import { inEitherWriteState, onNothing, writingOnEditableCellWithInitial } from "./cursorStates"

const withId = (_: RootState, id: EntityId) => id
const withColumnId = (_: RootState, _2: EntityId, columnId: string) => columnId

const selectCursor = (state: RootState) => state.datasheet.cursor

const selectCursorRowId = createSelector(selectCursor, (cursor) =>
  onNothing(cursor) ? undefined : cursor.rowId,
)

const selectCursorColId = createSelector(selectCursor, (cursor) =>
  onNothing(cursor) ? undefined : cursor.columnId,
)

const selectCursorIfWritingAndOnCell = createSelector(
  [selectCursor, withId, withColumnId],
  (cursor, id, columnId) =>
    inEitherWriteState(cursor) && cursor.rowId === id && cursor.columnId === columnId
      ? cursor
      : undefined,
)

const selectInitialOrRestorableValue = createSelector(
  [selectCursorIfWritingAndOnCell, (state: RootState) => state.datasheet.restorableCursorValue],
  (cursor, restorableValue) => {
    if (!fp.isUndefined(cursor) && inEitherWriteState(cursor) && !fp.isUndefined(restorableValue))
      return restorableValue
    if (!fp.isUndefined(cursor) && writingOnEditableCellWithInitial(cursor)) return cursor.initial
    return undefined
  },
)

const selectCellFocused = (state: RootState, rowId: EntityId, columnId: string) => {
  const cursor = selectCursor(state)
  return !onNothing(cursor) && fp.matches({ rowId, columnId }, cursor)
}

const makeSelectCursorStateForCell = (rowId: EntityId, columnId: string) =>
  createStructuredSelector({
    cursorIfInWrite: (state: RootState) => selectCursorIfWritingAndOnCell(state, rowId, columnId),
    inCursor: (state: RootState) => selectCellFocused(state, rowId, columnId),
    initialValue: (state: RootState) => selectInitialOrRestorableValue(state, rowId, columnId),
    inWrite: (state: RootState) => !!selectCursorIfWritingAndOnCell(state, rowId, columnId),
  })

export { makeSelectCursorStateForCell, selectCursor, selectCursorRowId, selectCursorColId }
