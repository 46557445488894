import React from "react"

export type EditingCellHandle = {
  save: () => void
  contains: (target: EventTarget | null) => boolean
}

export function useEditingCellRegistry() {
  const editingCellRef = React.useRef<React.RefObject<EditingCellHandle> | null>(null)
  const registerEditingCell = React.useCallback((ref: React.RefObject<EditingCellHandle>) => {
    editingCellRef.current = ref
  }, [])
  const unregisterEditingCell = React.useCallback(() => {
    editingCellRef.current = null
  }, [])

  useHandleClickOutsideEditingCell(editingCellRef)

  return { registerEditingCell, unregisterEditingCell }
}

function useHandleClickOutsideEditingCell(
  editingCellRef: React.MutableRefObject<React.RefObject<EditingCellHandle> | null>,
) {
  React.useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      const cell = editingCellRef.current?.current
      if (cell && !cell.contains(e.target)) {
        cell.save()
      }
    }
    document.addEventListener("mousedown", handleMouseDown)
    return () => document.removeEventListener("mousedown", handleMouseDown)
  }, [editingCellRef])
}
