import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"

import { useFormStyleContext } from "./FormStyleProvider"

type InputErrorTextProps = {
  className?: string
  message: string
}

const InputErrorText = ({ className, message }: InputErrorTextProps) => {
  const { defaultErrorIconPlacement } = useFormStyleContext()
  const placementClassName = defaultErrorIconPlacement === "center" ? "items-center" : "items-start"

  return (
    <div className={cn("mt-1 gap-1 text-status-critical flex", placementClassName, className)}>
      <FontAwesomeIcon
        icon={["far", "circle-exclamation"]}
        className={cn("text-inherit", defaultErrorIconPlacement === "top" && "translate-y-[25%]")}
      />
      <span>{message}</span>
    </div>
  )
}

export { InputErrorText }
