import { useEffect, useRef } from "react"

interface UseNProgressBarProps {
  finishCondition: boolean
  startCondition: boolean
}

const useNProgressBar = ({ finishCondition, startCondition }: UseNProgressBarProps) => {
  const didStartProgressRef = useRef(false)

  useEffect(() => {
    if (typeof window === "undefined") return
    if (typeof window.NProgress === "undefined") return

    if (startCondition && !didStartProgressRef.current && !finishCondition) {
      window.NProgress?.start?.()
      didStartProgressRef.current = true
    }

    if (finishCondition && didStartProgressRef.current) {
      window.NProgress?.done?.()
    }

    // Always ensure done is called when we unmount.
    // eslint-disable-next-line consistent-return
    return () => {
      if (didStartProgressRef.current) {
        window?.NProgress?.done?.()
      }
    }
  }, [finishCondition, startCondition])
}

export { useNProgressBar }
