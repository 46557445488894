import React from "react"
import { useTranslation } from "react-i18next"

import type { JobRequisitionInfoQuery } from "types/graphql"

type StandardProps = {
  req: JobRequisitionInfoQuery["jobRequisition"]
}

const Standard = ({ req }: StandardProps) => {
  const { t } = useTranslation()
  if (!req) return null

  return (
    <dl className="show">
      <dt>{t("v2.job_requisitions.modals.show.external_req_id")}</dt>
      <dd>{req.clientIdentifier}</dd>
      <dt>{t("v2.job_requisitions.modals.show.title")}</dt>
      <dd>{req.jobTitle}</dd>
      <dt>{t("v2.job_requisitions.modals.show.status")}</dt>
      <dd>{req.statusLabel}</dd>
      <dt>{t("v2.job_requisitions.modals.show.hiring_manager")}</dt>
      <dd>{req.hiringManagerName || "-"}</dd>
      {req.isAdp && (
        <div className="pt-4">
          <dt>{t("v2.job_requisitions.modals.show.salary_plan")}</dt>
          <dd>{req.salaryPlan || "-"}</dd>
          <dt>{t("v2.job_requisitions.modals.show.salary_grade")}</dt>
          <dd>{req.salaryGrade || "-"}</dd>
        </div>
      )}
      <dt>{t("v2.job_requisitions.modals.show.pay_range")}</dt>
      <dd>{req.compensationLabel}</dd>
      <dt>{t("v2.job_requisitions.modals.show.department")}</dt>
      <dd>{req.departmentName || "-"}</dd>
      <dt>{t("v2.job_requisitions.modals.show.location")}</dt>
      <dd>{req.locationLabel}</dd>
    </dl>
  )
}

export { Standard }
