type InitSmartRecruitersSuccess = {
  integration: "smart_recruiters"
  success: true
  data: null
  schema: null
}

/**
 * The purpose of this function is just to tap into the existing flow in
 * `SendToAts.tsx` to show the SmartRecruiters modal.
 */
const init = (): InitSmartRecruitersSuccess => ({
  integration: "smart_recruiters",
  success: true,
  data: null,
  schema: null,
})

export type { InitSmartRecruitersSuccess }
export { init }
