import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { ReactNode } from "react"

import { InputErrorText } from "v2/react/shared/forms/InputErrorText"
import { InputLabel } from "v2/react/shared/forms/InputLabel"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

import { useReactHookFormFieldError } from "./hooks/useWithReactHookFormHooks"
import { UseInReactHookFormProp } from "./types"

interface InputWrapperProps {
  children: React.ReactNode
  label?: ReactNode
  errors?: string
  showErrorMessage?: boolean
  renderErrors?: (errors: string) => React.ReactNode
  id?: string
  className?: string
  combineClassNames?: boolean
  name?: string
  onMouseEnterLabel?: React.MouseEventHandler
  onMouseLeaveLabel?: React.MouseEventHandler
  useInReactHookForm?: UseInReactHookFormProp
  infoMessage?: string
}

const InputWrapper = ({
  children,
  label,
  errors,
  showErrorMessage = true,
  id,
  className,
  combineClassNames = true,
  name,
  renderErrors,
  onMouseEnterLabel,
  onMouseLeaveLabel,
  useInReactHookForm,
  infoMessage,
}: InputWrapperProps) => {
  const rhfError = useReactHookFormFieldError({ name, useInReactHookForm })
  const errorMessage = errors || rhfError

  return (
    <div
      className={classNames(
        { "form-error": combineClassNames && errorMessage, "input-wrapper": combineClassNames },
        className,
      )}
    >
      <div className="items-center gap-2 flex">
        {label && (
          <InputLabel
            id={id}
            label={label}
            onMouseEnter={onMouseEnterLabel}
            onMouseLeave={onMouseLeaveLabel}
          />
        )}
        {infoMessage && (
          <Tooltip>
            <TooltipTrigger className="mb-1">
              <FontAwesomeIcon icon={["far", "info-circle"]} />
            </TooltipTrigger>
            <TooltipContent className="react-tooltip-content--sm">{infoMessage}</TooltipContent>
          </Tooltip>
        )}
      </div>

      {children}
      {errorMessage && showErrorMessage && renderErrors && renderErrors(errorMessage)}
      {errorMessage && showErrorMessage && !renderErrors && (
        <InputErrorText message={errorMessage} />
      )}
    </div>
  )
}

export { InputWrapper }
