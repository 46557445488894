import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table"
import { clamp, flow } from "lodash"
import React from "react"

import { maybeAddProperty } from "v2/react/utils/misc"

import { Column } from "../types"
import {
  APPROXIMATE_HEADER_PADDING_H,
  APPROXIMATE_SORT_ICON_WIDTH,
  COLUMN_RANGES,
} from "../utils/constants"

export function useTableDatasheet<TRow extends { id: string }>(
  rows: TRow[],
  columns: Column<TRow>[],
) {
  const data = React.useMemo(() => rows, [rows])

  const columnDefs = React.useMemo(
    () =>
      columns.map((column) =>
        flow(
          (col) => maybeAddProperty(col, "id", column.id),
          (col) => maybeAddProperty(col, "header", column.label),
          (col) => maybeAddProperty(col, "enableHiding", column.hidden),
          (col) => maybeAddProperty(col, "enableColumnFilter", column.enableFiltering),
          (col) => maybeAddProperty(col, "enableGrouping", column.enableGrouping),
          (col) => maybeAddProperty(col, "enableSorting", column.enableSorting),
          (col) => maybeAddProperty(col, "filterFn", column.filterFn),
          (col) => maybeAddProperty(col, "accessorFn", column.accessorFn),
          (col) => maybeAddProperty(col, "accessorKey", column.accessorFn ? undefined : column.id),
          (col) => maybeAddProperty(col, "meta", { original: column }),
          (col) => maybeAddProperty(col, "size", getApproximateWidthWithinBounds(column)),
          (col) => maybeAddProperty(col, "sortDescFirst", column.sortDescFirst),
          (col) => maybeAddProperty(col, "getPossibleFilterValues", column.getPossibleFilterValues),
          (col) =>
            maybeAddProperty(col, "sortingFn", column.enableSorting ? column.sortingFn : undefined),
        )({}),
      ),
    [columns],
  )

  const table = useReactTable({
    data,
    columns: columnDefs,
    initialState: {
      expanded: true,
    },
    state: {
      columnVisibility: columns.reduce((acc, column) => {
        if (column.hidden) return { ...acc, [column.id]: false }
        return acc
      }, {} as VisibilityState),
    },
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    groupedColumnMode: false,
  })

  return table
}

// https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
function getApproximateWidthWithinBounds<TRow extends { id: string }>(column: Column<TRow>) {
  const text = column.label
  const range = COLUMN_RANGES[column.width]

  const headerFont = "700 0.8125rem Satoshi, sans-serif"
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  if (!context) return range[0]

  context.font = headerFont
  let columnWidth = context.measureText(text).width + APPROXIMATE_HEADER_PADDING_H
  if (column.enableSorting) columnWidth += APPROXIMATE_SORT_ICON_WIDTH

  return clamp(columnWidth, ...range)
}
