import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, PositionType } from "types/graphql"
import { useEditPositionType } from "v2/react/components/positionTypes/hooks/useEditPositionType"
import {
  RequirementsModal,
  RequirementsTags,
} from "v2/react/components/positionTypes/modals/RequirementsModal"
import { ActionBanner } from "v2/react/components/positionTypes/Show/ActionBanner"
import { AlertBanner } from "v2/react/components/positionTypes/Show/AlertBanner"
import { EmptyContent, EmptyState } from "v2/react/components/positionTypes/Show/EmptyState"
import { SmallEditButton } from "v2/react/components/positionTypes/Show/SmallEditButton"
import { TagListDisplay } from "v2/react/components/positionTypes/Show/TagListDisplay"
import { useTagsWithFieldSuggestion } from "v2/react/hooks/useTypedFieldWithSuggestion"
import { normalizedDomainTagsProp } from "v2/react/hooks/useTypedFieldWithSuggestion/tagFieldHelpers"
import { Pulser } from "v2/react/shared/loaders/Pulser"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

interface RequirementsProps {
  positionType: PositionType
  featureFlags: FeatureFlags
}

type CombinedTagsArg = {
  addError: (message: string) => void
  positionType: PositionType
  hasAiFeatureFlag: boolean
}

function useCombinedTagsFields({ addError, positionType, hasAiFeatureFlag }: CombinedTagsArg) {
  const skillState = useTagsWithFieldSuggestion({
    addGenerateError: addError,
    hasAiFeatureFlag,
    record: positionType,
    field: "skills",
    getActual: normalizedDomainTagsProp("skills", "tags"),
    getUniqueKey: (record) => record.uniqueKey ?? `position_type_${record.id}`,
  })

  const characteristicsState = useTagsWithFieldSuggestion({
    addGenerateError: addError,
    hasAiFeatureFlag,
    record: positionType,
    field: "worker_characteristics",
    getActual: normalizedDomainTagsProp("worker_characteristics", "tags"),
    getUniqueKey: (record) => record.uniqueKey ?? `position_type_${record.id}`,
  })

  return {
    skillState,
    characteristicsState,
    characteristicsTags: characteristicsState.value,
    isInitializing: skillState.isInitializing || characteristicsState.isInitializing,
    isAwaitingAction: skillState.isAwaitingAction || characteristicsState.isAwaitingAction,
    skillsTags: skillState.value,
    initializeFailed: skillState.isInitializeFailed || characteristicsState.isInitializeFailed,
  }
}

const Requirements = ({ positionType, featureFlags }: RequirementsProps) => {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { t } = useTranslation()

  const {
    acceptFieldSuggestions,
    addError,
    cancelGeneratingFieldSuggestions,
    declineFieldSuggestions,
    errors,
    generateFieldSuggestions,
    initializeFieldSuggestions,
    isUpdatingPositionType,
    reset: resetEditState,
    updatePositionType,
  } = useEditPositionType(positionType)
  const {
    characteristicsState,
    characteristicsTags,
    initializeFailed,
    isAwaitingAction,
    isInitializing,
    skillsTags,
    skillState,
  } = useCombinedTagsFields({
    addError,
    positionType,
    hasAiFeatureFlag: Boolean(featureFlags.aiGeneration),
  })

  const handleClose = () => {
    resetEditState()
    cancelGeneratingFieldSuggestions(["skills", "worker_characteristics"])
    setEditModalOpen(false)
  }
  const handleEdit = () => setEditModalOpen(true)
  const handleInitializeRequirements = () => {
    const characteristicsCanInitialize =
      characteristicsState.isInitializeFailed || characteristicsState.isInitializing
    const skillsCanInitialize = skillState.isInitializeFailed || skillState.isInitializing
    if (characteristicsCanInitialize && skillsCanInitialize)
      initializeFieldSuggestions(["skills", "worker_characteristics"])
    else if (characteristicsCanInitialize) initializeFieldSuggestions(["worker_characteristics"])
    else if (skillsCanInitialize) initializeFieldSuggestions(["skills"])
  }
  const handleSave = (data: RequirementsTags, removeLinkedRequirements: boolean) =>
    updatePositionType({
      includeBlankValuesInPositionTypeAttributesSync: removeLinkedRequirements,
      skills: data.skills.map((skill) => skill.label),
      workerCharacteristics: data.characteristics.map((characteristic) => characteristic.label),
    })

  return (
    <>
      <div className="module-card" data-testid="requirements-module">
        <div className="module-card__header">
          <div className="module-title">{t("v2.position_types.show.requirements")}</div>
          {(skillsTags.length > 0 || characteristicsTags.length > 0) &&
            (!isAwaitingAction || !featureFlags.aiGeneration) && (
              <SmallEditButton onClick={handleEdit} />
            )}
        </div>
        {featureFlags.aiGeneration && initializeFailed && isAwaitingAction && (
          <AlertBanner handleGenerate={handleInitializeRequirements} />
        )}
        {featureFlags.aiGeneration && (skillsTags.length > 0 || characteristicsTags.length > 0) && (
          <ActionBanner
            awaitingAction={isAwaitingAction}
            fields={["skills", "worker_characteristics"]}
            onAcceptFieldSuggestions={acceptFieldSuggestions}
            onDeclineFieldSuggestions={declineFieldSuggestions}
            onEdit={handleEdit}
          />
        )}
        <div
          className={cn("module-card__body relative", {
            "border-t-0": isAwaitingAction && skillsTags.length,
          })}
        >
          {!skillsTags.length &&
          !characteristicsTags.length &&
          isInitializing &&
          isAwaitingAction &&
          Boolean(featureFlags.aiGeneration) ? (
            <Pulser testId="requirements-initializing-indicator" />
          ) : (
            <>
              <div className="items-center gap-2 flex">
                <FontAwesomeIcon icon={["far", "pencil-ruler"]} />
                {t("v2.position_types.show.skills")}
              </div>
              {skillsTags.length ? (
                <TagListDisplay tagList={skillsTags} />
              ) : (
                <EmptySkills
                  handleEdit={handleEdit}
                  isAiAvailable={Boolean(featureFlags.aiGeneration)}
                />
              )}
              <hr className="mx-[-1rem] my-0" />
              <div className="items-center gap-2 flex">
                <FontAwesomeIcon icon={["far", "heart"]} />
                {t("v2.position_types.show.characteristics")}
              </div>
              {characteristicsTags.length ? (
                <TagListDisplay tagList={characteristicsTags} />
              ) : (
                <EmptyCharacteristics
                  handleEdit={handleEdit}
                  isAiAvailable={Boolean(featureFlags.aiGeneration)}
                />
              )}
            </>
          )}
        </div>
      </div>
      <RequirementsModal
        canEditCharacteristics
        canEditSkills
        characteristicTags={characteristicsTags}
        contentType="definition"
        errors={errors}
        excludeRecord={positionType.uniqueKey ?? `position_type_${positionType.id}`}
        isAiAvailable={Boolean(featureFlags.aiGeneration)}
        isOpen={editModalOpen}
        isSaving={isUpdatingPositionType}
        modalTitle={t("v2.position_types.show.edit_job_definition_requirements")}
        onClose={handleClose}
        onRegenerateFieldSuggestion={generateFieldSuggestions}
        onSave={handleSave}
        record={positionType}
        skillTags={skillsTags}
        uniqueKey={positionType.uniqueKey ?? `position_type_${positionType.id}`}
        hasAiFeatureFlag={Boolean(featureFlags.aiGeneration)}
      />
    </>
  )
}

export { Requirements }

const EmptySkills = ({
  handleEdit,
  isAiAvailable,
}: {
  handleEdit: () => void
  isAiAvailable: boolean
}) => {
  const { t } = useTranslation()
  const noteI18nType = isAiAvailable ? "ai_add_note_html" : "add_note_html"
  const icon = isAiAvailable ? "sparkles" : "edit"

  const emptySkills: EmptyContent = {
    buttonText: `${t("v2.defaults.add")} ${t("v2.position_types.show.skills")}`,
    note: sanitizeParse(
      t(`v2.position_types.show.${noteI18nType}`, {
        count: 2,
        property: t("v2.position_types.show.skills").toLowerCase(),
      }),
    ),
    icon,
    onClick: handleEdit,
  }

  return (
    <EmptyState
      buttonText={emptySkills.buttonText}
      note={emptySkills.note}
      icon={emptySkills.icon}
      onClick={emptySkills.onClick}
    />
  )
}

const EmptyCharacteristics = ({
  handleEdit,
  isAiAvailable,
}: {
  handleEdit: () => void
  isAiAvailable: boolean
}) => {
  const { t } = useTranslation()

  const noteI18nType = isAiAvailable ? "ai_add_note_html" : "add_note_html"
  const icon = isAiAvailable ? "sparkles" : "edit"
  const emptyCharacteristics: EmptyContent = {
    buttonText: `${t("v2.defaults.add")} ${t("v2.position_types.show.characteristics")}`,
    note: sanitizeParse(
      t(`v2.position_types.show.${noteI18nType}`, {
        count: 2,
        property: t("v2.position_types.show.characteristics").toLowerCase(),
      }),
    ),
    icon,
    onClick: handleEdit,
  }

  return (
    <EmptyState
      buttonText={emptyCharacteristics.buttonText}
      note={emptyCharacteristics.note}
      icon={emptyCharacteristics.icon}
      onClick={emptyCharacteristics.onClick}
    />
  )
}
