import { isEmpty } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"

import { ProcessedData } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/utils"
import { useDateFormatter } from "v2/react/hooks/useDateFormatter"

interface PositionsFieldsProps {
  positions: ProcessedData["auxiliary"]["positions"]
}

const PositionsFields = ({ positions }: PositionsFieldsProps) => {
  const { t } = useTranslation()
  const { parseAndFormat } = useDateFormatter({
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })

  if (isEmpty(positions)) return null
  const showIncumbent = positions.some((position) => position.type === "REPLACEMENT")
  const showTargetStartDate = positions.some((position) => !isEmpty(position.targetStartDate))

  return (
    <div className="table !elevation bg-transparent text-neutral-100">
      <table>
        <thead className="bg-primary-3">
          <tr>
            <th>
              {t("v2.job_requisitions.modals.show.smart_recruiters.position_openings.position_id")}
            </th>
            <th>
              {t(
                "v2.job_requisitions.modals.show.smart_recruiters.position_openings.position_type",
              )}
            </th>
            {showIncumbent && (
              <th>
                {t("v2.job_requisitions.modals.show.smart_recruiters.position_openings.incumbent")}
              </th>
            )}
            {showTargetStartDate && (
              <th>
                {t(
                  "v2.job_requisitions.modals.show.smart_recruiters.position_openings.target_start_date",
                )}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {positions.map((position, index) => (
            <tr
              // eslint-disable-next-line react/no-array-index-key
              key={`position-${position.systemIdentifier}-${position.type}-${position.targetStartDate}-${index}`}
            >
              <td>{position.systemIdentifier}</td>
              <td>
                {!isEmpty(position.type)
                  ? t(
                      `v2.job_requisitions.modals.show.smart_recruiters.position_openings.type.${position.type.toLowerCase()}`,
                    )
                  : null}
              </td>
              {showIncumbent && <td>{position.incumbentName}</td>}
              {showTargetStartDate && (
                <td>
                  {!isEmpty(position.targetStartDate) && parseAndFormat(position.targetStartDate)}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export { PositionsFields }
