import { get } from "lodash"

import { GenericFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/GenericFormShape"
import { PosigenFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/PosigenFormShape"
import { formatCurrency } from "v2/react/utils/currency"

type VariablePayTypeData = { amount: number | null; id: string; name: string; type: string }

const annualBasePay = (
  basePayTypeId: string,
  basePayAmount: number,
  hoursPerWeek: number,
): number => {
  if (basePayTypeId === "position_base_pay_type_hourly") {
    const weeksInYear = 52
    return basePayAmount * hoursPerWeek * weeksInYear
  }
  return basePayAmount
}

const totalCompensation = (
  basePayTypeId: string,
  basePayAmount: number,
  hoursPerWeek: number,
  variablePayTypes: VariablePayTypeData[],
): number => {
  const annualBasePayAmount = Math.round(annualBasePay(basePayTypeId, basePayAmount, hoursPerWeek))
  return (
    annualBasePayAmount + Math.round(variablePayTotalAmount(annualBasePayAmount, variablePayTypes))
  )
}

const variablePayTotalAmount = (
  annualBasePay: number,
  variablePayTypes: VariablePayTypeData[],
): number =>
  (variablePayTypes || []).reduce(
    (total: number, payType: VariablePayTypeData) =>
      total + variablePayComputedAmount(annualBasePay, payType.type, payType.amount),
    0,
  )

const variablePayComputedAmount = (
  annualBasePay: number,
  variablePayTypeType: string,
  variablePayTypeAmount: number | null,
): number => {
  if (variablePayTypeType === "variable_pay_pay_type_percent") {
    const percentageAsFloat = (variablePayTypeAmount || 0) / 100
    return annualBasePay * percentageAsFloat
  }
  return variablePayTypeAmount || 0
}

const basePayLabel = (
  basePayTypeId: string,
  amount: number | null,
  hours: number | null,
): string => {
  if (basePayTypeId === "position_base_pay_type_hourly") {
    if (amount === null) return "- (Hourly)"
    return `${formatCurrency({ value: amount })} (Hourly), ${hours || "0"} hrs/wk`
  }
  if (amount === null) return "- (Salary)"
  return `${formatCurrency({ value: amount })} (Salary)`
}

const variablePayLabel = (
  annualBasePay: number,
  variablePayTypeType: string,
  variablePayTypeAmount: number | null,
): string => {
  const computedAmount: number = variablePayComputedAmount(
    annualBasePay,
    variablePayTypeType,
    variablePayTypeAmount,
  )
  if (variablePayTypeType === "variable_pay_pay_type_amount") {
    return `${formatCurrency({ value: computedAmount })}`
  }
  return `${formatCurrency({ value: computedAmount })} (${variablePayTypeAmount}%)`
}

const compensationLabelPrefix = (formData: GenericFormShape | PosigenFormShape): string => {
  const reqType = get(formData, ["reqType"])
  return reqType === "new_position" ? "Proposed" : ""
}

export {
  annualBasePay,
  compensationLabelPrefix,
  basePayLabel,
  totalCompensation,
  variablePayComputedAmount,
  variablePayLabel,
}
