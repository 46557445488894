import classNames from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import { usePositionSearch } from "v2/react/hooks/usePositionSearch"
import { AutocompleteResult } from "v2/react/shared/forms/AutocompleteResult"
import { isPresent } from "v2/react/utils/misc"

import { RenderItemFn, Search, SearchInput, SearchList } from "./Search"

interface PositionSearchInputProps {
  errorMessage?: string
  onSelect?: (selectedPosition: Position) => void
}

function PositionSearchInput({ errorMessage, onSelect }: PositionSearchInputProps) {
  const [inputValue, setInputValue] = useState("")
  const { t } = useTranslation()
  const { positions, returnEmpty, searchTerm } = usePositionSearch({
    filter: inputValue.trim(),
    chartKey: `chart_${window.gon.official_chart_id}`,
  })

  const renderItem: RenderItemFn<(typeof positions)[0]> = ({
    item,
    index,
    activeIndex,
    listRef,
    getItemProps,
    handleResultClick,
  }) => (
    <AutocompleteResult
      key={item.uniqueKey}
      position={item}
      isSelected={activeIndex === index}
      className={positionClassName(activeIndex, index)}
      searchTerm={searchTerm}
      refFn={(node: HTMLDivElement | null) => {
        // eslint-disable-next-line no-param-reassign
        listRef.current[index] = node
      }}
      getItemProps={getItemProps}
      handleResultClick={handleResultClick}
    />
  )

  const showError = isPresent(errorMessage)

  return (
    <Search
      className="position-input"
      items={positions}
      setInputValue={setInputValue}
      inputValue={inputValue}
      onSelect={onSelect}
      getId={(position) => position.uniqueKey}
      getLabel={getPositionLabel}
    >
      <div className={classNames({ "form-error show": showError })}>
        <SearchInput
          id="position_search_input"
          placeholder={`${t("v2.defaults.example_abbreviated")}: ${t("v2.defaults.example_name")}`}
        />
        {showError && <span className="form-error-message">{errorMessage}</span>}
      </div>
      <SearchList
        renderItem={renderItem}
        showEmptyMessage={!returnEmpty}
        emptyMessage={t("v2.positions.search.no_positions_found")}
        outerClassName="AutocompleteField"
      />
    </Search>
  )
}

const positionClassName = (activeIndex: number | null, index: number) =>
  classNames("AutocompleteField__result", { highlight: activeIndex === index })

const getPositionLabel = (position: Position) => {
  const people = position.people
  const peopleNames = people?.map((person) => person.name).join(", ")
  const positionTitle = position.title
  const positionSystemID = position.systemIdentifier
  return [peopleNames, positionTitle || positionSystemID].filter((n) => n).join(", ")
}

export { PositionSearchInput }
