import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useState } from "react"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { getCookie } from "v2/react/utils/cookies"

interface NavLinkProps {
  id: string
  url: string
  label: string
  icon?: IconProp
  active?: boolean
}

function NavLink({ id, url, label, icon, active }: NavLinkProps) {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleTooltip = () => {
    if (getCookie("nav_open_state") === "true") return
    setShowTooltip(true)
  }

  return (
    <Tooltip open={showTooltip} placement="right" gapSize={12}>
      <a className={cn("nav-link", id, { active })} href={url}>
        <TooltipTrigger
          onMouseEnter={handleTooltip}
          onMouseLeave={() => setShowTooltip(false)}
          className="w-full block"
        >
          <div className="nav-link--inner">
            <span>{icon && <FontAwesomeIcon icon={icon} />}</span>
            <p className="hide">{label}</p>
          </div>
        </TooltipTrigger>
      </a>

      <TooltipContent className="react-tooltip-content--sm">
        <span className="break-all">{label}</span>
      </TooltipContent>
    </Tooltip>
  )
}

export type { NavLinkProps }
export { NavLink }
