import React from "react"

import { withRootProvider } from "v2/react/components/RootProvider"
import PageNav from "v2/react/shared/navigation/PageNav"
import { LinkBlock } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { Link, LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlock } from "v2/react/shared/navigation/PageNav/TitleBlock"

interface Props {
  links: Link[]
  title: string
}

function Nav({ links, title }: Props) {
  return (
    <PageNav>
      <TitleBlock>
        <h1>{title}</h1>
      </TitleBlock>
      <LinkBlock>
        <LinkGroup links={links} usePjax />
      </LinkBlock>
    </PageNav>
  )
}

const SettingsNav = withRootProvider(Nav, "SettingsNav")

export { SettingsNav }
