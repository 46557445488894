import axios from "axios"
import { UrlHelper } from "react/utils/urls"

export const EXPORT_VIEW_DATA = "EXPORT_VIEW_DATA"
export const EXPORT_VIEW_DATA_SUCCESS = "EXPORT_VIEW_DATA_SUCCESS"

const EXPORT_API = "/api/app/v1/org_chart"

function exportViewData(viewData) {
  return {
    type: EXPORT_VIEW_DATA,
    positionIds: viewData,
  }
}

function exportViewDataSuccess() {
  return {
    type: EXPORT_VIEW_DATA_SUCCESS,
  }
}

function preparePostData(data, selectedExportColumns = []) {
  return {
    chartOptions: JSON.stringify({
      root_node_klass: "Company",
      position_ids: data,
      sort_order: "position_ids",
    }),
    fields: selectedExportColumns,
  }
}

export function exportAsCSV(visibleIds, selectedExportColumns = []) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(exportViewData(visibleIds))

      axios
        .post(EXPORT_API, preparePostData(visibleIds, selectedExportColumns))
        .then((response) => {
          // 202 (accepted) indicates that the export is running async
          if (response.status === 202) {
            window.location.href = UrlHelper.exportsPath()
            return
          }

          window.location.href = `${EXPORT_API}.csv`
        })
        .then(() => {
          dispatch(exportViewDataSuccess())
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
}
