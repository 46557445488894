import React from "react"
import { useTranslation } from "react-i18next"
import { z } from "zod"

import { JobRequisitionInfoQuery } from "types/graphql"
import { useDateFormatter } from "v2/react/hooks/useDateFormatter"
import { Notice } from "v2/react/shared/status/Notice"
import { titleize } from "v2/react/utils/strings"

const DateSchema = z
  .string()
  .datetime({ offset: true })
  .transform((date) => date.split("T")[0])

const SmartRecruitersSourceMeta = z.object({
  location_label: z.string().nullish(),
  industry: z.string().nullish(),
  function: z.string().nullish(),
  experience_level: z.string().nullish(),
  pay_range_currency: z.string().nullish(),
  position_openings: z
    .array(
      z.object({
        source_identifier: z.string().nullish(),
        position_id: z.string().nullish(),
        incumbent_name: z.string().nullish(),
        position_open_date: DateSchema.nullish(),
        target_start_date: DateSchema.nullish(),
        status: z.string().nullish(),
        type: z.string().nullish(),
      }),
    )
    .nullish(),
})

type SmartRecruitersSourceMeta = z.infer<typeof SmartRecruitersSourceMeta>

type SmartRecruitersProps = {
  req: JobRequisitionInfoQuery["jobRequisition"]
}

/**
 * A req details modal specific to Smart Recruiters. Mimics the pjax version.
 * @see app/views/v2/job_requisitions/modals/show/smart_recruiters.html.slim
 */
const SmartRecruiters = ({ req }: SmartRecruitersProps) => {
  const { t } = useTranslation()
  if (!req) return null

  const parsedSourceMeta = SmartRecruitersSourceMeta.safeParse(req.sourceMeta)
  if (!parsedSourceMeta.success) {
    return (
      <Notice type="critical" icon={["far", "triangle-exclamation"]}>
        {t("v2.defaults.error")}
      </Notice>
    )
  }

  const { data: sourceMeta } = parsedSourceMeta
  const {
    compensationLabel,
    totalOpenings,
    clientIdentifier,
    jobTitle,
    hiringManagerName,
    statusLabel,
    departmentName,
  } = req

  return (
    <>
      <dl className="show m-0 grid-cols-1 gap-4 grid 480:grid-cols-2 md:grid-cols-3">
        <Item>
          <dt>{t("v2.job_requisitions.modals.show.smart_recruiters.id")}</dt>
          <dd>{clientIdentifier || t("v2.defaults.dash")}</dd>
        </Item>
        <Item>
          <dt>{t("v2.job_requisitions.modals.show.smart_recruiters.title")}</dt>
          <dd>{jobTitle || t("v2.defaults.dash")}</dd>
        </Item>
        <Item>
          <dt>{t("v2.job_requisitions.modals.show.hiring_manager")}</dt>
          <dd>{hiringManagerName || t("v2.defaults.dash")}</dd>
        </Item>
        <Item>
          <dt>{t("v2.job_requisitions.modals.show.smart_recruiters.status")}</dt>
          <dd>{statusLabel || t("v2.defaults.dash")}</dd>
        </Item>
        <Item>
          <dt>{t("v2.job_requisitions.modals.show.smart_recruiters.opened_positions")}</dt>
          <dd>{totalOpenings}</dd>
        </Item>
        <Item>
          <dt>{t("v2.job_requisitions.modals.show.smart_recruiters.compensation")}</dt>
          <dd>{compensationLabel || t("v2.defaults.dash")}</dd>
        </Item>
        <Item>
          <dt>{t("v2.job_requisitions.modals.show.department")}</dt>
          <dd>{departmentName || t("v2.defaults.dash")}</dd>
        </Item>
        <Item>
          <dt>{t("v2.job_requisitions.modals.show.smart_recruiters.location")}</dt>
          {/* We choose to always show the bespoke location label, even if it's
        connected to an internal location. */}
          <dd>{sourceMeta?.location_label || t("v2.defaults.dash")}</dd>
        </Item>
        <Item>
          <dt>{t("v2.job_requisitions.modals.show.smart_recruiters.industry")}</dt>
          <dd>{sourceMeta?.industry || t("v2.defaults.dash")}</dd>
        </Item>
        <Item>
          <dt>{t("v2.job_requisitions.modals.show.smart_recruiters.function")}</dt>
          <dd>{sourceMeta?.function || t("v2.defaults.dash")}</dd>
        </Item>
        <Item>
          <dt>{t("v2.job_requisitions.modals.show.smart_recruiters.experience_level")}</dt>
          <dd>{sourceMeta?.experience_level || t("v2.defaults.dash")}</dd>
        </Item>
      </dl>
      <PositionOpeningsTable positionOpenings={sourceMeta?.position_openings} />
    </>
  )
}

const Item = ({ children }: { children: React.ReactNode }) => (
  <div className="flex-col flex">{children}</div>
)

const PositionOpeningsTable = ({
  positionOpenings,
}: {
  positionOpenings: SmartRecruitersSourceMeta["position_openings"]
}) => {
  const { t } = useTranslation()
  const { parseAndFormat } = useDateFormatter({
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
  if (!positionOpenings || positionOpenings.length === 0) return null

  return (
    <div className="table mt-6">
      <table>
        <thead>
          <tr>
            <th>
              {t("v2.job_requisitions.modals.show.smart_recruiters.position_openings.position_id")}
            </th>
            <th>
              {t(
                "v2.job_requisitions.modals.show.smart_recruiters.position_openings.position_type",
              )}
            </th>
            <th>
              {t("v2.job_requisitions.modals.show.smart_recruiters.position_openings.incumbent")}
            </th>
            <th>
              {t("v2.job_requisitions.modals.show.smart_recruiters.position_openings.open_date")}
            </th>
            <th>
              {t(
                "v2.job_requisitions.modals.show.smart_recruiters.position_openings.target_start_date",
              )}
            </th>
            <th>
              {t(
                "v2.job_requisitions.modals.show.smart_recruiters.position_openings.position_status",
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {positionOpenings?.map((positionOpening, index) => (
            <tr
              // eslint-disable-next-line react/no-array-index-key
              key={`position-${positionOpening.position_id}-${positionOpening.type}-${positionOpening.target_start_date}-${index}`}
            >
              <td>{positionOpening.position_id}</td>
              {positionOpening.type ? (
                <td>
                  {t(
                    `v2.job_requisitions.modals.show.smart_recruiters.position_openings.type.${positionOpening.type.toLowerCase()}`,
                    { default: titleize(positionOpening.type) },
                  )}
                </td>
              ) : null}
              <td>{positionOpening.incumbent_name}</td>
              <td>
                {positionOpening.position_open_date
                  ? parseAndFormat(positionOpening.position_open_date)
                  : null}
              </td>
              <td>
                {positionOpening.target_start_date
                  ? parseAndFormat(positionOpening.target_start_date)
                  : null}
              </td>
              {positionOpening.status ? (
                <td>
                  {t(
                    `v2.job_requisitions.modals.show.smart_recruiters.position_openings.status.${positionOpening.status.toLowerCase()}`,
                    { default: titleize(positionOpening.status) },
                  )}
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export { SmartRecruiters }
