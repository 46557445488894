import { ChartMetrics, ChartStateManager, OrgChart, RelationalNodeDataStore } from "org_chart/chart"
import { createElement } from "react"
import { createRoot } from "react-dom/client"
import ReactInitializer from "ReactInitializer"

import ColorCoding from "v2/color_coding"
import * as BudgetTable from "v2/modals/position_modal/budget_table"
import * as FilledByInput from "v2/modals/position_modal/filled_by_input"
import * as FundingSources from "v2/modals/position_modal/position_funding_sources"
import * as TitleInput from "v2/modals/position_modal/title_input"
import { PanelSidebar } from "v2/panel_sidebar"
import { mountStandaloneColorCodingTab } from "v2/react/components/orgChart/SuperPanel/ColorCodingTab/ColorCodingTab"
import { ProfileNav } from "v2/react/components/profile/ProfileNav"
import { SuccessionPlanPanelContainer } from "v2/react/components/succession/SuccessionPlanPanelContainer"
import {
  mountHistoricalOrgChartDuringNextTick,
  mountSpreadsheetDuringNextTick,
  Peripherals,
  ProfilePanel,
  SendToAts,
} from "v2/react/pages/org_chart/index"
import { BasicPageNav } from "v2/react/shared/navigation/PageNav/BasicPageNav"
import { bootstrapActiveChartContainer } from "v2/redux/containers/activeChartContainer"
import { mountDatasheetListeners } from "v2/redux/listeners/datasheetListeners"
import { InlineOrgUnitForm } from "v2/subforms/inlineOrgUnitForm"

window.OrgChart = OrgChart
window.ChartStateManager = ChartStateManager
window.ChartMetrics = ChartMetrics
window.ColorCoding = ColorCoding
window.PanelSidebar = PanelSidebar
window.RelationalNodeDataStore = RelationalNodeDataStore
window.BudgetTable = BudgetTable
window.FundingSources = FundingSources
window.FilledByInput = FilledByInput
window.TitleInput = TitleInput
window.InlineOrgUnitForm = InlineOrgUnitForm
window.bootstrapActiveChartContainer = bootstrapActiveChartContainer
window.mountStandaloneColorCodingTab = mountStandaloneColorCodingTab
window.mountDatasheetListeners = mountDatasheetListeners
window.mountSpreadsheetDuringNextTick = mountSpreadsheetDuringNextTick
window.mountHistoricalOrgChartDuringNextTick = mountHistoricalOrgChartDuringNextTick

ReactInitializer.loadDependenciesThenMount()

// These exports are available on window.ReactApplication.
// eslint-disable-next-line import/prefer-default-export
export {
  BasicPageNav,
  createElement,
  createRoot,
  OrgChart,
  PanelSidebar,
  Peripherals,
  ProfilePanel,
  ProfileNav,
  mountSpreadsheetDuringNextTick,
  SendToAts,
  SuccessionPlanPanelContainer,
}
