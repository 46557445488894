import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CellContext, createColumnHelper } from "@tanstack/react-table"
import React, { useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { PayGrade } from "types/graphql"
import { SortDirection } from "types/graphql.enums"
import { CreateModal } from "v2/react/components/payGrades/Index/CreateModal"
import { DeleteModal } from "v2/react/components/payGrades/Index/DeleteModal"
import { EditModal } from "v2/react/components/payGrades/Index/EditModal"
import RootProvider from "v2/react/components/RootProvider"
import { useServerTable, useTableState } from "v2/react/hooks/useServerTable"
import { SearchInput } from "v2/react/shared/forms/SearchInput"
import { UtilityNav } from "v2/react/shared/navigation/UtilityNav"
import { MoreActionsCell } from "v2/react/shared/tables/Table/Cell/MoreActionsCell"
import { Table } from "v2/react/shared/tables/Table/Table"
import { usePayGradeCollectionQuery } from "v2/redux/GraphqlApi/PayGradeApi"

function WithProvider() {
  const { t } = useTranslation()
  const [activePayGrade, setActivePayGrade] = useState<PayGrade | null>(null)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { sorting, pagination, search, setSorting, setPagination, setSearch } = useTableState()
  const scrollRef = useRef<HTMLDivElement>(null)

  const { data, isLoading } = usePayGradeCollectionQuery({
    searchTerm: search,
    // #paginate in rails is 1-based, but the table is 0-based
    page: pagination.pageIndex + 1,
    sortKey: sorting[0]?.id,
    sortDirection: sorting[0]?.desc ? SortDirection.Desc : SortDirection.Asc,
  })

  const rows = data?.collection || []

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<PayGrade>()

    const moreActions = ({ row }: CellContext<PayGrade, unknown>) => (
      <MoreActionsCell<PayGrade>
        activeItem={row.original}
        openDeleteModal={handleOpenDeleteModal}
        openEditModal={handleOpenEditModal}
      />
    )

    const periodTypeCell = ({ row }: CellContext<PayGrade, unknown>) => (
      <p>{row.original.periodType ? t(`v2.pay_grades.periods.${row.original.periodType}`) : ""}</p>
    )

    return [
      columnHelper.display({
        id: "actions",
        cell: moreActions,
      }),
      columnHelper.accessor("code", {
        header: t("v2.pay_grades.column_headers.code"),
        sortDescFirst: true,
      }),
      columnHelper.accessor("name", {
        header: t("v2.pay_grades.column_headers.name"),
        sortDescFirst: false,
      }),
      columnHelper.accessor("range", {
        header: t("v2.pay_grades.column_headers.range"),
        sortDescFirst: false,
      }),
      columnHelper.accessor("periodType", {
        header: t("v2.pay_grades.column_headers.period"),
        sortDescFirst: false,
        cell: periodTypeCell,
      }),
    ]
  }, [t])

  const handlePageChange = () => {
    scrollRef.current?.scroll({ top: 0, behavior: "smooth" })
  }

  const table = useServerTable<PayGrade>({
    columns,
    data: rows,
    sorting,
    pagination,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    totalRows: data?.metadata?.totalCount || 0,
  })

  const handleOpenEditModal = (jobLevel: PayGrade) => {
    setActivePayGrade(jobLevel)
    setEditModalOpen(true)
  }

  const handleCloseEditModal = () => {
    setEditModalOpen(false)
    setActivePayGrade(null)
  }

  const handleOpenDeleteModal = (payGrade: PayGrade) => {
    setActivePayGrade(payGrade)
    setDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
    setActivePayGrade(null)
  }

  return (
    <div ref={scrollRef}>
      <UtilityNav makeSticky>
        <div className="ml-auto items-center gap-2 flex">
          <SearchInput
            value={search}
            onChange={(value: string) => setSearch(value)}
            onClear={() => setSearch("")}
          />
          <button
            className="btn btn--primary sm:btn--large sm:btn--primary tooltip tooltip-right"
            type="button"
            onClick={() => setCreateModalOpen(!createModalOpen)}
          >
            <FontAwesomeIcon icon={["far", "plus"]} />
            <span className="hidden sm:flex">{t("v2.pay_grades.index.add_pay_grade")}</span>
          </button>
        </div>
      </UtilityNav>
      <div className="page-pad">
        <Table
          table={table}
          isLoading={isLoading}
          animateEntrance
          footerContent={t("v2.pay_grades.index.found", {
            count: data?.metadata?.totalCount || 0,
          })}
          onPageChange={handlePageChange}
        />
      </div>
      <CreateModal isOpen={createModalOpen} onClose={() => setCreateModalOpen(false)} />
      {activePayGrade && (
        <EditModal
          isOpen={editModalOpen}
          onClose={handleCloseEditModal}
          payGrade={activePayGrade}
        />
      )}
      {activePayGrade && (
        <DeleteModal
          isOpen={deleteModalOpen}
          onClose={handleCloseDeleteModal}
          payGrade={activePayGrade}
        />
      )}
    </div>
  )
}

function Index() {
  return (
    <RootProvider>
      <WithProvider />
    </RootProvider>
  )
}

export { Index }
