import { animate, motion } from "framer-motion"
import React, { useEffect, useRef, useState } from "react"

interface AnimatedNumberProps {
  value: number
  /** Duration of the tween (in seconds) */
  duration?: number
}

function AnimatedNumber({ value, duration = 0.75 }: AnimatedNumberProps) {
  const [displayValue, setDisplayValue] = useState<number>(0)
  const previousValueRef = useRef<number>(0)

  useEffect(() => {
    const controls = animate(previousValueRef.current, value, {
      duration,
      ease: "easeInOut",
      onUpdate: (latest) => {
        setDisplayValue(latest)
      },
    })
    previousValueRef.current = value

    return () => controls.stop()
  }, [value, duration])

  return <motion.span>{Math.round(displayValue).toLocaleString()}</motion.span>
}

export { AnimatedNumber }
