import barsClosedUrl from "images/bars-closed.svg"
import React from "react"

import { ClientOnly } from "v2/react/shared/layout/ClientOnly"
import { useNavController } from "v2/react/shared/navigation/useNavController"

function NavHamburger() {
  const { toggleNav } = useNavController()

  return (
    <ClientOnly>
      <div className="nav-hamburger no-print">
        <button
          className="btn--icon btn--ghost h-8 w-8 place-content-center grid hover:bg-neutral-3"
          onClick={toggleNav}
          type="button"
        >
          <img
            src={barsClosedUrl}
            alt="Navigation Toggle"
            className="closed-icon opacity-[.64] group-hover:opacity-100"
          />
        </button>
      </div>
    </ClientOnly>
  )
}

export { NavHamburger }
