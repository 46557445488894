import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"
import { useOnClickOutside } from "usehooks-ts"

import { getEffectiveDate } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/effectiveDates/helpers"
import {
  setFocusPositionEndDate,
  setRowForEffectiveDateModal,
} from "v2/redux/slices/DatasheetSlice"
import { useAppDispatch } from "v2/redux/store"

import { RestorePositionButton } from "../HeadcountPlanDatasheet/RestorePositionButton"
import { RevertChangesButton } from "../HeadcountPlanDatasheet/RevertChangesButton"
import { HeadcountPlanDatasheetRow } from "../HeadcountPlanDatasheet/types"
import { ConfirmDeleteModal } from "./ConfirmDeleteModal"
import { DuplicatePositionButton } from "./DuplicatePositionButton"
import { PositionMenuButton } from "./PositionMenuButton"
import { TogglePositionExclusionButton } from "./TogglePositionExclusionButton"

interface Props {
  headcountPlanId: string
  participantId?: string
  participantPositionId?: string
  excluded: boolean | undefined | null
  rootEventId: string
  row: HeadcountPlanDatasheetRow
}

export function PositionMenu({
  headcountPlanId,
  participantId,
  participantPositionId,
  excluded,
  rootEventId,
  row,
}: Props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = React.useState(false)
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  const isEliminated =
    row.type !== "new" && getEffectiveDate(row.positionAttributesWithEdits, "position_end_date")

  useOnClickOutside(ref, () => isOpen && setIsOpen(false))

  const handleDeletePositionConfirmClick = () => {
    setIsDeleteConfirmOpen(true)
    setIsOpen(false)
  }

  const handleEliminatePositionClick = () => {
    dispatch(setRowForEffectiveDateModal(row))
    dispatch(setFocusPositionEndDate(true))
    setIsOpen(false)
  }

  const onDeletePositionClose = () => {
    setIsDeleteConfirmOpen(false)
  }

  const onDuplicatePositionSuccess = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  const onRestorePositionSuccess = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  const onRevertChangesSuccess = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  const onExcludePositionSuccess = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  const canRestore =
    isEliminated && !row.originalManagerEliminatedOn && !row.currentManagerEliminatedOn

  const canRevertChanges =
    ((!isEliminated && row.type === "modified") || isEliminated) &&
    !row.originalManagerEliminatedOn &&
    !canRestore

  const rowHasChildren = Array.isArray(row.children) && row.children.length > 0

  return (
    <div ref={ref} className="relative">
      {isDeleteConfirmOpen && (
        <ConfirmDeleteModal
          headcountPlanId={headcountPlanId}
          isOpen={isDeleteConfirmOpen}
          onClose={onDeletePositionClose}
          participantId={participantId}
          rootEventId={row.rootEventId}
          priorRevisionNumber={row.revisionNumber}
        />
      )}
      <button
        id={`options-${row.id}`}
        type="button"
        className="btn btn--ghost mx-auto"
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        <FontAwesomeIcon icon={["far", "ellipsis"]} />
      </button>
      <div
        id={`position-menu-${row.id}`}
        className="hcp-position-menu"
        style={{
          display: isOpen ? "flex" : "none",
        }}
      >
        {canRevertChanges ? (
          <RevertChangesButton
            headcountPlanId={headcountPlanId}
            participantId={participantId}
            rootEventId={rootEventId}
            onSuccess={onRevertChangesSuccess}
            revisionNumber={row.revisionNumber}
          />
        ) : null}
        <DuplicatePositionButton
          headcountPlanId={headcountPlanId}
          participantId={participantId}
          participantPositionId={participantPositionId}
          payload={row.positionAttributesWithEdits}
          onSuccess={onDuplicatePositionSuccess}
        />
        {row.type === "new" ? (
          <TogglePositionExclusionButton
            headcountPlanId={headcountPlanId}
            participantId={participantId}
            excluded={excluded}
            rootEventId={rootEventId}
            onSuccess={onExcludePositionSuccess}
            disabled={rowHasChildren}
            tooltipContent={
              rowHasChildren
                ? t("v2.headcount_plan.errors.all_direct_reports_must_be_reassigned")
                : undefined
            }
          />
        ) : null}
        {canRestore ? (
          <RestorePositionButton
            row={row}
            headcountPlanId={headcountPlanId}
            participantId={participantId}
            onSuccess={onRestorePositionSuccess}
          />
        ) : null}
        {!isEliminated && !excluded ? (
          <PositionMenuButton
            className="text-status-critical"
            icon={<FontAwesomeIcon icon={["far", "ban"]} className="text-status-critical" />}
            onClick={handleEliminatePositionClick}
            text={t("v2.headcount_plan.eliminate_position_modal.eliminate_position")}
            disabled={row.type === "new" && rowHasChildren}
            tooltipContent={
              row.type === "new" && rowHasChildren
                ? t("v2.headcount_plan.errors.all_direct_reports_must_be_reassigned")
                : undefined
            }
          />
        ) : null}
        {row.deletable ? (
          <PositionMenuButton
            className={`text-status-critical delete-hcp-position-${rootEventId}`}
            icon={<FontAwesomeIcon icon={["far", "trash-alt"]} className="text-status-critical" />}
            onClick={handleDeletePositionConfirmClick}
            text={t("v2.headcount_plan.datasheet.remove_position")}
            disabled={rowHasChildren}
            tooltipContent={
              rowHasChildren
                ? t("v2.headcount_plan.errors.all_direct_reports_must_be_reassigned")
                : undefined
            }
          />
        ) : null}
      </div>
    </div>
  )
}
