import React from "react"

import { updateCellState } from "v2/redux/slices/DatasheetSlice"
import { selectCellState } from "v2/redux/slices/DatasheetSlice/datasheetSelectors"
import { CellEvent, CellState } from "v2/redux/slices/DatasheetSlice/types"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { FieldType } from "../types"

function exec(state: CellState, effects: CellEffects) {
  const effect = effects[state.state]

  if (effect) {
    effect()
  }
}

function defaultHasNotChanged(value: unknown, valueFromPreviousEdit: unknown) {
  return value === valueFromPreviousEdit
}

export function useCellState<TValue>(options: UseCellOptions<TValue>) {
  const appDispatch = useAppDispatch()
  const dispatch = React.useCallback(
    (event: Omit<CellEvent, "rowId" | "columnId">) => {
      appDispatch(updateCellState({ rowId: options.rowId, columnId: options.columnId, ...event }))
    },
    [appDispatch, options.rowId, options.columnId],
  )

  const state = useAppSelector(selectCellState(options), {
    equalityFn: (a, b) => a.state === b.state,
  })

  const onSaving = () => {
    options.onSaving?.(state, dispatch)

    const hasNotChangedFn = options.hasNotChanged ?? defaultHasNotChanged
    if (state.state !== "erroredSaving" && hasNotChangedFn(state.value, options.currentValue)) {
      dispatch({ type: "noop" })
      return
    }

    const runSave = async () => {
      if (!options.saveFn) {
        return
      }

      const res = await options.saveFn(state)
      // If we're in the org chart datasheet, the saveFn can potentially return
      // a "noChange" message to indicate a noop. It can also return a "followUp"
      // message when going into a follow-up modal. These both *only* apply
      // to the org chart datasheet for now.
      if (res.ok && res.message === "noChange") {
        dispatch({ type: "noop" })
      } else if (res.ok) {
        dispatch({ type: "saveSuccess" })
        setTimeout(() => dispatch({ type: "reset" }), 800)
      } else if (res.error.message === "followUp") {
        dispatch({ type: "saveFollowUp" })
      } else {
        dispatch({ type: "saveError", message: res.error.message })
      }
    }

    runSave()
  }

  React.useEffect(() => {
    exec(state, {
      idle: () => {
        options.onIdle?.(state, dispatch)
      },
      selected: () => {
        options.onSelected?.(state, dispatch)
      },
      editing: () => {
        options.onEditing?.(state, dispatch)
      },
      saving: onSaving,
      erroredSaving: onSaving,
      saved: () => {
        options.onSaved?.(state, dispatch)
      },
      errored: () => {
        options.onErrored?.(state, dispatch)
      },
      erroredSelected: () => {
        options.onSelected?.(state, dispatch)
      },
      erroredEditing: () => {
        options.onEditing?.(state, dispatch)
      },
    })
  }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

  const cell = React.useMemo(
    () => ({
      rowId: options.rowId,
      columnId: options.columnId,
      fieldType: options.fieldType,
      state: state.state,
      value: state.value,
      errorMessage: state.errorMessage,
      dispatch,
      editable: options.editable !== false,
      isIdle: state.state === "idle",
      isSelected: state.state === "selected",
      isEditing: state.state === "editing",
      isSaving: state.state === "saving",
      isSaved: state.state === "saved",
      isErrored: state.state === "errored",
      isErroredSelected: state.state === "erroredSelected",
      isErroredEditing: state.state === "erroredEditing",
      isErroredSaving: state.state === "erroredSaving",
      isFollowUp: state.state === "followUp",
    }),
    [state, dispatch, options.editable, options.columnId, options.rowId, options.fieldType],
  )

  return cell
}

// --------- TYPES ---------

export type Cell = ReturnType<typeof useCellState>

export type CellStateCallback = (state: CellState, dispatch: React.Dispatch<CellEvent>) => unknown

type UseCellOptions<TValue> = {
  currentValue?: TValue
  fieldType: FieldType
  rowId: string
  columnId: string
  editable?: boolean
  saveFn?: (
    state: CellState,
  ) => Promise<{ ok: true; message?: string } | { ok: false; error: { message: string } }>
  hasNotChanged?: (value: CellState["value"], oldValue: TValue | undefined) => boolean
  onSelected?: CellStateCallback
  onEditing?: CellStateCallback
  onSaving?: CellStateCallback
  onIdle?: CellStateCallback
  onSaved?: CellStateCallback
  onErrored?: CellStateCallback
}

type CellEffects = {
  [key in CellState["state"]]?: () => void
}
