import fp from "lodash/fp"

import { ChangeBatch, ChangeRequest, ChangeRequestChangeItem } from "types/graphql"

import { ChangeRequestWithOneItem } from "./types"

const EMPTY_CHANGE = {
  field: null,
  fieldLabelFormat: null,
  current: null,
  prior: null,
  modifierName: null,
  modifiedAt: null,
}

const toRequestWithOneChangeItem =
  (changeRequest: ChangeRequest) =>
  (change: ChangeRequestChangeItem): ChangeRequestWithOneItem => ({ ...changeRequest, change })

const toChangeRequestWithOneItems = (changeRequest: ChangeRequest): ChangeRequestWithOneItem[] =>
  fp.isEmpty(changeRequest.changes)
    ? [toRequestWithOneChangeItem(changeRequest)(EMPTY_CHANGE)]
    : fp.map(toRequestWithOneChangeItem(changeRequest), changeRequest.changes ?? [])

export const toFlattenedBatch = (batch: ChangeBatch) => ({
  ...batch,
  changeRequests: fp.flatMap(toChangeRequestWithOneItems, batch.changeRequests),
})

export const mapToFlattenedBatches = fp.map(toFlattenedBatch)
