import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { useAddChangeToHeadcountPlanMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { PositionMenuButton } from "../ShowParticipant/PositionMenuButton"

export function RevertChangesButton({
  headcountPlanId,
  participantId,
  rootEventId,
  onSuccess,
  revisionNumber,
}: {
  headcountPlanId: string
  participantId?: string
  rootEventId: string
  onSuccess: () => void
  revisionNumber: number
}) {
  const [mutate, mutation] = useAddChangeToHeadcountPlanMutation()

  React.useEffect(() => {
    if (mutation.data?.addChangeToHeadcountPlan?.headcountPlanChangeCreated) {
      onSuccess()
    }
  }, [mutation.data, onSuccess])

  return (
    <PositionMenuButton
      icon={<FontAwesomeIcon icon={["far", "undo"]} />}
      text={"revert_changes".t("headcount_plan")}
      onClick={() => {
        mutate({
          action: "update_existing",
          headcountPlanId,
          headcountPlanParticipantId: participantId,
          rootEventId,
          payload: {},
          revisionNumber: revisionNumber + 1,
        })
      }}
    />
  )
}
