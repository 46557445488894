import fp from "lodash/fp"
import React, { useCallback, useMemo, useRef, useState } from "react"

import { BasePay, SourcePay } from "types/graphql.enums"
import { BasePayInput } from "v2/react/shared/forms/BasePayInput"
import {
  createCurrencyOptions,
  CurrencyDropdown,
  CurrencyOption,
} from "v2/react/shared/forms/CurrencyDropdown"
import { CurrencyInput } from "v2/react/shared/forms/CurrencyInput"
import { VariablePayInput } from "v2/react/shared/forms/VariablePayInput"
import { formatCurrency, parseCurrency } from "v2/react/utils/currency"
import { useGetCurrencyCodeQuery } from "v2/redux/GraphqlApi"

interface VariablePayItem {
  label: string
  id: string
  value?: string | null
  payType?: SourcePay | null
  useAttentionState?: boolean
  error?: string
}

interface VariablePayMap {
  [key: string]: Omit<VariablePayItem, "id" | "label">
}

interface BasePayErrors {
  currency?: string
  hoursPerWeek?: string
}

interface BasePayItem {
  modelType: "position" | "compensation"
  currencyCode: string
  initialCurrencyValue: string
  initialPayType: BasePay
  initialHoursPerWeek: number | null | undefined
  label: string
  useAttentionState?: boolean
  errors?: BasePayErrors
}

interface UpdateSumProps {
  key: string
  value: string
  payType: BasePay | SourcePay
  hoursPerWeek?: string | null
}

interface CompensationTableProps {
  headerLabel: string
  basePay: BasePayItem
  variablePays: VariablePayItem[]
  clearErrors?: (keys: string[]) => void
}

const CompensationTable = ({
  headerLabel,
  basePay,
  variablePays,
  clearErrors,
}: CompensationTableProps) => {
  const { data, isLoading } = useGetCurrencyCodeQuery({})
  const company = data?.currentCompany || null
  const [currentCurrencyIcon, setCurrentCurrencyIcon] = useState<string>()

  const [basePayTotal, setBasePayTotal] = useState<number>(
    calculateBasePay({
      amount: basePay.initialCurrencyValue,
      type: basePay.initialPayType,
      hoursPerWeek: basePay.initialHoursPerWeek ? basePay.initialHoursPerWeek : null,
    }),
  )

  // This is used for calculating variable pays total amount. When variable
  // pays are updated, the amount and pay type are stored in this map,
  // and the total is recalculated.
  const [variablePayMap, setVariablePayMap] = useState<VariablePayMap>(
    fp.keyBy(fp.prop("id"))(variablePays),
  )

  // If the variable pay type is percent, then changing the base pay
  // amount affects that variable pay amount. This is why we
  // need to recalculate these values when the base pay amount changes.
  const variablePayTotal = useMemo(
    () => calculateVariablePayTotal(variablePayMap, basePayTotal),
    [variablePayMap, basePayTotal],
  )

  const total = basePayTotal + variablePayTotal

  const totalAnnualRef = useRef<HTMLInputElement>(null)

  const updateSum = useCallback(({ key, value, payType, hoursPerWeek }: UpdateSumProps) => {
    if (key === "base_pay") {
      setBasePayTotal(
        calculateBasePay({
          amount: value,
          type: payType as BasePay,
          hoursPerWeek: hoursPerWeek ? parseFloat(hoursPerWeek) : null,
        }),
      )
    } else {
      setVariablePayMap((prev) => ({
        ...prev,
        [key]: { value, payType: payType as SourcePay },
      }))
    }
  }, [])

  if (totalAnnualRef.current) {
    totalAnnualRef.current.value = formatCurrency({
      value: total,
      omitSymbol: true,
      trailing: true,
    })
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const onSelection = (option: CurrencyOption, changedFromInitial: boolean) => {
    setCurrentCurrencyIcon(option.icon)
  }

  if (isLoading || !company) {
    return null
  }

  const options = createCurrencyOptions(company.collections.currencies.options.nodes || [])

  const initialOption = options.find((option) => option.value === basePay.currencyCode)
  const initialIcon = initialOption?.icon
  if (!initialOption) return null

  return (
    <div className="compensation-table">
      <div className="compensation-table__row compensation-table__row--header flex-row items-center justify-between flex">
        {headerLabel}
        <CurrencyDropdown
          initialCurrencyCode={basePay.currencyCode}
          onSelection={onSelection}
          selectionDisplay="value"
          size="sm"
        />
      </div>
      <div className="compensation-table__row">
        <BasePayInput
          modelType={basePay.modelType}
          label={basePay.label}
          labelPlacement="left"
          iconClass={currentCurrencyIcon ?? initialIcon}
          initialCurrencyValue={basePay.initialCurrencyValue || undefined}
          initialPayType={basePay.initialPayType || undefined}
          initialHoursPerWeek={basePay.initialHoursPerWeek?.toString()}
          updateSum={updateSum}
          useAttentionState={basePay.useAttentionState}
          clearErrors={clearErrors}
          currencyErrors={basePay.errors?.currency}
          hoursErrors={basePay.errors?.hoursPerWeek}
        />
      </div>
      {variablePays?.map((variablePay) => {
        const { id, label, value, payType } = variablePay
        return (
          <div className="compensation-table__row" key={id}>
            <VariablePayInput
              label={label}
              labelPlacement="left"
              iconClass={currentCurrencyIcon ?? initialIcon}
              initialAmountValue={value || undefined}
              initialPayType={payType || undefined}
              name={id.replace(/_type$/, "")}
              updateSum={updateSum}
              useAttentionState={variablePay.useAttentionState}
              amountErrors={variablePay.error}
              clearErrors={clearErrors}
            />
          </div>
        )
      })}
      <div className="compensation-table__row compensation-table__row--footer">
        <span className="compensation-table__row--footer--label">
          {"Total".t("compensation", "Total")}
        </span>
        <div className="compensation-table__row--footer--total">
          <CurrencyInput
            ref={totalAnnualRef}
            id="totalAnnual"
            name="totalAnnual"
            iconClass={currentCurrencyIcon ?? initialIcon}
            defaultValue={formatCurrency({
              value: total,
              omitSymbol: true,
              trailing: true,
            })}
            disabled
          />
        </div>
      </div>
    </div>
  )
}

const calculateVariablePayTotal = (variablePayMap: VariablePayMap, basePayAmount: number) => {
  let total = 0
  Object.values(variablePayMap).forEach((variablePay) => {
    total += calculateVariablePay({
      amount: variablePay.value || "",
      type: variablePay.payType || SourcePay.Amount,
      basePayAmount,
    })
  })
  return total
}

const calculateVariablePay = ({
  amount,
  type,
  basePayAmount,
}: {
  amount: string
  type: SourcePay
  basePayAmount: number
}): number => {
  if (type === SourcePay.Percent) {
    return (parseCurrency(amount || "") / 100.0) * basePayAmount
  }
  return parseCurrency(amount || "") || 0.0
}

const calculateBasePay = ({
  amount,
  type,
  hoursPerWeek,
}: {
  amount: string
  type: BasePay
  hoursPerWeek: number | null
}): number => {
  let total = parseCurrency(amount) || 0
  if (type === BasePay.Hourly && hoursPerWeek) {
    total *= hoursPerWeek * 52.0
  }

  return total
}

export { CompensationTable, VariablePayItem, UpdateSumProps, BasePayItem }
