import React, { createContext, useCallback, useContext, useEffect, useMemo } from "react"
import { bindActionCreators } from "redux"

import {
  ChangeScreen,
  closedStackModal,
  poppedStackModalScreen,
  pushedStackModalScreens,
  ScreenStackApi,
  ScreenStackContextValue,
  selectScreenStack,
  selectStackModalIsOpen,
  selectStackModalScreen,
} from "v2/redux/slices/ChangeRequestSlice"
import { AppDispatch, useAppDispatch, useAppSelector } from "v2/redux/store"

const bindActions = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      closedStackModal,
      poppedStackModalScreen,
      pushedStackModalScreens,
    },
    dispatch,
  )

// ScreenStackContext with a stub/default value. In order to be useful, a
// provider needs to define/set this value.
export const ScreenStackContext = createContext<ScreenStackContextValue>({
  changeLatestScrollTop: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  latestScrollTopRef: { current: 0 },
  scrollContainerRef: { current: null },
  scrollTo: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
})

/**
 * Provides a basic API for managing the "screen stack" presented by the ADP
 * integration modal.
 *
 * Push or pop screens and manage a screen's scroll position. A screen's scroll
 * position is managed automatically (pushing a screen resets it, popping a
 * screen restores it). The active scroll position may also be set manually via
 * `scrollTo`. This functionality depends on the caller being wrapped with
 * `ScreenStackContext.Provider`.
 *
 * @see {@link webpack/v2/react/components/adp/ChangeBatchScreenStackModal/ScreenStackProvider.tsx}
 */
export function useScreenStackForAdpChangeModal(): ScreenStackApi {
  // Derive the actual screen stack and the "active" screen.
  const stack = useAppSelector(selectScreenStack)
  const activeScreen = useAppSelector(selectStackModalScreen)
  const isStackOpen = useAppSelector(selectStackModalIsOpen)

  // Prepare support for scroll synchronization so, if the caller wants, scroll
  // position can be reset (when drilling into a screen) or restored when
  // navigating to a prior screen.
  const {
    changeLatestScrollTop,
    changeLatestScrollTopFromUIEvent,
    latestScrollTopRef,
    scrollContainerRef,
    scrollTo,
  } = useScrollTracking()

  // Bind the core actions for managing the screen stack.
  const actions = useScreenStackActions()

  return {
    ...actions,
    activeScreen,
    changeLatestScrollTop,
    changeLatestScrollTopFromUIEvent,
    isStackOpen,
    latestScrollTopRef,
    scrollContainerRef,
    scrollTo,
    stack,
  }
}

export function useScreen(screenKey: ChangeScreen["screenKey"]) {
  const isActive = useAppSelector((state) => selectStackModalScreen(state)?.screenKey === screenKey)
  const scrollTopIfActive = useAppSelector((state) =>
    isActive ? selectStackModalScreen(state)?.scrollTop ?? 0 : undefined,
  )
  const { scrollTo } = useScrollTracking()

  useEffect(() => {
    if (scrollTopIfActive === undefined) return
    scrollTo({
      behavior: "instant",
      top: scrollTopIfActive,
    })
  }, [scrollTopIfActive, scrollTo])

  const actions = useScreenStackActions()
  return { ...actions, isActive, scrollTo }
}

export function useScreenStackActions() {
  const { latestScrollTopRef } = useScrollTracking()
  const dispatch = useAppDispatch()
  const { pushedStackModalScreens, ...actions } = useMemo(() => bindActions(dispatch), [dispatch])
  const pushScreens = useCallback(
    (screens: ChangeScreen[]) =>
      pushedStackModalScreens({
        screens,
        scrollTop: latestScrollTopRef?.current ?? 0,
      }),
    [latestScrollTopRef, pushedStackModalScreens],
  )

  return {
    closeStackModal: actions.closedStackModal,
    popStackModalScreen: actions.poppedStackModalScreen,
    pushScreens,
  }
}

/**
 * Encapsulates/exposes the latest scroll top position as a ref.
 *
 * This tracks the position as a ref since it is assumed that a change in its
 * value should not invalidate other hooks. Other hooks which use the ref will
 * have access to the latest scroll top value.
 *
 * This is used to help support restoring/resetting scroll position based on
 * screen changes.
 *
 * @private
 */
function useScrollTracking() {
  const { changeLatestScrollTop, ...context } = useContext(ScreenStackContext)

  const changeLatestScrollTopFromUIEvent: React.UIEventHandler = useCallback(
    (ev) => {
      changeLatestScrollTop(ev.currentTarget.scrollTop)
    },
    [changeLatestScrollTop],
  )

  return { ...context, changeLatestScrollTop, changeLatestScrollTopFromUIEvent }
}
