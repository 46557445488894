import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { startCase } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"

import { Maybe } from "types/graphql"
import { withRootProvider } from "v2/react/components/RootProvider"
import { statusColorLookup, statusTextLookup } from "v2/react/shared/status/statusUtils"

interface Props {
  badgeClasses?: string
  checkmarkOnApproved?: boolean
  displayType: "text" | "button"
  fontSizeClass?: "text-base" | "text-sm"
  status: Maybe<string> | undefined
  /**
   * If provided, this will be displayed directly instead of using the
   * translations.
   */
  statusLabel?: Maybe<string> | undefined
}

function Badge({
  badgeClasses,
  checkmarkOnApproved,
  displayType,
  status,
  statusLabel,
  fontSizeClass = "text-base",
}: Props) {
  const { t } = useTranslation()
  if (!status) return null

  const styles = classNames(
    "status-badge",
    fontSizeClass,
    {
      [`badge--${statusColorLookup(status)}`]: displayType === "button",
      [`inline status--${statusColorLookup(status)}`]: displayType === "text",
    },
    badgeClasses,
  )

  return (
    <div className={styles}>
      {checkmarkOnApproved && (status === "approved" || status === "plan_approved") && (
        <FontAwesomeIcon icon={["far", "check"]} size="1x" />
      )}{" "}
      {statusLabel || startCase(statusTextLookup(status, t))}
    </div>
  )
}

const StatusBadge = withRootProvider(Badge, "StatusBadge")

export { StatusBadge }
