import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { isEmpty } from "lodash"
import React from "react"

import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"

type SelectItem = { id: React.Key; label: string }

interface SelectDropdownProps<T extends SelectItem> {
  options: T[]
  selectedId?: T["id"]
  onClick?: (id: T) => void
  label?: string
  id?: string
  error?: string
}

const SelectDropdown = <T extends SelectItem>({
  options,
  selectedId,
  onClick,
  label,
  id,
  error,
}: SelectDropdownProps<T>) => {
  const selectedOption = options.find((option) => option.id === selectedId)

  return (
    <div className={cn("w-full", { "form-error": !!error })}>
      {label && (
        <label className="mb-1 text-neutral-100" id={`${id}-label`}>
          {label}
        </label>
      )}
      <Dropdown className="w-full block">
        <div className="select" aria-labelledby={`${id}-label`}>
          <Dropdown.Trigger>{selectedOption?.label}</Dropdown.Trigger>
        </div>
        <Dropdown.Menu className="!m-0 max-h-96">
          {options.map((option) => (
            <Dropdown.Item
              className="justify-between flex"
              key={option.id}
              onClick={() => onClick?.(option)}
              useActiveStyles
            >
              {option.label}
              {selectedId === option.id && (
                <FontAwesomeIcon icon={["fas", "circle-check"]} className="text-neutral-100" />
              )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {!isEmpty(error?.trim()) && <InputErrorText message={error ?? ""} />}
    </div>
  )
}

export { SelectDropdown }
