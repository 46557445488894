import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, PositionType } from "types/graphql"
import { useEditPositionType } from "v2/react/components/positionTypes/hooks/useEditPositionType"
import { SummaryModal } from "v2/react/components/positionTypes/modals/SummaryModal"
import { ActionBanner } from "v2/react/components/positionTypes/Show/ActionBanner"
import { AlertBanner } from "v2/react/components/positionTypes/Show/AlertBanner"
import { EmptyContent, EmptyState } from "v2/react/components/positionTypes/Show/EmptyState"
import { SmallEditButton } from "v2/react/components/positionTypes/Show/SmallEditButton"
import { useStringWithFieldSuggestion } from "v2/react/hooks/useTypedFieldWithSuggestion"
import { Pulser } from "v2/react/shared/loaders/Pulser"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

interface SummaryProps {
  positionType: PositionType
  featureFlags: FeatureFlags
}

const Summary = ({ positionType, featureFlags }: SummaryProps) => {
  const { t } = useTranslation()
  const {
    acceptFieldSuggestions,
    addError,
    cancelGeneratingFieldSuggestions,
    declineFieldSuggestions,
    errors,
    generateFieldSuggestions,
    initializeFieldSuggestions,
    isPerformingFieldSuggestionActions,
    isUpdatingPositionType,
    reset: resetEditState,
    updatePositionType,
  } = useEditPositionType(positionType)
  const {
    value: summary,
    isAwaitingAction,
    isInitializing,
    isInitializeFailed,
  } = useStringWithFieldSuggestion({
    addGenerateError: addError,
    record: positionType,
    hasAiFeatureFlag: Boolean(featureFlags.aiGeneration),
    field: "summary",
    getActual: ({ summary }) => summary ?? null,
    getUniqueKey: (record) => record.uniqueKey ?? `position_type_${record.id}`,
  })

  const [editModalOpen, setEditModalOpen] = useState(false)

  const handleClose = () => {
    setEditModalOpen(false)
    cancelGeneratingFieldSuggestions("summary")
    resetEditState()
  }
  const handleEdit = () => setEditModalOpen(true)
  const handleInitializeSummary = () => initializeFieldSuggestions("summary")
  const handleSave = (summary: string, removeLinkedSummary: boolean) =>
    updatePositionType({
      includeBlankValuesInPositionTypeAttributesSync: removeLinkedSummary,
      summary,
    })

  return (
    <>
      <div className="module-card" data-testid="summary-module">
        <div className="module-card__header">
          <div className="module-title">{t("v2.position_types.show.summary")}</div>
          {summary.length > 0 && (!isAwaitingAction || !featureFlags.aiGeneration) && (
            <SmallEditButton onClick={handleEdit} />
          )}
        </div>
        {featureFlags.aiGeneration && isInitializeFailed && isAwaitingAction && (
          <AlertBanner handleGenerate={handleInitializeSummary} />
        )}
        {featureFlags.aiGeneration && summary.length > 0 && (
          <ActionBanner
            awaitingAction={isAwaitingAction}
            fields={["summary"]}
            onAcceptFieldSuggestions={acceptFieldSuggestions}
            onDeclineFieldSuggestions={declineFieldSuggestions}
            onEdit={handleEdit}
          />
        )}
        <div
          className={cn("module-card__body", {
            "border-t-0": isAwaitingAction && summary.length,
          })}
        >
          {summary.length ? (
            <div className="formatted-block">{sanitizeParse(summary)}</div>
          ) : (
            <EmptySummary
              isInitializing={isInitializing && isAwaitingAction}
              handleEdit={handleEdit}
              isAiAvailable={Boolean(featureFlags.aiGeneration)}
            />
          )}
        </div>
      </div>
      <SummaryModal
        contentType="definition"
        errors={errors}
        isOpen={editModalOpen}
        isPerformingFieldSuggestionAction={isPerformingFieldSuggestionActions}
        isSaving={isUpdatingPositionType}
        isAiAvailable={Boolean(featureFlags.aiGeneration)}
        modalTitle={t("v2.position_types.show.edit_job_definition_summary")}
        onClose={handleClose}
        onRegenerateFieldSuggestion={generateFieldSuggestions}
        onSave={handleSave}
        positionSummary={summary}
        record={positionType}
        uniqueKey={positionType.uniqueKey ?? `position_type_${positionType.id}`}
        hasAiFeatureFlag={Boolean(featureFlags.aiGeneration)}
      />
    </>
  )
}

export { Summary }

const EmptySummary = ({
  isInitializing,
  handleEdit,
  isAiAvailable = true,
}: {
  isInitializing: boolean
  handleEdit: () => void
  isAiAvailable?: boolean
}) => {
  const { t } = useTranslation()

  const noteI18nType = isAiAvailable ? "ai_add_note_html" : "add_note_html"
  const icon = isAiAvailable ? "sparkles" : "edit"

  const emptySummary: EmptyContent = {
    buttonText: `${t("v2.defaults.add")} ${t("v2.position_types.show.summary")}`,
    note: sanitizeParse(
      t(`v2.position_types.show.${noteI18nType}`, {
        count: 1,
        property: t("v2.position_types.show.summary").toLowerCase(),
      }),
    ),
    icon,
    onClick: handleEdit,
  }

  if (isInitializing && isAiAvailable) return <Pulser testId="summary-initializing-indicator" />

  return (
    <EmptyState
      buttonText={emptySummary.buttonText}
      note={emptySummary.note}
      icon={emptySummary.icon}
      onClick={emptySummary.onClick}
    />
  )
}
