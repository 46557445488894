import { TFunction } from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

import { Person, Position } from "types/graphql"
import { Avatar } from "v2/react/shared/icons/Avatar"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"

import { totalTenureInYears } from "./utils"

interface Props {
  position: Position
}

function PreviouslyFilledBy({ position }: Props) {
  const people = position.previouslyFilledBy || []
  const { t } = useTranslation()

  if (!people) return null

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">{t("v2.positions.show.previously_filled_by")}</div>
      </div>
      <div className="module-card__body max-h-[400px] gap-0 overflow-y-auto border-0 p-0">
        <PersonRows people={people} position={position} t={t} />
      </div>
    </div>
  )
}

const PersonRows = ({
  people,
  position,
  t,
}: {
  people: Person[]
  position: Position
  t: TFunction
}) =>
  (people || []).map((person: Person) => (
    <PersonRow person={person} position={position} key={person.id} t={t} />
  ))

const PersonRow = ({
  person,
  position,
  t,
}: {
  person: Person
  position: Position
  t: TFunction
}) => (
  <div className="border-0 border-t border-solid border-neutral-8 px-4 py-4 flex">
    <div className="items-center pr-2 flex">
      {person.avatarThumbUrl ? (
        <Avatar person={person} size="med" />
      ) : (
        <BlueIcon icon={["far", "chair-office"]} size="med" />
      )}
    </div>
    <div>
      <div className="text-neutral-100">{person.name}</div>
      <div className="text-neutral-64">
        {t("v2.positions.show.tenure_in_years", {
          count: totalTenureInYears(person.createdAt),
        })}
      </div>
      <div className="text-neutral-64">
        {t("v2.positions.show.average_position_tenure", {
          count: position.tenureByPerson[person.id],
        })}
      </div>
    </div>
  </div>
)

export { PreviouslyFilledBy }
