import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"

import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { Link } from "v2/react/shared/navigation/PageNav/LinkGroup"

interface Props<T extends string = string> {
  classes?: string
  links: Link<T>[]
  activeLink?: Link
  menuText: string
  usePjax?: boolean
  onLinkClick?: (link: Link<T>) => (e: React.MouseEvent<HTMLAnchorElement>) => void
}

function LinkMenuGroup<T extends string = string>({
  classes,
  links,
  activeLink,
  menuText,
  usePjax,
  onLinkClick,
}: Props<T>) {
  return (
    <Dropdown align="right">
      <Dropdown.Trigger
        triggerClassName={classNames("btn dropdown-link items-center gap-1 !flex", classes)}
      >
        <span>{menuText}</span>
        <FontAwesomeIcon icon={["far", "chevron-down"]} />
      </Dropdown.Trigger>
      <Dropdown.Menu>
        {links.map((link: Link<T>) => (
          <Dropdown.Item
            useActiveStyles
            key={link.url}
            as="a"
            href={usePjax ? undefined : link.url}
            withIcon={
              link.text === activeLink?.text && (
                <FontAwesomeIcon icon={["fas", "circle-check"]} className="selected-icon ml-auto" />
              )
            }
            iconPosition="right"
            // Typing this right on the Dropdown.Item component is tricky,
            // opting out for now.
            // @ts-expect-error
            onClick={onLinkClick(link)}
          >
            {link.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { LinkMenuGroup }
