import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import RootProvider from "v2/react/components/RootProvider"
import { SuccessionMatrixModal } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/Modal/SuccessionMatrixModal"
import { useGetSuccessionMatrixSettingsQuery } from "v2/redux/GraphqlApi"
import {
  AxisModeSchema,
  CardTypeSchema,
  GridItemSchema,
  PaletteSchema,
  setAxisMode,
  setCardType,
  setGridItems,
  setPalette,
} from "v2/redux/slices/MatrixSlice"
import { useAppDispatch } from "v2/redux/store"

function EditMatrixSettingsButtonInner() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { data: matrix } = useGetSuccessionMatrixSettingsQuery({})
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  useEffect(() => {
    if (matrix) {
      dispatch(setCardType(CardTypeSchema.parse(matrix.cardType)))
      dispatch(setPalette(PaletteSchema.parse(matrix.paletteType)))
      dispatch(setGridItems(GridItemSchema.array().parse(matrix.gridItems)))
      dispatch(setAxisMode(AxisModeSchema.parse(matrix.axisMode)))
    }
  }, [matrix, dispatch])

  if (!matrix) return null

  const handleOpenModal = () => setEditModalIsOpen(true)
  const handleCloseModal = () => setEditModalIsOpen(false)

  return (
    <>
      <button
        className="btn--large btn--secondary tooltip tooltip-right"
        type="button"
        onClick={handleOpenModal}
      >
        <FontAwesomeIcon icon={["far", "gear"]} />
        <span className="tooltip-content tooltip-content--sm">
          {t("v2.succession_plan_panel.matrix.settings")}
        </span>
      </button>
      <SuccessionMatrixModal isOpen={editModalIsOpen} onClose={handleCloseModal} matrix={matrix} />
    </>
  )
}

function EditMatrixSettingsButton() {
  return (
    <RootProvider>
      <EditMatrixSettingsButtonInner />
    </RootProvider>
  )
}

export { EditMatrixSettingsButton }
