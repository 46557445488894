import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { Maybe } from "graphql/jsutils/Maybe"
import React from "react"
import { useTranslation } from "react-i18next"

import { Candidate, CrossCandidacy } from "types/graphql"
import { StandardRank } from "types/graphql.enums"
import { HighRiskTooltip } from "v2/react/components/succession/SuccessionPlanPanel/shared/HighRiskTooltip"
import { positionTitleDisplay } from "v2/react/components/succession/SuccessionPlanPanel/utils/positionMappers"
import { attributeBackgroundColorMapper } from "v2/react/components/succession/SuccessionPlanPanel/utils/successionUtils"
import { Avatar } from "v2/react/shared/icons/Avatar"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { UrlHelper } from "v2/react/utils/urls"

interface CandidateBadgeProps {
  candidate: Candidate
}

function CandidateBadge({ candidate }: CandidateBadgeProps) {
  const { t } = useTranslation()

  const crossCandidacies = candidate.crossCandidacies || []
  const highFlightRisk = candidate.person ? candidate.flightRiskLevel === StandardRank.High : false

  const determineMissingAttributes = (candidate: Candidate) => {
    const base = "v2.succession_plan_panel.matrix."
    let translation
    if (!candidate.person) {
      translation = "performance_potential_cannot_be_defined"
      return t(base + translation)
    }
    if (!candidate.performanceLevel && !candidate.potentialLevel) {
      translation = "performance_potential_blank"
      return t(base + translation)
    }

    if (!candidate.performanceLevel) {
      translation = "performance_blank"
      return t(base + translation, { level: candidate.potentialLevel })
    }
    if (!candidate.potentialLevel) {
      translation = "potential_blank"
      return t(base + translation, { level: candidate.performanceLevel })
    }

    return ""
  }

  return (
    <div
      className="border--elevation candidate-badge w-full grid-cols-[1fr_auto] items-center gap-2 rounded-full bg-white p-1 pr-2 grid group-[.unpositioned]:w-[14.25rem]"
      data-performance={candidate.performanceLevel}
      data-potential={candidate.potentialLevel}
    >
      <Tooltip key={candidate.id}>
        <TooltipTrigger className="min-w-0 items-center gap-2 flex">
          {candidate.person ? (
            <Avatar person={candidate.person} size="med" />
          ) : (
            <div className="circle circle-med bg-primary-8 text-primary-100">
              <FontAwesomeIcon icon={["far", "briefcase"]} />
            </div>
          )}
          <div className="truncate leading-3">
            {candidate.person ? (
              <a
                href={UrlHelper.personProfilePath(candidate.person.id)}
                className="link person-link cursor-pointer truncate p-0 text-left !text-sm-bold"
              >
                {candidate.person.name}
              </a>
            ) : (
              <p className="truncate text-sm-bold">{candidate.outsiderName}</p>
            )}
            {candidate.person?.primaryPosition && (
              <p className="truncate text-sm text-neutral-64">
                {positionTitleDisplay(candidate.person.primaryPosition, t)}
              </p>
            )}
          </div>
        </TooltipTrigger>
        {(!candidate.person || !candidate.performanceLevel || !candidate.potentialLevel) && (
          <TooltipContent className="react-tooltip-content !max-w-14">
            {determineMissingAttributes(candidate)}
          </TooltipContent>
        )}
      </Tooltip>
      <div className="ml-auto gap-2 flex">
        {(highFlightRisk || crossCandidacies.length > 0) && (
          <RiskLevelIconDisplay
            crossCandidacies={crossCandidacies}
            highFlightRisk={highFlightRisk}
          />
        )}
        {candidate.readinessLevel && candidate.readinessLevel !== "none" && (
          <ReadinessBadgeDisplay
            readinessLabel={candidate.readiness}
            readinessLevel={candidate.readinessLevel}
          />
        )}
      </div>
    </div>
  )
}

export { CandidateBadge }

const ReadinessBadgeDisplay = ({
  readinessLabel,
  readinessLevel,
}: {
  readinessLabel: Maybe<string>
  readinessLevel: Maybe<string>
}) => {
  const { t } = useTranslation()
  if (!readinessLevel) return null

  return (
    <Tooltip placement="bottom-end">
      <TooltipTrigger>
        <div
          className="readiness text-nowrap h-6 items-center gap-1 rounded-full border border-solid border-neutral-8 px-2 text-sm-bold text-neutral-64 flex"
          data-readiness={readinessLevel}
        >
          <div
            className={cn(
              "h-1.5 w-1.5 rounded-full",
              attributeBackgroundColorMapper[readinessLevel],
            )}
          />
          {t(`v2.succession_plan_panel.matrix.candidate_badge.readiness_display.${readinessLevel}`)}
        </div>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content react-tooltip-content--sm !max-w-[25rem]">
        {t("v2.succession_plan_panel.candidate.readiness")}: {readinessLabel}
      </TooltipContent>
    </Tooltip>
  )
}

interface RiskLevelIconDisplayProps {
  crossCandidacies: CrossCandidacy[]
  highFlightRisk: boolean
}

const RiskLevelIconDisplay = ({ crossCandidacies, highFlightRisk }: RiskLevelIconDisplayProps) => (
  <Tooltip placement="bottom-end">
    <TooltipTrigger>
      <div className="btn--icon btn--ghost cursor-default text-status-caution">
        <FontAwesomeIcon icon={["far", "triangle-exclamation"]} />
      </div>
    </TooltipTrigger>
    <TooltipContent className="react-tooltip-content !max-w-[25rem]">
      <HighRiskTooltip crossCandidacies={crossCandidacies} highFlightRisk={highFlightRisk} />
    </TooltipContent>
  </Tooltip>
)
