import React from "react"
import { useTranslation } from "react-i18next"

import { NewCareerLadderButton } from "v2/react/components/careerLadders/Index/NewCareerLadderButton"
import { withRootProvider } from "v2/react/components/RootProvider"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { TitleBlock, TitleHeaderWithParent } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { UtilityNav } from "v2/react/shared/navigation/UtilityNav"
import { UrlHelper } from "v2/react/utils/urls"

function Content() {
  const { t } = useTranslation()

  return (
    <>
      <PageNav>
        <TitleBlock>
          <TitleHeaderWithParent
            parentTitle={t("v2.career_ladders.header.job_architecture")}
            parentTitleUrl={UrlHelper.jobArchitecturePath()}
            title={t("v2.career_ladders.header.career_ladders")}
          />
        </TitleBlock>
        <ActionBlock> </ActionBlock>
      </PageNav>
      <UtilityNav>
        <div className="w-full items-center justify-end gap-2 flex">
          <NewCareerLadderButton />
        </div>
      </UtilityNav>
    </>
  )
}

const Header = withRootProvider(Content, "Header")

export { Header }
