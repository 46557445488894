import { bindActionCreators, Dictionary, EntityId } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import RelationalNodeDataStore from "org_chart/chart/utils/relationalNodeDataStore"

import { ColorCodeHash } from "types/graphql"
import { appContainer } from "v2/appContainer"
import type {
  BootstrapChartArg,
  BootstrapChartContainer,
  ChartFieldsContainer,
  ChartFieldsState,
  ChartSectionsContainer,
  ChartSettingsContainer,
  ColorCodingContainer,
  InitialColorCodingState,
  ProfilePanelContainer,
  StoreContainer,
} from "v2/redux/containers/types"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { startAppListening } from "v2/redux/listenerMiddleware"
import { setAbilities } from "v2/redux/slices/AbilitySlice"
import {
  chartSectionSelectors,
  setChartHistoryCutoffDate,
  setChartSections,
  setChartSectionTreeIndex,
  setCollapsedChartSectionByIds,
  setContainerKey,
  setFields,
} from "v2/redux/slices/ContainerSlice"
import {
  selectChartSectionsTree,
  selectFields,
  selectIsChartSectionCollapsed,
} from "v2/redux/slices/ContainerSlice/containerSelectors"
import {
  asyncCollapseOrExpandChartSection,
  asyncFetchChartSections,
  asyncLoadLists,
  asyncPatchPreferences,
  castServerChartSettings,
} from "v2/redux/slices/ContainerSlice/containerThunks"
import {
  ChartSectionsFetchResponsePayloadSchema,
  type ChartSection,
  type ContainerState,
} from "v2/redux/slices/ContainerSlice/types"
import { addFilter, setShowColumnStats } from "v2/redux/slices/GridSlice"
import {
  selectProfileSettings,
  setPersonId,
  setPositionId,
  updatePersonAttribute,
  updatePersonAvatar,
} from "v2/redux/slices/ProfilePanelSlice"
import {
  colorCodesPreloaded,
  configureColorCoding,
  patchSettings,
  setDataOptions,
} from "v2/redux/slices/VisualizationSlice"
import type { ServerChartSettings } from "v2/redux/slices/VisualizationSlice/types"
import {
  selectChartSettings,
  selectColorCodingServerState,
  selectColorCodingState,
} from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { AppDispatch, AppStore, RootState } from "v2/redux/store"

/**
 * Bootstraps a container for accessing, changing, and watching Org Chart state
 * outside of React components.
 */
export const bootstrapActiveChartContainer: BootstrapChartContainer = (bootstrapState) => {
  const store = appContainer.reduxStore
  const { chartId, initialColorCodes, initialColorCodingState } = bootstrapState
  setupChartScope(bootstrapState)
  store.dispatch(setShowColumnStats(bootstrapState.showColumnStats))

  return {
    chartId: bootstrapState.chartId,
    numericChartId: bootstrapState.numericChartId,
    containerType: bootstrapState.containerType,

    chartFields: bootstrapChartFieldsContainer(store, bootstrapState.initialFields),
    chartSections: bootstrapChartSectionsContainer(
      store,
      bootstrapState.initialChartSections,
      bootstrapState.initialChartSectionTreeIndex,
      bootstrapState.initialCollapsedChartSectionIds,
    ),
    chartSettings: bootstrapChartSettingsContainer(store, bootstrapState.initialChartSettings),
    profilePanel: bootstrapProfilePanelContainer(store),
    colorCoding: bootstrapColorCodingContainer(
      store,
      chartId,
      initialColorCodingState,
      initialColorCodes,
    ),
    store: bootstrapStoreContainer(store),
  }
}

function setupChartScope(bootstrapState: BootstrapChartArg) {
  const store = appContainer.reduxStore
  store.dispatch(setContainerKey(bootstrapState.chartId))
  store.dispatch(setAbilities(bootstrapState.abilities))
  store.dispatch(setChartHistoryCutoffDate(bootstrapState.chartHistoryCutoffDate || ""))

  // Once here, bail if we're not displaying a spreadsheet. The actions that
  // follow are specifically for the spreadsheet.
  if (bootstrapState.initialChartSettings.display_mode !== "grid") return

  const chartSectionId = bootstrapState.scopedToChartSectionId
  if (chartSectionId) {
    const byMatchingId = fp.propEq("id", Number(chartSectionId))
    const chartSection = fp.find(byMatchingId, bootstrapState.initialChartSections)
    const fieldKey = "chart_section"

    if (chartSection) store.dispatch(addFilter({ fieldKey, term: chartSection.name }))
  }

  const listId = bootstrapState.scopedToListId
  if (bootstrapState.scopedToListId) {
    const maybeSetId = typeof listId === "number" ? `list_${listId}` : listId

    store
      .dispatch(asyncLoadLists({ maybeSetId }))
      .then((list) => list && store.dispatch(addFilter({ fieldKey: "lists", term: list.name })))
  }
}

function bootstrapChartSectionsContainer(
  { dispatch, getState }: AppStore,
  initialChartSections?: ChartSection[],
  initialChartSectionTreeIndex?: ContainerState["chartSectionTreeIndex"],
  initialCollapsedChartSectionIds?: EntityId[],
): ChartSectionsContainer {
  const { selectAll, selectEntities, selectById, selectIds, selectTotal } = chartSectionSelectors
  const compactProps = (ids: EntityId[], lookup: Dictionary<ChartSection>) =>
    fp.compact(fp.props(ids, lookup))
  const preparedInitialState = ChartSectionsFetchResponsePayloadSchema.parse({
    chart_sections: initialChartSections || [],
    chart_sections_nested: initialChartSectionTreeIndex || {},
  })

  dispatch(setChartSections(preparedInitialState.chart_sections))
  dispatch(setChartSectionTreeIndex(preparedInitialState.chart_sections_nested))
  dispatch(setCollapsedChartSectionByIds(initialCollapsedChartSectionIds || []))

  const diffChartSections = (currentState: RootState, priorState: RootState) => {
    const priorSections = selectEntities(priorState)
    const priorIds = selectIds(priorState)
    const currentSections = selectEntities(currentState)
    const currentIds = selectIds(currentState)

    const createdIds = fp.without(priorIds, currentIds)
    const created = compactProps(createdIds, currentSections)
    const deletedIds = fp.without(currentIds, priorIds)
    const deleted = compactProps(deletedIds, priorSections)
    const updated: [ChartSection, ChartSection][] = []
    fp.values(currentSections).forEach((currentSection) => {
      const priorSection = currentSection ? priorSections[currentSection.id] : null
      if (!currentSection || !priorSection || priorSection === currentSection) return

      updated.push([currentSection, priorSection])
    })

    return { created, deleted, updated }
  }

  return {
    collapseOrExpandChartSection: (id, collapsed = undefined) =>
      dispatch(asyncCollapseOrExpandChartSection(id, collapsed)),
    fetchChartSections: () => dispatch(asyncFetchChartSections()),
    getChartSectionById: (id) => selectById(getState(), id),
    getChartSections: () => selectAll(getState()),
    getChartSectionsTotal: () => selectTotal(getState()),
    getChartSectionsTree: () => selectChartSectionsTree(getState()),
    isChartSectionCollapsed: (id) => selectIsChartSectionCollapsed(getState(), id),
    startListening: (listener) =>
      startAppListening({
        predicate: (_, currentState, prior) =>
          selectEntities(currentState) !== selectEntities(prior),
        effect: (_, api) => {
          const stateUpdates = diffChartSections(api.getState(), api.getOriginalState())
          listener(stateUpdates)
        },
      }),
  }
}

function bootstrapChartSettingsContainer(
  { dispatch, getState }: AppStore,
  initialChartSettings: ServerChartSettings,
): ChartSettingsContainer {
  const fromInitialServerSettings = castServerChartSettings(initialChartSettings)
  dispatch(patchSettings(fromInitialServerSettings))

  return {
    getChartSettings: () => selectChartSettings(getState()),
    updateChartSettings: (patch: Partial<ServerChartSettings>) =>
      dispatch(asyncPatchPreferences(patch)),
    startListening: (listener) =>
      startAppListening({
        predicate: (_, currentState, priorState) =>
          selectChartSettings(currentState) !== selectChartSettings(priorState),
        effect: () => listener(selectChartSettings(getState())),
      }),
  }
}

function bootstrapChartFieldsContainer(
  { dispatch, getState }: AppStore,
  initialFieldState: ChartFieldsState,
): ChartFieldsContainer {
  const { instance: related } = RelationalNodeDataStore
  const fieldHashes = fp.map(
    (fieldKey) => ({
      fieldKey,
      enabled: true,
      filterable: fieldKey !== "avatar",
      hideLabel: fieldKey === "avatar",
      label: related.hasFieldDefinition(fieldKey)
        ? (related.getFieldLabel(fieldKey, fieldKey) as string)
        : `field_${fieldKey}`.t("org_chart"),
      restricted: false,
      width: fieldKey === "avatar" ? 40 : undefined,
      format:
        initialFieldState.selectedDataFieldsInfo?.find((info) => info.key === fieldKey)?.format ||
        "text",
    }),
    initialFieldState.selectedDisplayFields,
  )

  dispatch(setFields(fieldHashes))
  dispatch(setDataOptions(initialFieldState))

  return {
    getFields: () => selectFields(getState()),
    startListening: (listener) =>
      startAppListening({
        predicate: (_, currentState, priorState) =>
          selectFields(currentState) !== selectFields(priorState),
        effect: () => listener(selectFields(getState())),
      }),
  }
}

function bootstrapColorCodingContainer(
  { dispatch, getState }: AppStore,
  containerKey: EntityId,
  initialColorCodingState: InitialColorCodingState,
  initialColorCodes?: ColorCodeHash[],
): ColorCodingContainer {
  const { configureColorCoding, prefetchColorCodes, updateChartSettings } = actionsWith(dispatch)
  const { edgeColorField, cardColorField } = initialColorCodingState
  let inDomains

  configureColorCoding(initialColorCodingState)
  if (edgeColorField || cardColorField) inDomains = fp.compact([edgeColorField, cardColorField])
  if (!initialColorCodes)
    prefetchColorCodes("getColorCodes", { key: `${containerKey}`, inDomains }, { force: true })
  else dispatch(colorCodesPreloaded({ colorCodes: initialColorCodes }))

  startAppListening({
    predicate: (_, currentState, priorState) =>
      selectColorCodingServerState(currentState) !== selectColorCodingServerState(priorState),
    effect: (_, { getState }) => {
      updateChartSettings(selectColorCodingServerState(getState()))
    },
  })

  return {
    getColorCodingState: () => selectColorCodingState(getState()),
    setPreloadedColorCodes: (arg) => dispatch(colorCodesPreloaded(arg)),
    startListening: (listener) =>
      startAppListening({
        predicate: (_, currentState, priorState) =>
          selectColorCodingState(currentState) !== selectColorCodingState(priorState),
        effect: () => listener(selectColorCodingState(getState())),
      }),
  }
}

function bootstrapProfilePanelContainer({ dispatch, getState }: AppStore): ProfilePanelContainer {
  return {
    setPersonId: (arg) => dispatch(setPersonId(arg)),
    setPositionId: (arg) => dispatch(setPositionId(arg)),
    updatePersonAvatar: (arg) => dispatch(updatePersonAvatar(arg)),
    updatePersonAttribute: (arg) => dispatch(updatePersonAttribute(arg)),
    getProfilePanelSettings: () => getState().profilePanel,
    startListening: (listener) =>
      startAppListening({
        actionCreator: updatePersonAvatar,
        effect: () => listener(selectProfileSettings(getState())),
      }),
  }
}

function bootstrapStoreContainer({ dispatch, getState }: AppStore): StoreContainer {
  return { dispatch, getState, startListening: startAppListening }
}

const actionsWith = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      configureColorCoding,
      prefetchColorCodes: GraphqlApi.util.prefetch,
      patchSettings,
      updateChartSettings: asyncPatchPreferences,
    },
    dispatch,
  )
