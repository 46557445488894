import cn from "classnames"
import React, { FormEvent } from "react"
import { useTranslation } from "react-i18next"

import { toggleSuperPanelFooter } from "v2/redux/slices/VisualizationSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface Props {
  disableSubmit?: boolean
  onCancel: () => void
  onSave: (e: FormEvent) => void
  doFormSubmit?: boolean
  cancelText?: string
}

function DrawerFooter({
  disableSubmit,
  onCancel,
  onSave,
  doFormSubmit = true,
  cancelText = "",
}: Props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const superPanelFooter = useAppSelector((state) => state.visualization.superPanelFooter)

  const handleCancel = () => {
    onCancel()
    dispatch(toggleSuperPanelFooter(false))
  }

  return (
    <div className={cn("drawer-section-footer", { hidden: !superPanelFooter })}>
      <div className="drawer-section-footer-buttons gap-2 p-2">
        <button type="button" className="btn--sm btn--secondary" onClick={handleCancel}>
          {cancelText || t("v2.orgchart.super_panel.clear_changes")}
        </button>
        <button
          type={doFormSubmit ? "submit" : "button"}
          onClick={onSave}
          className={cn("btn--sm btn--primary", { disabled: disableSubmit })}
          disabled={disableSubmit}
        >
          {t("v2.orgchart.super_panel.apply")}
        </button>
      </div>
    </div>
  )
}

export { DrawerFooter }
