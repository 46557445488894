import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import OrgChart from "org_chart/chart/orgChart"
import React, { useEffect } from "react"

import { FeatureFlags } from "types/graphql"
import { GroupDataButton } from "v2/react/components/orgChart/Navigation/Utility/GroupDataButton"
import { MetricsButton } from "v2/react/components/orgChart/Navigation/Utility/MetricsButton"
import { UtilitySearch } from "v2/react/components/orgChart/Navigation/Utility/UtilitySearch"
import { focusInputOnSelection } from "v2/react/components/orgChart/SuperPanel/superPanelUtils"
import { TabSwitch } from "v2/react/components/orgChart/SuperPanel/TabSwitch"
import { ChartNodeHistorySearchInput } from "v2/react/shared/forms/ChartNodeHistorySearchInput"
import { Toggle, ToggleButton, ToggleLabel, ToggleSwitch } from "v2/react/shared/forms/Toggle"
import { UtilityNav } from "v2/react/shared/navigation/UtilityNav"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import {
  selectCanEditChartNodes,
  selectCanTimeTravelChart,
} from "v2/redux/slices/AbilitySlice/abilitySelectors"
import { setEditMode } from "v2/redux/slices/VisualizationSlice"
import { selectChartSettings } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { TimeTravelStatusBadge } from "../HistoricalOrgChart/TimeTravelStatusBadge"
import { OrgChartViewOptions } from "./OrgChartViewOptions"

interface Props {
  canAddPosition: boolean
  // Noting that this prop applies to lists as well as charts.
  canManageChart: boolean
  canViewMetrics: boolean
  ff: FeatureFlags
  chart?: OrgChart
  onSelectSearch?: () => void
}

function OrgChartUtilityNav({
  canAddPosition,
  canManageChart,
  canViewMetrics,
  ff,
  chart,
  onSelectSearch,
}: Props) {
  const dispatch = useAppDispatch()
  const canEditChartNodes = useAppSelector(selectCanEditChartNodes)
  const canTimeTravelChart = useAppSelector(selectCanTimeTravelChart)
  const { displayMode, historyMode, historyModeSelectedDate } = useAppSelector(selectChartSettings)
  const editMode = useAppSelector((state) => state.visualization.editMode)
  const isListView = window.location.pathname.indexOf("lists/") > 0
  const showNewPositionButton = !isListView && canAddPosition
  const orgChartId = useAppSelector((state) => state.container.containerKey)

  useEffect(() => {
    focusInputOnSelection()
  }, [])

  const manageList = (e: React.MouseEvent) => {
    e.preventDefault()
    pjaxModalFor(`${window.location.pathname.replace("orgchart/", "")}/edit`)
  }

  const newPosition = (e: React.MouseEvent) => {
    e.preventDefault()
    pjaxModalFor(UrlHelper.newPositionChartPath(window.gon.selected_chart.id))
  }

  return (
    <UtilityNav>
      <div className="items-center gap-2 flex">
        {displayMode === "grid" && <GroupDataButton />}
        <TabSwitch ff={ff} />
        {canViewMetrics && <MetricsButton />}
      </div>

      {canTimeTravelChart && (
        <div className="items-center lg:absolute lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:transform">
          <TimeTravelStatusBadge />
        </div>
      )}

      <div className="items-center justify-end gap-2 flex md:w-full">
        {displayMode === "grid" && canEditChartNodes ? (
          <Toggle
            id="edit-mode-toggle"
            initialValue={editMode}
            onToggle={() => dispatch(setEditMode(!editMode))}
          >
            <ToggleButton
              disabled={historyMode === true}
              classes="btn sm:btn--large btn--secondary p-2 sm:p-3 tooltip tooltip-right"
            >
              <ToggleLabel
                classes="hidden sm:block"
                disabledLabel={"Edit mode".t("org_chart")}
                enabledLabel={"Edit mode".t("org_chart")}
              />
              <ToggleSwitch />
              <span className="tooltip-content tooltip-content--sm sm:invisible sm:hidden">
                {"Edit mode".t("org_chart")}
              </span>
            </ToggleButton>
          </Toggle>
        ) : null}
        {displayMode !== "grid" && !isListView && !historyMode && <UtilitySearch ff={ff} />}
        {displayMode !== "grid" &&
          !isListView &&
          historyMode &&
          historyModeSelectedDate &&
          orgChartId && (
            <ChartNodeHistorySearchInput
              chartKey={orgChartId}
              asOf={historyModeSelectedDate}
              onSelect={onSelectSearch}
            />
          )}
        {showNewPositionButton ? (
          <button
            className={cn("btn btn--primary sm:btn--large sm:btn--primary tooltip tooltip-right", {
              disabled: window.gon.editing_disabled,
            })}
            disabled={window.gon.editing_disabled || historyMode}
            onClick={newPosition}
            type="button"
          >
            <FontAwesomeIcon icon={["far", "plus"]} />
            <span className="hidden md:flex">{"Add Position".t("org_chart")}</span>
            <span className="tooltip-content tooltip-content--sm md:invisible md:hidden">
              {"Add Position".t("org_chart")}
            </span>
          </button>
        ) : null}
        {/* `canManageChart` applies to list views as well */}
        {isListView && canManageChart && (
          <button
            className="btn sm:btn--large btn--primary sm:btn--primary manage-list"
            disabled={window.gon.editing_disabled}
            onClick={manageList}
            type="button"
          >
            {"Manage List".t("org_chart")}
          </button>
        )}
      </div>

      {displayMode !== "grid" && chart && <OrgChartViewOptions chart={chart} />}
    </UtilityNav>
  )
}

export default OrgChartUtilityNav
