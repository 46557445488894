import OrgChart from "org_chart/chart/orgChart"
import RelationalNodeDataStore from "org_chart/chart/utils/relationalNodeDataStore"
import React, { useMemo, useRef, useState } from "react"

import { Position } from "types/graphql"
import { useOrgChart } from "v2/react/components/headcountPlanning/hooks/useOrgChart"
import { OrgChartSearchInput } from "v2/react/components/headcountPlanning/OrgChart/OrgChartSearchInput"
import { InProgressAlert } from "v2/react/components/headcountPlanning/shared/InProgressAlert"
import { DisplayState } from "v2/react/components/headcountPlanning/types"
import { OrgChartViewOptions } from "v2/react/components/orgChart/Navigation/OrgChartViewOptions"
import { SuperPanel } from "v2/react/components/orgChart/SuperPanel/SuperPanel"
import { TabSwitch } from "v2/react/components/orgChart/SuperPanel/TabSwitch"
import RootProvider from "v2/react/components/RootProvider"
import { useOffsetHeight } from "v2/react/hooks/useOffsetHeight"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { UtilityNav } from "v2/react/shared/navigation/UtilityNav"
import { getCookie } from "v2/react/utils/cookies"
import { getVisualHeight } from "v2/react/utils/layout"
import { UrlHelper } from "v2/react/utils/urls"
import { bootstrapActiveChartContainer } from "v2/redux/containers/activeChartContainer"
import { BootstrapChartArg, ChartContainer } from "v2/redux/containers/types"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"
import { useGetParticipantOrgChartQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { useAppSelector } from "v2/redux/store"

RelationalNodeDataStore.load({ keys: {}, index: {} })

interface InnerProps {
  headcountPlanId: string
  participantId: string
  activeChart: ChartContainer
}

export function ParticipantOrgChartInner({
  headcountPlanId,
  participantId,
  activeChart,
}: InnerProps) {
  const displayStateCookie = getCookie(
    `built__display-state-for-hcp-${headcountPlanId}`,
  ) as DisplayState
  const displayState =
    useAppSelector((state) => state.headcountPlan.displayState) ?? displayStateCookie ?? "approved"
  const featureFlags = useGetFeatureFlagsQuery()
  const ff = featureFlags?.data?.currentCompany?.featureFlags
  const { offsetHeight } = useOffsetHeight()
  const { data, isFetching } = useGetParticipantOrgChartQuery({
    headcountPlanId,
    participantId,
  })
  const headcountPlan = data?.headcountPlan
  const isFinalized = !!headcountPlan?.lockedAt
  const participant = headcountPlan?.participant
  const isLocked = participant?.status === "complete"
  const hcpPositions =
    isFinalized && displayState === "approved"
      ? participant?.approvedPositionsForOrgchart
      : participant?.allPositionsForOrgchart
  const person = participant?.person
  const position = participant?.position
  const [chart, setChart] = useState<OrgChart | null>(null)
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const positionsEndpoint = UrlHelper.headcountPlanOrganizePath(
    headcountPlanId,
    `?participant_id=${participant?.id}&display_state=${displayState}`,
  )
  const isParticipantPage = true

  const topNode = useMemo(
    () => ({
      id: position?.uniqueKey || "top",
      name: person?.name,
      klass: "Position",
      title: position?.title,
    }),
    [position, person],
  )

  const { addAsChild } = useOrgChart(
    activeChart,
    chart,
    chartContainerRef,
    hcpPositions,
    headcountPlanId,
    isFetching,
    isParticipantPage,
    positionsEndpoint,
    setChart,
    headcountPlan?.startDate,
    topNode,
    participantId,
    participant?.position?.id,
  )

  const onSelect = (item: Position) => {
    if (item.uniqueKey) chart?.focus(item.uniqueKey)
  }

  return (
    <div className="bg-white">
      <UtilityNav>
        <div className="items-center gap-2 flex">
          {ff && <TabSwitch ff={ff} hcpIsLocked={isLocked} />}
        </div>
        {!isFetching && !participant?.canSubmit && <InProgressAlert />}
        <div className="items-center justify-end gap-2 flex md:w-full">
          <OrgChartSearchInput
            headcountPlanId={headcountPlanId}
            participantId={participantId}
            onSelect={onSelect}
          />
        </div>
        <OrgChartViewOptions chart={chart} />
      </UtilityNav>

      <div className="relative bg-white">
        {chart && ff && (
          <SuperPanel
            ff={ff}
            offsetHeight={offsetHeight}
            chart={chart}
            addAsChild={addAsChild}
            hcpIsLocked={isLocked}
          />
        )}
      </div>
      {isFetching && <Spinner />}
      <div
        ref={chartContainerRef}
        className="organize-container bg-primary-3-solid"
        id="organize-container"
        style={{ height: getVisualHeight() }}
      />
    </div>
  )
}

interface Props {
  headcountPlanId: string
  participantId: string
  settings: BootstrapChartArg
}
const ParticipantOrgChart = ({ headcountPlanId, participantId, settings }: Props) => {
  const activeChart = bootstrapActiveChartContainer(settings)

  return (
    <RootProvider>
      <ParticipantOrgChartInner
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        activeChart={activeChart}
      />
    </RootProvider>
  )
}

export { ParticipantOrgChart }
