import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import ClosePanelButton from "v2/react/components/orgChart/SuperPanel/ClosePanelButton"

const ChartSectionsTabHeader = () => {
  const { t } = useTranslation()

  return (
    <div className="drawer-header">
      <div className="items-center gap-2 flex">
        <p className="drawer-title">{t("v2.orgchart.utilitynav.chart_sections")}</p>

        <div className="tooltip tooltip-left tooltip-wrap inline-block">
          <FontAwesomeIcon icon={["far", "circle-info"]} />
          <div className="tooltip-content w-40">
            {"Chart sections include a top position and all subordinate positions.".t("org_chart")}
          </div>
        </div>
      </div>
      <ClosePanelButton />
    </div>
  )
}

export { ChartSectionsTabHeader }
