import React from "react"

import { withRootProvider } from "v2/react/components/RootProvider"
import PageNav from "v2/react/shared/navigation/PageNav"
import { TitleBlock, TitleHeaderWithParent } from "v2/react/shared/navigation/PageNav/TitleBlock"

interface Props {
  parentTitle: string | null
  parentTitleUrl: string
  title: string
}

/**
 * This component is used for Page Navigation when there should be a link back
 * to a parent page. Presently, none of these subpages have tabs or actions.
 */
function BasicPageNavWithLinkBack({ parentTitle, parentTitleUrl, title }: Props) {
  return (
    <PageNav>
      <TitleBlock>
        {parentTitle ? (
          <TitleHeaderWithParent
            parentTitle={parentTitle}
            parentTitleUrl={parentTitleUrl}
            title={title}
          />
        ) : (
          <h1>{title}</h1>
        )}
      </TitleBlock>
    </PageNav>
  )
}

const BasicPageNavWithLinkBackWithProvider = withRootProvider(
  BasicPageNavWithLinkBack,
  "BasicPageNavWithLinkBackWithProvider",
)

export { BasicPageNavWithLinkBack, BasicPageNavWithLinkBackWithProvider }
