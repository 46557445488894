import fp from "lodash/fp"
import { NodeData } from "org_chart/chart/node/types"

import { ExtendedHierarchyNode } from "v2/react/components/orgChart/HistoricalOrgChart/utils"

/**
 * Filter function for deciding if a descendant should be counted.
 */
const isCountableDescendant = (
  d: NodeData,
  descendant: NodeData,
  countOpenPositions: boolean,
  countDottedRelationships: boolean,
): boolean => {
  if (descendant.id === d.id) return false
  if (!countOpenPositions && !descendant.first_name) return false
  if (!countDottedRelationships && descendant.type === "secondary") return false

  return true
}

/**
 * Unwraps the data object, builds counts, and assigns chart section and
 * title meta to each node in case that node is displayed at the top.
 * @param hierarchyNode Top node of chart
 * @param chartSettings
 * @returns
 */
const unwrapDataAndBuildMetaFromHierarchy = (
  hierarchyNode: ExtendedHierarchyNode,
  chartSettings: {
    countOpenPositions: boolean
    countDottedRelationships: boolean
    childrenCount: "all" | "immediate"
  },
): ExtendedHierarchyNode => {
  const titles: string[] = []
  const chartSections: string[] = []

  hierarchyNode.each((node) => {
    const nodeData = node.data as NodeData

    const title = nodeData.title
    if (title) titles.push(title)

    const chartSection = nodeData.chart_section
    if (chartSection) chartSections.push(chartSection)

    const countableDescendants = node
      .descendants()
      .filter((descendant) =>
        isCountableDescendant(
          node.data,
          descendant.data,
          chartSettings.countOpenPositions,
          chartSettings.countDottedRelationships,
        ),
      )
    const countableChildren = node.children?.filter((descendant) =>
      isCountableDescendant(
        node.data,
        descendant.data,
        chartSettings.countOpenPositions,
        chartSettings.countDottedRelationships,
      ),
    )

    Object.assign(node, {
      ...nodeData,
      total_subordinates: node.descendants().length - 1,
      total_direct_reports: node.children?.length || 0,
      children_count:
        chartSettings.childrenCount === "all"
          ? countableDescendants.length
          : countableChildren?.length || 0,
    })
  })

  hierarchyNode.each((node) => {
    Object.assign(node, {
      meta: {
        aggregates: {
          titles: titles
            .filter((value, index, array) => array.indexOf(value) === index)
            .sort((t) => t.length),
          chart_sections: chartSections
            .filter((value, index, array) => array.indexOf(value) === index)
            .sort((c) => c.length),
        },
      },
    })
  })

  return hierarchyNode
}

/**
 * Function to find assistants in the children array and reassign them to the assistants array.
 * @param d Current top node of the chart
 */
const reassignAssistants = (d: ExtendedHierarchyNode) => {
  if (!d.children) return
  if (d.assistants) return // The assistants have already been reassigned on this node
  if (d.data.klass === "Company" && d.children.length > 1) return

  const topNode = d.data.klass === "Company" ? d.children[0] : d

  const [assistants, nonAssistants] = fp.partition(
    (node) => node.data.is_assistant === true,
    topNode.children,
  )

  Object.assign(topNode, { children: nonAssistants, assistants })
}

export { unwrapDataAndBuildMetaFromHierarchy, reassignAssistants }
