import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

function NavActiveIcon() {
  const { t } = useTranslation()

  return (
    <div className="tooltip tooltip-right">
      <FontAwesomeIcon icon={["far", "bolt"]} className="text-status-info" />
      <span className="tooltip-content tooltip-content--sm">
        {t("v2.headcount_plan.active_plan")}
      </span>
    </div>
  )
}

export { NavActiveIcon }
