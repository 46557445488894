import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useRef, type ReactNode } from "react"
import { useTranslation } from "react-i18next"

import { DraggableList } from "v2/react/shared/collection/DraggableList/DraggableList"
import { SearchableDropdown } from "v2/react/shared/forms/SearchableDropdown"

interface SortableItemType {
  id: string
  label: string
  disabled?: boolean
  /**
   * If this is set on the items, then the checkboxes will be controlled. In
   * this case, ensure that a handler is passed to the `onCheckboxChange` prop.
   */
  checked?: boolean
}

interface FieldsetWithSearchProps<T> {
  id: string
  label?: string
  description?: string
  selectedItems: T[]
  searchableItems: T[]
  onReorder: (updatedItems: T[]) => void
  onSearchSelect: (selectedItem: T) => void
  onCheckboxChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  makeLabel?: (item: T) => ReactNode
  makeCheckboxId?: (item: T) => string
  makeCheckboxName?: (item: T) => string
  searchPlaceholder?: string
  noResultsPlaceholder?: string
  /**
   * Adds a border around the search field and the list of selected items.
   */
  useBorder?: boolean
}

const FieldsetWithSearch = <T extends SortableItemType>({
  id,
  label,
  description,
  selectedItems,
  searchableItems,
  onReorder,
  onSearchSelect,
  onCheckboxChange,
  makeLabel,
  makeCheckboxId,
  makeCheckboxName,
  searchPlaceholder,
  noResultsPlaceholder,
  useBorder,
}: FieldsetWithSearchProps<T>) => {
  const dragContainerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const isHCPDataOptions =
    window.location.pathname.indexOf("headcount_plans/") > 0 && id === "advanced-display-options"

  return (
    <fieldset id={id} className="mb-0 min-w-0 flex-col flex">
      {label && (
        <legend
          className={cn("text-base-bold", {
            "mb-1": !description,
          })}
        >
          {label}
        </legend>
      )}
      {isHCPDataOptions && <span className="text-neutral-64">{t("v2.defaults.coming_soon")}</span>}
      {!isHCPDataOptions && (
        <>
          {description && <p className="mb-1 text-neutral-64">{description}</p>}
          <div
            className={cn({
              "list-group is-draggable": useBorder,
            })}
          >
            <div
              className={cn("w-full pb-1", {
                "px-4 pt-2": useBorder,
              })}
            >
              <SearchableDropdown
                id={`${id}-search`}
                items={searchableItems}
                onSelect={onSearchSelect}
                searchPlaceholder={searchPlaceholder}
                noResultsPlaceholder={noResultsPlaceholder}
                makeLabel={makeLabel}
              />
            </div>
            <DraggableList
              dragContainerRef={dragContainerRef}
              items={selectedItems}
              handleReorder={onReorder}
              useListGroupStyle={!useBorder}
              draggableListClasses={cn({
                "shadow-none p-0 z-auto list-group is-draggable": !useBorder,
                "pt-1": selectedItems.length === 0 && useBorder,
                "pb-1": selectedItems.length > 0 && useBorder,
              })}
            >
              {selectedItems.map((item) => (
                <DraggableList.Item
                  key={`${id}-${item.id}`}
                  item={item}
                  dragContainerRef={dragContainerRef}
                  itemClasses={cn("py-1", {
                    "pl-1 pr-2": !useBorder,
                  })}
                >
                  <Checkbox
                    checkboxId={makeCheckboxId ? makeCheckboxId(item) : item.id}
                    checkboxName={makeCheckboxName ? makeCheckboxName(item) : item.id}
                    label={makeLabel ? makeLabel(item) : item.label}
                    item={item}
                    onChange={onCheckboxChange}
                  />
                </DraggableList.Item>
              ))}
            </DraggableList>
          </div>
        </>
      )}
    </fieldset>
  )
}

const Checkbox = ({
  checkboxId,
  checkboxName,
  label,
  item,
  onChange,
}: {
  checkboxId: string
  checkboxName: string
  label: ReactNode
  item: SortableItemType
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
}) => (
  <div className="w-full gap-3 flex">
    <FontAwesomeIcon
      icon={["fas", "grip-dots-vertical"]}
      size="sm"
      className="mt-1 items-start self-stretch"
    />
    <label
      htmlFor={checkboxId}
      className={cn(
        "!m-0 w-full min-w-0 items-start self-stretch font-medium transition-colors flex",
        { "cursor-pointer": !item.disabled, disabled: item.disabled },
      )}
    >
      <input
        type="checkbox"
        className="!mr-2 mt-1"
        id={checkboxId}
        name={checkboxName}
        data-show-field={item.id}
        onChange={onChange}
        value={item.id}
        disabled={item.disabled}
        // This checkbox is controlled if the `checked` prop is set on the
        // items.
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(item.checked !== undefined ? { checked: item.checked } : { defaultChecked: true })}
      />
      <div className="w-full min-w-0 break-words">{label}</div>
    </label>
  </div>
)

export { FieldsetWithSearch }
export type { FieldsetWithSearchProps, SortableItemType }
