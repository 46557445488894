import React from "react"
import { useTranslation } from "react-i18next"

import { BannerError } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/SendToAtsModal"
import { Notice } from "v2/react/shared/status/Notice"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

const Banners = ({ bannerErrors }: { bannerErrors: BannerError[] }) => {
  const { t } = useTranslation()

  return (
    <>
      <Notice type="caution" icon={["far", "triangle-exclamation"]}>
        {t("v2.job_requisitions.modals.send_to_ats.smart_recruiters.notice")}
      </Notice>
      {bannerErrors.map((error) => (
        <Notice key={error.key} type="critical" icon={["far", "circle-exclamation"]}>
          {sanitizeParse(error.message)}
        </Notice>
      ))}
    </>
  )
}

export { Banners }
