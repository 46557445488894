import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlanStatus } from "types/graphql.enums"
import { HeadcountPlanningNavTitleWithSubtitle } from "v2/react/components/headcountPlanning/HeadcountPlanningNav"
import { useHCPOwnerHeaderData } from "v2/react/components/headcountPlanning/hooks/useHCPHeaderData"
import {
  FinalizePlanButton,
  FinalizePlanModal,
} from "v2/react/components/headcountPlanning/Overview/FinalizePlanButton"
import { HCPStatusBadge } from "v2/react/components/headcountPlanning/shared/HCPStatusBadge"
import { NavActiveIcon } from "v2/react/components/headcountPlanning/shared/NavActiveIcon"
import { PlanTopbarMenu } from "v2/react/components/headcountPlanning/shared/PlanTopbarMenu"
import { withRootProvider } from "v2/react/components/RootProvider"
import { ClientOnly } from "v2/react/shared/layout/ClientOnly"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { UrlHelper } from "v2/react/utils/urls"

const Content = ({
  name,
  id,
  lockedAt,
  currentUserCanManage,
  canBeFinalized,
  hasActiveStatus,
  setBudget,
  initialStatus,
}: {
  name: string
  id: string
  lockedAt: string | null
  currentUserCanManage: boolean
  canBeFinalized: boolean
  hasActiveStatus: boolean
  setBudget: boolean
  initialStatus: HeadcountPlanStatus
}) => {
  const { t } = useTranslation()
  const [finalizePlanModalIsOpen, setFinalizePlanModalIsOpen] = useState(false)

  const {
    currentIsFinalized,
    currentCanBeFinalized,
    currentSetBudget,
    currentHasActiveStatus,
    currentCurrentUserCanManage,
    currentStatus,
  } = useHCPOwnerHeaderData({
    headcountPlanId: id,
    setBudget,
    hasActiveStatus,
    lockedAt,
    canBeFinalized,
    currentUserCanManage,
    initialStatus,
  })

  return (
    <PageNav>
      <TitleBlockLarge>
        <HeadcountPlanningNavTitleWithSubtitle
          title={name || ""}
          subtitle={t("v2.headcount_plan.headcount_planning")}
          subtitleLinkTo={UrlHelper.headcountPlansPath()}
        />
      </TitleBlockLarge>
      <ActionBlock>
        <div className="items-center gap-2 flex">
          {currentHasActiveStatus ? <NavActiveIcon /> : null}
          {currentStatus ? <HCPStatusBadge status={currentStatus} /> : null}
          {!currentCurrentUserCanManage || currentIsFinalized ? null : (
            <>
              <ClientOnly>
                <FinalizePlanModal
                  headcountPlanId={id}
                  headcountPlanName={name}
                  isOpen={finalizePlanModalIsOpen}
                  setIsOpen={setFinalizePlanModalIsOpen}
                />
              </ClientOnly>

              <FinalizePlanButton
                canBeFinalized={currentCanBeFinalized}
                setIsOpen={setFinalizePlanModalIsOpen}
              />
            </>
          )}
          {!currentCurrentUserCanManage || currentIsFinalized ? null : (
            <PlanTopbarMenu
              headcountPlanId={id}
              isFinalized={currentIsFinalized}
              setBudget={!!currentSetBudget}
            />
          )}
        </div>
      </ActionBlock>
    </PageNav>
  )
}

const OverviewHeader = withRootProvider(Content, "OverviewHeader")

export { OverviewHeader }
