import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlanStatus } from "types/graphql.enums"

function HCPStatusBadge({ status }: { status: HeadcountPlanStatus }) {
  const { t } = useTranslation()

  if (status === HeadcountPlanStatus.Approved) {
    return (
      <div className="badge--green items-center gap-2 flex">
        <FontAwesomeIcon icon={["far", "check"]} size="1x" />
        {t("v2.headcount_plan.status.plan_approved")}
      </div>
    )
  }
  if (status === HeadcountPlanStatus.InProgress) {
    return <div className="badge--yellow">{t("v2.headcount_plan.status.in_progress")}</div>
  }
  if (status === HeadcountPlanStatus.AllPlansSubmitted) {
    return <div className="badge--purple">{t("v2.headcount_plan.status.all_plans_submitted")}</div>
  }
}

export { HCPStatusBadge }
