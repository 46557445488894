import React from "react"
import { useTranslation } from "react-i18next"

import { withRootProvider } from "v2/react/components/RootProvider"
import PageNav from "v2/react/shared/navigation/PageNav"
import { LinkBlock } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { Link, LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlock } from "v2/react/shared/navigation/PageNav/TitleBlock"

interface Props {
  links: Link[]
}

function Content({ links }: Props) {
  const { t } = useTranslation()
  return (
    <PageNav>
      <TitleBlock>
        <h1>{t("v2.shared.nav.time_approvals")}</h1>
      </TitleBlock>
      <LinkBlock>
        <LinkGroup links={links} usePjax />
      </LinkBlock>
    </PageNav>
  )
}

const TimeApprovalsNav = withRootProvider(Content, "TimeApprovalsNav")

export { TimeApprovalsNav }
