import { TFunction } from "i18next"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlanMember, Person } from "types/graphql.d"
import { HeadcountPlanRole } from "types/graphql.enums"
import { HeadcountPlanningNavTitleWithSubtitle } from "v2/react/components/headcountPlanning/HeadcountPlanningNav"
import { HCPStepper } from "v2/react/components/headcountPlanning/shared/HCPStepper"
import { withRootProvider } from "v2/react/components/RootProvider"
import PageNav from "v2/react/shared/navigation/PageNav"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { headcountPlanMemberNameAsc } from "v2/react/utils/sorts"
import { UrlHelper } from "v2/react/utils/urls"
import {
  useGetHeadcountPlanQuery,
  useSaveHeadcountPlanMembersMutation,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { InvitationsForm, InvitationsFormRef } from "./InvitationsForm"
import { StickyFooterBar } from "./StickyFooterBar"

interface Props {
  headcountPlanId: string
  companyId: number | null
  currentPersonId: number | null
}

function Invitations({ headcountPlanId, companyId, currentPersonId }: Props) {
  const [members, setMembers] = useState<HeadcountPlanMember[] | null>(null)
  const planQueryResult = useGetHeadcountPlanQuery({ id: headcountPlanId })
  const planMembers = planQueryResult.data?.headcountPlan?.members
  const [savePlanParticipants, { isLoading: isLoadingSave }] = useSaveHeadcountPlanMembersMutation()
  const selectedMembers: HeadcountPlanMember[] = (members || planMembers || [])
    .slice()
    .sort(headcountPlanMemberNameAsc)
  const formRef = React.useRef<InvitationsFormRef>(null)
  const { t } = useTranslation()

  if (!companyId || !currentPersonId || !planMembers) return null

  const setBudget = planQueryResult?.data?.headcountPlan?.setBudget

  const addPerson = (selectedPerson: Person) => {
    const newMember = {
      id: selectedPerson.id,
      uniqueKey: `new_member_${selectedPerson.id}`,
      // Use "Participant" ("Planner" in the UI) by default
      role: selectedPerson.isCompanyManagerOrHrAdmin
        ? HeadcountPlanRole.Owner
        : HeadcountPlanRole.Participant,
      person: selectedPerson,
    }
    setMembers([...selectedMembers, newMember])
  }

  const removeMember = (selectedMember: HeadcountPlanMember) => {
    setMembers(
      selectedMembers.filter((m: HeadcountPlanMember) => m.uniqueKey !== selectedMember.uniqueKey),
    )
  }

  const updateMember = (updatedMember: HeadcountPlanMember) => {
    const withRemoved: HeadcountPlanMember[] = selectedMembers.filter(
      (m: HeadcountPlanMember) => m.uniqueKey !== updatedMember.uniqueKey,
    )
    setMembers(withRemoved.concat(updatedMember).sort(headcountPlanMemberNameAsc))
  }

  const onSave = () => !isLoadingSave && formRef.current?.handleInvitationsSubmit()

  const searchParams = new URLSearchParams(window.location.search)
  const cancelTo = searchParams.get("cancelTo")
  const mode = searchParams.get("mode")

  return (
    <>
      <div className="flex-col items-center justify-center gap-4 py-8 flex">
        {mode === "invite" && <HCPStepper setBudget={setBudget} currentStep={2} />}
        <InvitationsForm
          currentPersonId={currentPersonId}
          onMemberRemoved={removeMember}
          onMemberUpdated={updateMember}
          onPersonAdded={addPerson}
          selectedMembers={selectedMembers}
          headcountPlanId={headcountPlanId}
          savePlanParticipants={savePlanParticipants}
          isLoadingSave={isLoadingSave}
          ref={formRef}
          setBudget={setBudget}
          mode={mode}
        />
      </div>
      <StickyFooterBar innerClassName="w-full sm:w-[544px]">
        {mode !== "invite" && (
          <a
            href={cancelTo || UrlHelper.headcountPlanPath(headcountPlanId)}
            className="btn--large btn--secondary"
            type="submit"
          >
            {t("v2.defaults.cancel")}
          </a>
        )}
        <button
          className={`btn--large btn--primary ${isLoadingSave && "btn-disabled"}`}
          type={isLoadingSave ? "button" : "submit"}
          onClick={onSave}
        >
          {getSubmitButtonLabel(mode, setBudget, t)}
        </button>
      </StickyFooterBar>
    </>
  )
}

const getSubmitButtonLabel = (mode: string | null, setBudget: boolean, t: TFunction) => {
  if (mode === "invite") {
    return setBudget ? t("v2.defaults.continue") : t("v2.headcount_plan.setup.start_planning")
  }
  return t("v2.defaults.save")
}

const Header = ({ mode, name, id }: { mode: string | null; name: string; id: number | string }) => {
  const { t } = useTranslation()

  return (
    <PageNav>
      <TitleBlockLarge>
        <HeadcountPlanningNavTitleWithSubtitle
          title={
            mode === "invite"
              ? t("v2.headcount_plan.invitations.invite.header")
              : t("v2.headcount_plan.invitations.manage.header")
          }
          subtitle={name}
          subtitleLinkTo={UrlHelper.headcountPlanPath(id)}
        />
      </TitleBlockLarge>
    </PageNav>
  )
}

const InvitationsHeader = withRootProvider(Header, "InvitationsHeader")

export { Invitations, InvitationsHeader }
