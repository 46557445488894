import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useMemo } from "react"

import { HeadcountPlanMember } from "types/graphql.d"
import { HeadcountPlanRole } from "types/graphql.enums"
import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"

interface RoleTypeOptions {
  value: HeadcountPlanRole
  label: string
  labelDescription?: string
  iconPath?: string
}

const buildRoleTypeOptions = (
  currentPersonIsPlanOwner: boolean,
  memberIsCompanyManagerOrHrAdmin: boolean | null | undefined,
): RoleTypeOptions[] => {
  let roleTypes = ["admin", "owner", "admin_and_owner"]

  // If the current person is a plan admin, they shouldn't be able to assign
  // themselves to a lower permission level that would lock them out of admin
  // access for the plan.
  // Similarly, if the member in question is a company manager or HR admin,
  // it shouldn't be possible to remove thier "admin" role on the plan.
  if (currentPersonIsPlanOwner || memberIsCompanyManagerOrHrAdmin) {
    roleTypes = roleTypes.filter((roleType) => roleType !== "owner")
  }

  const legacyRoleType = (uiRoleType: string): HeadcountPlanRole => {
    if (uiRoleType === "admin") return HeadcountPlanRole.Owner
    if (uiRoleType === "owner") return HeadcountPlanRole.Participant
    return HeadcountPlanRole.OwnerAndParticipant
  }

  return roleTypes.map((roleType) => ({
    value: legacyRoleType(roleType),
    label: `role_type_${roleType}`.t("headcount_plan"),
    labelDescription: `role_type_${roleType}_description`.t("headcount_plan"),
  }))
}

interface SelectInputProps {
  currentPersonId: number
  initialValue: HeadcountPlanRole
  selectedHeadcountPlanMember: HeadcountPlanMember
  updateRole: (selectedHeadcountPlanMember: HeadcountPlanMember, role: HeadcountPlanRole) => void
}

const SelectInput = ({
  currentPersonId,
  initialValue,
  selectedHeadcountPlanMember,
  updateRole,
}: SelectInputProps) => {
  const currentPersonIsPlanOwner =
    selectedHeadcountPlanMember.uniqueKey?.includes("headcount_plan_owner") &&
    selectedHeadcountPlanMember?.person?.id === currentPersonId.toString()

  const memberIsCompanyManagerOrHrAdmin =
    selectedHeadcountPlanMember?.person?.isCompanyManagerOrHrAdmin

  const roleTypeOptions = useMemo(
    () => buildRoleTypeOptions(currentPersonIsPlanOwner, memberIsCompanyManagerOrHrAdmin),
    [currentPersonIsPlanOwner, memberIsCompanyManagerOrHrAdmin],
  )

  const handleRoleChange = (value: HeadcountPlanRole) => {
    updateRole(selectedHeadcountPlanMember, value)
  }

  const selectedRoleOption = roleTypeOptions.find((option) => option.value === initialValue)

  return (
    <div className="plan-member-list__select-input mr-2">
      <Dropdown align="right">
        <Dropdown.Trigger>
          <div className="select-none items-center gap-1.5 flex">
            <span>{selectedRoleOption?.label}</span>
            <FontAwesomeIcon icon="caret-down" />
          </div>
        </Dropdown.Trigger>
        <Dropdown.Menu>
          {roleTypeOptions.map((option) => (
            <Dropdown.Item
              key={option.value}
              onClick={() => handleRoleChange(option.value)}
              useActiveStyles
            >
              <div className="w-80 gap-2 flex">
                <div>
                  <div className="text-base-bold">{option.label}</div>
                  <div className="text-sm text-neutral-64">{option.labelDescription}</div>
                </div>
                <div className="ml-auto min-w-[1.5rem] items-center justify-center flex">
                  {option === selectedRoleOption && (
                    <FontAwesomeIcon icon={["fas", "circle-check"]} />
                  )}
                </div>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export { SelectInput }
