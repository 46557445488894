import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { useDateFormatter } from "v2/react/hooks/useDateFormatter"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { selectChartSettings } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

function TimeTravelStatusBadge() {
  const { t } = useTranslation()
  const { parseAndFormat } = useDateFormatter()
  const chartSettings = useAppSelector(selectChartSettings)
  const { historyMode, historyModeSelectedDate } = chartSettings
  const dispatch = useAppDispatch()

  const handleCancelHistoryMode = async () => {
    await dispatch(
      asyncPatchPreferences({
        history_mode_selected_date: null,
        history_mode: false,
      }),
    ).then(() => {
      window.location.reload()
    })
  }

  const openTimeTravelPanel = () => {
    dispatch(
      asyncPatchPreferences({
        super_panel_open: true,
        selected_tab: "tab-time-travel",
      }),
    )
  }

  return (
    <div className="h-full items-center justify-center flex">
      <div
        className={cn("hover badge", {
          "badge--slate removable": historyMode,
          "badge--white": !historyMode,
        })}
      >
        {!historyMode && (
          <button
            type="button"
            onClick={openTimeTravelPanel}
            className="h-full items-center bg-transparent flex"
          >
            <FontAwesomeIcon color="white" className="mr-1 h-full" icon={["far", "calendar-day"]} />
            <span>{t("v2.orgchart.utilitynav.viewing_today")}</span>
          </button>
        )}
        {historyMode && (
          <>
            <button
              type="button"
              onClick={openTimeTravelPanel}
              className="h-full items-center bg-transparent flex"
            >
              <FontAwesomeIcon color="white" className="mx-2 h-full" icon={["far", "history"]} />
              <span>
                {t("v2.orgchart.utilitynav.viewing_as_of", {
                  date: historyModeSelectedDate && parseAndFormat(historyModeSelectedDate),
                })}
              </span>
            </button>
            <Tooltip placement="bottom-start">
              <TooltipTrigger className="w-fit block">
                <button
                  onClick={handleCancelHistoryMode}
                  className="h-full border-none bg-transparent p-0"
                  type="button"
                >
                  <FontAwesomeIcon color="white" className="mx-2 h-full" icon={["far", "times"]} />
                </button>
              </TooltipTrigger>
              <TooltipContent className="react-tooltip-content">
                <span>{t("v2.orgchart.utilitynav.back_to_today")}</span>
              </TooltipContent>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  )
}

export { TimeTravelStatusBadge }
