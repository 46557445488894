import { EntityId } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import fp from "lodash/fp"
import { createSelector } from "reselect"

import {
  ChangeBatch,
  ChangeRequest,
  ChangeRequestsInteractiveChangeBatchesQuery,
  ChangeRequestsPagedChangeBatchesQuery,
  ChangeRequestsPagedChangeBatchesQueryVariables,
  ChangeRequestsProcessQueuedInput,
  ChangeRequestsProcessQueuedMutation,
  ChangeRequestsTransitionPausedChangeBatchInput,
  ChangeRequestsTransitionPausedChangeBatchMutation,
  PageInfo,
} from "types/graphql"
import { ChangeBatchState, ChangeRequestState } from "types/graphql.enums"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import {
  mapToFlattenedBatches,
  toFlattenedBatch,
} from "v2/redux/slices/ChangeRequestSlice/toFlattenedBatch"
import { FlattenedChangeBatch } from "v2/redux/slices/ChangeRequestSlice/types"
import { flatMutationOperation, queryOperation } from "v2/redux/utils/endpoints"

export type BatchStatePredicate = Predicate<ChangeBatch | string>
export type Counter<T> = (record: T) => number
export type Predicate<T> = (record: T) => boolean
export type Accessor<Result> = (record: unknown) => Result

const Tag = {
  enhancements: { addTagTypes: ["ChangeRequests"] },
  baseList: [{ type: "ChangeRequests", id: "list" }],
  interactiveBatches: [{ type: "ChangeRequests", id: "interactiveBatches" }],
}

type ChangeBatchPage = {
  pageInfo: PageInfo
  batches: FlattenedChangeBatch[]
}

const ChangeRequestsApi = GraphqlApi.enhanceEndpoints(Tag.enhancements).injectEndpoints({
  endpoints: (builder) => ({
    pagedChangeBatches: builder.query<
      ChangeBatchPage,
      ChangeRequestsPagedChangeBatchesQueryVariables
    >({
      query: queryOperation("ChangeRequestsPagedChangeBatches"),
      providesTags: Tag.baseList,
      transformResponse: (res: ChangeRequestsPagedChangeBatchesQuery) => ({
        pageInfo: res.currentCompany?.pagedChangeBatches.pageInfo ?? {
          hasNextPage: false,
          hasPreviousPage: false,
        },
        batches: mapToFlattenedBatches(res.currentCompany?.pagedChangeBatches.nodes ?? []),
      }),
    }),
    interactiveChangeBatches: builder.query<
      ChangeRequestsInteractiveChangeBatchesQuery["currentCompany"],
      void
    >({
      providesTags: Tag.interactiveBatches,
      query: queryOperation("ChangeRequestsInteractiveChangeBatches"),
      transformResponse: (res: ChangeRequestsInteractiveChangeBatchesQuery) => res.currentCompany,
    }),
    processQueued: builder.mutation<
      ChangeRequestsProcessQueuedMutation,
      ChangeRequestsProcessQueuedInput
    >({
      query: flatMutationOperation("ChangeRequestsProcessQueued"),
      invalidatesTags: [...Tag.baseList, ...Tag.interactiveBatches],
    }),
    transitionPausedBatch: builder.mutation<
      ChangeRequestsTransitionPausedChangeBatchMutation,
      ChangeRequestsTransitionPausedChangeBatchInput
    >({
      query: flatMutationOperation("ChangeRequestsTransitionPausedChangeBatch"),
      invalidatesTags: [...Tag.baseList, ...Tag.interactiveBatches],
    }),
  }),
})

const prettyDate = (val: string) => dayjs(val).format("MMM D, YYYY h:mm a")
export const maybePrettyDate = (val?: string | null) => (val ? prettyDate(val) : null)

export const propFormattedDate =
  (key: string | string[]) =>
  <T>(record: T) =>
    maybePrettyDate(fp.prop(key, record))
export const propIdFromUniqueKey =
  (key: string | string[]): Accessor<EntityId> =>
  <T>(record: T) =>
    fp.pipe(fp.prop(key), idFromUniqueKey)(record)
export const propSyncInitiatorName: Accessor<string | null> = fp.prop(["syncInitiator", "name"])
export const changeProp =
  (key: string) =>
  (changeRequest: ChangeRequest): string | null =>
    fp.prop(["change", key], changeRequest) ?? null
export const changePropFormattedDate = (key: string) => propFormattedDate(["change", key])

const makeBatchStatePredicateFor = (state: ChangeBatchState) => (value: ChangeBatch | string) =>
  fp.isString(value) ? value === state : value.state === state
export const isBusy = makeBatchStatePredicateFor(ChangeBatchState.Busy)
export const isOpen = makeBatchStatePredicateFor(ChangeBatchState.Open)
export const isPausedWithErrors = makeBatchStatePredicateFor(ChangeBatchState.PausedWithErrors)
export const isSent = makeBatchStatePredicateFor(ChangeBatchState.Sent)
export const isSentWithErrors = makeBatchStatePredicateFor(ChangeBatchState.SentWithErrors)
export const isActive: BatchStatePredicate = fp.anyPass([isBusy, isPausedWithErrors])
export const isDone: BatchStatePredicate = fp.anyPass([isSent, isSentWithErrors])

export const selectOpenAndActiveBatches = createSelector(
  ChangeRequestsApi.endpoints.interactiveChangeBatches.select(),
  ({ data }) => ({
    active: data?.activeChangeBatch ? toFlattenedBatch(data?.activeChangeBatch) : undefined,
    open: data?.openChangeBatch ? toFlattenedBatch(data?.openChangeBatch) : undefined,
  }),
)

const makeRequestStateFns = (state: ChangeRequestState) => {
  const whereFn: Predicate<ChangeRequest> = fp.propEq("state", state)
  const countFn: Counter<ChangeRequest[]> = fp.pipe(fp.filter(whereFn), fp.size)
  const countRequestsFn: Counter<ChangeBatch> = fp.pipe(fp.prop("changeRequests"), countFn)

  return [whereFn, countRequestsFn] as const
}

const { Discarded, Failed, FailedCopiedToNextBatch, FailedIgnored, Succeeded } = ChangeRequestState

export const didFail: Predicate<ChangeRequest> = fp.anyPass([
  fp.propEq("state", Failed),
  fp.propEq("state", FailedCopiedToNextBatch),
  fp.propEq("state", FailedIgnored),
])
const countFailed: Counter<ChangeRequest[]> = fp.pipe(fp.filter(didFail), fp.size)
export const countFailedRequests: Counter<ChangeBatch> = fp.pipe(
  fp.prop("changeRequests"),
  countFailed,
)

export const [didDiscard, countDiscardedRequests] = makeRequestStateFns(Discarded)
export const [didSucceed, countSuccessfulRequests] = makeRequestStateFns(Succeeded)
export const countRequests: Counter<ChangeBatch> = fp.pipe(fp.prop("changeRequests"), fp.size)

export const { interactiveChangeBatches, transitionPausedBatch } = ChangeRequestsApi.endpoints
export const {
  useProcessQueuedMutation,
  useTransitionPausedBatchMutation,
  usePagedChangeBatchesQuery,
  useInteractiveChangeBatchesQuery,
} = ChangeRequestsApi
