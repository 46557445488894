import cn from "classnames"
import { motion } from "framer-motion"
import React from "react"
import { useTranslation } from "react-i18next"

import { AnimatedNumber } from "v2/react/shared/loaders/AnimatedNumber"

interface ProgressGroupProps {
  actual: number
  planned: number
  percentage: number
  dataAvailable: boolean
}

const ANIMATION_DURATION = 0.75

const ProgressGroup = ({ actual, planned, percentage, dataAvailable }: ProgressGroupProps) => {
  const { t } = useTranslation()

  const isValidPercentage = dataAvailable && !Number.isNaN(percentage)
  const safePercentage = isValidPercentage ? Math.min(percentage, 100) : 0

  const actualDisplay = dataAvailable ? (
    <AnimatedNumber value={actual} duration={ANIMATION_DURATION} />
  ) : (
    "-"
  )
  const percentageDisplay = isValidPercentage ? (
    <AnimatedNumber value={percentage} duration={ANIMATION_DURATION} />
  ) : (
    "-"
  )

  return (
    <div className="flex-col items-start justify-center px-4 flex">
      <span className="text-sm text-neutral-64">
        {actualDisplay} {t("v2.defaults.of")} {planned}
      </span>
      <div className="w-full items-center gap-2 flex">
        <div
          className={cn(
            "relative h-1 w-full overflow-hidden rounded-full",
            getProgressBarBgClass(isValidPercentage, percentage),
          )}
        >
          {isValidPercentage && (
            <motion.div
              className={cn(
                "absolute left-0 top-0 h-full rounded-full",
                getProgressBarFillClass(isValidPercentage, percentage),
              )}
              initial={{ width: 0 }}
              animate={{ width: `${safePercentage}%` }}
              transition={{ duration: ANIMATION_DURATION, ease: "easeInOut" }}
            />
          )}
        </div>
        <div className="flex-1">
          <div className="min-w-[42px] items-center justify-end flex">
            <div
              className={cn(
                "badge--sm !gap-0 !border-0",
                getBadgeClass(isValidPercentage, percentage),
              )}
            >
              {percentageDisplay}%
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const getProgressBarFillClass = (isValidPercentage: boolean, percentage: number) => {
  if (!isValidPercentage) return "bg-neutral-3"
  return percentage <= 100 ? "bg-status-success" : "bg-status-critical"
}

const getProgressBarBgClass = (isValidPercentage: boolean, percentage: number) => {
  if (!isValidPercentage) return "bg-neutral-3"
  return percentage <= 100 ? "bg-status-success-light" : "bg-status-critical-light"
}

const getBadgeClass = (isValidPercentage: boolean, percentage: number) => {
  if (!isValidPercentage) return "badge--gray"
  return percentage <= 100 ? "badge--green" : "badge--red"
}

export { ProgressGroup }
