import classNames from "classnames"
import React, { ReactNode } from "react"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"

import { useWithReactHookFormRegister } from "./hooks/useWithReactHookFormHooks"
import { UseInReactHookFormProp } from "./types"

interface TextInputProps {
  autoFocus?: boolean
  className?: string
  defaultValue?: string
  disabled?: boolean
  errors?: string
  id: string
  inputClassName?: string
  inputRef?: React.Ref<HTMLInputElement>
  label?: ReactNode
  maxLength?: number
  name?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  showAttention?: boolean
  showErrorMessage?: boolean
  useInReactHookForm?: UseInReactHookFormProp
  infoMessage?: string
}

const TextInput = ({
  autoFocus,
  className,
  defaultValue,
  disabled,
  errors,
  id,
  inputClassName,
  inputRef,
  label,
  maxLength,
  name,
  onChange,
  placeholder,
  readOnly,
  required,
  showAttention,
  showErrorMessage,
  useInReactHookForm,
  infoMessage,
}: TextInputProps) => (
  <InputWrapper
    className={classNames(className, {
      attention: showAttention,
    })}
    errors={errors}
    id={id}
    label={label}
    name={name}
    showErrorMessage={showErrorMessage}
    useInReactHookForm={useInReactHookForm}
    infoMessage={infoMessage}
  >
    <input
      // The use of autoFocus may not be the best choice from an accessibility
      // perspective. Consider revising if possible.
      // https://brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/
      // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/no-autofocus.md
      //
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      className={classNames("input", inputClassName)}
      defaultValue={defaultValue || ""}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      readOnly={readOnly}
      ref={inputRef}
      required={required}
      type="text"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...useWithReactHookFormRegister({ useInReactHookForm, name })?.(name ?? "")}
    />
  </InputWrapper>
)

export { TextInput }
