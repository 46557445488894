import React from "react"
import { ChangeHandler, RefCallBack } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Option } from "types/graphql"
import { FormSelect } from "v2/react/shared/forms/FormSelect"

function ExternalTemplateIdField({
  show = true,
  selectRef,
  onChange,
  options,
}: {
  show?: boolean
  selectRef: RefCallBack
  onChange: ChangeHandler
  options: Option[]
}) {
  const { t } = useTranslation()
  if (!show) {
    return null
  }
  return (
    <div className="mb-4 cursor-default">
      <FormSelect
        label={t("v2.job_requisitions.modals.send_to_ats.greenhouse.field_template")}
        selectRef={selectRef}
        onSelect={onChange}
        options={options}
      />
    </div>
  )
}

export { ExternalTemplateIdField }
