import { NodeData } from "org_chart/chart/node/types"
import OrgChart, { OverrideOptions } from "org_chart/chart/orgChart"
import { useEffect, useRef, useState } from "react"

import { buildHierarchyWithCounts } from "v2/react/components/orgChart/HistoricalOrgChart/utils"
import { useAppSelector } from "v2/redux/store"

const useOrgChart = (
  chartData: NodeData[] | null,
  fullChartOptions: OverrideOptions,
  handleError: (error: unknown) => void,
  chartContainerRef: React.RefObject<HTMLDivElement>,
  chartSettings: {
    countOpenPositions: boolean
    countDottedRelationships: boolean
    childrenCount: "all" | "immediate"
  },
) => {
  const chartRef = useRef<OrgChart | null>(null)
  const [chart, setChart] = useState<OrgChart | null>(null)
  const { countOpenPositions, countDottedRelationships, childrenCount } = chartSettings
  const chartViewId = useAppSelector((state) => state.visualization.chartViewId)

  // Effect to initialize the chart when it is first created
  useEffect(() => {
    if (!chartData || !chartContainerRef.current || chartRef.current) return

    try {
      const newChart = new OrgChart("#organize-container", fullChartOptions)
      chartRef.current = newChart
      newChart.loadFromJSON()
      setChart(newChart)
    } catch (error) {
      handleError(error)
    }
  }, [chartData, fullChartOptions, chartContainerRef, handleError])

  // Effect to update the chart when chartData changes after the initial render
  useEffect(() => {
    if (!chartData || !chartRef.current || !chartRef.current.getInitialized()) return

    const newChartData = buildHierarchyWithCounts(
      structuredClone(chartData),
      countOpenPositions,
      countDottedRelationships,
      childrenCount,
      chartViewId,
    )

    try {
      chartRef.current.replaceRoot(newChartData)
    } catch (error) {
      handleError(error)
    }
  }, [
    chartData,
    countDottedRelationships,
    countOpenPositions,
    childrenCount,
    handleError,
    chartViewId,
  ])

  return { chart }
}

export { useOrgChart }
