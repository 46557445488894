import { Ref } from "react"

import type { Collection, Maybe } from "types/graphql"
import type { CellInput, SaveFn } from "v2/react/components/headcountPlanning/TableDatasheet/types"
import type { Direction } from "v2/react/utils/enums"

import { Statistics } from "./utils/statistics"

interface Column<TNode> {
  width: number
  fieldKey: keyof TNode
  label: string
  format: string
  enabled: boolean
  filterable: boolean
  restricted: boolean
  hideLabel: boolean
  collection?: Collection
}

enum RowType {
  Node = "Node",
  Group = "Group",
  Stats = "Stats",
}

interface GenericRow {
  id: string
  rowType: RowType
  // When present, indicates the row should be prefixed with a vertical stripe
  // of this color.
  color?: Maybe<string>
}

interface NodeRow<TNodeAttributes = unknown> extends GenericRow {
  id: string
  rowType: RowType.Node
  // IMPORTANT: I haven't refactored the calling logic to nest attributes in
  // this property. TS doesn't complain since the caller is a JS file, and
  // other event handlers/helpers still expect this data spread across the
  // node object (instead of in the +data+ attribute).
  data: TNodeAttributes
}

interface GroupRow extends GenericRow {
  id: string
  rowType: RowType.Group
  label: string
  groupPath: string[]
  isExpanded: boolean
  depth: number
  childrenCount: number
  isHidden: boolean
  fieldKey: string
  value: string
}

interface StatsRow extends GenericRow {
  rowType: RowType.Stats
  stats: Record<string, Statistics>
}

enum FieldType {
  Standard = "Standard",
  SuggestedAutocomplete = "SuggestedAutocomplete",
  ForcedAutocomplete = "ForcedAutocomplete",
  SelectDropdown = "SelectDropdown",
  PersonAutocomplete = "PersonAutocomplete",
  PositionAutocomplete = "PositionAutocomplete",
  PositionTypeAutocomplete = "PositionTypeAutocomplete",
  OrgUnitAutocomplete = "OrgUnitAutocomplete",
  NonEditable = "NonEditable",
}

interface ChangeMeta {
  authorAvatarUrl?: Maybe<string>
  authorName?: Maybe<string>
  authorId?: Maybe<string>
  authorType: "person" | "system"
  priorValue: string
  timestamp: string
}

type CursorSaveOptions =
  | { moveAfterTo: Direction }
  | { moveAfterByEvent: KeyboardEvent }
  | { transitionKeyCanMove: boolean }

type CursorConnection = {
  /**
   * Optional ref object providing functions to control the cell. When defined,
   * the cursor can use it to implement common behavior.
   */
  cellInputRef: Ref<CellInput>

  /**
   * Requests the cursor focus the cell (handy for handling an event like
   * onClick). This supports calls regardless of whether the cursor is on the
   * calling cell.
   */
  requestCursorTransition: () => void

  /**
   * Primarily used to prevent the default behavior of a key depending on the
   * cursor state (i.e., prevent arrow key defaults if they should submit a
   * write).
   */
  keyDownListenerWhileWriting: (event: KeyboardEvent | React.KeyboardEvent) => {
    handled: boolean
    event: KeyboardEvent | React.KeyboardEvent
  }

  /**
   * Submits the form and moves the cursor given a matching keyboard event.
   * A cell can opt out of using this when it needs more flexibility.
   */
  keyUpListenerWhileWriting: (event: KeyboardEvent | React.KeyboardEvent) => {
    handled: boolean
    event: KeyboardEvent | React.KeyboardEvent
  }

  /**
   * Indicates whether the cursor is on the cell (regardless of state).
   */
  inCursor: boolean

  /**
   * Indicates whether the cursor is "writing" changes to the cell.
   */
  inWrite: boolean

  /**
   * Set when the cursor is "writing" changes to the cell, and the write began
   * with an initial value.
   */
  initialWriteValue: string | undefined

  /**
   * Stop writing changes to the cell. Omitting cursor options will leave the
   * cursor on the cell and a transition keypress will start writing again.
   * This does not save any changes that have been made.
   */
  stopWriting: (cursorOptions?: CursorSaveOptions) => void

  /**
   * Begins the update process using the given value. If the commit was
   * triggered by a keyboard event, it can be optionally provided in order to
   * move the cursor to a new cell.
   */
  saveWrite: (value: string, options?: CursorSaveOptions) => void

  saveFn: SaveFn
}

export {
  CellInput,
  CursorConnection,
  CursorSaveOptions,
  ChangeMeta,
  Column,
  GroupRow,
  NodeRow,
  StatsRow,
  RowType,
  FieldType,
}
