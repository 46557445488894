import cn from "classnames"
import { motion } from "framer-motion"
import React from "react"
import AnimateHeight, { AnimateHeightProps } from "react-animate-height"
import { useTranslation } from "react-i18next"

import { LoadingIndicator } from "v2/react/shared/loaders/LoadingIndicator"
import { ErrorFallback } from "v2/react/shared/overlay/Modal/ErrorFallback"

interface LoadingWrapperProps {
  isLoading: boolean
  isError: boolean
  animationDuration?: number
  children: React.ReactNode
  /**
   * Class of the div that's present when the component is loading.
   */
  loaderClass?: string
  /**
   * Class of the div that's present when the component is not loading.
   */
  wrapperClass?: string
  /**
   * Whether to animate the height of the component.
   */
  animateHeight?: boolean
  /**
   * The height of the component while it's loading - used in conjunction with
   * animateHeight.
   */
  initialHeight?: number
}

const LoadingWrapper = ({
  isLoading,
  isError,
  animationDuration = 250,
  children,
  loaderClass = "h-screen",
  wrapperClass,
  animateHeight = false,
  initialHeight = 0,
}: LoadingWrapperProps) => {
  const { t } = useTranslation()

  return (
    <AnimateHeightWrapper
      animateHeight={animateHeight}
      duration={animationDuration}
      height={isLoading ? initialHeight : "auto"}
    >
      <LoadingIndicator
        isLoading={isLoading}
        spinnerClass="relative"
        wrapperClass={cn(loaderClass)}
        wrapperStyle={animateHeight ? { height: initialHeight } : undefined}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: animationDuration / 1000.0 }}
          className={wrapperClass}
        >
          <ErrorFallback isError={isError} errorMessage={t("v2.defaults.error")}>
            {children}
          </ErrorFallback>
        </motion.div>
      </LoadingIndicator>
    </AnimateHeightWrapper>
  )
}

const AnimateHeightWrapper = ({
  animateHeight = true,
  children,
  ...props
}: AnimateHeightProps & { animateHeight?: boolean }) => {
  if (!animateHeight) {
    return children
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AnimateHeight {...props}>{children}</AnimateHeight>
}

export { LoadingWrapper, AnimateHeightWrapper }
