import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { ChangeBatchState } from "types/graphql.enums"
import { ChangeScreen, selectHaveAnyActiveBatch } from "v2/redux/slices/ChangeRequestSlice"
import { useAppSelector } from "v2/redux/store"

type ScreenStackModalFooterProps = {
  isMutating: boolean
  onClosePress: React.MouseEventHandler
  onSendChangesPress: React.MouseEventHandler
  screen: ChangeScreen
}

export function ScreenStackModalFooter({
  isMutating,
  onClosePress,
  onSendChangesPress,
  screen,
}: ScreenStackModalFooterProps) {
  const { t } = useTranslation()
  const hasAnyActiveBatch = useAppSelector(selectHaveAnyActiveBatch)

  if (screen.screenKey !== "Batch") return null
  if (screen.batch.state !== ChangeBatchState.Open) return null

  return (
    <div className="justify-end gap-2 flex">
      <button className="btn btn--secondary" onClick={onClosePress} type="button">
        {t("v2.defaults.cancel")}
      </button>
      <button
        disabled={isMutating || hasAnyActiveBatch}
        className="btn btn--primary"
        onClick={onSendChangesPress}
        type="button"
      >
        {!isMutating ? (
          <>
            <FontAwesomeIcon className="mr-1" icon={["far", "paper-plane"]} size="1x" />
            {t("v2.adp.change_requests.sync_changes_send")}
          </>
        ) : null}
        {isMutating && t("v2.defaults.syncing")}
      </button>
    </div>
  )
}
