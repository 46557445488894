import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

import { AtsOptions } from "types/graphql"
import { AdpRmInfo, initAdpRmForm } from "v2/react/components/jobRequisitions/SendToAts/AdpRmForm"
import {
  GreenhouseForm,
  initGreenhouseForm,
} from "v2/react/components/jobRequisitions/SendToAts/GreenhouseForm"
import { initLeverForm, LeverForm } from "v2/react/components/jobRequisitions/SendToAts/LeverForm"
import { SendToAtsModal as SmartRecruitersSendToAtsModal } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/SendToAtsModal"
import { InitFailure } from "v2/react/components/jobRequisitions/SendToAts/utils"
import { JobRequisitionAbilities } from "v2/react/components/jobRequisitions/types"
import { Pulser } from "v2/react/shared/loaders/Pulser"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"
import { useGetAtsOptionsQuery } from "v2/redux/GraphqlApi/JobRequisitionsApi"

const showStates = ["approved", "closed"]

interface Props {
  abilities: JobRequisitionAbilities
  approvalState: string
  atsIdentifier: string
  clientIdentifier: string
  externalStatus: string
  status: string
  jobRequisitionId: string
  systemUid: string
  title: string
  replacementFor: string
  jobDescription: string
  isReplacement: boolean
  numPositions: number
}

function IntegrationInfo({
  abilities,
  approvalState,
  atsIdentifier,
  clientIdentifier,
  externalStatus,
  status,
  jobRequisitionId,
  systemUid,
  title,
  replacementFor,
  jobDescription,
  isReplacement,
  numPositions,
}: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  const handleShowModalButtonClick = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const handleCancelModal = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  if (atsIdentifier === "") return null
  if (!showStates.includes(approvalState)) return null

  return (
    <div className="module-card mb-0">
      <div className="module-card__header">
        <p className="mb-0 font-bold">
          {integrationLabel(atsIdentifier, "requisition".t("job_requisition"))}
        </p>
      </div>
      <div className="module-card__body gap-0 !p-0">
        <div className="module-card-list-item h-14 p-4">
          <div className="justify-between gap-2 flex">
            <img
              alt="logoThumbUrl"
              src={window.App.Helpers.imagePath(
                `/integrations/${integrationImageFile(atsIdentifier)}`,
              )}
            />
            <div className="items-center text-neutral-64 flex">{t("v2.defaults.id")}</div>
          </div>

          <div className="items-center flex">
            {clientIdentifier === "" ? "-" : clientIdentifier}
          </div>
        </div>

        <div className="module-card-list-item h-14 border-t-neutral-8 p-4">
          <div className="text-neutral-64">{"status".t("ats")}</div>
          <div>
            {status === "" ? (
              "-"
            ) : (
              <StatusBadge
                badgeClasses="badge--sm"
                displayType="button"
                status={status}
                statusLabel={externalStatus}
              />
            )}
          </div>
        </div>
      </div>
      {abilities.canManage &&
        showButton(approvalState, atsIdentifier, clientIdentifier, isOpen) && (
          <div className="module-card-list-item h-14 border-t-neutral-8 p-4">
            <SendToAtsWrapper
              closeModal={handleCancelModal}
              handleShowModalButtonClick={handleShowModalButtonClick}
              isOpen={isOpen}
              approvalState={approvalState}
              jobRequisitionId={jobRequisitionId}
              systemUid={systemUid}
              title={title}
              replacementFor={replacementFor}
              jobDescription={jobDescription}
              isReplacement={isReplacement}
              numPositions={numPositions}
              atsIdentifier={atsIdentifier}
            />
          </div>
        )}
    </div>
  )
}

const integrationImageFile = (atsIdentifier: string): string => {
  if (atsIdentifier === "greenhouse") return "icon-greenhouse.png"
  if (atsIdentifier === "lever") return "icon-lever.png"
  if (atsIdentifier === "smart_recruiters") return "icon-smart_recruiters.svg"
  if (["adp_base", "adp_rm"].includes(atsIdentifier || "")) return "icon-adp.png"
  return ""
}

const integrationLabel = (atsIdentifier: string, suffix: string): string => {
  if (atsIdentifier === "greenhouse") return `Greenhouse ${suffix}`
  if (atsIdentifier === "lever") return `Lever ${suffix}`
  if (atsIdentifier === "smart_recruiters") return `SmartRecruiters ${suffix}`
  if (["adp_base", "adp_rm"].includes(atsIdentifier || "")) return `ADP ${suffix}`
  return suffix
}

const showButton = (
  approvalState: string,
  atsIdentifier: string,
  clientIdentifier: string,
  isOpen: boolean,
) => {
  if (!showStates.includes(approvalState)) return false

  const supportedConnections = ["greenhouse", "lever", "smart_recruiters", "adp_rm"]
  if (!supportedConnections.includes(atsIdentifier)) return false

  // If the modal happens to be open, we don't want to force it closed when
  // state updates in the case there is error information to display.
  if (isOpen) return true

  const isConnectedToAts = clientIdentifier !== ""
  if (isConnectedToAts) return false

  return true
}

interface SendToAtsProps {
  closeModal: () => void
  handleShowModalButtonClick: () => void
  isOpen: boolean
  approvalState: string
  jobRequisitionId: string
  systemUid: string
  title: string
  replacementFor?: string
  jobDescription?: string
  isReplacement?: boolean
  numPositions?: number
  atsIdentifier: string
}

/**
 * Some SendToAts modals (i.e. SendToAtsWithInit) load the data as soon as this
 * component is mounted, and have an initialization step to ensure that there
 * are no errors with fetching the data. If there are we avoid displaying the
 * button.
 *
 * The contrasts with an alternative strategy (i.e. SendToAts) that skips this
 * initialization step and renders the button immediately / opts to potentially
 * load the data when the button is clicked and handle errors within the modal.
 */
const SendToAtsWrapper = ({
  closeModal,
  handleShowModalButtonClick,
  isOpen,
  approvalState,
  jobRequisitionId,
  systemUid,
  title,
  replacementFor,
  jobDescription,
  isReplacement,
  numPositions,
  atsIdentifier,
}: SendToAtsProps) => {
  if (atsIdentifier === "smart_recruiters") {
    return (
      <SendToAts
        closeModal={closeModal}
        handleShowModalButtonClick={handleShowModalButtonClick}
        isOpen={isOpen}
        approvalState={approvalState}
        jobRequisitionId={jobRequisitionId}
        atsIdentifier={atsIdentifier}
      />
    )
  }

  return (
    <SendToAtsWithInit
      closeModal={closeModal}
      handleShowModalButtonClick={handleShowModalButtonClick}
      isOpen={isOpen}
      approvalState={approvalState}
      jobRequisitionId={jobRequisitionId}
      systemUid={systemUid}
      title={title}
      replacementFor={replacementFor}
      jobDescription={jobDescription}
      isReplacement={isReplacement}
      numPositions={numPositions}
    />
  )
}

const SendToAts = ({
  closeModal,
  handleShowModalButtonClick,
  isOpen,
  approvalState,
  jobRequisitionId,
  atsIdentifier,
}: Pick<
  SendToAtsProps,
  "closeModal" | "handleShowModalButtonClick" | "isOpen" | "approvalState" | "jobRequisitionId"
> & {
  atsIdentifier: string
}) => (
  <>
    <div className="ml-auto">
      <SendToAtsButton
        handleShowModalButtonClick={handleShowModalButtonClick}
        disabled={approvalState === "closed"}
      />
    </div>
    {atsIdentifier === "smart_recruiters" && (
      <SmartRecruitersSendToAtsModal
        closeModal={closeModal}
        isOpen={isOpen}
        jobRequisitionId={jobRequisitionId}
      />
    )}
  </>
)

const SendToAtsWithInit = ({
  closeModal,
  handleShowModalButtonClick,
  isOpen,
  approvalState,
  jobRequisitionId,
  systemUid,
  title,
  replacementFor,
  jobDescription,
  isReplacement,
  numPositions,
}: Omit<SendToAtsProps, "atsIdentifier">) => {
  const { data, isLoading } = useGetAtsOptionsQuery()

  if (isLoading)
    return (
      <div>
        <Pulser />
      </div>
    )

  if (!data?.atsOptions) {
    return (
      <div>
        <p>ATS Integration Error</p>
      </div>
    )
  }

  const initData = initAtsForm(
    title,
    [],
    "",
    data.atsOptions,
    replacementFor,
    jobDescription,
    numPositions,
  )

  if (!initData.success) {
    return (
      <div>
        <p>ATS Integration Error</p>
        <p>{initData.message}</p>
      </div>
    )
  }

  return (
    <>
      <div className="ml-auto">
        <SendToAtsButton
          handleShowModalButtonClick={handleShowModalButtonClick}
          disabled={approvalState === "closed"}
        />
      </div>
      {initData.integration === "greenhouse" && (
        <GreenhouseForm
          closeModal={closeModal}
          formSchema={initData.schema}
          initialFormData={initData.data}
          isOpen={isOpen}
          jobRequisitionId={jobRequisitionId}
          options={initData.options}
          positionId={null}
          systemUid={systemUid}
        />
      )}
      {initData.integration === "lever" && (
        <LeverForm
          closeModal={closeModal}
          formSchema={initData.schema}
          initialFormData={initData.data}
          isOpen={isOpen}
          jobRequisitionId={jobRequisitionId}
          positionId={null}
          systemUid={systemUid}
        />
      )}
      {initData.integration === "adp_rm" && (
        <AdpRmInfo
          closeModal={closeModal}
          isOpen={isOpen}
          requisition={{
            jobTitle: title,
            systemUid,
            jobDescription: jobDescription ?? "",
            replacementFor: replacementFor ?? "",
            jobRequisitionId,
            isReplacement: !!isReplacement,
            numPositions: numPositions ?? 0,
          }}
        />
      )}
    </>
  )
}

const SendToAtsButton = ({
  handleShowModalButtonClick,
  disabled,
}: {
  handleShowModalButtonClick: () => void
  disabled: boolean
}) => (
  <button
    type="button"
    className="btn btn--primary"
    onClick={handleShowModalButtonClick}
    disabled={disabled}
  >
    {"send_to_ats".t("ats")}
  </button>
)

function initAtsForm(
  title: string,
  departments: string[],
  location: string,
  atsOptions: AtsOptions,
  replacementFor?: string,
  jobDescription?: string,
  numPositions?: number,
) {
  if (atsOptions.integration === "greenhouse") {
    return initGreenhouseForm(title, departments, location, atsOptions, numPositions ?? 1)
  }
  if (atsOptions.integration === "lever") {
    return initLeverForm(title, departments, location, numPositions ?? 1)
  }
  if (
    atsOptions.integration === "adp_rm" &&
    replacementFor !== undefined &&
    jobDescription !== undefined
  ) {
    return initAdpRmForm(title, replacementFor, jobDescription, numPositions ?? 0)
  }
  return {
    success: false,
    message: "unable_to_get_ats_data".t("ats"),
  } as InitFailure
}

export { IntegrationInfo }
