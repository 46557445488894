import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"

import { Notice } from "v2/react/shared/status/Notice"

interface Props {
  message?: string
  children?: ReactNode
}

const ChartError = ({ message, children }: Props) => {
  const { t } = useTranslation()

  return (
    <Notice type="caution" icon={["far", "exclamation-triangle"]} wrapperClass="mx-auto mt-4 w-fit">
      <p>{message || t("v2.defaults.error")}</p>
      {children}
    </Notice>
  )
}

export { ChartError }
