import { Maybe } from "graphql/jsutils/Maybe"
import { TFunction } from "i18next"

import { Person } from "types/graphql"
import { ApprovalStates } from "types/graphql.enums"

type ApprovalActionType = "approved" | "changes_requested" | "denied"
type DoneState =
  | ApprovalStates.Approved
  | ApprovalStates.Denied
  | ApprovalStates.Canceled
  | ApprovalStates.Closed
interface DatePropertyMap {
  [key: string]: "approvedAt" | "changesRequestedAt" | "deniedAt" | "updatedAt"
}

/*
 * The following interfaces are generic so that the approver
 * components can work for both chartApproval and requisitionApproval
 */

interface ApproverType {
  approvedAt?: Maybe<Date>
  changesRequestedAt?: Maybe<Date>
  deniedAt?: Maybe<Date>
  id: string
  note?: string | null
  person: Person
  sortOrder?: number | null
  state?: Maybe<string> | undefined
  status?: Maybe<string> | undefined
  updatedAt?: Maybe<Date>
}

interface NoteType {
  body: string
  createdAt: Date
  person: Person
  status: string
}

const initialStatuses = ["awaiting_approval", "pending_approval"]
const doneStates = [
  ApprovalStates.Approved,
  ApprovalStates.Denied,
  ApprovalStates.Canceled,
  ApprovalStates.Closed,
]

// Chart Approval and general Approval statuses include:
//   approved awaiting_approval canceled changes_requested denied  notified
//   pending pending_approval
// Job Requisition statuses include:
//   canceled closed filled offer_pending on_hold open pending_approval
//   rejected setup_in_progress
const statusColorLookup = (status: string) => {
  const statusColorMap: Record<string, string> = {
    approved: "green",
    awaiting_approval: "purple",
    at_risk: "yellow",
    canceled: "gray",
    closed: "gray",
    changes_requested: "yellow",
    complete: "green",
    denied: "red",
    filled: "green",
    high: "red",
    low: "green",
    medium: "yellow",
    none: "gray",
    no_configuration: "red",
    notified: "blue",
    offer_pending: "purple",
    on_hold: "yellow",
    open: "purple",
    open_position: "yellow",
    not_defined: "white",
    pending: "purple",
    pending_approval: "purple",
    plan_approved: "green",
    rejected: "red",
    setup_in_progress: "yellow",
  }

  return statusColorMap[status]
}

const statusTextLookup = (status: string, t: TFunction) => {
  const statusTextMap: Record<string, string> = {
    approved: t("v2.approvals.state.approved"),
    awaiting_approval: t("v2.approvals.awaiting_review"),
    at_risk: t("v2.defaults.at_risk"),
    canceled: t("v2.approvals.state.canceled"),
    closed: t("v2.approvals.state.closed"),
    changes_requested: t("v2.approvals.state.changes_requested"),
    complete: t("v2.defaults.complete"),
    denied: t("v2.approvals.state.denied"),
    filled: t("v2.approvals.ats_status.filled"),
    high: t("v2.defaults.high"),
    low: t("v2.defaults.low"),
    medium: t("v2.defaults.medium"),
    no_configuration: t("v2.approvals.state.no_configuration"),
    none: t("v2.defaults.none"),
    notified: t("v2.defaults.notified"),
    offer_pending: t("v2.approvals.ats_status.offer_pending"),
    on_hold: t("v2.approvals.ats_status.on_hold"),
    open: t("v2.approvals.ats_status.open"),
    open_position: t("v2.defaults.open_position"),
    not_defined: t("v2.succession.plans.statuses.not_defined"),
    pending: t("v2.approvals.ats_status.pending"),
    pending_approval: t("v2.approvals.ats_status.pending_approval"),
    plan_approved: t("v2.headcount_plan.status.plan_approved"),
    rejected: t("v2.approvals.ats_status.rejected"),
    setup_in_progress: t("v2.approvals.ats_status.setup_in_progress"),
  }

  return statusTextMap[status]
}

const checkIfNextApprover = <Approver extends ApproverType>(
  approvers: Approver[],
  approver: Approver,
) => {
  const currApprover = currentApprover(approvers)
  return approver.person.id === currApprover?.person.id || false
}

const currentApprover = (approvers: ApproverType[]) => {
  const currApprover = approvers.find(
    (a: ApproverType) => ("status" in a && !a.status) || ("state" in a && a.state !== "approved"),
  )
  return currApprover
}

const currentUserIsNextApprover = (approvers: ApproverType[], currentUserId: string | number) => {
  const currApprover = currentApprover(approvers)
  return currApprover?.person.id === currentUserId.toString() || false
}

const currentApproverStatus = (approvers: ApproverType[], currentUserId: string | number) => {
  const approver = approvers.find(
    (approver: ApproverType) => approver?.person.id === currentUserId.toString(),
  )

  if (!approver) return null

  return (("status" in approver && approver.status) ||
    ("state" in approver && approver.state)) as ApprovalActionType | null
}

const isApprovalDone = (approvalState: Nullish<ApprovalStates>): approvalState is DoneState =>
  Boolean(approvalState && doneStates.includes(approvalState))

export {
  ApprovalActionType,
  ApproverType,
  DatePropertyMap,
  NoteType,
  initialStatuses,
  isApprovalDone,
  checkIfNextApprover,
  currentApprover,
  currentApproverStatus,
  currentUserIsNextApprover,
  statusColorLookup,
  statusTextLookup,
}
