import React from "react"
import { useTranslation } from "react-i18next"

import { EXPORT_BUTTON_ID } from "v2/react/components/positions/reports/PositionsOverTime"
import { ReportsNav } from "v2/react/components/reports/ReportsNav"
import { withRootProvider } from "v2/react/components/RootProvider"
import { UrlHelper } from "v2/react/utils/urls"

const Content = () => {
  const { t } = useTranslation()

  return (
    <ReportsNav
      title={t("v2.position_reports.positions_over_time.title")}
      parentTitle={t("v2.reports.tabs.reports")}
      parentTitleUrl={UrlHelper.reportsPath()}
      exportRef={EXPORT_BUTTON_ID}
    />
  )
}

const Header = withRootProvider(Content, "Header")

export { Header }
