import { parseDate, today } from "@internationalized/date"
import React, { useState } from "react"

import { withRootProvider } from "v2/react/components/RootProvider"
import { useTimeZone } from "v2/react/hooks/useTimeZone"
import { LoadingWrapper } from "v2/react/shared/loaders/LoadingWrapper"
import { getCookie, setCookie } from "v2/react/utils/cookies"
import { useGetHeadcountPlanWithTreeQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { Notifications } from "./Overview/Notifications"
import { ParticipantTable } from "./Overview/ParticipantTable"

interface Props {
  headcountPlanId: string
  rootPositionId: string | undefined
  participantIds: string[]
  maxChartDepth: number
}

function WithProvider({ headcountPlanId, rootPositionId, participantIds, maxChartDepth }: Props) {
  const { data, isError } = useGetHeadcountPlanWithTreeQuery({
    id: headcountPlanId,
    maxChartDepth,
    rootPositionId,
    participantIds,
  })
  const timeZone = useTimeZone()
  const headcountPlan = data?.headcountPlan
  const cookieName = `built__dismissed-invite-notification-for-hcp-${headcountPlanId}`
  const [showNotifications, setShowNotifications] = useState(getCookie(cookieName) !== "true")
  const isFinalized = !!headcountPlan?.lockedAt
  const planStarted =
    !!headcountPlan?.startDate && parseDate(headcountPlan?.startDate) <= today(timeZone)

  const handleNotificationsCloseClick = () => {
    setShowNotifications(false)
    setCookie(cookieName, "true", 365 * 5) // 5 years since we'll have another solution by then
  }

  return (
    <LoadingWrapper isLoading={!headcountPlan} isError={isError}>
      {!!headcountPlan && (
        <div className="p-12">
          <Notifications
            headcountPlan={headcountPlan}
            onClose={handleNotificationsCloseClick}
            showNotifications={showNotifications}
            planStarted={planStarted}
          />
          <ParticipantTable
            showEntireOrgRow={headcountPlan.currentUserCanManage}
            headcountPlanId={headcountPlan.id}
            logoThumbUrl={headcountPlan.company.logoThumbUrl || ""}
            companyName={headcountPlan.company.name || ""}
            totalBudget={headcountPlan.formattedBudget}
            totalProposal={headcountPlan.formattedProposedBudget}
            totalRemainingBudget={headcountPlan.remainingBudget}
            participants={headcountPlan.participantsTree}
            canBeFinalized={headcountPlan.canBeFinalized}
            showBudget={headcountPlan.setBudget}
            showProposal={headcountPlan.setBudget && !isFinalized}
            showStatus={!isFinalized}
            showPositionCount={isFinalized}
            planStarted={planStarted}
            planMetrics={headcountPlan.finalAggregations}
          />
        </div>
      )}
    </LoadingWrapper>
  )
}

const Overview = withRootProvider(WithProvider, "Overview")

export { Overview }
