import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, JsonFormQuery, Position, ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode, SourcePay } from "types/graphql.enums"
import { SendToAts } from "v2/react/components/jobRequisitions/SendToAts/SendToAts"
import { InternalReqInfo } from "v2/react/components/orgChart/ProfilePanel/PositionModules/InternalReqInfo"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { RequisitionForm } from "v2/react/pages/job_requisitions"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useGetJsonFormQuery } from "v2/redux/GraphqlApi"
import { useAppSelector } from "v2/redux/store"

import { usePositionLoader } from "../hooks/usePersonPositionLoader"

interface RecruitingProps {
  abilities: ProfilePanelPermissions
  featureFlags: FeatureFlags
}

function Recruiting({ abilities, featureFlags }: RecruitingProps) {
  const { currentBox, toggleBox } = useSelectedBox("recruiting", ProfilePanelMode.Position)
  const position = useAppSelector((state) => state.profilePanel.position)

  const { t } = useTranslation()
  const { position: positionData, queryPositionTrigger } = usePositionLoader(position?.id)
  const { data: jsonForm } = useGetJsonFormQuery({ modelType: "JobRequisition" })

  useEffect(() => {
    if (positionData || !position) return
    queryPositionTrigger({ positionId: position.id })
  }, [position, positionData, queryPositionTrigger])

  if (!currentBox || !position) return null
  if (!featureFlags.positionManagement) return null

  const headerContent = <div className="title">{t("v2.profile_panel.recruiting")}</div>
  const requisitions =
    position.activeRecruitingJobRequisitions || positionData?.activeJobRequisitions || []
  const people = [...(position.people || [])]

  const internalInfoPanel = (
    <div>
      <InternalReqInfo requisitions={requisitions} />
    </div>
  )

  const data = buildInitialData(positionData || position, jsonForm)

  const emptyState = () => (
    <div className="place-content-center gap-2 text-center grid">
      <div className="justify-center flex">
        <div className="icon--round">
          <FontAwesomeIcon icon={["far", "briefcase"]} className="fa-lg" />
        </div>
      </div>
      <div className="mb-1.5">
        <p>{t("v2.profile_panel.create_requisition").split("/")[0]}</p>
        <p>{t("v2.profile_panel.create_requisition").split("/")[1]}</p>
      </div>
      <div className="justify-center flex">
        <RequisitionForm
          data={data}
          abilities={{ canCreate: abilities.canCreateJobRequisitions }}
          onComplete={() => queryPositionTrigger({ positionId: position.id })}
        />
      </div>
      {hasSendToAtsSupport() && (
        <>
          <p>{t("v2.defaults.or").toLocaleLowerCase()}</p>
          <div className="-mt-0.5">
            <SendToAts
              asLink
              asDropdownMenuLink={false}
              rmInfoOnly={false}
              title={position.title || ""}
              linkTitle={t("v2.profile_panel.external_ats")}
              positionId={position.id}
              orgUnitNames={(position.orgUnits || []).map((ou) => ou.name || "")}
              locationName={position.location?.label || ""}
              replacementFor={people.pop()?.fullName || undefined}
              jobDescription={position.sanitizedDescription ?? ""}
              numPositions={1}
            />
          </div>
        </>
      )}
    </div>
  )

  const internalReqs = requisitions.length > 0 ? internalInfoPanel : emptyState()

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <div className="w-full">{internalReqs}</div>
    </AccordionModulePanel>
  )
}

const hasSendToAtsSupport = () =>
  window.gon.has_send_to_ats_support &&
  (window.gon.selected_chart === undefined || window.gon.selected_chart?.official === true)

const buildInitialData = (position: Position, jsonForm: JsonFormQuery | undefined) => {
  const initialData = {
    additionalNotes: "",
    jobDescription: position.sanitizedDescription ?? position.description ?? "",
    justification: "",
    createdAt: "",
    createdBy: "",
    reqType: "backfill",
    sourceOpenings: 1,
    systemUid: "",
    schemaType: "generic" as const,
    backfillPosition: {
      filledBy: position.filledBy?.pop()?.fullName ?? "",
      id: `position_${position.id}`,
      lastFilledBy: position.lastFilledBy?.fullName ?? "",
      name: position.title ?? "",
    },
    attachment: {
      fileBase64Data: "",
      title: "",
      uploadId: "",
      uploadUrl: "",
    },
    position: {
      isBudgeted: position.isBudgeted ? "yes" : "no",
      hasDirectReports: position.hasDirectReports ? "yes" : "no",
      variablePayTypes:
        position.variablePays?.map((vp) => ({
          id: vp.field_id,
          name: String(vp.display_name),
          type: `variable_pay_pay_type_${vp.pay_type ?? SourcePay.Amount}`,
          amount: Number(vp.amount),
        })) ?? [],
      fte: position.fte ?? 0,
      flsa: position.flsa ?? "",
      totalAnnualCompensation: 0,
      schemaType: "generic" as const,
      jobCode: position.jobCode ?? "",
      companyCode: position.companyCode ?? "",
      assignedParent: position.assignedParent ?? false,
      projectedHireDate: position.projectedHireDate ?? "",
      externalIdentifier: position.externalIdentifier ?? "",
      orgUnits:
        position.orgUnits?.map((unit) => ({
          id: unit.id ?? "",
          name:
            (unit.code?.length || 0) > 0
              ? `${unit.code} - ${unit.formatted_value}`
              : unit.formatted_value || "",
          typeId: unit.field_id,
          typeName: unit.orgUnitType?.name ?? "",
        })) ?? [],
      employeeType: {
        id: position.employeeType?.uniqueKey ?? "",
        name: position.employeeType?.labelTranslated ?? "",
      },
      eeocClassification: {
        id: position.eeocClassificationOption?.id ?? "",
        name: position.eeocClassificationOption?.label ?? "",
      },
      location: {
        id: position.location?.uniqueKey ?? "",
        name: position.location?.label ?? "",
      },
      businessUnit: {
        id: position.businessUnit?.id ?? "",
        name: position.businessUnit?.label ?? "",
      },
      costNumber: {
        id: position.costNumber?.id ?? "",
        name: position.costNumber?.label ?? "",
      },
      department: {
        id: position.department?.id ?? "",
        name: position.department?.label ?? "",
      },
      jobTitle: {
        id: `position_type_${position.positionType?.id}`,
        title: position.title ?? "",
        jobCode: position.jobCode ?? "",
        jobCodeTitleLabel: position.jobCode
          ? `${position.jobCode} - ${position.title}`
          : position.title ?? "",
        jobLevel: { id: "", codeNameLabel: "" },
        payGrade: { id: "", name: "" },
      },
      reportsTo: {
        id: position.parent?.uniqueKey ?? "",
        name: position.reportsToName ?? "",
      },
      hiringPriority: {
        id: position.hiringPriorityOption?.id ?? "",
        name: position.hiringPriorityOption?.label ?? "",
      },
      basePay: {
        amount: position.positionBasePay || 0,
        hoursPerWeek: position.positionHoursPerWeek || 0,
        name: "base-pay",
        id: "base-pay",
        payType: {
          id: `position_base_pay_type_${(position.positionBasePayType ?? "").toLowerCase()}`,
          name: position.positionBasePayType ?? "",
        },
      },
    },
  }
  if (!jsonForm?.jsonForm) return initialData

  const excludedOrgUnitKeys = jsonForm.jsonForm.options.excluded_org_unit_type_keys ?? []
  const excludedVariablePayTypeKeys =
    jsonForm.jsonForm.options.excluded_variable_pay_type_keys ?? []

  if (excludedOrgUnitKeys.length > 0) {
    initialData.position.orgUnits = initialData.position.orgUnits.filter(
      (ou) => !excludedOrgUnitKeys.includes(ou.typeId),
    )
  }
  if (excludedVariablePayTypeKeys.length > 0) {
    initialData.position.variablePayTypes = initialData.position.variablePayTypes.filter(
      (variablePayType) => !excludedVariablePayTypeKeys.includes(variablePayType.id),
    )
  }

  return initialData
}

export { Recruiting }
