import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { GraphQLFormattedError } from "graphql"
import React from "react"
import { useTranslation } from "react-i18next"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { errorFor } from "v2/react/utils/errors"

export function PlanNewPositionIdPrefix({
  isSuccess,
  errors,
  defaultValue,
}: {
  isSuccess: boolean
  errors: GraphQLFormattedError[]
  defaultValue?: string
}) {
  const { t } = useTranslation()
  const errorMessage = errorFor("newPositionIdPrefix", errors)
  return (
    <div className="input-group max-w-[10.5rem]">
      <div>
        <label htmlFor="newPositionIdPrefix" className="flex">
          {t("v2.headcount_plan.setup.new_position_id_prefix")}{" "}
          <Tooltip placement="top">
            <TooltipTrigger>
              <div className="mt-[-3px] h-6 w-6 items-center justify-center rounded-[5px] p-[5px] grid hover:bg-neutral-8">
                <FontAwesomeIcon icon={["far", "circle-info"]} />
              </div>
            </TooltipTrigger>
            <TooltipContent className="react-tooltip-content--base">
              {t("v2.headcount_plan.setup.new_position_id_prefix_description")}
            </TooltipContent>
          </Tooltip>
        </label>
      </div>
      <div className={classNames("width-full", { "form-error": errorMessage })}>
        <input
          type="text"
          id="newPositionIdPrefix"
          className="input"
          name="newPositionIdPrefix"
          defaultValue={defaultValue}
        />
      </div>
      <div className={classNames({ "form-error": errorMessage })}>
        <p className="form-error-message">{isSuccess && errorMessage}</p>
      </div>
    </div>
  )
}
