import { parseDate } from "@internationalized/date"
import { isEmpty, snakeCase } from "lodash"
import React from "react"
import { DateValue } from "react-aria-components"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { OffsetBasedCollection, Option, OptionCollection } from "types/graphql"
import { SendToAtsFormProps } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/SendToAtsForm"
import {
  emptyCommonElement,
  SmartRecruitersFormDataType,
} from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/utils"
import { TextInput } from "v2/react/shared/forms"
import { DatePicker } from "v2/react/shared/forms/DateInputs/DatePicker"
import { DynamicDropdownInput } from "v2/react/shared/forms/DynamicDropdownInput"
import { BasicModule } from "v2/react/shared/layout/BasicModule"

interface MissingRequisitionFieldsProps {
  atsOptions: SendToAtsFormProps["initialData"]["auxiliary"]["atsOptions"]
}

const MissingRequisitionFields = ({ atsOptions }: MissingRequisitionFieldsProps) => {
  const { t } = useTranslation()

  const {
    formState: { defaultValues, errors },
  } = useFormContext<SmartRecruitersFormDataType>()

  const missingLocation = isEmpty(defaultValues?.job?.locationId)
  const missingDepartment = isEmpty(defaultValues?.job?.departmentId)
  const missingTargetStartDate = isEmpty(defaultValues?.positionOpenings?.targetStartDate)
  const missingRefNumber = isEmpty(defaultValues?.job?.refNumber)

  return (
    <BasicModule title={t("v2.job_requisitions.modals.send_to_ats.missing_requisition_data")} dark>
      <div className="label-bold flex-col gap-6 p-0 flex">
        {missingRefNumber && (
          <div className="flex-col gap-6 flex 480:flex-row">
            <TextInput
              id="refNumber"
              name="job.refNumber"
              label={t("v2.job_requisitions.modals.show.smart_recruiters.id")}
              errors={
                errors?.job?.refNumber
                  ? t(
                      `v2.job_requisitions.modals.send_to_ats.smart_recruiters.errors.${errors?.job?.refNumber?.message}`,
                      {
                        defaultValue: errors?.job?.refNumber?.message,
                      },
                    )
                  : undefined
              }
              useInReactHookForm
              infoMessage={t(
                "v2.job_requisitions.modals.send_to_ats.smart_recruiters.ref_number_info",
              )}
            />
          </div>
        )}
        {(missingLocation || missingDepartment) && (
          <div className="flex-col gap-6 flex 480:flex-row">
            {missingDepartment && (
              <FormDropdown
                variant="department"
                id="departmentId"
                collection={atsOptions?.departmentCollection}
                addNoneOption
              />
            )}
            {missingLocation && (
              <FormDropdown
                variant="location"
                id="locationId"
                collection={atsOptions?.locationCollection}
              />
            )}
          </div>
        )}
        <div className="flex-col gap-6 flex 480:flex-row">
          <FormDropdown
            variant="common"
            id="industry"
            collection={atsOptions?.industryCollection}
          />
          <FormDropdown
            variant="common"
            id="function"
            collection={atsOptions?.functionCollection}
          />
          <FormDropdown
            variant="common"
            id="experienceLevel"
            collection={atsOptions?.experienceLevelCollection}
          />
        </div>
        <div className="flex-col gap-6 flex 480:flex-row">
          <DateInput id="positionOpenDate" />
          {missingTargetStartDate && <DateInput id="targetStartDate" />}
        </div>
      </div>
    </BasicModule>
  )
}

const DateInput = ({ id }: { id: "positionOpenDate" | "targetStartDate" }) => {
  const { t } = useTranslation()
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<SmartRecruitersFormDataType>()

  const value = watch(`positionOpenings.${id}`)

  const handleChange = (value: DateValue) => {
    setValue(`positionOpenings.${id}`, value.toString())
  }

  const errorKey = errors?.positionOpenings?.[id]?.message
  const errorMessage = errorKey
    ? t(`v2.job_requisitions.modals.send_to_ats.smart_recruiters.errors.${errorKey}`, {
        defaultValue: errorKey,
      })
    : undefined

  return (
    <DatePicker
      id={id}
      label={t(
        `v2.job_requisitions.modals.send_to_ats.smart_recruiters.position_openings.${snakeCase(
          id,
        )}`,
      )}
      onChange={handleChange}
      showPlaceholderOnFocus
      withIcon
      wrapperClassName="border-none bg-transparent p-0 w-full"
      value={value ? parseDate(value) : null}
      errorMessage={errorMessage}
    />
  )
}

type CommonElementCollection =
  | (Omit<OffsetBasedCollection, "options"> & { options: Omit<OptionCollection, "metadata"> })
  | undefined
  | null

type FormDropdownProps =
  | {
      variant: "common"
      id: "industry" | "function" | "experienceLevel"
      collection: CommonElementCollection
      addNoneOption?: boolean
    }
  | {
      variant: "department"
      id: "departmentId"
      collection: CommonElementCollection
      addNoneOption?: boolean
    }
  | {
      variant: "location"
      id: "locationId"
      collection: MissingRequisitionFieldsProps["atsOptions"]["locationCollection"]
      addNoneOption?: boolean
    }

const FormDropdown = ({ variant, id, collection, addNoneOption }: FormDropdownProps) => {
  const { t } = useTranslation()

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<SmartRecruitersFormDataType>()
  if (!collection) return null

  const { label, name, totalCount, options: optionsWrapper } = collection

  let options: Option[] = []
  if ("nodes" in optionsWrapper) {
    options = optionsWrapper.nodes
  } else if ("collection" in optionsWrapper) {
    options = optionsWrapper.collection
  }

  if (addNoneOption) {
    options = [...options]
    options.unshift({
      id: "",
      label: t("v2.defaults.none"),
    })
  }

  const watchedValue = watch(`job.${id}`)
  const selected =
    typeof watchedValue === "string" ? options.find((o) => o.id === watchedValue) : watchedValue

  let errorKey: string | undefined
  if (variant === "common") {
    errorKey = errors?.job?.[id]?.id?.message
  } else {
    errorKey = errors?.job?.[id]?.message
  }
  const errorMessage = errorKey
    ? t(`v2.job_requisitions.modals.send_to_ats.smart_recruiters.errors.${errorKey}`, {
        defaultValue: errorKey,
      })
    : undefined

  const handleSelect = (option: Option) => {
    if (variant === "common") {
      setValue(`job.${id}`, option)
    } else {
      setValue(`job.${id}`, option.id)
    }
  }

  const handleClear = () => {
    if (variant === "common") {
      setValue(`job.${id}`, emptyCommonElement())
    } else {
      setValue(`job.${id}`, "")
    }
  }

  const labelForForm =
    variant === "location" ? t("v2.job_requisitions.modals.show.smart_recruiters.location") : label

  return (
    <div className="flex-1">
      <DynamicDropdownInput
        id={name}
        label={labelForForm}
        items={options}
        itemCount={totalCount}
        selected={selected ?? undefined}
        onSelect={handleSelect}
        onClear={handleClear}
        error={errorMessage}
      />
    </div>
  )
}

export { MissingRequisitionFields }
