import React from "react"

import { NotificationRecipient, Recruiter } from "types/graphql"
import { Approvers } from "v2/react/components/jobRequisitions/Overview/RightSection/Approvers"
import { NotificationRecipients } from "v2/react/components/jobRequisitions/Overview/RightSection/NotificationRecipients"
import { Recruiters } from "v2/react/components/jobRequisitions/Overview/RightSection/Recruiters"
import { JobRequisitionAbilities } from "v2/react/components/jobRequisitions/types"
import { ApproverType } from "v2/react/shared/status/statusUtils"
import {
  setEditApproversModalOpen,
  setEditNotifyeesModalOpen,
  setEditRecruitersModalOpen,
} from "v2/redux/slices/RequisitionSlice"
import { useAppDispatch } from "v2/redux/store"

import { IntegrationInfo } from "./IntegrationInfo"

interface Props {
  abilities: JobRequisitionAbilities
  approvalState: string | null | undefined
  approvers: ApproverType[]
  atsIdentifier: string
  clientIdentifier: string
  status: string
  externalStatus: string
  jobRequisitionId: string
  notificationRecipients: NotificationRecipient[]
  recruiters: Recruiter[]
  systemUid: string
  title: string
  workflowDate: Date
  replacementFor: string
  jobDescription: string
  isReplacement: boolean
  numPositions: number
}

function RightSection({
  abilities,
  approvalState,
  approvers,
  atsIdentifier,
  clientIdentifier,
  status,
  externalStatus,
  jobRequisitionId,
  notificationRecipients,
  recruiters,
  systemUid,
  title,
  workflowDate,
  replacementFor,
  jobDescription,
  isReplacement,
  numPositions,
}: Props) {
  const dispatch = useAppDispatch()

  const showApproversModal = () => {
    dispatch(setEditApproversModalOpen(true))
  }

  const showNotifyeesModal = () => {
    dispatch(setEditNotifyeesModalOpen(true))
  }

  const showRecruitersModal = () => {
    dispatch(setEditRecruitersModalOpen(true))
  }

  const recruitersSection = () => (
    <Recruiters
      onShowUpdateModal={showRecruitersModal}
      recruiters={recruiters}
      approvalState={approvalState}
      externalStatus={externalStatus}
    />
  )

  const approvedStates = ["approved", "closed"]
  const currentState = approvalState || ""

  return (
    <div className="page-gap flex-col flex">
      <IntegrationInfo
        abilities={abilities}
        approvalState={currentState}
        atsIdentifier={atsIdentifier}
        clientIdentifier={clientIdentifier}
        externalStatus={externalStatus}
        jobRequisitionId={jobRequisitionId}
        status={status}
        systemUid={systemUid}
        title={title}
        replacementFor={replacementFor}
        jobDescription={jobDescription}
        isReplacement={isReplacement}
        numPositions={numPositions}
      />
      {approvedStates.includes(currentState) ? recruitersSection() : ""}
      <Approvers
        approvers={approvers}
        approvalState={currentState}
        onShowUpdateModal={showApproversModal}
        workflowDate={workflowDate}
        workflowStatus={approvalState}
      />
      <NotificationRecipients
        approvalState={currentState}
        onShowUpdateModal={showNotifyeesModal}
        recipients={notificationRecipients}
      />
      {approvedStates.includes(currentState) ? "" : recruitersSection()}
    </div>
  )
}

export { RightSection }
