/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames"
import React, { MutableRefObject } from "react"

import { CompareValues } from "./CompareValues"
import { useDatasheetContext } from "./context"
import { DropdownMenu } from "./DropdownMenu"
import { useAutocompleteList } from "./hooks/useAutocompleteList"
import { useCellHandlers } from "./hooks/useCellHandlers"
import { useCellState } from "./hooks/useCellState"
import { EditingCellHandle } from "./hooks/useEditingCellRegistry"
import { useOptimisticValue } from "./hooks/useOptimisticValue"
import { StyleLayers } from "./StyleLayers"
import { CellInput, FieldType, SaveFn } from "./types"

type ForcedAutocompleteOption = {
  id: string
  label?: string
}

function defaultGetLabel<T extends ForcedAutocompleteOption>(option: T | null | undefined) {
  return option?.label ?? ""
}

type Props<T> = {
  compareValue?: T | null
  currentValue: T
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  renderOption: (option: T, isActive: boolean) => React.ReactNode
  disableOption?: (option: T) => boolean
  options: Array<T>
  notFoundMsg?: string
  saveFn: SaveFn<T>
  onSaved?: () => void
  onErrored?: () => void
  onEditing?: () => void
  columnId: string
  rowId: string
  editable?: boolean
  className?: string
  style?: React.CSSProperties
  getLabel?: (option: T | null | undefined) => string
  clearable?: boolean
  cellInputRef?: React.Ref<CellInput>
}
export function ForcedAutocompleteCell<T extends ForcedAutocompleteOption>({
  compareValue,
  searchTerm,
  setSearchTerm,
  renderOption,
  disableOption,
  options,
  saveFn,
  onSaved,
  onErrored,
  onEditing,
  currentValue,
  notFoundMsg,
  columnId,
  rowId,
  editable,
  className,
  style,
  cellInputRef,
  getLabel = defaultGetLabel,
  clearable = false,
}: Props<T>) {
  const [optimisticValue, setOptimisticValue] = useOptimisticValue(getLabel(currentValue))
  const initialValueRef = React.useRef<string | null>(null)
  const cellRef = React.useRef<HTMLDivElement>(null)
  const editingCellHandleRef = React.useRef<EditingCellHandle>(null)

  React.useImperativeHandle(
    editingCellHandleRef,
    (): EditingCellHandle => ({
      save: () => cell.dispatch({ type: "save", value: getSaveValue() }),
      contains: (target) =>
        cellRef.current?.contains(target as Node) === true ||
        autocompleteList.refs.floating.current?.contains(target as Node) === true,
    }),
  )

  React.useImperativeHandle(cellInputRef, () => ({
    getValue: () => searchTerm,
  }))

  const { registerEditingCell, unregisterEditingCell } = useDatasheetContext()

  const cell = useCellState<T>({
    currentValue,
    fieldType: FieldType.ForcedAutocomplete,
    rowId,
    columnId,
    editable,
    hasNotChanged: (value) => value?.id === currentValue?.id,
    saveFn: (state) => saveFn(state.value),
    onIdle: () => {
      setSearchTerm(getLabel(currentValue))
    },
    onEditing: (state) => {
      registerEditingCell(editingCellHandleRef)
      document.getElementById(`${columnId}-${rowId}-input`)?.focus()
      if (typeof state.initial === "string") {
        initialValueRef.current = state.initial
        setSearchTerm(state.initial)
      }
      onEditing?.()
    },
    onSaving: () => {
      unregisterEditingCell()
    },
    onSaved: (state) => {
      const label = getLabel(state.value)
      setSearchTerm(label)
      if (state.value) {
        setOptimisticValue(label)
      }
      onSaved?.()
    },
    onErrored: () => onErrored?.(),
  })

  const autocompleteList = useAutocompleteList({
    showList: cell.isEditing,
    minWidthAdjustment: 34,
  })
  const inputRef = autocompleteList.refs.reference as MutableRefObject<HTMLInputElement>

  const getSaveValue = () => {
    if (clearable && searchTerm === "") {
      return { id: "", label: "" }
    }
    const selectedOption = options[autocompleteList.activeIndex]
    if (selectedOption && !disableOption?.(selectedOption)) {
      return selectedOption
    }
    return currentValue
  }

  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, inputRef, {
    getSaveValue,
    moveDown: () =>
      autocompleteList.setActiveIndex((prev) =>
        prev === null ? 0 : Math.min(options.length - 1, prev + 1),
      ),
    moveUp: () =>
      autocompleteList.setActiveIndex((prev) => (prev === null ? 0 : Math.max(0, prev - 1))),
  })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      initialValueRef.current === null
        ? event.target.value
        : `${initialValueRef.current}${event.target.value.slice(-1)}`
    initialValueRef.current = null
    setSearchTerm(value)
  }

  const hideCompareValue = cell.isEditing || cell.isSaving || cell.isSaved
  const showSearchTerm = cell.isEditing || cell.isErroredEditing
  const showNotFoundMsg =
    searchTerm.length > 0 && options.length === 0 && typeof notFoundMsg === "string"
  const disableInput = !cell.isEditing && !cell.isErroredEditing
  const showDropdownMenu =
    (cell.isEditing || cell.isErroredEditing) && (options.length > 0 || showNotFoundMsg)
  const showStateValue = cell.isSaving || cell.isSaved || cell.isErrored || cell.isErroredSaving

  // eslint-disable-next-line no-nested-ternary
  const inputValue = showSearchTerm
    ? searchTerm
    : showStateValue
    ? getLabel(cell.value)
    : optimisticValue ?? getLabel(currentValue)

  const errored =
    cell.isErrored || cell.isErroredEditing || cell.isErroredSaving || cell.isErroredSelected

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      role="button"
      id={`${columnId}-${rowId}`}
      className={classNames("editable-cell", className, { "editable-cell--errored": errored })}
      onClick={handleCellClick}
      onKeyUp={handleCellKeyUp}
      style={style}
      ref={cellRef}
    >
      <CompareValues
        compareValue={hideCompareValue ? null : getLabel(compareValue)}
        style={{ maxWidth: "100%", width: "100%" }}
      >
        <input
          id={`${columnId}-${rowId}-input`}
          ref={autocompleteList.refs.setReference}
          {...autocompleteList.getReferenceProps()}
          className={classNames("-ml-0.5 h-full w-full border-none bg-white", {
            "pointer-events-none": disableInput,
          })}
          value={inputValue ?? ""}
          onChange={handleInputChange}
          disabled={disableInput}
          name={columnId}
          autoComplete="off"
        />
      </CompareValues>
      <DropdownMenu
        showList={showDropdownMenu}
        floatingRef={autocompleteList.refs.setFloating}
        floatingStyles={{
          ...autocompleteList.floatingStyles,
          zIndex: 5,
          marginLeft: "-0.95rem",
        }}
        floatingProps={autocompleteList.getFloatingProps}
        wrapperClasses="autocomplete-container"
        context={autocompleteList.context}
      >
        <div className="list-group autocomplete-list">
          {showNotFoundMsg && <li className="m-0 p-4 text-neutral-64">{notFoundMsg}</li>}
          {options.map((option, index) => {
            const disabled = disableOption?.(option) ?? false

            return (
              <li
                role="option"
                className={textClassName(autocompleteList.activeIndex, index, disabled)}
                aria-selected={autocompleteList.activeIndex === index}
                key={option.id}
                ref={(node) => {
                  autocompleteList.listRef.current[index] = node
                }}
                {...autocompleteList.getItemProps({
                  onClick: () => {
                    if (!disabled) {
                      cell.dispatch({ type: "save", value: option })
                    }
                  },
                })}
              >
                {renderOption(option, autocompleteList.activeIndex === index)}
              </li>
            )
          })}
        </div>
      </DropdownMenu>
      <StyleLayers cell={cell} fieldType={FieldType.ForcedAutocomplete} />
    </div>
  )
}

const textClassName = (activeIndex: number | null, index: number, disabled: boolean) =>
  classNames("list-group-item mb-0 justify-start gap-1.5", {
    highlight: activeIndex === index,
    disabled,
  })
