import { stratify as d3Stratify, HierarchyNode } from "d3-hierarchy"
import { TFunction } from "i18next"
import fp from "lodash/fp"
import { NodeData } from "org_chart/chart/node/types"
import OrgChart, { ChartNode } from "org_chart/chart/orgChart"
import { DataLoaderFn } from "org_chart/chart/utils/positionDataLoader"

import { ChartNodeHistory, Company, Maybe } from "types/graphql.d"
import { UrlHelper } from "v2/react/utils/urls"
import { ChartNodeHistoriesApi } from "v2/redux/GraphqlApi/ChartNodeHistoriesApi"
import { AppDispatch } from "v2/redux/store"

/**
 * Base fn for stratifying flat data into a tree structure for use with the
 * OrgChart D3 visualization.
 */
const internalStratify = d3Stratify()
  .id((d): string => (d as ChartNodeHistory).id)
  .parentId((d): Maybe<string> | undefined => (d as ChartNodeHistory).parent_id)

const transformDynamicField = fp.pipe(fp.keyBy("field_id"), fp.mapValues("formatted_value"))

/**
 * In order to conform to NodeInterface, the dynamic fields
 * (custom_field_values, variable_pays, and org_units) are configured as object
 * types that match the field definitions in NodeInterface. The front-end org
 * chart expects simple values for these however: values keyed by their unique
 *  key. This transforms things back into the "simple" format expected by the org
 *  chart. This is a consequence of implementing the NodeInterface, which is
 *  probably worth it, since it should enable easy usage of the ChartNodeHistory
 *  data in the spreadsheet view.
 */
const transformDynamicFields = fp.pipe(
  fp.update("custom_field_values", transformDynamicField),
  fp.update("variable_pays", transformDynamicField),
  fp.update("org_units", transformDynamicField),
)

/**
 * Get the chart view ID/unique key from an orgchart path
 */
const chartViewIdFromUrl = (): string | null => {
  const path = globalThis.location?.pathname
  if (!path) return null
  if (path.match(/orgchart\/top\/\d+/)) {
    const positionId = path.split("/").slice(-1)[0]
    return `position_${positionId}`
  }

  if (path.match(/orgchart\/chart_sections\/\d+/)) {
    const chartSectionId = path.split("/").slice(-1)[0]
    return `chart_section_${chartSectionId}`
  }

  // The Headcount Plan Org Chart uses this URL
  if (path.match(/org_chart\/top\/.+/)) {
    const positionId = path.split("/").slice(-1)[0]
    return positionId
  }

  // The orgchart lite URL uses plain IDs along with a chart_view_type
  // parameter, so this needs to be converted to the unique keyed format that
  // the historical chart uses.
  if (path.match(/orgchart\/lite/)) {
    const chartViewId = new URLSearchParams(globalThis.location.search).get("chart_view_id")
    const chartViewType = new URLSearchParams(globalThis.location.search).get("chart_view_type")

    if (chartViewType && chartViewType === "top_position") {
      return `position_${chartViewId}`
    }

    if (chartViewType && chartViewType === "chart_section") {
      return `chart_section_${chartViewId}`
    }

    return null
  }

  return null
}

const chartViewTypeAndIdFromUrl = (): string[] | null[] => {
  const path = window.location.pathname
  if (path.match(/orgchart\/top\/\d+/)) {
    const positionId = path.split("/").slice(-1)[0]
    return ["top_position", positionId]
  }

  if (path.match(/orgchart\/chart_sections\/\d+/)) {
    const chartSectionId = path.split("/").slice(-1)[0]
    return ["chart_section", chartSectionId]
  }

  return [null, null]
}

/**
 * The chart node history nodes contain these 3 important data points:
 *
 *   id: the id of the chart node history entry
 *   position_id: the id of the associated position
 *   parent_id: the id of the associated position's parent
 *
 * The id is central to a lot of orgchart functionality, and it's generally
 * assumed that this corresponds to a record id. In this context, the `id`
 * represents the history entry id rather than the position id itself, causing
 * a disconnection between the node's identity (`id`) and its position in the
 * hierarchy (`position_id`).
 *
 * In order to enable things like loading a node by id and having the hierarchy
 * connected to the actual node id, we remap the parent_id value (position
 * parent id) for each node to the chart history entry id. This allows us to
 * preserve the position hierarchy while having nodes keyed by their history
 * entry ID rather than the `position_id`.
 */
const createRemapParentId =
  (baseHistoryData: ChartNodeHistory[]) =>
  (node: ChartNodeHistory): ChartNodeHistory => {
    if (node.parent_id) {
      const parentNode = baseHistoryData.find(
        (otherNode) => otherNode.position_id === node.parent_id && otherNode.id !== node.id,
      )
      return { ...node, parent_id: parentNode?.id || null }
    }
    return node
  }

/**
 * The company node acts as the parent of all nodes who do not have a parent.
 * This transforms those nodes without a parent so they report to the company
 * node.
 */
const setNullParentToCompanyNode = (node: ChartNodeHistory): ChartNodeHistory => {
  if (node.parent_id === null) {
    return {
      ...node,
      parent_id: "company",
    }
  }

  return node
}

const remapTopPositionToReportToNone = (node: ChartNodeHistory): ChartNodeHistory => {
  const chartViewId = chartViewIdFromUrl()
  if (node.position_id === chartViewId || node.parent_id === "company") {
    return {
      ...node,
      parent_id: null,
    }
  }

  return node
}

/**
 * Base fn for transforming the graphQL chart history data into a format
 * appropriate for use with the OrgChart.
 */
const reshapeDataForHierarchy = (
  nodes: ChartNodeHistory[],
  company: Company,
  addCompanyNode: boolean,
): NodeData[] => {
  const rootNodes = nodes.filter((node) => node.parent_id === null)
  const allTitles = rootNodes.map((node) => node.meta?.aggregates?.titles || []).flat(1)
  const allChartSections = rootNodes
    .map((node) => node.meta?.aggregates?.chart_sections || [])
    .flat(1)
  const companyNode = {
    name: company.name,
    avatar: company.logoThumbUrl,
    klass: "Company",
    id: "company",
    position_id: "company",
    parent_id: null,
    payload: {},
    meta: {
      aggregates: {
        titles: fp.uniq(allTitles),
        chart_sections: fp.uniq(allChartSections),
      },
    },
  }

  const remapParentId = createRemapParentId(nodes)
  const transformNodes = fp.pipe(
    fp.map(remapParentId),
    fp.map(transformDynamicFields),
    ...(addCompanyNode ? [fp.map(setNullParentToCompanyNode)] : []),
    ...(!addCompanyNode ? [fp.map(remapTopPositionToReportToNone)] : []),
  )

  return [...(!addCompanyNode ? [] : [companyNode]), ...transformNodes(nodes)]
}

/**
  Builds a function that can be used to asynchronously load chart node history
  for the org chart using the GraphQL API. See [PositionDataLoader]
*/
const buildDataLoaderFn = (orgChartId: string, dispatch: AppDispatch): DataLoaderFn => {
  const dataLoader = async (nodeIds: number[], chart: OrgChart) => {
    if (nodeIds.length === 0) {
      return Promise.resolve(null)
    }

    const endpoint = ChartNodeHistoriesApi.endpoints.chartNodeHistoryByIdsFullData
    const state = dispatch((_, getState) => getState())
    const asOfDate = state.visualization.historyModeSelectedDate
    const result = dispatch(
      endpoint.initiate({
        uniqueKey: orgChartId,
        ids: nodeIds.map((id: number) => id.toString()),
        asOf: asOfDate || "",
      }),
    )

    const { data, isSuccess } = await result

    return new Promise((resolve, reject) => {
      if (!isSuccess || !data.chart?.chartHistoryNodes) {
        reject(new Error("Failed to load data or no chart history nodes found"))
        return
      }

      data.chart.chartHistoryNodes.forEach((nodeData) => {
        const orgChartNode = chart.find(nodeData.id, false)
        if (orgChartNode) {
          const newNode = Object.assign(orgChartNode, {
            ...fp.omit(["parent_id"], transformDynamicFields(nodeData)),
            loaded: true,
            loading: false,
          })
          chart.renderNode(newNode, { withChildren: false })
        }
      })

      chart.trigger("positions-loaded")
      resolve(data)
    })
  }

  return dataLoader
}

export interface ExtendedHierarchyNode extends HierarchyNode<NodeData> {
  total_subordinates?: number
  total_direct_reports?: number
  children_count?: number
  is_assistant?: boolean
  assistants?: NodeData[]
}

/**
 * Filter function for deciding if a descendant should be counted.
 */
const isCountableDescendant = (
  d: NodeData,
  descendant: NodeData,
  countOpenPositions: boolean,
  countDottedRelationships: boolean,
): boolean => {
  if (descendant.id === d.id) return false
  if (!countOpenPositions && !descendant.people_ids?.length) return false
  if (!countDottedRelationships && descendant.type === "secondary") return false

  return true
}

/**
 * Adds the children count numbers to each hierarchy node, taking into account
 * the given settings for subordinate counts. This allows for avoiding a
 * recalculation of all of this on the backend.
 *
 * Note that the `subordinates` and `total_direct_reports` fields are not
 * intended to be different based on the selected count options. That only
 * applies to children_count, which is the number displayed at the bottom of a
 * node that has children.
 */
const withDescendantCounts = (
  data: ExtendedHierarchyNode,
  countOpenPositions: boolean,
  countDottedRelationships: boolean,
  childrenCount: "all" | "immediate",
) =>
  data.each((d) => {
    const countableDescendants = d
      .descendants()
      .filter((descendant) =>
        isCountableDescendant(
          d.data,
          descendant.data,
          countOpenPositions,
          countDottedRelationships,
        ),
      )
    const countableChildren = d.children?.filter((descendant) =>
      isCountableDescendant(d.data, descendant.data, countOpenPositions, countDottedRelationships),
    )

    Object.assign(d, {
      total_subordinates: d.descendants().length - 1,
      total_direct_reports: d.children?.length || 0,
      children_count:
        childrenCount === "all" ? countableDescendants.length : countableChildren?.length || 0,
    })
  })

/**
 * D3 stratify will wrap all node data in `data`, so here we re-surface that
 * data at the top level.
 */
const unwrapDataFromHierarchy = (hierarchyNode: ExtendedHierarchyNode): ExtendedHierarchyNode => {
  hierarchyNode.each((node) => {
    Object.assign(node, node.data)
  })

  return hierarchyNode
}

/**
 * Stratifies the data and adds children/descendant counts.
 */
const buildHierarchyWithCounts = (
  data: NodeData[],
  countOpenPositions: boolean,
  countDottedRelationships: boolean,
  childrenCount: "all" | "immediate",
  chartViewId: Maybe<string> | undefined,
) => {
  const [assistants, nonAssistants] = fp.partition(
    (node) => node.is_assistant === true && chartViewId !== node.position_id,
    data,
  )
  const toStratifiedWithCounts = fp.pipe(
    internalStratify,
    (data: ExtendedHierarchyNode) => unwrapDataFromHierarchy(data),
    (data: ExtendedHierarchyNode) =>
      withDescendantCounts(data, countOpenPositions, countDottedRelationships, childrenCount),
  )
  let stratified
  if (nonAssistants.length === 0 && assistants.length === 1) {
    stratified = toStratifiedWithCounts(assistants)
  } else {
    stratified = toStratifiedWithCounts(nonAssistants)
  }

  if (assistants.length > 0) {
    stratified.each((d: NodeData) => {
      assistants.forEach((assistantNode) => {
        Object.assign(assistantNode, { total_subordinates: 0, total_direct_reports: 0 })
        if (d.id === assistantNode.parent_id) {
          if (
            d.parent === null ||
            (d.parent?.id === "company" && d.parent?.children?.length === 1)
          ) {
            const assistants = d.assistants ? [assistantNode, ...d.assistants] : [assistantNode]
            const updatedChildrenCount = (d.children_count ?? 0) + (assistants?.length ?? 0)
            Object.assign(d, { assistants, children_count: updatedChildrenCount })
          } else {
            const children = d.children ? [assistantNode, ...d.children] : [assistantNode]
            Object.assign(d, { children })
          }
        }
      })
    })
  }

  return stratified
}

const buildOrgchartActions = (
  t: TFunction,
  displayNodeAtTop: (node: NodeData) => void,
  setChartViewId: (id: string | null) => void,
) => [
  {
    action: "null-action",
    actionFn(node: NodeData) {
      const personId = node.person_id
      if (personId) window.location.href = UrlHelper.personProfilePath(personId.toString())

      return null
    },
    text: t("v2.historical_orgchart.actions.view_profile"),
    condition: (node: ChartNode) => !!node.get("person_id"),
    positionOnly: true,
    includeRoot: true,
    grouping: 0,
  },
  {
    action: "null-action",
    actionFn: displayNodeAtTop,
    text: t("v2.historical_orgchart.actions.display_chart_starting_here"),
    condition: (node: ChartNode) => !node.get("is_assistant"),
    positionOnly: true,
    includeRoot: false,
    grouping: 0,
  },
  {
    action: "null-action",
    text: t("v2.historical_orgchart.actions.display_full_chart"),
    actionFn() {
      window.history.pushState({}, "", UrlHelper.orgchartRootPath())
      setChartViewId(null)

      return null
    },
    positionOnly: true,
    includeRoot: true,
    onlyRoot: true,
  },
]

export {
  buildHierarchyWithCounts,
  reshapeDataForHierarchy,
  buildDataLoaderFn,
  chartViewIdFromUrl,
  chartViewTypeAndIdFromUrl,
  buildOrgchartActions,
}
