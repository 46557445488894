import { EntityId } from "@reduxjs/toolkit"
import { compact, map, omit, reject } from "lodash"
import OrgChart from "org_chart/chart/orgChart"

import { NodeData } from "../node/types"

type DataLoaderFn = (nodeIds: number[], chart: OrgChart) => Promise<unknown>

interface VisibleWindowCoordinateSet {
  min: number
  max: number
}

interface VisibleWindowCoordinates {
  x: VisibleWindowCoordinateSet
  y: VisibleWindowCoordinateSet
}

/**
 * Person data loaded through the Grape orgchart people endpoint, used in the
 * lists view.
 */
interface PersonData {
  id: EntityId
}

class PositionDataLoader {
  svg: d3.Selection<SVGElement>

  chart: OrgChart

  endpoint: string

  dataLoaderFn?: DataLoaderFn

  constructor(
    baseSvg: d3.Selection<SVGElement>,
    chart: OrgChart,
    endpoint: string,
    dataLoaderFn?: DataLoaderFn,
  ) {
    this.svg = baseSvg
    this.chart = chart
    this.endpoint = endpoint
    this.dataLoaderFn = dataLoaderFn
  }

  loadVisible() {
    const ids: number[] = []
    const personIds: number[] = []
    const visibleWindow = this.calculateVisibleWindow()
    const { chart } = this
    this.svg
      .selectAll("g.node")
      .filter((d) => {
        if (!visibleWindow) return false
        if (d.loaded === true || d.loading === true) {
          return false
        }

        return PositionDataLoader.visibleInWindow(d, visibleWindow)
      })
      .each(function (this: SVGGElement, d) {
        chart.renderNodeBase(this)
        // eslint-disable-next-line no-param-reassign
        d.loading = true
        if (d.id) {
          ids.push(d.id)
        } else if (d.person_id) {
          personIds.push(d.person_id)
        }
      })
    this.batchLoadByIds(ids)
    return this.batchLoadPersonIds(personIds)
  }

  visibleNodes() {
    const visibleWindow = this.calculateVisibleWindow(false)
    if (!visibleWindow) return []

    return this.svg
      .selectAll("g.node")
      .filter((d) => PositionDataLoader.visibleInWindow(d, visibleWindow))
  }

  static visibleInWindow(d: NodeData, visibleWindow: VisibleWindowCoordinates): boolean {
    return (
      d.x >= visibleWindow.x.min &&
      d.y >= visibleWindow.y.min &&
      d.x <= visibleWindow.x.max &&
      d.y <= visibleWindow.y.max
    )
  }

  calculateVisibleWindow(preload = true): VisibleWindowCoordinates | null {
    const svgElement = this.svg.node() as SVGSVGElement
    const svgHeight = parseInt(svgElement.getAttribute("height") ?? "0", 10)
    const svgWidth = parseInt(svgElement.getAttribute("width") ?? "0", 10)
    const transformGroup = this.svg.select("g[transform]").node() as SVGGElement
    const transformAttribute = transformGroup.getAttribute("transform") || ""
    const translateCoordinates = PositionDataLoader.parseTranslateCoordinates(transformAttribute)

    if (!translateCoordinates) {
      return null
    }

    const [translateX, translateY] = Array.from(translateCoordinates)
    const scale = PositionDataLoader.parseScale(transformAttribute)
    const preloadPercentage = preload ? 0.25 : 0

    return {
      x: {
        min: -(translateX / scale) - (svgWidth * preloadPercentage) / scale,
        max: svgWidth / scale - translateX / scale + (svgWidth * preloadPercentage) / scale,
      },
      y: {
        min: -(translateY / scale) - (svgHeight * preloadPercentage) / scale,
        max: svgHeight / scale - translateY / scale + (svgHeight * preloadPercentage) / scale,
      },
    }
  }

  // Extract translate x and y from a translate attribute value.
  // Coerces each to a whole number.
  static parseTranslateCoordinates(testString: string) {
    const match = testString.match(/translate\(([-\d.]+)(?:,|\s)([-\d.]+)\)/)

    if (!match) return null
    return map([match[1], match[2]], (value) => parseInt(value, 10))
  }

  static parseScale(testString: string) {
    const match = testString.match(/scale\((.+)\)/)
    if (!match) {
      return 1
    }
    return parseFloat(match[1])
  }

  loadAllWithCallback(callback: () => void) {
    const ids = this.nonLoadedPositionIds()

    const promises = this.batchLoadByIds(ids)
    if (!promises) {
      return callback()
    }
    return Promise.all(promises).then(() => callback())
  }

  batchLoadByIds(positionIds: number[]): Promise<unknown>[] | null {
    if (!(positionIds.length > 0)) {
      return null
    }

    const groups = []
    const batchSize = 50
    let i = 0
    const total = positionIds.length

    while (i < total) {
      groups.push(positionIds.slice(i, (i += batchSize)))
    }

    const promises: Promise<unknown>[] = []
    const dataLoader = this.resolveDataLoader()
    Array.from(groups).forEach((ids) => {
      promises.push(dataLoader.call(this, ids, this.chart))
    })

    return promises
  }

  resolveDataLoader(): DataLoaderFn {
    if (this.dataLoaderFn) {
      return this.dataLoaderFn
    }

    return this.loadByIds
  }

  batchLoadPersonIds(personIds: number[]): Promise<unknown>[] | null {
    if (!(personIds.length > 0)) {
      return null
    }

    const groups = []
    const batchSize = 50
    let i = 0
    const total = personIds.length

    while (i < total) {
      groups.push(personIds.slice(i, (i += batchSize)))
    }

    const promises: Promise<unknown>[] = []
    groups.forEach((ids) => {
      promises.push(this.loadPersonByIds(ids, this.chart))
    })

    return promises
  }

  loadByIds(nodeIds: number[], chart: OrgChart) {
    return new Promise((resolve, reject) => {
      if (!this.endpoint) {
        throw new Error("Org Chart - endpoint is required for loading json data")
      }
      const prefix = this.endpoint.indexOf("?") < 0 ? "?" : "&"
      const paramName = "ids[]="
      const param = prefix + paramName + nodeIds.join(`&${paramName}`)

      window.$.getJSON(this.endpoint + param)
        .done((nodeData: NodeData) => {
          const nodes = map(nodeData, (nodeDatum) => {
            const orgChartNode = chart.find(nodeDatum.id)
            if (!orgChartNode) {
              return null
            }

            // There is one case where we want to set the children count,
            // and that's for nodes that do not have a children count number
            // set. In 3-level mode, this can happen for reports of a 3rd level
            // node after their 3rd level parent is dragged from the 3rd level
            // to a higher level. Its children are currently being loaded in
            // without any children counts.
            if (!Number.isNaN(parseInt(orgChartNode.get("children_count"), 10))) {
              // eslint-disable-next-line no-param-reassign
              nodeDatum = omit(nodeDatum, "children_count")
            }

            orgChartNode.set({ ...nodeDatum, ...{ loaded: true, loading: false } })

            return orgChartNode
          })

          chart.trigger("positions-loaded")
          return resolve(nodes)
        })
        .fail(() => {
          reject()
          throw new Error(`Org Chart - Could not load json from: ${this.endpoint}`)
        })
    })
  }

  loadPersonByIds(personIds: number[], chart: OrgChart) {
    return new Promise((resolve, reject) => {
      const paramName = "ids[]="
      const param = `?${paramName}${personIds.join(`&${paramName}`)}`

      window.$.getJSON(`/api/app/v1/people/organize${param}`)
        .done((people: PersonData[]) => {
          const nodes = compact(
            map(people, (person) => {
              const orgChartNode = chart.findByPersonId(person.id)
              if (!orgChartNode) {
                return null
              }

              orgChartNode.set(omit(person, ["id"]))
              orgChartNode.set({ loaded: true, loading: false })
              return orgChartNode
            }),
          )

          nodes.forEach((node) => chart.renderNode(node, { withChildren: false }))
          chart.trigger("positions-loaded")
          return resolve(nodes)
        })
        .fail(() => {
          reject()
          throw new Error(`Org Chart - Could not load json from: ${this.endpoint}`)
        })
    })
  }

  nonLoadedPositionIds() {
    const allNodes = this.svg.selectAll("g.node").data()
    const nonLoaded = reject(allNodes, (node) => node.loaded === true || node.loading === true)
    return nonLoaded.map((d) => d.id).filter(Number)
  }
}

export default PositionDataLoader
export { DataLoaderFn }
