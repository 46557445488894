import React from "react"
import { useTranslation } from "react-i18next"

import { PeopleChangesOverTime } from "v2/react/components/headcountPlanning/Insights/PeopleChangesOverTime"
import { PlanVsActual } from "v2/react/components/headcountPlanning/Insights/PlanVsActual"
import { PositionForecastWidget } from "v2/react/components/headcountPlanning/Insights/PositionForecastWidget"
import { withRootProvider } from "v2/react/components/RootProvider"
import { Notice } from "v2/react/shared/status/Notice"

interface Props {
  headcountPlanId: string
  participantId?: string
  started: boolean
}

function Content({ headcountPlanId, participantId, started }: Props) {
  const { t } = useTranslation()

  return (
    <div className="page-pad flex-col flex">
      {!started && (
        <Notice icon={["far", "info-circle"]} type="info" wrapperClass="mb-8">
          <p>{t("v2.headcount_plan.insights.alert")}</p>
        </Notice>
      )}
      <PositionForecastWidget headcountPlanId={headcountPlanId} participantId={participantId} />
      <PlanVsActual headcountPlanId={headcountPlanId} participantId={participantId} />
      <PeopleChangesOverTime headcountPlanId={headcountPlanId} participantId={participantId} />
    </div>
  )
}

const Insights = withRootProvider(Content, "Insights")

export { Insights }
