import { CellContext } from "@tanstack/react-table"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { CareerLadder } from "types/graphql"
import { DeleteCareerLadderForm } from "v2/react/components/careerLadders/Index/DeleteCareerLadderForm"
import { EditCareerLadderModal } from "v2/react/components/careerLadders/Show/EditCareerLadderModal"
import RootProvider from "v2/react/components/RootProvider"
import { useClientTable } from "v2/react/hooks/useClientTable"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { Link } from "v2/react/shared/navigation/Links/Link"
import { MoreActionsCell } from "v2/react/shared/tables/Table/Cell/MoreActionsCell"
import { Table } from "v2/react/shared/tables/Table/Table"
import { UrlHelper } from "v2/react/utils/urls"
import { useCareerLaddersConnectionQuery } from "v2/redux/GraphqlApi/CareerLaddersApi"

function WithProvider() {
  const { t } = useTranslation()
  const { data, isLoading } = useCareerLaddersConnectionQuery({})
  const [activeCareerLadder, setActiveCareerLadder] = useState<CareerLadder | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const handleOpenEditModal = (careerLadder: CareerLadder) => {
    setActiveCareerLadder(careerLadder)
    setEditModalOpen(true)
  }

  const onEditModalClose = () => {
    setEditModalOpen(false)
    setActiveCareerLadder(null)
  }

  const handleOpenDeleteModal = (careerLadder: CareerLadder) => {
    setActiveCareerLadder(careerLadder)
    setDeleteModalOpen(true)
  }

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false)
    setActiveCareerLadder(null)
  }

  const rows = data?.collection || []

  const moreActions = ({ row }: CellContext<CareerLadder, unknown>) => (
    <MoreActionsCell<CareerLadder>
      activeItem={row.original}
      openDeleteModal={handleOpenDeleteModal}
      openEditModal={handleOpenEditModal}
      viewActiveItem={(careerLadder: CareerLadder) =>
        UrlHelper.careerLadderDetailsPath(careerLadder.id)
      }
    />
  )

  const table = useClientTable({
    columns: [
      {
        accessorKey: "actions",
        header: "",
        enableSorting: false,
        cell: moreActions,
      },
      { accessorKey: "code", header: t("v2.career_ladders.column_headers.code"), cell: LinkedRow },
      { accessorKey: "name", header: t("v2.career_ladders.column_headers.name"), cell: LinkedRow },
      {
        accessorKey: "description",
        header: t("v2.career_ladders.column_headers.description"),
        cell: WrapRow,
      },
      {
        accessorKey: "numJobDefinitions",
        header: t("v2.career_ladders.column_headers.num_job_definitions"),
      },
    ],
    data: rows,
  })

  return (
    <>
      <div className="page-pad">
        {isLoading ? (
          <Spinner />
        ) : (
          <Table
            table={table}
            footerContent={t("v2.career_ladders.index.found", {
              count: data?.metadata?.totalCount || 0,
            })}
          />
        )}
      </div>
      {activeCareerLadder && (
        <EditCareerLadderModal
          careerLadder={activeCareerLadder}
          isOpen={editModalOpen}
          onClose={onEditModalClose}
        />
      )}
      {activeCareerLadder && (
        <DeleteCareerLadderForm
          careerLadder={activeCareerLadder}
          isOpen={deleteModalOpen}
          onClose={onDeleteModalClose}
          title={t("v2.career_ladders.index.delete_career_ladder", {
            code: activeCareerLadder.code,
            name: activeCareerLadder.name ? ` - ${activeCareerLadder.name}` : "",
          })}
        />
      )}
    </>
  )
}
const Index = () => (
  <RootProvider>
    <WithProvider />
  </RootProvider>
)

export { Index }

const LinkedRow = ({ cell }: CellContext<CareerLadder, unknown>) => (
  <Link to={UrlHelper.careerLadderDetailsPath(cell.row.original.id)}>
    {cell.getValue() as string}
  </Link>
)

const WrapRow = ({ cell }: CellContext<CareerLadder, unknown>) => (
  <div className="min-w-[400px] whitespace-normal">{cell.getValue() as string}</div>
)
