import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import dayjs from "dayjs"
import { TFunction } from "i18next"
import { sumBy } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"

import { LoadingWrapper } from "v2/react/shared/loaders/LoadingWrapper"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { Info } from "v2/react/shared/status/Info"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetPositionForecastWidgetQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { useAppSelector } from "v2/redux/store"

interface PositionForecastWidgetProps {
  headcountPlanId: string
  participantId?: string
}

function PositionForecastWidget({ headcountPlanId, participantId }: PositionForecastWidgetProps) {
  const { t } = useTranslation()
  const featureFlags = useAppSelector((state) => state.session.featureFlags)
  const {
    data: forecastData,
    isLoading,
    isFetching,
    isError,
  } = useGetPositionForecastWidgetQuery({ headcountPlanId, participantId })

  const headcountPlan = forecastData?.headcountPlan
  const isFinalized = !!headcountPlan?.lockedAt
  const startDateOccurred = dayjs(headcountPlan?.startDate).isBefore(dayjs())
  const hasPositionManagement = !!featureFlags?.positionManagement

  const aggregations = headcountPlan?.positionForecastAggregations

  const totalPlannedPositions = aggregations?.totalPlannedPositionsCount || 0
  const showData = isFinalized && startDateOccurred
  const isActive = headcountPlan?.activeStatus === "active"

  const data: DataPoint[] = [
    {
      backgroundColor: "bg-gradient-primary-100-to-b",
      count: aggregations?.actualPositionsCount || 0,
      id: "actual_positions",
      showCount: showData,
    },
    {
      backgroundColor: "bg-gradient-primary-50-to-b",
      count: aggregations?.requisitionsApprovedCount || 0,
      id: "requisitions_approved",
      showCount: showData && hasPositionManagement && isActive,
      tooltip: !hasPositionManagement
        ? t("v2.headcount_plan.insights.position_forecast.requisition_tooltip")
        : undefined,
    },
    {
      backgroundColor: "bg-gradient-sunburst-50-to-b",
      count: aggregations?.requisitionsPendingCount || 0,
      id: "requisitions_pending",
      showCount: showData && hasPositionManagement && isActive,
      tooltip: !hasPositionManagement
        ? t("v2.headcount_plan.insights.position_forecast.requisition_tooltip")
        : undefined,
    },
    {
      backgroundColor: "bg-gradient-primary-8-to-b",
      count: aggregations?.plannedNewPositionsCount || 0,
      id: "new_positions",
      showCount: showData,
    },
  ]
  const positionCount = data.find((dataPoint) => dataPoint.id === "actual_positions")?.count || 0
  const fetchingData = isLoading || isFetching

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="items-center flex">
          <p className="font-bold">{t("v2.headcount_plan.insights.position_forecast.title")}</p>
          <Info description={t("v2.headcount_plan.insights.position_forecast.header_tooltip")} />
        </div>
        {hasPositionManagement && (
          <a className="btn--secondary btn--sm" href={UrlHelper.jobRequisitionsRootPath()}>
            {t("v2.headcount_plan.insights.position_forecast.view_requisitions")}
            <FontAwesomeIcon icon={["far", "chevron-right"]} />
          </a>
        )}
      </div>
      <LoadingWrapper
        isLoading={fetchingData}
        isError={isError}
        loaderClass="module-card__body"
        wrapperClass="module-card__body p-6"
        animateHeight
        // Note: 129px is the height of the widget in the designs without any
        // banners and at a large-ish breakpoint.
        initialHeight={129}
      >
        {positionCount > totalPlannedPositions && showData && (
          <div className="alert alert-critical items-center flex">
            <FontAwesomeIcon icon={["far", "info-circle"]} />
            <p>
              {t("v2.headcount_plan.insights.position_forecast.alert_exceeded_position_count", {
                count: positionCount - totalPlannedPositions,
              })}
            </p>
          </div>
        )}
        {!isActive && showData && (
          <div className="alert alert-critical items-center flex">
            <FontAwesomeIcon icon={["far", "info-circle"]} />
            <p>{t("v2.headcount_plan.insights.position_forecast.alert_req_data")}</p>
          </div>
        )}

        <div className="items-center gap-2 flex">
          <p className="text-[1.5rem]">{showData ? totalPlannedPositions : "–"}</p>
          <p>{t("v2.headcount_plan.insights.position_forecast.total_planned_positions")}</p>
        </div>
        {showData ? (
          <PercentageBar
            data={data.filter((dataPoint) => dataPoint.showCount)}
            plannedPositions={totalPlannedPositions}
            showOverage={positionCount > totalPlannedPositions && showData}
          />
        ) : (
          <div className="elevation bg-gradient-primary-8-to-b h-3 w-full rounded-xl" />
        )}

        <div className="items-center gap-8 flex">
          {data.map((dataPoint) =>
            dataPoint.tooltip ? (
              <Tooltip key={dataPoint.id}>
                <TooltipTrigger>
                  <LegendItem
                    backgroundColor={dataPoint.backgroundColor}
                    count={dataPoint.count}
                    showCounts={dataPoint.showCount}
                    showOverage={
                      positionCount > totalPlannedPositions &&
                      showData &&
                      dataPoint.id === "actual_positions"
                    }
                    property={dataPoint.id}
                    t={t}
                  />
                </TooltipTrigger>
                <TooltipContent className="react-tooltip-content">
                  {dataPoint.tooltip}
                </TooltipContent>
              </Tooltip>
            ) : (
              <LegendItem
                key={dataPoint.id}
                backgroundColor={dataPoint.backgroundColor}
                count={dataPoint.count}
                showCounts={dataPoint.showCount}
                showOverage={
                  positionCount > totalPlannedPositions &&
                  showData &&
                  dataPoint.id === "actual_positions"
                }
                property={dataPoint.id}
                t={t}
              />
            ),
          )}
        </div>
      </LoadingWrapper>
    </div>
  )
}

export { PositionForecastWidget }

interface LegendItemProps {
  backgroundColor: string
  count: number
  showCounts: boolean
  showOverage?: boolean
  property: string
  t: TFunction
}

const LegendItem = ({
  backgroundColor,
  count,
  showCounts,
  showOverage,
  t,
  property,
}: LegendItemProps) => (
  <div className="items-center gap-2 flex">
    <div className="items-center flex">
      <div
        className={cn(
          "h-2.5 w-2.5 shrink-0 rounded-full border border-solid border-neutral-8",
          backgroundColor,
          { "rounded-r-none": showOverage },
        )}
      />
      {showOverage && (
        <div
          className={cn(
            "h-2.5 w-2.5 shrink-0 rounded-r-full border border-solid border-neutral-8 bg-status-critical",
          )}
        />
      )}
    </div>
    <p>
      <span className="font-bold">{showCounts ? count : "–"} </span>
      {t(`v2.headcount_plan.insights.position_forecast.${property}`, {
        count,
      })}
    </p>
  </div>
)

type DataPoint = {
  backgroundColor: string
  count: number
  id: string
  showCount: boolean
  tooltip?: string
}

interface PercentageBarProps {
  data: DataPoint[]
  plannedPositions: number
  showOverage: boolean
}

const PercentageBar = ({ data, plannedPositions, showOverage }: PercentageBarProps) => {
  const totalCount = sumBy(data, "count")
  const getPercentage = (value: number) => Math.round((value / totalCount) * 100)

  return (
    <div className="w-full flex">
      {data.map((dataPoint, index) =>
        showOverage && dataPoint.id === "actual_positions" ? (
          <React.Fragment key={dataPoint.id}>
            <div
              className={cn("elevation h-3 rounded-l-xl", dataPoint.backgroundColor)}
              style={{
                width: `${getPercentage(plannedPositions)}%`,
                minWidth: dataPoint.count > 0 ? "12px" : 0,
              }}
            />
            <div className="relative">
              <div className="absolute -bottom-1.5 -translate-x-1/2 flex-col items-center flex">
                <p className="text-neutral-64">{plannedPositions}</p>
                <div className="h-6 w-0.5 bg-neutral-8" />
              </div>
            </div>
            <div
              className={cn("h-3 min-w-[12px] rounded-r-xl bg-status-critical")}
              style={{
                width: `${getPercentage(dataPoint.count - plannedPositions)}%`,
                minWidth: dataPoint.count > 0 ? "12px" : 0,
              }}
            />
          </React.Fragment>
        ) : (
          <div
            key={dataPoint.id}
            className={cn("elevation h-3 rounded-xl", dataPoint.backgroundColor, {
              "ml-0.5": index !== 0,
            })}
            style={{
              width: `${getPercentage(dataPoint.count)}%`,
              minWidth: dataPoint.count > 0 ? "12px" : 0,
            }}
          />
        ),
      )}
    </div>
  )
}
