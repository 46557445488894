import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import {
  SuccessionMatrixSettings,
  UpdateCompanySuccessionMatrixSettingsInputObject,
} from "types/graphql.d"
import { Matrix } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/Matrix"
import { ColorStyleIcon } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/Modal/ColorStyleIcon"
import { GridItemEditable } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/Modal/GridItemEditable"
import { MatrixIcon } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/Modal/MatrixIcon"
import { SelectDropdown } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/Modal/SelectDropdown"
import { basePalettes } from "v2/react/components/successionSettings/palettes"
import { InputErrorBanner } from "v2/react/shared/forms/InputErrorBanner"
import { Modal } from "v2/react/shared/overlay/Modal"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"
import { useSaveSuccessionMatrixSettingsMutation } from "v2/redux/GraphqlApi"
import {
  AxisModeType,
  CardType,
  CardTypeSchema,
  GridItem,
  PaletteSchema,
  PaletteType,
  setAxisMode,
  setCardType,
  setGridItems,
  setPalette,
  updateGridItemColor,
  updateGridItemLabel,
  updatePalette,
} from "v2/redux/slices/MatrixSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface SelectOption {
  headerDisplay: React.ReactNode
  fieldDisplay: React.ReactNode
  text: CardType
}
interface PaletteSelection {
  headerDisplay: React.ReactNode
  fieldDisplay: React.ReactNode
  palette: string[]
  text: PaletteType
}

interface Props {
  isOpen: boolean
  onClose: () => void
  matrix: SuccessionMatrixSettings
}

function SuccessionMatrixModal({ isOpen, onClose, matrix }: Props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [saveMatrixSettings, { isLoading }] = useSaveSuccessionMatrixSettingsMutation()
  const [errors, setErrors] = useState<string[]>([])
  const labelErrors = useAppSelector((state) => state.matrix.labelErrors)
  const cardOptions = useAppSelector((state) => state.matrix.cardOptions)
  const viewablePaletteOptions = useAppSelector((state) => state.matrix.viewablePaletteOptions)

  const gridItems = useAppSelector((state) => state.matrix.gridItems)
  const chosenCardStyle: CardType = useAppSelector((state) => state.matrix.cardType)
  const chosenPaletteStyle: PaletteType = useAppSelector((state) => state.matrix.paletteType)
  const chosenAxisMode: AxisModeType = useAppSelector((state) => state.matrix.axisMode)

  const changeCardStyle = (selected: SelectOption) => dispatch(setCardType(selected.text))

  const changePaletteStyle = (selected: PaletteSelection) => {
    if (selected.text === chosenPaletteStyle) return
    dispatch(updatePalette(selected.text))
  }

  const changeGridItemColor = (gridItem: GridItem, newColor: string) =>
    dispatch(updateGridItemColor({ gridItem, newColor }))

  const changeGridItemLabel = (gridItem: GridItem, newLabel: string) =>
    dispatch(updateGridItemLabel({ gridItem, newLabel }))

  const handleClose = () => {
    const initGridItems: GridItem[] = matrix.gridItems
    const initPaletteType: PaletteType = PaletteSchema.parse(matrix.paletteType)
    const initCardType: CardType = CardTypeSchema.parse(matrix.cardType)
    setErrors([])

    dispatch(setGridItems(initGridItems))
    dispatch(setPalette(initPaletteType))
    dispatch(setCardType(initCardType))
    onClose()
  }

  const handleSave = async () => {
    if (labelErrors) {
      setErrors([t("v2.succession_modal.label_required")])
      return
    }
    setErrors([])

    const settings: UpdateCompanySuccessionMatrixSettingsInputObject = {
      cardType: chosenCardStyle,
      paletteType: chosenPaletteStyle,
      axisMode: chosenAxisMode,
      gridItems,
    }

    const result = await saveMatrixSettings({ input: { settings } }).unwrap()
    if (!result.updateCompanySuccessionMatrixSettings?.errors.length) {
      onClose()
    } else {
      const errors = (result.updateCompanySuccessionMatrixSettings?.errors || []).map(
        (s: Error) => s.message,
      )
      setErrors(errors)
    }
  }

  const dropdownPaletteOptions =
    chosenPaletteStyle === "palette_custom"
      ? [...viewablePaletteOptions, chosenPaletteStyle]
      : viewablePaletteOptions

  const invertAxisMode = () => (chosenAxisMode === "performance" ? "potential" : "performance")

  const generatePaletteOption = (option: PaletteType, gridItems: GridItem[]): PaletteSelection => {
    const palette = basePalettes[option].length
      ? basePalettes[option]
      : gridItems.map((item) => item.color)

    return {
      palette,
      text: option,
      fieldDisplay: <MatrixIcon addMatrixBorder palette={palette} text={option} />,
      headerDisplay: <MatrixIcon addMatrixBorder={false} palette={palette} text={option} />,
    }
  }

  const generateCardTypeOption = (option: CardType) => ({
    text: option,
    fieldDisplay: <ColorStyleIcon option={option} />,
    headerDisplay: <ColorStyleIcon option={option} />,
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      title={t("v2.succession_modal.matrix_settings")}
    >
      <div className="matrix-settings p-6">
        <AnimatePresence>
          {errors.length > 0 && (
            <motion.div
              key="error-banner"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: {
                  opacity: 1,
                  height: "auto",
                  transition: { duration: 0.2, opacity: { delay: 0.1 } },
                },
                collapsed: {
                  opacity: 0,
                  height: 0,
                  transition: { duration: 0.2, height: { delay: 0.1 } },
                },
              }}
            >
              <InputErrorBanner errors={errors} />
            </motion.div>
          )}
        </AnimatePresence>
        <div className="mb-2 grid-cols-2 items-center gap-2 grid">
          <div className="mt-4 items-center space-x-4 flex">
            <div className="mb-4 place-content-center grid">
              <p className="mb-2 text-left font-bold text-neutral-100">
                {t("v2.succession_modal.x_axis")}
              </p>
              <div className="border--main min-w-[108px] rounded-lg bg-neutral-3 p-[0.6rem]">
                <p className="w-full text-left text-neutral-64">
                  {t(`v2.succession_plan_panel.matrix.${chosenAxisMode}`)}
                </p>
              </div>
            </div>
            <button
              type="button"
              className="btn--large btn--secondary mt-3 max-h-[39px] max-w-[40px]"
              onClick={() => dispatch(setAxisMode(invertAxisMode()))}
            >
              <FontAwesomeIcon
                className="cursor-pointer text-neutral-80"
                icon={["far", "arrow-right-arrow-left"]}
              />
            </button>
            <div className="mb-4 grid">
              <p className="mb-2 text-left font-bold text-neutral-100">
                {t("v2.succession_modal.y_axis")}
              </p>
              <div className="border--main min-w-[108px] rounded-lg bg-neutral-3 p-[0.6rem]">
                <p className="w-full text-left text-neutral-64">
                  {chosenAxisMode === "performance"
                    ? t("v2.succession_plan_panel.matrix.potential")
                    : t("v2.succession_plan_panel.matrix.performance")}
                </p>
              </div>
            </div>
          </div>
          <div className="matrix-settings__fields flex">
            <div className="field grid">
              <p className="mb-2 font-bold text-neutral-100">
                {t("v2.succession_modal.color_style")}
              </p>
              <SelectDropdown
                options={cardOptions.map(generateCardTypeOption)}
                initialOption={generateCardTypeOption(chosenCardStyle)}
                onSelection={changeCardStyle}
              />
            </div>
            <div className="field w-full grid">
              <p className="mb-2 font-bold text-neutral-100">
                {t("v2.succession_modal.color_scheme")}
              </p>
              <SelectDropdown
                disabled={chosenCardStyle === "color_none"}
                dropdownClassNames="w-[20.5rem]"
                options={dropdownPaletteOptions.map((option) =>
                  generatePaletteOption(option, gridItems),
                )}
                initialOption={generatePaletteOption(chosenPaletteStyle, gridItems)}
                onSelection={changePaletteStyle}
                triggerClassNames="w-full"
              />
            </div>
          </div>
        </div>
        <Matrix axisMode={chosenAxisMode}>
          {gridItems.map((box) => (
            <GridItemEditable
              key={box.id}
              item={box}
              cardStyle={chosenCardStyle}
              onUpdateItemLabel={changeGridItemLabel}
              onUpdateItemColor={changeGridItemColor}
            />
          ))}
        </Matrix>
      </div>
      <SaveFooter isSaving={isLoading} onSave={handleSave} onCancel={handleClose} />
    </Modal>
  )
}

export { SuccessionMatrixModal }
