import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { Notice } from "v2/react/shared/status/Notice"

interface ErrorNoticeProps {
  wrapperClassName?: string
  message?: string
}

const ErrorNotice = ({ wrapperClassName, message }: ErrorNoticeProps) => {
  const { t } = useTranslation()

  return (
    <div className={cn(wrapperClassName)}>
      <Notice type="critical" icon={["far", "triangle-exclamation"]}>
        {message ?? t("v2.defaults.error")}
      </Notice>
    </div>
  )
}

export { ErrorNotice }
