import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import RootProvider from "v2/react/components/RootProvider"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"

interface Props {
  canCreateChart: boolean
  chartId: number
  type: "button" | "menu-link"
  disabled?: boolean
}

function DuplicateButton({ canCreateChart, chartId, type, disabled = false }: Props) {
  const { t } = useTranslation()

  const duplicateChart = (e: React.MouseEvent) => {
    e.preventDefault()
    pjaxModalFor(UrlHelper.chartDuplicatePath(chartId))
  }

  if (!canCreateChart) return null

  return (
    <RootProvider>
      <button
        className={cn("duplicate", {
          "btn--large btn--secondary w-10": type === "button",
          "tooltip tooltip-right": type === "button" && !disabled,
          "dropdown-menu-link": type === "menu-link",
        })}
        onClick={(e) => duplicateChart(e)}
        type="button"
        disabled={disabled}
      >
        <i className="far fa-clone" />
        <span
          className={cn({
            "tooltip-content tooltip-content--sm": type === "button",
            "ml-2": type === "menu-link",
          })}
        >
          {t("v2.orgchart.pagenav.duplicate")}
        </span>
      </button>
    </RootProvider>
  )
}

export { DuplicateButton }
