import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

interface ConfirmProps {
  isApprovalPending: boolean
  isHeadcountPlanner: boolean
  isOfficialChart: boolean
  togglePermanentlyDelete: () => void
}

const Confirm: React.FC<ConfirmProps> = ({
  isApprovalPending,
  isHeadcountPlanner,
  isOfficialChart,
  togglePermanentlyDelete,
}) => {
  const { t } = useTranslation()

  return (
    <div className="p-6">
      {isApprovalPending && (
        <div className="alert-critical mb-2 gap-2 flex">
          <FontAwesomeIcon className="mt-1" icon={["far", "circle-exclamation"]} />
          <div>
            <p className="font-bold">{t("v2.profile_panel.in_approval_heading")}</p>
            <p>{t("v2.profile_panel.in_approval_text", "profile_panel")}</p>
          </div>
        </div>
      )}

      {isHeadcountPlanner ? (
        <>
          <input type="hidden" id="headcount-planner" name="isHeadcountPlanner" value="true" />
          <div className="alert-critical mb-4 gap-2 flex">
            <FontAwesomeIcon className="mt-1" icon={["far", "circle-exclamation"]} />
            <p id="remove-warning">{t("v2.profile_panel.headcount_remove_error")}</p>
          </div>
        </>
      ) : (
        <input type="hidden" id="headcount-planner" name="isHeadcountPlanner" value="false" />
      )}

      {isOfficialChart ? (
        <>
          <p className="mb-4">{t("v2.profile_panel.remove_are_you_sure")}</p>
          <p className="mb-4">{t("v2.profile_panel.for_position_management_customers")}</p>
          <div className="input-group">
            <label className="checkbox">
              <input
                type="checkbox"
                name="permanently_delete"
                onClick={() => togglePermanentlyDelete()}
              />
              {t("v2.profile_panel.permanently_delete")}{" "}
              {t("v2.profile_panel.remove_cannot_be_reversed")}
            </label>
          </div>
        </>
      ) : (
        <>
          <input className="hidden" name="permanently_delete" type="checkbox" />
          <p className="mb-4">
            {t("v2.profile_panel.remove_are_you_sure")}{" "}
            {t("v2.profile_panel.remove_cannot_be_reversed")}
          </p>
        </>
      )}
    </div>
  )
}

export { Confirm }
