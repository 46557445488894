import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseDate } from "@internationalized/date"
import cn from "classnames"
import dayjs from "dayjs"
import eliminateAllPositions from "images/eliminate-all-positions.png"
import movePositionsUp from "images/move-positions-up.png"
import fp from "lodash/fp"
import React, { useState } from "react"
import AnimateHeight from "react-animate-height"
import { DateValue } from "react-aria"
import { useTranslation } from "react-i18next"

import { EliminateHeadcountPlanPositionInput, Error } from "types/graphql"
import {
  FormEffectiveDate,
  getEffectiveDate,
  getEffectiveDatesList,
} from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/effectiveDates/helpers"
import { HeadcountPlanDatasheetRow } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/types"
import { FieldType } from "v2/react/components/headcountPlanning/TableDatasheet/types"
import { RemovePositionOptionCard } from "v2/react/components/positions/shared/RemovePositionOptionCard"
import { DatePicker } from "v2/react/shared/forms/DateInputs/DatePicker"
import { FormStyleProvider } from "v2/react/shared/forms/FormStyleProvider"
import { Modal, SaveFooter } from "v2/react/shared/overlay/Modal"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import {
  useAddChangeToHeadcountPlanMutation,
  useEliminateHeadcountPlanPositionMutation,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import {
  setFocusPositionEndDate,
  setRowForEffectiveDateModal,
  transitionTableCursor,
  updateCellState,
} from "v2/redux/slices/DatasheetSlice"
import { CursorState } from "v2/redux/slices/DatasheetSlice/cursor/types"
import { CellEvent } from "v2/redux/slices/DatasheetSlice/types"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface EffectiveDateModalProps {
  endDate: string
  headcountPlanId: string
  onModalCancel: () => void
  onModalSave: () => void
  row: HeadcountPlanDatasheetRow
  startDate: string
}

function EffectiveDateModal({
  endDate,
  headcountPlanId,
  onModalCancel,
  onModalSave,
  row,
  startDate,
}: EffectiveDateModalProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [saveEffectiveDates, { isLoading: isLoadingEffectiveDates }] =
    useAddChangeToHeadcountPlanMutation()
  const [saveEffectiveDatesWithEliminateInfo, { isLoading: isLoadingWithEliminate }] =
    useEliminateHeadcountPlanPositionMutation()
  const [errors, setErrors] = useState<Error[] | undefined | null>([])
  const [eliminateDescendingPositions, setEliminateDescendingPositions] = React.useState(false)
  const [showEliminatePositionSection, setShowEliminatePositionSection] = useState(false)
  const [effectiveDates, setEffectiveDates] = useState<FormEffectiveDate[]>(
    getEffectiveDatesList(row.positionAttributesWithEdits),
  )

  const everyChildHasEndDate = row.children?.every((child) => child.endsOn)

  const effectiveDateIsDisabled = (effectiveDate: FormEffectiveDate) => {
    if (row.type === "new" && effectiveDate.id === "position_end_date") {
      return !everyChildHasEndDate
    }
    return Boolean(effectiveDate.isValueFromActual)
  }

  const effectiveDateTooltipContent = (effectiveDate: FormEffectiveDate) => {
    if (row.type === "new" && effectiveDate.id === "position_end_date" && !everyChildHasEndDate) {
      return t("v2.headcount_plan.errors.direct_reports_must_have_end_date")
    }
    return null
  }

  const cellDispatch = React.useCallback(
    (event: Omit<CellEvent, "rowId" | "columnId">) => {
      dispatch(updateCellState({ rowId: row.id, columnId: "effective_dates", ...event }))
    },
    [dispatch, row],
  )

  const filterByOptional = (isOptional: boolean) =>
    effectiveDates.filter((ed) => ed.optional === isOptional)
  const distinctDates = fp.pipe(
    fp.map(({ value }: FormEffectiveDate) => value),
    fp.uniq,
  )(filterByOptional(false))

  const handleAllDateChanges = (value: DateValue) => {
    const updatedDates = effectiveDates.map((ed) =>
      ed.optional ? ed : { ...ed, value: value ? value?.toString() : null },
    )
    setEffectiveDates(updatedDates)
  }

  const handleDateChange = (id: string, value: DateValue) => {
    const updatedDates = effectiveDates.map((ed) =>
      ed.id === id ? { ...ed, value: value ? value?.toString() : null } : ed,
    )
    determineEliminatePositionSectionVisibility(updatedDates)
    setEffectiveDates(updatedDates)
  }

  const handleClose = () => {
    setErrors([])
    setShowEliminatePositionSection(false)
    dispatch(setRowForEffectiveDateModal(null))
    dispatch(setFocusPositionEndDate(false))

    // Sets focus back on the applicable cell
    dispatch(
      transitionTableCursor({
        rowId: row.id,
        columnId: "effective_dates",
        editable: true,
        state: CursorState.OnEditable,
        fieldType: FieldType.EffectiveDate,
        enteredBy: "placement",
        currentValue: null,
      }),
    )
  }

  const handleCancel = () => {
    onModalCancel()
    handleClose()
  }

  const handleSave = async () => {
    cellDispatch({ type: "save", value: effectiveDates })
    const effectiveDatesHash = fp.indexBy("id", effectiveDates)

    if (positionEndDateEdited(effectiveDates) && effectiveDatesHash.position_end_date?.value) {
      const input: EliminateHeadcountPlanPositionInput = {
        eliminateDescendingPositions,
        headcountPlanId,
        headcountPlanParticipantId: row.participantId,
        payload: { effective_dates: effectiveDatesHash },
        positionId: row.action === null ? row.position?.id : null,
        revisionNumber: row.action === null ? 0 : row.revisionNumber + 1,
        rootEventId: row.rootEventId,
      }

      const result = await saveEffectiveDatesWithEliminateInfo(input).unwrap()

      if (result.eliminateHeadcountPlanPosition?.success) {
        cellDispatch({ type: "saveSuccess" })
        setTimeout(() => cellDispatch({ type: "reset" }), 800)
        onModalSave()
        handleClose()
      } else {
        setErrors(result.eliminateHeadcountPlanPosition.errors)
      }
    } else {
      const input = {
        action: "update_existing",
        headcountPlanId,
        headcountPlanParticipantId: row.participantId,
        payload: { ...row.payload, effective_dates: effectiveDatesHash },
        positionId: row.rootEventId ? null : row.position?.id,
        revisionNumber: row.revisionNumber + 1,
        rootEventId: row.rootEventId,
      }

      const result = await saveEffectiveDates(input).unwrap()

      if (result.addChangeToHeadcountPlan.headcountPlanChangeCreated) {
        cellDispatch({ type: "saveSuccess" })
        setTimeout(() => cellDispatch({ type: "reset" }), 800)
        onModalSave()
        handleClose()
      } else if (
        result.addChangeToHeadcountPlan.errors &&
        result.addChangeToHeadcountPlan.errors.length > 0
      ) {
        setErrors(result.addChangeToHeadcountPlan.errors)
      }
    }
  }

  const determineEliminatePositionSectionVisibility = (updatedDates: FormEffectiveDate[]) => {
    const previousEndDate = getEffectiveDate(row?.positionAttributesWithEdits, "position_end_date")
    const currentEndDate = updatedDates.find((ed) => ed.id === "position_end_date")?.value

    setShowEliminatePositionSection(
      Boolean(
        positionEndDateEdited(updatedDates) &&
          currentEndDate &&
          !previousEndDate &&
          hasDirectReportsForDate(row, currentEndDate),
      ),
    )
  }

  const positionEndDateEdited = (updatedDates: FormEffectiveDate[]) => {
    const previousEndDate = getEffectiveDate(row.positionAttributesWithEdits, "position_end_date")
    const currentEndDate = updatedDates.find((ed) => ed.id === "position_end_date")?.value

    return currentEndDate !== previousEndDate
  }

  const retrieveErrorFor = (path: string) => {
    const error = errors?.find((element) => element?.details && element.details.date_id === path)
    if (error) return error.message
    return ""
  }

  return (
    <Modal
      isOpen
      onClose={handleCancel}
      size="md"
      title={t("v2.headcount_plan.effective_date_modal.heading")}
    >
      <div className="react-modal__body flex-col gap-6 flex">
        <FormStyleProvider defaultErrorIconPlacement="top">
          <div className="alert--gray items-center gap-1.5 flex">
            <FontAwesomeIcon icon={["far", "info-circle"]} />
            <p className="text-neutral-80">
              {t("v2.headcount_plan.effective_date_modal.plan_timespan")}:{" "}
              {dayjs(startDate).format("MMM D, YYYY")} - {dayjs(endDate).format("MMM D, YYYY")}
            </p>
          </div>
          {row.type === "new" ? (
            <div className="module-card !mb-0">
              <div className="module-card__body !border-t-0">
                {filterByOptional(false).map((d) => (
                  <EffectiveDateListItem
                    key={d.id}
                    effectiveDate={d}
                    errorMessage={retrieveErrorFor(d.id) || ""}
                    handleChange={handleDateChange}
                    disabled={effectiveDateIsDisabled(d)}
                    tooltipContent={effectiveDateTooltipContent(d)}
                  />
                ))}
                {filterByOptional(true).map((d) => (
                  <EffectiveDateListItem
                    key={d.id}
                    effectiveDate={d}
                    errorMessage={retrieveErrorFor(d.id) || ""}
                    handleChange={handleDateChange}
                    disabled={effectiveDateIsDisabled(d)}
                    tooltipContent={effectiveDateTooltipContent(d)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <>
              {filterByOptional(false).length > 0 && (
                <div className="module-card !mb-4">
                  {filterByOptional(false).length > 1 && (
                    <div className="module-card__header">
                      <p className="text-base-bold">
                        {t("v2.headcount_plan.effective_date_modal.set_all")}
                      </p>
                      <DatePicker
                        id="effective_date_all"
                        name="effective_date"
                        onChange={handleAllDateChanges}
                        showPlaceholderOnFocus
                        withIcon
                        wrapperClassName="border-none bg-transparent p-0 w-[9rem]"
                        value={
                          distinctDates.length === 1 && distinctDates[0]
                            ? parseDate(distinctDates[0])
                            : null
                        }
                      />
                    </div>
                  )}
                  <div
                    className={cn("module-card__body", {
                      "border-t-0": filterByOptional(false).length === 1,
                    })}
                  >
                    {filterByOptional(false).map((d) => (
                      <EffectiveDateListItem
                        key={d.id}
                        effectiveDate={d}
                        errorMessage={retrieveErrorFor(d.id) || ""}
                        handleChange={handleDateChange}
                        disabled={effectiveDateIsDisabled(d)}
                        tooltipContent={effectiveDateTooltipContent(d)}
                      />
                    ))}
                  </div>
                </div>
              )}
              {filterByOptional(true).length > 0 && (
                <div className="module-card !mb-0">
                  <div className="module-card__body !border-t-0">
                    {filterByOptional(true).map((d) => (
                      <EffectiveDateListItem
                        key={d.id}
                        effectiveDate={d}
                        errorMessage={retrieveErrorFor(d.id) || ""}
                        handleChange={handleDateChange}
                        disabled={effectiveDateIsDisabled(d)}
                        tooltipContent={effectiveDateTooltipContent(d)}
                      />
                    ))}
                    <AnimateHeight
                      duration={400}
                      height={showEliminatePositionSection ? "auto" : 0}
                    >
                      <div className="module-card">
                        <div className="module-card__body !border-t-0">
                          <p className="text-base-bold">
                            {t(
                              "v2.headcount_plan.eliminate_position_modal.eliminate_what_should_happen",
                            )}
                          </p>
                          <div className="gap-4 flex">
                            <RemovePositionOptionCard
                              imageUrl={movePositionsUp}
                              isSelected={!eliminateDescendingPositions}
                              handleSelection={() => setEliminateDescendingPositions(false)}
                              message={t(
                                "v2.headcount_plan.eliminate_position_modal.eliminate_move",
                              )}
                            />
                            <RemovePositionOptionCard
                              imageUrl={eliminateAllPositions}
                              isSelected={eliminateDescendingPositions}
                              handleSelection={() => setEliminateDescendingPositions(true)}
                              message={t(
                                "v2.headcount_plan.eliminate_position_modal.eliminate_all",
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </AnimateHeight>
                  </div>
                </div>
              )}
            </>
          )}
        </FormStyleProvider>
      </div>
      <SaveFooter
        isSaving={isLoadingEffectiveDates || isLoadingWithEliminate}
        enabledText={t("v2.defaults.save")}
        disabledText={t("v2.defaults.saving")}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    </Modal>
  )
}

export { EffectiveDateModal }

interface EffectiveDateListItemProps {
  effectiveDate: FormEffectiveDate
  errorMessage: string
  disabled?: boolean
  tooltipContent?: React.HTMLProps<HTMLDivElement>["children"]
  handleChange: (id: string, value: DateValue) => void
}

const EffectiveDateListItem = ({
  effectiveDate,
  errorMessage,
  disabled,
  tooltipContent,
  handleChange,
}: EffectiveDateListItemProps) => {
  const { t } = useTranslation()
  const focusPositionEndDate = useAppSelector((state) => state.datasheet.focusPositionEndDate)
  const updateEffectiveDate = (value: DateValue) => handleChange(effectiveDate.id, value)

  const datePicker = (
    <DatePicker
      errorMessage={errorMessage}
      autoFocus={effectiveDate.id === "position_end_date" && focusPositionEndDate}
      id={effectiveDate.id}
      includeClearFooter={effectiveDate.optional}
      isDisabled={disabled}
      name={effectiveDate.id}
      onChange={updateEffectiveDate}
      showPlaceholderOnFocus
      testId={effectiveDate.id}
      withIcon
      wrapperClassName="border-none bg-transparent p-0 w-[9rem]"
      value={effectiveDate.value ? parseDate(effectiveDate.value) : null}
    />
  )

  return (
    <div className="module-card-list-item">
      <p>
        {effectiveDate.label}{" "}
        {effectiveDate.optional && (
          <span className="text-sm text-neutral-64">{t("v2.defaults.optional")}</span>
        )}
      </p>
      {tooltipContent ? (
        <Tooltip>
          <TooltipTrigger>{datePicker}</TooltipTrigger>
          <TooltipContent className="react-tooltip-content--sm max-w-none">
            {tooltipContent}
          </TooltipContent>
        </Tooltip>
      ) : (
        datePicker
      )}
    </div>
  )
}

const hasDirectReportsForDate = (row: HeadcountPlanDatasheetRow, date: string) =>
  row.children?.some(
    (child) =>
      !child.isOriginal ||
      (child.isOriginal && !child.movesAwayOn) ||
      (child.movesAwayOn && dayjs(child.movesAwayOn).isAfter(date)),
  )
