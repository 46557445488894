import React from "react"

import { withRootProvider } from "v2/react/components/RootProvider"
import PageNav from "v2/react/shared/navigation/PageNav"
import { TitleBlock } from "v2/react/shared/navigation/PageNav/TitleBlock"

interface Props {
  title: string
}

/**
 * This component is used for Page Navigation when there are no tabs or actions
 * to be displayed. E.g. the Page Nav on the Calendar page.
 */
function BasicPageNav({ title }: Props) {
  return (
    <PageNav>
      <TitleBlock>
        <h1>{title}</h1>
      </TitleBlock>
    </PageNav>
  )
}

const BasicPageNavWithProvider = withRootProvider(BasicPageNav, "BasicPageNavWithProvider")

export { BasicPageNav, BasicPageNavWithProvider }
