import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { DisplayMode } from "org_chart/chart/node/types"
import React from "react"

import CancelApprovalButton from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/CancelApprovalButton"
import { ChartApprovalActions } from "v2/react/components/orgChart/ChartApproval/ChartApprovalActions"
import { EditApprovalButton } from "v2/react/components/orgChart/ChartApproval/EditApprovalButton"
import { currentUserIsNextApprover } from "v2/react/shared/status/statusUtils"
import { useGetChartAndApproversQuery } from "v2/redux/GraphqlApi/ChartApprovalsApi"
import { CurrentUser } from "v2/redux/slices/ApprovalSlice"
import { selectCurrentUser } from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import { useAppSelector } from "v2/redux/store"

interface Props {
  chartId: number
  setShowApprovalBar?: (value: React.SetStateAction<boolean>) => void
}

function ChartApprovalBar({ chartId, setShowApprovalBar }: Props) {
  const currentUser: CurrentUser | null = useAppSelector(selectCurrentUser)
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const { data: chart } = useGetChartAndApproversQuery({ id: chartId })

  if (!currentUser || !chart || !chart.status) return null

  const isCurrentApprover = currentUserIsNextApprover(
    chart?.chartApprovers || [],
    currentUser.currentPersonId,
  )

  const setBannerActions = (): React.ReactElement => {
    if (!chart.status) return <span />
    switch (actionType(currentUser, isCurrentApprover, chart.status)) {
      case "awaitingReview":
        return <ChartApprovalActions />
      case "pendingApproval":
        return <CancelApprovalButton chart={chart} size="sm" />
      case "changesRequested":
        return (
          <div>
            <EditApprovalButton chart={chart} />
          </div>
        )
      case "denied":
        return (
          <div>
            <EditApprovalButton chart={chart} />
          </div>
        )
      case "approved":
        return <div className="hidden sm:w-1 sm:block" />
      default:
        return <span />
    }
  }

  const baseAlertStyles =
    "flex flex-col items-start w-full gap-2 sm:flex-row justify-between sm:items-center h-fit sm:gap-1 overflow-hidden p-2 pl-3 rounded-lg"

  const bannerTitle = (
    <p className="items-center gap-2 flex">
      <FontAwesomeIcon
        icon={["far", alertType(chart.status, displayMode, isCurrentApprover).icon as IconName]}
      />
      <span className="text-sm font-bold text-neutral-100">
        {alertType(chart.status, displayMode, isCurrentApprover).title}
      </span>
    </p>
  )

  return (
    <div
      className={cn(
        alertType(chart.status, displayMode, isCurrentApprover).styles,
        baseAlertStyles,
        {
          "sm:!relative sm:!top-0 sm:!w-full sm:py-3 sm:pl-4 sm:pr-3 sm:!flex":
            displayMode === "grid",
        },
      )}
    >
      <div className="margin-0 whitespace-nowrap text-base-bold">{bannerTitle}</div>
      {setBannerActions()}
      <button
        type="button"
        className="btn--ghost absolute right-2 top-[5px] hover:bg-transparent sm:hidden"
        onClick={() => setShowApprovalBar && setShowApprovalBar(false)}
      >
        <FontAwesomeIcon icon={["far", "minus"]} />
      </button>
    </div>
  )
}

export { ChartApprovalBar }

interface AlertLookup {
  [key: string]: Record<string, string>
}

export const alertType = (
  status: string,
  displayMode: DisplayMode,
  isCurrentApprover?: boolean,
) => {
  const alertTypes: AlertLookup = {
    active: {
      alertType: "alert-pending",
      styles: "alert-pending sm:!w-[480px]",
      title: "awaiting_review".t("status_defaults"),
      icon: "paper-plane",
    },
    awaiting_approval: {
      alertType: "alert-pending",
      styles: "alert-pending sm:!w-[480px]",
      title: "pending_approval".t("status_defaults"),
      icon: "clock",
    },
    changes_requested: {
      alertType: "alert-caution",
      styles: "alert-caution sm:!w-[480px]",
      title: "changes_requested".t("status_defaults"),
      icon: "triangle-exclamation",
    },
    denied: {
      alertType: "alert-critical",
      styles: "alert-critical sm:!w-[480px]",
      title: "denied".t("status_defaults"),
      icon: "circle-exclamation",
    },
    approved: {
      alertType: "alert-success",
      styles: cn("alert-success sm:!w-fit"),
      title: "chart_is_approved".t("org_chart"),
      icon: "circle-check",
    },
  }

  if (isCurrentApprover && status === "awaiting_approval") return alertTypes.active
  return alertTypes[status]
}

const actionType = (currentUser: CurrentUser, isCurrentApprover: boolean, status: string) => {
  if (isCurrentApprover && status === "awaiting_approval") return "awaitingReview"
  if (status === "awaiting_approval" && currentUser.isCollaborator) return "pendingApproval"
  if (status === "changes_requested" && currentUser.isCollaborator) return "changesRequested"
  if (status === "denied" && currentUser.isCollaborator) return "denied"
  if (status === "approved" && currentUser.isCollaborator) return "approved"
  return null
}
