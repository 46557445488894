import dayjs, { Dayjs } from "dayjs"
import fp from "lodash/fp"
import { z } from "zod"

type EffectiveDate = z.infer<typeof EffectiveDateSchema>
type EffectiveDatesList = z.infer<typeof EffectiveDatesListSchema>
type FormEffectiveDate = z.infer<typeof EffectiveDateFormSchema>
type EffectiveDatesHash = z.infer<typeof EffectiveDatesHashSchema>

const EffectiveDateFormSchema = z.object({
  optional: z.boolean(),
  id: z.string(),
  label: z.string(),
  value: z.string().date().nullish(),
  isValueFromActual: z.boolean().nullish(),
})

const RequiredEffectiveDateSchema = z
  .object({
    optional: z.literal(false),
    id: z.string(),
    label: z.string(),
    value: z.string().date(),
    isValueFromActual: z.boolean().nullish(),
  })
  .passthrough()
const OptionalEffectiveDateSchema = z
  .object({
    optional: z.literal(true),
    id: z.string(),
    label: z.string(),
    value: z.string().date().nullish(),
    isValueFromActual: z.boolean().nullish(),
  })
  .passthrough()
const EffectiveDateSchema = z.discriminatedUnion("optional", [
  RequiredEffectiveDateSchema,
  OptionalEffectiveDateSchema,
])
const EffectiveDatesHashSchema = z.record(EffectiveDateSchema)
const EffectiveDatesListSchema = z.array(EffectiveDateSchema)

const isEffectiveDatesHash = (something: unknown): something is EffectiveDatesHash =>
  EffectiveDatesHashSchema.safeParse(something).success

const getEffectiveDatesList = (payload?: { [key: string]: unknown }): EffectiveDatesList =>
  fp.pipe(getEffectiveDatesHash, Object.values, sortEffectiveDates)(payload)

const getEffectiveDatesHash = (payload?: { [key: string]: unknown }): EffectiveDatesHash =>
  payload && isEffectiveDatesHash(payload.effective_dates) ? payload.effective_dates : {}

const getEffectiveDate = (
  payload?: { [key: string]: unknown } | undefined,
  dateId?: string | null,
) => (dateId ? getEffectiveDatesHash(payload)[dateId]?.value : undefined)

const getDistinctDates = (effectiveDates: EffectiveDatesList): string[] =>
  fp.pipe(
    fp.map(({ value }: EffectiveDate) => value),
    fp.reject(fp.isNil),
    fp.uniq,
  )(effectiveDates)

const orderValWhenSpecialDate = (dateHash: EffectiveDate) => {
  if (dateHash.id === "position_start_date") return 0
  if (dateHash.id === "employee_termination_date") return 1
  if (dateHash.id === "projected_hiring_date") return 2
  if (dateHash.id === "position_end_date") return 3

  return Infinity
}

const sortEffectiveDates: (list: EffectiveDatesList) => EffectiveDatesList = fp.sortBy([
  orderValWhenSpecialDate,
  fp.prop("label"),
])

const minEffectiveDate = (effectiveDates: EffectiveDatesList): Dayjs | undefined =>
  fp.minBy(
    (date) => date.valueOf(),
    fp.compact(effectiveDates.map(({ value }) => value)).map(dayjs),
  )

export {
  EffectiveDate,
  EffectiveDatesList,
  EffectiveDatesHash,
  FormEffectiveDate,
  RequiredEffectiveDateSchema,
  OptionalEffectiveDateSchema,
  EffectiveDateSchema,
  getDistinctDates,
  getEffectiveDate,
  getEffectiveDatesList,
  getEffectiveDatesHash,
  minEffectiveDate,
}
