import cn from "classnames"
import { motion } from "framer-motion"
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { PeopleConnectionQuery, Person } from "types/graphql.d"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { usePersonSearch } from "v2/react/hooks/usePersonSearch"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import PersonSearchResult, {
  maybeShowEmployeeIds,
  maybeShowPositionId,
} from "v2/react/shared/forms/PersonSearchResult"
import { opaqueDelay } from "v2/react/utils/framerAnimationVariants"

interface PersonSearchInputProps {
  autoFocus?: boolean
  dataFetchFn?: () => PeopleConnectionQuery
  errorMessage?: string
  htmlForId?: string
  omitValues?: string[]
  onSelect?: (selectedPerson: Person, index: number) => void
  placeholder?: string
  retainNameOnSelect?: boolean
  selected?: Partial<Person> & Pick<Person, "id" | "name">
  subordinateOnly?: boolean
  wrapperClassName?: string
}

function PersonSearchInput({
  autoFocus,
  dataFetchFn,
  errorMessage,
  htmlForId,
  omitValues,
  onSelect,
  placeholder,
  retainNameOnSelect,
  subordinateOnly,
  selected,
  wrapperClassName,
}: PersonSearchInputProps) {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(autoFocus || false)
  const [showResultList, setShowResultList] = useState(false)

  const {
    inputValue,
    setInputValue,
    showResultList: showList,
    peopleResult,
    handleInputChange,
  } = usePersonSearch({
    ignoreValue: selected?.name,
    omitValues: omitValues || [],
    fieldValue: selected?.name ?? "",
    dataFetchFn,
    subordinateOnly,
  })

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({ showList: showResultList, setShowList: setShowResultList })

  useEffect(() => {
    if (isFocused) {
      refs.domReference.current?.focus()
    } else {
      refs.domReference.current?.blur()
      setShowResultList(false)
    }
  }, [isFocused, refs])

  useEffect(() => {
    setShowResultList(showList)
  }, [showList])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    handleInputChange(event)

    if (value.length > 0) {
      setActiveIndex(0)
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeIndex != null && peopleResult[activeIndex]) {
      handleResultClick(peopleResult[activeIndex], activeIndex)
    }
  }

  const handleResultClick = (person: Person, index: number) => {
    setInputValue(retainNameOnSelect ? person.name : "")

    if (onSelect) onSelect(person, index)

    setActiveIndex(null)
    setShowResultList(false)
    setIsFocused(false)
  }

  return (
    <div className={cn("PersonSearchInput", wrapperClassName)}>
      <div className={errorMessage ? "form-error show" : ""}>
        <input
          className="input"
          id={htmlForId}
          name="person_name"
          placeholder={placeholder}
          type="text"
          value={inputValue}
          ref={refs.setReference}
          /* eslint-disable react/jsx-props-no-spreading */
          {...getReferenceProps({
            onKeyDown: handleKeyDown,
            onChange: handleChange,
          })}
        />
        {errorMessage && <span className="form-error-message">{errorMessage}</span>}
      </div>
      <DropdownMenu
        showList={showResultList && !!inputValue.trim().length}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="autocomplete-container"
        context={context}
      >
        {showResultList && peopleResult.length > 0 && (
          <div className="list-group autocomplete-list">
            {peopleResult.map((p, index) => (
              <div
                role="option"
                aria-selected={activeIndex === index}
                data-id={p.id}
                data-name={p.name}
                key={`list-group-item-${p.id}`}
                ref={(node) => {
                  listRef.current[index] = node
                }}
                className="list-group-item-wrapper"
                /* eslint-disable react/jsx-props-no-spreading */
                {...getItemProps({
                  onClick: () => handleResultClick(p, index),
                })}
              >
                <PersonSearchResult
                  active={inputValue === p.name || activeIndex === index}
                  avatarUrl={p.avatarThumbUrl || ""}
                  personName={p.name}
                  positionTitle={p.primaryPosition?.title || ""}
                  employeeIdDisplay={maybeShowEmployeeIds([p], inputValue, t)}
                  positionIdDisplay={maybeShowPositionId(
                    p.primaryPosition?.systemIdentifier,
                    inputValue,
                    t,
                  )}
                />
              </div>
            ))}
          </div>
        )}
        {showResultList && peopleResult.length === 0 && inputValue.trim().length > 0 && (
          <motion.div className="list-group autocomplete-list" variants={opaqueDelay}>
            <div className="disabled p-4">{t("v2.people.search.no_people_found")}</div>
          </motion.div>
        )}
      </DropdownMenu>
    </div>
  )
}

export { PersonSearchInput }
