import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { KeyboardEvent, useEffect, useState } from "react"

import { Option } from "types/graphql"
import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { prepareIconClass } from "v2/react/utils/misc"
import { useGetCurrencyCodeQuery } from "v2/redux/GraphqlApi"

export interface CurrencyOption {
  icon: string
  label: string
  value: string
}

interface Props {
  /**
   * (optional) value to set as the initiall selected currency code.
   * Defaults to the company currency code if it's not provided.
   */
  initialCurrencyCode?: string
  /**
   * (optional) method to access the selected currency option and determine whether
   * a change has occurred from the initial value. The alternative to access the selected
   * currency option only, would be to use the hidden currency_code field within a form.
   */
  onSelection?: (selected: CurrencyOption, changedFromInitial: boolean) => void
  /**
   * Determines whether the dropdown displays the full label for the selection
   * (Canadian Dollar (CAD, C$)) or just the value (CAD)
   */
  selectionDisplay: "value" | "label"
  /**
   * (optional) when passed in, displays the small select element. Defaults to the
   * regular input size.
   */
  size?: "sm"
}

function CurrencyDropdown({ initialCurrencyCode, onSelection, selectionDisplay, size }: Props) {
  const smallVersion = size === "sm"
  const { data, isLoading: isLoadingCurrencies } = useGetCurrencyCodeQuery({})
  const company = data?.currentCompany || null
  const [options, setOptions] = useState<CurrencyOption[]>([])
  const [currentCurrency, setCurrentCurrency] = useState<string>("")

  const [showResultList, setShowResultList] = useState(false)
  const [selectedOption, setSelectedOption] = useState<CurrencyOption | undefined>()

  const {
    activeIndex,
    context,
    floatingStyles,
    getFloatingProps,
    getItemProps,
    getReferenceProps,
    listRef,
    refs,
    setActiveIndex,
  } = useSelectList({ showList: showResultList, setShowList: setShowResultList })

  const handleOpen = () => {
    refs.domReference.current?.focus()
    setActiveIndex(showResultList ? 0 : null)
  }

  const handleResultClick = (option: CurrencyOption) => {
    setActiveIndex(null)
    setShowResultList(false)
    setSelectedOption(option)
    setCurrentCurrency(option.value)

    if (onSelection) {
      const initialOption = options.find((option) => option.value === company?.currencyCode)
      onSelection(option, option.value !== initialOption?.value)
    }
  }

  const closingList = (key: string) => key === "Escape" && showResultList
  const togglingInput = (key: string) => key === "Enter" && activeIndex === null && showResultList

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      (closingList(event.key) || togglingInput(event.key)) &&
      activeIndex !== null &&
      options[activeIndex]
    ) {
      handleResultClick(options[activeIndex])
    }

    if (event.key === "Enter" && activeIndex != null && options[activeIndex]) {
      const selectedCurrency = options[activeIndex]

      handleResultClick(selectedCurrency)
    }
  }

  useEffect(() => {
    // We only want this to run initially to setup our state values
    if (!company || selectedOption) return
    const currencyOptions = createCurrencyOptions(
      company?.collections.currencies.options.nodes || [],
    )
    const initialOption = currencyOptions.find(
      (option) => option.value === (initialCurrencyCode || company?.currencyCode),
    )

    setOptions(currencyOptions)
    setSelectedOption(initialOption)
    setCurrentCurrency(initialOption?.value || "")

    if (onSelection && initialOption) {
      onSelection(initialOption, false)
    }
  }, [company, initialCurrencyCode, onSelection, selectedOption])

  if (!selectedOption || isLoadingCurrencies) return null

  return (
    <>
      <div
        ref={refs.setReference}
        role="button"
        tabIndex={0}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({
          onClick: handleOpen,
          onKeyDown: handleKeyDown,
        })}
        className={cn("cursor-pointer gap-2", {
          "form-dropdown__dropdown-link justify-between": !smallVersion,
          "elevation hover:border--main-hover items-center justify-between rounded-md-sm bg-white px-2 py-1 text-neutral-80 shadow flex":
            smallVersion,
        })}
        data-testid="dropdown"
      >
        <p className={cn({ "text-left text-sm normal-case": smallVersion })}>
          {selectedOption[selectionDisplay]}
        </p>
        <FontAwesomeIcon
          icon={["fas", "caret-down"]}
          className={cn({ "!h-3 !w-3": smallVersion })}
        />
      </div>

      <DropdownMenu
        context={context}
        floatingProps={getFloatingProps}
        floatingRef={refs.setFloating}
        floatingStyles={{
          ...floatingStyles,
          marginTop: "8px",
          zIndex: 11,
          minWidth: "16rem",
        }}
        showList={showResultList}
        wrapperClasses={cn({
          "basic-select-menu h-[16rem] overflow-y-auto": !smallVersion,
          "select-dropdown__short-list gap-1 overflow-scroll": smallVersion,
        })}
      >
        <ul className="m-0">
          {options.map((option, index) => (
            <li
              aria-selected={activeIndex === index}
              key={option.value}
              role="option"
              className={cn("select-dropdown__option text-nowrap list-none gap-2", {
                active: option.label === selectedOption.label,
                "bg-neutral-3": activeIndex === index,
              })}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getItemProps({
                onClick: () => handleResultClick(option),
                onKeyDown: handleKeyDown,
              })}
              ref={(node) => {
                listRef.current[index] = node
              }}
              data-testid={option.value}
            >
              <span className="grid-cols-[1.25rem_auto] items-center grid">
                <FontAwesomeIcon icon={prepareIconClass(option.icon)} className="mx-auto" />
                <div className="items-center flex">
                  <div className="mr-2" />
                  <p className={cn({ "text-nowrap text-sm": smallVersion })}>{option.label}</p>
                </div>
              </span>
              {option.label === selectedOption.label && (
                <span title="checked">
                  <FontAwesomeIcon
                    icon={["fas", "circle-check"]}
                    className="selected-indicator ml-auto"
                  />
                </span>
              )}
            </li>
          ))}
        </ul>
      </DropdownMenu>
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <input
        type="hidden"
        id="currency_code"
        name="currency_code"
        role="textbox"
        value={currentCurrency}
      />
    </>
  )
}

export { CurrencyDropdown }

export const createCurrencyOptions = (currencyOptions: Option[]): CurrencyOption[] =>
  currencyOptions.map((option) => ({
    icon: option.id.split(":")[1],
    label: option.label,
    value: option.id.split(":")[0],
  }))
