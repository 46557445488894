import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { range } from "lodash"
import React from "react"

import { HeadcountPlanParticipant } from "types/graphql.d"
import { BudgetingCells } from "v2/react/components/headcountPlanning/Overview/ParticipantTable/BudgetingCells"
import { Cell as ProgressCell } from "v2/react/components/headcountPlanning/Overview/ParticipantTable/ProgressColumns/Cell"
import { StatusCell } from "v2/react/components/headcountPlanning/Overview/ParticipantTable/StatusCell"
import { UrlHelper } from "v2/react/utils/urls"

type Participant = Omit<HeadcountPlanParticipant, "children"> & {
  formattedBudget: HeadcountPlanParticipant["budget"]
  formattedProposedBudget: HeadcountPlanParticipant["proposedBudget"]
  children: Participant[]
  planAggregations: {
    plannedPositionsCount: NonNullable<
      HeadcountPlanParticipant["planAggregations"]
    >["plannedPositionsCount"]
    actualPositionsCount: NonNullable<
      HeadcountPlanParticipant["planAggregations"]
    >["actualPositionsCount"]
  }
}
interface Props {
  continuationLineLevels: number[]
  headcountPlanId: string
  isLastSibling: boolean
  level: number
  participant: Participant
  showBudget: boolean
  showProposal: boolean
  showStatus: boolean
  showPositionCount: boolean
  planStarted: boolean
  planAggregations: Participant["planAggregations"]
}

function ParticipantTableRow({
  continuationLineLevels,
  headcountPlanId,
  isLastSibling,
  level,
  participant,
  showBudget,
  showProposal,
  showStatus,
  showPositionCount,
  planStarted,
  planAggregations,
}: Props) {
  return (
    <tr>
      <td>
        <div className="hcp-overview-table__environment-label">
          {level > 0 &&
            range(level - 1).map((i) => (
              <div key={i} className="hcp-overview-table__node-label">
                {hasConnectionLineToDownlineSibling(continuationLineLevels, i + 1) && (
                  <div className="hcp-overview-table__node-line-connect-sibling" />
                )}
              </div>
            ))}
          <div className={classNames("hcp-overview-table__node-label", { "w-0": level === 0 })}>
            {level > 0 && (
              <>
                {isLastSibling ? (
                  <div className="hcp-overview-table__node-line-last-sibling" />
                ) : (
                  <div className="hcp-overview-table__node-line-sibling" />
                )}

                <div className="hcp-overview-table__node-line-horizontal" />
              </>
            )}
          </div>
          <div className="ml-[-0.525rem] h-8 w-8 items-center justify-center rounded-md bg-neutral-3 flex">
            <FontAwesomeIcon icon={["far", "sitemap"]} className="fa-w-14" />
          </div>
          <a
            href={UrlHelper.headcountPlanParticipantDatasheetPath(headcountPlanId, participant.id)}
            className="hcp-overview-table__env-link"
          >
            {participant.position?.title || "untitled_position".t("headcount_plan")}
          </a>
        </div>
      </td>
      <td>
        <div className="items-center px-4 flex">
          {participant.person.avatarThumbUrl && (
            <img
              className="circle mr-2 h-6 w-6"
              src={participant.person.avatarThumbUrl}
              alt={participant.person.name}
            />
          )}
          <span>{participant.person.name}</span>
        </div>
      </td>
      <td>
        <div className="items-center px-4 flex">{departmentName(participant)}</div>
      </td>
      {showPositionCount && (
        <ProgressCell
          columnType="position_count"
          actual={planAggregations.actualPositionsCount || 0}
          planned={planAggregations.plannedPositionsCount || 0}
          planStarted={planStarted}
          showPlannedOnly={!planStarted}
        />
      )}
      <BudgetingCells
        budget={participant.formattedBudget}
        completed={participant.status === "complete"}
        proposal={participant.formattedProposedBudget}
        showBudget={showBudget}
        showProposal={showProposal}
        remainingBudget={participant.remainingBudget}
      />
      {showStatus && <StatusCell status={participant.status} />}
    </tr>
  )
}

const departmentName = (participant: HeadcountPlanParticipant): string => {
  if (!participant.position?.orgUnits) return ""
  const departmentUnits = participant.position.orgUnits.filter(
    (u) => u.orgUnitType?.name === "Department",
  )
  if (departmentUnits.length < 1) return ""
  return departmentUnits[0].name as string
}

const hasConnectionLineToDownlineSibling = (continuationLineLevels: number[], level: number) =>
  continuationLineLevels.includes(level)

export type { Participant }
export { ParticipantTableRow }
