import React from "react"

import { withRootProvider } from "v2/react/components/RootProvider"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import { ExportButtonMenu, type ExportUrl } from "v2/react/shared/buttons/ExportButtonMenu"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { TitleBlock, TitleHeaderWithParent } from "v2/react/shared/navigation/PageNav/TitleBlock"

interface Props {
  parentTitle: string | null
  parentTitleUrl?: string
  title: string
  exportRef?: React.RefObject<HTMLButtonElement> | string
  /**
   * Some reports use a backend endpoint to export data, for these we
   * provide the exportUrl.
   */
  exportUrl?: string
  /**
   * Some reports use multiple backend endpoints to export data, for these we
   * provide the exportUrls.
   */
  exportUrls?: ExportUrl[]
  useCurrentSearchParamsWithExportUrl?: boolean
}

function WithProvider({
  parentTitle,
  parentTitleUrl,
  title,
  exportRef,
  exportUrl,
  exportUrls,
  useCurrentSearchParamsWithExportUrl,
}: Props) {
  return (
    <PageNav>
      <TitleBlock>
        {parentTitle ? (
          <TitleHeaderWithParent
            parentTitle={parentTitle}
            parentTitleUrl={parentTitleUrl}
            title={title}
          />
        ) : (
          <h1>{title}</h1>
        )}
      </TitleBlock>
      <ActionBlock>
        {exportRef && <ExportButton csvDownloadRef={exportRef} />}
        {exportUrls && (
          <ExportButtonMenu
            exportUrls={exportUrls}
            useCurrentSearchParamsWithExportUrl={useCurrentSearchParamsWithExportUrl}
          />
        )}
        {exportUrl && (
          <ExportButton
            exportUrl={exportUrl}
            useCurrentSearchParamsWithExportUrl={useCurrentSearchParamsWithExportUrl}
          />
        )}
      </ActionBlock>
    </PageNav>
  )
}

const ReportsNav = withRootProvider(WithProvider, "ReportsNav")

export { ReportsNav }
