import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { ReactNode, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  Dropdown,
  DropdownContext,
  type DropdownProps,
} from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import {
  useDropdownSelect,
  type OnOpenChange,
} from "v2/react/shared/collection/menus/Dropdown/hooks/useDropdownSelect"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"

interface SearchableItemType {
  id: string
  label: string
}

interface SearchableDropdownProps<T> {
  id: string
  label?: string
  items: T[]
  selectedItem?: T
  onSelect: (selectedItem: T) => void
  onClear?: () => void
  align?: DropdownProps["align"]
  searchPlaceholder?: string
  noResultsPlaceholder?: string
  makeLabel?: (item: T) => ReactNode
  menuClassName?: string
  useIcon?: boolean
  usePlaceholder?: boolean
  matchReferenceWidth?: boolean
  clearOnSelect?: boolean
  error?: string
}

const SearchableDropdown = <T extends SearchableItemType>({
  id,
  label,
  items,
  selectedItem,
  onSelect,
  onClear,
  align,
  searchPlaceholder,
  noResultsPlaceholder,
  makeLabel,
  menuClassName,
  useIcon = true,
  usePlaceholder = true,
  matchReferenceWidth = true,
  clearOnSelect = true,
  error,
}: SearchableDropdownProps<T>) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState(selectedItem?.label ?? "")
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const onOpenChange: OnOpenChange = (isOpen, event, reason) => {
    // Hack: When tabbing out of the search field, the reason here is undefined.
    // We leverage this to close the dropdown.
    if (!reason) {
      setIsOpen(false)
      floatingInfo.setActiveIndex(0)
    }

    const target = event?.target
    const targetInContainer =
      target instanceof HTMLElement && containerRef.current?.contains(target)
    if (targetInContainer) {
      return
    }

    setIsOpen(isOpen)
  }

  const floatingInfo = useDropdownSelect({
    showDropdown: isOpen,
    setShowDropdown: onOpenChange,
    align,
    ariaRole: "combobox",
    virtual: true,
    useDynamicSizing: true,
    maxHeight: 384, // 24rem
    matchReferenceWidth,
  })

  const contextValue = useMemo(
    () => ({ isOpen, setIsOpen, floatingInfo }),
    [isOpen, setIsOpen, floatingInfo],
  )

  const handleItemSelect = (item: T) => {
    onSelect?.(item)
    setIsOpen(false)
    if (clearOnSelect) {
      setInputValue("")
    } else {
      setInputValue(item.label)
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value
    setInputValue(searchTerm)
    floatingInfo.setActiveIndex(0)
  }

  const handleClearValue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    onClear?.()
    setInputValue("")
    floatingInfo.setActiveIndex(0)

    // Hack to ensure that the dropdown menu is appropriately resized.
    if (!matchReferenceWidth) {
      setIsOpen(false)
      setTimeout(() => {
        setIsOpen(true)
      }, 100)
    }
  }

  const visibleItems = items
    .filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase().trim()))
    .sort((a, b) => a.label.localeCompare(b.label))
  const noResults = visibleItems.length === 0

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      floatingInfo.activeIndex != null &&
      visibleItems[floatingInfo.activeIndex]
    ) {
      e.preventDefault()
      handleItemSelect(visibleItems[floatingInfo.activeIndex])
      floatingInfo.setActiveIndex(null)
      floatingInfo.refs.domReference.current?.blur()
    }
  }

  const handleFocus = () => {
    if (!isOpen) {
      floatingInfo.setActiveIndex(0)
      setIsOpen(true)
    }
  }

  return (
    <DropdownContext.Provider value={contextValue}>
      <div id={`${id}-wrapper`} className="w-full flex-col gap-1 flex" ref={containerRef}>
        {label && (
          <label className="text-neutral-100" htmlFor={id}>
            {label}
          </label>
        )}
        <div className="flex-col flex">
          <div className={cn("search-field dropdown", { "form-error": !!error })}>
            {useIcon ? (
              <FontAwesomeIcon icon={["far", "search"]} className="search-icon prefix" />
            ) : null}
            <input
              id={id}
              className={cn("input suffix-pad", { "prefix-pad": useIcon })}
              data-testid="combobox-trigger"
              autoComplete="off"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...floatingInfo.getReferenceProps({
                ref: floatingInfo.refs.setReference,
                onChange: handleSearch,
                value: inputValue,
                placeholder: usePlaceholder
                  ? searchPlaceholder ?? t("v2.shared.searchable_list.search")
                  : undefined,
                onKeyDown: handleKeyDown,
                onFocus: handleFocus,
              })}
            />
            {inputValue.trim().length > 0 ? (
              <button
                className="suffix !text-neutral-64"
                onMouseDown={handleClearValue}
                type="button"
                tabIndex={-1}
              >
                <FontAwesomeIcon icon={["far", "xmark"]} className="pointer-events-none" />
              </button>
            ) : null}
          </div>
          {error && <InputErrorText message={error ?? ""} />}
        </div>
        <Dropdown.Menu
          className={menuClassName}
          id="search-results"
          captureInitialFocus={false}
          returnFocus={false}
        >
          {noResults ? (
            <div id="no-search-results-message" className="p-2">
              {noResultsPlaceholder ?? t("v2.defaults.no_results_found")}
            </div>
          ) : (
            visibleItems.map((item) => (
              <Dropdown.Item
                key={`search-result-${item.id}`}
                id={`search-result-${item.id}`}
                onClick={() => handleItemSelect(item)}
                className="break-words"
                as="div"
                useActiveStyles
              >
                {makeLabel ? makeLabel(item) : item.label}
              </Dropdown.Item>
            ))
          )}
        </Dropdown.Menu>
      </div>
    </DropdownContext.Provider>
  )
}

export { SearchableDropdown }
