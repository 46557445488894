import classNames from "classnames"
import React from "react"

import { CompareValues } from "./CompareValues"
import { useCellHandlers } from "./hooks/useCellHandlers"
import { useCellState } from "./hooks/useCellState"
import { StandardCellInput } from "./StandardCellInput"
import { StyleLayers } from "./StyleLayers"
import { FieldType } from "./types"

type Props = {
  currentValue: string
  columnId: string
  rowId: string
}
export function NonEditableCell({ currentValue, columnId, rowId }: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const cell = useCellState({
    currentValue,
    fieldType: FieldType.NonEditable,
    rowId,
    columnId,
    editable: false,
    saveFn: () => Promise.resolve({ ok: true } as const),
  })

  const cellRef = React.useRef<HTMLDivElement>(null)

  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, inputRef, {
    getSaveValue: () => inputRef.current?.value ?? currentValue,
  })

  const errored =
    cell.isErrored || cell.isErroredEditing || cell.isErroredSaving || cell.isErroredSelected

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/control-has-associated-label
    <div
      role="button"
      ref={cellRef}
      className={classNames("editable-cell", { "editable-cell--errored": errored })}
      id={`${columnId}-${rowId}`}
      onClick={handleCellClick}
      onKeyUp={handleCellKeyUp}
    >
      <CompareValues>
        <StandardCellInput
          ref={inputRef}
          name={columnId}
          disabled
          type="text"
          defaultValue={currentValue}
        />
      </CompareValues>
      <StyleLayers cell={cell} fieldType={FieldType.Standard} />
    </div>
  )
}
