import { useState } from "react"

import type { Link } from "v2/react/shared/navigation/PageNav/LinkGroup"

export interface UseActiveViewProps<T extends string> {
  initialView: T
}

/**
 * Provides an `activeView` state along with a helper function to update it when
 * a link is clicked.
 */
export function useActiveView<T extends string>({ initialView }: UseActiveViewProps<T>) {
  const [activeView, setActiveView] = useState<T>(initialView)

  function handleLinkClick(link: Link<T>) {
    if (link.id) setActiveView(link.id)
  }

  return {
    activeView,
    setActiveView,
    handleLinkClick,
  }
}
