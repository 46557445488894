import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface Props {
  importPath: string
  isImporting: boolean
}

function RequisitionImportLink({ importPath, isImporting }: Props) {
  const { t } = useTranslation()

  return (
    <Tooltip placement="bottom-end">
      <TooltipTrigger>
        <a
          data-method="post"
          href={importPath}
          className={cn("btn--large btn--secondary", {
            disabled: isImporting,
          })}
        >
          <FontAwesomeIcon icon={["far", "upload"]} />
        </a>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm">
        {isImporting
          ? t("v2.job_requisitions.index.import_busy")
          : t("v2.job_requisitions.index.import")}
      </TooltipContent>
    </Tooltip>
  )
}

export { RequisitionImportLink }
