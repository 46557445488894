import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { withRootProvider } from "v2/react/components/RootProvider"
import { ExportForm } from "v2/react/components/succession/ExportForm"
import { useActiveView } from "v2/react/hooks/useActiveView"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlock } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { Link, LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlock } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { Modal } from "v2/react/shared/overlay/Modal"
import { UrlHelper } from "v2/react/utils/urls"

/**
 * See link ids defined in app/views/v2/succession/_header.html.slim
 */
type SuccessionView = "plans" | "insights" | "settings"

interface Props {
  links: Link<SuccessionView>[]
  initialView?: SuccessionView
}

function Content({ links, initialView }: Props) {
  const { t } = useTranslation()
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const { activeView, handleLinkClick } = useActiveView<SuccessionView>({
    initialView: initialView || "plans",
  })
  const isPlansView = activeView === "plans"

  const handleOpenModal = () => {
    setExportModalOpen(true)
  }

  const handleCloseModal = () => {
    setExportModalOpen(false)
  }

  return (
    <PageNav>
      <TitleBlock>
        <h1>{t("v2.shared.nav.succession_planning")}</h1>
      </TitleBlock>
      <LinkBlock>
        <LinkGroup links={links} onClick={handleLinkClick} usePjax />
      </LinkBlock>
      <ActionBlock>
        {isPlansView && (
          <>
            <Modal
              isOpen={exportModalOpen}
              onClose={handleCloseModal}
              title={t("v2.defaults.export")}
            >
              <ExportForm
                exportUrl={UrlHelper.exportSuccessionPlansPath()}
                onSubmit={handleCloseModal}
              />
            </Modal>
            <button
              type="button"
              className="btn--large btn--secondary w-11"
              id="export-csv"
              onClick={handleOpenModal}
            >
              <FontAwesomeIcon icon={["far", "download"]} />
            </button>
          </>
        )}
      </ActionBlock>
    </PageNav>
  )
}

const SuccessionNav = withRootProvider(Content, "SuccessionNav")

export { SuccessionNav }
