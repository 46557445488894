import cn from "classnames"
import React from "react"

import { Content } from "v2/react/shared/layout/Sidebar/Content"
import { getPageTopOffsets } from "v2/react/utils/layout"

interface SidebarProps {
  open: boolean
  children: React.ReactNode
  onAfterClose?: () => void
}

const Sidebar = ({ open, children, onAfterClose }: SidebarProps) => {
  const { utilityNavHeight, totalHeaderHeight } = getPageTopOffsets()

  const handleTransitionEnd = () => {
    if (!open) {
      onAfterClose?.()
    }
  }

  return (
    <div
      className={cn({
        drawer: true,
        "is-open": open,
      })}
      style={{
        top: `${utilityNavHeight}px`,
        height: `calc(100vh - ${totalHeaderHeight}px)`,
      }}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className="drawer-tab-container h-full bg-white">
        <div className="drawer-contents panel grid-rows-[auto_1fr] grid">{children}</div>
      </div>
    </div>
  )
}

Sidebar.Content = Content

export { Sidebar }
