import eliminateAllPositions from "images/eliminate-all-positions.png"
import movePositionsUp from "images/move-positions-up.png"
import React from "react"
import { useTranslation } from "react-i18next"

import { DestroyPositionMode } from "types/graphql.enums"
import { RemovePositionOptionCard } from "v2/react/components/positions/shared/RemovePositionOptionCard"

interface SubordinatesProps {
  onSelect: (option: DestroyPositionMode) => void
  selectedRemoveOption?: DestroyPositionMode | null
}
const Subordinates = ({ onSelect, selectedRemoveOption }: SubordinatesProps) => {
  const { t } = useTranslation()

  return (
    <div className="p-6">
      <p className="mb-6">{t("v2.profile_panel.remove_position_manages")}</p>
      <div className="gap-4 flex">
        <RemovePositionOptionCard
          imageUrl={movePositionsUp}
          isSelected={selectedRemoveOption === DestroyPositionMode.MoveChildrenUp}
          handleSelection={() => onSelect(DestroyPositionMode.MoveChildrenUp)}
          message={t("v2.profile_panel.remove_move")}
        />
        <RemovePositionOptionCard
          imageUrl={eliminateAllPositions}
          isSelected={selectedRemoveOption === DestroyPositionMode.DestroyAllChildren}
          handleSelection={() => onSelect(DestroyPositionMode.DestroyAllChildren)}
          message={t("v2.profile_panel.remove_all")}
        />
      </div>
    </div>
  )
}

export { Subordinates }
