import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { LinkMenuGroup } from "v2/react/shared/navigation/PageNav/LinkMenuGroup"
import { pjax } from "v2/react/utils/pjax"

import { hasSingularOrEmptyLinks } from "./utils"

export interface Link<T extends string = string> {
  active: boolean
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  show: boolean
  text: string
  url: string
  width?: number
  id?: T
  disablePjax?: boolean
}

interface LinkGroupProps<T extends string = string> {
  links: Link<T>[]
  showAll?: boolean
  /**
   * When `usePjax` is true, we replace the content of the page using pjax.  The
   * container it replaces is set on the content div in the top level layout.
   *
   * See:
   * - app/views/v1/layouts/application.html.erb
   * - app/views/v2/layouts/application.html.erb
   */
  usePjax?: boolean
  onClick?: (link: Link<T>) => void
}

function LinkGroup<T extends string = string>({
  showAll,
  links,
  usePjax,
  onClick,
}: LinkGroupProps<T>) {
  const shownLinks = useMemo(() => links.filter((link) => link.show), [links])
  const navRef = useRef<HTMLDivElement>(null)
  const [visibleLinks, setVisibleLinks] = useState<Link<T>[]>(shownLinks)
  const [hiddenLinks, setHiddenLinks] = useState<Link<T>[]>([])
  const [activeLink, setActiveLink] = useState<Link<T> | undefined>(getActiveLink(links))

  const updateLinksVisibility = useCallback(() => {
    const necessaryPadding = 64
    const nav = navRef.current
    if (!nav) return

    let availableWidth = nav.offsetWidth - necessaryPadding
    const newVisibleLinks: Link<T>[] = []
    const newHiddenLinks: Link<T>[] = []

    shownLinks.forEach((link) => {
      const linkElement = document.getElementById(link.url + link.text)

      if (linkElement?.offsetWidth) {
        // without setting this on the initial render, we have no width value for each link
        // which prevents links from becoming visible again when expanding the screen
        link.width = linkElement.offsetWidth // eslint-disable-line no-param-reassign
      }

      const linkWidth = link.width || linkElement?.offsetWidth || 0

      if (availableWidth - linkWidth > 0 || showAll) {
        newVisibleLinks.push(link)
        availableWidth -= linkWidth
      } else {
        newHiddenLinks.push(link)
        // if there is no room for the link, remove all available width
        // prevents odd flashing of links attempting to fit in the remaining space
        availableWidth = 0
      }
    })

    setVisibleLinks(newVisibleLinks)
    setHiddenLinks(newHiddenLinks)
  }, [shownLinks, showAll])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries[0].contentRect.width) return
      updateLinksVisibility()
    })

    if (navRef.current) {
      resizeObserver.observe(navRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [navRef, shownLinks, updateLinksVisibility])

  useSetActiveLinkOnRestorationVisits(usePjax, links, setActiveLink)

  if (hasSingularOrEmptyLinks(links)) return null

  const isFirst = (index: number) => index === 0
  const isLast = (index: number) => visibleLinks.length - 1 === index

  const onLinkClick = handleLinkClick<T>(setActiveLink, usePjax, onClick)

  const pageLink = (link: Link<T>, index: number) => (
    <motion.a
      key={link.url + link.text}
      href={link.url}
      id={link.url + link.text}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.2, ease: "easeOut" }}
      data-url={link.url}
      className={classNames("btn visible-link opacity-1 visible", {
        active: link.text === activeLink?.text,
        "!rounded-r-lg": isLast(index) && !(hiddenLinks.length > 0),
        "!rounded-r-none": isLast(index) && hiddenLinks.length > 0,
        "!order-[-1]": isFirst(index),
      })}
      onClick={onLinkClick(link)}
    >
      {link.text}
    </motion.a>
  )

  return (
    <div ref={navRef} className="btn-group w-full justify-center flex">
      <div
        className={classNames("grow overflow-hidden py-2 flex", {
          "justify-center block": !(hiddenLinks.length > 0),
        })}
      >
        <AnimatePresence>
          {visibleLinks.map((link: Link<T>, index: number) => pageLink(link, index))}
        </AnimatePresence>
        {hiddenLinks.length > 0 && (
          <LinkMenuGroup
            menuText={"More".t("defaults")}
            classes="!rounded-l-none !rounded-r-lg visible"
            links={hiddenLinks}
            usePjax={usePjax}
            activeLink={activeLink}
            onLinkClick={onLinkClick}
          />
        )}
      </div>
    </div>
  )
}

const handleLinkClick =
  <T extends string = string>(
    setActiveLink: React.Dispatch<React.SetStateAction<Link<T> | undefined>>,
    usePjax?: boolean,
    onClick?: LinkGroupProps<T>["onClick"],
  ) =>
  (link: Link<T>) =>
  (e: MouseEvent<HTMLAnchorElement>) => {
    link.onClick?.(e)
    onClick?.(link)

    if (usePjax && !link.disablePjax) {
      e.preventDefault()

      setActiveLink(link)
      pjax({
        url: link.url,
        container: "[data-pjax-container=content]",
        push: true,
      })
    }
  }

const getActiveLink = <T extends string = string>(links: Link<T>[]) =>
  links.find((link) => link.active)

/**
 * When going back/forward in the browser + using pjax, we need to ensure the
 * active link is styled correctly.
 */
const useSetActiveLinkOnRestorationVisits = <T extends string = string>(
  usePjax: boolean | undefined,
  links: Link<T>[],
  setActiveLink: React.Dispatch<React.SetStateAction<Link<T> | undefined>>,
) => {
  useEffect(() => {
    if (!usePjax) return undefined

    function handleUrlChange() {
      // Skip if the change is just a hash update. This can happen when clicking
      // a link with an href set to just '#'
      if (window.location.href.endsWith("#")) return

      const url = window.location.href.replace(window.location.origin, "")
      const activeLink = links.find((link) => link.url === url)
      setActiveLink(activeLink)

      // Reset any onmounts
      window.$?.onmount?.()
    }

    // For back/forward events
    window.addEventListener("popstate", handleUrlChange)

    return () => {
      window.removeEventListener("popstate", handleUrlChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usePjax])
}

export { LinkGroup, handleLinkClick }
