import React from "react"

import { FeatureFlags, PositionType } from "types/graphql"
import { Description } from "v2/react/components/positionTypes/Show/Description"
import { Details } from "v2/react/components/positionTypes/Show/Details"
import { PeopleInterestedList } from "v2/react/components/positionTypes/Show/PeopleInterestedList"
import { Positions } from "v2/react/components/positionTypes/Show/Positions"
import { Requirements } from "v2/react/components/positionTypes/Show/Requirements"
import { Responsibilities } from "v2/react/components/positionTypes/Show/Responsibilities"
import { Stats } from "v2/react/components/positionTypes/Show/Stats"
import { Summary } from "v2/react/components/positionTypes/Show/Summary"
import RootProvider from "v2/react/components/RootProvider"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"
import { useGetPositionTypeDetailsQuery } from "v2/redux/GraphqlApi/PositionTypesApi"

interface ShowProps {
  positionTypeId: number
  canReadPayGrade: boolean
}

function WithProvider({ positionTypeId, canReadPayGrade }: ShowProps) {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery()
  const { data: positionTypeResult, isLoading } = useGetPositionTypeDetailsQuery({
    id: `position_type_${positionTypeId}`,
  })
  const positionType: PositionType | null | undefined = positionTypeResult?.positionType
  const featureFlags: FeatureFlags | undefined = featureFlagsData?.currentCompany?.featureFlags

  if (isLoading || !positionType || !featureFlags) {
    return (
      <div className="relative h-screen">
        <Spinner testId="positionType-spinner" />
      </div>
    )
  }

  return (
    <div className="page-pad">
      <div className="grid-cols-1 gap-3 grid sm:grid-cols-[2fr_1fr] sm:gap-8">
        <div>
          <Summary positionType={positionType} featureFlags={featureFlags} />
          <Details
            positionType={positionType}
            positionTypeId={positionType.id}
            canReadPayGrade={canReadPayGrade}
            positionManagement={featureFlags.positionManagement}
          />
          <Requirements positionType={positionType} featureFlags={featureFlags} />
          <Responsibilities positionType={positionType} featureFlags={featureFlags} />
          {featureFlags.positionManagement && <Description positionType={positionType} />}
        </div>
        <div>
          <Stats positionType={positionType} positionManagement={featureFlags.positionManagement} />
          <Positions positionType={positionType} />
          <PeopleInterestedList positionType={positionType} />
        </div>
      </div>
    </div>
  )
}

const Show = ({ positionTypeId, canReadPayGrade }: ShowProps) => (
  <React.StrictMode>
    <RootProvider>
      <WithProvider positionTypeId={positionTypeId} canReadPayGrade={canReadPayGrade} />
    </RootProvider>
  </React.StrictMode>
)

export { Show, WithProvider }
