import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { StrictMode } from "react"

import RootProvider from "v2/react/components/RootProvider"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { useAppDispatch } from "v2/redux/store"

function MetricsCloseButtonInner() {
  const metricsPanel = document.querySelector("#org-chart-metrics")
  const dispatch = useAppDispatch()

  const handleClose = () => {
    metricsPanel?.classList.add("hidden")
    dispatch(asyncPatchPreferences({ show_metrics: false }))
  }

  return (
    <button type="button" onClick={handleClose} className="btn--sm btn--ghost disable-metrics">
      <FontAwesomeIcon icon={["far", "xmark"]} />
    </button>
  )
}

function MetricsCloseButton() {
  return (
    <StrictMode>
      <RootProvider>
        <MetricsCloseButtonInner />
      </RootProvider>
    </StrictMode>
  )
}

export default MetricsCloseButton
