import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { Person, PositionSeatChangeIntent } from "types/graphql.d"
import {
  FallbackOptionProps,
  SuggestedAutocompleteCell,
} from "v2/react/components/headcountPlanning/TableDatasheet/SuggestedAutocompleteCell"
import { prepareStyle } from "v2/react/components/orgChart/Datasheet/Cell/utils"
import type {
  Column,
  CursorConnection,
  NodeRow,
} from "v2/react/components/orgChart/Datasheet/types"
import { usePersonSearch } from "v2/react/hooks/usePersonSearch"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import {
  toEncodedIdIntentPair,
  toEncodedInitialData,
  withNameModal,
  withSeatChangeModal,
} from "v2/redux/slices/GridSlice/gridHelpers/people"
import { useAppSelector } from "v2/redux/store"

export type PersonAutocompleteProps<TNode, CType> = {
  column: Column<CType>
  currentValue?: string
  cursorConnection: CursorConnection
  isFirst: boolean
  isLast: boolean
  row: NodeRow<TNode>
  style: React.CSSProperties
}

export function prepareValue(arg: Person | null | undefined) {
  if (!arg) {
    return ""
  }
  if (arg.id === "custom:keydown" && arg.name.trim() === "") {
    return toEncodedIdIntentPair(`person_${arg.id}`, PositionSeatChangeIntent.Remove)
  }
  if (arg.id.includes("custom")) {
    return withNameModal(toEncodedInitialData(arg.name))
  }
  if (arg.primaryPosition) {
    return withSeatChangeModal(toEncodedInitialData(JSON.stringify(arg)))
  }
  return toEncodedIdIntentPair(`person_${arg.id}`, PositionSeatChangeIntent.Primary)
}

function getPersonLabel(person: Person | null | undefined) {
  return person?.name || ""
}

/**
 * Presents an autocomplete for the person's name along with an action to add a
 * new person.
 *
 * @public
 */
export function PersonAutocomplete<TNode, CType>({
  column,
  currentValue,
  cursorConnection,
  isFirst,
  isLast,
  row,
  style,
}: PersonAutocompleteProps<TNode, CType>) {
  const chartId = useAppSelector((state) => state.container.containerKey)

  const currentPersonId = (row.data as { person_id: string }).person_id || null
  // We need to convert the person_id (unique_key) to just the id to omit it from the search results.
  // If we convert the id -> the unique_key in our graphql types, this can be removed.
  const currentPersonIdNumber = currentPersonId && idFromUniqueKey(currentPersonId)
  const omitValues = React.useMemo(
    () => (currentPersonIdNumber ? [currentPersonIdNumber] : []),
    [currentPersonIdNumber],
  )

  const { inputValue, setInputValue, peopleResult } = usePersonSearch({
    omitValues,
    fieldValue: cursorConnection.initialWriteValue ?? currentValue,
    chartId: chartId || undefined,
  })

  return (
    <SuggestedAutocompleteCell
      editable
      cellInputRef={cursorConnection.cellInputRef}
      options={peopleResult}
      getLabel={getPersonLabel}
      columnId={String(column.fieldKey)}
      saveFn={(option) => cursorConnection.saveFn(prepareValue(option))}
      renderFallbackOption={({ saveCustom }) => <FallbackOption saveCustom={saveCustom} />}
      className={classNames("GridBody-cell Cell__select-field bg-transparent", { last: isLast })}
      currentValue={{ id: "", name: currentValue ?? "" }}
      searchTerm={inputValue}
      setSearchTerm={setInputValue}
      rowId={row.id}
      style={{ ...prepareStyle(style, isFirst, row.color), padding: 0 }}
      renderOption={(person) => <Option person={person} />}
      hasNotChanged={(value, oldValue) => {
        if (value.id.includes("custom")) {
          return value.id === "custom:keydown" && value.name === oldValue?.name
        }
        return value.id === oldValue?.id
      }}
      createOption={(optionInfo) => ({
        id: `custom:${optionInfo.createdBy}`,
        name: optionInfo.label,
      })}
    />
  )
}

function Option({ person }: { person: Person }) {
  return (
    <div className="flex">
      <div className="mr-3">
        <img
          alt={person.name || ""}
          src={person.avatarThumbUrl || ""}
          className="h-10 w-10 rounded-sm block"
        />
      </div>
      <div className="mr-auto flex-col gap-[0.1rem] overflow-hidden flex">
        <div className="text-primary-100">{person.name}</div>
        <div>{person.primaryPosition?.title || " "}</div>
      </div>
    </div>
  )
}

function FallbackOption({ saveCustom }: FallbackOptionProps) {
  const { t } = useTranslation()

  return (
    <div className="AutocompleteField__no-result border-none">
      <p className="AutocompleteField__no-result-text">
        {t("v2.people.search.dont_see_the_person_you_are_looking_for")}
      </p>
      <button type="button" className="btn--sm btn--primary" onClick={saveCustom}>
        <FontAwesomeIcon icon={["far", "user-plus"]} />
        {t("v2.defaults.create")}
      </button>
    </div>
  )
}
