import { stratify as d3Stratify, HierarchyNode } from "d3-hierarchy"
import { cloneDeep } from "lodash/fp"
import { NodeData } from "org_chart/chart/node/types"
import OrgChart from "org_chart/chart/orgChart"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { OrgchartPosition, Position, Scalars } from "types/graphql"
import {
  reassignAssistants,
  unwrapDataAndBuildMetaFromHierarchy,
} from "v2/react/components/headcountPlanning/OrgChart/utils"
import { useChartViewId } from "v2/react/components/orgChart/HistoricalOrgChart/hooks"
import { ExtendedHierarchyNode } from "v2/react/components/orgChart/HistoricalOrgChart/utils"
import { tryParseEntityAndId } from "v2/react/utils/uniqueKey"
import { UrlHelper } from "v2/react/utils/urls"
import { ChartContainer } from "v2/redux/containers/types"
import { useSaveHeadcountPlanPositionMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

const useOrgChart = (
  activeChart: ChartContainer,
  chart: OrgChart | null,
  chartContainerRef: React.RefObject<HTMLDivElement>,
  hcpPositions: OrgchartPosition[] | null | undefined,
  headcountPlanId: string,
  isFetching: boolean,
  isParticipantPage: boolean,
  positionsEndpoint: string,
  setChart: React.Dispatch<React.SetStateAction<OrgChart | null>>,
  startDate: Scalars["ISO8601Date"],
  topNode: OrgchartPosition,
  participantId?: string,
  parentPositionId?: string,
) => {
  const { t } = useTranslation()
  const { chartViewId, setChartViewId } = useChartViewId()
  const [chartData, setChartData] = useState<HierarchyNode<NodeData> | null>(null)
  const chartSettings = activeChart.chartSettings.getChartSettings()

  const viewEntireChart = useCallback(() => {
    const url = participantId
      ? UrlHelper.headcountPlanParticipantOrgChartPath(headcountPlanId, participantId)
      : UrlHelper.headcountPlanOwnerOrgChartPath(headcountPlanId)
    window.history.pushState({}, "", url)
    setChartViewId(null)
  }, [setChartViewId, headcountPlanId, participantId])

  const displayNodeAtTop = useCallback(
    (node: NodeData) => {
      if (node.klass === "Company") {
        viewEntireChart()
      } else if (node.id && !node.is_assistant) {
        const topId = node.id.toString()
        const url = participantId
          ? UrlHelper.headcountPlanParticipantOrgChartPath(headcountPlanId, participantId, topId)
          : UrlHelper.headcountPlanOwnerOrgChartPath(headcountPlanId, topId)
        window.history.pushState({}, "", url)
        setChartViewId(topId)
      }

      return null
    },
    [setChartViewId, viewEntireChart, headcountPlanId, participantId],
  )

  useEffect(() => {
    if (isFetching || !hcpPositions || !chartContainerRef.current || chart) return

    // Reset contents of org chart container
    const container = document.getElementById("organize-container")
    if (container) container.innerHTML = ""

    const stratify = d3Stratify().parentId((d): string | null | undefined => {
      const node = d as OrgchartPosition
      return node.parent_id
    })

    let positions = [...hcpPositions]
    if (participantId) {
      positions.push(topNode)
    } else {
      const roots = positions.filter((node) => !node.parent_id)
      const addTopNode = chartSettings.displayMode !== "three_level" || roots.length > 1
      if (addTopNode) {
        positions = positions.map((node) => {
          if (!node.parent_id) {
            return { ...node, parent_id: topNode.id }
          }
          return node
        })
        positions.push(topNode)
      }
    }

    let hierarchalData = stratify(positions) as HierarchyNode<NodeData>
    unwrapDataAndBuildMetaFromHierarchy(hierarchalData as ExtendedHierarchyNode, chartSettings)
    setChartData(hierarchalData)
    hierarchalData = cloneDeep(hierarchalData)
    if (chartViewId) {
      const top = hierarchalData?.find((n) => n.id === chartViewId)
      if (top) hierarchalData = top
    }
    if (hierarchalData) reassignAssistants(hierarchalData)

    const chartOptions = {
      ...chartSettings,
      jsonData: hierarchalData,
      loadAsync: true,
      positionsEndpoint,
      orgchartLite: true,
      dragAndDropEnabled: false,
      activeChart,
      displayTopFn: displayNodeAtTop,
    }

    const newChart = new OrgChart("#organize-container", chartOptions)
    newChart.loadFromJSON()
    setChart(newChart)
  }, [
    activeChart,
    chart,
    chartContainerRef,
    chartSettings,
    chartViewId,
    displayNodeAtTop,
    hcpPositions,
    isFetching,
    isParticipantPage,
    participantId,
    positionsEndpoint,
    topNode,
    setChart,
  ])

  useEffect(() => {
    if (!chart || !chart.getInitialized()) return

    let newChartData = cloneDeep(chartData)
    if (chartViewId) {
      const top = newChartData?.find((n) => n.id === chartViewId)
      if (top) newChartData = top
    }

    if (newChartData) reassignAssistants(newChartData)

    chart.replaceRoot(newChartData)
  }, [chart, chartData, chartSettings, chartViewId])

  const [mutate] = useSaveHeadcountPlanPositionMutation()
  const addAsChild = async (position: Position) => {
    const parsedEntity = tryParseEntityAndId(position.id)
    if (!parsedEntity) return

    const id = parsedEntity.entity === "position" ? parsedEntity.id : position.id

    const reportsTo = {
      id: id ?? "",
      name: position?.person?.name ?? "",
      title: position?.title ?? "",
      label: (position?.person?.name || position?.title || id) ?? "",
    }

    const result = await mutate({
      input: {
        action: "create_new",
        parentPositionId,
        headcountPlanId,
        headcountPlanParticipantId: participantId,
        revisionNumber: 0,
        payload: {
          title: { id: "custom", label: t("v2.headcount_plan.new_position_title") },
          effective_dates: { position_start_date: { id: "position_start_date", value: startDate } },
          reports_to: reportsTo,
        },
      },
    }).unwrap()

    if (result && chart) {
      const change = result.addChangeToHeadcountPlan
      const node = chart.find(position.id)
      if (node) {
        const child = node.addChild({
          id: `${change.changeEventId}-${change.changeEventRootId}`,
          title: t("v2.headcount_plan.new_position_title"),
          parent_id: position.id,
        })
        chart.focus(child.id)
      }
    }
  }

  return { addAsChild }
}

export { useOrgChart }
