import { setCookie } from "v2/react/utils/cookies"

interface UseDisplayStateMethodsProps {
  setDisplayState?: (state: "original" | "approved") => void
  headcountPlanId: string
  setIsOpen: (isOpen: boolean) => void
}

const useDisplayStateMethods = ({
  setDisplayState,
  headcountPlanId,
  setIsOpen,
}: UseDisplayStateMethodsProps): { showOriginalView: () => void; showApprovedView: () => void } => {
  const showOriginalView = () => {
    setDisplayState?.("original")
    setCookie(`built__display-state-for-hcp-${headcountPlanId}`, "original", 5 * 365)
    setIsOpen(false)
  }

  const showApprovedView = () => {
    setDisplayState?.("approved")
    setCookie(`built__display-state-for-hcp-${headcountPlanId}`, "approved", 5 * 365)
    setIsOpen(false)
  }

  return { showOriginalView, showApprovedView }
}

export { useDisplayStateMethods }
