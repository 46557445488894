import { zodResolver } from "@hookform/resolvers/zod"
import { isEmpty } from "lodash"
import React, { useCallback } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Banners } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/SendToAtsForm/Banners"
import { MissingRequisitionFields } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/SendToAtsForm/MissingRequisitionFields"
import { PositionsFields } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/SendToAtsForm/PositionsFields"
import { SetRequisitionFields } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/SendToAtsForm/SetRequisitionFields"
import { BannerError } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/SendToAtsModal"
import {
  SmartRecruitersFormSchema,
  SmartRecruitersFormType,
} from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/types"
import { useErrorsForPresetFormFields } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/useBannerErrors"
import {
  handleServerErrors,
  type ProcessedData,
} from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/utils"
import { Submit } from "v2/react/components/jobRequisitions/SendToAts/Submit"
import { useNProgressBar } from "v2/react/hooks/useNProgressBar"
import { FormStyleProvider } from "v2/react/shared/forms/FormStyleProvider"
import { useSendToSmartRecruitersMutation } from "v2/redux/GraphqlApi/JobRequisitionsApi"

interface SendToAtsFormProps {
  initialData: ProcessedData
  closeModal: () => void
  bannerErrors: BannerError[]
  updateBannerErrors: (errors: BannerError[]) => void
}

const SendToAtsForm = ({
  initialData,
  closeModal,
  bannerErrors,
  updateBannerErrors,
}: SendToAtsFormProps) => {
  const { t } = useTranslation()
  const [sendToSmartRecruiters, { isLoading }] = useSendToSmartRecruitersMutation()

  const methods = useForm({
    defaultValues: initialData.formData,
    resolver: zodResolver(SmartRecruitersFormSchema),
  })

  const {
    formState: { errors, isSubmitting, defaultValues },
    handleSubmit,
    setError,
  } = methods

  useErrorsForPresetFormFields({
    errors,
    defaultValues,
    updateBannerErrors,
    t,
  })

  useNProgressBar({
    finishCondition: !isSubmitting && !isLoading,
    startCondition: isSubmitting,
  })

  const onSubmit: SubmitHandler<SmartRecruitersFormType> = useCallback(
    async (input) => {
      const response = await sendToSmartRecruiters(input)

      let jobRequisition
      if ("data" in response) {
        const responseErrors = response.data.sendToSmartRecruiters.errors
        jobRequisition = response.data.sendToSmartRecruiters.jobRequisition

        if (!!responseErrors && !isEmpty(responseErrors)) {
          handleServerErrors({ errors: responseErrors, setError, updateBannerErrors })
          return
        }
      }

      // This indicates an unhandled error.
      if ("errors" in response || !jobRequisition) {
        updateBannerErrors([
          {
            key: "generic",
            message: t("v2.job_requisitions.modals.send_to_ats.smart_recruiters.generic_error"),
          },
        ])
        return
      }

      // If we made it here, we successfully created both the job and
      // positions in SmartRecruiters.
      closeModal()
    },
    [sendToSmartRecruiters, setError, updateBannerErrors, closeModal, t],
  )

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <FormStyleProvider defaultErrorIconPlacement="top">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="react-modal__body flex-col gap-6 flex">
            <Banners bannerErrors={bannerErrors} />
            <MissingRequisitionFields atsOptions={initialData.auxiliary.atsOptions} />
            <SetRequisitionFields auxiliaryData={initialData.auxiliary} />
            <PositionsFields positions={initialData.auxiliary.positions} />
          </div>
          <div className="react-modal__footer">
            <Submit isSubmitting={isSubmitting} onCancelClick={closeModal} useProgressBar={false} />
          </div>
        </form>
      </FormStyleProvider>
    </FormProvider>
  )
}

export type { SendToAtsFormProps }
export { SendToAtsForm }
