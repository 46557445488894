import { useEffect, useState } from "react"

/**
 * Custom hook to detect when an element becomes sticky (when it is pinned to the top of the viewport or root element)
 * @param {React.RefObject<HTMLElement>} ref - A reference to the sticky element you want to observe
 * @param {IntersectionObserverInit} options - Optional configuration for the Intersection Observer, such as threshold or root
 * @returns {boolean} - Returns a boolean indicating whether the element is sticky (pinned) or not.
 *
 * Note: this works in conjunction with setting "top: -1px" on the sticky element.
 * In short, "top: 0" keeps the element fully visible, so the observer doesn't detect any change.
 * "top: -1px" pushes the element slightly out of view, which makes the observer think it has become sticky
 */
const useDetectSticky = (ref: React.RefObject<HTMLElement>, options: IntersectionObserverInit) => {
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    if (!ref || !ref.current) return undefined
    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(e.intersectionRatio < 0.99),
      options,
    )

    observer.observe(cachedRef)

    return () => {
      observer.unobserve(cachedRef)
    }
  }, [ref, options])

  return isSticky
}

export { useDetectSticky }
