import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { motion } from "framer-motion"
import React, { RefObject } from "react"
import { GridCellRangeProps } from "react-virtualized"

import { FieldType } from "v2/react/components/orgChart/Datasheet/types"
import {
  selectionColor,
  updatePositionStyles,
} from "v2/react/components/orgChart/Datasheet/utils/styles"
import { useDatasheetCellCursor } from "v2/react/components/orgChart/OrgChartDatasheet/hooks/useDatasheetCellCursor"
import { inEitherWriteState, onNothing } from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import { selectCellErrorMessage } from "v2/redux/slices/NodeSlice/nodeSelectors"
import { useAppSelector } from "v2/redux/store"

interface ActiveCursorProps {
  beaconRef: RefObject<HTMLButtonElement>
  sheetRef?: RefObject<{
    contains: (element: Node | null) => boolean
    scrollToCell: (params: { rowIndex: number; columnIndex: number }) => void
  }>
  styleCache: GridCellRangeProps["styleCache"]
}

function ActiveCursor({ beaconRef, sheetRef, styleCache }: ActiveCursorProps) {
  const { cursor, rowIndex, columnIndex } = useDatasheetCellCursor({ beaconRef, sheetRef })

  const fieldsLength = useAppSelector((state) => state.container.fields.length)
  const hasError = useAppSelector((state) =>
    onNothing(cursor)
      ? false
      : !!selectCellErrorMessage(state, cursor.rowId, cursor.columnId, columnIndex === 0),
  )

  // The style cache may not have an entry for the cell that the cursor is on.
  // This is expected and ok. It happens when the cell is not being rendered
  // by the virtualized container (virtualized containers only render a subset
  // of their children).
  const styleOfTargetCell = styleCache[`${rowIndex}-${columnIndex}`]
  if (!cursor || onNothing(cursor) || !styleOfTargetCell) return null

  const style = getStyle({
    cursorStyle: styleOfTargetCell,
    editable: cursor.editable,
    hasError,
    inWrite: inEitherWriteState(cursor),
    isLastCol: fieldsLength === Number(columnIndex) + 1,
    isTopRow: rowIndex === 0,
  })

  return (
    <motion.div
      id="cell-cursor"
      style={style}
      animate={{ left: style.left, top: style.top }}
      transition={{ ease: "easeInOut", duration: cursor.enteredBy !== "placement" ? 0.1 : 0 }}
    >
      {cursor.fieldType === FieldType.SelectDropdown ? (
        <motion.div
          key="caret"
          animate={{ opacity: 1, rotate: inEitherWriteState(cursor) ? "180deg" : "0deg" }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          style={{ translateY: "-50%", translateX: "50%" }}
          transition={{ opacity: { delay: 0.1, duration: 0.05 }, rotate: { duration: 0.2 } }}
          className={classNames("GridBody-cell__caret")}
        >
          <FontAwesomeIcon style={{ color: style.borderColor }} icon={["fas", "caret-down"]} />
        </motion.div>
      ) : null}
      {!inEitherWriteState(cursor) ? (
        <motion.div
          key="handle"
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="GridBody-cell__selected-handle"
          style={{
            bottom: 0,
            right: 0,
            width: 6,
            height: 6,
            backgroundColor: style.borderColor,
            transform: "translate(50%, 50%)",
            position: "absolute",
          }}
        />
      ) : null}
    </motion.div>
  )
}

type GetStyleArg = {
  editable: boolean
  hasError: boolean
  inWrite: boolean
  isTopRow: boolean
  isLastCol: boolean
  cursorStyle: React.CSSProperties
}

const getStyle = ({
  editable,
  hasError,
  inWrite,
  isTopRow,
  isLastCol,
  cursorStyle,
}: GetStyleArg) => {
  const preStyle = {
    left: Number(cursorStyle.left),
    width: Number(cursorStyle.width),
    top: Number(cursorStyle.top),
    height: Number(cursorStyle.height),
  }

  return {
    ...preStyle,
    ...updatePositionStyles(isTopRow, isLastCol, preStyle),
    borderColor: selectionColor(editable, hasError),
    borderWidth: inWrite ? 2 : 1,
    borderStyle: "solid",
    pointerEvents: "none",
    position: "absolute",
    zIndex: 100,
  } as React.CSSProperties
}

export { ActiveCursor }
