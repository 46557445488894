import * as d3 from "d3"

import {
  generateXAxis,
  generateYAxis,
  moveTooltip,
  roundedRectPath,
} from "v2/dashboards/charts/utils"

function divergingBarChart({ data, options = {} }) {
  const {
    xAxisLabel = "",
    yAxisLabel = "",
    margin = { top: options.yAxisLabel ? 20 : 8, right: 4, bottom: 20, left: 25 },
    chartWidth = null,
    chartHeight = null,
    axisColor = "#63698C", // $color-neutral-64-solid
    axisGridLineColor = "rgba(237, 237, 242, 1)", // $color-neutral-8-solid
    fontFamily = "Satoshi, sans-serif",
    useGridLines = true,
    elementId = "diverging-stacked-bar-chart",
    positiveLabel = null,
    negativeLabel = null,
    netLabel = null,
    positiveColor = "#8DB0FF", // $color-primary-50-solid
    negativeColor = "#FFA7BE", // $color-accent-flamingo-solid
    minTickSpacing = 40,
    barRadius = 4,
    fallBackYAxisMax = 6,
    fallBackYAxisMin = 6,
  } = options

  const element = document.getElementById(elementId)

  // Compute width and height from the element's client dimensions.
  const width = chartWidth || element?.clientWidth || 800
  const height = chartHeight || element?.clientHeight || 400

  // Define the two series keys.
  const keys = ["positive", "negative"]
  // Base colors for each series:
  const baseColors = {
    positive: positiveColor,
    negative: negativeColor,
  }

  // Set up the stack generator with a custom value accessor.
  const stack = d3
    .stack()
    .keys(keys)
    .value((d, key) => (key === "negative" ? -d.negative : d.positive))
    .offset(d3.stackOffsetDiverging)

  const series = stack(data)

  // Set y-domain to be from –negative (negative side) to positive (positive
  // side).
  let max = d3.max(data, (d) => d.positive) || 0
  let min = d3.max(data, (d) => d.negative) || 0
  if (max === 0 && min === 0) {
    max = fallBackYAxisMax
    min = fallBackYAxisMin
  }
  // If the max on either side is 1, bump up the y-domain to 2.
  if (max === 1) max = 2
  if (min === 1) min = 2

  // We potentially manually set the number of ticks based on the range of the
  // y-axis.  This is done to ensure that the y-axis labels are not decimals.
  let numTicks = height / minTickSpacing
  if (max + min <= 4) {
    numTicks = 4
  }
  if (max + min <= 2) {
    numTicks = 2
  }

  const yDomain = [-min, max]

  // Create scales.
  const x = d3
    .scaleBand()
    .domain(data.map((d) => d.label))
    .range([margin.left, width - margin.right])
    .padding(0.1)

  const y = d3
    .scaleLinear()
    .domain(yDomain)
    .nice(numTicks)
    .range([height - margin.bottom, margin.top])

  // Create the SVG container.
  const svg = d3
    .create("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [0, 0, width, height])
    .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
    .style("font", fontFamily)

  // -------------------------
  // Y-Axis.
  // -------------------------
  generateYAxis(
    svg,
    y,
    margin,
    height,
    width,
    axisColor,
    fontFamily,
    yAxisLabel,
    axisGridLineColor,
    useGridLines,
    minTickSpacing,
    numTicks,
  )

  // -------------------------
  // Defs:
  // Create a linear gradient for the overlay.
  // -------------------------
  const defs = svg.append("defs")
  // Gradient for positive bars
  const positiveOverlayGradient = defs
    .append("linearGradient")
    .attr("id", "bar-overlay-gradient-positive")
    .attr("x1", "0%")
    .attr("y1", "0%")
    .attr("x2", "0%")
    .attr("y2", "100%")
  positiveOverlayGradient.append("stop").attr("offset", "0%").attr("stop-color", "#FFFFFF3D")
  positiveOverlayGradient.append("stop").attr("offset", "100%").attr("stop-color", "#FFFFFF00")

  // Gradient for negative bars
  const negativeOverlayGradient = defs
    .append("linearGradient")
    .attr("id", "bar-overlay-gradient-negative")
    .attr("x1", "0%")
    .attr("y1", "0%")
    .attr("x2", "0%")
    .attr("y2", "100%")
  negativeOverlayGradient.append("stop").attr("offset", "0%").attr("stop-color", "#FFFFFF00")
  negativeOverlayGradient.append("stop").attr("offset", "100%").attr("stop-color", "#FFFFFF3D")

  // Create tooltip
  let tooltip = d3.select("#content").select("#diverging-bar-chart-tooltip")
  if (tooltip.empty()) {
    tooltip = d3
      .select("#content")
      .append("div")
      .attr("id", "diverging-bar-chart-tooltip")
      .attr("class", "tooltip")
      .classed("hidden", true)
      .style("opacity", 0)
      .style("position", "absolute")
      .style("pointer-events", "none")
  }

  // -------------------------
  // Bars: Draw the bars in a group.
  // -------------------------
  const barsGroup = svg.append("g").attr("id", "barsGroup")

  series.forEach((seriesItem) => {
    const seriesKey = seriesItem.key
    const baseColor = baseColors[seriesKey]
    seriesItem.forEach((d) => {
      const rectW = Math.min(x.bandwidth(), 30)
      const xPos = x(d.data.label) + (x.bandwidth() - rectW) / 2
      const y0 = y(d[0])
      const y1 = y(d[1])
      const rectY = Math.min(y0, y1)
      const rectH = Math.abs(y1 - y0)
      const roundTop = seriesKey === "positive"

      // Generate the path for this bar segment.
      const pathD = roundedRectPath(xPos, rectY, rectW, rectH, barRadius, roundTop)

      // Draw the base bar with its base color.
      const baseBar = barsGroup
        .append("path")
        .attr("d", pathD)
        .attr("fill", baseColor)
        .attr("class", `bar-${d.data.id} ${seriesKey}`)

      // Attach the tooltip.
      baseBar
        .on("mouseover", (event) => {
          showTooltip(event, d, tooltip, svg, positiveLabel, negativeLabel, netLabel)
        })
        .on("mouseout", () => {
          hideTooltip(tooltip)
        })

      const overlayId =
        seriesKey === "positive"
          ? "url(#bar-overlay-gradient-positive)"
          : "url(#bar-overlay-gradient-negative)"

      // Draw the overlay with the gradient.
      barsGroup
        .append("path")
        .attr("d", pathD)
        .attr("fill", overlayId)
        .attr("stroke", "none")
        .attr("pointer-events", "none")
    })
  })

  // -------------------------
  // X-Axis - rendered last so it's on top of the bars.
  // -------------------------
  generateXAxis(svg, x, width, height, margin, axisColor, fontFamily, minTickSpacing, xAxisLabel, y)

  return svg.node()
}

function makeTooltipContent(d, positiveLabel, negativeLabel, netLabel) {
  return `
    <div class="react-tooltip-content react-tooltip-content--light min-w-[184px] flex-col gap-0 p-0 text-sm flex">
      <div class="p-3">
        <span class="text-sm-bold">${d.data.fullLabel || d.data.label}</span>
      </div>
      <hr class="my-0 w-full" />
      <div class="flex-col gap-2 p-3 flex">
        <div class="flex-row justify-between gap-2 flex">
          <span class="text-sm-bold">${positiveLabel}</span>
          <span>${d.data.positive}</span>
        </div>
        <div class="flex-row justify-between gap-2 flex">
          <span class="text-sm-bold">${negativeLabel}</span>
          <span>${d.data.negative > 0 ? "-" : ""}${d.data.negative}</span>
        </div>
      </div>
      <hr class="my-0 w-full" />
      <div class="p-3">
        <div class="flex-row justify-between gap-2 flex">
          <span class="text-sm-bold">${netLabel}</span>
          <span class="text-sm-bold">${d.data.positive - d.data.negative}</span>
        </div>
      </div>
    </div>
  `
}

function showTooltip(event, d, tooltip, svg, positiveLabel, negativeLabel, netLabel) {
  const tooltipContent = makeTooltipContent(d, positiveLabel, negativeLabel, netLabel)
  tooltip.html(tooltipContent)
  tooltip.classed("hidden", false)
  moveTooltip(event, tooltip, svg, "positive")
  tooltip.transition().duration(200).style("opacity", 1)
}

function hideTooltip(tooltip) {
  tooltip
    .transition()
    .duration(200)
    .style("opacity", 0)
    .on("end", () => {
      tooltip.classed("hidden", true)
    })
}

export default divergingBarChart
