import { z } from "zod"

const ReqTypeSchema = z.union([z.literal("NEW"), z.literal("REPLACEMENT")])

/**
 * @see: app/graphql/mutations/job_requisitions/send_to_smart_recruiters.rb
 */
const SmartRecruitersFormSchema = z.object({
  jobRequisitionId: z.string().nullish(),
  positionId: z.string().nullish(),
  jobTitle: z.string(),
  sourceOpenings: z.number(),
  jobDescription: z.string().nullish(),
  job: z.object({
    title: z.string(),
    refNumber: z.string().max(63, { message: "ref_number_max_length" }).nullish(),
    locationId: z.string().min(1, { message: "location_required" }),
    industry: z.object({
      id: z.string().min(1, { message: "industry_required" }),
      label: z.string().min(1, { message: "industry_required" }),
    }),
    function: z.object({
      id: z.string().min(1, { message: "function_required" }),
      label: z.string().min(1, { message: "function_required" }),
    }),
    experienceLevel: z.object({
      id: z.string().min(1, { message: "experience_level_required" }),
      label: z.string().min(1, { message: "experience_level_required" }),
    }),
    jobDescription: z.string().max(24999, { message: "job_description_max_length" }).nullish(),
    departmentId: z.string().nullish(),
  }),
  positionOpenings: z.object({
    type: ReqTypeSchema,
    positionOpenDate: z
      .string()
      .date("position_open_date_required")
      .min(1, { message: "position_open_date_required" }),
    targetStartDate: z
      .string()
      .date("target_start_date_required")
      .min(1, { message: "target_start_date_required" }),
  }),
})

type SmartRecruitersFormType = z.infer<typeof SmartRecruitersFormSchema>

/**
 * Types the data from the req's JSON Form data and massages it into a
 * a nice shape for use with the SmartRecruiters SendToAts form.
 */
const ReqJsonFormDataSchema = z
  .object({
    reqType: z.union([z.literal("new_position"), z.literal("backfill")]),
    position: z.object({
      projectedHireDate: z.preprocess((arg) => {
        if (typeof arg === "string" && arg.trim() === "") {
          return undefined
        }
        return arg
      }, z.string().date().optional()),
      location: z
        .object({
          id: z.string(),
          name: z.string(),
        })
        .optional(),
      orgUnits: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          typeId: z.string(),
          typeName: z.string(),
        }),
      ),
    }),
  })
  .transform(({ reqType, position: { projectedHireDate, location, orgUnits } }) => {
    const department = orgUnits.find((unit) => unit.typeName === "Department")
    const parsedProjectedHireDate = z.string().date().safeParse(projectedHireDate)

    return {
      reqType: ReqTypeSchema.parse(reqType === "backfill" ? "REPLACEMENT" : "NEW"),
      projectedHireDate: parsedProjectedHireDate.success ? parsedProjectedHireDate.data : "",
      location,
      department: department
        ? {
            id: department.id,
            label: department.name,
          }
        : undefined,
    }
  })

type ReqJsonFormDataType = z.infer<typeof ReqJsonFormDataSchema>

export type { SmartRecruitersFormType, ReqJsonFormDataType }
export { SmartRecruitersFormSchema, ReqJsonFormDataSchema }
