/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table } from "@tanstack/react-table"
import React from "react"

import { EditingCellHandle, useEditingCellRegistry } from "./hooks/useEditingCellRegistry"

type DatasheetContext = {
  table: Table<any>
  registerEditingCell: (ref: React.RefObject<EditingCellHandle>) => void
  unregisterEditingCell: () => void
}

const DatasheetContext = React.createContext<DatasheetContext>({
  table: {} as Table<any>,
  registerEditingCell: () => null,
  unregisterEditingCell: () => null,
})

export function DatasheetContextProvider<TRow extends { id: string }>({
  table,
  children,
}: {
  table: Table<TRow>
  children: React.ReactNode
}) {
  const { registerEditingCell, unregisterEditingCell } = useEditingCellRegistry()

  const value = React.useMemo(
    () => ({
      table,
      registerEditingCell,
      unregisterEditingCell,
    }),
    [table, registerEditingCell, unregisterEditingCell],
  )

  return <DatasheetContext.Provider value={value}>{children}</DatasheetContext.Provider>
}

export function useDatasheetContext() {
  return React.useContext(DatasheetContext)
}
