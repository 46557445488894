import fp from "lodash/fp"

import type { Collection, Maybe } from "types/graphql"
import { BasePay, SourcePay, VariablePayModel } from "types/graphql.enums"
import type { NodeRow } from "v2/react/components/orgChart/Datasheet/types"
import { variablePayRegex, variablePayTypeRegex } from "v2/react/utils/regex"
import { isEncodedInitialData } from "v2/redux/slices/GridSlice/gridHelpers/orgUnits"
import { isNameModal, isSeatChangeModal } from "v2/redux/slices/GridSlice/gridHelpers/people"
import type { EnhancedNodeInterface, FieldKey } from "v2/redux/slices/NodeSlice/types"

interface FollowUpConditionalProps {
  fieldKey: FieldKey
  row: NodeRow<EnhancedNodeInterface>
  value?: Maybe<string>
  collection?: Collection
}

const isVariablePayType = (fieldKey: string) => variablePayTypeRegex.test(fieldKey)
const isVariablePayAmount = (fieldKey: string) => variablePayRegex.test(fieldKey)
const basePayTypeCast = (payType: unknown) =>
  payType === BasePay.Hourly ? BasePay.Hourly : BasePay.Salary
const variablePayTypeCast = (payType: unknown) =>
  payType === SourcePay.Amount ? SourcePay.Amount : SourcePay.Percent

const isNewVariablePayAmount = (
  fieldKey: FieldKey,
  row: NodeRow<EnhancedNodeInterface>,
  modelType: VariablePayModel,
) => {
  if (isVariablePayAmount(fieldKey)) {
    if (getModelTypeFromVariablePay(row, fieldKey) === modelType) {
      return !hasPreviousVariablePay(fieldKey, row)
    }
  }
  return false
}

const variablePayTypeChanged = (
  fieldKey: FieldKey,
  row: NodeRow<EnhancedNodeInterface>,
  modelType: VariablePayModel,
) =>
  isVariablePayType(fieldKey) ? getModelTypeFromVariablePay(row, fieldKey) === modelType : false

const variablePayAmountChanged = (
  fieldKey: FieldKey,
  row: NodeRow<EnhancedNodeInterface>,
  modelType: VariablePayModel,
) =>
  isVariablePayAmount(fieldKey) ? getModelTypeFromVariablePay(row, fieldKey) === modelType : false

const hasPreviousVariablePay = (fieldKey: FieldKey, row: NodeRow<EnhancedNodeInterface>) =>
  !!fp.find(fp.propEq("field_id", fieldKey), row.data.variable_pays_original)?.id

const hasPreviousPositionBasePay = (row: NodeRow<EnhancedNodeInterface>) =>
  !!row.data.position_base_pay

const isNewPositionBasePay = (fieldKey: FieldKey, row: NodeRow<EnhancedNodeInterface>) =>
  fp.includes(fieldKey)(["position_base_pay", "position_hours_per_week"]) &&
  !hasPreviousPositionBasePay(row)

const getModelTypeFromVariablePay = (row: NodeRow<EnhancedNodeInterface>, fieldKey: FieldKey) =>
  row.data?.variable_pays_original?.find((vp: { field_id: string }) => vp.field_id === fieldKey)
    ?.model_type

const hasBudgetedPayFollowUp = ({ fieldKey, row }: FollowUpConditionalProps) =>
  fp.anyPass([
    (fieldKey: FieldKey) => isNewVariablePayAmount(fieldKey, row, VariablePayModel.Position),
    (fieldKey: FieldKey) => variablePayTypeChanged(fieldKey, row, VariablePayModel.Position),
    (fieldKey: FieldKey) => isNewPositionBasePay(fieldKey, row),
    fp.isEqual("position_base_pay_type"),
  ])(fieldKey)

const hasEmployeeTypeFollowUp = ({ fieldKey, value, collection }: FollowUpConditionalProps) => {
  if (fieldKey !== "employee_status" || !value || !collection) return false

  const terminationStatuses = fp.filter(
    (status) =>
      status.systemType && ["terminated", "termination_scheduled"].includes(status.systemType),
    collection.options.nodes,
  )
  const terminationIds = fp.map("id", terminationStatuses)

  return terminationIds.includes(value)
}

const hasTitleChangeFollowUp = ({ fieldKey }: FollowUpConditionalProps) =>
  fp.isEqual("title")(fieldKey)

const hasCompensationFollowUp = ({ fieldKey, row }: FollowUpConditionalProps) =>
  fp.anyPass([
    (fieldKey: FieldKey) => variablePayTypeChanged(fieldKey, row, VariablePayModel.Compensation),
    (fieldKey: FieldKey) => variablePayAmountChanged(fieldKey, row, VariablePayModel.Compensation),
    fp.isEqual("base_pay_type"),
    fp.isEqual("base_pay"),
    fp.isEqual("hours_per_week"),
  ])(fieldKey)

const hasOrgUnitFollowUp = ({ fieldKey, value }: FollowUpConditionalProps) =>
  fp.startsWith("org_unit_type", fieldKey) && isEncodedInitialData(value)

const hasNameFollowUp = ({ fieldKey, value }: FollowUpConditionalProps) =>
  fp.isEqual("name")(fieldKey) && isNameModal(value)

const hasSeatChangeFollowUp = ({ fieldKey, value }: FollowUpConditionalProps) =>
  fp.isEqual("name")(fieldKey) && isSeatChangeModal(value)

export {
  basePayTypeCast,
  hasBudgetedPayFollowUp,
  hasCompensationFollowUp,
  hasEmployeeTypeFollowUp,
  hasNameFollowUp,
  hasOrgUnitFollowUp,
  hasPreviousPositionBasePay,
  hasPreviousVariablePay,
  hasSeatChangeFollowUp,
  hasTitleChangeFollowUp,
  isVariablePayAmount,
  isVariablePayType,
  variablePayTypeCast,
}
