import React from "react"
import { useTranslation } from "react-i18next"

import { VirtualChartPositionNode } from "types/graphql.d"
import { CompareValues } from "v2/react/components/headcountPlanning/TableDatasheet/CompareValues"
import { ForcedAutocompleteCell } from "v2/react/components/headcountPlanning/TableDatasheet/ForcedAutocompleteCell"
import { StrikethroughCell } from "v2/react/components/headcountPlanning/TableDatasheet/StrikethroughCell"
import { SuggestedAutocompleteCell } from "v2/react/components/headcountPlanning/TableDatasheet/SuggestedAutocompleteCell"
import PersonSearchResult, {
  maybeShowEmployeeIds,
  maybeShowPositionId,
  positionIdDisplay,
} from "v2/react/shared/forms/PersonSearchResult"
import { useSearchSubordinatePositionsQuery } from "v2/redux/GraphqlApi"

import { useSaveCell } from "../hooks/useSaveCell"
import { HeadcountPlanDatasheetRow } from "./types"

type ReportsToCellProps = {
  forEntireOrg: boolean
  row: HeadcountPlanDatasheetRow
  headcountPlanId: string
  participantId?: string
  readOnly: boolean
  onSaved?: () => void
  onErrored?: () => void
}
export function ReportsToCell({
  forEntireOrg,
  row,
  headcountPlanId,
  participantId,
  onSaved,
  onErrored,
  readOnly,
}: ReportsToCellProps) {
  const currentValue = row.positionAttributesWithEdits?.reports_to ?? {}
  const [searchTerm, setSearchTerm] = React.useState<string>(currentValue?.label ?? "")
  const [skipQuery, setSkipQuery] = React.useState<boolean>(true)
  const query = useSearchSubordinatePositionsQuery(
    {
      headcountPlanId,
      positionIdToExclude: row.position ? row.position.id : null,
      eventUniqueKeyToExclude: row.position ? null : row.rootEventUniqueKey,
      participantId,
      searchTerm,
    },
    { skip: skipQuery },
  )

  const nodes = query.data ?? []

  const saveFn = useSaveCell<VirtualChartPositionNode>(
    headcountPlanId,
    "reports_to",
    row,
    participantId,
  )

  const compareValue =
    row.type === "modified" && "reports_to" in row.payload
      ? row.positionAttributes.reports_to
      : null

  const createOption = React.useCallback(
    (option: { label: string }) => ({
      id: "custom",
      label: option.label,
      title: option.label,
      name: option.label,
    }),
    [],
  )

  if (row.excluded) {
    return <StrikethroughCell value={currentValue?.label ?? ""} />
  }

  if (readOnly) {
    return (
      <CompareValues compareValue={compareValue?.label} className="non-editable-cell">
        <span>{currentValue?.label ?? ""}</span>
      </CompareValues>
    )
  }

  return forEntireOrg ? (
    <SuggestedAutocompleteCell
      columnId="reports_to"
      rowId={row.id}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      options={nodes}
      renderOption={(node) => <RenderOption node={node} searchTerm={searchTerm} />}
      disableOption={(option) => typeof option.positionEndDate === "string"}
      saveFn={(value) =>
        saveFn({ id: value?.id, label: value?.label, title: value?.title, name: value?.name })
      }
      onSaved={onSaved}
      onErrored={onErrored}
      onEditing={() => setSkipQuery(false)}
      currentValue={currentValue}
      compareValue={compareValue}
      createOption={createOption}
    />
  ) : (
    <ForcedAutocompleteCell
      columnId="reports_to"
      rowId={row.id}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      options={nodes}
      notFoundMsg="Unable to find or access a person by this name."
      renderOption={(node) => <RenderOption node={node} searchTerm={searchTerm} />}
      disableOption={(option) => typeof option.positionEndDate === "string"}
      saveFn={(value) =>
        saveFn({ id: value?.id, label: value?.label, title: value?.title, name: value?.name })
      }
      onSaved={onSaved}
      onErrored={onErrored}
      onEditing={() => setSkipQuery(false)}
      currentValue={currentValue}
      compareValue={compareValue}
    />
  )
}

type RenderOptionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  node: any
  searchTerm: string
}
function RenderOption({ node, searchTerm }: RenderOptionProps) {
  const { t } = useTranslation()
  const positionIsFilled = typeof node.name !== "undefined"

  return (
    <PersonSearchResult
      avatarUrl={node.avatar || ""}
      includeListItemClass={false}
      personName={node.name}
      positionTitle={node.title}
      positionIdDisplay={
        positionIsFilled
          ? maybeShowPositionId(node.systemIdentifier, searchTerm, t)
          : positionIdDisplay(node.systemIdentifier, t)
      }
      employeeIdDisplay={maybeShowEmployeeIds(node.person ? [node.person] : null, searchTerm, t)}
    />
  )
}
