import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Maybe } from "types/graphql"
import {
  FallbackOptionProps,
  SuggestedAutocompleteCell,
} from "v2/react/components/headcountPlanning/TableDatasheet/SuggestedAutocompleteCell"
import { prepareStyle } from "v2/react/components/orgChart/Datasheet/Cell/utils"
import { Column, CursorConnection, NodeRow } from "v2/react/components/orgChart/Datasheet/types"
import { usePositionTypesAutocompleteQueryWithState } from "v2/react/components/positionTypes/hooks/usePositionTypesAutocompleteQueryWithState"
import {
  toEncodedIdNamePair,
  toEncodedInitialData,
} from "v2/redux/slices/GridSlice/gridHelpers/orgUnits"

type PositionTypeAutocompleteProps<TNode, CType> = {
  column: Column<CType>
  currentValue?: string
  cursorConnection: CursorConnection
  isFirst: boolean
  isLast: boolean
  row: NodeRow<TNode>
  style: React.CSSProperties
}

export function prepareValue(arg: Maybe<{ id: string; label: string }> | undefined) {
  if (!arg || arg.label === "") {
    return ""
  }
  if (arg.id.includes("custom")) {
    return toEncodedInitialData(arg.label)
  }
  return toEncodedIdNamePair(arg.id, arg.label)
}

/**
 * Presents an autocomplete for the position type along with an action to add a
 * new position type.
 *
 * @public
 */
function PositionTypeAutocomplete<TNode, CType>({
  column,
  currentValue,
  cursorConnection,
  isFirst,
  isLast,
  row,
  style,
}: PositionTypeAutocompleteProps<TNode, CType>) {
  const [inputValue, setInputValue] = useState(
    cursorConnection.initialWriteValue ?? currentValue ?? "",
  )

  const { positionTypes } = usePositionTypesAutocompleteQueryWithState({
    currentTerm: currentValue ?? "",
    searchTerm: inputValue,
  })

  const options = positionTypes.map(({ jobCodeTitleLabel, uniqueKey }) => ({
    id: uniqueKey,
    label: jobCodeTitleLabel,
  }))

  // NOTE: some logic must be duplicated in the `useCellState` call in `OrgChartDatasheetCell`
  // This is because this cell will get replaced with `DisplayValueCell` when the cursor moves
  // away, so the save would never complete.

  return (
    <SuggestedAutocompleteCell
      editable
      cellInputRef={cursorConnection.cellInputRef}
      options={options}
      columnId={String(column.fieldKey)}
      saveFn={(option) => cursorConnection.saveFn(prepareValue(option))}
      renderFallbackOption={({ saveCustom }) => (
        <FallbackOption saveCustom={saveCustom} columnLabel={column.label} />
      )}
      className={cn("GridBody-cell Cell__select-field bg-transparent", { last: isLast })}
      currentValue={{ id: "", label: currentValue ?? "" }}
      searchTerm={inputValue}
      setSearchTerm={setInputValue}
      rowId={row.id}
      style={{ ...prepareStyle(style, isFirst, row.color), padding: 0 }}
      renderOption={(option) => <div>{option.label}</div>}
      hasNotChanged={(value, oldValue) =>
        value.id !== "custom:fallbackClick" && value.label === oldValue?.label
      }
      createOption={(optionInfo) => ({
        id: `custom:${optionInfo.createdBy}`,
        label: optionInfo.label,
      })}
    />
  )
}

function FallbackOption({
  saveCustom,
  columnLabel,
}: FallbackOptionProps & { columnLabel: string }) {
  const { t } = useTranslation()
  return (
    <div className="AutocompleteField__footer align-center justify-center border-none px-4 py-2 flex">
      <button className="btn--sm btn--primary" onMouseDown={saveCustom} type="button">
        <FontAwesomeIcon icon={["far", "plus"]} />
        {t("v2.defaults.add_entity", { entity: columnLabel })}
      </button>
    </div>
  )
}

export { PositionTypeAutocomplete, PositionTypeAutocompleteProps }
