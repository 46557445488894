import React from "react"

import { SearchableDropdown } from "v2/react/shared/forms/SearchableDropdown"
import { SelectDropdown } from "v2/react/shared/forms/SelectDropdown"

type DynamicItem = {
  id: string
  label: string
}

interface Props<T extends DynamicItem> {
  items: T[]
  selected?: T
  onSelect?: (value: T) => void
  onClear?: () => void
  placeholder?: string
  label?: string
  id?: string
  limit?: number
  itemCount?: number
  error?: string
}

/**
 * Returns a standard dropdown menu if there are less than `limit` items,
 * otherwise returns a searchable dropdown menu.
 *
 * Used to optimize the user experience when selecting from dynamic lists.
 *
 * Note: For now this component assumes all of the data is loaded on the client.
 * In the future, we will need to update this component to support server-side
 * pagination/searching a là `MultiSelectionFilterWithSearch`.
 */
const DynamicDropdownInput = <T extends DynamicItem>({
  items,
  selected,
  onSelect,
  onClear,
  placeholder,
  label,
  id,
  limit = 10,
  itemCount,
  error,
}: Props<T>) => {
  const handleSelect = (value: T) => {
    onSelect?.(value)
  }
  const totalCount = itemCount ?? items.length

  if (totalCount <= limit) {
    return (
      <SelectDropdown<T>
        id={id}
        label={label}
        options={items}
        selectedId={selected?.id}
        onClick={handleSelect}
        error={error}
      />
    )
  }

  return (
    <SearchableDropdown<T>
      id={id ?? ""}
      label={label}
      items={items}
      selectedItem={selected}
      onSelect={handleSelect}
      onClear={onClear}
      searchPlaceholder={placeholder}
      useIcon={false}
      usePlaceholder={false}
      matchReferenceWidth={false}
      clearOnSelect={false}
      error={error}
    />
  )
}

export { DynamicDropdownInput }
