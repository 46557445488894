import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { SuccessionPlanCandidate } from "v2/react/components/orgChart/ProfilePanel/PositionModules/SuccessionPlanCandidate"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { IS_V2 } from "v2/react/components/orgChart/ProfilePanel/utils/constants"
import { Avatar } from "v2/react/shared/icons/Avatar"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { closeProfilePlanSidebar } from "v2/redux/slices/ProfilePanelSlice"
import { openSuccessionPlanSidebar } from "v2/redux/slices/SuccessionPlanPanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface SuccessionPlanProps {
  abilities: ProfilePanelPermissions
  featureFlags: FeatureFlags
}

function SuccessionPlan({ abilities, featureFlags }: SuccessionPlanProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const position = useAppSelector((state) => state.profilePanel.position)
  const activeMode = useAppSelector((state) => state.profilePanel.profilePanelMode)
  // Because this box is shared between both tabs, we need to determine whether or not to show
  // or hide the module based on the active mode
  const { currentBox, toggleBox } = useSelectedBox(
    "succession_plan",
    activeMode ?? ProfilePanelMode.Position,
  )
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  if (!person && activeMode === ProfilePanelMode.Person) return null
  if (!position || !currentBox) return null
  if (!abilities.canReadSuccessionStrategy) return null
  if (!featureFlags.successionPlanning) return null

  const openSuccessionPlan = (positionId: string | undefined) => {
    if (!positionId) return

    if (!IS_V2) {
      const node = window.App.OrgChart.getNodeByPosition(Number(positionId))
      window.App.OrgChart.focus(Number(positionId))
      window.App.OrgChart.viewSuccessionPlan(node)
    } else {
      dispatch(closeProfilePlanSidebar())
      dispatch(openSuccessionPlanSidebar({ positionId }))
    }
  }

  const headerContent = <div className="title">{t("v2.profile_panel.succession_plan")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div className="succession-plan-profile-module grid-cols-[2fr_auto_1fr_auto_2fr] gap-1 grid">
          <blockquote className="succession-plan-module__candidates !p-2">
            <p className="text-sm-bold">
              {t("v2.profile_panel.candidates_to_replace", {
                count: position.successionPlan?.candidates?.length || 0,
                person: person?.fullName,
              })}
            </p>
            <ul className="no-bullets">
              {position.successionPlan?.candidates?.map((candidate) => (
                <SuccessionPlanCandidate candidate={candidate} key={candidate.id} />
              ))}
            </ul>

            <button
              className={classNames("link-text mt-2", { disabled: window.gon.editing_disabled })}
              onClick={() => openSuccessionPlan(position.id)}
              type="button"
            >
              {t(`v2.profile_panel.${abilities.canEditSuccessionPlan ? "edit_plan" : "view_plan"}`)}
            </button>
          </blockquote>

          <div className="self-center">
            <FontAwesomeIcon icon={["fas", "right"]} />
          </div>
          <div className="succession-plan-module__target">
            <blockquote className="w-[105px] flex-col items-center gap-2 border-neutral-8 !p-2 flex">
              <p className="text-center text-sm-bold">
                {position.title ?? t("v2.profile_panel.untitled_position")}
              </p>
              {person ? (
                <Avatar person={person} />
              ) : (
                <BlueIcon icon={["far", "chair-office"]} size="med-lg" />
              )}
              {!person && (
                <div className="badge--sm badge--yellow">{t("v2.defaults.open_position")}</div>
              )}
            </blockquote>
          </div>
          <div className="self-center">
            <FontAwesomeIcon icon={["fas", "right"]} />
          </div>

          <div className="succession-plan-module__candidacies">
            <div className="list-group h-full !p-2">
              <div className="list-group-item p-0 text-sm-bold">
                {t("v2.profile_panel.candidacies_count", {
                  person: person?.fullName,
                  count: person?.candidacies?.length ?? 0,
                })}
              </div>

              {(person?.candidacies || []).map((candidacy) => (
                <div className="list-group-item" key={candidacy.id}>
                  <div>
                    <div className="list-group-item-title !mb-0">
                      {t("v2.profile_panel.candidacy_position", {
                        position: candidacy.sortOrder,
                      })}
                    </div>
                    <button
                      className="link-text cursor-pointer"
                      onClick={() => openSuccessionPlan(candidacy.successionPlan?.position?.id)}
                      type="button"
                    >
                      {candidacy.successionPlan?.position?.title ||
                        t("v2.profile_panel.untitled_position")}
                    </button>
                    <div className="secondary-text">
                      {`${t("v2.profile_panel.reports_to")} ${
                        candidacy.successionPlan?.position?.reportsToName ||
                        t("v2.profile_panel.untitled_position")
                      }`}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { SuccessionPlan }
