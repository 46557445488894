import { Table } from "@tanstack/react-table"
import { Virtualizer } from "@tanstack/react-virtual"
import React, { RefObject } from "react"
import { bindActionCreators } from "redux"
import invariant from "tiny-invariant"
import { useEventListener } from "usehooks-ts"

import { useCustomEventListener } from "v2/react/hooks/useCustomEventListener"
import { transitionTableCursor } from "v2/redux/slices/DatasheetSlice"
import { beginWriteWithCursor } from "v2/redux/slices/DatasheetSlice/cursor/cursorActions"
import { cursorEventTarget } from "v2/redux/slices/DatasheetSlice/cursor/cursorEvents"
import { selectCursor } from "v2/redux/slices/DatasheetSlice/cursor/cursorSelectors"
import {
  inEitherRead,
  inEitherReadOnEditable,
  inEitherWriteState,
  onEditableCell,
  onEditableCellTransitionNext,
  onNonEditableCell,
  onNothing,
} from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import {
  CellCursor,
  CursorEventDetail,
  CursorState,
} from "v2/redux/slices/DatasheetSlice/cursor/types"
import { AppDispatch, useAppDispatch, useAppSelector } from "v2/redux/store"

import { ArrowKeys, makeMovementMatchHandler, TransitionKeys } from "./cursorKeyMovements"
import { createMovedCursor } from "./moveCursor"

export type HookArg = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>
  beaconRef: RefObject<HTMLButtonElement>
  cursorRef: RefObject<HTMLDivElement>
  tableContainerRef: RefObject<HTMLDivElement>
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>
  columnVirtualizer: Virtualizer<HTMLDivElement, Element>
}

interface HookState extends HookArg {
  cursor: CellCursor
}

/**
 * Listens to a variety of events in order to control the cell cursor. Manages
 * both moving the cursor, and transitioning the cursor into a "write" state.
 *
 * This hook is meant to be used at the top-level of the datasheet.
 */
export function useDatasheetCellCursor(arg: HookArg) {
  const hookState = {
    ...arg,
    cursor: useAppSelector(selectCursor),
  }

  useCellCursorStateChangeListenerToRestoreFocus(hookState)
  useCellCursorTargetChangeListenerToRestoreFocus(hookState)
  useCellCursorTargetChangeListenerToScrollIntoView(hookState)
  useKeyUpListenerToBeginWriteWithNewValue(hookState)
  useKeyDownListenerToPreventUnwantedDefaultBehavior(hookState)
  useKeyUpListenerToControlCursor(hookState)

  return hookState
}

/**
 * Prevents default behaviors of certain keydown events.
 *
 * While editing an input, a keyup event will not fire for the "Tab" key by
 * default. When not editing an input, "Tab" messes with the scroll position.
 * Squelching the event during a keydown remedies both issues.
 *
 * When not editing an input, an arrow key causes the sheet to scroll. This
 * squelches that as the arrow key now controls the cell cursor.
 */
function useKeyDownListenerToPreventUnwantedDefaultBehavior({
  cursor,
  tableContainerRef,
}: HookState) {
  const listener = React.useCallback(
    (reactOrNativeEvent: KeyboardEvent | React.KeyboardEvent) => {
      const { current: tableContainer } = tableContainerRef
      // @ts-ignore
      const inTable = tableContainer?.contains(reactOrNativeEvent.target)
      const event = unwrapEvent(reactOrNativeEvent)

      if (matchModifierKey(event) || !inTable) return

      // Ensure Enter + Tab behave appropriately.
      if (TransitionKeys.matchEvent(event)) squelchEvent(event)

      // When not writing to a cell, prevent default arrow key behavior to
      // prevent unwanted scrolling.
      if (!inEitherWriteState(cursor) && ArrowKeys.matchEvent(event)) squelchEvent(event)
    },
    [cursor, tableContainerRef],
  )

  useEventListener("keydown", listener)
}

/**
 * Handles moving the cursor, or when on an editable cell, possibly
 * transitioning to the "writingOnEditable" state.
 */
function useKeyUpListenerToControlCursor({ beaconRef, table }: HookState) {
  const cursor = useAppSelector(selectCursor)
  const appDispatch = useAppDispatch()

  const listener = React.useCallback(
    (reactOrNativeEvent: React.KeyboardEvent | KeyboardEvent) => {
      const event = unwrapEvent(reactOrNativeEvent)
      if (matchModifierKey(event)) return

      // Control is relinquished to the cell that is undergoing changes. If
      // writing, or if we're on nothing, return.
      if (onNothing(cursor) || inEitherWriteState(cursor)) return

      const applyMovementIfMatchFoundIn = makeMovementMatchHandler(event, ({ direction }) =>
        appDispatch(transitionTableCursor(createMovedCursor(cursor, table, direction))),
      )

      event.preventDefault()
      if (onNonEditableCell(cursor) || onEditableCellTransitionNext(cursor)) {
        applyMovementIfMatchFoundIn(ArrowKeys, TransitionKeys)
      } else if (onEditableCell(cursor) && TransitionKeys.matchEvent(event)) {
        appDispatch(transitionTableCursor({ ...cursor, state: CursorState.WritingOnEditable }))
      } else if (onEditableCell(cursor)) {
        applyMovementIfMatchFoundIn(ArrowKeys)
      }
    },
    [appDispatch, cursor, table],
  )

  useEventListener("keyup", listener, beaconRef)
}

/**
 * When on an editable cell, transitions into a write state. If the cell
 * supports it, this will replace the current value with the character of the
 * key pressed. Skips the event if it fires for a non-printable character.
 */
function useKeyUpListenerToBeginWriteWithNewValue({ beaconRef, cursor }: HookState) {
  const { beginWriteWithCursor } = useCursorActions(useAppDispatch())
  const listener = React.useCallback(
    (reactOrNativeEvent: KeyboardEvent | React.KeyboardEvent) => {
      const event = unwrapEvent(reactOrNativeEvent)
      // if (activeElementNotInSheet(sheetRef)) return
      if (matchModifierKey(event)) return
      if (!inEitherReadOnEditable(cursor)) return
      // These keys must be handled elsewhere...return if they're detected.
      if (TransitionKeys.matchEvent(event) || ArrowKeys.matchEvent(event)) return

      // Bail if given any control/modifier key. See the unicode categories
      // these map to here: https://www.compart.com/en/unicode/category
      if (!event.key.match(/^[^\p{Cc}\p{Cf}\p{Zp}\p{Zl}]{1,2}$/u)) return

      // If here, the user has started typing while focused on an editable
      // cell. Transition the cursor state using the first character as the
      // initial input.
      event.preventDefault()
      beginWriteWithCursor({ clobber: { withValue: `${event.key}` } })
    },
    [beginWriteWithCursor, cursor],
  )

  useEventListener("keyup", listener, beaconRef)
}

/**
 * Focuses the beaconRef if it's not in focus, the cursor target changed, and
 * the cursor is in a read state.
 */
function useCellCursorTargetChangeListenerToRestoreFocus({ beaconRef }: HookState) {
  const listener = React.useCallback(
    ({ detail: { cursor } }: CustomEvent<CursorEventDetail>) => {
      if (inEitherRead(cursor) && beaconRef.current !== document.activeElement)
        beaconRef.current?.focus({ preventScroll: true })
    },
    [beaconRef],
  )

  useCustomEventListener(cursorEventTarget, "cellCursorTargetChange", listener)
}

/**
 * Focuses the beaconRef if it's not in focus, the cursor _state_ changed, and
 * the cursor is in a read state. Useful for restoring control after a follow
 * up interaction where a new cell to target wasn't available.
 */
function useCellCursorStateChangeListenerToRestoreFocus({ beaconRef }: HookState) {
  const listener = React.useCallback(
    ({ detail: { cursor } }: CustomEvent<CursorEventDetail>) => {
      if (inEitherRead(cursor) && beaconRef.current !== document.activeElement)
        beaconRef.current?.focus({ preventScroll: true })
    },
    [beaconRef],
  )

  useCustomEventListener(cursorEventTarget, "cellCursorStateChange", listener)
}

/**
 * Focuses the beaconRef if it's not in focus, the cursor target changed, and
 * the cursor is in an editable state.
 */
function useCellCursorTargetChangeListenerToScrollIntoView({
  table,
  tableContainerRef,
  columnVirtualizer,
  rowVirtualizer,
}: HookState) {
  const listener = React.useCallback(
    ({ detail: { cursor } }: CustomEvent<CursorEventDetail>) => {
      if (!tableContainerRef.current) return
      if (onNothing(cursor)) return

      const { rows } = table.getRowModel()
      // We can't rely on `row.index` since it's not an accurate reflection
      // when taking filtering, sorting, and grouping into account.
      const rowIndex = rows.findIndex(({ id }) => id === cursor.rowId)
      const [offsetForRow] = rowVirtualizer.getOffsetForIndex(rowIndex)
      const virtualRows = rowVirtualizer.getVirtualItems()
      const actualVirtualRow = virtualRows.find(({ index }) => index === rowIndex)
      // When the cursor moved up, ensure row is fully in view after accounting
      // for scrollMargin. There is a hack here that we should refactor, where
      // we fallback to measurementsCache if needed. I believe this is
      // technically a private API, but it supports responding to a cursor that
      // moves up which is out of the bounds of our virtualizers.
      const scrollMargin = rowVirtualizer.options.scrollMargin
      const actualStart = actualVirtualRow
        ? actualVirtualRow && actualVirtualRow.start
        : rowVirtualizer.measurementsCache[rowIndex]?.start
      const offsetWillOccludeRow = actualStart && actualStart - scrollMargin < offsetForRow
      if (offsetWillOccludeRow) {
        rowVirtualizer.scrollToOffset(actualStart - scrollMargin - 1)
      } else {
        // ASSUMPTION: `scrollToIndex` is NOP when row is already visible.
        rowVirtualizer.scrollToIndex(rowIndex)
      }

      const actualColumn = table.getColumn(cursor.columnId)
      const columnIndex = table
        .getVisibleFlatColumns()
        .findIndex(({ id }) => id === actualColumn?.id)
      invariant(actualColumn && columnIndex >= 0)
      // ASSUMPTION: `scrollToIndex` is NOP when column is already visible.
      columnVirtualizer.scrollToIndex(columnIndex)
    },
    [columnVirtualizer, rowVirtualizer, table, tableContainerRef],
  )

  useCustomEventListener(cursorEventTarget, "cellCursorTargetChange", listener)
}

const unwrapEvent = (event: KeyboardEvent | React.KeyboardEvent) =>
  "nativeEvent" in event ? event.nativeEvent : event

const matchModifierKey = ({ metaKey, altKey, ctrlKey }: KeyboardEvent) =>
  metaKey || altKey || ctrlKey

const squelchEvent = (event: Event) => {
  event.preventDefault()
  event.stopPropagation()
}

const useCursorActions = (dispatch: AppDispatch) =>
  React.useMemo(() => bindActionCreators({ beginWriteWithCursor }, dispatch), [dispatch])
