import { TFunction } from "i18next"
import { isEmpty } from "lodash"
import { UseFormSetError } from "react-hook-form"
import { z } from "zod"

import type {
  SendToSmartRecruitersMutation,
  SmartRecruitersJobRequisitionQuery,
} from "types/graphql"
import { BannerError } from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/SendToAtsModal"
import {
  ReqJsonFormDataSchema,
  SmartRecruitersFormType,
  type ReqJsonFormDataType,
} from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/types"

interface ProcessDataProps {
  jobRequisitionId?: string
  positionId?: string
  data: SmartRecruitersJobRequisitionQuery
  t: TFunction
}

type JobRequisitionOpenings = NonNullable<
  SmartRecruitersJobRequisitionQuery["jobRequisition"]
>["jobRequisitionOpenings"]

type ProcessedJobRequisitionOpening = {
  systemIdentifier: string | null | undefined
  type: ReqJsonFormDataType["reqType"]
  incumbentName: string | null | undefined
  targetStartDate: string
}

type ProcessedData = {
  formData: SmartRecruitersFormType
  auxiliary: {
    department: ReqJsonFormDataType["department"]
    location: ReqJsonFormDataType["location"]
    positions: ProcessedJobRequisitionOpening[]
    atsOptions: SmartRecruitersJobRequisitionQuery["atsOptions"] & {
      locationCollection?: NonNullable<
        SmartRecruitersJobRequisitionQuery["currentCompany"]
      >["collections"]["locations"]
    }
    bannerErrors?: BannerError[]
  }
}

/**
 * Shapes the data from the query into the format needed for the form.
 * Additionally provides some auxiliary data used to render various aspects of
 * the form.
 */
const processData = ({
  jobRequisitionId,
  positionId,
  data,
  t,
}: ProcessDataProps): ProcessedData => {
  const jobRequisition = data.jobRequisition
  const position = data.position

  const {
    jobDescription,
    jobTitle,
    sourceOpenings,
    location,
    department,
    reqType,
    projectedHireDate,
    positionOpenDate,
  } = buildReqData(jobRequisition, position)

  const matchedDepartment = matchDepartment(data.atsOptions?.departmentCollection, department)

  const formData = {
    jobRequisitionId,
    positionId,
    jobTitle,
    sourceOpenings,
    jobDescription,
    job: {
      title: jobTitle,
      refNumber: jobRequisition?.systemUid,
      locationId: location?.id ?? "",
      industry: emptyCommonElement(),
      function: emptyCommonElement(),
      experienceLevel: emptyCommonElement(),
      jobDescription,
      departmentId: matchedDepartment?.id ?? undefined,
    },
    positionOpenings: {
      type: reqType,
      positionOpenDate,
      targetStartDate: projectedHireDate,
    },
  }

  const auxiliary = {
    department: matchedDepartment,
    location,
    atsOptions: {
      ...data.atsOptions,
      locationCollection: data.currentCompany?.collections?.locations,
    },
    positions: processJobReqOpenings({
      rawOpenings: jobRequisition?.jobRequisitionOpenings ?? (position ? [{ position }] : []),
      globalData: formData.positionOpenings,
    }),
    bannerErrors: buildInitialBannerErrors(department, matchedDepartment, t),
  }

  return {
    formData,
    auxiliary,
  }
}

/**
 * Builds up the data needed to create a req in SmartRecruiters. We're either
 * building the data from an existing job requisition or from a position.
 */
const buildReqData = (
  jobRequisition: SmartRecruitersJobRequisitionQuery["jobRequisition"],
  position: SmartRecruitersJobRequisitionQuery["position"],
) => {
  const parsedReqData: ReqJsonFormDataType = jobRequisition
    ? ReqJsonFormDataSchema.parse(jobRequisition?.jsonFormData)
    : // If there's no job requisition, we're building the data from a position,
      // which means it's a backfill.
      {
        reqType: "REPLACEMENT",
        projectedHireDate: position?.projectedHireDate || "",
        location: {
          id: position?.location?.uniqueKey || "",
          name: position?.location?.label || "",
        },
        department: position?.department || undefined,
      }

  const jobDescription = jobRequisition?.jobDescription || position?.description
  const jobTitle = jobRequisition?.jobTitle || position?.title || ""
  const sourceOpenings = jobRequisition?.jobRequisitionOpenings?.length ?? (position ? 1 : 0)
  // Ideally this could be something that is associated with each opening
  // individually, but for now we make this a global value that applies to all
  // openings.  If there's a job req, we just take the openSince value from the
  // first opening, otherwise we use the position's openSince value.
  const positionOpenDate =
    z
      .string()
      .datetime({ offset: true })
      .transform((date) => date.split("T")[0])
      .safeParse(
        jobRequisition?.jobRequisitionOpenings?.[0]?.position?.openSince || position?.openSince,
      ).data || ""

  return {
    jobDescription,
    jobTitle,
    sourceOpenings,
    positionOpenDate,
    ...parsedReqData,
  }
}

const buildInitialBannerErrors = (
  department: ReqJsonFormDataType["department"],
  matchedDepartment: ReqJsonFormDataType["department"],
  t: TFunction,
) => {
  const bannerErrors: BannerError[] = []
  if (department?.id && !matchedDepartment) {
    bannerErrors.push({
      key: "departmentId",
      message: t(
        "v2.job_requisitions.modals.send_to_ats.smart_recruiters.errors.department_not_matched",
        {
          name: department?.label,
        },
      ),
      type: "initial",
    })
  }

  return bannerErrors
}

type SmartRecruitersFormDataType = ReturnType<typeof processData>["formData"]

/**
 * Attempts to match the department on the req form with a department from
 * SmartRecruiters by name. If a match is found, we use the ID from
 * SmartRecruiters.  If no match is found, we leave this blank and allow the
 * user to select the department from existing options within SmartRecruiters.
 */
const matchDepartment = (
  departmentCollection: NonNullable<
    SmartRecruitersJobRequisitionQuery["atsOptions"]
  >["departmentCollection"],
  departmentFromReq: ReqJsonFormDataType["department"],
) => {
  if (!departmentFromReq) return undefined
  const smartRecruitersDepartments = departmentCollection?.options?.collection
  if (!smartRecruitersDepartments || smartRecruitersDepartments.length === 0) return undefined

  return (
    smartRecruitersDepartments.find((department) => department.label === departmentFromReq.label) ??
    undefined
  )
}

/**
 * Shapes the job requisition openings into a format suitable for rendering the
 * positions module.
 */
const processJobReqOpenings = ({
  rawOpenings,
  globalData,
}: {
  rawOpenings: JobRequisitionOpenings
  globalData: SmartRecruitersFormDataType["positionOpenings"]
}) =>
  rawOpenings?.map((opening) => {
    const position = opening.position

    return {
      systemIdentifier: position?.systemIdentifier,
      type: globalData.type,
      incumbentName: position?.filledByFormatted || position?.lastFilledByFormatted,
      targetStartDate: globalData?.targetStartDate,
    }
  }) || []

/**
 * Maps server errors to the associated form fields and/or populates the error
 * banners.
 */
const handleServerErrors = ({
  errors,
  setError,
  updateBannerErrors,
}: {
  errors: NonNullable<SendToSmartRecruitersMutation["sendToSmartRecruiters"]>["errors"]
  setError: UseFormSetError<SmartRecruitersFormType>
  updateBannerErrors: (errors: BannerError[]) => void
}) => {
  if (isEmpty(errors) || !errors) return

  const bannerErrors: BannerError[] = []

  const errorCreatingPositions = errors.find(
    (error) => error.path?.includes("error_creating_positions"),
  )
  if (errorCreatingPositions) {
    updateBannerErrors([
      {
        key: "error_creating_positions",
        message: errorCreatingPositions.message,
      },
    ])
    return
  }

  errors.forEach((error) => {
    const errorPath = error.path
    const formField = mapServerErrorToFormField(errorPath)
    const message = error.message

    if (formField === "generic") {
      bannerErrors.push({
        key: formField,
        message,
      })
    } else {
      setError(formField, { message })

      // We always throw any location/department errors up in a banner in case
      // they're not present in the inputs.
      if (["job.locationId", "job.departmentId"].includes(formField)) {
        bannerErrors.push({
          key: formField.replace("job.", "") as FormKey,
          message,
        })
      }
    }
  })

  if (bannerErrors.length > 0) {
    updateBannerErrors(bannerErrors)
  }
}

const mapServerErrorToFormField = (path?: string | string[] | null) => {
  if (!path) return "generic"

  const pathArray = Array.isArray(path) ? path : [path]
  const pathString = pathArray.join(".").toLowerCase()

  if (pathString.includes("location") || pathString.includes("city") || pathString.includes("geo"))
    return "job.locationId"

  if (pathString.includes("department")) return "job.departmentId"
  if (pathString.includes("industry")) return "job.industry.id"
  if (pathString.includes("function")) return "job.function.id"
  if (pathString.includes("experienceLevel")) return "job.experienceLevel.id"

  return "generic"
}

const emptyCommonElement = () => ({
  id: "",
  label: "",
})

type FormKey =
  | keyof SmartRecruitersFormDataType
  | keyof SmartRecruitersFormDataType["job"]
  | keyof SmartRecruitersFormDataType["positionOpenings"]

export type { SmartRecruitersFormDataType, ProcessedData, FormKey }
export { processData, emptyCommonElement, handleServerErrors }
