import cn from "classnames"
import React, { useState } from "react"

import { Maybe } from "types/graphql"
import { SuggestedAutocompleteCell } from "v2/react/components/headcountPlanning/TableDatasheet/SuggestedAutocompleteCell"
import { prepareStyle } from "v2/react/components/orgChart/Datasheet/Cell/utils"
import { Column, CursorConnection, NodeRow } from "v2/react/components/orgChart/Datasheet/types"
import { useCollectionSearch } from "v2/react/hooks/useCollectionSearch"
import {
  toEncodedIdNamePair,
  toEncodedInitialData,
} from "v2/redux/slices/GridSlice/gridHelpers/orgUnits"
import type { FieldKey } from "v2/redux/slices/NodeSlice/types"

type SuggestedAutocompleteProps<TNode, CType> = {
  cursorConnection: CursorConnection
  row: NodeRow<TNode>
  column: Column<CType>
  isFirst: boolean
  isLast: boolean
  style: React.CSSProperties
  currentValue?: string
}

export function prepareValue(arg: Maybe<{ id: string; label: string }> | undefined) {
  if (!arg || arg.label === "") {
    return ""
  }
  if (arg.id === "custom") {
    return toEncodedInitialData(arg.label)
  }
  return toEncodedIdNamePair(arg.id, arg.label)
}

function SuggestedAutocomplete<TNode, CType>({
  column,
  currentValue,
  cursorConnection,
  isFirst,
  isLast,
  row,
  style,
}: SuggestedAutocompleteProps<TNode, CType>) {
  const [inputValue, setInputValue] = useState(
    cursorConnection.initialWriteValue ?? currentValue ?? "",
  )

  const { collectionResult: options } = useCollectionSearch({
    fieldKey: column.fieldKey as FieldKey, // TODO MY: Harden this
    filter: inputValue.trim(),
  })

  // NOTE: some logic must be duplicated in the `useCellState` call in `OrgChartDatasheetCell`
  // This is because this cell will get replaced with `DisplayValueCell` when the cursor moves
  // away, so the save would never complete.

  return (
    <SuggestedAutocompleteCell
      editable
      cellInputRef={cursorConnection.cellInputRef}
      options={options}
      columnId={String(column.fieldKey)}
      saveFn={(option) => cursorConnection.saveFn(prepareValue(option))}
      className={cn("GridBody-cell Cell__select-field bg-transparent", { last: isLast })}
      currentValue={{ id: "", label: currentValue ?? "" }}
      searchTerm={inputValue}
      setSearchTerm={setInputValue}
      rowId={row.id}
      style={{ ...prepareStyle(style, isFirst, row.color), padding: 0 }}
      renderOption={(option) => <div>{option.label}</div>}
    />
  )
}

export { SuggestedAutocomplete, SuggestedAutocompleteProps }
