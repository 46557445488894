/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import { HeadcountPlanDatasheetRow } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/types"
import { removeNode } from "v2/redux/slices/NodeSlice"

import { RootState } from "../store"
import { buildId, cellReducer, machine } from "./DatasheetSlice/cellStateMachines"
import { cursorUnit } from "./DatasheetSlice/cursor/cursorActions"
import { onNothing, transitionValid } from "./DatasheetSlice/cursor/cursorStates"
import { translateCellStateToRedux, translateReduxToCellState } from "./DatasheetSlice/translators"
import { CellEvent, CellState, DatasheetErrorSet, DatasheetState } from "./DatasheetSlice/types"

const errorsAdapter = createEntityAdapter<DatasheetErrorSet>()
const cellsAdapter = createEntityAdapter<CellState>()

const initialState: DatasheetState = {
  cursor: cursorUnit,
  restorableCursorValue: undefined,
  errors: errorsAdapter.getInitialState(),
  cells: cellsAdapter.getInitialState(),
  rowForEffectiveDateModal: null,
  focusPositionEndDate: false,
}

const internalErrorSelectors = errorsAdapter.getSelectors((state: DatasheetState) => state.errors)
const internalCellSelectors = cellsAdapter.getSelectors((state: DatasheetState) => state.cells)

const DatasheetSlice = createSlice({
  name: "datasheet",
  initialState,
  reducers: {
    setFocusPositionEndDate: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("focusPositionEndDate", payload)(state),
    setRowForEffectiveDateModal: (
      state,
      { payload }: PayloadAction<HeadcountPlanDatasheetRow | null>,
    ) => fp.set("rowForEffectiveDateModal", payload)(state),
    transitionTableCursor: (state, { payload: next }: PayloadAction<DatasheetState["cursor"]>) =>
      transitionValid(state.cursor, next)
        ? fp.assign(state, { cursor: next, restorableCursorValue: undefined })
        : state,
    cursorInputUnmountedWhileWriting: (state, { payload: value }: PayloadAction<string>) =>
      fp.set("restorableCursorValue", value, state),
    updateCellState: (state, { payload: event }: PayloadAction<CellEvent>) => {
      state.restorableCursorValue = undefined

      const storedCellState = internalCellSelectors.selectById(state, buildId(event))
      const cellState = translateReduxToCellState(storedCellState, event, state.cursor)
      const newCellState = cellReducer(cellState, event, machine)
      const storedErrors = internalErrorSelectors.selectById(state, event.rowId)

      const translated = translateCellStateToRedux(newCellState, state.cursor, storedErrors)

      if (translated.storedCellState) {
        cellsAdapter.setOne(state.cells, translated.storedCellState)
      } else {
        cellsAdapter.removeOne(state.cells, translated.id)
      }

      state.cursor = translated.cursor

      if (translated.errorSet) {
        errorsAdapter.setOne(state.errors, translated.errorSet)
      } else if (storedErrors && Object.keys(storedErrors.fieldErrors).length > 1) {
        // Just remove this column's error since there is more than one error in the row.
        const fieldErrors = { ...storedErrors.fieldErrors }
        delete fieldErrors[event.columnId]
        errorsAdapter.updateOne(state.errors, {
          id: event.rowId,
          changes: {
            fieldErrors,
          },
        })
      } else {
        errorsAdapter.removeOne(state.errors, event.rowId)
      }
    },
    updateCellStates: (state, { payload: states }: PayloadAction<CellEvent[]>) => {
      states.map((s) =>
        DatasheetSlice.caseReducers.updateCellState(state, {
          payload: s,
          type: "datasheet/updateCellState",
        }),
      )
    },
  },
  extraReducers: (builder) =>
    builder.addMatcher(removeNode.match, (state, { payload: id }) => {
      // This currently assumes only one selection, but should be broadened if
      // we add support for multi-selections.
      if (onNothing(state.cursor)) return
      if (state.cursor.rowId !== id) return
      state.cursor = cursorUnit // eslint-disable-line no-param-reassign
    }),
})

const errorSetSelectors = errorsAdapter.getSelectors((state: RootState) => state.datasheet.errors)
const cellStateSelectors = cellsAdapter.getSelectors((state: RootState) => state.datasheet.cells)

export const {
  setFocusPositionEndDate,
  setRowForEffectiveDateModal,
  transitionTableCursor,
  updateCellState,
  updateCellStates,
  cursorInputUnmountedWhileWriting,
} = DatasheetSlice.actions
export { DatasheetSlice, errorSetSelectors, cellStateSelectors }
