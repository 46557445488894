import React from "react"
import { ChangeHandler, RefCallBack } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { TextInput } from "v2/react/shared/forms/TextInput"

function LocationField({
  defaultValue,
  name,
  onChange,
  textInputRef,
}: {
  defaultValue: string | undefined
  name: string
  onChange: ChangeHandler
  textInputRef: RefCallBack
}) {
  const { t } = useTranslation()

  return (
    <div className="mb-4 cursor-default">
      <TextInput
        defaultValue={defaultValue}
        id="location"
        inputRef={textInputRef}
        label={t("v2.job_requisitions.modals.send_to_ats.lever.field_location")}
        name={name}
        onChange={onChange}
      />
    </div>
  )
}

export { LocationField }
