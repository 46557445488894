import { zodResolver } from "@hookform/resolvers/zod"
import { isNil } from "lodash"
import { head } from "lodash/fp"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as z from "zod"

import { AtsOptions } from "types/graphql"
import { Modal, useModalOverlayRef } from "v2/react/shared/overlay/Modal"
import { Notice } from "v2/react/shared/status/Notice"
import { useSendToAtsMutation } from "v2/redux/GraphqlApi/JobRequisitionsApi"

import { ExternalDepartmentIdField } from "./fields/ExternalDepartmentIdField"
import { ExternalLocationIdField } from "./fields/ExternalLocationIdField"
import { ExternalTemplateIdField } from "./fields/ExternalTemplateIdField"
import { JobTitleField } from "./fields/JobTitleField"
import { SystemUidField } from "./fields/SystemUidField"
import { FormError } from "./FormError"
import { Submit } from "./Submit"
import { findInitialOptionId, findOptionById, InitFailure, isForCreation } from "./utils"

const GreenhouseOptions = z.object({
  departments: z
    .array(z.object({ id: z.string(), label: z.string() }))
    .nonempty("Departments options are missing."),
  locations: z
    .array(z.object({ id: z.string(), label: z.string() }))
    .nonempty("Locations options are missing"),
  jobTemplates: z
    .array(z.object({ id: z.string(), label: z.string() }))
    .nonempty("Job template options are missing"),
})

type GreenhouseOptionsType = z.infer<typeof GreenhouseOptions>

const GreenhouseFormSchema = z.object({
  jobTitle: z.string().nonempty(),
  externalTemplateId: z.string(),
  externalDepartmentId: z.string(),
  externalLocationId: z.string(),
  jobRequisitionId: z.string().nullish(),
  systemUid: z.string().nullish(),
  numPositions: z.number().default(1),
})

type GreenhouseFormSchemaType = typeof GreenhouseFormSchema
type GreenhouseFormType = z.infer<typeof GreenhouseFormSchema>

type GreenhouseInitSuccess = {
  integration: "greenhouse"
  success: true
  data: GreenhouseFormType
  options: GreenhouseOptionsType
  schema: GreenhouseFormSchemaType
}

type InitGreenhouseResponse = GreenhouseInitSuccess | InitFailure

function initGreenhouseForm(
  jobTitle: string,
  departmentNames: string[],
  locationName: string,
  atsOptions: AtsOptions | undefined | null,
  numPositions: number,
): InitGreenhouseResponse {
  const parsedOptions = GreenhouseOptions.safeParse(atsOptions)

  if (parsedOptions.success) {
    return {
      integration: "greenhouse",
      success: true,
      data: {
        jobTitle,
        systemUid: "",
        externalDepartmentId: findInitialOptionId(parsedOptions.data.departments, departmentNames),
        externalLocationId: findInitialOptionId(parsedOptions.data.locations, [locationName]),
        externalTemplateId: parsedOptions.data.jobTemplates[1].id,
        numPositions,
      } as GreenhouseFormType,
      options: parsedOptions.data,
      schema: GreenhouseFormSchema,
    }
  }
  return { success: false, message: "unable_to_get_ats_data".t("ats") }
}

function mapDataToInput(
  jobRequisitionId: string,
  systemUid: string,
  positionId: string | null,
  options: GreenhouseOptionsType,
  data: GreenhouseFormType,
) {
  const departmentOption = findOptionById(options.departments, data.externalDepartmentId)
  const locationOption = findOptionById(options.locations, data.externalLocationId)
  if (!departmentOption || !locationOption) {
    return { message: "invalid_input".t("ats") }
  }
  const input = {
    ...data,
    departmentName: departmentOption?.label,
    locationName: locationOption?.label,
    positionId,
  }
  if (!isForCreation(jobRequisitionId)) {
    input.systemUid = systemUid
    input.jobRequisitionId = jobRequisitionId
  }
  return { input }
}

interface GreenhouseFormProps {
  closeModal: () => void
  formSchema: GreenhouseFormSchemaType
  initialFormData: GreenhouseFormType
  isOpen: boolean
  jobRequisitionId?: string
  options: GreenhouseOptionsType
  positionId: string | null
  systemUid?: string
}

function GreenhouseForm({
  closeModal,
  formSchema,
  initialFormData,
  isOpen,
  options,
  positionId,
  jobRequisitionId = "",
  systemUid = "",
}: GreenhouseFormProps) {
  const { t } = useTranslation()
  const [mutate] = useSendToAtsMutation()
  const {
    register,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: initialFormData,
    resolver: zodResolver(formSchema),
  })

  const { modalRef, scrollToTop } = useModalOverlayRef()

  const showError = (message: string) => {
    setError("root.serverError", { message })
    scrollToTop()
  }

  const rootErrorMsg = errors?.root?.serverError?.message

  const onSubmit: SubmitHandler<GreenhouseFormType> = async (data) => {
    if (!isValid) {
      return
    }
    try {
      const mapped = mapDataToInput(jobRequisitionId, systemUid, positionId, options, data)
      if (!mapped.input) {
        showError(mapped.message)
        return
      }
      const result = await mutate({ input: mapped.input }).unwrap()
      if (isNil(result)) {
        showError("unexpected_error".t("ats"))
        return
      }
      const error = head(result?.sendToAts?.errors)
      if (error) {
        switch (error.message) {
          case "no_ats_integration":
            showError("no_ats_integration".t("ats"))
            return
          case "no_greenhouse_user":
            showError("no_greenhouse_user".t("ats"))
            return
          case "rate_limited_error":
            showError("try_again".t("ats"))
            return
          case "This Requisition ID has already been taken.":
            showError("req_id_taken".t("ats"))
            return
          default:
            showError("failed_to_send".t("ats"))
            return
        }
      }
      closeModal()
      window.location.reload()
    } catch (error) {
      const message = "unexpected_error".t("ats")
      setError("root.serverError", { message })
      scrollToTop()
    }
  }

  const reg = {
    jobTitle: register("jobTitle"),
    systemUid: register("systemUid"),
    externalDepartmentId: register("externalDepartmentId"),
    externalLocationId: register("externalLocationId"),
    externalTemplateId: register("externalTemplateId"),
  }

  return (
    <Modal
      isOpen={isOpen}
      footer={
        <Submit
          isSubmitting={isSubmitting}
          onCancelClick={closeModal}
          onClick={handleSubmit(onSubmit)}
        />
      }
      onClose={closeModal}
      title={"send_to_ats".t("ats")}
      overlayRef={modalRef}
    >
      <form>
        <div className="react-modal__body">
          <Notice type="caution" icon={["far", "triangle-exclamation"]} wrapperClass="mb-6">
            <p>{t("v2.job_requisitions.modals.send_to_ats.greenhouse.additional_info")}</p>
          </Notice>
          <FormError message={rootErrorMsg} />
          <div className="accordion-module-panel">
            <div className="accordion-module-panel__header">
              <p className="text-base-bold">
                {t("v2.job_requisitions.modals.send_to_ats.module_missing_header")}
              </p>
            </div>
            <hr className="my-0" />

            <div className="accordion-module-panel__body">
              <div className="accordion-module-panel__body-items">
                <SystemUidField
                  ats="greenhouse"
                  textInputRef={reg.systemUid.ref}
                  name={reg.systemUid.name}
                  onChange={reg.systemUid.onChange}
                  defaultValue={initialFormData.systemUid || undefined}
                  show={isForCreation(jobRequisitionId)}
                />
                <JobTitleField
                  ats="greenhouse"
                  name={reg.jobTitle.name}
                  textInputRef={reg.jobTitle.ref}
                  onChange={reg.jobTitle.onChange}
                  defaultValue={initialFormData.jobTitle}
                  show={isForCreation(jobRequisitionId)}
                  error={!!errors.jobTitle}
                />
                <ExternalTemplateIdField
                  selectRef={reg.externalTemplateId.ref}
                  onChange={reg.externalTemplateId.onChange}
                  options={options.jobTemplates}
                />
                <ExternalDepartmentIdField
                  selectRef={reg.externalDepartmentId.ref}
                  onChange={reg.externalDepartmentId.onChange}
                  options={options.departments}
                />
                <ExternalLocationIdField
                  selectRef={reg.externalLocationId.ref}
                  onChange={reg.externalDepartmentId.onChange}
                  options={options.locations}
                />
              </div>
            </div>
          </div>

          <div className="accordion-module-panel mt-6">
            <div className="accordion-module-panel__header">
              <p className="text-base-bold">
                {t("v2.job_requisitions.modals.send_to_ats.module_requisition_data")}
              </p>
            </div>
            <hr className="my-0" />

            <div className="accordion-module-panel__body">
              {!isForCreation(jobRequisitionId) && (
                <>
                  <div className="items-center justify-between p-4 flex">
                    <p>{t("v2.job_requisitions.modals.send_to_ats.greenhouse.field_title")}</p>
                    <p>{initialFormData.jobTitle}</p>
                  </div>
                  <hr className="my-0" />
                </>
              )}
              <div className="items-center justify-between p-4 flex">
                <p>{t("v2.job_requisitions.modals.send_to_ats.greenhouse.number_of_openings")}</p>
                <p>{initialFormData.numPositions}</p>
              </div>
              {jobRequisitionId ? (
                <>
                  <hr className="my-0" />
                  <div className="items-center justify-between p-4 flex">
                    <p>
                      {t("v2.job_requisitions.modals.send_to_ats.greenhouse.field_requisition_id")}
                    </p>
                    <p>{systemUid}</p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { GreenhouseForm, GreenhouseInitSuccess, initGreenhouseForm }
