import { Column, Row, Table } from "@tanstack/react-table"
import { TFunction } from "i18next"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

import { AllowedAttribute } from "types/graphql.d"
import { getEffectiveDate } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/effectiveDates/helpers"
import { HeadcountPlanDatasheetRow } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet/types"
import { FieldType } from "v2/react/components/headcountPlanning/TableDatasheet/types"
import { downloadCsv, sanitizeCsvValue } from "v2/react/utils/csv"

const ENDING_DATE_COLUMN_KEYS = [
  "position_start_date",
  "position_end_date",
  "employee_termination_date",
  "projected_hiring_date",
]

/** @private */
type CsvColumnDef = {
  /** Used to enforce column uniqueness. */
  key: string
  /** The label shown as a column header. */
  label: string
  /** Returns the cell value for this column/row. */
  getValue: (row: Row<HeadcountPlanDatasheetRow>, table: Table<HeadcountPlanDatasheetRow>) => string
}

function useDownloadCsvCallback(allowedAttributes?: AllowedAttribute[], exportType?: string) {
  const { t } = useTranslation()

  return useCallback(
    (table: Table<HeadcountPlanDatasheetRow>, csvDownloadName?: string) => {
      const allRows = table.getPrePaginationRowModel().rows
      const defs = buildColumnDefs(allRows, allowedAttributes, t)

      const headers = defs.map(({ label }) => sanitizeCsvValue(label)).join(",")
      const rows = allRows
        .filter((row) => !row.getIsGrouped())
        .map((row) => defs.map(({ getValue }) => sanitizeCsvValue(getValue(row, table))).join(","))

      downloadCsv([headers, ...rows].join("\n"), csvDownloadName, exportType)
    },
    [allowedAttributes, t, exportType],
  )
}

function buildColumnDefs(
  allRows: Row<HeadcountPlanDatasheetRow>[],
  attributes: AllowedAttribute[] | undefined,
  t: TFunction,
) {
  const selectedAttributes = attributes?.filter(({ isSelected }) => isSelected)
  if (!selectedAttributes) {
    return [positionIdColumnDef(t), typeColumnDef(t), ...endingDateColumnDefs(t)]
  }

  // Presence of an attribute ID in this index generally dictates whether we'll
  // show "previous" and "effective date" columns for an attribute. Computed in
  // one pass ahead of time to avoid repeated table scans.
  const changedIndex = buildChangeIndex(allRows)

  const columnDefs = selectedAttributes?.reduce(
    (columnDefs: CsvColumnDef[], attribute: AllowedAttribute) => {
      // Justification is always displayed as a single column.
      if (attribute.id === "justification") {
        return addUntrackedColumnDefs(columnDefs, justificationColumnDef(attribute))
      }

      if (changedIndex.has(attribute.id)) {
        const previousDef = previousColumnDefFor(attribute, t)
        const currentDef = currentValueColumnDefFor(attribute)
        const incomingDefs = attribute.usesEffectiveDate
          ? [previousDef, currentDef, effectiveDateColumnDefFor(attribute, t)]
          : [previousDef, currentDef]

        return addUntrackedColumnDefs(columnDefs, ...incomingDefs)
      }

      // If here assume that the column has no change.
      return addUntrackedColumnDefs(columnDefs, currentValueColumnDefFor(attribute))
    },
    [positionIdColumnDef(t), typeColumnDef(t)],
  )

  return [...columnDefs, ...endingDateColumnDefs(t)]
}

function buildChangeIndex(allRows: Row<HeadcountPlanDatasheetRow>[]) {
  const changed: Set<string> = new Set()

  allRows.forEach(({ original }) => {
    if (original.type === "modified") {
      Object.keys(original.payload).forEach((key) => changed.add(key))
    }
  })

  return changed
}

function addUntrackedColumnDefs(columnDefs: CsvColumnDef[], ...incomingColumnDefs: CsvColumnDef[]) {
  const nextDefs = [...columnDefs]

  incomingColumnDefs.forEach((incomingDef) => {
    if (!nextDefs.some(({ key }) => key === incomingDef.key)) {
      nextDefs.push(incomingDef)
    }
  })

  return nextDefs
}

const positionIdColumnDef = (t: TFunction): CsvColumnDef => ({
  key: "position_id",
  label: t("v2.positions.index.table_columns.position_id"),
  getValue: ({ original }) => original.systemId ?? "",
})

const justificationColumnDef = (attribute: AllowedAttribute): CsvColumnDef => ({
  key: "justification",
  label: attribute.name,
  getValue: (row) => row.original.positionAttributesWithEdits.justification ?? "",
})

const typeColumnDef = (t: TFunction): CsvColumnDef => ({
  key: "type",
  label: t("v2.headcount_plan.datasheet.type"),
  getValue: ({ original }) => {
    const translated = t(`v2.headcount_plan.position_type.${original.type}`)
    return original.excluded && original.type === "new"
      ? `${translated} - ${t("v2.headcount_plan.csv.excluded")}`
      : translated
  },
})

const endingDateColumnDefs = (t: TFunction): CsvColumnDef[] =>
  ENDING_DATE_COLUMN_KEYS.map((key) => ({
    key,
    label: t(`v2.headcount_plan.csv.${key}`),
    getValue: ({ original }) => getEffectiveDate(original.positionAttributesWithEdits, key) ?? "",
  }))

const previousColumnDefFor = (attribute: AllowedAttribute, t: TFunction): CsvColumnDef => ({
  key: `${attribute.id}_previous`,
  label: `${attribute.name} - ${t("v2.headcount_plan.csv.previous_value")}`,
  getValue: ({ original }, table) =>
    getAttributeValue(attribute, original.positionAttributes ?? {}, table.getColumn(attribute.id)),
})

const currentValueColumnDefFor = (attribute: AllowedAttribute): CsvColumnDef => ({
  key: attribute.id,
  label: attribute.name,
  getValue: ({ original }, table) =>
    getAttributeValue(
      attribute,
      original.positionAttributesWithEdits ?? {},
      table.getColumn(attribute.id),
    ),
})

const effectiveDateColumnDefFor = (attribute: AllowedAttribute, t: TFunction): CsvColumnDef => {
  const baseLabel =
    attribute.effectiveDateId === "position_budget"
      ? t("v2.headcount_plan.csv.position_budget")
      : attribute.name

  return {
    key: `${attribute.effectiveDateId}-date`,
    label: `${baseLabel} - ${t("v2.headcount_plan.csv.effective_date")}`,
    getValue: ({ original }) =>
      getEffectiveDate(original.positionAttributesWithEdits, attribute.effectiveDateId) ?? "",
  }
}

const getAttributeValue = (
  attribute: AllowedAttribute,
  positionAttributes: {
    [key in string]: number | string | { label: string | null | undefined } | null | undefined
  },
  column: Column<HeadcountPlanDatasheetRow, unknown> | undefined,
) => {
  const fieldType = column?.columnDef.meta?.original.fieldType

  if (fieldType === FieldType.SelectDropdown && column?.columnDef.meta?.original?.options) {
    return (
      column?.columnDef.meta?.original?.options.find(
        (option) => option.id === positionAttributes[attribute.id],
      )?.label ?? ""
    )
  }

  const actual = positionAttributes[attribute.id]

  if (actual === null || actual === undefined) return ""
  if (typeof actual === "number" || typeof actual === "string") return `${actual}`
  return actual.label ?? ""
}

export { useDownloadCsvCallback }
