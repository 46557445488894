import dayjs from "dayjs"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { TimelineIntervalTypeEnum, TimelineMetricTypeEnum } from "types/graphql.d"
import { BasicSelectMenu } from "v2/react/shared/collection/menus/BasicSelectMenu"
import { NestingMenu } from "v2/react/shared/collection/menus/NestingMenu"
import { ReorderableCheckboxMenu } from "v2/react/shared/forms/ReorderableCheckboxMenu"
import { Toggle, ToggleButton, ToggleLabel, ToggleSwitch } from "v2/react/shared/forms/Toggle"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { TimelineTable } from "v2/react/shared/tables/TimelineTable/TimelineTable"
import {
  useGetHeadcountPlanTimelineOptionsQuery,
  useGetTimelinePageQuery,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"

type TimelineViewProps = {
  headcountPlanId: string
  csvDownloadRef: React.RefObject<HTMLButtonElement> | string
  csvDownloadName: string
  participantId?: string
}
export function TimelineView({
  csvDownloadRef,
  csvDownloadName,
  headcountPlanId,
  participantId,
}: TimelineViewProps) {
  const timelineOptions = useGetHeadcountPlanTimelineOptionsQuery({ headcountPlanId })

  if (timelineOptions.isLoading) return <Spinner />

  const collections = timelineOptions.data?.currentCompany?.collections
  const headcountPlan = timelineOptions.data?.headcountPlan
  const metricOptions = collections?.headcountPlanTimelineMetrics?.options?.nodes ?? []
  const intervalOptions = collections?.timelineIntervals?.options?.nodes ?? []
  const groupByOptions = headcountPlan?.groupByOptions ?? []

  return (
    <Inner
      headcountPlanId={headcountPlanId}
      participantId={participantId}
      csvDownloadRef={csvDownloadRef}
      csvDownloadName={csvDownloadName}
      metricOptions={metricOptions}
      intervalOptions={intervalOptions}
      groupByOptions={groupByOptions}
    />
  )
}

type MultiSelectOption = Option & { selected: boolean }
type Option = { id: string; label: string }
type InnerProps = {
  headcountPlanId: string
  participantId?: string
  csvDownloadRef: React.RefObject<HTMLButtonElement> | string
  csvDownloadName: string
  metricOptions: Option[]
  intervalOptions: Option[]
  groupByOptions: Option[]
}
function Inner({
  headcountPlanId,
  participantId,
  csvDownloadRef,
  csvDownloadName,
  metricOptions,
  intervalOptions,
  groupByOptions,
}: InnerProps) {
  const [showActuals, setShowActuals] = useState(false)
  const [intervalType, setIntervalType] = React.useState(intervalOptions[0].id)
  const [metrics, setMetrics] = React.useState<MultiSelectOption[]>(
    metricOptions.map((option) => ({ ...option, selected: option.id.includes("count") })),
  )
  const [groupBy, setGroupBy] = React.useState<MultiSelectOption[]>(
    groupByOptions.map((option) => ({ ...option, selected: false })),
  )

  const selectedMetricIds = metrics.filter((metric) => metric.selected).map((metric) => metric.id)
  const selectedGroupByIds = groupBy.filter((group) => group.selected).map((group) => group.id)

  const { isLoading, data } = useGetTimelinePageQuery({
    headcountPlanId,
    participantId,
    intervalType: intervalType as TimelineIntervalTypeEnum,
    metrics: selectedMetricIds as TimelineMetricTypeEnum[],
    groupBy: selectedGroupByIds,
    showActuals,
  })
  const headcountPlan = data?.headcountPlan
  const columns = data?.headcountPlanTimeline?.columns
  const rows = data?.headcountPlanTimeline?.rows
  const totals = data?.headcountPlanTimeline?.totals

  const { t } = useTranslation()

  const allDataIsDefined = headcountPlan && columns && rows !== undefined && totals !== undefined

  if (isLoading || !allDataIsDefined) return <Spinner />

  const handleActualsToggle = () => setShowActuals((prev) => !prev)

  return (
    <div className="page-pad flex-col flex">
      <div id="hcp-positions-addbar">
        <div className="w-full items-center justify-between flex">
          <div className="timeline-filters w-full p-2 sm:w-fit sm:p-0">
            <div className="input-group mb-0 flex-1 sm:mb-6">
              <label htmlFor="group_by" className="text-sm font-bold sm:text-base sm:font-bold">
                {t("v2.headcount_plan_timeline.group_by_label")}
              </label>
              <NestingMenu
                id="group_by"
                className="sm:w-[160px]"
                options={groupBy}
                setOptions={setGroupBy}
              />
            </div>
            <div className="input-group mb-0 flex-1 sm:mb-6">
              <label htmlFor="interval" className="text-sm font-bold sm:text-base sm:font-bold">
                {t("v2.headcount_plan_timeline.interval_label")}
              </label>
              <BasicSelectMenu
                id="interval"
                className="sm:w-[160px]"
                selectedOptionId={intervalType}
                setSelectedOptionId={(optionId) =>
                  setIntervalType(optionId as TimelineIntervalTypeEnum)
                }
                options={intervalOptions}
              />
            </div>
            <div className="input-group mb-0 flex-1 sm:mb-6">
              <label htmlFor="metrics" className="text-sm font-bold sm:text-base sm:font-bold">
                {t("v2.headcount_plan_timeline.metrics_label")}
              </label>
              <ReorderableCheckboxMenu
                id="metrics"
                className="sm:w-[160px]"
                listClassName="right-0 sm:left-0"
                options={metrics}
                setOptions={setMetrics}
              />
            </div>
          </div>
          <Tooltip placement="bottom-end">
            <TooltipTrigger className="w-fit block">
              <Toggle
                id="edit-mode-toggle"
                initialValue={showActuals}
                onToggle={handleActualsToggle}
              >
                <ToggleButton
                  classes="btn sm:btn--large btn--secondary p-2 sm:p-3 tooltip tooltip-right"
                  disabled={
                    !headcountPlan.lockedAt || dayjs().isBefore(headcountPlan.startDate, "day")
                  }
                >
                  <ToggleLabel
                    classes="hidden sm:block"
                    disabledLabel={t("v2.headcount_plan_timeline.show_actuals")}
                    enabledLabel={t("v2.headcount_plan_timeline.show_actuals")}
                  />
                  <ToggleSwitch />
                  <span className="tooltip-content tooltip-content--sm sm:invisible sm:hidden">
                    {t("v2.headcount_plan_timeline.show_actuals")}
                  </span>
                </ToggleButton>
              </Toggle>
            </TooltipTrigger>
            {(!headcountPlan.lockedAt || dayjs().isBefore(headcountPlan.startDate, "day")) && (
              <TooltipContent className="react-tooltip-content">
                {t("v2.headcount_plan_timeline.disabled_actuals")}
              </TooltipContent>
            )}
          </Tooltip>
        </div>
      </div>
      <div className="w-full overflow-x-auto pb-4">
        <TimelineTable
          columns={columns}
          rows={rows}
          totals={totals}
          startDate={headcountPlan.startDate}
          endDate={headcountPlan.endDate}
          csvDownloadRef={csvDownloadRef}
          csvDownloadName={csvDownloadName}
          csvExportType="headcount_plan"
          boundaryMismatchTooltipTranslationPath="v2.headcount_plan_timeline"
          showActuals={showActuals}
        />
      </div>
    </div>
  )
}
