import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { mergeCurrentSearchParamsWithUrl } from "v2/react/utils/urls"

interface Props {
  /**
   * This can either by a ref object or a string. If it's a string, it will be
   * used to set the button's id, which can later be used to look up the button
   * element.
   */
  csvDownloadRef?: React.RefObject<HTMLButtonElement> | string
  setRef?: (element: HTMLButtonElement) => void
  className?: string
  disabled?: boolean
  exportUrl?: string
  /**
   * If true, the current search params will be used along with the exportUrl
   * when the export is clicked.
   */
  useCurrentSearchParamsWithExportUrl?: boolean
}

function ExportButton({
  csvDownloadRef,
  setRef,
  className,
  disabled,
  exportUrl,
  useCurrentSearchParamsWithExportUrl,
}: Props) {
  const { t } = useTranslation()
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (useCurrentSearchParamsWithExportUrl && exportUrl) {
      const newUrl = mergeCurrentSearchParamsWithUrl(exportUrl)
      e.currentTarget.href = newUrl
    }
  }

  const buttonId = typeof csvDownloadRef === "string" ? csvDownloadRef : "export-csv"
  const buttonRef = typeof csvDownloadRef === "string" ? undefined : csvDownloadRef ?? setRef

  return (
    <Tooltip>
      <TooltipTrigger>
        {exportUrl ? (
          <a
            id="export-csv"
            className={cn("btn--large btn--secondary w-11", className, {
              disabled,
            })}
            href={exportUrl}
            aria-disabled={disabled}
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={["far", "download"]} />
          </a>
        ) : (
          <button
            id={buttonId}
            ref={buttonRef}
            className={cn("btn--large btn--secondary w-11", className)}
            type="button"
            disabled={disabled}
          >
            <FontAwesomeIcon icon={["far", "download"]} />
          </button>
        )}
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm">
        <span>{t("v2.defaults.export")}</span>
      </TooltipContent>
    </Tooltip>
  )
}

export { ExportButton }
