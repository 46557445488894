import cn from "classnames"
import React, { CSSProperties } from "react"

import { CompareValues } from "./CompareValues"
import { useDatasheetContext } from "./context"
import { useCellHandlers } from "./hooks/useCellHandlers"
import { useCellState } from "./hooks/useCellState"
import { EditingCellHandle } from "./hooks/useEditingCellRegistry"
import { StandardCellInput } from "./StandardCellInput"
import { StyleLayers } from "./StyleLayers"
import { CellInput, FieldType, SaveFn } from "./types"

type Props = {
  currentValue: string
  compareValue?: string | null
  saveFn?: SaveFn
  inputType?: string
  inputPlaceholder?: string
  onSaved?: () => void
  onErrored?: () => void
  columnId: string
  rowId: string
  editable?: boolean
  classNames?: string
  showPlaceholderWhenInactive?: boolean
  style?: CSSProperties
  cellInputRef?: React.Ref<CellInput>
  inputDefaultValueOverride?: string
}
export function StandardEditableCell({
  saveFn,
  onSaved,
  onErrored,
  currentValue,
  compareValue,
  inputPlaceholder,
  columnId,
  rowId,
  editable,
  inputType = "text",
  classNames,
  showPlaceholderWhenInactive,
  style,
  cellInputRef,
  inputDefaultValueOverride,
}: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const cellRef = React.useRef<HTMLDivElement>(null)
  const editingCellHandleRef = React.useRef<EditingCellHandle>(null)

  React.useImperativeHandle(
    editingCellHandleRef,
    (): EditingCellHandle => ({
      save: () => cell.dispatch({ type: "save", value: getSaveValue() }),
      contains: (target) => cellRef.current?.contains(target as Node) ?? false,
    }),
  )

  React.useImperativeHandle(cellInputRef, () => ({
    getValue: () => inputRef.current?.value ?? currentValue,
  }))

  const { registerEditingCell, unregisterEditingCell } = useDatasheetContext()

  const cell = useCellState({
    currentValue,
    fieldType: FieldType.Standard,
    rowId,
    columnId,
    editable,
    saveFn: saveFn ? (state) => saveFn(state.value) : undefined,
    onSaved: () => onSaved?.(),
    onErrored: () => onErrored?.(),
    onEditing: ({ initial }) => {
      registerEditingCell(editingCellHandleRef)
      if (inputRef.current) {
        inputRef.current.disabled = false
        inputRef.current.value = inputDefaultValueOverride ?? initial ?? currentValue
        inputRef.current?.focus()
      }
    },
    onSaving: () => {
      unregisterEditingCell()
    },
  })

  const getSaveValue = () => inputRef.current?.value ?? currentValue

  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, inputRef, { getSaveValue })

  const shouldHideCompareValue =
    cell.isEditing || cell.isSaving || cell.isSaved || cell.isErroredEditing || cell.isErroredSaving

  const errored =
    cell.isErrored || cell.isErroredEditing || cell.isErroredSaving || cell.isErroredSelected

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      role="button"
      className={cn("editable-cell", classNames, { "editable-cell--errored": errored })}
      id={`${columnId}-${rowId}`}
      onClick={handleCellClick}
      onKeyUp={handleCellKeyUp}
      style={style}
      ref={cellRef}
    >
      <CompareValues compareValue={shouldHideCompareValue ? null : compareValue}>
        <StandardCellInput
          ref={inputRef}
          name={columnId}
          disabled={!(cell.isEditing || cell.isErroredEditing)}
          type={inputType}
          defaultValue={inputDefaultValueOverride ?? currentValue}
          placeholder={
            cell.isEditing || cell.isErroredEditing || showPlaceholderWhenInactive
              ? inputPlaceholder
              : undefined
          }
        />
      </CompareValues>
      <StyleLayers cell={cell} fieldType={FieldType.Standard} />
    </div>
  )
}
