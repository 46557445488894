import { ControlProps } from "@jsonforms/core"
import { useJsonForms } from "@jsonforms/react"
import { get } from "lodash"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { formatCurrency } from "v2/react/utils/currency"

import { compensationLabelPrefix, totalCompensation } from "./utils/compensation"
import { formNumericValue, formStringValue } from "./utils/forms"

type ControlPropsSubset = Pick<ControlProps, "id" | "visible">

// Handling logic that involves multiple fields from the form, see:
// https://jsonforms.discourse.group/t/computed-field-values/114
const JsonModuleListTotalCompensation: FC<ControlPropsSubset> = ({ id, visible }) => {
  const { t } = useTranslation()
  const context = useJsonForms()
  const formData = context?.core?.data

  if (!visible) return null

  const fieldDisplay = () => {
    const amount = formNumericValue(formData, ["position", "basePay", "amount"])
    const basePayTypeId = formStringValue(formData, ["position", "basePay", "payType", "id"])
    const hours = formNumericValue(formData, ["position", "basePay", "hoursPerWeek"])
    const variablePayTypes = get(formData, ["position", "variablePayTypes"]) || []

    if (!basePayTypeId && !variablePayTypes) return <span />

    const value: number = totalCompensation(basePayTypeId, amount, hours, variablePayTypes)
    if (!value) return <span /> // Assumption: Don't show "$0"

    return <div>{formatCurrency({ value })}</div>
  }

  return (
    <div className="module-card-list-item">
      <label className="text-neutral-64" htmlFor={id}>
        {compensationLabelPrefix(formData)} {t("v2.job_requisitions.fields.total_position_budget")}
      </label>
      {/* See: https://testing-library.com/docs/queries/about/#:~:text=getByTestId
      "getByTestId: The user cannot see (or hear) these, so this is only
      recommended for cases where you can't match by role or text or it doesn't
      make sense (e.g. the text is dynamic)." */}
      <div data-testid="module-list-item-value">{fieldDisplay()}</div>
    </div>
  )
}

export { JsonModuleListTotalCompensation }
