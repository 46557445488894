import cn from "classnames"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { TimelineIntervalTypeEnum, TimelineMetricTypeEnum } from "types/graphql.enums"
import planVsActual from "v2/dashboards/charts/plan_vs_actual"
import { ChartLegend } from "v2/react/components/headcountPlanning/Insights/ChartLegend"
import { SmallSelect } from "v2/react/shared/collection/menus/SmallSelect"
import { LoadingWrapper } from "v2/react/shared/loaders/LoadingWrapper"
import { Info } from "v2/react/shared/status/Info"
import {
  useGetHeadcountPlanTimelineOptionsQuery,
  useGetPlanVsActualQuery,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"

interface PlanVsActualProps {
  headcountPlanId: string
  participantId?: string
}

function PlanVsActual({ headcountPlanId, participantId }: PlanVsActualProps) {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLDivElement>(null)
  const [dataType, setDataType] = useState<TimelineMetricTypeEnum>(TimelineMetricTypeEnum.NewCount)
  const [intervalType, setIntervalType] = useState<TimelineIntervalTypeEnum>(
    TimelineIntervalTypeEnum.Month,
  )
  const {
    data: graphResponse,
    isLoading,
    isFetching,
    isError,
  } = useGetPlanVsActualQuery({ headcountPlanId, participantId, intervalType, metric: dataType })
  const dropdownOptions = useGetHeadcountPlanTimelineOptionsQuery({ headcountPlanId })

  const fetchingData = isFetching || isLoading

  const planVsActualData = useMemo(
    () => graphResponse?.planVsActual?.result,
    [graphResponse?.planVsActual?.result],
  )

  const createChart = useCallback(() => {
    if (!planVsActualData) return

    if (containerRef.current) {
      containerRef.current.innerHTML = ""
    }

    const chart = planVsActual({
      data: planVsActualData,
      options: {
        elementId: "plan-vs-actual",
        dataType,
      },
    })

    containerRef.current?.append(chart)
  }, [planVsActualData, dataType])

  useEffect(() => {
    createChart()

    const resizeObserver = new ResizeObserver(() => {
      createChart()
    })
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [createChart])

  const intervalOptions =
    dropdownOptions.data?.currentCompany?.collections?.timelineIntervals?.options?.nodes ?? []

  const dataOptions =
    dropdownOptions.data?.currentCompany?.collections?.headcountPlanTimelineMetrics?.options
      ?.nodes ?? []

  const legendItems = [
    {
      label: t("v2.headcount_plan.insights.plan_vs_actual.plan_label"),
      backgroundColor: "bg-accent-breeze",
    },
    {
      label: t("v2.headcount_plan.insights.plan_vs_actual.actual_label"),
      backgroundColor: "bg-primary-100",
    },
  ]

  return (
    <div className="module-card">
      <div className="module-card__header justify-between">
        <div className="items-center flex">
          <span className="text-base-bold text-neutral-100">
            {t("v2.headcount_plan.insights.plan_vs_actual.title")}
          </span>
          <Info description={t("v2.headcount_plan.insights.plan_vs_actual.header_tooltip")} />
        </div>
        <div className="items-center gap-4 flex">
          <SmallSelect<string>
            options={intervalOptions}
            selectedId={intervalType}
            onClick={(id) => setIntervalType(id as TimelineIntervalTypeEnum)}
          />

          <SmallSelect<string>
            options={dataOptions}
            selectedId={dataType}
            onClick={(id) => setDataType(id as TimelineMetricTypeEnum)}
          />
        </div>
      </div>
      <LoadingWrapper
        isLoading={fetchingData}
        isError={isError}
        loaderClass={cn({ "module-card__body p-6": fetchingData || isError })}
        wrapperClass="module-card__body p-6"
      >
        <div ref={containerRef} id="plan-vs-actual" />
        <ChartLegend legendItems={legendItems} />
      </LoadingWrapper>
    </div>
  )
}

export { PlanVsActual }
