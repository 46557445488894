import { TFunction } from "i18next"
import { capitalize } from "lodash"

export function truncateValue(value: string, maxTextLength: number) {
  if (value.length <= maxTextLength) return value

  return `${value.slice(0, maxTextLength - 3).trim()}...`
}

export function measureText(text: string, font = "700 13px Satoshi, sans-serif") {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  if (!context) return null
  context.font = font
  return context.measureText(text)
}

export const joinStrings = (items: string[], t: TFunction) => {
  if (items.length <= 1) return items[0]
  return `${items.slice(0, -1).join(", ")} ${t("v2.defaults.and")} ${items[items.length - 1]}`
}

/**
 * Formats the string according to the tag format used by the server.
 *
 * @example
 * ```ts
 * toTagFormat("foo-baz") //=> "Foo-Baz"
 * toTagFormat("vigorously adaptive  ") //=> "Vigorously Adaptive"
 * ```
 */
export const toTagFormat = (text: string) => {
  const parts = Array.from(text.trim().matchAll(/\s+|-+|[^ \t\r\n\f\v-]+/g))

  const formatPart = (match: RegExpMatchArray) => {
    if (/\s+/.test(match[0])) return " "
    if (/-+/.test(match[0])) return match[0]

    return capitalize(match[0])
  }

  return parts.map(formatPart).join("")
}

/**
 * Mimics the behavior of Rails' `titleize` method.
 *
 * @example
 * ```ts
 * titleize("foo-baz") //=> "Foo Baz"
 * titleize("vigorously adaptive  ") //=> "Vigorously Adaptive"
 * ```
 */
export const titleize = (text: string) =>
  text.trim().replace(/[-_]+/g, " ").split(/\s+/).map(capitalize).join(" ")
