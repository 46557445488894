import cn from "classnames"
import { isEmpty } from "lodash"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  ProcessedData,
  SmartRecruitersFormDataType,
} from "v2/react/components/jobRequisitions/SendToAts/SmartRecruiters/utils"
import { useTruncatedTooltip } from "v2/react/hooks/useTruncatedTooltip"
import { BasicModule } from "v2/react/shared/layout/BasicModule"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { removeHtmlTagsAndTrim, sanitizeParse } from "v2/react/utils/sanitizeParse"

interface SetRequisitionFieldsProps {
  auxiliaryData: ProcessedData["auxiliary"]
}

const SetRequisitionFields = ({ auxiliaryData }: SetRequisitionFieldsProps) => {
  const { t } = useTranslation()

  const {
    formState: { defaultValues },
  } = useFormContext<SmartRecruitersFormDataType>()
  const items = [
    {
      label: t("v2.job_requisitions.modals.show.department"),
      value: auxiliaryData?.department?.label,
    },
    {
      label: t("v2.job_requisitions.modals.show.smart_recruiters.title"),
      value: presence(defaultValues?.jobTitle),
    },
    {
      label: t("v2.job_requisitions.modals.send_to_ats.smart_recruiters.job_description"),
      value: presence(defaultValues?.jobDescription),
    },
    {
      label: t("v2.job_requisitions.modals.show.smart_recruiters.id"),
      value: presence(defaultValues?.job?.refNumber),
    },
    {
      label: t("v2.job_requisitions.modals.show.smart_recruiters.location"),
      value: auxiliaryData?.location?.name,
    },
  ].filter((item) => item.value)

  return (
    <BasicModule
      title={t("v2.job_requisitions.modals.send_to_ats.requisition_data")}
      dark
      bodyClassNames="p-0"
    >
      <div className="label-bold flex-col flex">
        {items.map((item, index) => {
          if (!item.value) return null

          return (
            <SetItem key={item.label} label={item.label} value={item.value} first={index === 0} />
          )
        })}
      </div>
    </BasicModule>
  )
}

const SetItem = ({ label, value, first }: { label: string; value: string; first?: boolean }) => {
  const { ref, showTooltip, setShowTooltip, determineTooltip } =
    useTruncatedTooltip<HTMLDivElement>()

  return (
    <div
      className={cn("justify-between gap-6 px-4 py-[18px] flex", {
        "border border-x-0 border-b-0 border-solid border-neutral-8": !first,
      })}
    >
      <span>{label}</span>
      <Tooltip open={showTooltip}>
        <TooltipTrigger
          className="items-end truncate text-right"
          onMouseEnter={determineTooltip}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <p ref={ref} className="truncate">
            {removeHtmlTagsAndTrim(value)}
          </p>
        </TooltipTrigger>
        <TooltipContent className="react-tooltip-content">{sanitizeParse(value)}</TooltipContent>
      </Tooltip>
    </div>
  )
}

const presence = (value: string | null | undefined) => (isEmpty(value) ? null : value)

export { SetRequisitionFields }
