import axios from "axios"

import { buildCsrfHeader } from "v2/httpUtils"

interface GqlResponseBody<TData, TErrors = unknown[]> {
  data: TData
  errors?: TErrors
}

const baseGqlFetch = async (params: {}) =>
  axios.post("/graphql", params, {
    headers: { ...buildCsrfHeader(), "content-type": "application/json" },
    withCredentials: true,
  })

const adminGqlFetch = async (params: {}) =>
  axios.post("/admin/graphql", params, {
    headers: { ...buildCsrfHeader(), "content-type": "application/json" },
    withCredentials: true,
  })

/** @public */
const gqlFetch = async <TData, TErrors = unknown[]>(
  params = {},
): Promise<GqlResponseBody<TData, TErrors>> =>
  baseGqlFetch(params).then((res) => res.data as GqlResponseBody<TData, TErrors>)

export { adminGqlFetch, baseGqlFetch, gqlFetch, GqlResponseBody }
