import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

type ColumnType = "position_count"

interface HeaderProps {
  columnType: ColumnType
}

const Header = ({ columnType }: HeaderProps) => {
  const { t } = useTranslation()

  return (
    <th className="w-[16rem] min-w-[11rem]">
      <div className="place-content-left items-center gap-2 px-4 flex">
        {t(`v2.headcount_plan.overview.columns.${columnType}`)}
        <Tooltip placement="bottom">
          <TooltipTrigger className="items-center justify-center flex">
            <FontAwesomeIcon icon={["far", "info-circle"]} />
          </TooltipTrigger>
          <TooltipContent className="react-tooltip-content--sm">
            {t(`v2.headcount_plan.overview.${columnType}.tooltip`)}
          </TooltipContent>
        </Tooltip>
      </div>
    </th>
  )
}

export type { ColumnType }
export { Header }
