import fp from "lodash/fp"
import { isDynamicField } from "org_chart/chart/utils/relationalNodeDataStore/dynamicFields"
import { createSelector, createStructuredSelector } from "reselect"

import { RootState } from "v2/redux/store"
import { ReduxState } from "v2/redux/types"

/**
 * @public
 * @returns {array} an array of active field keys.
 */
const selectActiveDisplayFields = (state: ReduxState) => state.visualization.selectedDisplayFields

const selectDisplayFieldOptions = (state: ReduxState) => state.visualization.displayFieldOptions

/**
 * @public
 * @returns {boolean} a value indicating whether or not to show chart section
 *   colors.
 */
const selectShowChartSectionColors = (state: ReduxState) => state.visualization.enableChartSections

/**
 * @public
 * @returns {array} an array of active, dynamic, field keys. Dynamic field keys
 *   are generally formatted as "<type>_<id>". Always a subset of the active
 *   data fields.
 */
const selectActiveDynamicFields = createSelector(selectActiveDisplayFields, (activeFields) =>
  fp.filter(isDynamicField)(activeFields),
)

/**
 * @public
 * @returns {array} an array of static field keys (everything except those
 *   returned from selectActiveDynamicFields). Always a subset of the active
 *   data fields.
 */
const selectActiveStaticFields = createSelector(
  selectActiveDisplayFields,
  selectActiveDynamicFields,
  (allFields, dynamicFields) => fp.without(dynamicFields, allFields),
)

const selectChartSettings = createStructuredSelector({
  builderPositionsMin: (state: RootState) => state.visualization.builderPositionsMin,
  childrenCount: (state: RootState) => state.visualization.childrenCount,
  chosenField: (state: RootState) => state.visualization.edgeColorField,
  collapsedChartSectionIds: createSelector(
    (state: RootState) => state.container.chartSectionsCollapsedIndex,
    (chartSectionsIndex) => fp.keys(fp.pickBy(fp.identity, chartSectionsIndex)),
  ),
  collapseDepth: (state: RootState) => state.visualization.collapseDepth,
  condensed: (state: RootState) => state.visualization.condensed,
  countDottedRelationships: (state: RootState) => state.visualization.countDottedRelationships,
  countOpenPositions: (state: RootState) => state.visualization.countOpenPositions,
  displayFields: (state: RootState) => state.visualization.displayFields,
  displayMode: (state: RootState) => state.visualization.displayMode,
  displayModePrevious: (state: RootState) => state.visualization.displayModePrevious,
  enableChartSections: (state: RootState) => state.visualization.enableChartSections,
  enableFields: (state: RootState) => state.visualization.isEdgeColorEnabled,
  enableSecondaryFields: (state: RootState) => state.visualization.isCardColorEnabled,
  enableTextwrap: (state: RootState) => state.visualization.enableTextwrap,
  editMode: (state: RootState) => state.visualization.editMode,
  gridPositionFilter: (state: RootState) => state.visualization.gridPositionFilter,
  historyMode: (state: RootState) => state.visualization.historyMode,
  historyModeSelectedDate: (state: RootState) => state.visualization.historyModeSelectedDate,
  metricsBreakdownOpenFor: (state: RootState) => state.visualization.metricsBreakdownOpenFor,
  metricsIncludeDotted: (state: RootState) => state.visualization.metricsIncludeDotted,
  pageNum: (state: RootState) => state.visualization.pageNum,
  panOnScroll: (state: RootState) => state.visualization.panOnScroll,
  secondaryChosenField: (state: RootState) => state.visualization.cardColorField,
  secondarySortField: (state: RootState) => state.visualization.secondarySortField,
  selectedTab: (state: RootState) => state.visualization.selectedTab,
  showColorPicker: (state: RootState) => state.visualization.showColorPicker,
  showColumnStats: (state: RootState) => state.visualization.showColumnStats,
  showDate: (state: RootState) => state.visualization.showDate,
  showLabels: (state: RootState) => state.visualization.showLabels,
  showExpandedMetrics: (state: RootState) => state.visualization.showExpandedMetrics,
  showMetrics: (state: RootState) => state.visualization.showMetrics,
  showMetricsPercentage: (state: RootState) => state.visualization.showMetricsPercentage,
  showPositionColors: (state: RootState) => state.visualization.showPositionColors,
  sortDirection: (state: RootState) => state.visualization.sortDirection,
  sortField: (state: RootState) => state.visualization.sortField,
  superPanelOpen: (state: RootState) => state.visualization.superPanelOpen,
})

const selectDataOptions = createStructuredSelector({
  childrenCount: (state: RootState) => state.visualization.childrenCount,
  countDottedRelationships: (state: RootState) => state.visualization.countDottedRelationships,
  countOpenPositions: (state: RootState) => state.visualization.countOpenPositions,
  selectedDisplayFields: (state: RootState) => state.visualization.selectedDisplayFields,
})

const selectAutoPersistedSettings = createStructuredSelector({
  edit_mode: (state: RootState) => state.visualization.editMode,
})

export {
  selectActiveDisplayFields,
  selectDisplayFieldOptions,
  selectActiveDynamicFields,
  selectActiveStaticFields,
  selectAutoPersistedSettings,
  selectChartSettings,
  selectShowChartSectionColors,
  selectDataOptions,
}

export * from "v2/redux/slices/VisualizationSlice/visualizationSelectors/colorCodingSelectors"
