import I18nDataLoader from "v2/i18nDataLoader"

class ReactInitializer {
  /**
   * Loads translations, then imports `ReactRailsUJS` and mounts React
   * components. This helps ensure that SSR components don't run into any
   * hydration issues stemming from missing translations.
   */
  static loadDependenciesThenMount(): void {
    const currentLanguage = window.gon?.current_user_locale ?? "en"

    document.addEventListener("DOMContentLoaded", () => {
      I18nDataLoader.maybeLoadLocaleAndUpdateAppContainer(currentLanguage).finally(() => {
        // Ensure that the app is always mounted, even if translations fail to
        // load
        this.importAndMountReactRailsUJS()
      })
    })
  }

  /**
   * Dynamically import react_ujs and mount components after loading
   * translations. If we do this before, then `ReactRailsUJS` will automatically
   * mount components on `DOMContentLoaded`, which can lead to hydration issues.
   */
  private static importAndMountReactRailsUJS(): void {
    // @ts-expect-error
    import("react_ujs").then(({ default: ReactRailsUJS }) => {
      const componentRequireContexts = [
        // @ts-expect-error
        require.context("v2/react/components", true),
        // @ts-expect-error
        require.context("v2/react", true),
      ]

      ReactRailsUJS.useContexts(componentRequireContexts)
      ReactRailsUJS.detectEvents()
      ReactRailsUJS.mountComponents()
    })
  }
}

export default ReactInitializer
