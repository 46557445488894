import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useState } from "react"

import { usePositionTypeSearch } from "v2/react/hooks/usePositionTypeSearch"
import { prepareIconClass } from "v2/react/utils/misc"
import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"

import { PositionTypeAutocompleteResult } from "./PositionTypeAutocompleteResult"
import { RenderItemFn, Search, SearchInput, SearchList } from "./Search"

interface PositionTypeSearchInputProps {
  classes?: string
  icon?: string
  onSelect?: (selectedPositionType: PositionTypesConnectionNode) => void
  isFocused?: boolean
  notInCareerLadder?: boolean
  placeholder?: string
  disabled?: boolean
  htmlForId?: string
  excludeEmpty?: boolean
}

function PositionTypeSearchInput({
  excludeEmpty,
  htmlForId,
  onSelect,
  placeholder,
  classes = "",
  icon = "far fa-search",
  isFocused = false,
  notInCareerLadder = false,
  disabled,
}: PositionTypeSearchInputProps) {
  const [inputValue, setInputValue] = useState("")
  const { positionTypes, returnEmpty } = usePositionTypeSearch({
    filter: inputValue.trim(),
    excludeEmpty,
    notInCareerLadder,
  })

  const renderItem: RenderItemFn<(typeof positionTypes)[0]> = ({
    item,
    index,
    activeIndex,
    listRef,
    getItemProps,
    handleResultClick,
  }) => (
    <PositionTypeAutocompleteResult
      className={classNames("list-group-item", { highlight: activeIndex === index })}
      getItemProps={getItemProps}
      handleResultClick={handleResultClick}
      isSelected={activeIndex === index}
      key={item.uniqueKey}
      positionType={item}
      refFn={(node: HTMLDivElement | null) => {
        // eslint-disable-next-line no-param-reassign
        listRef.current[index] = node
      }}
    />
  )

  return (
    <Search
      className={classes}
      floatOverrides={{ placement: "bottom-start" }}
      items={positionTypes}
      setInputValue={setInputValue}
      inputValue={inputValue}
      onSelect={onSelect}
      getId={(positionType) => positionType.uniqueKey}
      getLabel={(item) => item.title || ""}
    >
      <div className="search-field relative">
        <div className="prefix pl-1 pr-1">
          <FontAwesomeIcon icon={prepareIconClass(icon)} />
        </div>
        <SearchInput
          id={htmlForId}
          className="h-6 pl-[1.9rem] text-sm sm:h-auto sm:text-base"
          placeholder={placeholder}
          disabled={disabled}
          isFocused={isFocused}
        />
      </div>
      <SearchList
        renderItem={renderItem}
        showEmptyMessage={!returnEmpty}
        emptyMessage={"no_position_types_found".t("position")}
        outerStyle={{ minWidth: "min(280px, 90vw)", right: 0 }}
        outerClassName="autocomplete-container"
        innerClassName="list-group autocomplete-list"
      />
    </Search>
  )
}

export { PositionTypeSearchInput }
