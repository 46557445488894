import cn from "classnames"
import React from "react"

interface LegendItem {
  label: string
  backgroundColor: string
}

interface ChartLegendProps {
  legendItems: LegendItem[]
}

function ChartLegend({ legendItems }: ChartLegendProps) {
  return (
    <div className="flex-col gap-2 flex 360:flex-row 360:gap-8">
      {legendItems.map((item) => (
        <div className="items-center gap-2 flex" key={item.label}>
          <div
            className={cn(
              "border-1 h-2.5 w-2.5 rounded-full border-solid border-neutral-8",
              item.backgroundColor,
            )}
          />
          <span className="text-base">{item.label}</span>
        </div>
      ))}
    </div>
  )
}

export { ChartLegend }
