import { EntityId } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import { createSelector } from "reselect"

import { NodeInterface } from "types/graphql.d"
import { Direction } from "v2/react/utils/enums"
import { Field } from "v2/redux/slices/ContainerSlice"
import { selectFields } from "v2/redux/slices/ContainerSlice/containerSelectors"
import {
  selectCursor,
  selectCursorColId,
  selectCursorRowId,
} from "v2/redux/slices/DatasheetSlice/cursor/cursorSelectors"
import { onNothing } from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import { selectDatasheetRows } from "v2/redux/slices/GridSlice/gridSelectors/selectDatasheetRows"
import { RowType } from "v2/redux/slices/GridSlice/types"
import { nodeSelectors } from "v2/redux/slices/NodeSlice/NodeApi"
import type { FieldKey } from "v2/redux/slices/NodeSlice/types"
import type { RootState } from "v2/redux/store"

import { getFieldType } from "../gridHelpers/getFieldType"

const withDirection = (_: RootState, direction: Direction) => direction

const isAvatarField = fp.propEq("fieldKey", "avatar")

const selectFieldsSansAvatar = (state: RootState) => fp.reject(isAvatarField, selectFields(state))

export const selectFieldsAsColumns = (hasCollectionMap: Record<string, boolean>) =>
  createSelector(
    selectFieldsSansAvatar,
    nodeSelectors.selectEntities,
    (state: RootState) => state.visualization.editMode,
    (state: RootState) => state.session.featureFlags,
    (fields: Field[], nodesById, editMode, featureFlags) =>
      fields.map((field) => {
        const original = {
          ...field,
          id: field.fieldKey,
          accessorFn: ({ id }: { id: string }) => nodesById[id]?.[field.fieldKey],
          editableFn: ({ id }: { id: string }) => {
            const node = nodesById[id]
            return node?.editable_fields
              ? editMode && node.editable_fields.includes(field.fieldKey)
              : false
          },
          width: "medium" as const,
          fieldType: getFieldType({
            fieldKey: field.fieldKey,
            hasCollection: hasCollectionMap[field.fieldKey],
            // Since `selectFieldsAsColumns` is only used for compatibility
            // with the hcp datasheet cells, we can assume that
            // isEditable is true here. We just want the fieldType of
            // the column here, regardless of whether it's editable for
            // particular rows.
            isEditable: true,
            featureFlags,
          }),
        }

        return { ...original, meta: { original } }
      }),
  )

export const selectTraversableRows = createSelector(selectDatasheetRows, (rows) =>
  rows.filter((row) => row.rowType === RowType.Node),
)

const selectCursorPositionForChange = createSelector(
  (state: RootState) =>
    fp.filter(fp.matches({ rowType: RowType.Node }), selectDatasheetRows(state)),
  selectFieldsSansAvatar,
  selectCursorRowId,
  selectCursorColId,
  (rows, fieldsSansAvatar, rowId, columnId) => {
    const rowIndex = fp.findIndex(fp.propEq("id", rowId), rows)
    const columnIndex = fp.findIndex(fp.propEq("fieldKey", columnId), fieldsSansAvatar)
    const lastColumnIndex = fieldsSansAvatar.length - 1

    return {
      columnIndex,
      inFirstColumn: columnIndex === 0,
      inLastColumn: columnIndex === lastColumnIndex,
      lastColumnIndex,
      rowIndex,
    }
  },
)

export const selectAdjacentCell = (
  state: RootState,
  direction: Direction,
): [EntityId, FieldKey] => {
  const current = selectCursor(state)
  if (onNothing(current)) throw new Error("Unexpected call; cursor must be on something")
  const rows = fp.filter(fp.matches({ rowType: RowType.Node }), selectDatasheetRows(state))
  const fieldsSansAvatar = selectFieldsSansAvatar(state)
  const [nextRowIndex, nextColumnIndex] = selectAdjacentCellIndices(state, direction)

  const lastColumnIndex = fieldsSansAvatar.length - 1
  const lastRowIndex = rows.length - 1

  // Edge case: if the next row would put the cursor out of bounds, return the
  // current id/field key as the cursor should remain in place. This is unlike
  // the column, which will cause the cursor to "wrap" around to the last
  // column of the prior row or the first column of the next row.
  if (nextRowIndex < 0 || nextRowIndex > lastRowIndex)
    return [current.rowId, current.columnId as keyof NodeInterface]

  const rowIndex = fp.clamp(0, lastRowIndex, nextRowIndex)
  const columnIndex = fp.clamp(0, lastColumnIndex, nextColumnIndex)

  return [rows[rowIndex].id, fieldsSansAvatar[columnIndex]?.fieldKey]
}

const selectAdjacentCellIndices = createSelector(
  [selectCursorPositionForChange, withDirection],
  ({ columnIndex, inFirstColumn, inLastColumn, lastColumnIndex, rowIndex }, direction) => {
    const nextCell = {
      isLastCellOfPreviousRow: direction === Direction.Left && inFirstColumn,
      isCellOnLeft: direction === Direction.Left && !inFirstColumn,
      isFirstCellOfNextRow: direction === Direction.Right && inLastColumn,
      isCellOnRight: direction === Direction.Right && !inLastColumn,
      isCellBelow: direction === Direction.Down,
      isCellAbove: direction === Direction.Up,
    }

    if (nextCell.isLastCellOfPreviousRow) return [rowIndex - 1, lastColumnIndex]
    if (nextCell.isCellOnLeft) return [rowIndex, columnIndex - 1]
    if (nextCell.isFirstCellOfNextRow) return [rowIndex + 1, 0]
    if (nextCell.isCellOnRight) return [rowIndex, columnIndex + 1]
    if (nextCell.isCellAbove) return [rowIndex - 1, columnIndex]
    if (nextCell.isCellBelow) return [rowIndex + 1, columnIndex]

    return [rowIndex, columnIndex]
  },
)
