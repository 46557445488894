import { CellEvent, CellState, CellStateMachine } from "./types"

export type IdObject = {
  rowId: string
  columnId: string
}

export function buildId(obj: IdObject) {
  return `${obj.rowId}.${obj.columnId}`
}

export function separateId(id: string): IdObject {
  const [rowId, columnId] = id.split(".")
  return { rowId, columnId }
}

export function initialCellState(obj: IdObject): CellState {
  return {
    id: buildId(obj),
    state: "idle",
  }
}

export function selectedCellState(obj: IdObject): CellState {
  return {
    id: buildId(obj),
    state: "selected",
  }
}

export function erroredSelectedCellState(
  obj: IdObject,
  errorMessage: string | undefined,
): CellState {
  return {
    id: buildId(obj),
    state: "erroredSelected",
    errorMessage,
  }
}

export function editingCellState(obj: IdObject & { initial?: string }): CellState {
  return {
    id: buildId(obj),
    state: "editing",
    initial: obj.initial,
  }
}

export function erroredEditingCellState(
  obj: IdObject & { initial?: string },
  errorMessage: string | undefined,
): CellState {
  return {
    id: buildId(obj),
    state: "erroredEditing",
    errorMessage,
    initial: obj.initial,
  }
}

export const machine: CellStateMachine = {
  idle: {
    error: (event, state) => ({
      id: state.id,
      state: "errored",
      value: state.value,
      errorMessage: event.message,
    }),
  },
  selected: {
    error: (event, state) => ({
      id: state.id,
      state: "erroredSelected",
      value: state.value,
      errorMessage: event.message,
    }),
    save: (event, state) => ({
      id: state.id,
      state: "saving",
      value: event.value,
    }),
  },
  editing: {
    save: (event, state) => ({
      id: state.id,
      state: "saving",
      value: event.value,
      nextCursor: event.nextCursor,
    }),
    error: (event, state) => ({
      id: state.id,
      state: "erroredEditing",
      value: state.value,
      errorMessage: event.message,
    }),
  },
  saving: {
    noop: (event, state) => ({ id: state.id, state: "selected", nextCursor: state.nextCursor }),
    saveError: (event, state) => ({
      id: state.id,
      state: "errored",
      value: state.value,
      errorMessage: event.message,
    }),
    saveSuccess: (event, state) => ({
      id: state.id,
      state: "saved",
      value: state.value,
      nextCursor: state.nextCursor,
    }),
    saveFollowUp: (event, state) => ({
      id: state.id,
      state: "followUp",
      value: state.value,
      nextCursor: state.nextCursor,
    }),
  },
  followUp: {
    cancelFollowUp: (event, state) => ({
      id: state.id,
      state: "selected",
    }),
    saveSuccess: (event, state) => ({
      id: state.id,
      state: "saved",
      value: state.value,
      nextCursor: state.nextCursor,
    }),
  },
  saved: {
    reset: (event, state) => ({ id: state.id, state: "selected" }),
  },
  errored: {
    reset: (event, state) => ({
      id: state.id,
      state: "idle",
      value: state.value,
    }),
  },
  erroredSelected: {},
  erroredEditing: {
    save: (event, state) => ({
      id: state.id,
      state: "erroredSaving",
      value: event.value,
      errorMessage: state.errorMessage,
    }),
  },
  erroredSaving: {
    noop: (event, state) => ({
      id: state.id,
      state: "errored",
      value: state.value,
      errorMessage: state.errorMessage,
    }),
    saveError: (event, state) => ({
      id: state.id,
      state: "errored",
      value: state.value,
      errorMessage: event.message,
    }),
    saveSuccess: (event, state) => ({
      id: state.id,
      state: "saved",
      value: state.value,
    }),
  },
}

export function cellReducer(state: CellState, event: CellEvent, machine: CellStateMachine) {
  const transitionFn = machine[state.state][event.type]

  if (transitionFn) {
    return transitionFn(event, state)
  }

  return state
}
