import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import { useOrgChartSearch } from "v2/react/components/headcountPlanning/hooks/useOrgChartSearch"
import { AutocompleteResult } from "v2/react/shared/forms/AutocompleteResult"
import { RenderItemFn, Search, SearchInput, SearchList } from "v2/react/shared/forms/Search"

interface OrgChartSearchInputProps {
  headcountPlanId: string
  participantId?: string
  onSelect?: (selectedNode: Position) => void
}

function OrgChartSearchInput({
  onSelect,
  participantId,
  headcountPlanId,
}: OrgChartSearchInputProps) {
  const [inputValue, setInputValue] = useState("")
  const [isFocused, setIsFocused] = useState(false)
  const { positions, returnEmpty, searchTerm } = useOrgChartSearch({
    filter: inputValue.trim(),
    headcountPlanId,
    participantId,
  })
  const { t } = useTranslation()

  const searchFilterStr = (str: string) => `${str.charAt(0).toUpperCase() + str.slice(1)}: `
  const searchFilters = ["everything", "people", "positions"]
  const searchFilterStrs = searchFilters.map((filter) => searchFilterStr(filter))
  const handleSelect = (item: Position) => {
    if (item.uniqueKey && searchFilters.includes(item.uniqueKey)) {
      setInputValue(searchFilterStr(item.uniqueKey) || "")
      setIsFocused(true)
    } else if (onSelect) onSelect(item)
  }

  let filteredSearchTerm = searchTerm
  searchFilterStrs.forEach((term) => {
    filteredSearchTerm = filteredSearchTerm.replace(term, "")
  })

  const renderItem: RenderItemFn<(typeof positions)[0]> = ({
    item,
    index,
    activeIndex,
    listRef,
    getItemProps,
    handleResultClick,
  }) => (
    <AutocompleteResult
      className=""
      getItemProps={getItemProps}
      handleResultClick={handleResultClick}
      isSelected={activeIndex === index}
      searchTerm={filteredSearchTerm}
      key={item.uniqueKey}
      position={item as Position}
      refFn={(node: HTMLDivElement | null) => {
        // eslint-disable-next-line no-param-reassign
        listRef.current[index] = node
      }}
    />
  )

  return (
    <Search
      className="utility-search-wrapper"
      floatOverrides={{ placement: "bottom-start" }}
      items={positions as Position[]}
      inputValue={inputValue}
      setInputValue={setInputValue}
      onSelect={handleSelect}
      getId={(item) => item.id}
      getLabel={(item) => item.title || ""}
      showResultOnFocus
    >
      <div className="search-field relative">
        <div className="prefix pl-1 pr-1">
          <FontAwesomeIcon icon={["far", "search"]} />
        </div>
        <SearchInput
          className="h-6 pl-[1.9rem] text-sm sm:h-auto sm:text-base"
          placeholder={t("v2.historical_orgchart.search.placeholder")}
          isFocused={isFocused}
        />
        <button
          className={classNames("suffix", { hidden: inputValue === "" })}
          onClick={() => setInputValue("")}
          type="button"
        >
          <FontAwesomeIcon icon={["fas", "xmark"]} />
        </button>
      </div>
      <SearchList
        renderItem={renderItem}
        showEmptyMessage={!returnEmpty}
        emptyMessage={t("v2.positions.search.no_positions_found")}
        outerStyle={{ right: 0 }}
        outerClassName="autocomplete-container"
        innerClassName="list-group autocomplete-list"
      />
    </Search>
  )
}

export { OrgChartSearchInput }
