import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, PositionType } from "types/graphql"
import { useEditPositionType } from "v2/react/components/positionTypes/hooks/useEditPositionType"
import { ResponsibilitiesModal } from "v2/react/components/positionTypes/modals/ResponsibilitiesModal"
import { ActionBanner } from "v2/react/components/positionTypes/Show/ActionBanner"
import { AlertBanner } from "v2/react/components/positionTypes/Show/AlertBanner"
import { EmptyContent, EmptyState } from "v2/react/components/positionTypes/Show/EmptyState"
import { SmallEditButton } from "v2/react/components/positionTypes/Show/SmallEditButton"
import { useStringWithFieldSuggestion } from "v2/react/hooks/useTypedFieldWithSuggestion"
import { Pulser } from "v2/react/shared/loaders/Pulser"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

interface ResponsibilitiesProps {
  positionType: PositionType
  featureFlags: FeatureFlags
}

const Responsibilities = ({ positionType, featureFlags }: ResponsibilitiesProps) => {
  const { t } = useTranslation()
  const {
    acceptFieldSuggestions,
    addError,
    cancelGeneratingFieldSuggestions,
    declineFieldSuggestions,
    errors,
    generateFieldSuggestions,
    initializeFieldSuggestions,
    isPerformingFieldSuggestionActions,
    isUpdatingPositionType,
    reset: resetEditState,
    updatePositionType,
  } = useEditPositionType(positionType)
  const {
    isAwaitingAction,
    isInitializeFailed,
    isInitializing,
    value: tasksAndResponsibilities,
  } = useStringWithFieldSuggestion({
    addGenerateError: addError,
    hasAiFeatureFlag: Boolean(featureFlags.aiGeneration),
    record: positionType,
    field: "tasks_and_responsibilities",
    getActual: ({ tasksAndResponsibilities }) => tasksAndResponsibilities ?? null,
    getUniqueKey: (record) => record.uniqueKey ?? `position_type_${record.id}`,
  })

  const [editModalOpen, setEditModalOpen] = useState(false)

  const handleClose = () => {
    setEditModalOpen(false)
    cancelGeneratingFieldSuggestions("tasks_and_responsibilities")
    resetEditState()
  }
  const handleEdit = () => setEditModalOpen(true)
  const handleInitializeResponsibilities = () =>
    initializeFieldSuggestions("tasks_and_responsibilities")
  const handleSave = (tasksAndResponsibilities: string, removeLinkedResponsibilities: boolean) =>
    updatePositionType({
      includeBlankValuesInPositionTypeAttributesSync: removeLinkedResponsibilities,
      tasksAndResponsibilities,
    })

  return (
    <>
      <div className="module-card" data-testid="responsibilities-module">
        <div className="module-card__header">
          <div className="module-title">{t("v2.position_types.show.responsibilities")}</div>
          {tasksAndResponsibilities.length > 0 &&
            (!isAwaitingAction || !featureFlags.aiGeneration) && (
              <SmallEditButton onClick={handleEdit} />
            )}
        </div>
        {featureFlags.aiGeneration && isInitializeFailed && isAwaitingAction && (
          <AlertBanner handleGenerate={handleInitializeResponsibilities} />
        )}
        {featureFlags.aiGeneration && tasksAndResponsibilities.length > 0 && (
          <ActionBanner
            awaitingAction={isAwaitingAction}
            fields={["tasks_and_responsibilities"]}
            onEdit={handleEdit}
            onAcceptFieldSuggestions={acceptFieldSuggestions}
            onDeclineFieldSuggestions={declineFieldSuggestions}
          />
        )}
        <div
          className={cn("module-card__body relative", {
            "border-t-0": isAwaitingAction && tasksAndResponsibilities.length,
          })}
        >
          {tasksAndResponsibilities.length ? (
            <div className="formatted-block">{sanitizeParse(tasksAndResponsibilities)}</div>
          ) : (
            <EmptyResponsibilities
              handleEdit={handleEdit}
              isInitializing={isInitializing && isAwaitingAction}
              isAiAvailable={Boolean(featureFlags.aiGeneration)}
            />
          )}
        </div>
      </div>
      <ResponsibilitiesModal
        contentType="definition"
        hasAiFeatureFlag={Boolean(featureFlags.aiGeneration)}
        errors={errors}
        isAiAvailable={Boolean(featureFlags.aiGeneration)}
        isOpen={editModalOpen}
        isPerformingFieldSuggestionAction={isPerformingFieldSuggestionActions}
        isSaving={isUpdatingPositionType}
        modalTitle={t("v2.position_types.show.edit_job_definition_responsibilities")}
        onClose={handleClose}
        onRegenerateFieldSuggestion={generateFieldSuggestions}
        onSave={handleSave}
        positionResponsibilities={tasksAndResponsibilities}
        record={positionType}
        uniqueKey={positionType.uniqueKey ?? `position_type_${positionType.id}`}
      />
    </>
  )
}

export { Responsibilities }

const EmptyResponsibilities = ({
  isInitializing,
  handleEdit,
  isAiAvailable,
}: {
  isInitializing: boolean
  handleEdit: () => void
  isAiAvailable?: boolean
}) => {
  const { t } = useTranslation()

  const noteI18nType = isAiAvailable ? "ai_add_note_html" : "add_note_html"
  const icon = isAiAvailable ? "sparkles" : "edit"

  const emptyResponsibilities: EmptyContent = {
    buttonText: `${t("v2.defaults.add")} ${t("v2.position_types.show.responsibilities")}`,
    note: sanitizeParse(
      t(`v2.position_types.show.${noteI18nType}`, {
        count: 2,
        property: t("v2.position_types.show.responsibilities").toLowerCase(),
      }),
    ),
    icon,
    onClick: handleEdit,
  }

  if (isInitializing && isAiAvailable)
    return <Pulser testId="responsibilities-initializing-indicator" />

  return (
    <EmptyState
      buttonText={emptyResponsibilities.buttonText}
      note={emptyResponsibilities.note}
      icon={emptyResponsibilities.icon}
      onClick={emptyResponsibilities.onClick}
    />
  )
}
