import React, { useEffect } from "react"

import { usePanelListeners } from "v2/react/components/orgChart/ProfilePanel/hooks/usePanelListeners"
import { ProfilePanel } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import { PanelSidebar, PortaledPanelSidebar } from "v2/react/components/PanelSidebar"
import RootProvider from "v2/react/components/RootProvider"
import { setContainerKey } from "v2/redux/slices/ContainerSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface ProfilePanelContainerProps {
  officialChartId: number
  pjaxReloadContainer?: string
  pjaxReloadUrl?: string
  usePortal?: boolean
}

const WithProvider = ({
  officialChartId,
  pjaxReloadContainer = "#content",
  pjaxReloadUrl = window.location.pathname,
  usePortal,
}: ProfilePanelContainerProps) => {
  const isSidebarOpen = useAppSelector((state) => state.profilePanel.isOpen)
  const dispatch = useAppDispatch()
  const activePositionId = useAppSelector((state) => state.profilePanel.positionId)

  useEffect(() => {
    if (activePositionId) {
      dispatch(setContainerKey(`chart_${officialChartId}`))
    }
  }, [activePositionId, dispatch, officialChartId])
  usePanelListeners()

  if (usePortal) {
    return (
      <PortaledPanelSidebar id="side-panel__profile" isOpen={isSidebarOpen} panelType="profile">
        {/* isOfficialChart will be true when the profile panel is not used from the org chart */}
        <ProfilePanel
          isOfficialChart
          pjaxReloadContainer={pjaxReloadContainer}
          pjaxReloadUrl={pjaxReloadUrl}
        />
      </PortaledPanelSidebar>
    )
  }

  return (
    <PanelSidebar id="side-panel__profile" isOpen={isSidebarOpen} panelType="profile">
      {/* isOfficialChart will be true when the profile panel is not used from the org chart */}
      <ProfilePanel
        isOfficialChart
        pjaxReloadContainer={pjaxReloadContainer}
        pjaxReloadUrl={pjaxReloadUrl}
      />
    </PanelSidebar>
  )
}

const ProfilePanelContainer = ({
  officialChartId,
  pjaxReloadContainer,
  pjaxReloadUrl,
  usePortal,
}: ProfilePanelContainerProps) => (
  <RootProvider>
    <WithProvider
      officialChartId={officialChartId}
      pjaxReloadContainer={pjaxReloadContainer}
      pjaxReloadUrl={pjaxReloadUrl}
      usePortal={usePortal}
    />
  </RootProvider>
)

export { ProfilePanelContainer }
