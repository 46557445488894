import { zodResolver } from "@hookform/resolvers/zod"
import { TFunction } from "i18next"
import { head, isNil } from "lodash"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"

import { Modal, useModalOverlayRef } from "v2/react/shared/overlay/Modal"
import { Notice } from "v2/react/shared/status/Notice"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useSendToAtsMutation } from "v2/redux/GraphqlApi/JobRequisitionsApi"

import { JobDescriptionField } from "./fields/JobDescriptionField"
import { JobTitleField } from "./fields/JobTitleField"
import { SystemUidField } from "./fields/SystemUidField"
import { FormError } from "./FormError"
import { Submit } from "./Submit"
import { InitFailure, isForCreation } from "./utils"

type AdpRmInitSuccess = {
  integration: "adp_rm"
  success: true
  data: AdpRmFormType
  schema: AdpRmFormSchemaType
}

type InitAdpRmResponse = AdpRmInitSuccess | InitFailure

function initAdpRmForm(
  jobTitle: string,
  replacementFor: string,
  jobDescription: string,
  numPositions: number,
): InitAdpRmResponse {
  return {
    integration: "adp_rm",
    success: true,
    data: {
      jobTitle,
      systemUid: "",
      replacementFor,
      jobDescription,
      numPositions,
    } as AdpRmFormType,
    schema: AdpRmFormSchema,
  }
}

function mapDataToInput(positionId: string | null, data: AdpRmFormType) {
  const input = {
    ...data,
    positionId,
  }
  return { input, message: "" }
}

const AdpRmFormSchema = z.object({
  jobTitle: z.string().min(1),
  jobRequisitionId: z.string().nullish(),
  systemUid: z.string().nullish(),
  jobDescription: z.string().nullish(),
  replacementFor: z.string().nullish(),
  numPositions: z.number().default(1),
})

type AdpRmFormSchemaType = typeof AdpRmFormSchema
type AdpRmFormType = z.infer<typeof AdpRmFormSchema>

interface AdpRmFormProps {
  closeModal: () => void
  formSchema: AdpRmFormSchemaType
  initialFormData: AdpRmFormType
  isOpen: boolean
  isReplacement: boolean
  jobRequisitionId?: string
  positionId: string | null
}

function AdpRmForm({
  closeModal,
  formSchema,
  initialFormData,
  isOpen,
  isReplacement,
  jobRequisitionId,
  positionId,
}: AdpRmFormProps) {
  const { t } = useTranslation()
  const [mutate] = useSendToAtsMutation()
  const {
    register,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: initialFormData,
    resolver: zodResolver(formSchema),
  })

  const { modalRef, scrollToTop } = useModalOverlayRef()

  const showError = (message: string) => {
    setError("root.serverError", { message })
    scrollToTop()
  }

  const rootErrorMsg = errors?.root?.serverError?.message
  const onSubmit: SubmitHandler<AdpRmFormType> = async (data) => {
    if (!isValid) {
      return
    }
    try {
      const mapped = mapDataToInput(positionId, data)
      if (!mapped.input) {
        showError(mapped.message)
        return
      }
      const result = await mutate({ input: mapped.input }).unwrap()
      if (isNil(result)) {
        showError(t("v2.job_requisitions.modals.send_to_ats.errors.unexpected_error"))
        return
      }
      const error = head(result?.sendToAts?.errors)
      if (error) {
        showError(getErrorMessage(t, error))
        return
      }
      closeModal()
      window.location.reload()
    } catch (error) {
      showError(t("v2.job_requisitions.modals.send_to_ats.errors.unexpected_error"))
    }
  }

  const reg = {
    jobTitle: register("jobTitle"),
    systemUid: register("systemUid"),
    jobDescription: register("jobDescription"),
    replacementFor: register("replacementFor"),
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      footer={
        <Submit
          onClick={handleSubmit(onSubmit)}
          onCancelClick={closeModal}
          isSubmitting={isSubmitting}
        />
      }
      title={t("v2.job_requisitions.modals.send_to_ats.title")}
      overlayRef={modalRef}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="react-modal__body">
          <Notice type="caution" icon={["far", "triangle-exclamation"]} wrapperClass="mb-6">
            <p>{t("v2.job_requisitions.modals.send_to_ats.adp.additional_info")}</p>
          </Notice>
          <FormError message={rootErrorMsg} />
          <div className="accordion-module-panel">
            <div className="accordion-module-panel__header">
              <p className="text-base-bold">
                {t("v2.job_requisitions.modals.send_to_ats.module_missing_header")}
              </p>
            </div>
            <hr className="my-0" />

            <div className="accordion-module-panel__body">
              <div className="accordion-module-panel__body-items">
                <SystemUidField
                  ats="adp"
                  textInputRef={reg.systemUid.ref}
                  name={reg.systemUid.name}
                  onChange={reg.systemUid.onChange}
                  defaultValue={initialFormData.systemUid || undefined}
                  show={isForCreation(jobRequisitionId)}
                />
                <JobTitleField
                  ats="adp"
                  name={reg.jobTitle.name}
                  textInputRef={reg.jobTitle.ref}
                  onChange={reg.jobTitle.onChange}
                  defaultValue={initialFormData.jobTitle}
                  show={isForCreation(jobRequisitionId)}
                  error={!!errors.jobTitle}
                />
                <JobDescriptionField
                  name={reg.jobDescription.name}
                  textInputRef={reg.jobDescription.ref}
                  onChange={reg.jobDescription.onChange}
                  defaultValue={initialFormData.jobDescription || undefined}
                  show={isForCreation(jobRequisitionId)}
                  error={!!errors.jobDescription}
                />
              </div>
            </div>
          </div>

          <div className="accordion-module-panel mt-6">
            <div className="accordion-module-panel__header">
              {t("v2.job_requisitions.modals.send_to_ats.module_requisition_data")}
            </div>
            <div className="accordion-module-panel__body">
              <div className="items-center justify-between p-4 flex">
                <p>{t("v2.job_requisitions.modals.send_to_ats.adp.field_replacement")}</p>
                <p>
                  {isReplacement
                    ? t("v2.job_requisitions.modals.send_to_ats.adp.is_replacement")
                    : t("v2.job_requisitions.modals.send_to_ats.adp.is_new")}
                </p>
              </div>
              <hr className="my-0" />
              <div className="items-center justify-between p-4 flex">
                <p>{t("v2.job_requisitions.modals.send_to_ats.adp.num_positions")}</p>
                <p>{initialFormData.numPositions}</p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

interface AdpRmInfo {
  isOpen: boolean
  requisition: {
    jobTitle: string
    systemUid: string
    jobDescription: string
    replacementFor: string
    jobRequisitionId: string
    isReplacement: boolean
    numPositions: number
  }
  closeModal: () => void
}

function AdpRmInfo({ closeModal, isOpen, requisition }: AdpRmInfo) {
  const { t } = useTranslation()
  const { modalRef, scrollToTop } = useModalOverlayRef()
  const [mutate] = useSendToAtsMutation()
  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: {
      ...requisition,
    },
    resolver: zodResolver(AdpRmFormSchema),
  })

  const showError = (message: string) => {
    setError("root.serverError", { message })
    scrollToTop()
  }
  const rootErrorMsg = errors?.root?.serverError?.message

  const onSubmit: SubmitHandler<AdpRmFormType> = async () => {
    if (!isValid) {
      return
    }
    try {
      const result = await mutate({ input: requisition }).unwrap()
      if (isNil(result)) {
        showError(t("v2.job_requisitions.modals.send_to_ats.errors.unexpected_error"))
        return
      }
      const error = head(result?.sendToAts?.errors)
      if (error) {
        showError(getErrorMessage(t, error))
        return
      }
      closeModal()
      window.location.reload()
    } catch (error) {
      showError(t("v2.job_requisitions.modals.send_to_ats.errors.unexpected_error"))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      overlayClassName="z-100"
      footer={
        <Submit
          onClick={handleSubmit(onSubmit)}
          onCancelClick={closeModal}
          isSubmitting={isSubmitting}
        />
      }
      title={t("v2.job_requisitions.modals.send_to_ats.title")}
      overlayRef={modalRef}
    >
      <div className="p-4">
        <FormError message={rootErrorMsg} />
        <div className="accordion-module-panel">
          <div className="accordion-module-panel__header">
            {t("v2.job_requisitions.modals.send_to_ats.module_requisition_data")}
          </div>
          <hr className="my-0" />

          <div className="accordion-module-panel__body">
            <div className="items-center justify-between p-4 flex">
              <div>{t("v2.job_requisitions.fields.req_id")}</div>
              <div>{requisition.systemUid}</div>
            </div>
            <hr className="my-0" />

            <div className="items-center justify-between p-4 flex">
              <div>{t("v2.job_requisitions.modals.send_to_ats.adp.field_replacement")}</div>
              <div>
                {requisition.isReplacement
                  ? t("v2.job_requisitions.modals.send_to_ats.adp.is_replacement")
                  : t("v2.job_requisitions.modals.send_to_ats.adp.is_new")}
              </div>
            </div>
            <hr className="my-0" />

            <div className="items-center justify-between p-4 flex">
              <div>{t("v2.job_requisitions.modals.send_to_ats.adp.num_positions")}</div>
              <div>{requisition.numPositions}</div>
            </div>
            <hr className="my-0" />

            <div className="items-center justify-between p-4 flex">
              <div>{t("v2.job_requisitions.modals.send_to_ats.adp.field_title")}</div>
              <div>{requisition.jobTitle}</div>
            </div>
            <hr className="my-0" />

            <div className="items-center justify-between p-4 flex">
              <div className="min-w-[100px]">
                {t("v2.job_requisitions.modals.send_to_ats.adp.field_description")}
              </div>
              <div>{sanitizeParse(requisition.jobDescription ?? "")}</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const getErrorMessage = (t: TFunction, error: Error | { message?: string }) => {
  switch (error.message) {
    case "no_ats_integration":
      return t("v2.job_requisitions.modals.send_to_ats.errors.no_ats_integration")
    case "rate_limited_error":
      return t("v2.job_requisitions.modals.send_to_ats.errors.try_again")
    case "This Requisition ID has already been taken.":
      return t("v2.job_requisitions.modals.send_to_ats.errors.req_id_taken")
    case "unknown_error":
      return t("v2.job_requisitions.modals.send_to_ats.errors.failed_to_send")
    default:
      return error.message ?? t("v2.job_requisitions.modal.send_to_ats.errors.unexpected_error")
  }
}

export { AdpRmForm, AdpRmInfo, AdpRmInitSuccess, initAdpRmForm }
