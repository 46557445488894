import classNames from "classnames"
import dayjs from "dayjs"
import React, { MutableRefObject, useEffect, useRef } from "react"

import { useWithReactHookFormRegister } from "v2/react/shared/forms/hooks/useWithReactHookFormHooks"
import { InputWrapper } from "v2/react/shared/forms/InputWrapper"
import { UseInReactHookFormProp } from "v2/react/shared/forms/types"

interface DateInputProps {
  defaultValue?: string
  /**
   * boolean value passed in to prevent today being set
   * as the default value when one is not provided
   */
  emptyDefaultValue?: boolean
  errors?: string
  focusOnMount?: boolean
  id?: string
  inputClassName?: string
  inputRef?: MutableRefObject<HTMLInputElement | null>
  label?: string
  name: string
  min?: string
  max?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  useInReactHookForm?: UseInReactHookFormProp
  wrapperClassName?: string
}

const dateFormat = "YYYY-MM-DD"

const DateInput = ({
  defaultValue = "",
  value = "",
  emptyDefaultValue,
  errors,
  focusOnMount = false,
  id,
  inputClassName,
  inputRef,
  name,
  label,
  onChange,
  useInReactHookForm,
  wrapperClassName,
  min,
  max,
}: DateInputProps) => {
  const dateRef = useRef<HTMLInputElement>(inputRef?.current || null)

  useEffect(() => {
    if (focusOnMount && dateRef?.current) {
      dateRef.current.focus()
    }
  }, [focusOnMount, inputRef])

  const useReactHookFormProps = useWithReactHookFormRegister({ useInReactHookForm, name })?.(name)

  return (
    <InputWrapper
      className={wrapperClassName}
      errors={errors}
      id={id}
      label={label}
      name={name}
      useInReactHookForm={useInReactHookForm}
    >
      {value !== "" ? (
        <input
          id={id}
          type="date"
          className={classNames("input", inputClassName)}
          name={name}
          min={min}
          max={max}
          value={value}
          onChange={onChange}
          ref={dateRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...useReactHookFormProps}
        />
      ) : (
        <input
          id={id}
          type="date"
          className={classNames("input", inputClassName)}
          name={name}
          min={min}
          max={max}
          defaultValue={
            emptyDefaultValue ? defaultValue : defaultValue || dayjs(Date.now()).format(dateFormat)
          }
          onChange={onChange}
          ref={dateRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...useReactHookFormProps}
        />
      )}
    </InputWrapper>
  )
}

export { DateInput }
